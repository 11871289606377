import React, { Component } from "react";
import {
  Accordion,
  TableCell,
  TableBody,
  Divider,
  Box,
  Grid,
  Card,
  TableContainer,
  CardContent,
  Button,
  TableHead,
  Table,
  TableRow,
  Typography,
  Paper,
  FormControl
} from "@mui/material";
import Select from 'react-select';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ImDatabase, ImSearch, ImPlay3 } from "react-icons/im";
import APIDashboard from "../../services/APIDashboard";
import APIDashboardNasional from "../../services/APIDashboardNasional";
import APIWilayah from '../../services/APIWilayah';
import authServices from '../../services/authServices';



import Swal from "sweetalert2";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panel1: true,
      blocking: false,
      labelProv: "",
      labelKab: "",
      labelKec: "",
      labelKel: "",
      labelRw: "",
      labelRt: "",

      labelName: "",
      labelUsername: "",
      labelTingkatWilayah: "",
      labelRole: "",
      authProv: "",
      authKab: "",
      authKec: "",
      authKel: "",
      displayProv: false,
      displayKab: false,

      admPusat: 0,
      admProv: 0,
      admKab: 0,
      mp: 0,
      spv: 0,
      pendata: 0,
      md: 0,
      tabks: 0,
      tabulasi: 0,

      //---table
      sizePerPage: 10,
      tableHead: [],
      datas: [],
      //---end table

      //---wilayah
      flagNumber: -1,
      flagOpen: true,
      blocking: false,
      dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      //---end wilayah

      jumlahData: 0,
      datas: '',
      rows: [
        { value: '1', label: '2022-10-20' },
        { value: '2', label: '2022-10-20' },
        { value: '3', label: '2022-10-20' },
        { value: '4', label: '2022-10-20' },
      ],


    };
  }

  componentDidMount = () => {

    const rest = JSON.parse(localStorage.getItem("authority"));
    this.setState({
      roleUser: rest[0].attributes.user.roleID
    })
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({
          flagSearch: false,
          roleUser: rest[0].attributes.role
        },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }

        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
        }
        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }


    const dt = JSON.parse(localStorage.getItem("authority"));
    const wilProv = dt[0].attributes.user.wilProvinsi;
    const wilKab = dt[0].attributes.user.wilKabupaten;
    const wilKec = dt[0].attributes.user.wilKecamatan;
    const wilKel = dt[0].attributes.user.wilKelurahan;
    const atr = dt[0].attributes;
    let lprov, lkab, lkec, lkel;
    Object.keys(wilProv).length > 0
      ? (lprov = wilProv.nama_provinsi)
      : (lprov = "All");
    Object.keys(wilKab).length > 0
      ? (lkab = wilKab.nama_kabupaten)
      : (lkab = "All");
    Object.keys(wilKec).length > 0
      ? (lkec = wilKec.nama_kecamatan)
      : (lkec = "All");
    Object.keys(wilKel).length > 0
      ? (lkel = wilKel.nama_kelurahan)
      : (lkel = "All");

    authServices.get('/user/findWilayahById/' + dt[0].attributes.user.userId + '?periodeSensus=2024')
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200 && res.data.status === "fail" ) {
          
          this.setState({
            labelName: atr.user.namaLengkap,
            labelUsername: atr.user_name,
            labelTingkatWilayah: atr.user.TingkatWilayah,
            labelRole: atr.user.roleName,
            authProv: lprov,
            authKab: lkab,
            authKec: lkec,
            authKel: lkel,
          });
        }else{
          this.setState({
            labelName: res.data.namaLengkap,
            labelUsername: res.data.username,
            labelTingkatWilayah: atr.user.TingkatWilayah,
            labelRole: atr.user.roleName,
            authProv: lprov,
            authKab: lkab,
            authKec: lkec,
            authKel: lkel,
          });
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        
      });



    let dataPost = {};
    const idTingkatWIlayah = dt[0].attributes.user.TingkatWilayahID;
    let idWilayah = 0;

    const id_provinsi = parseInt(dt[0].attributes.user.wilProvinsi.id_provinsi);
    const id_kabupaten = parseInt(dt[0].attributes.user.wilKabupaten.id_kabupaten);
    const id_kecamatan = parseInt(dt[0].attributes.user.wilKecamatan.id_kecamatan);
    const id_kelurahan = parseInt(dt[0].attributes.user.wilKelurahan.id_kelurahan);

    if (idTingkatWIlayah === null) {
      idWilayah = 0;
      dataPost = {
        tingkatWilayahID: 0,
        id_provinsi: 0,
        id_kabupaten: 0,
        id_kecamatan: 0,
        id_kelurahan: 0
      };
    } else if (idTingkatWIlayah == 1) {
      idWilayah = parseInt(dt[0].attributes.user.wilProvinsi.id_provinsi);
      dataPost = {
        tingkatWilayahID: idTingkatWIlayah,
        id_provinsi: id_provinsi,
        id_kabupaten: 0,
        id_kecamatan: 0,
        id_kelurahan: 0
      };
    } else if (idTingkatWIlayah == 2) {
      idWilayah = parseInt(dt[0].attributes.user.wilKabupaten.id_kabupaten);
      dataPost = {
        tingkatWilayahID: idTingkatWIlayah,
        id_provinsi: id_provinsi,
        id_kabupaten: id_kabupaten,
        id_kecamatan: 0,
        id_kelurahan: 0
      };
    } else if (idTingkatWIlayah == 3) {
      idWilayah = parseInt(dt[0].attributes.user.wilKecamatan.id_kecamatan);
      dataPost = {
        tingkatWilayahID: idTingkatWIlayah,
        id_provinsi: id_provinsi,
        id_kabupaten: id_kabupaten,
        id_kecamatan: id_kecamatan,
        id_kelurahan: 0
      };
    } else {
      idWilayah = parseInt(dt[0].attributes.user.wilKelurahan.id_kelurahan);
      dataPost = {
        tingkatWilayahID: idTingkatWIlayah,
        id_provinsi: id_provinsi,
        id_kabupaten: id_kabupaten,
        id_kecamatan: id_kecamatan,
        id_kelurahan: id_kelurahan
      };
    }

    APIDashboard.post("/pkportal/dashboard/getSummaryLocation", dataPost)
      .then((res) => {
        this.setState({ blocking: false });
        
        if (res.status === 200) {
          this.setState({
            labelProv:
              res.data.data.rowProp === 1
                ? wilProv.nama_provinsi
                : res.data.data.rowProp,
            labelKab:
              res.data.data.rowKab === 1
                ? wilKab.nama_kabupaten
                : res.data.data.rowKab,
            labelKec:
              res.data.data.rowKec === 1
                ? wilKec.nama_kecamatan
                : res.data.data.rowKec,
            labelKel:
              res.data.data.rowKel === 1
                ? wilKel.nama_kelurahan
                : res.data.data.rowKel,
            labelRw:
              res.data.data.rowKel === 1
                ? wilKel.nama_kelurahan
                : res.data.data.rowRw,
            labelRt:
              res.data.data.rowKel === 1
                ? wilKel.nama_kelurahan
                : res.data.data.rowRt
          });
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
      });

    APIDashboard.post("/pkportal/dashboard/getSummaryUser", dataPost)
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState({
            pendata: res.data.data.rowUserPendata === null ? 0 : res.data.data.rowUserPendata,
            admPusat: res.data.data.rowUserPusat === null ? 0 : res.data.data.rowUserPusat,
            admProv: res.data.data.rowUserAdmProv === null ? 0 : res.data.data.rowUserAdmProv,
            admKab: res.data.data.rowUserAdmKab === null ? 0 : res.data.data.rowUserAdmKab,
            mp: res.data.data.rowUserMp === null ? 0 : res.data.data.rowUserMp,
            spv: res.data.data.rowUserSpv === null ? 0 : res.data.data.rowUserSpv,
            md: res.data.data.rowUserMd === null ? 0 : res.data.data.rowUserMd,
            tabks: res.data.data.rowUserTabulasiKs === null ? 0 : res.data.data.rowUserTabulasiKs,
            tabulasi: res.data.data.rowUserTabulasi === null ? 0 : res.data.data.rowUserTabulasi,
          });
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
      });
  };

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 });
  };
  handleOpenProv = () => {
    this.setState({
      displayProv: true,
    });
  };
  handleOpenKab = () => {
    this.setState({
      displayKab: true,
    });
  };

  rubah(angka) {
    if (angka === null) {
      angka = 0
    }
    return angka?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  handleSearch = (e) => {
    this.setState({ blocking: true });
    const rest = JSON.parse(localStorage.getItem("authority"));
    const tingkat = rest[0].attributes.user.TingkatWilayahID

    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, role } = this.state;
    let dataPostNas = {
      v_id_provinsi: '1',
      v_id_kabupaten: '0',
      tingkatWilayahID: tingkat
    };
    // if(this.state.provinsi >= 0){
    //     Swal.fire({
    //       title: 'Info',
    //       icon: 'info',
    //       text: 'Wajib Memilih Wilayah Minimal Provinsi',
    //     });
    //     this.setState({ blocking: false });
    // }else{
    

    if (this.state.id_provinsi === 0) {
      dataPostNas = {
        "kab": 0,
        "prov": 0,
        "tingkatWilayahID": 0
      }
      APIDashboardNasional.post('getListDashbordStatusPendataan', dataPostNas)
        .then((res) => {
          this.setState({ blocking: false });
          if (res.status === 200) {
            this.setState(
              {
                datas: res.data
              }, () => {
                this.setState({
                  jumlahData: this.state.datas.data.length
                })
              }
            )
          }
        })
        .catch((error) => {
          this.setState({ blocking: false });
          
        });

    } else {
      

      dataPostNas = {
        prov: this.state.provinsi.value,
        kab: (this.state.kabupaten !== null) ? this.state.kabupaten.value : '0',
        // tingkatWilayahID: (this.state.kabupaten !== null) ? 2 : 1
      };
      APIDashboardNasional.post('getListDashbordStatusPendataanByShcema', dataPostNas)
        .then((res) => {
          this.setState({ blocking: false });
          if (res.status === 200) {
            this.setState(
              {
                datas: res.data
              }, () => {
                this.setState({
                  jumlahData: this.state.datas.data.length
                })
              }
            )
          }
        })
        .catch((error) => {
          this.setState({ blocking: false });
          
        });
    }
    // }
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // 
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah


  render() {
    const options = {
      page: 1,
      sizePerPageList: [{
        text: '1', value: 1
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
    };
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className="content">
          <div className="title-content">
            <ImPlay3 className="icon-brd" />
            Dashboard
          </div>
          <div className="body-content">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                  <section className="fieldset">
                    <h1>Pemutakhiran Wilayah</h1>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter primary">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.labelProv)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Provinsi
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter success">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.labelKab)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Kabupaten
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter info">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.labelKec)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Kecamatan
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter info">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.labelKel)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Kelurahan
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter success">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.labelRw)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              RW
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter primary">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.labelRt)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              RT
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                    </Grid>
                  </section>
                </Grid>
                <Grid item xs={12} md={5}>
                  <section className="fieldset">
                    <h1>Profile</h1>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <span className="fieldset-text">
                          <b>Name:</b> {this.state.labelName}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span className="fieldset-text">
                          <b>Username:</b> {this.state.labelUsername}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span className="fieldset-text">
                          <b>Tingkat Wilayah:</b>{" "}
                          {this.state.labelTingkatWilayah}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span className="fieldset-text">
                          <b>Role:</b> {this.state.labelRole}
                        </span>
                      </Grid>
                    </Grid>
                    <Divider sx={{ margin: "1rem 0", fontSize: "12px" }}>
                      {" "}
                      Authority{" "}
                    </Divider>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <span className="fieldset-text">
                          <b>Provinsi:</b> {this.state.authProv}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span className="fieldset-text">
                          <b>Kabupaten:</b> {this.state.authKab}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span className="fieldset-text">
                          <b>Kecamatan:</b> {this.state.authKec}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span className="fieldset-text">
                          <b>Kelurahan:</b> {this.state.authKel}
                        </span>
                      </Grid>
                    </Grid>
                  </section>
                </Grid>
                <Grid item xs={12} md={12}>
                  <section className="fieldset">
                    <h1>User PK</h1>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter primary">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.admPusat)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Admin Pusat
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter success">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.admProv)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Admin Provinsi
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter info">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.admKab)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Admin Kabupaten
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter info">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.mp)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Manager Pengelola
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter success">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.spv)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Supervisor
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter primary">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.pendata)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Pendata
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter success">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.md)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Manager Data
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter primary">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.tabks)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Tabulasi KS
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Card sx={{ width: "100%" }}>
                          {/* <CardContent sx={{ padding: 0 }}> */}
                          <div className="card-counter info">
                            <Typography
                              className="count-numbers"
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {this.rubah(this.state.tabulasi)}
                            </Typography>
                            <Typography
                              className="count-name"
                              sx={{ mb: 1 }}
                              color="text.secondary"
                            >
                              Tabulasi
                            </Typography>
                            <ImDatabase className="count-icon" />
                          </div>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                    </Grid>
                  </section>
                </Grid>

                <Grid item xs={12} md={12}>
                  <section className="fieldset">
                    <h1>Informasi Data</h1>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br></br>
                    <Grid item xs={12} md={12}>
                      <Paper sx={{ width: '100%', boxShadow: 'none' }}>
                        {/* <CommonTable
                    tableHead={this.state.tableHead}
                    datas={this.state.datas}
                    spp={+this.state.sizePerPage}
                  > */}
                        <div className='user-table'>
                          <BootstrapTable
                            data={this.state.datas.data}
                            pagination={true}
                            options={options}
                            search={false}
                          >
                            <TableHeaderColumn width='160' isKey={true} dataField='nama_provinsi' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Nama Provinsi</TableHeaderColumn>
                            <TableHeaderColumn width='120' dataField='nama_kabupaten' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Nama Kabupaten</TableHeaderColumn>
                            <TableHeaderColumn width='120' dataField='status_sensus' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Status Data</TableHeaderColumn>
                            <TableHeaderColumn width='120' dataField='jumlah_kk' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Jumlah KK</TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                        <div className='sizePerPage'>
                          Size Per Page
                          <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                            <option value='1'>1</option>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value={this.state.jumlahData}>All</option>
                          </select>
                        </div>
                        <div className='totalData'><b>Total Data: {this.state.jumlahData}</b></div>
                      </Paper>
                    </Grid>
                  </section>
                </Grid>

                {/* Data Golden Dan On Going  */}
                {/* <Grid item xs={12} md={12}>
                <section className="fieldset">
                <h1>Informasi Data</h1>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                    <TableContainer component={Paper}>
                      <Table aria-label="caption table">
                        <caption><b>Detail Data Golden</b></caption>
                        <TableHead>
                          <TableRow>
                            <TableCell align="ceter">Jumlah Data</TableCell>
                            <TableCell align="ceter">Tanggal Dibuat</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.rows.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell align="ceter">{row.value}</TableCell>
                              <TableCell align="ceter">{row.label}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TableContainer component={Paper}>
                        <Table aria-label="caption table">
                          <caption><b>Detail Data OnGoing 1</b></caption>
                          <TableHead>
                            <TableRow>
                              <TableCell align="ceter">Jumlah Data</TableCell>
                              <TableCell align="ceter">Tanggal Dibuat</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.rows.map((row) => (
                              <TableRow key={row.name}>
                                <TableCell align="ceter">{row.value}</TableCell>
                                <TableCell align="ceter">{row.label}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid item xs={12} md={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="caption table">
                          <caption><b>Detail Data OnGoing 2</b></caption>
                          <TableHead>
                            <TableRow>
                              <TableCell align="ceter">Jumlah Data</TableCell>
                              <TableCell align="ceter">Tanggal Dibuat</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.rows.map((row) => (
                              <TableRow key={row.name}>
                                <TableCell align="ceter">{row.value}</TableCell>
                                <TableCell align="ceter">{row.label}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                  </Grid>
                  </section>
                </Grid> */}

              </Grid>
            </Box>
          </div>
        </div>
      </BlockUi>
    );
  }
}

export default Dashboard;
