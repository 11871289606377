import React, { Component } from 'react'
import { ImPlay3, ImSearch, ImPrinter } from 'react-icons/im';
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonTable from '../../../commons/table/TableMonitoring';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import APIWilayah from '../../../services/APIWilayah';
import APIDashboard from "../../../services/APIDashboard";
import APIMonitoringBaru from '../../../services/APIMonitoringBaru';
import APITargetVsActual from '../../../services/APITargetVsActual';

class TargetVsActual extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panel1: true,
      sizePerPage: 10,
      title: '',
      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      text1: 'NAMA PROVINSI',

      datas: [],
      optRekap: [{ value: 2, label: 'CAPI', name: 'true' }, { value: 3, label: 'PAPI', name: 'false' }],
      optPeriodePendataan: [{ value: 2022, label: '2022' }, { value: 2021, label: '2021' }],

      //Wilayah
      dt_prov: [], provinsi: null, id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: null, id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: null, id_rw: 0, dis_rw: true,
      dt_rt: [], rt: null, rtID: '', id_rt: 0, dis_rt: true,
      periodePendataan: null,

      //datas: [],
      //selectDatas: [],
      sendDatas: {
        "tingkatWilayahID": 0,
        "idProv": 0,
        "idKab": 0,
        "idKec": 0,
        "idKel": 0,
        "idRW": 0,
        "periodePendataan": 0,
        "metodePendataan": null
      }
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  setHeaderTable = () => {
    let { text1 } = this.state
    const { provinsi, kabupaten, kecamatan, desa, rw } = this.state
    let header = []
    var headerNamaWilayah = [
      { width: "150", row: '0', rowSpan: '2', title: text1, dataField: "namaDaerah", headerAlign: 'center', dataAlign: 'center', editable: false },
    ]

    var headerColumns = [
      { width: "300", row: '0', colSpan: '3', title: 'KABUPATEN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'ADA', dataField: 'kabAda', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'TERDATA', dataField: 'kabTerdata', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: '%', dataField: 'persenKab', headerAlign: 'center', dataAlign: 'center', editable: false },
    ];

    var headerColumnsKec = [
      { width: "300", row: '0', colSpan: '3', title: 'KECAMATAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'ADA', dataField: 'kecAda', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'TERDATA', dataField: 'kecTerdata', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: '%', dataField: 'persenKec', headerAlign: 'center', dataAlign: 'center', editable: false },
    ];

    var headerColumnsKel = [
      { width: "300", row: '0', colSpan: '3', title: 'KELURAHAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'ADA', dataField: 'kelAda', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'TERDATA', dataField: 'kelTerdata', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: '%', dataField: 'persenKel', headerAlign: 'center', dataAlign: 'center', editable: false },
    ]

    var headerColumnsRW = [
      { width: "300", row: '0', colSpan: '3', title: 'RW', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'ADA', dataField: 'rwAda', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'TERDATA', dataField: 'rwTerdata', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: '%', dataField: 'persenRw', headerAlign: 'center', dataAlign: 'center', editable: false },
    ]

    var headerColumnsRT = [
      { width: "300", row: '0', colSpan: '3', title: 'RT', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'ADA', dataField: 'rtAda', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'TERDATA', dataField: 'rtTerdata', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: '%', dataField: 'persenRt', headerAlign: 'center', dataAlign: 'center', editable: false },
    ]

    var headerColumnsKK = [
      { width: "400", row: '0', colSpan: '4', title: 'JUMLAH KK', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'ADA', dataField: 'kkAda', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'TARGET KK', dataField: 'kkTarget', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: 'TERDATA', dataField: 'kkTerdata', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '1', title: '% THD TARGET', dataField: 'persenKk', headerAlign: 'center', dataAlign: 'center', editable: false },
    ]

    let head = rw ? header = headerNamaWilayah.concat(headerColumnsKK) :
      desa ? header = headerNamaWilayah.concat(headerColumnsRT, headerColumnsKK) :
        kecamatan ? header = headerNamaWilayah.concat(headerColumnsRW, headerColumnsRT, headerColumnsKK) :
          kabupaten ? header = headerNamaWilayah.concat(headerColumnsKel, headerColumnsRW, headerColumnsRT, headerColumnsKK) :
            provinsi ? header = headerNamaWilayah.concat(headerColumnsKec, headerColumnsKel, headerColumnsRW, headerColumnsRT, headerColumnsKK) :
              header = headerNamaWilayah.concat(headerColumns, headerColumnsKec, headerColumnsKel, headerColumnsRW, headerColumnsRT, headerColumnsKK)

    this.setState({ tableHead: head });
  }

  componentDidMount() {
    this.setHeaderTable()
    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    let title2

    if (idTingkatWilayah == 0) {
      title2 = ''

      this.setState({
        sendDatas: {
          "tingkatWilayahID": 0,
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "periodePendataan": 0,
          "metodePendataan": null
        }
      }
      );

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      this.setState(
        {
          sendDatas: {
            "tingkatWilayahID": 1,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": 0,
            "idKec": 0,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0,
            "metodePendataan": null
          }
        }
      )

    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

      this.setState(
        {
          sendDatas: {
            "tingkatWilayahID": 2,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
            "idKec": 0,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0,
            "metodePendataan": null
          }
        },
        // () => {this.getBackend(this.state.sendDatas)}  
      )

    } else {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan
      this.setState(
        {
          sendDatas: {
            "tingkatWilayahID": 3,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
            "idKec": 0,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0,
            "metodePendataan": null
          }
        }
      )
    }

    this.setState({ title: title2 },
      () => { this.setHeaderTable() }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {
                console.log("objKab", objKab)
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {
                console.log("objKec", objKec)
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
              }
            }
          );
          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  //API (Wilayah)
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      //handleSearch
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 1,
          "idProv": +e.value,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
      );

      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 0,
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 2,
          "idKab": +e.value,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
        , id_kecamatan: 0, id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 1,
          //"idProv": this.state.sendDatas.idProv,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,

        }

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 3,
          "idKec": +e.value,

        }
      });

      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
        , id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 2,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 4,
          "idKel": +e.value,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );

      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
        , id_rw: 0

      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 3,
          "idKel": 0,
          "idRW": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 5,
          "idRW": +e.value,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 4,
          "idRW": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah
  sel_periode = (e) => {
    if (e) {
      this.setState({
        periodePendataan: e,
        sendDatas: {
          ...this.state.sendDatas,
          "periodePendataan": +e.value
        }

      });
    }
    else {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "periodePendataan": 0
        },
        periodePendataan: null,

      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  sel_metode = (e) => {
    if (e) {
      this.setState({
        metodePendataan: e,
        sendDatas: {
          ...this.state.sendDatas,
          "metodePendataan": e.name
        }

      });
    }
    else {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "metodePendataan": null
        },
        metodePendataan: null,

      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'NAMA RT'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'NAMA RW'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'NAMA KELURAHAN'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'NAMA KECAMATAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'NAMA KABUPATEN'
              }
              else {
                title = ''
                text1 = 'NAMA PROVINSI'
              }
            }
          }
        }
      }
    }
    this.setState({ title: title, text1: text1 },
      () => { this.setHeaderTable() }
    )
  }

  handleSearch = () => {
    if (this.state.sendDatas.periodePendataan !== 0) {
      this.setLabel()
      this.setState({ blocking: true })
      console.log('data dilempar', this.state.sendDatas)
      // APIDashboard.post("/pkportal/monitoring/getMonitoringTarget", this.state.sendDatas)
      APITargetVsActual.post("/pkportal/monitoring/getMonitoringTarget", this.state.sendDatas)
        .then((res) => {
          this.setState({ blocking: false });
          if (res.status === 200) {
            this.setState(
              {
                datas: res.data.data
              }
            )
          }
        })
        .catch((error) => {
          this.setState({ blocking: false });
          console.log(error, "dataaa ERROR");
        });

    } else {
      Swal.fire({
        title: 'Info',
        icon: 'info',
        text: 'Silakan pilih periode pendataan.',
      });
    }
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Monitoring Target vs Aktual</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%', marginBottom: '10px' }} size="small" >
                            Periode Pendataan
                            <Select options={this.state.optPeriodePendataan} id="periode" name="periode" onChange={this.sel_periode} value={this.state.periodePendataan} placeholder="Pilih Periode Pendataan" isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Jenis Rekapitulasi
                            <Select options={this.state.optRekap} id="prov" name="provinsi" onChange={this.sel_metode} value={this.state.metodePendataan} placeholder="Pilih Jenis Data" isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid item xs={12}>
                            <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} />Cari</Button>
                            <Button sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} />Cetak</Button>
                          </Grid>
                          {/* <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={12}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '5px' }} variant="contained">Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Capaian Terdata</h3>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
              />
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div>
            </Paper>
          </div>
        </div>
      </BlockUi>
    )
  }
}

export default TargetVsActual;