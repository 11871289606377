import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import moment from 'moment';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { AiOutlineIdcard } from "react-icons/ai";
import { BsGearWideConnected, BsPersonCheck, BsPersonCheckFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { ImBin, ImCalendar, ImCross, ImFloppyDisk, ImListNumbered, ImLocation, ImManWoman, ImMap2, ImPhone, ImPlay3 } from 'react-icons/im';
import { MdAlternateEmail } from "react-icons/md";
import Select from 'react-select';
import Swal from 'sweetalert2';
import APIWilayah from '../../../services/APIWilayah';
import authServices from '../../../services/authServices';
import APIButtonAkses from '../../../services/APIButtonAkses';
import { ColorLens } from "@mui/icons-material";


const isNumber = (str) => /^[0-9]*$/.test(str);

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            dt_roles: [],
            dt_wilayah: [],
            role: null,
            value_priode: this.props.value_priode,
            tingkat_wilayah: [],
            statusRole: JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID,
            blocking: false,
            dt_prov: [], provinsi: null, id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: false, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: false, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: null, id_des: 0, dis_des: false, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: null, id_rw: 0, dis_rw: false, depdagriRw: '',
            dt_rt: [], rt: null, id_rt: '', id_rt: 0, dis_rt: false, depdagriRt: '',
            hideProvinsi: 'none', hideKabupaten: 'none', hideKecamatan: 'none', hideKelurahan: 'none', hideRW: 'none', hideRT: 'none', hideInpuWilayah: 'none',
            wilayahUser: [],
            newValues: [],
            nomer: 1,
            username: '', nik: '', nip: '', email: '', namaLengkap: '', alamat: '', noTelepon: '', password: '123456', smartphoneInput: '',
            disSave: true, hideNpwp: 'none',
            dataStatus: [
                { value: 'PNS', label: 'PNS' },
                { value: 'PPPK', label: 'PPPK' },
                { value: 'Bukan PNS', label: 'Bukan PNS' },
            ],
            golongStatus: [
                { value: '1', label: 'Golongan I' },
                { value: '2', label: 'Golongan II' },
                { value: '3', label: 'Golongan III' },
                { value: '4', label: 'Golongan IV' },
            ], valueGolongan: '',
            namaIbuKandung: '', npwp: '', golongan: '', validasiData: false, no_rekening: '',
            tanggal_lahir: '', tempa_lahir: '',
            disButtonSave: false, jenis_kelamin: '',
            status_pembayaran: false,

            //Inputan Wilayah Alamat
            dt_prov2: [], provinsi2: null, id_provinsi2: 0, depdagriProv2: '', dis_prov2: false, auto_prov2: false,
            dt_kab2: [], kabupaten2: null, id_kabupaten2: 0, dis_kab2: false, depdagriKab2: '', auto_kab2: false,
            dt_kec2: [], kecamatan2: null, id_kecamatan2: 0, dis_kec2: false, depdagriKec2: '', auto_kec2: false,
            dt_des2: [], desa2: null, id_des2: 0, dis_des2: false, depdagriKel2: '', auto_des2: false,
            dt_rw2: [], rw2: '', id_rw2: 0, dis_rw2: false, depdagriRw2: '',
            dt_rt2: [], rt2: '', id_rt2: '', id_rt2: 0, dis_rt2: false, depdagriRt2: '', kode_pos: '',

            flagProv: true,
            flagKab: true,
            flagKec: true,
            flagDes: true,
            disstatus: false,
            optBank: [],
            kodebank: null,
            namabank: null,
            bank: null,

            //loc wilayah
            lockWilayah: false,
            rowRoleId: null,
            wilayahNew: false,

        }
    }

    sel_bank = (e) => {
        console.log(e)
        if (e) {
            this.setState({
                bank: e, kodebank: e.value, namabank: e.label
            }, () => {
            })


        }
        else {
            this.setState({
                bank: [], kodebank: null,
                namabank: null,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleSelectRole = (e) => {

        if (e) {
            this.setState({ role: e, hideInpuWilayah: '', tk: null })
            if (e.value == 2) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }

                    })
                })
            } else if (e.value == 1) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    smartphoneInput: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 25) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            } else if (e.value == 26) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 27) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 28 || e.value == 29 || e.value == 30) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 2,
                            tingkatWilayah: "Kabupaten"
                        }],
                        tk: { value: 2, label: 'Kabupaten' }
                    })
                })
            } else if (e.value == 15) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    smartphoneInput: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 16) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 17) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 18) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: '',
                    hideRT: '',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: ''
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 6,
                            tingkatWilayah: "RT"
                        }],
                        tk: { value: 6, label: 'RT' }
                    })
                })
            } else if (e.value == 5) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: '',
                    hideRT: '',
                    smartphoneInput: 'none',
                    captionPendata: ''
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 6,
                            tingkatWilayah: "RT"
                        }],
                        tk: { value: 6, label: 'RT' }
                    })
                })
            } else if (e.value == 3) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: "Kecamatan" }
                    })
                })
            } else if (e.value == 4) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: false,
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            } else if (e.value == 6) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 7) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 8) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 9) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 2,
                            tingkatWilayah: "Kabupaten"
                        }],
                        tk: { value: 2, label: 'Kabupaten' }
                    })
                })
            } else if (e.value == 10) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tingkatWilayah: { value: 4, label: 'Kelurahan' }
                    })
                })
            } else if (e.value == 11) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideRT: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 12) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 2,
                            tingkatWilayah: "Kabupaten"
                        }],
                        tk: { value: 2, label: 'Kabupaten' }
                    })
                })
            } else if (e.value == 13) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 14) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            } else if (e.value == 20) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none'
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 21) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none'
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 2,
                            tingkatWilayah: "Kabupaten"
                        }],
                        tk: { value: 2, label: 'Kabupaten' }
                    })
                })
            } else if (e.value == 22) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none'
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 23) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none'
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            }
        } else {
            this.setState({
                role: [],
                hideInpuWilayah: 'none',
                hideProvinsi: 'none',
                hideKabupaten: 'none',
                hideKecamatan: 'none',
                hideKelurahan: 'none',
                hideRW: 'none',
                hideRT: 'none',
                smartphoneInput: 'none',
                captionPendata: 'none',
                wilayahUser: [],
                tk: null,
                dt_wilayah: []
            })
        }
    }

    handleSelectTingkatWilayah = (e) => {
        this.setState({ tingkat_wilayah: e })
    }

    handleSelectStatus = (e) => {
        if (e) {
            this.setState({
                statusPekerjaan: e,
                statusPekerjaannya: e.label
            })
            if (e.value === "Bukan PNS") {
                this.setState({
                    valueGolongannya: '',
                    valueGolongan: '',
                    nip: ''
                })
            } else if (e.value === "PPPK") {
                this.setState({
                    valueGolongannya: '',
                    valueGolongan: ''
                })
            }
        } else {
            this.setState({
                statusPekerjaannya: null
            })
        }

    }

    handleSelectGolongan = (e) => {
        this.setState({
            valueGolongan: e,
            valueGolongannya: e.value
        })
    }


    getBank = () => {
        APIButtonAkses.get('transaksi/getRefBank?periode=' + this.state.value_priode)
            .then((res) => {

                this.setState({
                    optBank: res.data,
                    blocking: false
                })
            })
    }

    getRole = (e) => {
        authServices
            .get("/roles/list?periodeSensus=" + this.state.value_priode)
            .then((res) => {
                this.setState({ blocking: false });
                if (res.status === 200) {
                    this.setState({
                        dt_roles: res.data
                    }, () => {
                    })
                }
            })
            .catch((error) => {
                this.setState({ blocking: false });

            });
    }

    getTingkatWilayah = (e) => {
        authServices.get("/roles/wilayah?periodeSensus=" + this.state.value_priode)
            .then((res) => {
                this.setState({ blocking: false });
                if (res.status === 200) {
                    this.setState({
                        dt_wilayah: res.data
                    }, () => {
                    })
                }
            })
            .catch((error) => {
                this.setState({ blocking: false });

            });
    }


    handleChangeUsername = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-' ']/g, '')
        });
    }

    handleChangeEmail = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.@ ]/g, '')
        });
    }

    handleChangeKelamin = (e) => {
        this.setState({
            jenis_kelamin: e.target.value
        });
    }

    handleChangeTempatLahir = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeNamaBank = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeAlamat = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeRW = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeRT = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeForm = (e) => {
        const { name, value } = e.target;
        const numericFields = ['noTelepon', 'no_rekening', 'npwp', 'kode_pos', 'nik'];

        if (numericFields.includes(name)) {
            // Menggunakan regex untuk memeriksa apakah nilai hanya mengandung angka
            if (/^\d*$/.test(value)) {
                this.setState({ [name]: value });
            }
        } else {
            this.setState({ [name]: value });
        }
    }


    handleChangeNpwp = (e) => {
        if (e.target.checked) {
            this.setState({ hideNpwp: false, statusNpwp: true })
        } else {
            this.setState({ hideNpwp: 'none', npwp: '', statusNpwp: false })
        }
    }

    handleChangeValidasi = (e) => {
        if (e.target.checked) {
            this.setState({ validasiData: true })
        } else {
            this.setState({ validasiData: false })
        }
    }

    getBackend = (formData) => {
        this.setState({ disButtonSave: true, blocking: true })
        authServices.put("user/update", formData)
            .then((res) => {
                this.setState({ blocking: false, disButtonSave: false });

                if (res.data.status == '400') {
                    Swal.fire(
                        'Save gagal !',
                        'Mohon Isi Semua Kolom Yang Tersedia.',
                        'error'
                    )
                } else if (res.status === 200 && res.data.status === false) {
                    Swal.fire(
                        'Gagal Simpan !',
                        'ID Wilayah Telah Digunakan.',
                        'error'
                    )
                } else if ((res.status === 200 && res.data.status === true)) {
                    Swal.fire(
                        'Berhasil Simpan !',
                        'User berhasil di edit.',
                        'success'
                    ).then(
                        this.props.handleOpenCloseEditUser(null, {}, formData)
                    )
                }
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    Swal.fire(
                        'Gagal Simpan !',
                        error.response.data.message,
                        'error'
                    )
                } else {
                    Swal.fire(
                        'Gagal Simpan !',
                        error.response.data.message,
                        'error'
                    )
                }

                this.setState({ blocking: false, disButtonSave: false });

            });
    }

    refreshPage = (e) => {
        window.location.href = '/user';
    }

    sel_tanggal_lahir = (e) => {
        if (e) {
            this.setState({
                tanggal_lahir: e.target.value,
            })
        } else {
            this.setState({
                tanggal_lahir: ''
            })
        }
    }

    handleSaveProfil = (e) => {


        let countWilayah = 0;

        if (this.state.bank === null && this.state.no_rekening?.length === 0) {
            Swal.fire(
                'Peringatan !',
                'No Rekening Wajib Di Isi Apabila Anda Mengisi Nama Bank !',
                'info'
            )
            return false;
        }
        if (this.state.no_rekening?.length > 0 && this.state.bank === null) {
            Swal.fire(
                'Peringatan !',
                'Nama Bank Wajib Di Isi Apabila Anda Mengisi Nomor Rekening !',
                'info'
            )
            return false;
        }

        this.state.wilayahUser.forEach(el => {
            if (el.status_data !== 'delete') {
                countWilayah++
            }
        })

        if (countWilayah === 0) {
            Swal.fire(
                'Peringatan !',
                'User Wilayah Tidak Boleh Kosong !',
                'info'
            )
            return false;
        }

        if (this.state.tanggal_lahir === '' || this.state.tempat_lahir === '' ||
            this.state.tanggal_lahir === null || this.state.tempat_lahir === null) {
            Swal.fire(
                'Peringatan !',
                'Mohon Isi Tanggal Lahir & Tempat Lahir !',
                'info'
            )
            return false;
        }

        if (this.state.provinsi2 === null || Object.keys(this.state.provinsi2).length === 0 ||
            this.state.kabupaten2 === null || Object.keys(this.state.kabupaten2).length === 0 ||
            this.state.kecamatan2 === null || Object.keys(this.state.kecamatan2).length === 0 ||
            this.state.desa2 === null || Object.keys(this.state.desa2).length === 0 ||
            this.state.alamat === '' || this.state.kode_pos.length <= 0 || this.state.kode_pos === '' ||
            this.state.rw2 === '' || this.state.rw2 === '' ||
            this.state.rt2 === null || this.state.rt2 === null
        ) {
            Swal.fire(
                'Peringatan !',
                'Alamat Dan Wilayah Tidak Boleh Kosong',
                'info'
            )
            return false;
        }

        const kodePosStr = this.state.kode_pos.toString();
        if (kodePosStr.length < 5) {
            Swal.fire(
                'Info!',
                'Mohon isi kode pos minimal 5 angka, sesuai dengan kode pos daerah.',
                'info'
            );
            return false
        }


        if (this.state.jenis_kelamin <= 0) {
            Swal.fire(
                'Peringatan !',
                'Mohon Pilih Jenis Kelamin !',
                'info'
            )
            return false;
        }


        if (this.state.hideNpwp === false && this.state.npwp === "") {
            Swal.fire(
                'Peringatan !',
                'Npwp Wajib Di Isi Apabila Anda Mempunyai NPWP !',
                'info'
            )
            return false;
        }

        if (this.state.validasiData === false) {
            Swal.fire(
                'Peringatan !',
                'Mohon Ceklis Bahwa Anda Menyetujui Bahwa Anda Dapat Mempertanggungjawabkan Data Yang Anda Input.',
                'info'
            )
            return false;
        }

        if (this.state.statusPekerjaannya === null || this.state.statusPekerjaannya?.length === 0) {

            Swal.fire(
                'Peringatan !',
                'Status Pekerjaan wajib dipilih',
                'error'
            )
            return false;
        }




        if (this.state.statusPekerjaannya === "PNS" && (this.state.nip === "" || this.state.valueGolongannya === '' || this.state.valueGolongannya === 0)) {
            Swal.fire(
                'Peringatan !',
                'PNS Wajib Mengisi NIP Dan Golongan .',
                'error'
            )
            return false;
        }
        // else if (this.state.statusPekerjaannya === "PPPK" && this.state.nip === "") {
        //     Swal.fire(
        //         'Peringatan !',
        //         'PPPK Wajib Mengisi NIP.',
        //         'error'
        //     )
        //     return false;
        // }


        if (this.state.role.value == 5) {
            if (this.state.statusPekerjaan == '') {
                Swal.fire(
                    'Peringatan !',
                    'Status Pekerjaan untuk pendata Harus Diisi.',
                    'error'
                )
                return false;
            }
        }

        if (this.state.role.value == 6) {

            if (this.state.statusPekerjaan == '') {
                Swal.fire(
                    'Peringatan !',
                    'Status Pekerjaan untuk Manager Data Harus Diisi.',
                    'error'
                )
                return false;
            }
        }

        if (
            this.state.username == '' ||
            this.state.namaLengkap === '' ||
            this.state.namaLengkap === null ||
            this.state.nik == '' ||
            this.state.alamat == '' ||
            this.state.email === '' ||
            this.state.email === null ||
            // this.state.noTelepon === '' ||
            // this.state.noTelepon === null ||
            this.state.tingkat_wilayah == []
        ) {
            Swal.fire(
                'Peringatan !',
                'Mohon Isi Semua Kolom Yang Tersedia.',
                'error'
            )
            return false;
        }
        let timestamp = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Jakarta" })).toISOString();
        this.setState({
            dataFormat: {
                id: this.state.id,
                namalengkap: this.state.namaLengkap,
                email: this.state.email,
                notelepon: this.state.noTelepon,
                roleid: this.state.role.value,
                alamat: this.state.alamat,
                nik: this.state.nik,
                password: this.state.password,
                nip: this.state.nip,
                isactive: true,
                istemporary: true,
                periodesensus: 2024,
                smartphone: this.state.smartphone,
                tingkatwilayahid: this.state.tingkat_wilayah.value,
                namaIbuKandung: this.state.namaIbuKandung,
                npwp: this.state.npwp,
                statusPekerjaan: this.state.statusPekerjaannya,
                golongan: this.state.valueGolongannya,
                userWilayah: this.state.wilayahUser,
                status_npwp: this.state.statusNpwp,
                lastmodifiedby: this.state.lastModifiedBy,
                username: this.state.username,
                bank: this.state.kodebank,
                no_rekening: this.state.no_rekening,
                validasiDate: timestamp,
                tanggal_lahir: new Date(this.state.tanggal_lahir.toLocaleString({ timeZone: "Asia/Jakarta" })).toISOString(),
                tempat_lahir: this.state.tempat_lahir,
                kode_pos: this.state.kode_pos,
                alamat_provinsi: this.state.provinsi2.value,
                alamat_kabupaten: this.state.kabupaten2.value,
                alamat_kecamatan: this.state.kecamatan2.value,
                alamat_kelurahan: this.state.desa2.value,
                alamat_rw: (this.state.rw2 !== undefined) ? this.state.rw2 : this.state.datauser.alamat_rw,
                alamat_rt: (this.state.rt2 !== undefined) ? this.state.rt2 : this.state.datauser.alamat_rt,
                jenis_kelamin: parseInt(this.state.jenis_kelamin),
                status_pembayaran: this.state.status_pembayaran
            }
        }, function () {
            let dataOri = this.state.dataFormat
            if (this.state.role.value === 10) {
                dataOri = { ...dataOri, tingkatwilayahid: 6 }
            }
            // 
            this.getBackend(dataOri)
        })

    }

    getData = () => {
        const pathname = window.location.href;
        const lastPart = pathname.split("/").pop();

        this.setState({ blocking: true });
        authServices.get('/user/findWilayahById/' + this.props.dataEdit.id + '?periodeSensus=' + this.state.value_priode)
            .then((res) => {
                const bank = this.state.optBank.filter(el => el.kode === res.data.bank)
                let bank_coba = bank.map(({ kode, bank }) => ({ value: kode, label: bank }))
                this.setState({ blocking: false, id: res.data.id, disstatus: res.data.roleid });
                if (this.state.disstatus === 10) {
                    this.setState({ status_pembayaran: true })
                }
                if (res.status === 200 && res.data.status === "fail") {
                    Swal.fire(
                        'Gagal Memuat Data',
                        'Ada Kesalahan Saat Memuat Data',
                        'error'
                    ).then(
                        this.props.handleOpenCloseEditUser(null, {}, '')
                    )
                } else if (res.status === 200) {
                    const res_role = res
                    authServices.get("/roles/list?periodeSensus=" + this.state.value_priode)
                        .then((res) => {
                            if (res.status === 200) {
                                this.setState({
                                    dt_roles: res.data
                                }, () => {
                                    const role = JSON.parse(localStorage.getItem('authority'))[0].attributes.role
                                    if (role === 'Supervisor') {
                                        this.setState({
                                            dt_roles: []
                                        })
                                    } else if (role === 'Manager Pengelolaan' || role === 'Manager Data') {
                                        const manager = [res.data[3], res.data[4], res.data[9], res.data[13]]
                                        this.setState({
                                            dt_roles: manager
                                        })
                                    } else if (res.data.status_pembayaran === 'Operator') {
                                        this.setState({
                                            status_pembayaran: true
                                        })
                                    } else if (role === 'Admin Kabupaten') {
                                        const manager = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[9], res.data[12], res.data[13]]
                                        this.setState({
                                            dt_roles: manager
                                        })
                                    } else if (role === 'Admin Provinsi') {
                                        const manager = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[8], res.data[9], res.data[11], res.data[12], res.data[13]]
                                        this.setState({
                                            dt_roles: manager
                                        })
                                    } else {
                                        this.setState({
                                            dt_roles: res.data
                                        })
                                    }

                                    const x = this.state.dt_roles.filter(el => el.id === res_role.data.roleid)

                                    const obj = { value: x[0].id, label: x[0].roleName }

                                    this.handleSelectRole(obj)




                                })
                            }
                        })

                    const res_tingkatWilayah = res
                    authServices.get("/roles/wilayah?periodeSensus=" + this.state.value_priode)
                        .then((res) => {
                            if (res.status === 200) {
                                this.setState({
                                    dt_wilayah: res.data
                                }, () => {

                                    const y = this.state.dt_wilayah.filter(el => el.id === res_tingkatWilayah.data.tingkatwilayahid)
                                    let obj = { value: y[0].id, label: y[0].tingkatWilayah }
                                    if (res_tingkatWilayah.data.roleid === 10) {
                                        obj = { value: 4, label: 'Kelurahan' }
                                    }
                                    this.handleSelectTingkatWilayah(obj)
                                })
                            }
                        })
                        .catch((error) => {
                            this.setState({ blocking: false });

                        });



                    let dataWilayahRes = res.data.userWilayah
                    dataWilayahRes.forEach(el => el.wilayahNew = false)

                    this.setState(
                        {
                            datauser: res.data,
                            username: res.data.username,
                            nik: res.data.nik,
                            nip: res.data.nip,
                            email: res.data.email,
                            namaLengkap: res.data.namalengkap,
                            alamat: res.data.alamat,
                            namaIbuKandung: res.data.namaIbuKandung,
                            npwp: res.data.npwp,
                            statusNpwp: res.data.status_npwp,
                            smartphone: res.data.smartphone,
                            // role: res.data.roleid,
                            noTelepon: (res.data.notelepon == null) ? 0 : res.data.notelepon,
                            password: 'Bkbn@123456',
                            wilayahUser: (res.data.userWilayah === null) ? [] : dataWilayahRes,
                            tempWilayahUser: (res.data.userWilayah === null) ? [] : res.data.userWilayah,
                            statusPekerjaan: [{
                                value: res.data.statusPekerjaan,
                                label: res.data.statusPekerjaan
                            }],
                            statusPekerjaannya: res.data.statusPekerjaan,
                            valueGolongan: [{
                                value: res.data.golongan,
                                label: (res.data.golongan == 1) ? 'Golongan I' :
                                    (res.data.golongan == 2) ? 'Golongan II' :
                                        (res.data.golongan == 3) ? 'Golongan III' :
                                            (res.data.golongan == 4) ? 'Golongan IV' :
                                                'Belum Memilih Golongan',
                            }],
                            valueGolongannya: res.data.golongan,
                            no_rekening: res.data.no_rekening,
                            tanggal_lahir: moment(res.data.tanggal_lahir).format("YYYY-MM-DD"),
                            tempat_lahir: res.data.tempat_lahir,
                            kode_pos: res.data.kode_pos,
                            rw2: res.data.alamat_rw,
                            bank: bank_coba,
                            rt2: res.data.alamat_rt,
                            jenis_kelamin: res.data.jenis_kelamin,
                            status_pembayaran: res.data.status_pembayaran,

                            //lock wilayah
                            lockWilayah: res.data.lockWilayah
                        }, () => {
                            this.setProv2();
                            this.setKab2(parseInt(res.data.alamat_provinsi));
                            this.setKec2(parseInt(res.data.alamat_kabupaten));
                            this.setDes2(parseInt(res.data.alamat_kecamatan));
                        }
                    )
                }
            })
            .catch((error) => {
                this.setState({ blocking: false });
                // 
                Swal.fire(
                    'Gagal Get Data !',
                    'Cek API Terlebih Dahulu.',
                    'error'
                )
                return false
            });

    }


    componentDidMount = () => {
        setTimeout(() => {
            this.getBank();
        }, 500);

        setTimeout(() => {
            this.getRole();
        }, 1000);

        setTimeout(() => {
            this.getData();
        }, 1500);

        setTimeout(() => {
            this.getTingkatWilayah();
        }, 2000);
        if (this.state.role) {
            this.setState({
                tampil: 'visible'
            })
        } else {
            this.setState({
                tampil: 'none'
            })
        }
        const rest = JSON.parse(localStorage.getItem("authority"));
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
            const nm = rest[0].attributes.user.TingkatWilayahID
            const lastModifiedBy = rest[0].attributes.user_name
            this.setState({
                lastModifiedBy: lastModifiedBy
            })
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                        // this.setProv2(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }

                if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                    // if(this.state.datauser?.alamat_provinsi === undefined){
                    //     this.setProv2(null)
                    // }else{
                    //     this.setProv2(this.state.datauser.alamat_provinsi)
                    // }
                }
                if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    // if(this.state.datauser?.alamat_provinsi === undefined || this.state.datauser?.alamat_kabupaten === undefined){
                    //     this.setKab2(null,null)
                    // }else{
                    //     this.setKab2(this.state.datauser.alamat_provinsi,this.state.datauser.alamat_kabupaten)
                    // }
                }
                if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                    // if(this.state.datauser?.alamat_provinsi === undefined || this.state.datauser?.alamat_kabupaten === undefined || this.state.datauser?.alamat_kecamatan === undefined) {
                    //     this.setKec2(null, null)
                    // }else{
                    //     this.setKec2(this.state.datauser.alamat_kabupaten, this.state.datauser.alamat_kecamatan)
                    // }
                }
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                    // if(this.state.datauser?.alamat_provinsi === undefined || this.state.datauser?.alamat_kabupaten === undefined || this.state.datauser?.alamat_kecamatan === undefined || this.state.datauser?.alamat_kelurahan == undefined) {
                    //     this.setDes2(null, null)
                    // }else{
                    //     this.setDes2(this.state.datauser.alamat_kecamatan, this.state.datauser.alamat_kelurahan)
                    // }
                }
            }
        } else {
            this.setProv(null);
            // this.setProv2(null);
        }

        console.log('data edit >>', this.props.dataEdit)
        // var data = this.props.detailData
    }

    componentDidUpdate = () => {
        // if(this.state.namaProv === ''){
        //     const  data = JSON.parse(this.props.lihat)
        //     this.setProv(data.provinsiId, data.kabupatenId, data.kecamatanId, data.kelurahanId)
        // }

        if (this.state.dt_prov2.length > 0 && this.state.flagProv === true) {
            const newdatanya = this.state.dt_prov2.filter(item => item.value === parseInt(this.state.datauser.alamat_provinsi))
            this.setState({
                provinsi2: newdatanya.length > 0 ? newdatanya[0] : null, flagProv: false
            })
        }
        if (this.state.dt_kab2.length > 0 && this.state.flagKab === true) {
            const newdatanya = this.state.dt_kab2.filter(item => item.value === parseInt(this.state.datauser.alamat_kabupaten))
            this.setState({
                kabupaten2: newdatanya.length > 0 ? newdatanya[0] : null, flagKab: false
            })
        }
        if (this.state.dt_kec2.length > 0 && this.state.flagKec === true) {
            const newdatanya = this.state.dt_kec2.filter(item => item.value === parseInt(this.state.datauser.alamat_kecamatan))
            this.setState({ kecamatan2: newdatanya.length > 0 ? newdatanya[0] : null, flagKec: false })
        }
        if (this.state.dt_des2.length > 0 && this.state.flagDes === true) {
            const newdatanya = this.state.dt_des2.filter(item => item.value === parseInt(this.state.datauser.alamat_kelurahan))
            this.setState({ desa2: newdatanya.length > 0 ? newdatanya[0] : null, flagDes: false })
        }
    }

    //API Wilayah
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            // 
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            // 
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            // 
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        // 
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: false, dis_des: false, dis_rw: false, dis_rt: false
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: false, dis_kec: false, dis_des: false, dis_rw: false, dis_rt: false,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {},
            }
                ,
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: false, dis_rw: false, dis_rt: false
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: false, dis_des: false, dis_rw: false, dis_rt: false,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: false, dis_rt: false
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: false, dis_rw: false, dis_rt: false,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: false, dis_rt: false,
                id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                rt: null, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah
    hapus = (e, row) => {
        let dataDelete = { ...row, status_data: 'delete', 'createdby': this.state.lastModifiedBy, }
        this.setState({
            blocking: false,
            wilayahUser: this.state.wilayahUser.filter(item => item !== row)
        }, () => this.setState({
            wilayahUser: [...this.state.wilayahUser, dataDelete]
        })
        );

    }

    resetWilayah = (e) => {
        let newData = []
        this.state.wilayahUser.forEach(item => {
            const obj = {
                'id': this.state.nomer,
                'id_provinsi': (item.id_provinsi == null ? 0 : item.id_provinsi),
                'nama_provinsi': (item.nama_provinsi == null ? '-' : item.id_provinsi),
                'id_kabupaten': (item.id_kabupaten == null ? 0 : item.id_kabupaten),
                'nama_kabupaten': (item.nama_kabupaten == null ? '-' : item.id_kabupaten),
                'id_kecamatan': (item.id_kecamatan == null ? 0 : item.id_kecamatan),
                'nama_kecamatan': (item.nama_kecamatan == null ? '-' : item.nama_kecamatan),
                'id_kelurahan': (item.id_kelurahan == null ? 0 : item.id_kelurahan),
                'nama_kelurahan': (item.nama_kelurahan == null ? '-' : item.id_kelurahan),
                'id_rt': (item.id_rt == null ? 0 : item.id_rt),
                'nama_rt': (item.nama_rt == null ? '-' : item.kodeDepdagri + ' - ' + item.nama_rt),
                'nama_rw': (item.nama_rw == null ? '-' : item.kodeDepdagri + ' - ' + item.nama_rw),
                'id_rw': (item.id_rw == null ? 0 : item.id_rw),
                'status_data': 'delete'
            };
            newData.push(obj);
        })
        this.setState({
            wilayahUser: newData
        });
    }

    handleSimpanWilayah = (e) => {
        this.setState({
            disSave: false,
            // lockWilayah: false
        })
        if (this.state.role.value === 10) {
            const newData = []
            if (this.state.desa === null) {
                Swal.fire(
                    'Wilayah Kelurahan Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            }
            APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + (this.state.desa.value === null ? 0 : this.state.desa.value) + '&periodeSensus=' + this.state.value_priode)
                .then(response => {
                    response.data.forEach(elRw => {
                        APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + elRw.id_rw + '&periodeSensus=' + this.state.value_priode)
                            .then(responseRt => {
                                responseRt.data.forEach(elRt => {
                                    const obj = {
                                        'id': this.state.nomer,
                                        'id_provinsi': (this.state.provinsi == null ? 0 : this.state.provinsi.value),
                                        'nama_provinsi': (this.state.provinsi == null ? '-' : this.state.provinsi.label),
                                        'id_kabupaten': (this.state.kabupaten == null ? 0 : this.state.kabupaten.value),
                                        'nama_kabupaten': (this.state.kabupaten == null ? '-' : this.state.kabupaten.label),
                                        'id_kecamatan': (this.state.kecamatan == null ? 0 : this.state.kecamatan.value),
                                        'nama_kecamatan': (this.state.kecamatan == null ? '-' : this.state.kecamatan.label),
                                        'id_kelurahan': (this.state.desa == null ? 0 : this.state.desa.value),
                                        'nama_kelurahan': (this.state.desa == null ? '-' : this.state.desa.label),
                                        'id_rt': (elRt.id_rt == null ? 0 : elRt.id_rt),
                                        'nama_rt': (elRt.nama_rt == null ? '-' : elRt.kodeDepdagri + ' - ' + elRt.nama_rt),
                                        'nama_rw': (elRw.nama_rw == null ? '-' : elRt.kodeDepdagri + ' - ' + elRw.nama_rw),
                                        'id_rw': (elRt.id_rw == null ? 0 : elRt.id_rw),
                                        'createdby': this.state.lastModifiedBy,
                                        'status_data': 'insert'
                                    };
                                    newData.push(obj);
                                })
                                this.setState({
                                    // wilayahUser:  [...this.state.wilayahUser,...newData]
                                    wilayahUser: newData
                                });
                            })
                    })

                })
        } else {
            const newData = this.state.wilayahUser
            // Buat nambah kebawah
            // const newData = []
            const obj = {
                //bikin object label
                id: this.state.nomer,
                id_provinsi: (this.state.provinsi == null ? 0 : this.state.provinsi.value),
                nama_provinsi: (this.state.provinsi == null ? '-' : this.state.provinsi.label),
                id_kabupaten: (this.state.kabupaten == null ? 0 : this.state.kabupaten.value),
                nama_kabupaten: (this.state.kabupaten == null ? '-' : this.state.kabupaten.label),
                id_kecamatan: (this.state.kecamatan == null ? 0 : this.state.kecamatan.value),
                nama_kecamatan: (this.state.kecamatan == null ? '-' : this.state.kecamatan.label),
                id_kelurahan: (this.state.desa == null ? 0 : this.state.desa.value),
                nama_kelurahan: (this.state.desa == null ? '-' : this.state.desa.label),
                id_rt: (this.state.rt == null ? 0 : this.state.rt.value),
                nama_rt: (this.state.rt == null ? '-' : this.state.rt.label),
                id_rw: (this.state.rw == null ? 0 : this.state.rw.value),
                nama_rw: (this.state.rw == null ? '-' : this.state.rw.label),
                createdby: this.state.lastModifiedBy,
                status_data: 'insert',
                wilayahNew: true
            };

            if (this.state.tk.value === 1 && this.state.provinsi === null) {
                Swal.fire(
                    'Wilayah Provinsi Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 2 && this.state.kabupaten === null) {
                Swal.fire(
                    'Wilayah Kabupaten Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 3 && this.state.kecamatan === null) {
                Swal.fire(
                    'Wilayah Kecamatan Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 4 && this.state.desa === null) {
                Swal.fire(
                    'Wilayah Kelurahan Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 5 && this.state.rw === null) {
                Swal.fire(
                    'Wilayah RW Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 6 && this.state.rt === null) {
                Swal.fire(
                    'Wilayah RT Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else {
                if (this.state.tk.value === 6) {
                    const dataSelect = newData.find(el => el.id_kelurahan === this.state.desa.value)
                    if (this.state.wilayahUser.length === 0 || dataSelect !== undefined) {
                        const dataRt = newData.find(el => el.id_rt === this.state.rt.value)
                        if (dataRt === undefined) {
                            newData.push(obj);
                        } else {
                            Swal.fire(
                                'Wilayah RT Telah Di Pilih !',
                                'Mohon Ganti RT Untuk Melanjutkan',
                                'info'
                            )
                        }
                    } else {
                        Swal.fire(
                            'Pendata Tidak Boleh Beda Kelurahan!',
                            'Wilayah Pendata Hanya Boleh Di Kelurahan Yang Sama.',
                            'info'
                        )
                    }
                } else {
                    const dataSelect = newData.find(el => el.id_provinsi === this.state.provinsi.value)
                    if (dataSelect === undefined) {
                        newData.push(obj);
                    } else {
                        Swal.fire(
                            'Role Ini Hanya Boleh Memilih 1 Wilayah !',
                            '',
                            'info'
                        )
                    }
                }
            }
            this.setState({
                wilayahUser: newData,
                nomer: this.state.nomer++
            }, () => console.log('wilayah user >>', this.state.wilayahUser));
        }
    }

    handleToggle = (e) => {
        if (e.target.checked == true) {
            this.setState({
                smartphone: true
            })
        } else {
            this.setState({
                smartphone: false
            })
        }
    };

    setProv2 = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData2.push(obj);
                    });

                    this.setState({ dt_prov2: newData2 },
                        () => {
                            if (id !== null && this.state.auto_prov2 === true) {
                                const vl = this.state.dt_prov2.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov2(vl[0])

                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab2(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData2.push(obj);
                });
                this.setState({ dt_kab2: newData2, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab2 === true) {
                            const vl = this.state.dt_kab2.filter(item => parseInt(item.value) === parseInt(idKab))
                            // 
                            this.sel_kab2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec2(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData2.push(obj);
                });
                this.setState({ dt_kec2: newData2, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec2 === true) {
                            const vl = this.state.dt_kec2.filter(item => parseInt(item.value) === parseInt(idKec))

                            this.sel_kec2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes2(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData2.push(obj);
                });
                this.setState({ dt_des2: newData2, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des2 === true) {
                            const vl = this.state.dt_des2.filter(item => parseInt(item.value) === parseInt(idDes))
                            // 
                            this.sel_des2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov2 = (e) => {
        if (e) {
            this.setState({
                provinsi2: e, id_provinsi2: e.value, depdagriProv2: e.kode,
                kabupaten2: null, kecamatan2: null, desa2: null, dis_kab2: false, dis_kec2: true, dis_des2: true, dis_rw2: true, dis_rt2: true,
            },
                () => {
                    if (this.state.id_provinsi2) {
                        this.setKab2(this.state.id_provinsi2);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                provinsi2: null, kabupaten2: null, kecamatan2: null, desa2: null,
                dis_kab2: true, dis_kec2: true, dis_des2: true, dis_rw: true, dis_rt: true,
                id_provinsi2: '', id_kabupaten2: '', id_kecamatan: '', id_des2: '', id_rw2: '', id_rt2: ''
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab2 = (e) => {
        if (e) {
            this.setState({
                kabupaten2: e, id_kabupaten2: e.value, depdagriKab2: e.kode,
                kecamatan2: null, desa2: null,
                dis_kec2: false, dis_des2: true, dis_rw2: true, dis_rt2: true,
            },
                () => {
                    if (this.state.id_kabupaten2) {
                        this.setKec2(this.state.id_kabupaten2);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                kabupaten2: null, kecamatan2: null, desa2: null,
                dis_kec2: true, dis_des2: true,
                id_kabupaten2: '', id_kecamatan2: '', id_des2: '', id_rw2: '', id_rt2: ''
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec2 = (e) => {
        if (e) {
            this.setState({
                kecamatan2: e, id_kecamatan2: e.value, desa2: null,
                dis_des2: false, dis_rw2: true, dis_rt2: true,
            },
                () => {
                    if (this.state.id_kecamatan2) {
                        this.setDes2(this.state.id_kecamatan2);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                kecamatan2: null, desa2: null,
                dis_des2: true, dis_rw2: true, dis_rt2: true,
                id_kecamatan2: '', id_des2: '', id_rw2: '', id_rt2: ''
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des2 = (e) => {
        // 
        if (e) {
            this.setState({
                desa2: e, id_des2: e.value
            },
                () => {
                    if (this.state.id_des2) {
                        // this.setRW(this.state.id_des);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                desa2: null,
                id_des2: ''
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }


    render() {

        const isButtonDeleteDisabled = this.state.lockWilayah === true
            ? true
            : (this.state.status_pembayaran === true || (this.state.disstatus === 10 && this.state.status_pembayaran === false))
                ? true
                : false;
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                <div className='content'>
                    <div className='title-content'><ImPlay3 className='icon-brd' />Edit User</div>
                    <div className='body-content'>
                        <Container>
                            <Box
                                sx={{
                                    width: "100%"
                                }}
                                m={3}>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div >
                                            <FaUser />&nbsp; Username <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            disabled
                                            name="username"
                                            variant="outlined"
                                            type="text"
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            value={this.state.username}
                                            onChange={this.handleChangeUsername} />
                                        <FormControl style={{ display: this.state.captionPendata }}>
                                            <FormHelperText id="component-helper-text">
                                                Username Pendata akan diberikan prefix <a style={{ fontWeight: 'bold' }}>p.KodeProvinsi.KodeKabupaten.KodeKecamatan.KodeKelurahan</a>
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <BsPersonCheckFill />&nbsp; NIK <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            name="nik"
                                            inputProps={
                                                { maxLength: 16 }
                                            }
                                            variant="outlined"
                                            value={this.state.nik}
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeForm} />
                                    </Grid>
                                </Grid>

                                <p></p>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <AiOutlineIdcard />&nbsp; Nama Lengkap <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            name="namaLengkap"
                                            value={this.state.namaLengkap}
                                            variant="outlined"
                                            type="text"
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeForm} />
                                    </Grid>
                                    {/* <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <AiOutlineIdcard />&nbsp; Ibu Kandung
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="namaIbuKandung"
                                        value={this.state.namaIbuKandung}
                                        variant="outlined"
                                        type="text"
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid> */}
                                    <Grid
                                        item
                                        xs={2}

                                    >
                                        <div>
                                            <ImManWoman />
                                            &nbsp; Jenis Kelamin <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>

                                    <Grid
                                        item xs={4}
                                    >
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="jenis_kelamin"
                                                onChange={this.handleChangeKelamin}
                                                value={this.state.jenis_kelamin}
                                            >
                                                <FormControlLabel value="1" control={<Radio />} label="Laki-Laki" />
                                                <FormControlLabel value="2" control={<Radio />} label="Perempuan" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <p></p>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImCalendar />
                                            &nbsp; Tanggal Lahir <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField name='tanggal_lahir' onChange={this.sel_tanggal_lahir} value={this.state.tanggal_lahir} inputProps={{ style: { height: "6px" }, max: moment(new Date()).format("YYYY-MM-DD") }} type="date" id="outlined-basic" isClearable />
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div >
                                            <ImLocation />&nbsp; Tempat Lahir <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            name="tempat_lahir"
                                            variant="outlined"
                                            value={this.state.tempat_lahir}
                                            type="text"
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeTempatLahir}
                                            onPaste={(e) => { e.preventDefault(); return false; }} />
                                    </Grid>
                                </Grid>
                                <p></p>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div >
                                            <MdAlternateEmail />&nbsp; Email <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            name="email"
                                            variant="outlined"
                                            value={this.state.email}
                                            type="email"
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeEmail} />
                                    </Grid>
                                    <p></p>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImPhone />
                                            &nbsp; No. Telepon
                                        </div>

                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            inputProps={
                                                { maxLength: 13 }
                                            }
                                            name="noTelepon"
                                            variant="outlined"
                                            value={this.state.noTelepon}
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeForm} />
                                    </Grid>
                                </Grid>
                                <p></p>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <AiOutlineIdcard />&nbsp; Nama Bank <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            <Select options={this.state.optBank?.map(({ kode, bank }) => ({ value: kode, label: bank }))} isClearable onChange={this.sel_bank} value={this.state.bank} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImListNumbered />&nbsp; No Rekening <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            name="no_rekening"
                                            variant="outlined"
                                            style={{ width: '100%', bottom: '5px' }}
                                            value={this.state.no_rekening}
                                            size="small"
                                            onChange={this.handleChangeForm}
                                            inputProps={{ maxLength: 18 }}
                                            disabled={false}
                                        />

                                    </Grid>
                                </Grid>
                                <p></p>
                                <fieldset>
                                    <legend>ALAMAT (HARUS SESUAI KTP)</legend>
                                    <p></p>
                                    <Grid
                                        container
                                        rowSpacing={3}
                                        columnSpacing={{
                                            xs: 1,
                                            sm: 2,
                                            md: 3
                                        }}>

                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <ImLocation />&nbsp; Alamat <a style={{ color: 'red' }}>*</a>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                required
                                                inputProps={{
                                                    maxLength: 30,
                                                }}
                                                name="alamat"
                                                value={this.state.alamat}
                                                variant="outlined"
                                                type="text"
                                                style
                                                ={{
                                                    width: '100%'
                                                }}
                                                size="small"
                                                onChange={this.handleChangeAlamat}
                                                onPaste={(e) => { e.preventDefault(); return false; }} />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <ImListNumbered />
                                                &nbsp; Kode POS <a style={{ color: 'red' }}>*</a>
                                            </div>

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                required
                                                inputProps={
                                                    { maxLength: 5 }
                                                }
                                                name="kode_pos"
                                                variant="outlined"
                                                value={this.state.kode_pos}
                                                style
                                                ={{
                                                    width: '100%'
                                                }}
                                                size="small"
                                                onBlur={(e) => {
                                                    const value = e.target.value;
                                                    if (value.length < 5) {
                                                        Swal.fire(
                                                            'Info!',
                                                            'Mohon isi kode pos minimal 5 angka, sesuai dengan kode pos daerah.',
                                                            'info'
                                                        );
                                                    }
                                                }}
                                                onChange={this.handleChangeForm} />
                                        </Grid>
                                    </Grid>
                                    <p></p>
                                    <Grid
                                        container
                                        rowSpacing={3}
                                        columnSpacing={{
                                            xs: 1,
                                            sm: 2,
                                            md: 3
                                        }}>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <ImLocation />
                                                &nbsp; Provinsi <a style={{ color: 'red' }}>*</a>
                                            </div>

                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small" >
                                                <Select isDisabled={this.state.dis_prov2} options={this.state.dt_prov} id="prov2" name="provinsi2" onChange={this.sel_prov2} value={this.state.provinsi2} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <ImLocation />
                                                &nbsp; Kabupaten <a style={{ color: 'red' }}>*</a>
                                            </div>

                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select options={this.state.dt_kab2} isClearable onChange={this.sel_kab2} value={this.state.kabupaten2} placeholder="Cari..." isDisabled={this.state.dis_kab2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <p></p>

                                    <Grid
                                        container
                                        rowSpacing={3}
                                        columnSpacing={{
                                            xs: 1,
                                            sm: 2,
                                            md: 3
                                        }}>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <ImLocation />
                                                &nbsp; Kecamatan <a style={{ color: 'red' }}>*</a>
                                            </div>

                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select options={this.state.dt_kec2} isClearable onChange={this.sel_kec2} value={this.state.kecamatan2} placeholder="Cari..." isDisabled={this.state.dis_kec2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <ImLocation />
                                                &nbsp; Kelurahan <a style={{ color: 'red' }}>*</a>
                                            </div>

                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select options={this.state.dt_des2} isClearable onChange={this.sel_des2} value={this.state.desa2} placeholder="Cari..." isDisabled={this.state.dis_des2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <p></p>

                                    <Grid
                                        container
                                        rowSpacing={3}
                                        columnSpacing={{
                                            xs: 1,
                                            sm: 2,
                                            md: 3
                                        }}>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <ImLocation />
                                                &nbsp; RW <a style={{ color: 'red' }}>*</a>
                                            </div>

                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            <TextField
                                                required
                                                inputProps={
                                                    { maxLength: 10 }
                                                }
                                                name="rw2"
                                                variant="outlined"
                                                value={this.state.rw2}
                                                style
                                                ={{
                                                    width: '100%'
                                                }}
                                                size="small"
                                                onChange={this.handleChangeRW}
                                                onPaste={(e) => { e.preventDefault(); return false; }} />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <ImLocation />
                                                &nbsp; RT <a style={{ color: 'red' }}>*</a>
                                            </div>

                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            <TextField
                                                required
                                                inputProps={
                                                    { maxLength: 10 }
                                                }
                                                name="rt2"
                                                variant="outlined"
                                                value={this.state.rt2}
                                                style
                                                ={{
                                                    width: '100%'
                                                }}
                                                size="small"
                                                onChange={this.handleChangeRT}
                                                onPaste={(e) => { e.preventDefault(); return false; }} />
                                        </Grid>
                                    </Grid>
                                    <p></p>
                                </fieldset>
                                <p></p>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <BsPersonCheck />
                                            &nbsp; Status <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>

                                    <Grid item xs={4}>
                                        <Select
                                            name="status"
                                            value={this.state.statusPekerjaan}
                                            options={this.state.dataStatus}
                                            onChange={this.handleSelectStatus}
                                        />
                                    </Grid>
                                    {(this.state.statusPekerjaannya === 'PNS') ?
                                        <>
                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    display: 'inline-block'
                                                }}>
                                                <div>
                                                    <BsPersonCheck />
                                                    &nbsp; Golongan
                                                </div>

                                            </Grid>

                                            <Grid item xs={4}>
                                                <Select
                                                    name="status"
                                                    value={this.state.valueGolongan}
                                                    options={this.state.golongStatus}
                                                    onChange={this.handleSelectGolongan}
                                                />
                                            </Grid>
                                        </>
                                        :
                                        <></>

                                    }

                                    {(this.state.statusPekerjaannya === "PNS" || this.state.statusPekerjaannya === "PPPK") ?
                                        <>
                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    display: 'inline-block'
                                                }}>
                                                <div>
                                                    <BsPersonCheck />&nbsp; NIP
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    required
                                                    inputProps={{
                                                        maxLength: 18,
                                                    }}
                                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                                    name="nip"
                                                    variant="outlined"
                                                    value={this.state.nip}
                                                    style
                                                    ={{
                                                        width: '100%'
                                                    }}
                                                    size="small"
                                                    onChange={this.handleChangeForm} />
                                            </Grid>
                                        </>
                                        :
                                        <></>
                                    }
                                </Grid>
                                <p></p>

                                <FormControlLabel control={<Checkbox
                                    onChange={this.handleChangeNpwp}
                                    checked={this.state.statusNpwp === true ? true : false}
                                />} label="Apakah Anda Memiliki NPWP ?" />
                                <p></p>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block',
                                            display: this.state.statusNpwp === true ? false : 'none'
                                        }}>
                                        <div>
                                            <AiOutlineIdcard />&nbsp; NPWP
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            required

                                            name="npwp"
                                            value={this.state.npwp}
                                            variant="outlined"
                                            style
                                            ={{
                                                width: '100%',
                                                display: this.state.statusNpwp === true ? false : 'none'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeForm}
                                            inputProps={{ maxLength: 16 }} />
                                    </Grid>
                                </Grid>

                                <Divider />
                                <p></p>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div >
                                            <BsGearWideConnected />&nbsp; Role <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl
                                            sx={{
                                                width: '100%'
                                            }}
                                            size="small">
                                            <Select
                                                isDisabled
                                                options={this.state.dt_roles.map(({ id, roleName }) => ({ value: id, label: roleName }))}
                                                isClearable
                                                onChange={this.handleSelectRole}
                                                value={this.state.role}
                                                placeholder="Cari..."
                                                styles={{
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <BsPersonCheckFill />&nbsp; Tingkat Wilayah <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl
                                            sx={{
                                                width: '100%'
                                            }}
                                            size="small">
                                            <Select
                                                isDisabled
                                                id='selectWil'
                                                options={this.state.dt_wilayah.map(({ id, tingkatWilayah }) => ({ value: id, label: tingkatWilayah }))}
                                                isClearable
                                                onChange={this.handleSelectTingkatWilayah}
                                                value={this.state.tingkat_wilayah}
                                                placeholder="Cari..."
                                                styles={{
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: this.state.smartphoneInput
                                        }}
                                    >
                                        <div>
                                            <ImPhone />
                                            &nbsp; Metode Pendataan Smartphone
                                        </div>
                                    </Grid>
                                    <p></p>
                                    <Grid
                                        item xs={4}
                                        style={{
                                            display: this.state.smartphoneInput
                                        }}
                                    >
                                        <FormGroup>
                                            <FormControlLabel control={<Switch
                                                onChange={this.handleToggle}
                                                value={this.state.smartphone}
                                                name='smartphoneCheck'
                                                disabled={this.state.status_pembayaran === true ? true : false}
                                                checked={this.state.smartphone === true ? true : false}
                                            />}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <p></p>
                                {/* State buat hide*/}
                                <Grid item xs={12} md={12}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                        <Grid style={{ display: this.state.hideProvinsi }} item xs={12} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small" >
                                                Provinsi
                                                <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>
                                        <Grid style={{ display: this.state.hideKabupaten }} item xs={12} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                Kabupaten
                                                <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>
                                        <Grid style={{ display: this.state.hideKecamatan }} item xs={12} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                Kecamatan
                                                <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>
                                        <Grid style={{ display: this.state.hideKelurahan }} item xs={12} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                Kelurahan
                                                <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>
                                        <Grid style={{ display: this.state.hideRW }} item xs={12} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                RW
                                                <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>
                                        <Grid style={{ display: this.state.hideRT }} item xs={12} md={4}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                RT
                                                <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <p></p>
                                </Grid>
                                {/* <Grid style={{display: this.state.hideInpuWilayah }}> */}
                                <Grid>

                                    <Button disabled={this.state.status_pembayaran === true ? true : false} onClick={this.handleSimpanWilayah} sx={{ marginRight: '5px' }} variant="contained"><ImMap2 className='icon-brd' style={{ marginRight: '5px' }} /> Tambah Wilayah</Button>

                                    {/* <Button onClick={this.resetWilayah} color='warning' variant="contained"><ImLoop2 className='icon-brd' style={{ marginRight: '5px' }} /> Reset Wilayah</Button> */}
                                    <p></p>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Provinsi</TableCell>
                                                    <TableCell>Kabupaten</TableCell>
                                                    <TableCell>Kecamatan</TableCell>
                                                    <TableCell>Kelurahan</TableCell>
                                                    <TableCell>RW</TableCell>
                                                    <TableCell>RT</TableCell>
                                                    <TableCell>Aksi</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/* Request nama wilayah ke BE */}

                                                {this.state.wilayahUser.map((row) => (
                                                    row.status_data == "delete" ?
                                                        <div></div>
                                                        : <TableRow
                                                            key={this.state.nomer++}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">{row.nama_provinsi}</TableCell>
                                                            <TableCell align="left">{row.nama_kabupaten}</TableCell>
                                                            <TableCell align="left">{row.nama_kecamatan}</TableCell>
                                                            <TableCell align="left">{row.nama_kelurahan}</TableCell>
                                                            <TableCell align="left">{row.nama_rw}</TableCell>
                                                            <TableCell align="left">{row.nama_rt}</TableCell>
                                                            <TableCell align="left">
                                                                {this.props.dataEdit.roleId == 5 ? //khusus pendata
                                                                    <>
                                                                        <Button disabled={isButtonDeleteDisabled == true && row.wilayahNew == false} onClick={(e) => this.hapus(e, row)} className='btnTable' color='warning' variant="contained"><ImBin className='icon-brd' /></Button>
                                                                        {(isButtonDeleteDisabled == true && row.wilayahNew == false) &&
                                                                            <label style={{ color: 'red' }}><b>Wilayah dilock</b></label>}
                                                                    </>

                                                                    :
                                                                    <Button disabled={(this.state.status_pembayaran === true) || (this.state.disstatus === 10 && this.state.status_pembayaran === false) ? true : false} onClick={(e) => this.hapus(e, row)} className='btnTable' color='warning' variant="contained"><ImBin className='icon-brd' /></Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <p></p>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Checkbox
                                        onChange={this.handleChangeValidasi}
                                    />} label="Data yang dimasukkan sudah benar dan dapat dipertanggung jawabkan" /><br></br>
                                    <Button disabled={this.state.disButtonSave} onClick={this.handleSaveProfil} sx={{ marginRight: '5px' }} variant="contained"><ImFloppyDisk className='icon-brd' style={{ marginRight: '5px' }} /> Simpan</Button>
                                    &nbsp;
                                    <Button disabled={this.state.disButtonSave} onClick={(e) => this.props.handleOpenCloseEditUser(e, {})} sx={{ marginRight: '5px' }} color='error' variant="contained"><ImCross className='icon-brd' style={{ marginRight: '5px' }} /> Batal</Button>
                                </Grid>
                            </Box>
                        </Container>
                    </div>
                </div>
            </BlockUi >
        )
    }
}

export default EditUser;