import React, { Component } from "react";
import { Select as MuiSelect, TextField, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, TableCell, Input } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIUpload from '../../../services/APIUpload';
import APIDashboard from "../../../services/APIDashboard";
import Swal from 'sweetalert2';
import { HiOutlineDocumentText } from "react-icons/hi";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
//import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';

import { ImSearch, ImBin, ImCross, ImFloppyDisk, ImLoop2, ImMap, ImMap2, ImPlay3, ImPlus, ImDownload2, ImDownload, ImPrinter, ImCheckmark } from 'react-icons/im';
import { BsPencilSquare,BsCheckCircleFill } from "react-icons/bs";
import { GiPadlockOpen, GiPadlock } from "react-icons/gi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import APIReport from '../../../services/APIReport';
import download from 'downloadjs'
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import APIButton from "../../../services/APIButtonAkses";
import APIPemutakhiran from "../../../services/APIPemutakhiran";

const isNumber = (str) => /^[0-9]*$/.test(str);

class monitoringSK extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panel1: true,
            sizePerPage: 10,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],
            // defaultData: [{ 'nama_rw': 'RW001', 'nama_rt': 'RT011', 'entri': 'Sukses', 'ada': '70', 'didata': '69', 'ditemui': '67', 'baru': '2', 'pindah': '2', 'meninggaldunia': '0', 'tidakditemukan': '1', 'cerai': '0' },],
            roleId: 0,
            persen: 0,
            datas: [],
            dt_metode: [{ value: 1, label: 'CAPI (SmartPhone)', name: 'CAPI' }, { value: 2, label: 'PAPI', name: 'PAPI' }],
            dt_aduan: [{ value: 1, label: 'Nominal Tidak Sesuai', name: 'Nominal Tidak Sesuai' }, { value: 2, label: 'Nama Lengkap Tidak Sesuai', name: 'Nama Lengkap Tidak Sesuai' }],
            selectedAduan: 0,
            aduan: [],
            dt_status: [{ value: 1, label: 'Sudah Ditangani', name: 'Sudah Ditangani' }, { value: 2, label: 'Belum Ditangani', name: 'Belum Ditangani' }],
            selectedStatus: 0,
            status: [],

            filterMetode: [], selectedMetode: null,
            totalupload: 0,
            belumupload: 0,
            selectedRows: [],

            //Wilayah
            dt_prov: [], provinsi: null, id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: null, id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: null, id_rw: 0, dis_rw: true,
            dt_rt: [], rt: null, rtID: '', id_rt: 0, dis_rt: true,
            roleUserLogin: localStorage.getItem("authority") ? JSON.parse(localStorage.getItem("authority"))[0].attributes.user.userName : null,
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    componentDidMount = () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
            roleUser: rest[0].attributes.user.roleID
        })
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({
                    flagSearch: false,
                    roleUser: rest[0].attributes.role
                },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }

                if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                }
                if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    //API Wilayah
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            // 
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            // 
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            // 
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        // 
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah
    sel_metode = (e) => {
        if (e) {
            this.setState({
                filterMetode: e, selectedMetode: e.name,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterMetode: null, selectedMetode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleSearch = () => {
        if(this.state.id_provinsi === 0) {
            Swal.fire({
                title: 'Info!',
                icon: 'warning',
                text: 'Harap Pilih Provinsi',
            })
        }else{
        this.setState({ blocking: true })
        APIButton.get("/aktif/getTombolAktifByProvIdKabId?periodeSensus=" + this.state.value_priode + "&kabupatenId=" + this.state.id_kabupaten + "&provinsiId=" + this.state.id_provinsi + "&modulPembayaran=PK" + '&periodeSensus=2024')
            .then((res) => {
                this.setState({ blocking: false });
                if (res.status === 200) {
                    this.setState(
                        {
                            datas: res.data,
                        }

                    )
                    
                }

            })
            .catch((error) => {
                this.setState({ blocking: false });
                
            });
        }
    }


    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    buttonFormatter = (cell, row) => {
        if (row.ButtonStatus === false) {


            return <div className='divBtnTable'>

                <Tooltip title="Update Status"><Button id={row.id} onClick={(e) => this.handleupdate(e, row)} className='btnTable' variant="outlined"><GiPadlockOpen style={{ color: 'red' }} /></Button></Tooltip>

            </div>
        } else {
            return <div className='divBtnTable'>

                <Tooltip title="Update Status"><Button id={row.id} onClick={(e) => this.handleupdate(e, row)} className='btnTable' variant="outlined"><GiPadlock style={{ color: 'red' }} /></Button></Tooltip>

            </div>
        }

    }

    createdFormatAduan = (cell, row) => {
        if (row.ButtonStatus === true) {
            return 'c7ebcd'
        } else {
            return 'fc3021'
        }
    }

    buttonFormatteraduan = (cell, row) => {
        if (row.ButtonStatus === true) {
            return <div className='divBtnTable'>
                Approval Aktif
            </div>
        } else {
            return <div className='divBtnTable'>
                Approval NonAktif
            </div>
        }

    }


    handleupdate = (e, row) => {
        this.setState({ blocking: true })

        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin " + row.ButtonStatus === true ? "Non Aktifkan Approval" : "Aktifkan Approval" + " Provinsi " + row.NamaProvinsi + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.setState({ blocking: true })
                let status = row.ButtonStatus === true ? false : true

                let param = {
                    "buttonStatus": status,
                    "kabupatenIdList": [
                        row.KabupatenID
                    ],
                    "provinsiId": row.ProvinsiID,
                    "modulPembayaran":"PK"
                }

                APIButton.post("/aktif/updateAktifProKab", param)
                    .then((res) => {
                        

                        this.setState({ blocking: false });
                        if (res.status === 200) {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'success',
                                text: 'Data Berhasil di Update',
                            })
                            this.setState(

                                {
                                    blocking: false
                                }, () => {
                                    this.handleSearch()
                                }

                            )
                        }

                    })
                    .catch((error) => {
                        this.setState({ blocking: false });
                        
                    });


            } else {
                this.setState({blocking:false})
            }
        })

    }

    handleDownloadFileSK = (e, row) => {

        let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten
        var newStr = row.fileName.replace(/\s/g, "");

        let Data = new FormData();
        Data.append('idWilayah', row.idKabupaten)
        Data.append('idFileTypeUpload', '2')
        Data.append('tingkatwilayah', '2')

        Swal.fire({
            title: 'Download File SK',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya !',
            cancelButtonText: 'Batal'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ blocking: true })

                APIUpload.post('userpembiayaan/downloadFileUser', Data, {
                    responseType: 'blob',
                })
                    .then((response) => {
                        if (response.status === 200) {
                            const content = response.headers['content-type'];
                            
                            download(response.data, newStr, content)
                            this.setState({ blocking: false })
                            Swal.fire({
                                title: 'Info!',
                                icon: 'success',
                                text: 'File berhasil didownload',
                            })

                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Error',
                                icon: 'warning',
                                text: 'File gagal didownload',
                            })

                            this.setState({ blocking: false })
                        }
                    })
                    .catch((error) => {
                        // Handle error.
                        
                        Swal.fire({
                            title: 'Error',
                            icon: 'warning',
                            text: 'File gagal didownload',
                        })
                        this.setState({ blocking: false })
                    });
            }
        })
    }

    preview = (e, row, action) => {
        let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten
        const link = document.createElement("a");
        link.target = "_blank";

        let Data = new FormData();
        Data.append('idWilayah', row.idKabupaten)
        Data.append('idFileTypeUpload', '2')
        Data.append('tingkatwilayah', '2')

        APIUpload.post('userpembiayaan/downloadFileUser', Data, {
            responseType: 'blob',
        })
            .then((res) => {
                

                link.href = URL.createObjectURL(
                    new Blob([res.data], { type: "application/pdf" })
                );
                this.setState({
                    // previewModal: !this.state.previewModal,
                    // urlPdf: link.href
                })

                link.click();

            })
            .catch((error) => {
                let message = error;
                Swal.fire({
                    title: 'Error!',
                    icon: 'warning',
                    // text: 'Jaringan bermasalah.',
                    text: message,
                })
                
            });
    }


    handleChangeInput = (e, tab) => {
        if (e.target.name) {
            if (isNumber(e.target.value)) {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {

            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    sel_aduan = (e) => {
        if (e) {
            this.setState({
                aduan: e, selectedAduan: e.value,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                aduan: null, selectedAduan: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_status = (e) => {
        if (e) {
            this.setState({
                status: e, selectedStatus: e.value,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                status: null, selectedStatus: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }



    handleCetak = (e) => {
        e.preventDefault()
        // if (this.handleValidation()) {
        this.setState({ blocking: true });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw } = this.state;

        // const addFilter = this.state.additionalFilter
        // let newFilter = []
        // addFilter.forEach(row => {
        //     if (row.filtValue && row.valkey) {
        //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
        //     }
        // })

        Swal.fire({
            title: 'Cetak Formulir',
            //text: "Anda akan mencetak formulir",
            icon: 'info',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Excel',
            confirmButtonColor: '#3085d6',
            showDenyButton: true,
            denyButtonText: 'PDF',
            denyButtonColor: '#4ea842',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        }).then((result) => {

            let url = 'viewReport?filterPencarian=%7B'

            if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
            if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
            if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
            if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
            if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

            let urlCetakPdf = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
            let urlCetakExcel = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

            if (result.isConfirmed) {
                APIReport.get(urlCetakExcel, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'xlsx', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        // 
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });

            } else if (result.isDenied) {
                APIReport.get(urlCetakPdf, { responseType: 'blob' })
                    .then(res => {
                        // 
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'pdf', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        // 
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });


            } else {
                this.setState({ blocking: false });
            }
        })
    }

    handlesumberSelect = (e, param) => {
        if(this.state.selectedRows?.length === 0){
          Swal.fire({
            title: 'Info!',
            icon: 'info',
            text: 'Tidak Ada Data Yang di Update.',
            showConfirmButton: false,
            timer: 1500
          })
        }else{
        
        let id = []
        let idProv = this.state.selectedRows[0]?.ProvinsiID
        this.state.selectedRows.forEach(el => {
          id.push(el.KabupatenID.toString())
        //   idkec.push(el.id_kecamatan.toString())
      })
      
        Swal.fire({
          title: 'Apakah Anda Yakin?',
          text: "Total " + this.state.selectedRows?.length + " Kabupaten, Silahkan Memilih Aktivasi Approval",
          icon: 'warning',
          showDenyButton: true,
          denyButtonColor:'#3085d6',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Lock',
          denyButtonText: 'Unlock',
          cancelButtonText:'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            this.setState({ blocking: true });
    
            let status = false
            let param = {
                "buttonStatus": status,
                "kabupatenIdList":
                    id
                ,
                "provinsiId": idProv,
                "modulPembayaran":"PK"
            }
            APIButton.post("/aktif/updateAktifProKab", param)
                .then((res) => {
                    

                    this.setState({ blocking: false });
                    if (res.status === 200) {
                        Swal.fire({
                            title: 'Info!',
                            icon: 'success',
                            text: 'Data Berhasil di Update',
                        })
                        this.setState(

                            {
                                blocking: false,
                                selectedRows:[]
                            }, () => {
                                this.handleSearch()
                            }

                        )
                    }

                })
              .catch((error) => {
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  text: 'Data Gagal Diupdate',
                })
                this.setState({ blocking: false });
              });
    
            } else if (result.isDenied) {
            this.setState({ blocking: true });
    
            let status = true
            let param = {
                "buttonStatus": status,
                "kabupatenIdList":
                    id
                ,
                "provinsiId": idProv,
                "modulPembayaran":"PK"
            }
            APIButton.post("/aktif/updateAktifProKab", param)
                .then((res) => {
                    

                    this.setState({ blocking: false });
                    if (res.status === 200) {
                        Swal.fire({
                            title: 'Info!',
                            icon: 'success',
                            text: 'Data Berhasil di Update',
                        })
                        this.setState(

                            {
                                blocking: false,
                                selectedRows:[]
                            }, () => {
                                this.handleSearch()
                            }

                        )
                    }

                })
              .catch((error) => {
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  text: 'Data Gagal Diupdate',
                })
                this.setState({ blocking: false });
              });      }
    
        })
    }
      }

      handlesumberSelectNasional = (e, param) => {
        Swal.fire({
          title: 'Apakah Anda Yakin?',
          text: "NASIONAL, Silahkan Memilih Aktivasi Approval",
          icon: 'warning',
          showDenyButton: true,
          denyButtonColor:'#3085d6',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Lock',
          denyButtonText: 'Unlock',
          cancelButtonText:'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            this.setState({ blocking: true });
    
            let status = false
            let param = {
                "buttonStatus": status,
                "kabupatenIdList":
                    []
                ,
                "provinsiId": 0,
                "modulPembayaran":"PK"
            }
            APIButton.post("/aktif/updateAktifProKab?periodeSensus=" + this.state.value_priode, param)
                .then((res) => {
                    

                    this.setState({ blocking: false });
                    if (res.status === 200) {
                        Swal.fire({
                            title: 'Info!',
                            icon: 'success',
                            text: 'Data Berhasil di Update',
                        })
                        this.setState(

                            {
                                blocking: false,
                                selectedRows:[]
                            }, () => {
                                this.handleSearch()
                            }

                        )
                    }

                })
              .catch((error) => {
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  text: 'Data Gagal Diupdate',
                })
                this.setState({ blocking: false });
              });
    
            } else if (result.isDenied) {
            this.setState({ blocking: true });
    
            let status = true
            let param = {
                "buttonStatus": status,
                "kabupatenIdList":
                    []
                ,
                "provinsiId": 0,
                "modulPembayaran":"PK"
            }
            APIButton.post("/aktif/updateAktifProKab?periodeSensus=" + this.state.value_priode, param)
                .then((res) => {
                    

                    this.setState({ blocking: false });
                    if (res.status === 200) {
                        Swal.fire({
                            title: 'Info!',
                            icon: 'success',
                            text: 'Data Berhasil di Update',
                        })
                        this.setState(

                            {
                                blocking: false,
                                selectedRows:[]
                            }, () => {
                                this.handleSearch()
                            }

                        )
                    }

                })
              .catch((error) => {
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  text: 'Data Gagal Diupdate',
                })
                this.setState({ blocking: false });
              });      }
    
        })
      }

    handleSelectRow = (cat, row, isSelect) => {


        var data = this.state.datas
        let dataAll = []

        if (isSelect === true && cat === 'single') {
            this.setState({ selectedRows: this.state.selectedRows?.concat(row), disApprov: false });
        } else if (isSelect === true && cat === 'all') {
            this.setState({ selectedRows: data });
        } else if (cat === 'single') {
            if (this.state.selectedRows?.length !== 0) {
                var array = JSON.parse(JSON.stringify(this.state.selectedRows));
                var index = array.findIndex(el => el.KabupatenID === row.KabupatenID);
                if (index !== -1) {
                    array.splice(index, 1);
                    if (this.state.selectedRows?.length > 1) {
                        this.setState({ selectedRows: array, disApprov: false });
                    } else {
                        this.setState({ selectedRows: array, disApprov: true });
                    }

                }
                
            }
        }
        else {
            this.setState({ selectedRows: [], disApprov: true });
        }
    }



    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10px',

            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRow('single', row, isSelect)
            },
            onSelectAll: (isSelect, row, e) => {
                if (isSelect) {
                    this.handleSelectRow('all', row, isSelect)
                    return this.state.datas.map(row => row.KabupatenID)
                } else {
                    this.handleSelectRow('all', row, isSelect)
                    return [];
                }
            }
        };
        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 1,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
        };
        return (
            <div className='content'>
                <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                    <div className='title-content'><ImPlay3 className='icon-brd' />Monitoring Approval Provinsi Dan Nasional</div>
                    <div className='body-content'>                    <Box sx={{ width: '100%' }}>
                        <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                            <AccordionSummary
                                className='panelSummary'
                                expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                            >
                                <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%' }} size="small" >
                                                    Provinsi
                                                    <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                                            </Grid>  
                                                 {/* <Grid item xs={12} md={4}>
                                                 <Button onClick={this.handlesumberSelectNasional} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px', backgroundColor: 'green' }} variant="contained"> Update Approval Nasional</Button>
                                            </Grid> */}


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails >
                        </Accordion >
                    </Box >
                    <h3>Approval</h3>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                <Grid item xs={12} md={12}>
                                    <div>
                                    <Button onClick={(e) => this.handlesumberSelectNasional()} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'red', ':hover': { bgcolor: 'red', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px', float: 'left' }} sx={{ float: 'left' }} /> Update Nasional</Button>

                                        {/* <Button disabled={this.state.selectedRows?.length === 0}  sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: '#B22222', ':hover': { bgcolor: '#B22222', color: 'white' } }} variant="contained"><BsXCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Reject</Button> */}
                                        <Button onClick={(e) => this.handlesumberSelect()} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px', float: 'left' }} sx={{ float: 'left' }} /> Update Approval</Button>

                                    </div>
                                </Grid>
                            </Grid>
                        <Paper sx={{ width: "100%", boxShadow: "none" }}>

                            {/* {this.state.datas} */}
                            <div className="rekap-table">
                                <BootstrapTable
                                    data={this.state.datas}
                                    pagination={true}
                                    options={options}
                                    search={true}
                                    selectRow={selectRow}
                                // key='KabupatenID'
                                >

                                    {/* <TableHeaderColumn width="40" dataField="" headerAlign="center" dataAlign="center" dataFormat={this.numberingFormat} >NO </TableHeaderColumn> */}
                                    <TableHeaderColumn width="120" dataField="ProvinsiID" headerAlign="center" dataAlign="center"  >PROVINSI ID </TableHeaderColumn>
                                    <TableHeaderColumn width="120" dataField="NamaProvinsi" headerAlign="center" dataAlign="center"  >PROVINSI </TableHeaderColumn>
                                    <TableHeaderColumn isKey={true} width="120" dataField="KabupatenID" headerAlign="center" dataAlign="center"  >KABUPATEN ID </TableHeaderColumn>
                                    <TableHeaderColumn width="120" dataField="NamaKabupaten" headerAlign="center" dataAlign="center"  >KABUPATEN </TableHeaderColumn>
                                    <TableHeaderColumn width="160" dataField="NamaModul" headerAlign="center" dataAlign="center"  >NAMA MODUL </TableHeaderColumn>
                                    <TableHeaderColumn width="110" dataField="ButtonStatus" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatAduan} dataFormat={this.buttonFormatteraduan} >BUTTON STATUS </TableHeaderColumn>

                                    <TableHeaderColumn width="70" dataField="action" headerAlign="center" dataAlign="center" dataFormat={this.buttonFormatter} >Update Status </TableHeaderColumn>

                                    {/* <TableHeaderColumn width="60" dataField="targetkk" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TARGET KK </TableHeaderColumn> */}
                                    {/* <TableHeaderColumn width="80" dataField="file" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.fileFormatter} >FILE </TableHeaderColumn> */}
                                    {/* <TableHeaderColumn width="80" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn> */}
                                </BootstrapTable>
                            </div>
                            <div className="sizePerPage">
                                Size Per Page
                                <select
                                    name="category"
                                    value={this.state.sizePerPage}
                                    onChange={this.handleChangeSizePerPage}
                                >
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value={this.state.datas?.length}>All</option>
                                </select>
                            </div>
                            <div className="totalData">
                                <b>Total Data: {this.state.datas?.length}</b>
                            </div>
                        </Paper>

                    </div>
                </BlockUi >
            </div >
        )
    }
}

export default monitoringSK;