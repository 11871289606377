import React, { Component } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock, AiOutlineVerticalAlignBottom, AiOutlineFilePdf, AiOutlineUpload } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import { ImSearch, ImBin, ImCross, ImFloppyDisk, ImLoop2, ImMap, ImMap2, ImPlay3, ImPlus, ImDownload2, ImDownload } from 'react-icons/im';
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import TextareaAutosize from '@mui/base/TextareaAutosize';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FileUploader } from "react-drag-drop-files";
import APIWilayah from '../../services/APIWilayah';
import APIUser from "../../services/APIUser";
import authServices from "../../services/authServices";
import { Link } from 'react-router-dom';
import moment from 'moment';
import APIMonitoringBackend from '../../services/APIMonitoringBackend';
import axios from 'axios';

const fileTypes = ["PDF", "DOCX", "JPG", "PNG"];
class HasilLaporan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendDatas: {},
      panel: true,

      //---table
      sizePerPage: 10,
      tableHead: [],
      datas: [],
      action: '',
      //---end table

      //---wilayah
      flagNumber: -1,
      flagOpen: true,
      blocking: false,
      dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
      dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
      //---end wilayah
      disjenis: true,
      dis_PIC:false,
      sdm: [
        { value: 'Supervisor', label: 'Supervisor' },
        { value: 'Operator', label: 'Operator' },
      ],
      dt_status: [{ value: 1, label: 'Berhasil', name: 'Berhasil' }, { value: 2, label: 'Berhasil Sebagian', name: 'Berhasil Sebagian' }, { value: 3, label: 'Tidak Berhasil', name: 'Tidak Berhasil' }],
      dt_sdm: [{ value: 1, label: 'Pendata', name: 'Pendata' }, { value: 2, label: 'Kader Formulir', name: 'Kader Formulir' }, { value: 3, label: 'Operator', name: 'Operator' }, { value: 4, label: 'Supervisor', name: 'Supervisor' }, { value: 5, label: 'Manager Data', name: 'Manager Data' }, { value: 6, label: 'Manager Pengelola', name: 'Manager Pengelola' }],
      dt_jenis: [
        { value: 1, label: 'Pencarian Data PK Hilang, keterangan https://bkkbn-pk21.blogspot.com/2021/05/penanganan-data-hilang.html', name: 'Pencarian Data PK Hilang, keterangan https://bkkbn-pk21.blogspot.com/2021/05/penanganan-data-hilang.html' },
        { value: 2, label: 'Peng-active-an / Penon-active-an Pendata', name: 'Peng-active-an / Penon-active-an Pendata' },
        { value: 3, label: 'Penambahan User Operator', name: 'Penambahan User Operator' },
        { value: 4, label: 'Penggantian Pendata (Pengalihan/Mutasi Data PK)', name: 'Penggantian Pendata (Pengalihan/Mutasi Data PK)' },
        { value: 5, label: 'Perubahan Penugasan RT (Pengalihan/Mutasi Data PK)', name: 'Perubahan Penugasan RT (Pengalihan/Mutasi Data PK)' },
        { value: 6, label: 'Pemutakhiran Wilayah RT (Pengalihan/Mutasi Data PK)', name: 'Pemutakhiran Wilayah RT (Pengalihan/Mutasi Data PK)' },
        { value: 7, label: 'Pengembalian Data PK Delete', name: 'Pengembalian Data PK Delete' },
        { value: 8, label: 'Other', name: 'Other' }
      ],
      dt_hp: [
        { value: 1, label: 'HP SAMSUNG & BROWSER SAMSUNG', name: 'HP SAMSUNG & BROWSER SAMSUNG' },
        { value: 2, label: 'HP SAMSUNG & BROWSER CHROME', name: 'HP SAMSUNG & BROWSER CHROME' },
        { value: 3, label: 'HP SAMSUNG & BROWSER LAIN', name: 'HP SAMSUNG & BROWSER LAIN' },
        { value: 1, label: 'HP VIVO & BROWSER SAMSUNG', name: 'HP VIVO & BROWSER SAMSUNG' },
        { value: 2, label: 'HP VIVO & BROWSER CHROME', name: 'HP VIVO & BROWSER CHROME' },
        { value: 3, label: 'HP VIVO & BROWSER LAIN', name: 'HP VIVO & BROWSER LAIN' },
        { value: 1, label: 'HP OPPO & BROWSER SAMSUNG', name: 'HP OPPO & BROWSER SAMSUNG' },
        { value: 2, label: 'HP OPPO & BROWSER CHROME', name: 'HP OPPO & BROWSER CHROME' },
        { value: 3, label: 'HP OPPO & BROWSER LAIN', name: 'HP OPPO & BROWSER LAIN' },
        { value: 1, label: 'HP XIAOMI & BROWSER SAMSUNG', name: 'HP XIAOMI & BROWSER SAMSUNG' },
        { value: 2, label: 'HP XIAOMI & BROWSER CHROME', name: 'HP XIAOMI & BROWSER CHROME' },
        { value: 3, label: 'HP XIAOMI & BROWSER LAIN', name: 'HP XIAOMI & BROWSER LAIN' },
        { value: 1, label: 'HP IPHONE & BROWSER SAMSUNG', name: 'HP IPHONE & BROWSER SAMSUNG' },
        { value: 2, label: 'HP IPHONE & BROWSER CHROME', name: 'HP IPHONE & BROWSER CHROME' },
        { value: 3, label: 'HP IPHONE & BROWSER LAIN', name: 'HP IPHONE & BROWSER LAIN' },
      ],
      filterSDM: [],
      sdmValue: '',
      jenisValue: '',
      filterjenis: [],
      hpValue: '',
      filterhp: [],
      statusValue: '',
      filterstatus: [],
      Other: '',
      ketMasalah: '',
      username: '',
      ketPenanganan: '',
      hasilPenanganan: '',
      kesimpulan: '',
      Nama_PIC: '',
      tanggal: '',

      dt_roles: [],
      role: {},
      openEditUser: false,
      openAddUser: false,
      openDialog: false,

      changePassword: false,
      showPasswordLama: false,
      showPasswordBaru: false,
      showPasswordBaruKonfirm: false,
      password: '',
      passwordBaru: '',
      updateBy: localStorage.getItem("username"),
      userId: 0,
      userRole: JSON.parse(localStorage.getItem('authority'))[0].attributes.role
    }
  }

  sel_sdm = (e) => {


    if (e) {
      let sdm = []
      e.forEach(el => {
        sdm.push(el.label)
      })
      this.setState({
        filterSDM: e, sdmValue: sdm,
      },
        () => {
          if (e) {

          }
        }
      )
    }
    else {
      this.setState({
        filterSDM: null, sdmValue: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_jenis = (e) => {
    console.log(e)
    this.setState({ disjenis: true, Other: '' })
    if (e) {
      this.setState({
        filterjenis: e, jenisValue: e.label,
      },
        () => {
          if (e.label === 'Other') {
            this.setState({
              disjenis: false
            })

          }
        }
      )
    }
    else {
      this.setState({
        filterjenis: null, jenisValue: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_hp = (e) => {
    if (e) {
      this.setState({
        filterhp: e, hpValue: e.label,
      },
        () => {
          if (e) {


          }
        }
      )
    }
    else {
      this.setState({
        filterhp: null, hpValue: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_status = (e) => {
    if (e) {
      this.setState({
        filterstatus: e, statusValue: e.label,
      },
        () => {
          if (e) {


          }
        }
      )
    }
    else {
      this.setState({
        filterstatus: null, statusValue: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handleChangeInputOther = (e, tab) => {
    if (e) {
      this.setState({ Other: e.target.value })
    } else {
      this.setState({ Other: e.target.value.toUpperCase() })
    }
  }

  handleChangeInputketMasalah = (e, tab) => {

    if (e) {
      this.setState({ ketMasalah: e.target.value })
    } else {
      this.setState({ ketMasalah: e.target.value.toUpperCase() })
    }
  }

  handleChangeInputketPenanganan = (e, tab) => {

    if (e) {
      this.setState({ ketPenanganan: e.target.value })
    } else {
      this.setState({ ketPenanganan: e.target.value.toUpperCase() })
    }
  }

  handleChangeInputUsername = (e, tab) => {

    if (e) {
      this.setState({ username: e.target.value })
    } else {
      this.setState({ username: e.target.value.toUpperCase() })
    }
  }

  handleChangeInputhasilPenanganan = (e, tab) => {

    if (e) {
      this.setState({ hasilPenanganan: e.target.value })
    } else {
      this.setState({ hasilPenanganan: e.target.value.toUpperCase() })
    }
  }

  handleChangeInputkesimpulan = (e, tab) => {

    if (e) {
      this.setState({ kesimpulan: e.target.value })
    } else {
      this.setState({ kesimpulan: e.target.value.toUpperCase() })
    }
  }

  handleChangeInputPIC = (e, tab) => {

    if (e) {
      this.setState({ Nama_PIC: e.target.value })
    } else {
      this.setState({ Nama_PIC: e.target.value.toUpperCase() })
    }
  }

  sel_tanggal = (e) => {
    console.log(e)

    // console.log(moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss.sssZ'))
    if (e) {
      this.setState({
        //tgl_sp2d: moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss.sssZ').toString()
        tanggal: e.target.value
      })
    }
  }

  handleClear = () => {
    this.setState({
      provinsi: [],
      id_provinsi: 0,
      filterSDM: null,
      sdmValue: null,
      jenisValue: '',
      filterjenis: [],
      hpValue: '',
      filterhp: [],
      statusValue: '',
      filterstatus: [],
      Other: '',
      ketMasalah: '',
      ketPenanganan: '',
      hasilPenanganan: '',
      kesimpulan: '',
      Nama_PIC: '',
      username: '',
      tanggal: '',
    })
  }

  handleSubmit = () => {
    // console.log(this.state.sdmValue)
    const dataTable = {
      // "role": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
      "pic": this.state.Nama_PIC,
      "case_date": this.state.tanggal,
      "id_propinsi": this.state.id_provinsi,
      "jenis_case": this.state.jenisValue,
      "other": this.state.Other,
      "description_case": this.state.ketMasalah,
      "status": this.state.statusValue,
      "description_handling": this.state.ketPenanganan,
      "jns_hp_browser": this.state.hpValue,
      "hasil_penanganan": this.state.hasilPenanganan,
      "kesimpulan": this.state.kesimpulan,
      "userName": this.state.username

      // "pdf" : this.state.setFile
    }


    let Data = new FormData();
    Data.append("doc", this.state.setFile);
    console.log(dataTable)


    if(this.state.action === 'edit'){
      Swal.fire({
        title: 'Apakah Anda Yakin?',
        text: "Ingin Edit Laporan & Penanganan?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ blocking: true, onSubmit: true })
  
          APIMonitoringBackend.post('pkportal/monitoring/updateProblemPK', dataTable)
            .then((response) => {
              if (response.status === 200) {
                this.setState({ blocking: false, openDialog: false, });
                this.handleClear()
                this.handleSearch()
                Swal.fire({
                  title: 'Info!',
                  icon: 'success',
                  text: 'Data Berhasil Diedit!',
                })
              }
            })
            .catch((error) => {
              let message = error;
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                // text: 'Jaringan bermasalah.',
                text: message,
              })
              this.setState({ blocking: false });
            });
        } else {
          this.setState({ blocking: false });
        }
  
      })

    }else{

      Swal.fire({
        title: 'Apakah Anda Yakin?',
        text: "Ingin Simpan Laporan & Penanganan?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ blocking: true, onSubmit: true })
  
          APIMonitoringBackend.post('pkportal/monitoring/insertProblemPK', dataTable)
            .then((response) => {
              if (response.status === 200) {
                this.setState({ blocking: false, openDialog: false, });
                this.handleClear()
                this.handleSearch() 
                Swal.fire({
                  title: 'Info!',
                  icon: 'success',
                  text: 'Data Berhasil Ditambah!',
                })
              }
            })
            .catch((error) => {
              let message = error;
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                // text: 'Jaringan bermasalah.',
                text: message,
              })
              this.setState({ blocking: false });
            });
        } else {
          this.setState({ blocking: false });
        }
  
      })
    }

   

  }

  getRole = (e) => {
    authServices
      .get("/roles/list")
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState({
            dt_roles: res.data
          }, () => {
            const role = JSON.parse(localStorage.getItem('authority'))[0].attributes.role
            if (role === 'Supervisor') {
              const manager = [res.data[4]]
              this.setState({
                dt_roles: manager
              })
            } else if (role === 'Manager Pengelolaan' || role === 'Manager Data') {
              const manager = [res.data[3], res.data[4], res.data[9], res.data[13], res.data[17]]
              this.setState({
                dt_roles: manager
              })
            } else if (role === 'Admin Kabupaten') {
              const manager = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[9], res.data[12], res.data[13]]
              this.setState({
                dt_roles: manager
              })
            } else if (role === 'Admin Provinsi') {
              const manager = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[8], res.data[9], res.data[11], res.data[12], res.data[13], res.data[14], res.data[15], res.data[16], res.data[17]]
              this.setState({
                dt_roles: manager
              })
            } else {
              this.setState({
                dt_roles: res.data
              })
            }
          })
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });
  }


  setHeaderTable = () => {
    var headerColumns = [
      { width: "100", title: 'STATUS', dataField: "isActive", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", title: 'USERNAME', dataField: "username", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'NAMA', dataField: 'namaLengkap', headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'ROLE', dataField: "roleName", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "150", title: 'WILAYAH', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'ALAMAT', dataField: "alamat", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'TELEPON', dataField: "noTelepon", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", title: 'EMAIL', dataField: "email", headerAlign: 'center', dataAlign: 'left', editable: false },

    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidMount = () => {
    this.setHeaderTable()
    this.getRole();

    const rest = JSON.parse(localStorage.getItem("authority"));
    this.setState({
      roleUser: rest[0].attributes.user.roleID
    })
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({
          flagSearch: false,
          roleUser: rest[0].attributes.role
        },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }

        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
        }
        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // console.log(vl, 'vale kab')
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // console.log(vl, 'vale kec')
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              // console.log(vl, 'vale des')
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
        sendDatas: { ...this.state.sendDatas, idProvinsi: e.value }
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // console.log('masuk false')
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di prov')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {},
      }
        ,
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
        sendDatas: { ...this.state.sendDatas, idKabupaten: e.value }
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kab')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
        sendDatas: { ...this.state.sendDatas, idKecamatan: e.value }
      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kec')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
        sendDatas: { ...this.state.sendDatas, idKelurahan: e.value }
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di des')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
        sendDatas: { ...this.state.sendDatas, idRw: e.value }
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
        sendDatas: { ...this.state.sendDatas, idRt: e.value }
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        rt: null, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
          "idRw": this.state.sendDatas.idRW,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  handleSelectRole = (e) => {
    if (e) {
      this.setState({ role: e })
      this.setState({ sendDatas: { ...this.state.sendDatas, roleId: e.value } });
    } else {
      this.setState({ role: {}, sendDatas: { ...this.state.sendDatas, roleId: '' } })
    }

  }

  handleOpenClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openDialog: !this.state.openDialog },
      () => { if (!this.state.openDialog) { this.clearForm() } }
    )
  }

  handleChangePanel = (e) => {
    e.preventDefault();
    this.setState({ panel: !this.state.panel })
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  clearForm = () => {
    this.setState({})
  }

  handleSearch = () => {
    // this.setLabel()
    this.getBackend()
  }

  statusFormatter = (cell, row) => {
    return <div>{moment(row.case_date).format("YYYY-MM-DD")}</div>
  }

  edit = (e, row, action) => {
    let date = row.case_date.substr(0,10)
    var nDate = date.slice(8, 10) + '-' + date.slice(5, 7) + '-' + date.slice(0, 4);
    console.log(nDate)
    this.setState({ 
      action: action,
      dis_PIC:true,
      openDialog: true, 
      id_provinsi: row.id_propinsi,
      jenisValue: row.jenis_case,
      provinsi: { 'label': row.id_propinsi },
      filterstatus: {'label': row.status},
      filterhp: {'label': row.jns_hp_browser},
      filterjenis: {'label': row.jenis_case},
      hpValue: row.jns_hp_browser,
      statusValue: row.status,
      Other: row.other,
      ketMasalah: row.description_case,
      ketPenanganan: row.description_handling,
      hasilPenanganan: row.hasil_penanganan,
      kesimpulan: row.kesimpulan,
      Nama_PIC: row.pic,
      username: row.userName,
      tanggal: nDate,
    })


  }

  buttonFormatter = (cell, row) => {
    

    return <div className='divBtnTable'>
      <Tooltip title="Edit"><Button id={row.id} onClick={(e) => this.edit(e, row, 'edit')} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button></Tooltip>


    </div>

  }

  getBackend() {
    this.setState({ blocking: true });

    APIMonitoringBackend.get('/pkportal/monitoring/getListProblemPK')
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState(
            {
              datas: res.data.String
            }, () => {
              //   console.log(res.data.String)
            }
          )
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        // console.log(error, "dataaa ERROR");
      });
  }

  render() {

    const options = {
      page: 1,
      sizePerPageList: [{
        text: '1', value: 1
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
    };
    return (
      <div className='content'>
        <div className='title-content'><ImPlay3 className='icon-brd' />Hasil Laporan Masalah PK</div>
        <div className='body-content'>
          <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel} onChange={this.handleChangePanel} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>

                        <Grid item xs={12} md={4}>
                          <Button onClick={(e) => this.handleOpenClose(e, 'create')} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImPlus className='icon-brd' style={{ marginRight: '5px' }} /> Laporan Penanganan</Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Hasil Laporan  {this.state.title}</h3>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <div className='user-table'>
                <BootstrapTable data={this.state.datas}
                  pagination={true}
                  options={options}
                  search={true}
                >
                  <TableHeaderColumn width='175' isKey={true} dataField='pic' headerAlign='center' columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign='center'>Nama PIC</TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='case_date' headerAlign='center' dataAlign='center' dataFormat={this.statusFormatter}>Tanggal Kejadian</TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='userName' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Username User</TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='jenis_case' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Jenis Permasalahan</TableHeaderColumn>
                  <TableHeaderColumn width='160' dataField='jns_hp_browser' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Jenis Handphone & Browser</TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='hasil_penanganan' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Hasil Penanganan</TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='kesimpulan' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Kesimpulan Masalah</TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='status' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Status Penanganan</TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn>
                </BootstrapTable>
              </div>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='1'>1</option>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div>
            </Paper>

            <Dialog open={this.state.openDialog} onClose={this.handleOpenClose} maxWidth="xl" fullScreen>
              <DialogTitle> Laporan Dan Penanganan</DialogTitle>
              {/* {this.state.id ? 'Ubah' : 'Tambah'} */}
              <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                      <Grid item xs={12} md={1}>
                        <FormControl sx={{ width: '100%' }} size="small" >

                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Nama PIC
                          <TextField
                            required
                            name="Nama_PIC"
                            variant="outlined"
                            value={this.state.Nama_PIC}
                            disabled={this.state.dis_PIC}
                            type="text"
                            style
                            ={{
                              width: '100%'
                            }}
                            size="small"
                            onChange={this.handleChangeInputPIC} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Tanggal Kejadian
                          <TextField name='tanggal' onChange={this.sel_tanggal} value={this.state.tanggal} inputProps={{ style: { height: "6px" } }} type="date" id="outlined-basic" isClearable />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                      <Grid item xs={12} md={1}>
                        <FormControl sx={{ width: '100%' }} size="small" >

                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          User Name Pendata, Operator, Dan lain-lain
                          <TextField
                            required
                            name="username"
                            variant="outlined"
                            value={this.state.username}
                            type="text"
                            style
                            ={{
                              width: '100%'
                            }}
                            size="small"
                            onChange={this.handleChangeInputUsername} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Provinsi
                          <Select options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                      <Grid item xs={12} md={1}>
                        <FormControl sx={{ width: '100%' }} size="small" >

                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Jenis Permasalahan
                          <Select options={this.state.dt_jenis} onChange={this.sel_jenis} value={this.state.filterjenis} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Other
                          <TextareaAutosize
                            maxRows={1}
                            name="other"
                            variant="outlined"
                            value={this.state.Other}
                            disabled={this.state.disjenis}
                            placeholder="Other"
                            defaultValue=""
                            style={{ width: '100%', paddingBottom: '50px' }}
                            onChange={this.handleChangeInputOther}
                          />
                        </FormControl>
                      </Grid> */}
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                      <Grid item xs={12} md={1}>
                        <FormControl sx={{ width: '100%' }} size="small" >

                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Keterangan Permasalahan
                          <TextareaAutosize
                            maxRows={1}
                            required
                            name="ketMasalah"
                            value={this.state.ketMasalah}
                            onChange={this.handleChangeInputketMasalah}
                            placeholder="Keterangan Permasalahan"
                            defaultValue=""
                            style={{ width: '100%', paddingBottom: '50px' }}
                          />
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Status Penanganan
                          <Select options={this.state.dt_status} onChange={this.sel_status} value={this.state.filterstatus} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                      <Grid item xs={12} md={1}>
                        <FormControl sx={{ width: '100%' }} size="small" >

                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Keterangan Penanganan
                          <TextareaAutosize
                            maxRows={1}
                            required
                            name="ketPenanganan"
                            variant="outlined"
                            value={this.state.ketPenanganan}
                            onChange={this.handleChangeInputketPenanganan}
                            placeholder="Keterangan Penanganan"
                            defaultValue=""
                            style={{ width: '100%', paddingBottom: '50px' }}
                          />
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Jenis Handphone dan Jenis Browser
                          <Select options={this.state.dt_hp} onChange={this.sel_hp} value={this.state.filterhp} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                      <Grid item xs={12} md={1}>
                        <FormControl sx={{ width: '100%' }} size="small" >

                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Hasil Penanganan Masalah
                          <TextareaAutosize
                            maxRows={1}
                            required
                            name="hasilPenanganan"
                            variant="outlined"
                            value={this.state.hasilPenanganan}
                            placeholder="Hasil Penanganan Masalah"
                            defaultValue=""
                            style={{ width: '100%', paddingBottom: '50px' }}
                            onChange={this.handleChangeInputhasilPenanganan}
                          />
                        </FormControl>
                      </Grid> */}
                      {/* <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Kesimpulan Penyebab Masalah
                          <TextareaAutosize
                            maxRows={1}
                            required
                            name="kesimpulan"
                            variant="outlined"
                            value={this.state.kesimpulan}
                            aria-label="maximum height"
                            placeholder="Kesimpulan Penyebab Masalah"
                            defaultValue=""
                            style={{ width: '100%', paddingBottom: '50px' }}
                            onChange={this.handleChangeInputkesimpulan}
                          />
                        </FormControl>
                      </Grid> */}
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                      <Grid item xs={12} md={1}>
                        <FormControl sx={{ width: '100%' }} size="small" >

                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl size="small" style={{ width: '100%' }}>
                          Upload Dokumen/Gambar
                          <FileUploader
                            handleChange={this.handleChange}
                            name="pdf"
                            label="Dokumen/Gambar"
                            types={fileTypes}
                            hoverTitle="Upload File"
                            style={{ width: '100%' }}

                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          <Button disabled={this.state.disabledSimpanRt} onClick={this.handleSubmit} variant="outlined" color="primary" sx={{ marginTop: '15px' }}>Simpan</Button>
                          <Button disabled={this.state.disabledSimpanRt} onClick={this.handleClear} variant="outlined" color="warning" sx={{ marginTop: '15px' }}>Clear</Button>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenClose} variant="outlined" color="error">Batal</Button>
              </DialogActions>
            </Dialog>
          </BlockUi>
        </div>

      </div>
    )
  }
}

export default HasilLaporan;