import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [flag, setFlag] = useState(true)
  const [rightOpen, setRightOpen] = useState(props.rightOpen)

  const leftChecked = intersection(checked, left);
  // const rightChecked = intersection(checked, right);

  useEffect(() => {
    setRightOpen(props.rightOpen)
  }, [props.rightOpen])
  

  useEffect(() => {
    setLeft(props.dataWilayah)
  }, [props.dataWilayah])

  useEffect(() => {
    let arr = props.dataWilayahTujuan
    arr = arr.map(obj => ({ ...obj, status: 'x' }))
    setRight(arr)
    setFlag(false)
  }, [props.dataWilayahTujuan])  

  useEffect(() => {
    // console.log(right, 'data right side')
    props.handleSelect(right)
  }, [right])
  

  const handleToggle = (obj) => () => {
    const currentIndex = checked.indexOf(obj);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(obj);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) =>
    intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = (e, el) => {
    e.preventDefault()
    let arr = left
    arr.push(el)
    arr.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
    setLeft(arr);
    setRight(not(right, arr));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 200,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((el) => {
          const labelId = `transfer-list-all-item-${el.value}-label`;

          return (
            <ListItem
              key={el.value}
              role="listitem"
              button
              onClick={handleToggle(el)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(el) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={el.value} primary={`${el.label}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const customListTujuan = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 2 }}
        subheader={title}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 200,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((el) => {
          return (
            <ListItem
              key={el.value}
              role="listitem"
              button
            >
                {el.status !== 'x' ?
                    <>
                        <ListItemIcon>
                            <Button onClick={(e) => handleCheckedLeft(e, el)} color='warning' variant='contained' sx={{marginRight: '5px', marginLeft: '-10px'}}>X</Button>
                        </ListItemIcon>
                    </>
                    : 
                    <>
                        <ListItemIcon>
                            <Button sx={{marginRight: '5px', marginLeft: '-10px'}}>-</Button>
                        </ListItemIcon>
                    </>
                }
                <ListItemText id={el.value} primary={`${el.label}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList('Asal: ' + props.wilayah, left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || flag || (right.length === 0 && rightOpen === false)}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          {/* <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button> */}
        </Grid>
      </Grid>
      <Grid item>{customListTujuan('Tujuan: ' + props.wilayahTujuan? props.wilayahTujuan.label: '-', right)}</Grid>
    </Grid>
  );
}
