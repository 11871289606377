import { React, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import WithNav from './WithNav';
import WithoutNav from './WithoutNav';
import Login from './components/Login';
import NotFound from './components/NotFound';
import Tracking from './components/Tracking';
import Dashboard from './components/page/Dashboard';
import DetailDasboard from './components/page/DetailDasboard';
import Tabulasi from './components/page/Tabulasi';
import TabulasiKRS from './components/page/TabulasiKRS';
import TabulasiKRSDetail from './components/page/TabulasiKRSDetail';
import TestTable from './components/page/TestTable';
//---pengaturan
import CreateUser from './components/page/pengaturan/CreateUser';
import EditUser from './components/page/pengaturan/EditUser';
import Indikator from './components/page/pengaturan/Indikator';
import Role from './components/page/pengaturan/Role';
import SuratKeputusan from './components/page/pengaturan/SuratKeputusan';
import Target from './components/page/pengaturan/Target';
import Transfer from './components/page/pengaturan/Transfer';
import User from './components/page/pengaturan/User';
import AktivasiPWA from './components/page/pengaturan/aktivasiPWA';
import LokusDanECDI from './components/page/pengaturan/LokusDanECDI';
//---monitoring
import Approval from './components/page/ApprovalPendataan';
import ApprovalAPBD from './components/page/ApprovalPendataanAPBD';



import ApprovalRejectPendataan from './components/page/ApprovalRejectPendataan';
import UserProfile from './components/page/UserProfile';
import DetailStatus from './components/page/monitoring/DetailStatus';
import FormulirCAPI from './components/page/monitoring/FormulirCAPI';
import History from './components/page/monitoring/History';
import Implikasi from './components/page/monitoring/Implikasi';
import Metode from './components/page/monitoring/Metode';
import MonitoringBelumDibayar from './components/page/monitoring/MonitoringBelumDibayar';
import MonitoringSumberPendanaan from './components/page/monitoring/MonitoringSumberPendanaan';
import Notifsiga from './components/page/monitoring/Notifsiga';
import Pemutakhiran from './components/page/monitoring/Pemutakhiran';
import RekapStatus from './components/page/monitoring/RekapStatus';
import Rekapitulasi from './components/page/monitoring/Rekapitulasi';
import TargetVsActual from './components/page/monitoring/TargetVsActual';
import Anomali from './components/page/monitoring/anomaliPendataan';
import LaporanPenangganan from './components/page/monitoring/laporanPenangganan';
import MonitoringApprovProv from './components/page/monitoring/monitoringApprovProv';
import MonitoringDataTunggakan from './components/page/monitoring/monitoringDataTunggakan';
import PUS from './components/page/monitoring/monitoringPUS';
import MonitoringPendataan from './components/page/monitoring/monitoringPendataan';
import MonitoringPenggunaTabulasi from './components/page/monitoring/monitoringPenggunaTabulasi';
import MonitoringPerbandingan from './components/page/monitoring/monitoringPerbandingan';
import PendataanSupervisor from './components/page/monitoring/pendataanSupervisor';
import RekapPendataan from './components/page/monitoring/rekapPendataan';
import CetakSDM from './components/page/CetakSDM';
import HasilLaporan from './components/page/HasilLaporan';
import TabulasiDetail from './components/page/TabulasiDetail';
import LaporanPemanfaatan from './components/page/monitoring/LaporanPemanfaatan';
import ApprovalOperasional from './components/page/monitoring/approvalOperasional';
import CetakFormulirKK from './components/page/monitoring/cetakFormulirKK';

// APBN
import MonitoringAduanAPBN from './components/page/PK/APBN/monitoringAduan';
import MonitoringApprovalAPBN from './components/page/PK/APBN/MonitoringApproval';
import MonitoringCetakKKSumberPendanaanAPBN from './components/page/PK/APBN/MonitoringCetakKKSumberPendanaan';
import MonitoringOperasionalAPBN from './components/page/PK/APBN/monitoringOperasional';
import MonitoringPembayaranAPBN from './components/page/PK/APBN/monitoringPembayaran';
import MonitoringPersenAPBN from './components/page/PK/APBN/MonitoringPersen';
import RekapPembayaranAPBN from './components/page/PK/APBN/rekapPembayaran';

// APBD
import MonitoringAduanAPBD from './components/page/PK/APBD/monitoringAduan';
import MonitoringApprovalAPBD from './components/page/PK/APBD/MonitoringApproval';
import MonitoringCetakKKSumberPendanaanAPBD from './components/page/PK/APBD/MonitoringCetakKKSumberPendanaan';
import MonitoringOperasionalAPBD from './components/page/PK/APBD/monitoringOperasional';
import MonitoringPembayaranAPBD from './components/page/PK/APBD/monitoringPembayaran';
import MonitoringPersenAPBD from './components/page/PK/APBD/MonitoringPersen';
import RekapPembayaranAPBD from './components/page/PK/APBD/rekapPembayaran';

import MonitoringSK from './components/page/PK/monitoringSK';
import OperasionalPembayaran from './components/page/pengaturanPembayaran/OperasionalPembayaran';
import DaftarPajak from './components/page/pengaturanPembayaran/DaftarPajak';
import DaftarBank from './components/page/pengaturanPembayaran/DaftarBank';
import SuratKeputusanUpload from './components/page/pengaturanPembayaran/SuratKeputusanUpload';


import KRSApprovalRejectPendataan from './components/page/krs/KRSApprovalRejectPendataan';
import KRSApprovalRejectPendataanKab from './components/page/krs/KRSApprovalRejectPendataanKab';
import KRSCetakSDM from './components/page/krs/KRSCetakSDM';
import KRSMonitoringAduan from './components/page/krs/KRSMonitoringAduan';
import KRSMonitoringSumberPendanaan from './components/page/krs/KRSMonitoringSumberPendanaan';
import KRSPengelola from './components/page/krs/KRSPengelola';
import KRSSumberPendanaan from './components/page/krs/KRSSumberPendanaan';
import KRSUploadSuratKeputusan from './components/page/krs/KRSUploadSuratKeputusan';
import KRSVerifikator from './components/page/krs/KRSVerifikator';
import KRSMonitoringApprovProv from './components/page/krs/KRSmonitoringApprovProv';


// APBD

import KRSMonitoringApprovalAPBD from './components/page/krs/APBD/KRSMonitoringApproval';
import KRSMonitoringOperasionalAPBD from './components/page/krs/APBD/KRSmonitoringOperasional';
import KRSmonitoringPembayaranAPBD from './components/page/krs/APBD/KRSmonitoringPembayaran';
import KRSMonitoringPersenAPBD from './components/page/krs/APBD/KRSMonitoringPersen';
import KRSPembayaranAPBD from './components/page/krs/APBD/KRSPembayaran';
import KRSRekapitulasiPembayaranAPBD from './components/page/krs/APBD/KRSRekapitulasiPembayaran';

// APBN
import KRSmonitoringSKAPBN from './components/page/krs/APBN/KRSmonitoringSK';
import KRSMonitoringApprovalAPBN from './components/page/krs/APBN/KRSMonitoringApproval';
import KRSMonitoringOperasionalAPBN from './components/page/krs/APBN/KRSmonitoringOperasional';
import KRSmonitoringPembayaranAPBN from './components/page/krs/APBN/KRSmonitoringPembayaran';
import KRSMonitoringPersenAPBN from './components/page/krs/APBN/KRSMonitoringPersen';
import KRSPembayaranAPBN from './components/page/krs/APBN/KRSPembayaran';
import KRSRekapitulasiPembayaranAPBN from './components/page/krs/APBN/KRSRekapitulasiPembayaran';
import KRSPengumpulan from './components/page/krs/APBN/KRSPengumpulan';




import Page401 from './components/page/page401/page401';



function RequireAuth({ children }) {
  return checkToken() ? children : <Navigate to="/login" replace />;
}

function CheckAuth({ children }) {
  return checkToken() ? <Navigate to="/" replace /> : children;
}

function checkToken() {
  const check_token = localStorage.getItem('cekToken');
  return check_token === 'true';
}

function AuthCheckingFormulirPAPI({ children }) {
  return checkAuthorized([99, 4, 19], children);
}

function AuthCheckingAdmin({ children }) {
  return checkAuthorized([99, 1, 19], children);
}

function AuthCheckingPengaturanPembayaranRef({ children }) {
  return checkAuthorized([99, 1, 19, 2, 9], children);
}

function AuthCheckingFormulirCAPI({ children }) {
  return checkAuthorized([99, 1, 19, 4], children);
}

function AuthCheckingApprovalOperasional({ children }) {
  return checkAuthorized([99, 6, 3, 19, 24, 33], children);
}

function AuthCheckingApprovalPemutakhiran({ children }) {
  return checkAuthorized([99, 15, 16, 17, 19, 28, 29, 30], children);
}

function AuthCheckingUserWilayah({ children }) {
  return NocheckAuthorized([20, 21, 22, 23], children);
}

function AuthCheckingUploadSK({ children }) {
  return checkAuthorized([99, 9, 19], children);
}

function AuthCheckingMonitoringOperasional({ children }) {
  return checkAuthorized([99, 1, 4, 6, 3, 15, 16, 17, 19, 9, 25, 24, 33], children);
}

function AuthCheckingMonitoringApproval({ children }) {
  return checkAuthorized([99, 1, 15, 16, 17, 19, 24, 33], children);
}

function AuthCheckingMonitoringSK({ children }) {
  return checkAuthorized([99, 1, 2, 19, 25, 24, 33], children);
}

function AuthCheckingMonitoringAprovalProv({ children }) {
  return checkAuthorized([99, 1, 19], children);
}

function AuthCheckingMonitoringAduan({ children }) {
  return checkAuthorized([99, 1, 2, 19, 15, 16, 17, 24, 33, 25, 19], children);
}

function AuthCheckingMonitoringPersen({ children }) {
  return checkAuthorized([99, 1, 2, 15, 16, 17, 19, 25, 24, 33], children);
}

function AuthCheckingMonitoringPembayaran({ children }) {
  return checkAuthorized([99, 1, 10, 3, 4, 6, 15, 16, 17, 19, 25, 24, 33], children);
}

function AuthCheckingTabulasiPusat({ children }) {
  return checkAuthorized([99, 19, 8, 2, 1, 9, 3, 6, 4, 11, 12, 13, 14, 20, 21, 22, 23, 24, 33, 31], children);
}

function AuthCheckingSumberPendanaan({ children }) {
  return checkAuthorized([99, 1, 6, 15, 16, 17, 24, 33, 1, 19], children);
}

function AuthCheckingKRS({ children }) {
  return checkAuthorized([99, 1, 19, 25, 26, 27, 28], children);
}

function AuthCheckingKRSUploadSK({ children }) {
  return checkAuthorized([99, 19, 9, 15, 16, 17, 28, 29, 30], children);
}

function AuthCheckingKRSPengumpulSK({ children }) {
  return checkAuthorized([99, 1, 19, 25], children);
}

function AuthCheckingKRSPengolaSK({ children }) {
  return checkAuthorized([99, 1, 19, 26], children);
}

function AuthCheckingKRSVerifikatorSK({ children }) {
  return checkAuthorized([99, 19, 27], children);
}

function AuthCheckingVervalMonitoringAPBN({ children }) {
  return checkAuthorized([99, 1, 2, 3, 6, 9, 15, 16, 17, 19, 26, 27, 24, 33], children);
}

function AuthCheckingVervalCetakSDM({ children }) {
  return checkAuthorized([99, 1, 15, 16, 17, 19, 26, 27, 24, 33, 28, 29, 30], children);
}

function AuthCheckingVervalMonitoringAPBD({ children }) {
  return checkAuthorized([99, 1, 2, 3, 6, 9, 15, 16, 17, 19, 26, 27, 24, 33, 28, 29, 30], children);
}

function AuthCheckingECDIDanLokus({ children }) {
  return checkAuthorized([1, 2, 3, 4, 6, 9, 19, 24, 33, 99], children);
}

function checkAuthorized(roles, children) {
  const checkRoleLogin = getRoleID();
  return roles.includes(checkRoleLogin) ? children : <UnauthorizedRedirect />;
}

function UnauthorizedRedirect() {
  const location = useLocation();
  return <Navigate to="/401" replace state={{ path: location.pathname }} />;
}

function getRoleID() {
  const rest = JSON.parse(localStorage.getItem('authority'));
  return rest[0].attributes.user.roleID;
}

function NocheckAuthorized(roles, children) {
  const checkRoleLogin = getRoleID();
  return !roles.includes(checkRoleLogin) ? children : <UnauthorizedRedirect />;
}

// const REFRESH_INTERVAL = 2 * 60 * 60 * 1000;

// function useAutoRefresh() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const refresh = () => {
//       window.location.reload();
//     };

//     const timeoutId = setTimeout(refresh, REFRESH_INTERVAL);

//     return () => {
//       clearTimeout(timeoutId);
//     };
//   }, [navigate]);
// }

function App() {
  useEffect(() => {
    // Data yang ingin Anda kirim
    const data = {
      kategoriAplikasi: 'siga pk'
    };

    // Konfigurasi untuk permintaan POST
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    // URL endpoint untuk POST
    const url = 'https://siga-api-gateway.bkkbn.go.id/landing-page-be-v2/statistic-pengunjung/insertStatistikKunjungan?kategoriAplikasi=siga%20pk';
    // Melakukan permintaan POST menggunakan Axios
    axios.post(url, config)
      .then((response) => {
      })
      .catch((error) => {
      });
  }, []);

  return (
    <Routes>
      <Route element={<WithoutNav />}>
        <Route path='/login' element={<CheckAuth><Login /></CheckAuth>} />
        <Route path='/tracking' element={<Tracking />} />
        <Route path='/tabulasi' element={<Tabulasi />} />
        <Route path='/tabulasi/:group/:table' element={<TabulasiDetail />} />
        <Route path='/tabulasi-krs' element={<TabulasiKRS />} />
        <Route path='/tabulasi-krs/:group/:table' element={<TabulasiKRSDetail />} />
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route element={<WithNav />}>
        <Route path='/' element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path='/transfer' element={<RequireAuth><Transfer /></RequireAuth>} />
        <Route path='/test' element={<RequireAuth><TestTable /></RequireAuth>} />
        <Route path='/DetailDasboard' element={<RequireAuth><DetailDasboard /></RequireAuth>} />
        <Route path='/laporan-tabulasi' element={<RequireAuth><AuthCheckingTabulasiPusat><Tabulasi /></AuthCheckingTabulasiPusat></RequireAuth>} />
        <Route path='/laporan-tabulasi-krs' element={<RequireAuth><TabulasiKRS /></RequireAuth>} />
        <Route path='/LaporanPemanfaatan' element={<RequireAuth><LaporanPemanfaatan /></RequireAuth>} />
        <Route path='/laporan-tabulasi/:group/:table' element={<RequireAuth><AuthCheckingTabulasiPusat><TabulasiDetail /></AuthCheckingTabulasiPusat></RequireAuth>} />
        <Route path='/laporan-tabulasi-krs/:group/:table' element={<RequireAuth><TabulasiKRSDetail /></RequireAuth>} />
        {/* pengaturan */}
        <Route path='/user' element={<RequireAuth><AuthCheckingUserWilayah><User /></AuthCheckingUserWilayah></RequireAuth>} />
        <Route path='/role' element={<RequireAuth><Role /></RequireAuth>} />
        <Route path='/LokusDanECDI' element={<RequireAuth><AuthCheckingECDIDanLokus><LokusDanECDI /></AuthCheckingECDIDanLokus></RequireAuth>} />

        <Route path='/target' element={<RequireAuth><Target /></RequireAuth>} />
        <Route path='/create-user' element={<RequireAuth><CreateUser /></RequireAuth>} />
        <Route path='/edit-user/:username' element={<RequireAuth><EditUser /></RequireAuth>} />
        <Route path='/indikator' element={<RequireAuth><Indikator /></RequireAuth>} />
        <Route path='/aktivasiPWA' element={<RequireAuth><AktivasiPWA /></RequireAuth>} />
        <Route path='/surat-keputusan' element={<RequireAuth><AuthCheckingUploadSK><SuratKeputusan /></AuthCheckingUploadSK></RequireAuth>} />

        {/* monitoring */}
        <Route path='/laporan-pemutakhiran' element={<RequireAuth><Pemutakhiran /></RequireAuth>} />
        <Route path='/history' element={<RequireAuth><History /></RequireAuth>} />
        <Route path='/laporan-implikasi' element={<RequireAuth><Implikasi /></RequireAuth>} />
        <Route path='/rekap-status' element={<RequireAuth><RekapStatus /></RequireAuth>} />
        <Route path='/detail-status' element={<RequireAuth><DetailStatus /></RequireAuth>} />
        <Route path='/targetvsactual' element={<RequireAuth><TargetVsActual /></RequireAuth>} />
        <Route path='/metode-pendataan' element={<RequireAuth><Metode /></RequireAuth>} />
        <Route path='/notifsiga' element={<RequireAuth><Notifsiga /></RequireAuth>} />
        <Route path='/rekapitulasi' element={<RequireAuth><Rekapitulasi /></RequireAuth>} />
        <Route path='/edit-profile' element={<RequireAuth><UserProfile /></RequireAuth>} />

        <Route path='/formulir-capi' element={<RequireAuth><AuthCheckingFormulirCAPI><FormulirCAPI /></AuthCheckingFormulirCAPI></RequireAuth>} />
        <Route path='/anomaliPendataan' element={<RequireAuth><Anomali /></RequireAuth>} />
        <Route path='/approval-pemutakhiran' element={<RequireAuth><AuthCheckingApprovalPemutakhiran><Approval /></AuthCheckingApprovalPemutakhiran></RequireAuth>} />
        <Route path='/approval-pemutakhiranAPBD' element={<RequireAuth><AuthCheckingApprovalPemutakhiran><ApprovalAPBD /></AuthCheckingApprovalPemutakhiran></RequireAuth>} />

        <Route path='/reject-pemutakhiran' element={<RequireAuth><AuthCheckingApprovalPemutakhiran><ApprovalRejectPendataan /></AuthCheckingApprovalPemutakhiran></RequireAuth>} />
        <Route path='/formulir-papi' element={<RequireAuth><AuthCheckingFormulirPAPI><PendataanSupervisor /></AuthCheckingFormulirPAPI></RequireAuth>} />
        <Route path='/data-tunggakan' element={<RequireAuth><MonitoringDataTunggakan /></RequireAuth>} />
        <Route path='/approval-operasional' element={<RequireAuth><AuthCheckingApprovalOperasional><ApprovalOperasional /></AuthCheckingApprovalOperasional></RequireAuth>} />

        <Route path='/monitoring-pus' element={<RequireAuth><PUS /></RequireAuth>} />

        <Route path='/rekapPendataan' element={<RequireAuth><RekapPendataan /></RequireAuth>} />

        <Route path='/monitoring-pendataan' element={<RequireAuth><MonitoringPendataan /></RequireAuth>} />
        <Route path='/monitoring-dibayar' element={<RequireAuth><AuthCheckingMonitoringApproval><MonitoringBelumDibayar /></AuthCheckingMonitoringApproval></RequireAuth>} />
        <Route path='/monitoring-perbandingan' element={<RequireAuth><AuthCheckingMonitoringOperasional><MonitoringPerbandingan /></AuthCheckingMonitoringOperasional></RequireAuth>} />
        <Route path='/monitoringApprovalProv' element={<RequireAuth><AuthCheckingMonitoringAprovalProv><MonitoringApprovProv /></AuthCheckingMonitoringAprovalProv></RequireAuth>} />
        <Route path='/laporan-penanggan' element={<RequireAuth><LaporanPenangganan /></RequireAuth>} />
        <Route path='/hasil-laporan' element={<RequireAuth><HasilLaporan /></RequireAuth>} />
        <Route path='/monitoring-pengguna-tabulasi' element={<RequireAuth><MonitoringPenggunaTabulasi /></RequireAuth>} />


        <Route path='/krs/KRSPengelola' element={<RequireAuth><AuthCheckingKRSPengolaSK><KRSPengelola /></AuthCheckingKRSPengolaSK></RequireAuth>} />
        <Route path='/krs/KRSVerifikator' element={<RequireAuth><AuthCheckingKRSVerifikatorSK><KRSVerifikator /></AuthCheckingKRSVerifikatorSK></RequireAuth>} />
        <Route path='/krs/KRSMonitoringAduan' element={<RequireAuth><AuthCheckingKRS> <KRSMonitoringAduan /> </AuthCheckingKRS></RequireAuth>} />
        <Route path='/krs/KRSCetakSDM' element={<RequireAuth><AuthCheckingVervalCetakSDM> <KRSCetakSDM /> </AuthCheckingVervalCetakSDM></RequireAuth>} />
        <Route path='/krs/KRSMonitoringApprovProv' element={<RequireAuth><AuthCheckingKRS> <KRSMonitoringApprovProv /> </AuthCheckingKRS></RequireAuth>} />
        <Route path='/krs/KRSMonitoringSumberPendanaan' element={<RequireAuth><AuthCheckingKRS> <KRSMonitoringSumberPendanaan /> </AuthCheckingKRS></RequireAuth>} />
        <Route path='/krs/KRSApprovalRejectPendataan' element={<RequireAuth><AuthCheckingKRS> <KRSApprovalRejectPendataan /> </AuthCheckingKRS></RequireAuth>} />
        <Route path='/krs/KRSApprovalRejectPendataanKab' element={<RequireAuth><AuthCheckingKRS> <KRSApprovalRejectPendataanKab /> </AuthCheckingKRS></RequireAuth>} />
        <Route path='/krs/KRSUploadSuratKeputusan' element={<RequireAuth><AuthCheckingKRSUploadSK> <KRSUploadSuratKeputusan /> </AuthCheckingKRSUploadSK></RequireAuth>} />
        <Route path='/krs/KRSSumberPendanaan' element={<RequireAuth> <KRSSumberPendanaan /></RequireAuth>} />

        {/* APBN */}
        <Route path='/APBN/monitoring-approval' element={<RequireAuth><AuthCheckingMonitoringApproval><MonitoringApprovalAPBN /></AuthCheckingMonitoringApproval></RequireAuth>} />
        <Route path='/APBN/monitoring-Operasional' element={<RequireAuth><AuthCheckingMonitoringOperasional><MonitoringOperasionalAPBN /></AuthCheckingMonitoringOperasional></RequireAuth>} />
        <Route path='/APBN/monitoring-pembayaran' element={<RequireAuth><AuthCheckingMonitoringPembayaran><MonitoringPembayaranAPBN /></AuthCheckingMonitoringPembayaran></RequireAuth>} />
        <Route path='/APBN/monitoring-persen' element={<RequireAuth><AuthCheckingMonitoringPersen><MonitoringPersenAPBN /></AuthCheckingMonitoringPersen></RequireAuth>} />
        <Route path='/APBN/rekapitulasi-pembayaran' element={<RequireAuth><AuthCheckingMonitoringPembayaran><RekapPembayaranAPBN /></AuthCheckingMonitoringPembayaran></RequireAuth>} />
        <Route path='/APBN/MonitoringAduan' element={<RequireAuth><AuthCheckingMonitoringAduan><MonitoringAduanAPBN /></AuthCheckingMonitoringAduan></RequireAuth>} />
        <Route path='/APBN/monitoring-Pendanaankk' element={<RequireAuth><AuthCheckingMonitoringPembayaran><MonitoringCetakKKSumberPendanaanAPBN /></AuthCheckingMonitoringPembayaran></RequireAuth>} />

        {/* APBD */}
        <Route path='/APBD/monitoring-approval' element={<RequireAuth><AuthCheckingMonitoringApproval><MonitoringApprovalAPBD /></AuthCheckingMonitoringApproval></RequireAuth>} />
        <Route path='/APBD/monitoring-Operasional' element={<RequireAuth><AuthCheckingMonitoringOperasional><MonitoringOperasionalAPBD /></AuthCheckingMonitoringOperasional></RequireAuth>} />
        <Route path='/APBD/monitoring-pembayaran' element={<RequireAuth><AuthCheckingMonitoringPembayaran><MonitoringPembayaranAPBD /></AuthCheckingMonitoringPembayaran></RequireAuth>} />
        <Route path='/APBD/monitoring-persen' element={<RequireAuth><AuthCheckingMonitoringPersen><MonitoringPersenAPBD /></AuthCheckingMonitoringPersen></RequireAuth>} />
        <Route path='/APBD/rekapitulasi-pembayaran' element={<RequireAuth><AuthCheckingMonitoringPembayaran><RekapPembayaranAPBD /></AuthCheckingMonitoringPembayaran></RequireAuth>} />
        <Route path='/APBD/MonitoringAduan' element={<RequireAuth><AuthCheckingMonitoringAduan><MonitoringAduanAPBD /></AuthCheckingMonitoringAduan></RequireAuth>} />
        <Route path='/APBD/monitoring-Pendanaankk' element={<RequireAuth><AuthCheckingMonitoringPembayaran><MonitoringCetakKKSumberPendanaanAPBD /></AuthCheckingMonitoringPembayaran></RequireAuth>} />

        <Route path='/monitoring-sk' element={<RequireAuth><AuthCheckingMonitoringSK><MonitoringSK /></AuthCheckingMonitoringSK></RequireAuth>} />


        {/* APBD  */}

        <Route path='/krs/APBD/KRSMonitoringApproval' element={<RequireAuth><AuthCheckingVervalMonitoringAPBD> <KRSMonitoringApprovalAPBD /> </AuthCheckingVervalMonitoringAPBD></RequireAuth>} />
        <Route path='/krs/APBD/KRSMonitoringOperasional' element={<RequireAuth><AuthCheckingVervalMonitoringAPBD> <KRSMonitoringOperasionalAPBD /> </AuthCheckingVervalMonitoringAPBD></RequireAuth>} />
        <Route path='/krs/APBD/KRSmonitoringPembayaran' element={<RequireAuth><AuthCheckingVervalMonitoringAPBD> <KRSmonitoringPembayaranAPBD /> </AuthCheckingVervalMonitoringAPBD></RequireAuth>} />
        <Route path='/krs/APBD/KRSMonitoringPersen' element={<RequireAuth><AuthCheckingVervalMonitoringAPBD> <KRSMonitoringPersenAPBD /> </AuthCheckingVervalMonitoringAPBD></RequireAuth>} />
        <Route path='/krs/APBD/KRSPembayaran' element={<RequireAuth><AuthCheckingVervalMonitoringAPBD> <KRSPembayaranAPBD /> </AuthCheckingVervalMonitoringAPBD></RequireAuth>} />
        <Route path='/krs/APBD/KRSRekapitulasiPembayaran' element={<RequireAuth><AuthCheckingVervalMonitoringAPBD> <KRSRekapitulasiPembayaranAPBD /> </AuthCheckingVervalMonitoringAPBD></RequireAuth>} />

        {/* APBN  */}
        <Route path='/krs/APBN/KRSPengumpulan' element={<RequireAuth><AuthCheckingKRSPengumpulSK><KRSPengumpulan /> </AuthCheckingKRSPengumpulSK></RequireAuth>} />
        <Route path='/krs/APBN/KRSMonitoringApproval' element={<RequireAuth><AuthCheckingVervalMonitoringAPBN> <KRSMonitoringApprovalAPBN /> </AuthCheckingVervalMonitoringAPBN></RequireAuth>} />
        <Route path='/krs/APBN/KRSMonitoringOperasional' element={<RequireAuth><AuthCheckingVervalMonitoringAPBN> <KRSMonitoringOperasionalAPBN /> </AuthCheckingVervalMonitoringAPBN></RequireAuth>} />
        <Route path='/krs/APBN/KRSmonitoringPembayaran' element={<RequireAuth><AuthCheckingVervalMonitoringAPBN> <KRSmonitoringPembayaranAPBN /> </AuthCheckingVervalMonitoringAPBN></RequireAuth>} />
        <Route path='/krs/APBN/KRSMonitoringPersen' element={<RequireAuth><AuthCheckingVervalMonitoringAPBN> <KRSMonitoringPersenAPBN /> </AuthCheckingVervalMonitoringAPBN></RequireAuth>} />
        <Route path='/krs/APBN/KRSPembayaran' element={<RequireAuth><AuthCheckingVervalMonitoringAPBN> <KRSPembayaranAPBN /> </AuthCheckingVervalMonitoringAPBN></RequireAuth>} />
        <Route path='/krs/APBN/KRSRekapitulasiPembayaran' element={<RequireAuth><AuthCheckingVervalMonitoringAPBN> <KRSRekapitulasiPembayaranAPBN /> </AuthCheckingVervalMonitoringAPBN></RequireAuth>} />
        <Route path='/krs/APBN/KRSmonitoringSK' element={<RequireAuth><AuthCheckingVervalMonitoringAPBN> <KRSmonitoringSKAPBN /> </AuthCheckingVervalMonitoringAPBN></RequireAuth>} />

        <Route path='/pengaturanTarif/OperasionalPembayaran' element={<RequireAuth><AuthCheckingAdmin> <OperasionalPembayaran /> </AuthCheckingAdmin></RequireAuth>} />
        <Route path='/pengaturanTarif/DaftarBank' element={<RequireAuth> <AuthCheckingAdmin><DaftarBank /> </AuthCheckingAdmin> </RequireAuth>} />
        <Route path='/pengaturanTarif/DaftarPajak' element={<RequireAuth> <AuthCheckingAdmin> <DaftarPajak /> </AuthCheckingAdmin> </RequireAuth>} />
        <Route path='/pengaturanTarif/SuratKeputusanUpload' element={<RequireAuth><AuthCheckingPengaturanPembayaranRef> <SuratKeputusanUpload /> </AuthCheckingPengaturanPembayaranRef></RequireAuth>} />

        <Route path='/SumberPendanaan' element={<RequireAuth><AuthCheckingSumberPendanaan> <MonitoringSumberPendanaan /> </AuthCheckingSumberPendanaan></RequireAuth>} />


        <Route path='/cetak-formulir-kk' element={<RequireAuth><CetakFormulirKK /></RequireAuth>} />
        <Route path='/daftar-sdm' element={<RequireAuth><CetakSDM /></RequireAuth>} />

        {/* NoAuth */}
        <Route path='/401' element={<RequireAuth><Page401 /></RequireAuth>} />


      </Route>
    </Routes>
  );
}

export default App;