import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  CssBaseline,
  TextField,
  Typography,
  Paper,
  Box,
  Grid,
  withStyles,
} from "@material-ui/core";
import Swal from "sweetalert2";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import authServices from "../services/authServices2";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url('/assets/img/newbg.jpeg')`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(12, 16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#ef012d",
  },
  form: {
    width: "100%",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
  },
  divTitleLeft: {
    display: "flex",
    // justifyContent: 'center',
    // alignItems: 'center',
    // height: '100%',
    padding: "1rem",
  },
  titleLeft: {
    fontSize: "22px",
    textShadow: "-1px 0 black, 0 2px black, 1px 0 black, 0 -1px black",
    color: "#fff",
    fontWeight: "700",
  },
  divTitleMid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 200px)",
    paddingTop: "7rem",
  },
  titleMid: {
    fontSize: "16px",
    textShadow: "-1px 0 white, 0 2px white, 1px 0 white, 0 -1px white",
    color: "#333",
    fontStyle: "italic",
  },
  leftQuote: {
    position: "absolute",
    marginLeft: "-29px",
    marginTop: "-20px",
    fontSize: "64px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

function Login(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [fields, setFields] = useState({ password: "", email: "" });
  const [errors, setErrors] = useState({ password: false, email: false });
  const [blocking, setBlocking] = useState(false);
  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = true;
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = true;
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setBlocking(true);
      localStorage.clear();
      //const url = 'auth/signin';
      const form = {
        username: fields.email,
        password: fields.password,
      };
      authServices
        .post("auth/signin", form)
        .then((res) => {
          setBlocking(false);
          if (!res.data.accessToken || res.data.message === 'Bad credentials' || res.data.message == 'UnAuthorized') {
            Swal.fire({
              title: "Peringatan",
              icon: "warning",
              text: "Terdapat kesalahan ID atau password.",
            });
          } else if (res.data.accessToken.length > 0) {
            Swal.fire({
              title: "Sukses!",
              icon: "success",
              text: "Login Berhasil.",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              localStorage.setItem("cekToken", "true");
              localStorage.setItem("token", res.data.accessToken);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              localStorage.setItem(
                "username",
                res.data.authorities[0].attributes.user_name
              );
              localStorage.setItem(
                "name",
                res.data.authorities[0].attributes.user.namaLengkap
              );
              localStorage.setItem(
                "golongan",
                res.data.authorities[0].attributes.user.golongan
              );
              localStorage.setItem(
                "statusPekerjaan",
                res.data.authorities[0].attributes.user.statusPekerjaan
              );
              localStorage.setItem(
                "namaIbuKandung",
                res.data.authorities[0].attributes.user.namaIbuKandung
              );
              localStorage.setItem(
                "bank",
                res.data.authorities[0].attributes.user.backgroundColor
              );
              localStorage.setItem(
                "no_rekening",
                res.data.authorities[0].attributes.user.no_rekening
              );
              localStorage.setItem(
                "npwp",
                res.data.authorities[0].attributes.user.NPWP
              );
              localStorage.setItem(
                "status_npwp",
                res.data.authorities[0].attributes.user.status_npwp
              );
              localStorage.setItem(
                "email",
                res.data.authorities[0].attributes.user.email
              );
              localStorage.setItem(
                "noTelepon",
                res.data.authorities[0].attributes.user.noTelepone
              );
              localStorage.setItem(
                "authority",
                JSON.stringify(res.data.authorities)
              );
              navigate(state?.path || "/");
            });
          } else {
            Swal.fire({
              title: "Peringatan",
              icon: "warning",
              text: "ID anda belum terdaftar.",
            });
          }
        })
        .catch((error) => {
          setBlocking(false);
          if (
            error &&
            error.response &&
            (error.response.status === 401 || error.response.status === 404 || error.response.status === 500)
          ) {
            Swal.fire({
              title: "Peringatan",
              icon: "warning",
              text: "Terdapat kesalahan ID atau password.",
            });
          } else {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Silakan cek koneksi jaringan internet anda.",
            });
          }
        });
    } else {
      Swal.fire({
        title: "Peringatan",
        icon: "warning",
        text: "Masukkan ID dan password anda.",
      });
    }
  };

  const handleChange = (e, param) => {
    e.preventDefault();
    switch (param) {
      case "email":
        setFields({ ...fields, email: e.target.value });
        setErrors({ ...errors, email: false });
        break;
      case "password":
        setFields({ ...fields, password: e.target.value });
        setErrors({ ...errors, password: false });
        break;
      default:
        fields[param] = e.target.value;
        break;
    }
  };

  const { classes } = props;

  return (
    <BlockUi tag="div" blocking={blocking} message="Please wait">
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className={classes.image}>
          <div className={classes.divTitleLeft}>
            <span className={classes.titleLeft}>
              Selamat Datang
              <br />
              Di Portal Pemutakhiran PK-24
            </span>
          </div>
          <div className={classes.divTitleMid}>
            <span className={classes.titleMid}>
              <div className={classes.leftQuote}>&ldquo;</div>
              Ada kertas diberi perekat
              <br />
              Kertasnya kuat dalamnya hangat
              <br />
              Selamat aktivitas wahai Team Hebat
              <br />
              Semoga kita tetap semangat
            </span>
          </div>
        </Grid>
        <Grid
          style={{ borderLeft: "4px solid #2369AF" }}
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <div className="col-xs-12" style={{ textAlign: "center" }}>
              <img
                src="../assets/img/logo2024.png"
                alt="login"
                width="350"
                height="80"
              />
            </div>
            <span className={classes.title}>Sign In</span>
            <form className={classes.form} onSubmit={handleLogin} noValidate>
              <TextField
                onChange={(e) => handleChange(e, "email")}
                value={fields["email"]}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="ID Pengguna"
                name="email"
                autoComplete="email"
                autoFocus
                error={errors["email"]}
              />
              <TextField
                onChange={(e) => handleChange(e, "password")}
                value={fields["password"]}
                style={{ backgroundColor: '#e8f0fe' }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Kata Sandi"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                error={errors["password"]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ backgroundColor: '#e8f0fe' }}
                    >
                      <IconButton
                        style={{ backgroundColor: '#e8f0fe' }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <FormControlLabel className="fs12" style={{width: '150px'}}
                                control={<Checkbox onChange={(e) => this.handleChange(e, 'remember_me')} value={fields["remember_me"]} name="remember_me" color="primary"/>}
                                label={<Typography className="smallCheck">Ingatkan Saya</Typography>}
                          /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                // color="primary"
                className={classes.submit}
                style={{
                  backgroundColor: "#2369AF",
                  fontSize: "12px",
                  color: "white",
                  padding: "13px",
                  margin: "16px 0",
                }}
              >
                MASUK
              </Button>
              <br></br>
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  cursor: "pointer",
                  color: "#333",
                }}
              >

                <Link
                  to="/tracking"
                  target={"_blank"}
                  style={{ textDecoration: "none", }}
                >
                  Cek Bukti Bayar Dan Pengaduan
                </Link>
              </div>
              <br></br>
              <div
                className="col-xs-12"
                style={{ textAlign: "center", marginBottom: "5px" }}
              >
                <img
                  src="../assets/img/bkkbn.png"
                  alt="login"
                  width="95"
                  height="60"
                  style={{ marginRight: "1rem" }}
                />
                <img
                  src="../assets/img/kominfo.png"
                  alt="login"
                  width="60"
                  height="60"
                />
              </div>
              <Box mt={5}>
                <div className="text-muted">
                  <Typography
                    className="textCpr"
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {"Copyright © "}
                    <b>BKKBN</b> {"2024"} <b>|</b> v1.0.4
                  </Typography>
                </div>
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </BlockUi>
  );
}
export default withStyles(styles, { withTheme: true })(Login);
