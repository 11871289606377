import React from 'react';
//------react-bootstrap-table---------------
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

const Table = (props) => {

    function customFormatter(cell, row, param) {
        // console.log(row, 'row.status_active');
        switch (param) {
            case 'action':
                return <div>
                    <span className="btnInTable">
                        <Button className="btn-success btn-brand btn-sm icon" onClick={(e) => props.handleEdit(e, row)}><i className="icon-pencil5"> </i></Button>
                    </span>
                </div>

            case 'statusAktif':

                let label = ''
                let color = ''
                if (cell === 'Aktif') {
                    label = 'Aktif'
                    color = 'success'
                } else if (cell === 'Non-Aktif') {
                    label = 'Non-Aktif'
                    color = 'error'
                } else {
                    label = '-'
                    color = 'primary'
                }
                return <Tooltip>
                    <Chip label={label} color={color} size="small" />
                </Tooltip>
            default:
                return cell;
        }
    }

    const renderShowsTotal = (start, to, total) => (
        <span className="react-bootstrap-table-pagination-total" style={{ marginTop: '12px', position: 'absolute' }}>
            <b>Total</b>: {total}
        </span>
    );

    const options = {
        page: 1,
        sizePerPageList: [{
            text: '1', value: 1
        }, {
            text: '10', value: 10
        }], // you can change the dropdown list for size per page
        sizePerPage: props.spp,  // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: '<', // Previous page button text
        nextPage: '>', // Next page button text
        firstPage: '<<', // First page button text
        lastPage: '>>', // Last page button text
        hideSizePerPage: true,
        paginationShowsTotal: false,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
    };

    return (
        <div>
            <BootstrapTable data={props.datas} striped hover version='4'
                width='1260px'
                // cellEdit={ cellEditProp }
                search={false}
                //pagination={paginationFactory()}
                options={options}
                keyField='id'
                wrapperClass='class-responsive'
                pagination={true}
            >
                {
                    props.tableHead.map((column, index) => {
                        return <TableHeaderColumn
                            row={column.row}
                            rowSpan={column.rowSpan}
                            colSpan={column.colSpan}
                            width={column.width}
                            key={column.dataField}
                            dataField={column.dataField}
                            headerAlign={column.headerAlign}
                            dataAlign={column.dataAlign}
                            dataSort={column.dataSort}
                            tdStyle={column.tdStyle}
                            dataFormat={(cell, row) => customFormatter(cell, row, column.dataFormat)}
                            editable={false}
                            thStyle={{ whiteSpace: 'normal' }}
                        >
                            {column.title}
                        </TableHeaderColumn>
                    })
                }
            </BootstrapTable>
        </div>
    )
}

export default Table