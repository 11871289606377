import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { FaBars } from 'react-icons/fa';
import { ImSwitch } from 'react-icons/im';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';


const Header = ({ handleToggleSidebar }) => {
  let nameProfile = localStorage.getItem("authority") ? JSON.parse(localStorage.getItem("authority"))[0].attributes.user.namaLengkap : '-';
  const authed = localStorage.getItem('token')
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault()
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin akan keluar dari aplikasi?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
          localStorage.clear();
          navigate("/login");
      }
    });
  };

  return (
    <div className="header">
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div className="logout">
        {authed && 
          <>
            <Button className="btn-logout" variant="outlined" onClick={handleLogout}> <ImSwitch /> </Button>
            <Link to="/edit-profile" className='usernm' style={{textDecoration: 'none', marginTop: '3px', color: '#333'}}>Hi, <b><i>{nameProfile}</i></b></Link>
          </>
        }
      </div>
    </div>
  );
};

export default Header;
