import React from 'react';
import { BiDollar, BiSelectMultiple } from 'react-icons/bi';
import { FaFileInvoiceDollar, FaListUl, FaSearchDollar } from 'react-icons/fa';
import { HiBadgeCheck } from 'react-icons/hi';
import { ImClipboard, ImCogs, ImDisplay, ImFileText, ImHome, ImList, ImLocation2, ImNewspaper, ImTable2, ImTarget, ImUsers } from 'react-icons/im';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const Aside = ({ toggled, handleToggleSidebar, collapsed, handleCollapsedChange }) => {
  const roleUserLogin = localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null
  // const smartPhone = localStorage.getItem("authority") ? JSON.parse(localStorage.getItem('authority'))[0].attributes.user.Smartphone : null
  return (
    <ProSidebar
      image={false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="lg"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        {collapsed ?
          <div style={{ textAlign: 'center', padding: '12px 0 14px', marginLeft: '-7px' }}>
            <img src='../../assets/img/logo-min.png' width="32" alt="login" />
          </div>
          :
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "0.95rem 0.5rem 0.6rem" }}>
            <img src='../../assets/img/logo2024.png' width="100" height="35" alt="login" style={{ marginRight: 10 }} />
            <div style={{ borderLeft: '1px solid #000', height: '50px', margin: '0 10px' }}></div>
            <img src='../../assets/img/logoVerval2.png' width="100" height="25" alt="logoVerval" />
          </div>
        }
      </SidebarHeader>

      {(process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'development') &&
        <SidebarContent>
          <SimpleBar style={{ maxHeight: 'calc(100vh - 124px)' }}>
            <Menu iconShape="circle">
              <MenuItem icon={<ImHome />}> Dashboard <Link to="/" /> </MenuItem>
              {([28, 29, 30].every(role => role !== roleUserLogin)) &&
                <SubMenu title="Pengaturan" icon={<ImCogs />}>
                  {([25, 26, 27, 20, 21, 22, 23, 31].every(role => role !== roleUserLogin)) &&
                    <MenuItem icon={<ImLocation2 />}>Master Wilayah <Link to="/transfer" /></MenuItem>}
                  {([20, 21, 22, 23, 31].every(role => role !== roleUserLogin)) &&
                    <MenuItem icon={<ImUsers />}>User <Link to="/user" /></MenuItem>}
                  {([25, 26, 27, 20, 21, 22, 23, 31].every(role => role !== roleUserLogin)) &&
                    <MenuItem icon={<ImTarget />}>Target KK Wilayah <Link to="/target" /></MenuItem>}
                  {(roleUserLogin === 99 || [1, 2, 3, 4, 6, 9, 19, 33, 24].includes(roleUserLogin)) &&
                    <MenuItem icon={<ImFileText />}>Lokus  <Link to="/LokusDanECDI" /></MenuItem>}
                </SubMenu>
              }
              <SubMenu title="Pengaturan Pembayaran" icon={<BiDollar />}>
                {(roleUserLogin === 99 || [1, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<ImFileText />}>Daftar Bank <Link to="/pengaturanTarif/DaftarBank" /></MenuItem>}
                {(roleUserLogin === 99 || [1, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<ImFileText />}>Operasional Pembayaran<Link to="/pengaturanTarif/OperasionalPembayaran" /></MenuItem>}
                {(roleUserLogin === 99 || [1, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<ImFileText />}>Daftar Pajak <Link to="/pengaturanTarif/DaftarPajak" /></MenuItem>}
                {(roleUserLogin === 99 || [1, 19, 99, 9, 2].includes(roleUserLogin)) &&
                  <MenuItem icon={<ImFileText />}>Pejabat Penandatangan Kuitansi<Link to="/pengaturanTarif/SuratKeputusanUpload" /></MenuItem>}
              </SubMenu>
              <SubMenu title="Monitoring" icon={<ImDisplay />}>
                <MenuItem icon={<ImClipboard />}>Metode Pendataan <Link to="/metode-pendataan" /></MenuItem>
                <MenuItem icon={<ImClipboard />}>Rekapitulasi Pendataan PK <Link to="/rekapPendataan" /></MenuItem>
                <MenuItem icon={<ImClipboard />}>Detail Status <Link to="/detail-status" /></MenuItem>


              </SubMenu>
              <SubMenu title="Modul Pembayaran PK" icon={<BiDollar />}>
                {(roleUserLogin === 99 || [1, 2, 3, 4, 5, 6, 18, 19, 15, 16, 17, 33, 24].includes(roleUserLogin)) &&
                  <MenuItem icon={<FaListUl />}>Cetak SDM PK <Link to="/daftar-sdm" /></MenuItem>}
                {(roleUserLogin === 99 || [1, 4, 5, 6, 10, 18, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<ImFileText />}>Formulir CAPI <Link to="/formulir-capi" /></MenuItem>}
                {(roleUserLogin === 99 || [1, 19, 4].includes(roleUserLogin)) &&
                  <MenuItem icon={<ImList />}>Formulir PAPI <Link to="/formulir-papi" /></MenuItem>}
                {(roleUserLogin === 99 || [3, 6, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<HiBadgeCheck />}>Approval Operasional <Link to="/approval-operasional" /></MenuItem>}
                {(roleUserLogin === 99 || [15, 16, 17, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<HiBadgeCheck />}>Approval Pemutakhiran APBN KK <Link to="/approval-pemutakhiran" /></MenuItem>}
                {(roleUserLogin === 99 || [28, 29, 30, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<HiBadgeCheck />}>Approval Pemutakhiran APBD KK <Link to="/approval-pemutakhiranAPBD" /></MenuItem>}
                {(roleUserLogin === 99 || roleUserLogin === 19) &&
                  <MenuItem icon={<HiBadgeCheck />}>Reject Verval KRS BPP <Link to="/reject-pemutakhiran" /></MenuItem>}
                {(roleUserLogin === 99 || [1, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<ImNewspaper />}>Aktivasi Approval <Link to="/monitoringApprovalProv" /></MenuItem>}
                {(roleUserLogin === 99 || [1, 9, 19].includes(roleUserLogin)) &&
                  <MenuItem icon={<ImNewspaper />}>Upload Surat Keputusan Kabupaten <Link to="/surat-keputusan" /></MenuItem>}

                <SubMenu title="Monitoring Bayar">
                  {(roleUserLogin === 99 || [1, 6, 15, 16, 17, 33, 24, 19].includes(roleUserLogin)) &&
                    <MenuItem icon={<ImNewspaper />}>Monitoring Sumber Pendanaan <Link to="/SumberPendanaan" /></MenuItem>}
                  {(roleUserLogin === 99 || [1, 2, 19, 33, 24].includes(roleUserLogin)) &&
                    <MenuItem icon={<ImNewspaper />}>Monitoring Surat Keputusan <Link to="/monitoring-sk" /></MenuItem>}
                  <SubMenu title="APBN">
                    {(roleUserLogin === 99 || [1, 3, 4, 6, 9, 15, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<ImNewspaper />}>Monitoring Approval <Link to="/APBN/monitoring-approval" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 2, 3, 4, 6, 15, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<BiSelectMultiple />}>Monitoring Operasional <Link to="/APBN/monitoring-Operasional" /></MenuItem>}

                    {(roleUserLogin === 99 || [1, 3, 4, 6, 15, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<FaSearchDollar />}>Monitoring Pembayaran <Link to="/APBN/monitoring-pembayaran" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 2, 3, 6, 15, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<FaSearchDollar />}>Monitoring Progress Percent <Link to="/APBN/monitoring-persen" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 3, 4, 6, 15, 10, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<FaFileInvoiceDollar />}>Rekapitulasi Pembayaran <Link to="/APBN/rekapitulasi-pembayaran" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 2, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<ImNewspaper />}>Monitoring Pengaduan <Link to="/APBN/monitoringAduan" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 19].includes(roleUserLogin)) &&
                      <MenuItem icon={<ImNewspaper />}>Monitoring Rekap KK Sumber Pendanaan APBN <Link to="/APBN/monitoring-Pendanaankk" /></MenuItem>}
                  </SubMenu>
                  <SubMenu title="APBD">
                    {(roleUserLogin === 99 || [1, 3, 4, 6, 9, 15, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<ImNewspaper />}>Monitoring Approval <Link to="/APBD/monitoring-approval" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 2, 3, 4, 6, 15, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<BiSelectMultiple />}>Monitoring Operasional <Link to="/APBD/monitoring-Operasional" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 2, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<ImNewspaper />}>Monitoring Surat Keputusan <Link to="/APBD/monitoring-sk" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 3, 4, 6, 15, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<FaSearchDollar />}>Monitoring Pembayaran <Link to="/APBD/monitoring-pembayaran" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 2, 3, 6, 15, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<FaSearchDollar />}>Monitoring Progress Percent <Link to="/APBD/monitoring-persen" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 3, 4, 6, 15, 10, 16, 17, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<FaFileInvoiceDollar />}>Rekapitulasi Pembayaran <Link to="/APBD/rekapitulasi-pembayaran" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 2, 19, 33, 24].includes(roleUserLogin)) &&
                      <MenuItem icon={<ImNewspaper />}>Monitoring Pengaduan <Link to="/APBD/monitoringAduan" /></MenuItem>}
                    {(roleUserLogin === 99 || [1, 19].includes(roleUserLogin)) &&
                      <MenuItem icon={<ImNewspaper />}>Monitoring Rekap KK Sumber Pendanaan APBN <Link to="/APBD/monitoring-Pendanaankk" /></MenuItem>}
                  </SubMenu>


                </SubMenu>
              </SubMenu>
              {(roleUserLogin === 99 || roleUserLogin === 1 || roleUserLogin === 2 || roleUserLogin === 3 || roleUserLogin === 6 || roleUserLogin === 9 || roleUserLogin === 19 || roleUserLogin === 9 || roleUserLogin === 15 || roleUserLogin === 16 || roleUserLogin === 17 || roleUserLogin === 24 || roleUserLogin === 25 || roleUserLogin === 26 || roleUserLogin === 27 || roleUserLogin === 28 || roleUserLogin === 29 || roleUserLogin === 30 || roleUserLogin === 33) &&
                <SubMenu title="Modul Pembayaran KRS" style={{ fontWeight: 'bold' }} icon={<BiDollar />}>
                  {(roleUserLogin === 99 || [1, 19, 9].includes(roleUserLogin)) && <MenuItem icon={<FaListUl />}>Upload Surat Keputusan VERVAL <Link to="/krs/KRSUploadSuratKeputusan" /></MenuItem>}
                  {(roleUserLogin === 99 || [1, 19, 25].includes(roleUserLogin)) && <MenuItem icon={<BiSelectMultiple />}>Pengumpul Desa / Kelurahan APBN<Link to="/krs/APBN/KRSPengumpulan" /></MenuItem>}
                  {(roleUserLogin === 99 || [1, 19, 26].includes(roleUserLogin)) && <MenuItem icon={<BiSelectMultiple />}> Approval Pengolah Kecamatan <Link to="/krs/KRSPengelola" /></MenuItem>}
                  {(roleUserLogin === 99 || [1, 19, 27].includes(roleUserLogin)) && <MenuItem icon={<BiSelectMultiple />}> Approval Verifikator Kecamatan <Link to="/krs/KRSVerifikator" /></MenuItem>}
                  {(roleUserLogin === 99 || [1, 19, 15, 16, 17].includes(roleUserLogin)) && <MenuItem icon={<BiSelectMultiple />}>Approval Pembayaran APBN <Link to="/krs/APBN/KRSPembayaran" /> </MenuItem>}
                  {(roleUserLogin === 99 || [1, 19, 28, 29, 30].includes(roleUserLogin)) && <MenuItem icon={<BiSelectMultiple />}>Approval Pembayaran APBD <Link to="/krs/APBD/KRSPembayaran" /> </MenuItem>}
                  {(roleUserLogin === 99 || roleUserLogin === 19) && <MenuItem icon={<BiSelectMultiple />}>Reject Verval BPP PROVINSI <Link to="/krs/KRSApprovalRejectPendataan" /> </MenuItem>}
                  {(roleUserLogin === 99 || roleUserLogin === 19 || [28, 29, 30].includes(roleUserLogin)) && <MenuItem icon={<BiSelectMultiple />}>Reject Verval BPP KAB KOTA <Link to="/krs/KRSApprovalRejectPendataankab" /> </MenuItem>}
                  {(roleUserLogin === 99 || [1, 19, 15, 16, 17].includes(roleUserLogin)) && <MenuItem icon={<BiSelectMultiple />}>Sumber Pendanaan<Link to="/krs/KRSSumberPendanaan" /> </MenuItem>}
                  {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 28, 29, 30].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />}>Cetak SDM <Link to="/krs/KRSCetakSDM" /></MenuItem>}
                  {(roleUserLogin === 99 || [1, 19].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />}>Aktivasi Approval <Link to="/krs/KRSMonitoringApprovProv" /></MenuItem>}
                  {(roleUserLogin === 99 || [1, 19].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />}>Aduan Permasalahan <Link to="/krs/KRSMonitoringAduan" /></MenuItem>}

                  {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 25, 26, 27, 33, 24, 28, 29, 30, 2, 3, 6, 9
                  ].includes(roleUserLogin)) && // 15, 16, 17,
                    <SubMenu title="Monitoring KRS">
                      {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 25, 26, 27, 33, 24, 2, 3, 6, 9
                      ].includes(roleUserLogin)) && // 15, 16, 17,
                        <SubMenu title="APBN">
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Monitoring Surat Keputusan'>Monitoring Surat Keputusan <Link to="/krs/APBN/KRSmonitoringSK" /></MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 33, 24, 2, 3, 6, 9].includes(roleUserLogin)) && <MenuItem title='Monitoring Operasional' icon={<ImNewspaper />}>Monitoring Operasional  <Link to="/krs/APBN/KRSmonitoringOperasional" /> </MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 33, 24, 26, 27].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Monitoring Approval'>Monitoring Approval  <Link to="/krs/APBN/KRSMonitoringApproval" /> </MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 33, 24].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Monitoring Pembayaran'> Monitoring Pembayaran  <Link to="/krs/APBN/KRSmonitoringPembayaran" /></MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 33, 24].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Monitoring Percent'>Progress Percent  <Link to="/krs/APBN/KRSMonitoringPersen" /></MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 33, 24].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Rekapitulasi Pembayaran'>Rekapitulasi Pembayaran <Link to="/krs/APBN/KRSRekapitulasiPembayaran" /></MenuItem>}
                        </SubMenu>
                      }
                      {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 28, 29, 30, 33, 24, 2, 3, 6, 9
                      ].includes(roleUserLogin)) &&
                        <SubMenu title="APBD">
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 33, 24, 28, 29, 30, 2, 3, 6, 9
                          ].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Monitoring Operasional'>Monitoring Operasional  <Link to="/krs/APBD/KRSmonitoringOperasional" /> </MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 33, 24, 28, 29, 30, 26, 27].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Monitoring Approval'> Monitoring Approval  <Link to="/krs/APBD/KRSMonitoringApproval" /> </MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 33, 24, 28, 29, 30].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Monitoring Pembayaran'> Monitoring Pembayaran  <Link to="/krs/APBD/KRSmonitoringPembayaran" /></MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 33, 24, 28, 29, 30].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Monitoring Percent'>Progress Percent  <Link to="/krs/APBD/KRSMonitoringPersen" /></MenuItem>}
                          {(roleUserLogin === 99 || [1, 19, 15, 16, 17, 26, 27, 33, 24, 28, 29, 30].includes(roleUserLogin)) && <MenuItem icon={<ImNewspaper />} title='Rekapitulasi Pembayaran'>Rekapitulasi Pembayaran <Link to="/krs/APBD/KRSRekapitulasiPembayaran" /></MenuItem>}
                        </SubMenu>}
                    </SubMenu>}
                </SubMenu>

              }
              {(roleUserLogin === 99 || [1, 19, 8, 2, 9, 3, 6, 20, 21, 22, 23, 31].includes(roleUserLogin)) &&
                <MenuItem icon={<ImTable2 />}> Tabulasi <Link to="/laporan-tabulasi" /></MenuItem>}
              {(roleUserLogin === 99 || [1, 19, 8].includes(roleUserLogin)) &&
                <MenuItem icon={<ImDisplay />}> Monitoring Pengguna Tabulasi <Link to="/monitoring-pengguna-tabulasi" /></MenuItem>}
            </Menu>
            <div className="block" style={{ justifyContent: 'center', marginLeft: '-7px', marginTop: '1rem' }}>
              <Switch
                height={16}
                width={30}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={handleCollapsedChange}
                checked={collapsed}
                onColor="#219de9"
                offColor="#bbbbbb"
              />
            </div>
          </SimpleBar>
        </SidebarContent>


      }

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '12px' }}>
            Copyright © <b><i>BKKBN</i></b> 2024 <b>|</b> v1.0.4
          </span>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
