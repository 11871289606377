import React, { Component } from 'react'
import { ImPlay3, ImSearch, ImPrinter } from 'react-icons/im';
import { AiFillLock, AiTwotoneEdit } from 'react-icons/ai';
import { Select as MuiSelect, Box, Dialog, TextField, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, Checkbox, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIDashboard from "../../../services/APIDashboard";
import CommonTable from '../../../commons/table/TableMonitoring';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill, BsInfoCircle } from "react-icons/bs";

import Tooltip from '@mui/material/Tooltip';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import APIReport from '../../../services/APIReport';
import Swal from 'sweetalert2';
import APIPemutakhiran from '../../../services/APIPemutakhiran';
import authServices from '../../../services/authServices';
import APIVerval from '../../../services/APIVerval';

import axios from 'axios';
//import couchdb
import cdb1 from '../../../services/cdb1.json';
import cdb2 from '../../../services/cdb2.json';
import cdb3 from '../../../services/cdb3.json';
import cdb4 from '../../../services/cdb4.json';
import cdbKab from '../../../services/cdbKab1.json';

class OperasionalPembayaran extends Component {
  constructor(props) {
    super(props);
    this.bstable = React.createRef()
    this.state = {
      couchdb: "",
      USER_KEY_PG: process.env.REACT_APP_USER_KEY_PROXY_GATEWAY,
      onSubmit: false,
      openDialog: false,
      panel1: true,
      sizePerPage: 10,
      title: '',
      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      text1: 'KODE',
      text2: 'NAMA KABUPATEN',
      text3: 'TARGET KK',
      text4: 'DIDATA',
      text5: 'TIDAK DITEMUI',
      text6: 'DITOLAK',
      text7: 'TIDAK MENJAWAB',
      text8: 'BELUM DIDATA',
      text9: 'ACTION',
      btnLock: false,
      btnUnlock: false,
      dataFilter: [],
      datas: [
      ]
      ,
      data_test: [{ kode: '01', nama: 'RT 001', statusLock: 'unlock' }, { kode: '02', nama: 'RT 002', statusLock: 'lock' }],
      openInfo: false,
      optJenisRekap: [],
      datapromises: [],
      selectedJenisRekapId: 0,
      jenis_rekap: '',
      disApprov: false,
      disAprrovaladmin: false,

      optPeriodePendataan: [{ value: 2022, label: '2022' }, { value: 2021, label: '2021' }],

      selectedPeriodePendataanId: 0,
      periode_pendataan: '',

      tk_wilayah: 0,
      textCari: '',
      disAprrovalProv: false,
      duplicate: 0,
      susulan: 0,
      dupsul: 0,
      loginby: null,

      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

      cariRW: false,
      roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
      selectedRows: [],
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }, { value: '2022', label: '2022' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
      dt_tingkat: [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' }, { value: '5', label: '5' }],
      tingkat: null,
      value_tingkat: null,

      //datas: [],
      //selectDatas: [],
      sendDatas: {
        "tingkatWilayahID": 0,
        "idProv": 0,
        "idKab": 0,
        "idKec": 0,
        "idKel": 0,
        "idRW": 0,
        "periodePendataan": 0
      },
      optRole: [],
      optModul: [{ value: 'PK', label: 'PK' }, { value: 'ECDI', label: 'ECDI' }, { value: 'VERVAL', label: 'VERVAL' }],
      kodeModul: null,
      modul: null,
      namaModul: null,
      kodeRole: null,
      kode: null,
      namaRole: null,
      Role: null,
      apbn: null,
      apbd: null,
      deskripsi: null,
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }, { value: '2022', label: '2022' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
      edit: false,
    }
  }

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      }, () => {

        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if (role === 1 || role === 99) {
          this.setState({
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
          })
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }

            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  getRole = (e) => {
    authServices
      .get("/roles/list?periodeSensus=2024")
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          const rolebaru = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[9], res.data[13], res.data[27], res.data[20], res.data[21], res.data[22]]
          console.log(rolebaru)
          this.setState({
            optRole: rolebaru
          }, () => {
          })
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });
  }

  handleCreate = () => {
    if (this.state.id_provinsi === 0) {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Provinsi wajib dipilih!',
        showConfirmButton: false,
        timer: 1500
      })
      this.setState({
        openDialog: false,
      })

      return;
    }
    this.setState({
      openDialog: true,
      edit: false,
      kodeRole: null,
      kode: null,
      namaRole: null,
      Role: null,
      apbn: null,
      apbd: null,
      modul: null,
      deskripsi: null,

    })
  }

  handleEdit = (e, row) => {
    let idrole = this.state.optRole?.map(({ id, roleName }) => ({ value: id, label: roleName })).filter(el => el.value === row.roleID)
    let options = [];
    if ([3, 4, 6].includes(row.roleID)) {
      options = [{ value: 'PK', label: 'PK' }, { value: 'ECDI', label: 'ECDI' }];
    } else if ([25, 26, 27].includes(row.roleID)) {
      options = [{ value: 'VERVAL', label: 'VERVAL' }];
    } else if (row.roleID === 32) {
      options = [{ value: 'ECDI', label: 'ECDI' }];
    } else if ([5, 10, 18].includes(row.roleID)) {
      options = [{ value: 'PK', label: 'PK' }]
    }
    let modul = options.filter(el => el.value === row.modul)

    console.log(idrole)
    console.log(row.roleID)
    this.setState({
      openDialog: true,
      kodeRole: row.roleID,
      kode: row.kode,
      Role: idrole,
      modul: modul,
      apbn: row.tarifApbn,
      apbd: row.tarifApbd,
      namaModul: row.modul,
      deskripsi: row.deskripsi,
      kodeedit: row.kode,
      onSubmit: false,
      disAPBD: [5, 4, 10, 18, 25].includes(row.roleID),
      edit: true,

    }, () => {
      console.log(this.state.disAPBD)
    })
  }
  sel_Role = (e) => {
    if (e) {
      const { value } = e;

      let options = [];
      if ([3, 4, 6].includes(value)) {
        options = [{ value: 'PK', label: 'PK' }, { value: 'ECDI', label: 'ECDI' }];
      } else if ([25, 26, 27].includes(value)) {
        options = [{ value: 'VERVAL', label: 'VERVAL' }];
      } else if (value === 32) {
        options = [{ value: 'ECDI', label: 'ECDI' }];
      } else if ([5, 10, 18].includes(value)) {
        options = [{ value: 'PK', label: 'PK' }]
      }

      this.setState({
        optModul: options,
        dismodul: false,
        Role: e,
        kodeRole: value,
        namaRole: e.label,
        disAPBD: [5, 4, 10, 18, 25].includes(value),
        apbd: null,
        kodeModul: null,
        modul: null,
        namaModul: null,
      });
    } else {
      this.setState({
        Role: [],
        kodeRole: null,
        namaRole: null,
      });
    }
  };


  sel_Modul = (e) => {
    if (e) {
      const { value } = e;
      this.setState({
        modul: e,
        kodeModul: value,
        namaModul: e.label,
      });
    } else {
      this.setState({
        Role: [],
        kodeRole: null,
        namaRole: null,
      });
    }
  };


  handleOpenClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openDialog: !this.state.openDialog },
      () => { if (!this.state.openDialog) { this.clearForm() } }
    )
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  setHeaderTable = () => {
    let { text1, text2, text3, text4, text5, text6, text7, text8, text9 } = this.state
    var headerColumns = [
      { width: '100', title: text1, dataField: "kode", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: '250', title: text2, dataField: "nama", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: '100', title: text3, dataField: "rowtargetKK", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text4, dataField: "rowJumlahKKygDidata", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text5, dataField: "rowJumlahygTidakDapatDitemui", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text6, dataField: "rowKJmlKKygDitolak", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text7, dataField: "rowJmlKKygTidakMampuMenjawab", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text8, dataField: "rowKKygBelumDidata", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text9, dataField: "rowMutasiOut", headerAlign: 'center', dataAlign: 'right', editable: false },
    ];
    this.setState({ tableHead: headerColumns });
  }

  sel_tingkat = (e) => {

    if (e) {
      this.setState({ tingkat: e, value_tingkat: e.value, })
    } else {
      this.setState({ tingkat: null, value_tingkat: null, })

    }
  }


  componentDidUpdate() {

  }

  componentDidMount() {
    this.setState({ optJenisRekap: [], blocking: false, loginby: localStorage.getItem("username") });
    this.setHeaderTable()
    this.getRole()
    let created = new Date().toISOString().substring(0, 10)
    let temp = new Date("2023-06-25").toISOString().substring(0, 10)
    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    let title2, text11, text21, newData

    if (this.state.roleUserLogin === 6) {
      const data_md = this.state.data_test.filter(data => data.statusLock === 'lock')
      this.setState({ data_test: data_md })
    }

    if (idTingkatWilayah == 0) {
      title2 = ''
      newData = [{ value: 1, label: 'PROVINSI' }, { value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
      { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

      this.setState({
        sendDatas: {
          //"tingkatWilayahID": 0,
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "periodePendataan": 0
        },
        optJenisRekap: newData
      }
      );

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi
      newData = [{ value: 1, label: 'PROVINSI' }, { value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
      { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

      this.setState(
        {
          sendDatas: {
            //"tingkatWilayahID":1,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": 0,
            "idKec": 0,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0
          },
          optJenisRekap: newData
        }
      )

    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten
      newData = [{ value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
      { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

      this.setState(
        {
          sendDatas: {
            //"tingkatWilayahID":2,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
            "idKec": 0,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0
          },
          optJenisRekap: newData
        },
        //() => {this.getBackend(this.state.sendDatas)}

      )


    } else if (idTingkatWilayah === 3) {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan
      newData = [{ value: 3, label: 'KECAMATAN' }, { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]


      this.setState(
        {
          sendDatas: {
            //"tingkatWilayahID":3,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
            "idKec": rest[0].attributes.user.wilKecamatan.id_kecamatan,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0
          },
          optJenisRekap: newData
        }
      )
    } else if (idTingkatWilayah === 4) {
      newData = [{ value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]
      this.setState({
        sendDatas: {
          "tingkatWilayahID": 3,
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "idKec": rest[0].attributes.user.wilKecamatan.id_kecamatan,
          "idKel": rest[0].attributes.user.wilKelurahan.id_kelurahan,
          "idRW": 0,
          "periodePendataan": 0
        },
        optJenisRekap: newData
      })
    } else {
      newData = [{ value: 5, label: 'RW' }]
      this.setState({

        optJenisRekap: newData
      })

    }

    this.setState({ title: title2 },
      () => { this.setHeaderTable() }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
              }
            }
          );


          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }

  }


  //API (Wilayah)
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      //handleSearch
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "idProv": +e.value,
          "tingkatWilayahID": 1

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
      );

      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, disAprrovalProv: false, disAprrovaladmin: false
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )


    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 0,
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,

        },
        disAprrovalProv: false, disAprrovaladmin: false
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 2,
          // "idProv": this.state.sendDatas.idProv,
          "idKab": +e.value,
          // "idKec": 0,
          // "idKel": 0,
          // "idRW": 0,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
        , id_kecamatan: 0, id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
          if (this.state.id_provinsi) {
            this.setState({ blocking: true })
            APIVerval.get("/aktif/getTombolAktifByProvIdKabId?periodeSensus=" + this.state.value_priode + "&kabupatenId=" + this.state.id_kabupaten + "&provinsiId=" + this.state.id_provinsi + "&modulPembayaran=PK" + '&periodeSensus=2024')
              .then((res) => {
                console.log(res.data[0])
                this.setState({ blocking: false });
                if (res.status === 200) {
                  this.setState(
                    {
                      disAprrovalProv: res.data[0].ButtonStatus, disAprrovaladmin: res.data[0].ButtonStatus
                    }

                  )
                }

              })
              .catch((error) => {
                this.setState({ blocking: false });
                console.log(error, "dataaa ERROR");
              });
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 1,
          // "idProv": this.state.sendDatas.idProv,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,

        }

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 3,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          "idKec": +e.value,
          // "idKel": 0,
          // "idRW": 0,

        }
      });


      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
        , id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 2,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,

        }

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 4,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          // "idKec": this.state.sendDatas.idKec,
          "idKel": +e.value,
          // "idRW": 0,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
        , id_rw: 0

      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 3,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          // "idKec": this.state.sendDatas.idKec,
          "idKel": 0,
          "idRW": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 5,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          // "idKec": this.state.sendDatas.idKec,
          // "idKel": this.state.sendDatas.idKel,
          "idRW": +e.value,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 4,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          // "idKec": this.state.sendDatas.idKec,
          // "idKel": this.state.sendDatas.idKel,
          "idRW": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah


  sel_periodePendataan = (e) => {
    if (e) {
      this.setState({
        periode_pendataan: e,
        selectedPeriodePendataanId: +e.value,
        sendDatas: {
          ...this.state.sendDatas,
          "periodePendataan": +e.value
        }
      });

    }
    else {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "periodePendataan": 0
        },
        periode_pendataan: null, selectedPeriodePendataanId: 0,
      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  // sel_jenisRekap = (e) => {
  //   if (e) {
  //     console.log('e', e)
  //     const rest = JSON.parse(localStorage.getItem("authority"));
  //     this.setState({
  //       jenis_rekap: e,
  //       selectedJenisRekapId:e.value,
  //     });

  //   }
  //   else {
  //       this.setState({
  //         jenis_rekap: null, selectedJenisRekapId: 0,
  //       },
  //           () => {
  //               if (e === null) {
  //               }
  //           }
  //       )
  //   }
  // }

  buttonFormatter = (cell, row) => {
    return <div className='divBtnTable'>
      <Button onClick={(e) => this.handleEdit(e, row)} id={row.id} className='btnTable' variant="outlined"><AiTwotoneEdit style={{ color: 'green' }} /></Button>
      <Button onClick={(e) => this.handleHapus(e, row)} id={row.id} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button>
    </div>

  }

  handleHapus = (e, row) => {
    Swal.fire({
      title: 'Apakah Anda Yakin?',
      text: "Ingin Menghapus Daftar Tarif ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ blocking: true });
        let Data = new FormData();
        Data.append('deskripsi', row.deskripsi)
        Data.append("provinsi", row.provinsi);
        Data.append("roleID", row.roleID);
        Data.append("kode", row.kode);

        APIVerval.post("/tarif/deleteTarif", Data)
          .then((res) => {
            this.setState({ blocking: false, openDialog: false });
            if (res.status === 200) {
              this.handleSearch()
            }

          })
          .catch((error) => {
            this.setState({ blocking: false });
            console.log(error, "dataaa ERROR");
          });
      } else {
        this.setState({ openDialog: false })
      }
    })
  }

  handleSubmit = () => {
    this.setState({ blocking: false, onSubmit: false })

    const dataTableCreate = {
      "createdBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "createdDate": new Date(),
      "isActive": 1,
      "roleID": this.state.kodeRole,
      "lastModified": new Date(),
      "lastModifiedBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "deskripsi": this.state.deskripsi,
      "levelWilayah": 1,
      "periodeSensus": this.state.value_priode,
      "tarifApbn": this.state.apbn,
      "tarifApbd": this.state.apbd,
      "provinsi": this.state.id_provinsi,
      "modul": this.state.namaModul
    }


    const dataTableedit = {
      "createdBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "createdDate": new Date(),
      "isActive": 1,
      "roleID": this.state.kodeRole,
      "kode": this.state.kode,
      "lastModified": new Date(),
      "lastModifiedBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "deskripsi": this.state.deskripsi,
      "levelWilayah": 1,
      "periodeSensus": this.state.value_priode,
      "tarifApbn": this.state.apbn,
      "tarifApbd": this.state.apbd,
      "provinsi": this.state.id_provinsi,
      "modul": this.state.namaModul

    }

    if (this.state.kodeRole === null || this.state.namaModul === null || this.state.deskripsi === null) {
      Swal.fire({
        title: 'info!',
        icon: 'info',
        text: "Harap Isi Form semua",
      })
      this.setState({ blocking: false, onSubmit: false })

      return;
    }
    Swal.fire({
      title: 'Apakah Anda Yakin?',
      text: "Dengan Data Entri Tarif ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        let paramedit = this.state.edit === true ? dataTableedit : dataTableCreate

        APIVerval.post('/tarif/upsertTarif', paramedit)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: 'Berhasil!',
                icon: 'info',
                text: this.state.edit === true ? 'Tarif Berhasil Diedit.' : 'Tarif Berhasil DiTambah.',
              }).then(() => {
                this.handleSearch()
                this.setState({ blocking: false, onSubmit: false, openDialog: false });

              })
            } else {
              Swal.fire({
                title: 'Gagal!!',
                icon: 'info',
                text: response,
              })
              this.setState({ blocking: false, onSubmit: false, openDialog: false });
            }
          })
          .catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false, onSubmit: false, openDialog: false });
          });
      } else {
        this.setState({ blocking: false, onSubmit: false });
      }
    })

  }

  handleOpenCloseInfo = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openInfo: !this.state.openInfo })
  }

  checkbox1 = (cell, row) => {
    let status = null;
    if (row.isActive === 1) {
      status = true;
    } else if (row.isActive === 0) {
      status = false;
    } return (
      <div className='divBtnTable'>
        <Checkbox style={{ transform: 'scale(0.8)', padding: '2px', margin: '0' }} checked={status} onChange={(e) => this.handleCheckbox1Change(e, row, status)} />
      </div>
    );
  }

  statusFormatter = (cell, row) => {
    let label = ''
    if (row.status === 1) {
      label = 'Pending MD'
    } else if (row.status === 2) {
      label = 'Pending MP'
    } else if (row.status === 3) {
      label = 'Complete'
    } else if (row.status === 7) {
      label = 'Reject MD'
    } else if (row.status === 8) {
      label = 'Reject MP'
    } else if (row.status === 9) {
      label = 'Reject PK'
    } else if (row.status === 10) {
      label = 'Reject PPK'
    } else if (row.status === 11) {
      label = 'Reject BPP'
    }

    return <div>{label}</div>
  }


  handleChangeInput = (e, tab) => {
    if (e.target.name === 'apbn' || e.target.name === 'apbd') {
      if (!isNaN(e.target.value)) { // Memastikan bahwa nilai yang dimasukkan adalah angka
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value.toUpperCase() });
    }
  }

  searchTable = () => {
    console.log("HAYO LUUUU")
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  selectCouchdb = () => {
    let setCdb = ""
    let provId = this.state.id_provinsi
    console.log(provId, 'id_provinsi')
    let CDB1 = process.env.REACT_APP_COUCHDB_1_URL
    let CDB2 = process.env.REACT_APP_COUCHDB_2_URL
    let CDB3 = process.env.REACT_APP_COUCHDB_3_URL
    let CDB4 = process.env.REACT_APP_COUCHDB_4_URL

    cdb1.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB1
      }
    })

    cdb2.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB2
      }
    })

    cdb3.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB3
      }
    })

    cdb4.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB4
      }
    })
    return setCdb
  }

  selectCouchdbKab = () => {
    let setCdb = ""
    let provId = this.state.id_provinsi
    console.log(provId, 'id_provinsi')
    let CDB1 = process.env.REACT_APP_COUCHDB_1_URL
    let CDB2 = process.env.REACT_APP_COUCHDB_2_URL
    let CDB3 = process.env.REACT_APP_COUCHDB_3_URL
    let CDB4 = process.env.REACT_APP_COUCHDB_4_URL
    let CDBKAB = process.env.REACT_APP_COUCHDB_KAB_URL

    cdb1.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB1
      }
    })

    cdb2.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB2
      }
    })

    cdb3.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB3
      }
    })

    cdb4.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB4
      }
    })
    return setCdb
  }

  cekUpdateValidasi = async (param) => {
    this.setState({ blocking: true })
    // this.setState({blocking:true})
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw } = this.state
    let promises = []

    param.map(async (el) => {
      const reqParamValue = {
        "idProvinsi": el.id_provinsi,
        "idKabupaten": el.id_kabupaten,
        "idKecamatan": el.id_kecamatan,
        "idKelurahan": el.id_kelurahan,
        "idRw": el.id_rw,
        "idRt": el.id_rt
      }
      promises.push(
        await APIPemutakhiran.post("/portalpk/metodepembayaran/getValidasiPembayaran", reqParamValue)
          .then((res) => {
            let ndt = this.state.datas
            this.setState(prevState => {
              let dt = prevState.datas
              dt = dt.map(row => (row.id_rt === el.id_rt && row.pendata === el.pendata ? { ...el, validasiPembayaran: res.data.validasiPembayaran } : row))
              return { datas: dt }
            },
              () => {
                console.log(this.state)
              })


          }).catch(() => {
            console.log("ERROR")
          })
      )
    })
    Promise.all(promises).then(() => {
      this.setState({ blocking: false })
    }).catch(() => {
      this.setState({ blocking: false })
    })

  }

  handleSearch = () => {
    this.setState({ blocking: true });

    if (this.state.id_provinsi === 0) {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Provinsi wajib dipilih!',
        showConfirmButton: false,
        timer: 1500
      })
      this.setState({
        blocking: false
      })

      return;
    }

    APIVerval.get("/tarif/getPeriodeSumberTarif?periode=" + this.state.value_priode + '&provinsi=' + this.state.id_provinsi)
      .then((res) => {
        console.log(res.data)
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState(
            {
              datas: res.data
            }

          )
        }

      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });
  }


  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  handleCetak = (e) => {


    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;
    //this.setJudulLaporan()

    let judul_lap, kode_lap
    //const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      kode_lap = this.state.rt.kode
      judul_lap = this.state.rt.namaWil
    } else {
      kode_lap = ''
      judul_lap = ''
      if (id_rw !== 0) {
        kode_lap = this.state.rw.kode
        judul_lap = this.state.rw.namaWil
      } else {
        kode_lap = ''
        judul_lap = ''
        if (id_des !== 0) {
          kode_lap = this.state.desa.kode
          judul_lap = this.state.desa.namaWil
        } else {
          kode_lap = ''
          judul_lap = ''
          if (id_kecamatan !== 0) {
            kode_lap = this.state.kecamatan.kode
            judul_lap = this.state.kecamatan.namaWil
          } else {
            kode_lap = ''
            judul_lap = ''
            if (id_kabupaten !== 0) {
              kode_lap = this.state.kabupaten.kode
              judul_lap = this.state.kabupaten.namaWil
            } else {
              kode_lap = ''
              judul_lap = ''
              if (id_provinsi !== 0) {
                kode_lap = this.state.provinsi.kode
                judul_lap = this.state.provinsi.namaWil
              } else {
                kode_lap = ''
                judul_lap = ' Tingkat Nasional'
              }
            }
          }
        }
      }
    }

    this.setState({ judul_laporan: judul_lap, kode_laporan: kode_lap })

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#4ea842',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#FF8000',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      //let urlCetakPdf = url +'%22ketWilayah%22%3A%22'+'tes' + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C'+ '%22title%22%3A%22' +this.state.title+ '%22%2C' +'%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'


      //viewReport?filterPencarian=%7B%22provinsiId%22%3A%221%22%2C%22kabupatenId%22%3A%227%22%2C%22kecamatanId%22%3A%224155%22%2C%22ketWilayah%22%3A%22Nama%20wilayah%20inputan%22%2C%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D

      //{"provinsiId":"1","kabupatenId":"7","kecamatanId":"4155","ketWilayah":"Nama wilayah inputan","reportName":"TABEL1","reportType":"pdf","reportCategory":"MasterWilayah"}

      //let coba =''
      //coba = decodeURI(this.state.judul_laporan)
      let urlCetakPdf = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
      let urlCetakExcel = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })

    // e.preventDefault()

  }

  handleSelectRow = (cat, row, isSelect) => {


    var data = this.state.datas
    let dataAll = []

    console.log(this.state.row)
    if (isSelect === true && cat === 'single') {
      this.setState({ selectedRows: this.state.selectedRows?.concat(row), dataFilter: this.state.selectedRows?.concat(row), disApprov: false });
    } else if (isSelect === true && cat === 'all') {
      if (this.state.textCari !== '') {
        dataAll = this.state.datas.filter(el => el.ID === row.ID)
        this.setState({ selectedRows: dataAll, dataFilter: dataAll, disApprov: false });
      } else {

        dataAll = this.state.datas.filter(el => el.status === 0 || el.status === 7 || el.status === 8 || el.status === 9 || el.status === 10 || el.status === 11)
        this.setState({ selectedRows: data, dataFilter: dataAll, disApprov: false });
      }

    } else if (cat === 'single') {
      if (this.state.selectedRows?.length !== 0) {
        var array = JSON.parse(JSON.stringify(this.state.selectedRows));
        var index = array.findIndex(el => el.ID === row.ID);
        if (index !== -1) {
          array.splice(index, 1);
          if (this.state.selectedRows?.length > 1) {
            this.setState({ selectedRows: array, disApprov: false });
          } else {
            this.setState({ selectedRows: array, disApprov: true });
          }

        }
        console.log(this.state.selectedRows)
      }
    }
    else {
      this.setState({ selectedRows: [], disApprov: true });
    }
  }

  onSearchChange = (searchText, colInfos, multiColumnSearch) => {
    this.setState({ textCari: searchText })

  }

  createdFormatDuplicate = (cell, row) => {
    if (row.isDuplidate === true || row.validasiPembayaran === false) {
      return 'fc3021'
    } else {
      return ''
    }
  }

  createFormatDuplicateRow = (cell, row) => {
    if (row.isDuplidate === true) {
      return <div className='divBtnTable'>
        <Tooltip title="Data Duplicate"><p style={{ width: '100%', height: '100%' }}>2</p></Tooltip>
      </div>
    } else if (row.validasiPembayaran === false) {
      return <div className='divBtnTable'>
        <Tooltip title="Masih terdapat Data Susulan dalam Proses"><p style={{ width: '100%', height: '100%' }}>1</p></Tooltip>
      </div>
    } else if (row.isDuplidate === false && row.validasiPembayaran === false) {

      <div className='divBtnTable'>
        <Tooltip title="Data Ada Duplicate dan Masih terdapat Data Susulan dalam Proses"><p style={{ width: '100%', height: '100%' }}>3</p></Tooltip>
      </div>
    } else {
      return ''
    }
  }

  clearForm = () => {
    this.setState({})
  }

  renderDropdownMenu = (cell, row) => {
    // This is just a sample dropdown menu, replace it with your own dropdown menu component
    return (
      <div className="dropdown">
        <button className="btn btn-secondary dropdown-toggle" type="button" id={`dropdownMenu${row.ID}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Menu
        </button>
        <div className="dropdown-menu" aria-labelledby={`dropdownMenu${row.ID}`}>
          <a className="dropdown-item" href="#">Aksi 1</a>
          <a className="dropdown-item" href="#">Aksi 2</a>
          <a className="dropdown-item" href="#">Aksi 3</a>
        </div>
      </div>
    );
  }

  render() {

    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: '#e3e8e4',

      size: '10000px',
      headerColumnStyle: { backgroundColor: 'blue' },
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handleSelectRow('single', row, isSelect)
      },
      onSelectAll: (isSelect, row, e) => {
        if (isSelect) {
          this.handleSelectRow('all', row, isSelect)
          return this.state.datas.map(row => row.ID)
        } else {
          this.handleSelectRow('all', row, isSelect)
          return [];
        }
      }

    };



    const options = {
      page: 1,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      onSearchChange: (searchText, colInfos, multiColumnSearch) => {
        this.onSearchChange(searchText, colInfos, multiColumnSearch)
      },
    };

    const searchProps = {
      dataField: 'price',
      text: 'Product Price',
      filterFormatted: true,
      filterValue: () => { console.log("here") },
      onSearch: (e) => {
        this.search(e, "hdsidhi")
      }
    }

    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Operasional Pembayaran</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select options={this.state.dt_priode} onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Tingkat Wilayah
                            <Select options={this.state.dt_tingkat} onChange={this.sel_tingkat} value={this.state.tingkat} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>

                        {/* <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid> */}

                        <Grid item xs={12} md={4}>
                          <Grid item xs={12}>
                            <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                            {/* <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button> */}

                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Operasional Pembayaran {this.state.title}</h3>
            <Grid item xs={12} md={12}>
              <Button onClick={(e) => this.handleCreate()} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px', float: 'left' }} sx={{ float: 'left' }} /> Tambah</Button>
            </Grid>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <div className='rekap-table'>
                <BootstrapTable
                  ref={this.bstable}
                  data={this.state.datas}
                  keyField='ID'
                  pagination={true}

                  options={options}
                  search={searchProps}
                  // searchPlaceholder='Which product would you like to search?'
                  selectRow={selectRow}
                >
                  <TableHeaderColumn width='100' dataField='roleID' headerAlign='center' dataAlign='center' >ROLE</TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="deskripsi" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >DESKRIPSI </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="tarifApbn" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >APBN </TableHeaderColumn>
                  <TableHeaderColumn width='80' dataField='tarifApbd' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate} >APBD </TableHeaderColumn>
                  <TableHeaderColumn width='80' dataField='modul' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate} >MODUL </TableHeaderColumn>

                  <TableHeaderColumn width='120' dataField='isActive' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate} dataFormat={this.checkbox1}>STATUS</TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn>



                </BootstrapTable>
              </div>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              {/* <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div> */}
            </Paper>
            <Dialog open={this.state.openDialog} onClose={this.handleOpenClose}>
              <DialogTitle>Operasional Pembayaran</DialogTitle>
              {/* {this.state.id ? 'Ubah' : 'Tambah'} */}
              <DialogContent>
                <DialogContentText>
                  {/* RW: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_rw}<br /> */}
                </DialogContentText>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: '100%' }} size="small">
                      Role
                      <Select options={this.state.optRole?.map(({ id, roleName }) => ({ value: id, label: roleName }))} isClearable onChange={this.sel_Role} value={this.state.Role} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: '100%' }} size="small">
                      Modul
                      <Select options={this.state.optModul} isClearable onChange={this.sel_Modul} value={this.state.modul} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="filled-required"
                      label="DESKRIPSI"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      name="deskripsi"
                      style={{ width: '100%' }}
                      value={this.state.deskripsi}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="filled-required"
                      label="APBN"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      name="apbn"
                      style={{ width: '100%' }}
                      value={this.state.apbn}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="filled-required"
                      label="APBD"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      name="apbd"
                      disabled={this.state.disAPBD}
                      style={{ width: '100%' }}
                      value={this.state.apbd}
                    />
                  </Grid>


                </Grid>

              </DialogContent>
              <DialogActions>
                {/* <Button disabled={this.state.disabledSimpanRt} onClick={this.handleAddRt} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Upload PDF</Button> */}
                {this.state.onSubmit === false ?
                  <>
                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleSubmit} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenClose} variant="outlined" color="error">Batal</Button>
                  </> :
                  <div style={{ paddingRight: '1rem', fontWeight: '600' }}>
                    Loading, please wait...
                  </div>
                }
                {/* <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenClose} variant="outlined" color="error">Batal</Button> */}

              </DialogActions>
            </Dialog>
          </div>
        </div>
      </BlockUi>
    )
  }
}

export default OperasionalPembayaran;