import axios from 'axios';

let url = ''

if (process.env.REACT_APP_ENV === 'development') {
    url = "http://dev-portal-api-gateway-portal-pk-staging.apps.ocp-dev.bkkbn.go.id/monitoring"
}

if (process.env.REACT_APP_ENV === 'production') {
    //url new
    url = "https://pk23-portal-gw.bkkbn.go.id/monitoring"
}

export default axios.create({
    baseURL: url,
    headers: {
        Authorization: localStorage.getItem('tokenMtg') !== 'null' ? 'Bearer ' + localStorage.getItem('tokenMtg') : '',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'user_key': '6ab0c0c4f7c40f3c7747329890cc5e78',
        'Content-Type': 'application/json',
    },
    responseType: 'json'
});
