import React, { Component } from 'react'
import { ImPlay3, ImSearch, ImPrinter } from 'react-icons/im';
import { AiFillLock } from 'react-icons/ai';
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIDashboard from "../../../services/APIDashboard";
import CommonTable from '../../../commons/table/TableMonitoring';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill, BsInfoCircle } from "react-icons/bs";

import Tooltip from '@mui/material/Tooltip';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import APIReport from '../../../services/APIReport';
import Swal from 'sweetalert2';
import APIVerval from '../../../services/APIVerval';
import APIButton from '../../../services/APIButtonAkses';

import axios from 'axios';
//import couchdb
import cdb1 from '../../../services/cdb1.json';
import cdb2 from '../../../services/cdb2.json';
import cdb3 from '../../../services/cdb3.json';
import cdb4 from '../../../services/cdb4.json';
import cdbKab from '../../../services/cdbKab1.json';

class FormulirCAPI extends Component {
  constructor(props) {
    super(props);
    this.bstable = React.createRef()
    this.state = {
      couchdb: "",
      USER_KEY_PG: process.env.REACT_APP_USER_KEY_PROXY_GATEWAY,

      panel1: true,
      sizePerPage: 10,
      title: '',
      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      text1: 'KODE',
      text2: 'NAMA KABUPATEN',
      text3: 'TARGET KK',
      text4: 'DIDATA',
      text5: 'TIDAK DITEMUI',
      text6: 'DITOLAK',
      text7: 'TIDAK MENJAWAB',
      text8: 'BELUM DIDATA',
      text9: 'ACTION',
      btnLock: false,
      btnUnlock: false,
      dataFilter: [],
      datas: [],
      data_test: [{ kode: '01', nama: 'RT 001', statusLock: 'unlock' }, { kode: '02', nama: 'RT 002', statusLock: 'lock' }],
      openInfo: false,
      optJenisRekap: [],
      datapromises: [],
      selectedJenisRekapId: 0,
      jenis_rekap: '',
      disApprov: false,
      disAprrovaladmin: false,

      optPeriodePendataan: [{ value: 2022, label: '2022' }, { value: 2021, label: '2021' }],

      selectedPeriodePendataanId: 0,
      periode_pendataan: '',

      tk_wilayah: 0,
      textCari: '',
      disAprrovalProv: false,
      duplicate: 0,
      susulan: 0,
      dupsul: 0,
      loginby: null,

      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

      cariRW: false,
      roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
      selectedRows: [],

      //datas: [],
      //selectDatas: [],
      sendDatas: {
        "tingkatWilayahID": 0,
        "idProv": 0,
        "idKab": 0,
        "idKec": 0,
        "idKel": 0,
        "idRW": 0,
        "periodePendataan": 0
      },
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
    }
  }

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      }, () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if (role === 1 || role === 99) {
          this.setState({
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
          })
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }

            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  setHeaderTable = () => {
    let { text1, text2, text3, text4, text5, text6, text7, text8, text9 } = this.state
    var headerColumns = [
      { width: '100', title: text1, dataField: "kode", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: '250', title: text2, dataField: "nama", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: '100', title: text3, dataField: "rowtargetKK", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text4, dataField: "rowJumlahKKygDidata", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text5, dataField: "rowJumlahygTidakDapatDitemui", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text6, dataField: "rowKJmlKKygDitolak", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text7, dataField: "rowJmlKKygTidakMampuMenjawab", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text8, dataField: "rowKKygBelumDidata", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: text9, dataField: "rowMutasiOut", headerAlign: 'center', dataAlign: 'right', editable: false },
    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidUpdate() {

  }

  componentDidMount() {
    this.setState({ optJenisRekap: [], blocking: false, loginby: localStorage.getItem("username") });
    this.setHeaderTable()
    let created = new Date().toISOString().substring(0, 10)
    let temp = new Date("2023-06-25").toISOString().substring(0, 10)
    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    let title2, text11, text21, newData

    if (this.state.roleUserLogin === 6) {
      const data_md = this.state.data_test.filter(data => data.statusLock === 'lock')
      this.setState({ data_test: data_md })
    }

    if (idTingkatWilayah == 0) {
      title2 = ''
      newData = [{ value: 1, label: 'PROVINSI' }, { value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
      { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

      this.setState({
        sendDatas: {
          //"tingkatWilayahID": 0,
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "periodePendataan": 0
        },
        optJenisRekap: newData
      }
      );

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi
      newData = [{ value: 1, label: 'PROVINSI' }, { value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
      { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

      this.setState(
        {
          sendDatas: {
            //"tingkatWilayahID":1,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": 0,
            "idKec": 0,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0
          },
          optJenisRekap: newData
        }
      )

    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten
      newData = [{ value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
      { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

      this.setState(
        {
          sendDatas: {
            //"tingkatWilayahID":2,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
            "idKec": 0,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0
          },
          optJenisRekap: newData
        },
        //() => {this.getBackend(this.state.sendDatas)}

      )


    } else if (idTingkatWilayah === 3) {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan
      newData = [{ value: 3, label: 'KECAMATAN' }, { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]


      this.setState(
        {
          sendDatas: {
            //"tingkatWilayahID":3,
            "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
            "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
            "idKec": rest[0].attributes.user.wilKecamatan.id_kecamatan,
            "idKel": 0,
            "idRW": 0,
            "periodePendataan": 0
          },
          optJenisRekap: newData
        }
      )
    } else if (idTingkatWilayah === 4) {
      newData = [{ value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]
      this.setState({
        sendDatas: {
          "tingkatWilayahID": 3,
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "idKec": rest[0].attributes.user.wilKecamatan.id_kecamatan,
          "idKel": rest[0].attributes.user.wilKelurahan.id_kelurahan,
          "idRW": 0,
          "periodePendataan": 0
        },
        optJenisRekap: newData
      })
    } else {
      newData = [{ value: 5, label: 'RW' }]
      this.setState({

        optJenisRekap: newData
      })

    }

    this.setState({ title: title2 },
      () => { this.setHeaderTable() }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
              }
            }
          );


          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }

  }


  //API (Wilayah)
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      //handleSearch
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "idProv": +e.value,
          "tingkatWilayahID": 1

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
      );

      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, disAprrovalProv: false, disAprrovaladmin: false
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )


    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 0,
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,

        },
        disAprrovalProv: false, disAprrovaladmin: false
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 2,
          // "idProv": this.state.sendDatas.idProv,
          "idKab": +e.value,
          // "idKec": 0,
          // "idKel": 0,
          // "idRW": 0,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
        , id_kecamatan: 0, id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
          if (this.state.id_provinsi) {
            this.setState({ blocking: true })
            APIButton.get("/aktif/getTombolAktifByProvIdKabId?periodeSensus=" + this.state.value_priode + "&kabupatenId=" + this.state.id_kabupaten + "&provinsiId=" + this.state.id_provinsi + "&modulPembayaran=PK" + '&periodeSensus=2024')
              .then((res) => {
                
                this.setState({ blocking: false });
                if (res.status === 200) {
                  this.setState(
                    {
                      disAprrovalProv: res.data[0].ButtonStatus, disAprrovaladmin: res.data[0].ButtonStatus
                    }

                  )
                }

              })
              .catch((error) => {
                this.setState({ blocking: false });
                
              });
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 1,
          // "idProv": this.state.sendDatas.idProv,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,

        }

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 3,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          "idKec": +e.value,
          // "idKel": 0,
          // "idRW": 0,

        }
      });


      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
        , id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 2,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,

        }

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 4,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          // "idKec": this.state.sendDatas.idKec,
          "idKel": +e.value,
          // "idRW": 0,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
        , id_rw: 0

      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 3,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          // "idKec": this.state.sendDatas.idKec,
          "idKel": 0,
          "idRW": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 5,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          // "idKec": this.state.sendDatas.idKec,
          // "idKel": this.state.sendDatas.idKel,
          "idRW": +e.value,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "tingkatWilayahID": 4,
          // "idProv": this.state.sendDatas.idProv,
          // "idKab": this.state.sendDatas.idKab,
          // "idKec": this.state.sendDatas.idKec,
          // "idKel": this.state.sendDatas.idKel,
          "idRW": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah


  sel_periodePendataan = (e) => {
    if (e) {
      this.setState({
        periode_pendataan: e,
        selectedPeriodePendataanId: +e.value,
        sendDatas: {
          ...this.state.sendDatas,
          "periodePendataan": +e.value
        }
      });

    }
    else {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "periodePendataan": 0
        },
        periode_pendataan: null, selectedPeriodePendataanId: 0,
      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  // sel_jenisRekap = (e) => {
  //   if (e) {
  //     
  //     const rest = JSON.parse(localStorage.getItem("authority"));
  //     this.setState({
  //       jenis_rekap: e,
  //       selectedJenisRekapId:e.value,
  //     });

  //   }
  //   else {
  //       this.setState({
  //         jenis_rekap: null, selectedJenisRekapId: 0,
  //       },
  //           () => {
  //               if (e === null) {
  //               }
  //           }
  //       )
  //   }
  // }

  buttonFormatter = (cell, row) => {
    if (this.state.roleUserLogin === 4 || this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) {
      return <div className='divBtnTable'>
        {(this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) &&
          <>
            {this.state.disAprrovaladmin === true ?
              <>
                {row.status === 0 || row.status === 7 || row.status === 8 || row.status === 9 || row.status === 10 || row.status === 11 ?
                  <Tooltip title="Approve"><Button id={row.id} onClick={(e) => this.handleApproveRejectRow(e, row)} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button></Tooltip>

                  :
                  <>-</>}
              </>
              :
              <>-</>
            }

          </>
        }
        {(this.state.roleUserLogin !== 19 && this.state.roleUserLogin !== 99) &&
          <>
            {this.state.disAprrovalProv === true ?
              <>
                {row.status === 0 || row.status === 7 || row.status === 8 || row.status === 9 || row.status === 10 || row.status === 11 ?
                  <Tooltip title="Approve"><Button id={row.id} onClick={(e) => this.handleApproveRejectRow(e, row)} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button></Tooltip>

                  :
                  <>-</>
                }
              </>
              :
              <>-</>
            }

          </>
        }

      </div>

    } else {
      return <>-</>
    }

  }

  handleOpenCloseInfo = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openInfo: !this.state.openInfo })
  }

  statusFormatter = (cell, row) => {
    let label = ''
    if (row.status === 1) {
      label = 'Pending MD'
    } else if (row.status === 2) {
      label = 'Pending MP'
    } else if (row.status === 3) {
      label = 'Complete'
    } else if (row.status === 7) {
      label = 'Reject MD'
    } else if (row.status === 8) {
      label = 'Reject MP'
    } else if (row.status === 9) {
      label = 'Reject PK'
    } else if (row.status === 10) {
      label = 'Reject PPK'
    } else if (row.status === 11) {
      label = 'Reject BPP'
    }

    return <div>{label}</div>
  }

  handleApproveRejectRow = async (e, row) => {
    if (this.state.loginby !== localStorage.getItem("username")) {
      Swal.fire({
        title: 'warning!',
        icon: 'warning',
        text: 'Ada Data Tidak Sesuai, Diharuskan Untuk Reload Page!',
      }).then(() => {
        window.location.reload(false);
      })

    } else {
      if (row.isDuplidate === true && row.validasiPembayaran === false) {
        Swal.fire({
          title: 'warning!',
          icon: 'warning',
          text: 'Data Ada Yang Duplicate Atau Masih terdapat Data Susulan dalam Proses',
        })
        this.setState({ blocking: false, disApprov: false })
      } else if (row.isDuplidate === true) {
        Swal.fire({
          title: 'warning!',
          icon: 'warning',
          text: 'Data Ada Yang Duplicate',
        })
        this.setState({ blocking: false, disApprov: false })
      } else if (row.validasiPembayaran === false) {
        Swal.fire({
          title: 'warning!',
          icon: 'warning',
          text: 'Masih terdapat Data Susulan dalam Proses',
        })
        this.setState({ blocking: false, disApprov: false })
      } else {
        this.setState({ blocking: true })
        const { couchdb, id_provinsi, id_kabupaten, kabupaten, id_kecamatan, id_des, kelurahan, id_rw } = this.state
        if (e) {

          let datacdb = cdbKab.filter(el => el.nama_kabupaten_baru === kabupaten?.label.substring(5, kabupaten.length))
          let datafixcdb = datacdb.map(el => el.nama_kabupaten_lama)
          const kab = datafixcdb[0]
          const kabLower = kab.toLowerCase()
          let remove = kabLower.split('.').join("");
          let kabName = remove.replace(/ /g, "_");
          let dbname = `data_bkkbn_${kabName}`

          
          
          const dtime = new Date().getTime()
          
          let payloadCdb = {}
          //get data form server couchdb
          const data_selector = {
            "selector": {
              // "user_name": row.pendata,
              "_id": "" + row.id_rt,
              "group": "dataRekap"
            },
            "limit": 1
          }
          let res
          try {
            res = await axios.post(couchdb + '/' + dbname + '/_find', data_selector, {
              headers: {
                user_key: this.state.USER_KEY_PG,
                Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
              }
            })
          } catch (error) {
            console.error(error)
            this.setState({ blocking: false })

          }
          const { data } = await res;
          

          if (data.docs.length === 0) {
            payloadCdb = {
              "_id": "" + row.id_rt,
              "user_name": row.pendata,
              "group": "dataRekap",
              "periode_sensus": "2024",
              "id_rw": "" + row.id_rw,
              "id_rt": "" + row.id_rt,
              "status": 3,
              "http_method": "PUT",
              "tgl_submit": dtime
            }
          } else {
            
            payloadCdb = {
              "_id": "" + row.id_rt,
              "_rev": data.docs[0]._rev,
              "user_name": row.pendata,
              "group": "dataRekap",
              "periode_sensus": "2024",
              "id_rw": "" + row.id_rw,
              "id_rt": "" + row.id_rt,
              "status": 3,
              "http_method": "PUT",
              "tgl_submit": dtime
            }
          }

          
          
          let approveParam = {
            "id_rt": row.id_rt,
            "user_name": this.state.loginby,
            "yang_ada": row.yang_ada,
            "didata": row.didata,
            "ditemui": row.ditemui,
            "userID": row.userId,
            "baru": row.baru,
            "pindah": row.pindah,
            "meninggal": row.meninggal,
            "tidak_ditemukan": row.tidak_ditemukan,
            "bercerai": row.bercerai,
            "status": 1,
            "provinsiId": +id_provinsi,
            "kabupatenId": +id_kabupaten,
            "role_id": 5,
            "id": row.id === 0 ? null : row.id,
            "createTunggakanBy": this.state.loginby,
            'id_provinsi': row.id_provinsi,
            'id_kabupaten': row.id_kabupaten,
            'id_kecamatan': row.id_kecamatan,
            'id_rw': row.id_rw,
            'id_kelurahan': row.id_kelurahan,
            'sumber_pendanaan': row.sumber_pendanaan
          }
          

          Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin Approve Formulir ini?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then((result) => {
            if (result.isConfirmed) {
              this.setState({ selectedRows: [], blocking: true })
              axios.put(couchdb + '/' + dbname + '/' + row.id_rt, payloadCdb, {
                headers: {
                  Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                  user_key: this.state.USER_KEY_PG
                }
              }).then(res => {
                APIVerval.post('portalpk/metodepembayaranpk/insertFormulirPapi?periodeSensus=' + this.state.value_priode, approveParam)
                  .then(async (response) => {
                    
                    if (response.status === 200) {
                      if (response.data['insert formulir papi'] === "sukses") {

                        Swal.fire({
                          title: 'Info!',
                          icon: 'success',
                          text: 'Data Berhasil Di Approve',
                        }).then(() => {
                          this.setState({ selectedRows: [], blocking: false })
                          this.handleSearch()
                        })

                      } else {
                        
                        await axios.delete(couchdb + '/' + dbname + '/' + row.id_rt + '?rev=' + res.data.rev, {
                          headers: {
                            Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                            user_key: this.state.USER_KEY_PG
                          }
                        })
                        Swal.fire({
                          title: 'warning',
                          icon: '',
                          text: 'Data Gagal Di Approve',
                        })
                        this.setState({ selectedRows: [], blocking: false })
                        this.handleSearch()
                      }
                    } else {
                      this.setState({ selectedRows: [], blocking: false })
                      Swal.fire({
                        title: 'warning',
                        icon: '',
                        text: 'Data Gagal Di Approve',
                      })
                      await axios.delete(couchdb + '/' + dbname + '/' + row.id_rt + '?rev=' + res.data.rev, {
                        headers: {
                          Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                          user_key: this.state.USER_KEY_PG
                        }
                      })
                      this.setState({ selectedRows: [], blocking: false })
                      this.handleSearch()
                    }
                  })
                  .catch(async (error) => {

                    await axios.put(couchdb + '/' + dbname + '/' + row.id_rt + '?rev=' + res.data.rev, {
                      headers: {
                        Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                        user_key: this.state.USER_KEY_PG
                      }
                    })
                    Swal.fire({
                      title: 'warning',
                      icon: '',
                      text: 'Data Gagal Di Approve',
                    })
                    this.setState({ selectedRows: [], blocking: false })
                    this.handleSearch()
                  });
              }).catch((e) => {
                Swal.fire({
                  title: 'Error',
                  icon: '',
                  text: 'Gagal melakukan lock pendataan',
                })
              })

            } else {
              this.setState({ blocking: false })
            }

          })
        }
      }
    }
  }

  handleApproveRejectAll = async (e, param) => {
    this.setState({ blocking: true, disApprov: true })

    let dataAll = []
    let dataDuplicate = []
    let dataValidasi = []
    const { couchdb, id_provinsi, id_kabupaten, kabupaten, id_kecamatan, id_des, kelurahan, id_rw } = this.state
    let datacdb = cdbKab.filter(el => el.nama_kabupaten_baru === kabupaten?.label.substring(5, kabupaten.length))
    let datafixcdb = datacdb.map(el => el.nama_kabupaten_lama)
    
    dataAll = this.state.selectedRows.filter(el => el.status === 0 || el.status === 7 || el.status === 8 || el.status === 9 || el.status === 10 || el.status === 11)
    dataDuplicate = this.state.selectedRows.filter(el => el.isDuplidate === true)
    dataValidasi = this.state.selectedRows.filter(el => el.validasiPembayaran === false)

    if (this.state.loginby !== localStorage.getItem("username")) {
      Swal.fire({
        title: 'warning!',
        icon: 'warning',
        text: 'Ada Data Tidak Sesuai, Diharuskan Untuk Reload Page!',
      }).then(() => {
        window.location.reload(false);
      })

    } else {
      if (dataDuplicate.length > 0 && dataValidasi.length > 0) {
        Swal.fire({
          title: 'warning!',
          icon: 'warning',
          text: 'Data Ada Yang Duplicate Atau Masih terdapat Data Susulan dalam Proses',
        })
        this.setState({ blocking: false, disApprov: false })
      } else if (dataDuplicate.length > 0) {
        Swal.fire({
          title: 'warning!',
          icon: 'warning',
          text: 'Data Ada Yang Duplicate',
        })
        this.setState({ blocking: false, disApprov: false })
      } else if (dataValidasi.length > 0) {
        Swal.fire({
          title: 'warning!',
          icon: 'warning',
          text: 'Masih terdapat Data Susulan dalam Proses',
        })
        this.setState({ blocking: false, disApprov: false })
      } else {
        if (dataAll.length > 0) {
          Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin Approve Formulir ini?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.setState({ selectedRows: [], blocking: true, datas: [] })
              let promises = []


              Promise.all(dataAll.map(async (el) => {
                
                const kab = datafixcdb[0]
                const kabLower = kab.toLowerCase()
                let remove = kabLower.split('.').join("");
                let kabName = remove.replace(/ /g, "_");
                let dbname = `data_bkkbn_${kabName}`

                // 
                // 
                const dtime = new Date().getTime()
                let payloadCdb = {}
                //get data form server couchdb
                const data_selector = {
                  "selector": {
                    // "user_name": el.pendata,
                    "_id": "" + el.id_rt,
                    "group": "dataRekap"
                  },
                  "limit": 1
                }
                const { data } = await axios.post(couchdb + '/' + dbname + '/_find', data_selector, {
                  headers: {
                    Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                    user_key: this.state.USER_KEY_PG
                  }
                })

                if (data.docs.length === 0) {
                  payloadCdb = {
                    "_id": "" + el.id_rt,
                    "user_name": el.pendata,
                    "group": "dataRekap",
                    "periode_sensus": "2024",
                    "id_rw": "" + el.id_rw,
                    "id_rt": "" + el.id_rt,
                    "status": 3,
                    "http_method": "PUT",
                    "tgl_submit": dtime
                  }
                } else {
                  payloadCdb = {
                    "_id": "" + el.id_rt,
                    "_rev": data.docs[0]._rev,
                    "user_name": el.pendata,
                    "group": "dataRekap",
                    "periode_sensus": "2024",
                    "id_rw": "" + el.id_rw,
                    "id_rt": "" + el.id_rt,
                    "status": 3,
                    "http_method": "PUT",
                    "tgl_submit": dtime
                  }
                }

                

                let approveParam = {
                  "id_rt": el.id_rt,
                  "user_name": this.state.loginby,
                  "yang_ada": el.yang_ada,
                  "didata": el.didata,
                  "ditemui": el.ditemui,
                  "baru": el.baru,
                  "pindah": el.pindah,
                  "meninggal": el.meninggal,
                  "tidak_ditemukan": el.tidak_ditemukan,
                  "bercerai": el.bercerai,
                  "userID": el.userId,
                  "status": 1,
                  "role_id": 5,
                  "id": el.id === 0 ? null : el.id,
                  "createTunggakanBy": this.state.loginby,
                  'id_provinsi': el.id_provinsi,
                  'id_kabupaten': el.id_kabupaten,
                  'id_kecamatan': el.id_kecamatan,
                  'id_kelurahan': el.id_kelurahan,
                  'id_rw': el.id_rw,
                  'sumber_pendanaan' : el.sumber_pendanaan
                }
                
                promises.push(
                  await axios.put(couchdb + '/' + dbname + '/' + el.id_rt, payloadCdb, {
                    headers: {
                      Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                      user_key: this.state.USER_KEY_PG
                    }
                  }).then(async res => {
                    await APIVerval.post('portalpk/metodepembayaranpk/insertFormulirPapi?periodeSensus=' + this.state.value_priode, approveParam)
                      .then(async (response) => {
                        let obj = {};
                        var timeStamp = response;
                        obj = timeStamp;
                        this.state.datapromises.push(obj);
                        if (response.data['insert formulir papi'] === "gagal") {
                          await axios.delete(couchdb + '/' + dbname + '/' + el.id_rt + '?rev=' + res.data.rev, {
                            headers: {
                              Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                              user_key: this.state.USER_KEY_PG
                            }
                          })
                        }
                      })
                      .catch(async (error) => {
                        await axios.delete(couchdb + '/' + dbname + '/' + el.id_rt + '?rev=' + res.data.rev, {
                          headers: {
                            Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                            user_key: this.state.USER_KEY_PG
                          }
                        })
                      })
                  }).catch((e) => {
                    Swal.fire({
                      title: 'Error',
                      icon: '',
                      text: 'Gagal melakukan lock pendataan',
                    })
                  })
                )
                
              })).then(() => {
                
                Promise.all(promises).then(() => {
                  
                  // Swal.fire({
                  //   title: 'Info!',
                  //   icon: 'success',
                  //   text: 'Data Berhasil di Aprrove',
                  // }).then(resp =>  {
                  //   this.handleSearch()
                  //   this.setState({ selectedRows: [], blocking: true })
                  // })
                  let datapro = []
                  let datapromise = []
                  datapro = this.state.datapromises.filter(el => el.data['insert formulir papi'] === "gagal")
                  datapromise = this.state.datapromises.filter(el => el.status === 200)
                  
                  if (datapro.length > 0) {
                    Swal.fire({
                      title: 'Info!',
                      icon: 'warning',
                      text: 'Data Berhasil di Aprrove dan Ada ' + datapro.length + ' Data yang Gagal',
                    }).then(resp => {
                      this.handleSearch()
                      this.setState({ selectedRows: [], blocking: true })
                    })

                  } else if (datapromise.length === this.state.datapromises.length) {
                    Swal.fire({
                      title: 'Info!',
                      icon: 'success',
                      text: 'Data Berhasil di Aprrove',
                    }).then(resp => {
                      this.handleSearch()
                      this.setState({ selectedRows: [], blocking: true })
                    })
                  } else {
                    Swal.fire({
                      title: 'Info!',
                      icon: 'success',
                      text: 'Data Gagal di Aprrove',
                    }).then(resp => {
                      this.handleSearch()
                      this.setState({ selectedRows: [], blocking: true })
                    })
                  }

                  // 
                }).catch((error) => {
                  Swal.fire({
                    title: 'Error',
                    icon: 'warning',
                    text: 'Data Gagal di Aprrove',
                  })
                  this.setState({ selectedRows: [], blocking: false })
                  this.handleSearch()
                })

              })
                .catch((error) => {
                  let message = error;
                  Swal.fire({
                    title: 'Error!',
                    icon: 'warning',
                    text: 'Gagal melakukan lock pendataan',
                  }).then(() => {
                    this.handleSearch()
                    this.setState({ selectedRows: [], blocking: false })
                  })
                })
            } else {
              this.setState({ blocking: false, disApprov: false })
            }

          })
        } else {
          Swal.fire({
            title: "INFO",
            text: "Tidak ada data yang dipilih atau data telah disubmit",
            icon: 'info',
            showConfirmButton: true
          })
          this.setState({ selectedRows: [], blocking: false })
        }
      }
    }

  }

  searchTable = () => {
    
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  selectCouchdb = () => {
    let setCdb = ""
    let provId = this.state.id_provinsi
    
    let CDB1 = process.env.REACT_APP_COUCHDB_1_URL
    let CDB2 = process.env.REACT_APP_COUCHDB_2_URL
    let CDB3 = process.env.REACT_APP_COUCHDB_3_URL
    let CDB4 = process.env.REACT_APP_COUCHDB_4_URL

    cdb1.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB1
      }
    })

    cdb2.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB2
      }
    })

    cdb3.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB3
      }
    })

    cdb4.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB4
      }
    })
    return setCdb
  }

  selectCouchdbKab = () => {
    let setCdb = ""
    let provId = this.state.id_provinsi
    
    let CDB1 = process.env.REACT_APP_COUCHDB_1_URL
    let CDB2 = process.env.REACT_APP_COUCHDB_2_URL
    let CDB3 = process.env.REACT_APP_COUCHDB_3_URL
    let CDB4 = process.env.REACT_APP_COUCHDB_4_URL
    let CDBKAB = process.env.REACT_APP_COUCHDB_KAB_URL

    cdb1.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB1
      }
    })

    cdb2.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB2
      }
    })

    cdb3.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB3
      }
    })

    cdb4.map(key => {
      if (+key.id_provinsi === +provId) {
        setCdb = CDB4
      }
    })
    return setCdb
  }

  cekUpdateValidasi = async (param) => {
    this.setState({ blocking: true })
    // this.setState({blocking:true})
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw } = this.state
    let promises = []

    param.map(async (el) => {
      const reqParamValue = {
        "idProvinsi": el.id_provinsi,
        "idKabupaten": el.id_kabupaten,
        "idKecamatan": el.id_kecamatan,
        "idKelurahan": el.id_kelurahan,
        "idRw": el.id_rw,
        "idRt": el.id_rt
      }
      promises.push(
        await APIVerval.post("/portalpk/metodepembayaranpk/getValidasiPembayaran?periodeSensus=" + this.state.value_priode, reqParamValue)
          .then((res) => {
            let ndt = this.state.datas
            this.setState(prevState => {
              let dt = prevState.datas
              dt = dt.map(row => (row.id_rt === el.id_rt && row.pendata === el.pendata ? { ...el, validasiPembayaran: res.data.validasiPembayaran } : row))
              return { datas: dt }
            },
              () => {
                
              })


          }).catch(() => {
            
          })
      )
    })
    Promise.all(promises).then(() => {
      this.setState({ blocking: false })
    }).catch(() => {
      this.setState({ blocking: false })
    })

  }

  handleSearch = () => {
    //cek couchdb
    let couchdb = this.selectCouchdb()
    let couchdbKab = this.selectCouchdbKab()

    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw, id_rt, title, kecamatan } = this.state
    const reqParam = {
      "provinsiId": id_provinsi,
      "kabupatenId": id_kabupaten,
      "kecamatanId": id_kecamatan,
      "kelurahanId": id_des,
      "rwId": id_rw,
      "roleId": 5
    }
    if (id_kabupaten !== 0) {

      this.setState({ blocking: true, datas: [], selectedRows: [], textCari: '' },
        () => {

          APIVerval.post("/portalpk/metodepembayaranpk/getListFormulirPapi?periodeSensus=" + this.state.value_priode, reqParam)
            .then((res) => {

              this.setState({ blocking: false, });
              var newData = [];
              var newDatas = [];

              if (res.status === 200) {
                // const arrayNew = {}
                // res.data.data.map((el) => {
                //   if(arrayNew[el.id_rt + '-' + el.pendata]) {
                //     arrayNew[el.id_rt + '-' + el.pendata] = 1
                //     return 
                //   }
                //   arrayNew[el.id_rt + '-' + el.pendata] = 0
                // })


                // res.data.data.forEach(data => {
                //   let isDuplidate = arrayNew[data.id_rt + '-' + data.pendata] === 1 ? true : false

                //   data = Object.assign({ isDuplidate: isDuplidate }, data)
                //   newData.push(data)
                // },)
                // 


                const arry = res.data.data.map((el) => el.id_rt)
                const toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)
                const duplicateElementa = toFindDuplicates(arry);

                res.data.data.forEach(data => {
                  let isDuplidate = duplicateElementa.indexOf(data.id_rt) >= 0 ? true : false

                  const obj1 = Object.assign({ isDuplidate: isDuplidate }, data)
                  data = obj1
                  newData.push(data)
                },)

                this.setState(
                  {
                    datas: newData,
                    selectedRows: [],
                    couchdb,
                    blocking: false
                  }, () => {
                    this.cekUpdateValidasi(newData)
                    this.bstable.current.reset();

                    let dup = this.state.datas.filter(el => el.isDuplidate)
                    let sul = this.state.datas.filter(el => el.validasiPembayaran === false)
                    // 
                    this.setState({ duplicate: dup.length, susulan: sul?.length })
                  }
                )
              } else {
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  text: 'Pencarian Data Gagal, Harap Coba lagi!',
                })
                this.setState({ blocking: false });
              }

            })
            .catch((error) => {
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                text: 'Pencarian Data Gagal, Harap Coba lagi!',
              })
              this.setState({ blocking: false });
            });
        }
      )

    } else {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Filter wilayah harus dipilih minimal tingkat kecamatan',
        showConfirmButton: true
      })
      this.setState({ blocking: false });
    }

  }


  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  handleCetak = (e) => {


    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;
    //this.setJudulLaporan()

    let judul_lap, kode_lap
    //const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      kode_lap = this.state.rt.kode
      judul_lap = this.state.rt.namaWil
    } else {
      kode_lap = ''
      judul_lap = ''
      if (id_rw !== 0) {
        kode_lap = this.state.rw.kode
        judul_lap = this.state.rw.namaWil
      } else {
        kode_lap = ''
        judul_lap = ''
        if (id_des !== 0) {
          kode_lap = this.state.desa.kode
          judul_lap = this.state.desa.namaWil
        } else {
          kode_lap = ''
          judul_lap = ''
          if (id_kecamatan !== 0) {
            kode_lap = this.state.kecamatan.kode
            judul_lap = this.state.kecamatan.namaWil
          } else {
            kode_lap = ''
            judul_lap = ''
            if (id_kabupaten !== 0) {
              kode_lap = this.state.kabupaten.kode
              judul_lap = this.state.kabupaten.namaWil
            } else {
              kode_lap = ''
              judul_lap = ''
              if (id_provinsi !== 0) {
                kode_lap = this.state.provinsi.kode
                judul_lap = this.state.provinsi.namaWil
              } else {
                kode_lap = ''
                judul_lap = ' Tingkat Nasional'
              }
            }
          }
        }
      }
    }

    this.setState({ judul_laporan: judul_lap, kode_laporan: kode_lap })

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#4ea842',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#FF8000',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      //let urlCetakPdf = url +'%22ketWilayah%22%3A%22'+'tes' + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C'+ '%22title%22%3A%22' +this.state.title+ '%22%2C' +'%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'


      //viewReport?filterPencarian=%7B%22provinsiId%22%3A%221%22%2C%22kabupatenId%22%3A%227%22%2C%22kecamatanId%22%3A%224155%22%2C%22ketWilayah%22%3A%22Nama%20wilayah%20inputan%22%2C%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D

      //{"provinsiId":"1","kabupatenId":"7","kecamatanId":"4155","ketWilayah":"Nama wilayah inputan","reportName":"TABEL1","reportType":"pdf","reportCategory":"MasterWilayah"}

      //let coba =''
      //coba = decodeURI(this.state.judul_laporan)
      let urlCetakPdf = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
      let urlCetakExcel = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })

    // e.preventDefault()

  }

  handleSelectRow = (cat, row, isSelect) => {


    var data = this.state.datas
    let dataAll = []

    
    if (isSelect === true && cat === 'single') {
      this.setState({ selectedRows: this.state.selectedRows?.concat(row), dataFilter: this.state.selectedRows?.concat(row), disApprov: false });
    } else if (isSelect === true && cat === 'all') {
      if (this.state.textCari !== '') {
        dataAll = this.state.datas.filter(el => el.id_rt === row.id_rt)
        this.setState({ selectedRows: dataAll, dataFilter: dataAll, disApprov: false });
      } else {

        dataAll = this.state.datas.filter(el => el.status === 0 || el.status === 7 || el.status === 8 || el.status === 9 || el.status === 10 || el.status === 11)
        this.setState({ selectedRows: data, dataFilter: dataAll, disApprov: false });
      }

    } else if (cat === 'single') {
      if (this.state.selectedRows?.length !== 0) {
        var array = JSON.parse(JSON.stringify(this.state.selectedRows));
        var index = array.findIndex(el => el.id_rt === row.id_rt);
        if (index !== -1) {
          array.splice(index, 1);
          if (this.state.selectedRows?.length > 1) {
            this.setState({ selectedRows: array, disApprov: false });
          } else {
            this.setState({ selectedRows: array, disApprov: true });
          }

        }
        
      }
    }
    else {
      this.setState({ selectedRows: [], disApprov: true });
    }
  }

  onSearchChange = (searchText, colInfos, multiColumnSearch) => {
    this.setState({ textCari: searchText })

  }

  createdFormatDuplicate = (cell, row) => {
    if (row.isDuplidate === true || row.validasiPembayaran === false) {
      return 'fc3021'
    } else {
      return ''
    }
  }

  createFormatDuplicateRow = (cell, row) => {
    if (row.isDuplidate === true) {
      return <div className='divBtnTable'>
        <Tooltip title="Data Duplicate"><p style={{ width: '100%', height: '100%' }}>2</p></Tooltip>
      </div>
    } else if (row.validasiPembayaran === false) {
      return <div className='divBtnTable'>
        <Tooltip title="Masih terdapat Data Susulan dalam Proses"><p style={{ width: '100%', height: '100%' }}>1</p></Tooltip>
      </div>
    } else if (row.isDuplidate === false && row.validasiPembayaran === false) {

      <div className='divBtnTable'>
        <Tooltip title="Data Ada Duplicate dan Masih terdapat Data Susulan dalam Proses"><p style={{ width: '100%', height: '100%' }}>3</p></Tooltip>
      </div>
    } else {
      return ''
    }
  }

  render() {

    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: '#e3e8e4',

      size: '10000px',
      headerColumnStyle: { backgroundColor: 'blue' },
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handleSelectRow('single', row, isSelect)
      },
      onSelectAll: (isSelect, row, e) => {
        if (isSelect) {
          this.handleSelectRow('all', row, isSelect)
          return this.state.datas.map(row => row.id_rt)
        } else {
          this.handleSelectRow('all', row, isSelect)
          return [];
        }
      }

    };



    const options = {
      page: 1,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      onSearchChange: (searchText, colInfos, multiColumnSearch) => {
        this.onSearchChange(searchText, colInfos, multiColumnSearch)
      },
    };

    const searchProps = {
      dataField: 'price',
      text: 'Product Price',
      filterFormatted: true,
      filterValue: () => {  },
      onSearch: (e) => {
        this.search(e, "hdsidhi")
      }
    }

    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Formulir CAPI</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                      </Grid>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Grid item xs={12}>
                            <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} />Cari</Button>
                            {/* <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button> */}

                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Formulir CAPI {this.state.title}</h3>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
              <Grid item xs={12} md={12}>
                <div>
                  {/* <Button disabled={this.state.selectedRows?.length === 0}  sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: '#B22222', ':hover': { bgcolor: '#B22222', color: 'white' } }} variant="contained"><BsXCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Reject</Button> */}
                  <span className='btnTable'><Tooltip title="Keterangan" placement="left" arrow><Button style={{ float: 'right' }} onClick={this.handleOpenCloseInfo} ><BsInfoCircle size={20} /></Button></Tooltip></span>
                  {(this.state.roleUserLogin !== 19 && this.state.roleUserLogin !== 99) &&
                    <>
                      {this.state.disAprrovalProv === true && <Button disabled={this.state.dataFilter?.length === 0 || this.state.disApprov} onClick={(e) => this.handleApproveRejectAll()} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px', float: 'left' }} sx={{ float: 'left' }} /> Approve</Button>}
                    </>
                  }
                  {(this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) &&
                    <>
                      {this.state.disAprrovaladmin === true && <Button disabled={this.state.dataFilter?.length === 0 || this.state.disApprov} onClick={(e) => this.handleApproveRejectAll()} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px', float: 'left' }} sx={{ float: 'left' }} /> Approve</Button>}
                    </>
                  }
                </div>

              </Grid>
            </Grid>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <div className='rekap-table'>
                <BootstrapTable
                  ref={this.bstable}
                  data={this.state.datas}
                  keyField='id_rt'
                  pagination={true}

                  options={options}
                  search={searchProps}
                  // searchPlaceholder='Which product would you like to search?'
                  selectRow={selectRow}
                >
                  <TableHeaderColumn width="80" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='status' dataFormat={this.statusFormatter} headerAlign='center' dataAlign='center' >STATUS</TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="kode_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >KODE RW </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >NAMA RW </TableHeaderColumn>
                  <TableHeaderColumn width='80' dataField='kode_rt' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate} >KODE RT </TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='nama_rt' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>NAMA RT</TableHeaderColumn>
                  <TableHeaderColumn width='220' dataField='pendata' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>PENDATA</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='yang_ada' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>YANG ADA</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='didata' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>DIDATA</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='ditemui' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>DITEMUI</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='baru' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>BARU</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='pindah' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>PINDAH</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='meninggal' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>MENINGGAL</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='tidak_ditemukan' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>TIDAK DITEMUKAN</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='bercerai' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>BERCERAI</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='status' dataFormat={this.createFormatDuplicateRow} headerAlign='center' dataAlign='center' >KETERANGAN</TableHeaderColumn>


                </BootstrapTable>
              </div>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              {/* <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div> */}
              <div className='totalData'>
                <b style={{ marginRight: '15px' }}>Total Data: {this.state.datas.length}</b>
                <b style={{ marginRight: '15px' }}>Data Duplicate: {this.state.duplicate}</b>
                <b style={{ marginRight: '15px' }}>Data Susulan: {this.state.susulan}</b>
                {/* <b style={{ marginRight: '15px' }}>Data Duplicate dan Susulan: {this.state.dupsul}</b> */}
              </div>
            </Paper>
            <Dialog open={this.state.openInfo} onClose={this.handleOpenCloseInfo} width={'xs'}>
              <DialogTitle style={{ paddingLeft: '25px' }}><BsInfoCircle size={15} />&nbsp; Info</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 1 }} style={{ marginTop: '10px', marginBottom: '15px', paddingLeft: '15px' }}> */}
                  Info Code di Column KETERANGAN <br />
                  - Keterangan 1 adalah Masih terdapat Data Susulan dalam Proses <br />
                  - Keterangan 2 adalah Data Duplicate<br />
                  - Keterangan 3 adalah Data Ada Duplicate dan Masih terdapat Data Susulan dalam Proses



                  {/* </Grid> */}

                </DialogContentText>

              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleOpenCloseInfo} variant="outlined" color="primary">Tutup</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </BlockUi>
    )
  }
}

export default FormulirCAPI;