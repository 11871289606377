import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA7o_GZEpqebBjxFK7HqH7uX79s72dGaUs",
    authDomain: "phone-auth-d34e3.firebaseapp.com",
    projectId: "phone-auth-d34e3",
    storageBucket: "phone-auth-d34e3.appspot.com",
    messagingSenderId: "582165067157",
    appId: "1:582165067157:web:999b1e5045ca2bf5ec729c"
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);