import React, { Component } from 'react'
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import { BsPencilSquare } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { HiOutlineDocumentText } from "react-icons/hi";
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIPemutakhiranMonitoring from "../../../services/APIPemutakhiranMonitoring";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock, AiOutlineVerticalAlignBottom, AiOutlineFilePdf, AiOutlineUpload } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import { ImSearch, ImBin, ImCross, ImFloppyDisk, ImLoop2, ImMap, ImMap2, ImPlay3, ImPlus, ImDownload2, ImDownload } from 'react-icons/im';
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill, BsInfoCircle } from "react-icons/bs";
import APIDashboard from "../../../services/APIDashboard";
import APIReport from '../../../services/APIReport';
import CommonTable from '../../../commons/table/Table';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { FileUploader } from "react-drag-drop-files";
import APIVerval from '../../../services/APIVerval';
import APIUpload from '../../../services/APIUpload';
import { Grid4x4 } from '@mui/icons-material';
import APIMonitoring from '../../../services/APIMonitoringBackend';
import APIButton from '../../../services/APIButtonAkses';

import axios from 'axios';
//import couchdb
import cdb1 from '../../../services/cdb1.json';
import cdb2 from '../../../services/cdb2.json';
import cdb3 from '../../../services/cdb3.json';
import cdb4 from '../../../services/cdb4.json';
import cdbKab from '../../../services/cdbKab1.json';

const fileTypes = ["PDF", "IMG"];
const isNumber = (str) => /^[0-9]*$/.test(str);
class approvalOperasional extends Component {
    constructor(props) {
        super(props);
        this.bstable = React.createRef()
        this.state = {
            couchdb: "",
            USER_KEY_PG: process.env.REACT_APP_USER_KEY_PROXY_GATEWAY,
            openInfo: false,
            loginby: null,
            panel1: true,
            sizePerPage: 10,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],
            text11: '',
            text21: '',
            openDialog: false,
            ada: '',
            Tditemui: '',
            menolak: '',
            tMampu: '',
            jumlah: '',
            setFile: null,
            kode_rt: 0,
            nama_rt: 0,
            pendata: null,
            yang_ada: 0,
            didata: 0,
            ditemui: 0,
            baru: 0,
            pindah: 0,
            meninggal: 0,
            tidak_ditemukan: 0,
            disAprrovaladmin: false,
            bercerai: 0,
            status: 0,
            didataTambah: 0,
            openDialogUploadSK: false,
            textCari: '',
            disApprov: true,
            dataFilter: [],
            datapromises: [],
            datas: [],
            no: "",
            wilayah: [],
            namaKelurahan: [],
            metodePendataan: [],
            smartphone: 0,
            role: JSON.parse(localStorage.getItem('authority'))[0].attributes.role,
            roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,

            dt_sdm: [{ value: 1, label: 'Supervisor CAPI', name: 'SPV CAPI' }, { value: 2, label: 'Supervisor PAPI', name: 'SPV PAPI' }, { value: 3, label: 'Operator', name: 'OPERATOR' }],
            filterSDM: [], selectedSDM: null,
            disAprrovalProv: false,


            //Wilayah
            dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
            dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,
            selectedRows: [], rowDipilih: [], selectNo: [],
            disDupli: false,

            approvValue: '',

            dt_user_role: [{ value: 6, label: 'Manager Data', nama: 7, approv: 2 }, { value: 3, label: 'Manager Pengelola', nama: 8, approv: 3 }],
            filterUserRole: [], selectedUserRole: null, nameRole: '',
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    handleOpenClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openDialog: !this.state.openDialog },
            () => { if (!this.state.openDialog) { this.clearForm() } }
        )
    }

    handleOpenCloseUpload = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openDialogUploadSK: !this.state.openDialogUploadSK },
            () => { if (!this.state.openDialogUploadSK) { this.clearForm() } }
        )
    }

    handleOpenCloseInfo = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openInfo: !this.state.openInfo })
    }


    clearForm = () => {
        this.setState({})
    }

    setHeaderTable = () => {
    }

    // componentDidUpdate = () => {
    //     if(this.state.id_provinsi === 33){
    //         this.setState({ disAprrovalProv: true })
    //     }
    //     

    // }

    componentDidMount() {
        // this.setHeaderTable()
        this.setState({ loginby: localStorage.getItem("username") })
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
        let title2, text11, text21
        const idTingkatWilayah2 = JSON.parse(localStorage.getItem("authority"))[0].attributes.user.wilProvinsi.id_provinsi

        let created = new Date().toISOString().substring(0, 10)
        let temp = new Date("2023-06-25").toISOString().substring(0, 10)

        if (idTingkatWilayah === 0) {
            title2 = ''

            this.setState({
                sendDatas: {
                    "idProv": 0,
                    "idKab": 0,
                    "idKec": 0,
                    "idKel": 0,
                    "idRW": 0,
                    "idRt": 0,
                    "limit": 300
                }
            });

        } else if (idTingkatWilayah === 1) {
            title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

            this.setState({
                sendDatas: {
                    "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
                    "idKab": 0,
                    "idKec": 0,
                    "idKel": 0,
                    "idRW": 0,
                    "idRt": 0,
                    "limit": 300
                }
            });

        } else if (idTingkatWilayah === 2) {
            title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

            this.setState({
                sendDatas: {
                    "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
                    "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                    "idKec": 0,
                    "idKel": 0,
                    "idRW": 0,
                    "idRt": 0,
                    "limit": 300
                }
            },
                () => { this.getBackend(this.state.sendDatas) }
            )


        } else {
            title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan

            this.setState({
                sendDatas: {
                    "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
                    "idKab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
                    "idKec": 0,
                    "idKel": 0,
                    "idRW": 0,
                    "idRt": 0,
                    "limit": 300
                }
            });
        }

        this.setState({ title: title2, text1: text11, text2: text21 },
            () => { this.setHeaderTable() }
        )

        if (rest === false) { this.props.history.push('/401') }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }
                const wilProvinsi = rest[0].attributes.user.wilProvinsi
                const wilKabupaten = rest[0].attributes.user.wilKabupaten
                const wilKecamatan = rest[0].attributes.user.wilKecamatan
                const wilKelurahan = rest[0].attributes.user.wilKelurahan

                if (Object.keys(wilProvinsi).length > 0) {

                    const objProp = {
                        'value': wilProvinsi.id_provinsi,
                        'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
                    };

                    this.setState({ dt_prov: objProp },
                        () => {
                            if (this.state.auto_prov === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(objProp)
                            }
                        }
                    );
                    //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                //        alert(wilKabupaten.length)
                if (Object.keys(wilKabupaten).length > 0) {
                    //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    const objKab = {
                        'value': wilKabupaten.id_kabupaten,
                        'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
                    };

                    this.setState({ dt_kab: objKab },
                        () => {
                            if (this.state.auto_kab === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kab(objKab)
                            }
                        }
                    );

                }
                if (Object.keys(wilKecamatan).length > 0) {
                    const objKec = {
                        'value': wilKecamatan.id_kecamatan,
                        'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
                    };

                    this.setState({ dt_kec: objKec },
                        () => {
                            if (this.state.auto_kec === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kec(objKec)
                            }
                        }
                    );
                    //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(wilKelurahan).length > 0) {
                    const objKel = {
                        'value': wilKelurahan.id_kelurahan,
                        'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
                    };

                    this.setState({ dt_kel: objKel },
                        () => {
                            if (this.state.auto_des === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_des(objKel)
                            }
                        }
                    );


                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    //API Wilayah
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {

        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, disAprrovalProv: false, disAprrovaladmin: false
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }

                }
            )
        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                disAprrovalProv: false, disAprrovaladmin: false
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }

                    if (this.state.id_provinsi) {
                        this.setState({ blocking: true })
                        APIButton.get("/aktif/getTombolAktifByProvIdKabId?periodeSensus=" + this.state.value_priode + "&kabupatenId=" + this.state.id_kabupaten + "&provinsiId=" + this.state.id_provinsi + "&modulPembayaran=PK" + '&periodeSensus=2024')
                            .then((res) => {
                                
                                this.setState({ blocking: false });
                                if (res.status === 200) {
                                    this.setState(
                                        {
                                            disAprrovalProv: res.data[0].ButtonStatus, disAprrovaladmin: res.data[0].ButtonStatus
                                        }

                                    )
                                }

                            })
                            .catch((error) => {
                                this.setState({ blocking: false });
                                
                            });
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {

                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_sdm = (e) => {
        if (e) {
            this.setState({
                filterSDM: e, selectedSDM: e.name,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterSDM: null, selectedSDM: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_user_role = (e) => {
        if (e) {
            this.setState({
                filterUserRole: e, selectedUserRole: e.value, nameRole: e.nama, approvValue: e.approv
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterUserRole: null, selectedUserRole: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    // handlePageChange = (page, rowsPerPage) => {
    //     this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    // }

    setLabel = () => {
        let title, text1, text2
        const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
        if (id_rt !== 0) {
            title = ' di RT ' + this.state.rt.label
        } else {
            title = ''
            if (id_rw !== 0) {
                title = ' di RW ' + this.state.rw.label
                text1 = 'RT'
                text2 = 'JUMLAH KK'
            } else {
                title = ''
                if (id_des !== 0) {
                    title = ' di Kelurahan ' + this.state.desa.label
                    text1 = 'RW'
                    text2 = 'JUMLAH RT'
                } else {
                    title = ''
                    if (id_kecamatan !== 0) {
                        title = ' di Kecamatan ' + this.state.kecamatan.label
                        text1 = 'KELURAHAN'
                        text2 = 'JUMLAH RW'
                    } else {
                        title = ''
                        if (id_kabupaten !== 0) {
                            title = ' di Kabupaten ' + this.state.kabupaten.label
                            text1 = 'KECAMATAN'
                            text2 = 'JUMLAH KELURAHAN'
                        } else {
                            title = ''
                            if (id_provinsi !== 0) {
                                title = ' di Provinsi ' + this.state.provinsi.label
                                text1 = 'KABUPATEN'
                                text2 = 'JUMLAH KECAMATAN'
                            } else {
                                title = ' Tingkat Nasional'
                                text1 = 'PROVINSI'
                                text2 = 'JUMLAH KABUPATEN'
                            }
                        }
                    }
                }
            }
        }

        this.setState({ title: title, text1: text1, text2: text2 },
            () => { this.setHeaderTable() }
        )
    }

    statusFormatter = (cell, row) => {
        let label = ''
        if (row.status === 1) {
            label = 'Pending MD'
        } else if (row.status === 2) {
            label = 'Pending MP'
        } else if (row.status === 3) {
            label = 'Complete'
        } else if (row.status === 7) {
            label = 'Reject MD'
        } else if (row.status === 8) {
            label = 'Reject MP'
        }

        return <div>{label}</div>
    }

    uploadView = (e, row) => {
        this.handleOpenCloseUpload()
    }

    handleDownloadFileSPV = (e, row) => {
        this.setState({ blocking: true })


        if (row.sdm !== "SPV CAPI") {
            let Data = new FormData();
            Data.append('idWilayah', row.id_rt)
            Data.append('idFileTypeUpload', row.id_file_type)
            Data.append('tingkatwilayah', '6')

            Swal.fire({
                title: 'Download File Rekap RT',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya !',
                cancelButtonText: 'Batal'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ blocking: true })

                    APIUpload.post('userpembiayaan/downloadFileUser', Data, {
                        responseType: 'blob',
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                const content = response.headers['content-type'];
                                download(response.data, row.file_name.replace(/ /g, ''), content)
                                this.setState({ blocking: false })
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'success',
                                    text: 'File berhasil didownload',
                                })

                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Error',
                                    icon: 'warning',
                                    text: 'File gagal didownload',
                                })

                                this.setState({ blocking: false })
                            }
                        })
                        .catch((error) => {
                            // Handle error.
                            Swal.fire({
                                title: 'Error',
                                icon: 'warning',
                                text: 'File gagal didownload',
                            })
                            this.setState({ blocking: false })
                        });
                } else {
                    this.setState({ blocking: false })
                }
            })
                .catch((error) => {
                    // Handle error.

                });
        } else {
            Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Tidak Ada File Di Supervisor CAPI ',
            })
            this.setState({ blocking: false })
        }
    }

    handleDownloadFileMD = (e, row) => {
        this.setState({ blocking: true })

        let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten
        let namawilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.nama_kabupaten

        let Data = new FormData();
        Data.append('idWilayah', +wilayah)
        Data.append('idFileTypeUpload', '2')
        Data.append('tingkatwilayah', '2')

        Swal.fire({
            title: 'Download File SK',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya !',
            cancelButtonText: 'Batal'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ blocking: true })

                APIUpload.post('userpembiayaan/downloadFileUser', Data, {
                    responseType: 'blob',
                })
                    .then((response) => {
                        if (response.status === 200) {
                            const content = response.headers['content-type'];
                            download(response.data, 'Surat-Keputusan-' + namawilayah + '.' + 'pdf', content)
                            this.setState({ blocking: false })
                            Swal.fire({
                                title: 'Info!',
                                icon: 'success',
                                text: 'File berhasil didownload',
                            })

                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Error',
                                icon: 'warning',
                                text: 'File gagal didownload',
                            })

                            this.setState({ blocking: false })
                        }
                    })
                    .catch((error) => {
                        // Handle error.
                        Swal.fire({
                            title: 'Error',
                            icon: 'warning',
                            text: 'File gagal didownload',
                        })
                        this.setState({ blocking: false })
                    });
            } else {
                this.setState({ blocking: false })
            }
        })
            .catch((error) => {
                // Handle error.
                this.setState({ blocking: false })
            });



    }

    noFormatter = (cell, row, rowIdx) => {
        // let rowNumber = (this.state.currentPage - 1) * this.state.sizePerPage + (rowIdx + 1);
        // return `<i class='glyphicon glyphicon-usd'></i> ${rowNumber}`;
        // let coba = row.map((el) => (el.id_rt === row.id_rt ))
        // 
        // )
        // const id = []
        // this.state.selectedRows.concat(row).forEach(el => {
        //     id.push(el.id)
        // })
        // 

        // let arr = []
        // arr = arr.concat(row)
        // 
        // for(let i=0; i<arr.length; i++){
        //     // arr[i].id = i+1

        //     return <span>{i}</span>
        // }
        // this.setState({datas: arr},
        //     ()=> {
        //         
        //     }    
        // )
        // return <span>aku</span>



    }

    TextFormatter = (cell, row) => {
        if (row.isDuplidate === true && row.validasiPembayaran === false) {
            return 'Data Duplicate dan Belum Update'
        } else if (row.validasiPembayaran === false) {
            return 'Data Belum Update'
        } else if (row.isDuplidate === true) {
            return 'Data Duplicate'
        } else {
            return ''
        }
    }


    fileFormatter = (cell, row) => {
        const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
        if (roleId === 3 || roleId === 6 || roleId === 19 || roleId === 99) {

            return <div className='divBtnTable'>
                {(row.sdm !== "OPERATOR") && <Tooltip title="Download View SPV"><Button id={row.id_rt} onClick={(e) => this.handleDownloadFileSPV(e, row)} className='btnTable' variant="outlined"><ImDownload2 style={{ color: 'blue' }} /></Button></Tooltip>}
                {(roleId === 3 || roleId === 6) && <Tooltip title="Download SK"><Button id={row.id_rt} onClick={(e) => this.handleDownloadFileMD(e, row)} className='btnTable' variant="outlined"><ImDownload style={{ color: 'blue' }} /></Button></Tooltip>}
                {/* <Tooltip title="Upload SK"><Button id={row.id_rt} onClick={(e) => this.uploadView(e, row)} className='btnTable' variant="outlined"><AiOutlineUpload style={{ color: 'green' }} /></Button></Tooltip> */}

            </div>
        } else {
            return '-'
        }
        // }

    }

    buttonFormatter = (cell, row) => {
        // if (this.state.role === "Supervisor") {
        //     return <div className='divBtnTable'>
        //         {row.status === 0 ?
        //             <Button onClick={(e) => this.handleEdit(e, row)} id={row.id} className='btnTable' variant="outlined"><BsFillArrowDownCircleFill style={{ color: 'green' }} /></Button>
        //             :
        //             <>
        //                 -
        //             </>
        //         }
        //     </div>
        // } else {
        const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
        if (roleId === 3 || roleId === 6 || roleId === 19 || roleId === 99) {

            return <div className='divBtnTable'>
                {(this.state.roleUserLogin !== 19 && this.state.roleUserLogin !== 99) &&
                    <div>
                        {this.state.disAprrovalProv === true &&
                            <>
                                <Button onClick={(e) => this.handleApprove(e, row, 'APPROV')} id={row.id} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button>
                                {((row.sdm === 'OPERATOR' && roleId === 3) || (row.sdm === 'OPERATOR' && (roleId === 19 || roleId === 99))) &&
                                    <Button onClick={(e) => this.handleApprove(e, row, 'NONAPPOV')} id={row.id} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button>
                                }
                                {row.sdm !== 'OPERATOR' &&
                                    <Button onClick={(e) => this.handleApprove(e, row, 'NONAPPOV')} id={row.id} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button>
                                }
                            </>}
                    </div>
                }
                {(this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) &&
                    <div>

                        {this.state.disAprrovaladmin === true &&
                            <>
                                <Button onClick={(e) => this.handleApprove(e, row, 'APPROV')} id={row.id} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button>
                                {((row.sdm === 'OPERATOR' && roleId === 3) || (row.sdm === 'OPERATOR' && (roleId === 19 || roleId === 99))) &&
                                    <Button onClick={(e) => this.handleApprove(e, row, 'NONAPPOV')} id={row.id} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button>
                                }
                                {row.sdm !== 'OPERATOR' &&
                                    <Button onClick={(e) => this.handleApprove(e, row, 'NONAPPOV')} id={row.id} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button>
                                }
                            </>
                        }
                    </div>
                }


            </div>
        } else {
            return '-'
        }
        // }

    }

    handleHapus = () => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin Menghapus Formulir ini?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {

        })
    }



    handleApprove = async (e, row, action) => {
        this.setState({ blocking: true, disApprov: true })

        const { couchdb, id_provinsi, id_kabupaten, kabupaten, id_kecamatan, id_des, kelurahan, id_rw } = this.state
        let datacdb = cdbKab.filter(el => el.nama_kabupaten_baru === kabupaten?.label.substring(5, kabupaten.length))
        let datafixcdb = datacdb.map(el => el.nama_kabupaten_lama)

        const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
        if (this.state.loginby !== localStorage.getItem("username")) {
            Swal.fire({
                title: 'warning!',
                icon: 'warning',
                text: 'Ada Data Tidak Sesuai, Diharuskan Untuk Reload Page!',
            }).then(() => {
                window.location.reload(false);
            })

        } else {
            if (action === 'APPROV') {
                if (row.status_validasi === "1") {
                    Swal.fire({
                        title: 'warning!',
                        icon: 'warning',
                        text: 'Data KDR Belum Di Approve',
                    })
                    this.setState({ blocking: false, disApprov: false })
                } else if (row.isDuplidate === true) {
                    Swal.fire({
                        title: 'warning!',
                        icon: 'warning',
                        text: 'Data Ada Yang Duplicate',
                    })
                    this.setState({ blocking: false, disApprov: false })
                } else if (row.validasiPembayaran === false) {
                    Swal.fire({
                        title: 'warning!',
                        icon: 'warning',
                        text: 'Masih terdapat Data Susulan dalam Proses',
                    })
                    this.setState({ blocking: false, disApprov: false })
                } else {
                    this.setState({ blocking: true, disApprov: true })
                    if (row.sdm === 'OPERATOR') {
                        if (roleId === 3) {
                            Swal.fire({
                                title: 'Apakah Anda Yakin?',
                                text: "Ingin Approve Formulir ini?",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Ya',
                                cancelButtonText: 'Tidak'
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    const dataApprove = {
                                        "id": [
                                            row.id
                                        ],
                                        "username": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
                                        "role_id": roleId === 19 || roleId === 99 ? this.state.selectedUserRole : (roleId === 6 ? 6 : 3),
                                        "status": roleId === 19 || roleId === 99 ? this.state.approvValue : (roleId === 6 ? 2 : 3),
                                        "createTunggakanBy": this.state.loginby
                                    }
                                    this.setState({ blocking: true })
                                    APIVerval.post('portalpk/metodepembayaranpk/updateFormulirPapi?periodeSensus=' + this.state.value_priode, dataApprove)
                                        .then((response) => {
                                            if (response.status === 200) {
                                                if (response.data['update formulir papi'] === "sukses") {
                                                    Swal.fire({
                                                        title: 'Info!',
                                                        icon: 'success',
                                                        text: 'Data Berhasil Di Approve',
                                                    }).then(() => {
                                                        this.handleSearch()
                                                        this.setState({ selectedRows: [], blocking: true })
                                                    })
                                                } else {
                                                    Swal.fire({
                                                        title: 'warning',
                                                        icon: '',
                                                        text: 'Data Gagal Di Approve',
                                                    }).then(() => {
                                                        this.handleSearch()
                                                        this.setState({ selectedRows: [], blocking: true })
                                                    })
                                                }
                                            }
                                        })
                                        .catch((error) => {
                                            let message = error;
                                            Swal.fire({
                                                title: 'Error!',
                                                icon: 'warning',
                                                // text: 'Jaringan bermasalah.',
                                                text: 'Data Gagal Di Approve',
                                            }).then(() => {
                                                this.handleSearch()
                                                this.setState({ selectedRows: [], blocking: false })
                                            })
                                        });
                                } else {
                                    this.setState({ blocking: false })
                                }
                            })

                        } else {
                            
                            const kab = datafixcdb[0]
                            const kabLower = kab.toLowerCase()
                            let remove = kabLower.split('.').join("");
                            let kabName = remove.replace(/ /g, "_");
                            let dbname = `data_bkkbn_${kabName}`

                            
                            
                            const dtime = new Date().getTime()
                            
                            let payloadCdb = {}
                            //get data form server couchdb
                            const data_selector = {
                                "selector": {
                                    // "user_name": row.pendata,
                                    "_id": "" + row.id_rt,
                                    "group": "dataRekap"
                                },
                                "limit": 1
                            }
                            const res = await axios.post(couchdb + '/' + dbname + '/_find', data_selector, {
                                headers: {
                                    Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                    user_key: this.state.USER_KEY_PG
                                }
                            })
                            const { data } = await res;
                            

                            if (data.docs.length === 0) {
                                payloadCdb = {
                                    "_id": "" + row.id_rt,
                                    "user_name": row.pendata,
                                    "group": "dataRekap",
                                    "periode_sensus": "2024",
                                    "id_rw": "" + row.id_rw,
                                    "id_rt": "" + row.id_rt,
                                    "status": 3,
                                    "http_method": "PUT",
                                    "tgl_submit": dtime
                                }
                            } else {
                                payloadCdb = {
                                    "_id": "" + row.id_rt,
                                    "_rev": data.docs[0]._rev,
                                    "user_name": row.pendata,
                                    "group": "dataRekap",
                                    "periode_sensus": "2024",
                                    "id_rw": "" + row.id_rw,
                                    "id_rt": "" + row.id_rt,
                                    "status": 3,
                                    "http_method": "PUT",
                                    "tgl_submit": dtime
                                }
                            }
                            

                            const payload = {
                                "id_rt": row.id_rt,
                                "user_name": this.state.loginby,
                                "yang_ada": row.yang_ada,
                                "didata": row.didata,
                                "ditemui": row.ditemui,
                                "baru": row.baru,
                                "pindah": row.pindah,
                                "meninggal": row.meninggal,
                                "tidak_ditemukan": row.tidak_ditemukan,
                                "bercerai": row.bercerai,
                                "userID": row.userId,
                                "status": roleId === 19 || roleId === 99 ? this.state.approvValue : (roleId === 6 ? 2 : 3),
                                "role_id": 10,
                                "id": row.id === 0 ? null : row.id,
                                "createTunggakanBy": this.state.loginby,
                                'id_provinsi': row.id_provinsi,
                                'id_kabupaten': row.id_kabupaten,
                                'id_kecamatan': row.id_kecamatan,
                                'id_rw': row.id_rw,
                                'id_kelurahan': row.id_kelurahan,
                                'sumber_pendanaan': row.sumber_pendanaan
                            }
                            

                            Swal.fire({
                                title: 'Apakah Anda Yakin?',
                                text: row.status_validasi === "2" ? "Data KDR Dan OPR Tidak Sama, Apakah Ingin Melanjutkan Approval?" : "Ingin Approve Formulir ini?",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Ya',
                                cancelButtonText: 'Tidak'
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    this.setState({ blocking: true, selectedRows: [] })
                                    axios.put(couchdb + '/' + dbname + '/' + row.id_rt, payloadCdb, {
                                        headers: {
                                            Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                            user_key: this.state.USER_KEY_PG
                                        }
                                    }).then(async response => {
                                        if (response.status === 200 || response.status === 201) {
                                            APIVerval.post('portalpk/metodepembayaranpk/insertFormulirPapi?periodeSensus=' + this.state.value_priode, payload)
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        if (response.data['insert formulir papi'] === "sukses") {

                                                            Swal.fire({
                                                                title: 'Info!',
                                                                icon: 'success',
                                                                text: 'Data Berhasil Di Approve',
                                                            }).then(() => {
                                                                this.handleSearch()
                                                                this.setState({ selectedRows: [], blocking: true })
                                                            })
                                                        } else {
                                                            Swal.fire({
                                                                title: 'warning',
                                                                icon: '',
                                                                text: 'Data Gagal Di Approve',
                                                            }).then(() => {
                                                                this.handleSearch()
                                                                this.setState({ selectedRows: [], blocking: true })
                                                            })
                                                        }
                                                    }
                                                })
                                                .catch(async (error) => {
                                                    let message = error;
                                                    await axios.put(couchdb + '/' + dbname + '/' + row.id_rt + '?rev=' + res.data.rev, {
                                                        headers: {
                                                            Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                                            user_key: this.state.USER_KEY_PG
                                                        }
                                                    })
                                                    Swal.fire({
                                                        title: 'Error!',
                                                        icon: 'warning',
                                                        // text: 'Jaringan bermasalah.',
                                                        text: message,
                                                    })
                                                    this.setState({ blocking: false })
                                                    this.handleSearch()
                                                });
                                        } else {
                                            await axios.put(couchdb + '/' + dbname + '/' + row.id_rt + '/_deleteDataRekap', {
                                                headers: {
                                                    Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                                    user_key: this.state.USER_KEY_PG
                                                }
                                            })
                                            Swal.fire({
                                                title: 'warning',
                                                icon: '',
                                                text: 'Data Gagal Di Approve',
                                            }).then(() => {
                                                this.handleSearch()
                                                this.setState({ blocking: true })
                                            })
                                        }
                                    }).catch((e) => {
                                        Swal.fire({
                                            title: 'Error',
                                            icon: '',
                                            text: 'Gagal melakukan lock pendataan',
                                        }).then(() => {
                                            this.handleSearch()
                                            this.setState({ blocking: true })
                                        })
                                    })
                                } else {
                                    this.setState({ blocking: false })
                                }
                            })
                        }
                    } else {
                        const dataApprov = {
                            "id": [
                                row.id
                            ],
                            "username": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
                            "role_id": roleId === 19 || roleId === 99 ? this.state.selectedUserRole : (roleId === 6 ? 6 : 3),
                            "status": roleId === 19 || roleId === 99 ? this.state.approvValue : (roleId === 6 ? 2 : 3),
                            "createTunggakanBy": this.state.loginby
                        }
                        

                        Swal.fire({
                            title: 'Apakah Anda Yakin?',
                            text: "Ingin Approve Formulir ini?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Ya',
                            cancelButtonText: 'Tidak'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.setState({ blocking: true })
                                APIVerval.post('portalpk/metodepembayaranpk/updateFormulirPapi?periodeSensus=' + this.state.value_priode, dataApprov)
                                    .then((response) => {
                                        if (response.status === 200) {
                                            if (response.data['update formulir papi'] === "sukses") {
                                                Swal.fire({
                                                    title: 'Info!',
                                                    icon: 'success',
                                                    text: 'Data Berhasil Di Approve',
                                                }).then(() => {
                                                    this.handleSearch()
                                                    this.setState({ selectedRows: [], blocking: true })
                                                })
                                            } else {
                                                Swal.fire({
                                                    title: 'warning',
                                                    icon: '',
                                                    text: 'Data Gagal Di Approve',
                                                }).then(() => {
                                                    this.handleSearch()
                                                    this.setState({ selectedRows: [], blocking: true })
                                                })
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        let message = error;
                                        Swal.fire({
                                            title: 'Error!',
                                            icon: 'warning',
                                            // text: 'Jaringan bermasalah.',
                                            text: 'Data Gagal Di Approve',
                                        }).then(() => {
                                            this.handleSearch()
                                            this.setState({ selectedRows: [], blocking: false })
                                        })
                                    });
                            } else {
                                this.setState({ blocking: false })
                            }

                        })
                    }
                }
            } else {
                this.setState({ blocking: true })
                
                const { couchdb, kabupaten } = this.state
                const kab = datafixcdb[0]
                const kabLower = kab.toLowerCase()
                let remove = kabLower.split('.').join("");
                let kabName = remove.replace(/ /g, "_");
                let dbname = `data_bkkbn_${kabName}`

                
                
                const dtime = new Date().getTime()
                
                let payloadCdb = {}
                //get data form server couchdb
                const data_selector = {
                    "selector": {
                        // "user_name": row.pendata,
                        "_id": "" + row.id_rt,
                        "group": "dataRekap"
                    },
                    "limit": 1
                }
                const res = await axios.post(couchdb + '/' + dbname + '/_find', data_selector, {
                    headers: {
                        Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                        user_key: this.state.USER_KEY_PG
                    }
                })
                const { data } = await res;
                
                const reject = {
                    "id": [
                        row.id
                    ],
                    "username": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
                    "role_id": roleId === 19 || roleId === 99 ? this.state.selectedUserRole : (roleId === 6 ? 6 : 3),
                    "status": roleId === 19 || roleId === 99 ? this.state.nameRole : (roleId === 6 ? 7 : 8),
                    "sdm": row.sdm
                }

                if (res.status === 200) {
                    this.setState({ blocking: false })
                } else {
                    this.setState({ blocking: false })
                }
                Swal.fire({
                    title: 'Apakah Anda Yakin?',
                    text: "Ingin Reject Formulir ini?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        if (data.docs.length > 0) {
                            data.docs[0].status = 2
                            axios.put(couchdb + '/' + dbname + '/' + row.id_rt, data.docs[0], {
                                headers: {
                                    Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                    user_key: this.state.USER_KEY_PG
                                }
                            }).then(res => {
                                

                                APIVerval.post('portalpk/metodepembayaranpk/updateFormulirPapi?periodeSensus=' + this.state.value_priode, reject)
                                    .then((response) => {
                                        if (response.status === 200) {
                                            this.handleOpenClose()
                                            Swal.fire({
                                                title: 'Info!',
                                                icon: 'success',
                                                text: 'Data Berhasil Di Reject',
                                            }).then(resp => {
                                                this.handleSearch()
                                                this.setState({ selectedRows: [], blocking: true })
                                            })
                                        } else {
                                            data.docs[0].status = 3
                                            axios.put(couchdb + '/' + dbname + '/' + row.id_rt, data.docs[0], {
                                                headers: {
                                                    Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                                    user_key: this.state.USER_KEY_PG
                                                }
                                            })
                                        }
                                    })
                                    .catch((error) => {
                                        let message = error;
                                        data.docs[0].status = 3
                                        axios.put(couchdb + '/' + dbname + '/' + row.id_rt, data.docs[0], {
                                            headers: {
                                                Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                                user_key: this.state.USER_KEY_PG
                                            }
                                        })
                                        Swal.fire({
                                            title: 'Error!',
                                            icon: 'warning',
                                            // text: 'Jaringan bermasalah.',
                                            text: message,
                                        })
                                    });
                            }).catch((e) => {
                                Swal.fire({
                                    title: 'Error',
                                    icon: '',
                                    text: 'Gagal melakukan unlock pendataan',
                                })
                                this.handleSearch()
                            })
                        } else {
                            APIVerval.post('portalpk/metodepembayaranpk/updateFormulirPapi?periodeSensus=' + this.state.value_priode, reject)
                                .then((response) => {
                                    if (response.status === 200) {
                                        this.handleOpenClose()
                                        Swal.fire({
                                            title: 'Info!',
                                            icon: 'success',
                                            text: 'Data Berhasil Di Reject',
                                        }).then(() => {
                                            this.handleSearch()
                                            this.setState({ blocking: true })
                                        })
                                    }
                                })
                                .catch((error) => {
                                    let message = error;
                                    Swal.fire({
                                        title: 'Error!',
                                        icon: 'warning',
                                        // text: 'Jaringan bermasalah.',
                                        text: message,
                                    })
                                    this.handleSearch()
                                    this.setState({ blocking: false })
                                });
                        }

                    } else {
                        this.setState({ blocking: false })
                    }
                })
                this.setState({ blocking: false })
            }
        }
    }

    handleApproveRejectAll = async (e) => {
        this.setState({ blocking: true, disApprov: true })
        const { couchdb, id_provinsi, id_kabupaten, kabupaten, id_kecamatan, id_des, kelurahan, id_rw } = this.state
        let datacdb = cdbKab.filter(el => el.nama_kabupaten_baru === kabupaten?.label.substring(5, kabupaten.length))
        let datafixcdb = datacdb.map(el => el.nama_kabupaten_lama)
        let arrId = this.state.selectedRows.filter(el => el.id === 0)
        let arrSpv = this.state.selectedRows.filter(el => el.sdm === 'SPV PAPI')
        let arrCapi = this.state.selectedRows.filter(el => el.sdm === 'SPV CAPI')
        let arrOpr = this.state.selectedRows.filter(el => el.sdm === 'OPERATOR')
        let opr1 = this.state.selectedRows.filter(el => el.status_validasi === '1')
        let opr2 = this.state.selectedRows.filter(el => el.status_validasi === '2')

        const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
        let dataDuplicate = []
        let dataValidasi = []
        dataDuplicate = this.state.selectedRows.filter(el => el.isDuplidate === true)
        dataValidasi = this.state.selectedRows.filter(el => el.validasiPembayaran === false)
        if (this.state.loginby !== localStorage.getItem("username")) {
            Swal.fire({
                title: 'warning!',
                icon: 'warning',
                text: 'Ada Data Tidak Sesuai, Diharuskan Untuk Reload Page!',
            }).then(() => {
                window.location.reload(false);
            })

        } else {
            if (dataDuplicate.length > 0 && dataValidasi.length > 0) {
                Swal.fire({
                    title: 'warning!',
                    icon: 'warning',
                    text: 'Data Ada Yang Duplicate Dan Masih terdapat Data Susulan dalam Proses',
                })
                this.setState({ blocking: false, disApprov: false })
            } else if (dataDuplicate.length > 0) {
                Swal.fire({
                    title: 'warning!',
                    icon: 'warning',
                    text: 'Data Ada Yang Duplicate',
                })
                this.setState({ blocking: false, disApprov: false })
            } else if (dataValidasi.length > 0) {
                Swal.fire({
                    title: 'warning!',
                    icon: 'warning',
                    text: 'Masih terdapat Data Susulan dalam Proses',
                })
                this.setState({ blocking: false, disApprov: false })
            } else if (opr1.length > 0) {
                Swal.fire({
                    title: 'warning!',
                    icon: 'warning',
                    text: 'Didalam List Approve, Ada Data KDR Belum Di Approve, Harap Dicek Kembali',
                })
                this.setState({ blocking: false, disApprov: false })
            } else {
                if (this.state.selectedRows.length > 0 || arrId.length > 0) {
                    Swal.fire({
                        title: 'Apakah Anda Yakin?',
                        text: opr2.length > 0 ? "Data KDR Dan OPR Tidak Sama, Apakah Ingin Melanjutkan Approval?" : "Ingin Approve Formulir ini?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak'
                    }).then(async result => {
                        if (result.isConfirmed) {
                            this.setState({ blocking: true, selectedRows: [] })
                            let promises = []
                            //---UPDATE APPROVE SPV
                            if ((arrSpv?.length > 0 || arrCapi?.length > 0 || arrOpr.length > 0 && roleId === 3) || (arrSpv?.length > 0 || arrCapi?.length > 0 || arrOpr.length > 0 && (roleId === 19 || roleId === 99) && this.state.selectedUserRole === 3)) {
                                let id = []
                                this.state.selectedRows.forEach(el => {
                                    id.push(el.id)
                                })
                                const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
                                const dataApprov = {
                                    "id": id,
                                    "username": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
                                    "role_id": roleId === 19 || roleId === 99 ? this.state.selectedUserRole : (roleId === 6 ? 6 : 3),
                                    "status": roleId === 19 || roleId === 99 ? this.state.approvValue : (roleId === 6 ? 2 : 3),
                                    "createTunggakanBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName
                                }
                                promises.push(
                                    APIVerval.post('portalpk/metodepembayaranpk/updateFormulirPapi?periodeSensus=' + this.state.value_priode, dataApprov)
                                        .then((response) => {
                                            let obj = {};
                                            var timeStamp = response;
                                            obj = timeStamp;
                                            this.state.datapromises.push(obj);

                                        })
                                        .catch((error) => {
                                        })
                                )
                                Promise.all(promises).then(() => {
                                    let datapro = []
                                    datapro = this.state.datapromises.filter(el => el.data['update formulir papi'] === "gagal")

                                    
                                    if (datapro.length > 0) {
                                        Swal.fire({
                                            title: 'Info!',
                                            icon: 'warning',
                                            text: 'Data Berhasil di Aprrove dan Ada ' + datapro.length + ' Data yang Gagal',
                                        }).then(() => {
                                            this.handleSearch()
                                            this.setState({ selectedRows: [], blocking: true })
                                        })

                                    } else {
                                        Swal.fire({
                                            title: 'Info!',
                                            icon: 'success',
                                            text: 'Data CAPI/PAPI Berhasil di Aprrove',
                                        }).then(() => {
                                            if (arrOpr.length > 0 && roleId === 6) {
                                                this.setState({ selectedRows: [], blocking: true })
                                            } else {
                                                this.handleSearch()
                                                this.setState({ selectedRows: [], blocking: true })
                                            }

                                        })

                                    }

                                    // this.handleSearch()
                                    // this.setState({blocking: false})
                                })
                                    .catch((error) => {
                                        let message = error;
                                        Swal.fire({
                                            title: 'Error!',
                                            icon: 'warning',
                                            text: 'Data gagal di Approve',
                                        })
                                        this.handleSearch()
                                        this.setState({ selectedRows: [], blocking: false })
                                    });
                            }

                            if ((arrOpr?.length > 0 && roleId === 6) || (arrOpr?.length > 0 && (roleId === 19 || roleId === 99) && this.state.selectedUserRole === 6)) {
                                Promise.all(arrOpr.map(async (row) => {
                                    //set couchdb
                                    const kab = datafixcdb[0]
                                    const kabLower = kab.toLowerCase()
                                    let remove = kabLower.split('.').join("");
                                    let kabName = remove.replace(/ /g, "_");
                                    let dbname = `data_bkkbn_${kabName}`

                                    // 
                                    // 
                                    const dtime = new Date().getTime()
                                    
                                    let payloadCdb = {}
                                    //get data form server couchdb
                                    const data_selector = {
                                        "selector": {
                                            // "user_name": row.pendata,
                                            "_id": "" + row.id_rt,
                                            "group": "dataRekap"
                                        },
                                        "limit": 1
                                    }
                                    const res = await axios.post(couchdb + '/' + dbname + '/_find', data_selector, {
                                        headers: {
                                            Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                            user_key: this.state.USER_KEY_PG
                                        }
                                    })
                                    const { data } = await res;
                                    

                                    if (data.docs.length === 0) {
                                        payloadCdb = {
                                            "_id": "" + row.id_rt,
                                            "user_name": row.pendata,
                                            "group": "dataRekap",
                                            "periode_sensus": "2024",
                                            "id_rw": "" + row.id_rw,
                                            "id_rt": "" + row.id_rt,
                                            "status": 3,
                                            "http_method": "PUT",
                                            "tgl_submit": dtime
                                        }
                                    } else {
                                        payloadCdb = {
                                            "_id": "" + row.id_rt,
                                            "_rev": data.docs[0]._rev,
                                            "user_name": row.pendata,
                                            "group": "dataRekap",
                                            "periode_sensus": "2024",
                                            "id_rw": "" + row.id_rw,
                                            "id_rt": "" + row.id_rt,
                                            "status": 3,
                                            "http_method": "PUT",
                                            "tgl_submit": dtime
                                        }
                                    }
                                    
                                    const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
                                    const payload = {
                                        "id_rt": row.id_rt,
                                        "user_name": this.state.loginby,
                                        "yang_ada": row.yang_ada,
                                        "didata": row.didata,
                                        "ditemui": row.ditemui,
                                        "baru": row.baru,
                                        "pindah": row.pindah,
                                        "meninggal": row.meninggal,
                                        "tidak_ditemukan": row.tidak_ditemukan,
                                        "bercerai": row.bercerai,
                                        "userID": row.userId,
                                        "status": roleId === 19 || roleId === 99 ? this.state.approvValue : (roleId === 6 ? 2 : 3),
                                        "role_id": 10,
                                        "id": row.id === 0 ? null : row.id,
                                        "createTunggakanBy": this.state.loginby,
                                        'id_provinsi': row.id_provinsi,
                                        'id_kabupaten': row.id_kabupaten,
                                        'id_kecamatan': row.id_kecamatan,
                                        'id_rw': row.id_rw,
                                        'id_kelurahan': row.id_kelurahan,
                                        'sumber_pendanaan': row.sumber_pendanaan

                                    }
                                    promises.push(
                                        await axios.put(couchdb + '/' + dbname + '/' + row.id_rt, payloadCdb, {
                                            headers: {
                                                Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                                user_key: this.state.USER_KEY_PG
                                            }
                                        }).then(async res => {
                                            if (res.status === 200 || res.status === 201) {
                                                await APIVerval.post('portalpk/metodepembayaranpk/insertFormulirPapi?periodeSensus=' + this.state.value_priode, payload)
                                                    .then((response) => {
                                                        let obj = {};
                                                        var timeStamp = response;
                                                        obj = timeStamp;
                                                        this.state.datapromises.push(obj);

                                                    })
                                                    .catch(async (error) => {
                                                        await axios.delete(couchdb + '/' + dbname + '/' + row.id_rt + '?rev=' + res.data.rev, {
                                                            headers: {
                                                                Authorization: 'Basic YWRtaW46cGFzc3dvcmQ=',
                                                                user_key: this.state.USER_KEY_PG
                                                            }
                                                        })
                                                    })
                                            } else {
                                                Swal.fire({
                                                    title: 'Error',
                                                    icon: '',
                                                    text: 'Gagal melakukan lock pendataan',
                                                })
                                            }
                                        }).catch((e) => {
                                            Swal.fire({
                                                title: 'Error',
                                                icon: '',
                                                text: 'Gagal melakukan lock pendataan',
                                            })
                                        })

                                    )
                                })).then(() => {
                                    Promise.all(promises).then(() => {
                                        let datapro = []
                                        datapro = this.state.datapromises.filter(el => el.data['insert formulir papi'] === "gagal")
                                        
                                        if (datapro.length > 0) {
                                            Swal.fire({
                                                title: 'Info!',
                                                icon: 'warning',
                                                text: 'Data Berhasil di Aprrove dan Ada ' + datapro.length + ' Data yang Gagal',
                                            }).then(() => {
                                                this.handleSearch()
                                                this.setState({ selectedRows: [], blocking: true })
                                            })

                                        } else {
                                            Swal.fire({
                                                title: 'Info!',
                                                icon: 'success',
                                                text: 'Data OPERATOR Berhasil di Aprrove',
                                            }).then(() => {
                                                this.handleSearch()
                                                this.setState({ selectedRows: [], blocking: true })
                                            })

                                        }

                                        // this.handleSearch()
                                        // this.setState({blocking: false})
                                    })
                                        .catch((error) => {
                                            let message = error;
                                            Swal.fire({
                                                title: 'Error!',
                                                icon: 'warning',
                                                text: 'Gagal melakukan lock pendataan',
                                            }).then(() => {
                                                this.handleSearch()
                                                this.setState({ selectedRows: [], blocking: false })
                                            })

                                        });
                                }).catch((error) => {
                                    let message = error;
                                    Swal.fire({
                                        title: 'Error!',
                                        icon: 'warning',
                                        text: 'Data gagal di Approve',
                                    })
                                    this.handleSearch()
                                    this.setState({ selectedRows: [], blocking: false })
                                });
                            }

                        } else {
                            this.setState({ blocking: false, disApprov: false })
                        }

                    })
                } else {
                    Swal.fire({
                        title: "INFO",
                        text: "Tidak ada data yang dipilih atau data telah disubmit",
                        icon: 'info',
                        showConfirmButton: true
                    })
                    this.setState({ blocking: false })
                }
            }
        }
    }

    handleAprrovSelect = (e, param) => {
        const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
        const dataApprov = {
            "id": [
                this.state.selectedRows[0].id
            ],
            "username": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
            "role_id": roleId,
            "status": roleId === 6 ? 2 : 3
        }

        const reject = {
            "id": [
                this.state.selectedRows[0].id
            ],
            "username": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
            "role_id": roleId,
            "status": 0
        }

        if (param === 'APPROV') {
            Swal.fire({
                title: 'Apakah Anda Yakin?',
                text: "Ingin Approve Formulir ini?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak'
            }).then((result) => {
                if (result.isConfirmed) {
                    APIVerval.post('portalpk/metodepembayaranpk/updateFormulirPapi?periodeSensus=' + this.state.value_priode, dataApprov)
                        .then((response) => {
                            if (response.status === 200) {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'success',
                                    text: 'Data Berhasil Di Approve',
                                })
                                this.handleSearch()
                                this.setState({ blocking: false })
                            }
                        })
                        .catch((error) => {
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                text: 'Data gagal di Approve',
                            })
                            this.handleSearch()
                            this.setState({ blocking: false })
                        });
                } else {
                    this.setState({ blocking: false })
                }

            })
        } else {
            Swal.fire({
                title: 'Apakah Anda Yakin?',
                text: "Ingin Reject Formulir ini?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak'
            }).then((result) => {
                if (result.isConfirmed) {
                    APIVerval.post('portalpk/metodepembayaranpk/updateFormulirPapi?periodeSensus=' + this.state.value_priode, reject)
                        .then((response) => {
                            if (response.status === 200) {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'success',
                                    text: 'Data Berhasil Di Reject',
                                })
                                this.handleSearch()
                                this.setState({ blocking: false, selectedRows: [] })
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                text: 'Data Gagal Di Reject',
                            })
                            this.handleSearch()
                            this.setState({ blocking: false, selectedRows: [] })
                        });
                } else {
                    this.setState({ blocking: false })
                }
            })
        }

    }

    selectCouchdb = () => {
        let setCdb = ""
        let provId = this.state.id_provinsi
        
        let CDB1 = process.env.REACT_APP_COUCHDB_1_URL
        let CDB2 = process.env.REACT_APP_COUCHDB_2_URL
        let CDB3 = process.env.REACT_APP_COUCHDB_3_URL
        let CDB4 = process.env.REACT_APP_COUCHDB_4_URL

        cdb1.map(key => {
            if (+key.id_provinsi === +provId) {
                setCdb = CDB1
            }
        })

        cdb2.map(key => {
            if (+key.id_provinsi === +provId) {
                setCdb = CDB2
            }
        })

        cdb3.map(key => {
            if (+key.id_provinsi === +provId) {
                setCdb = CDB3
            }
        })

        cdb4.map(key => {
            if (+key.id_provinsi === +provId) {
                setCdb = CDB4
            }
        })
        return setCdb
    }

    cekUpdateValidasi = async (param) => {
        
        this.setState({ blocking: true })
        // this.setState({blocking:true})
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw } = this.state
        let promises = []

        param.map(async (el) => {
            const reqParamValue = {
                "idProvinsi": id_provinsi,
                "idKabupaten": id_kabupaten,
                "idKecamatan": id_kecamatan,
                "idKelurahan": el.id_kelurahan,
                "idRw": el.id_rw,
                "idRt": el.id_rt
            }
            promises.push(
                await APIVerval.post("/portalpk/metodepembayaranpk/getValidasiPembayaran?periodeSensus=" + this.state.value_priode, reqParamValue)
                    .then((res) => {


                        let ndt = this.state.datas
                        this.setState(prevState => {
                            let dt = prevState.datas
                            
                            dt = dt.map(row => ((row.roleId !== 18 ? (row.id_rt === el.id_rt && row.userId === el.userId ? { ...el, validasiPembayaran: res.data.validasiPembayaran } : row) : row)))
                            return { datas: dt }
                        },
                            () => {


                                let dataDuplicate = []
                                let dataValidasi = []
                                dataDuplicate = this.state.datas.filter(el => el.isDuplidate === true)
                                dataValidasi = this.state.datas.filter(el => el.validasiPembayaran === false)
                                if (dataDuplicate.length > 0 || dataValidasi.length > 0) {
                                    this.setState({ disDupli: true })
                                } else {
                                    this.setState({ disDupli: false })
                                }

                            })

                    }).catch(() => {
                        
                    })
            )
        })
        Promise.all(promises).then(() => {
            this.setState({ blocking: false })
        }).catch(() => {
            this.setState({ blocking: false })
        })

    }

    handleSearch = () => {
        let couchdb = this.selectCouchdb()
        this.setState({ selectedRows: [] })
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw, selectedSDM, selectedUserRole, roleUserLogin } = this.state

        if (roleUserLogin === 19 || roleUserLogin === 99) {
            if (selectedUserRole !== null && selectedSDM !== null) {
                const reqParam = {
                    "provinsiId": +id_provinsi,
                    "kabupatenId": id_kabupaten,
                    "kecamatanId": id_kecamatan,
                    "kelurahanId": id_des,
                    "rwId": id_rw,
                    "roleId": selectedUserRole,
                    "sdm": selectedSDM
                }

                if (id_kecamatan !== 0 && selectedSDM !== null) {
                    this.setState({ blocking: true })
                    var newData = [];
                    APIVerval.post('portalpk/metodepembayaranpk/getListFormulirPapi?periodeSensus=' + this.state.value_priode, reqParam)
                        .then((res) => {

                            if (res.status === 200) {
                                this.setState({ blocking: false });

                                const arry = res.data.data.map((el) => el.id_rt)
                                const toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)
                                const duplicateElementa = toFindDuplicates(arry);
                                res.data.data.forEach(data => {
                                    let isDuplidate = duplicateElementa.indexOf(data.id_rt) >= 0 ? true : false
                                    const obj1 = Object.assign({ isDuplidate: isDuplidate }, data)
                                    data = obj1
                                    newData.push(data)
                                },)
                                this.setState({ datas: newData, selectedRows: [], couchdb }, () => {
                                    this.bstable.current.reset();
                                    if (selectedUserRole === 3 || roleUserLogin === 3) {

                                    } else {

                                        this.cekUpdateValidasi(newData)
                                    }
                                })
                                

                            } else {
                                Swal.fire({
                                    title: 'Error!',
                                    icon: 'warning',
                                    text: 'Pencarian Data Gagal, Harap Coba lagi!',
                                })
                                this.setState({ blocking: false });
                            }


                        })
                        .catch((error) => {
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                text: 'Pencarian Data Gagal, Harap Coba lagi!',
                            })
                            this.setState({ blocking: false });
                        });
                } else {
                    Swal.fire({
                        title: 'Info!',
                        icon: 'info',
                        text: 'Filter wilayah harus dipilih minimal tingkat kecamatan dan filter SDM wajib dipilih!',
                        showConfirmButton: true
                    })
                    this.setState({ blocking: false });
                }

            } else {
                Swal.fire({
                    title: 'Info!',
                    icon: 'info',
                    text: 'Filter User Role harus dipilih dan filter SDM wajib dipilih!',
                    showConfirmButton: true
                })
                this.setState({ blocking: false });
            }


        } else {
            const reqParam = {
                "provinsiId": +id_provinsi,
                "kabupatenId": id_kabupaten,
                "kecamatanId": id_kecamatan,
                "kelurahanId": id_des,
                "rwId": id_rw,
                "roleId": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
                "sdm": selectedSDM
            }

            if (id_kecamatan !== 0 && selectedSDM !== null) {
                this.setState({ blocking: true })
                var newData = [];
                var newDatas = []
                APIVerval.post('portalpk/metodepembayaranpk/getListFormulirPapi?periodeSensus=' + this.state.value_priode, reqParam)
                    .then((res) => {

                        if (res.status === 200) {
                            this.setState({ blocking: false });
                            const arry = res.data.data.map((el) => (el.id_rt))
                            
                            const toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)
                            const duplicateElementa = toFindDuplicates(arry);
                            res.data.data.forEach(data => {
                                let isDuplidate = duplicateElementa.indexOf(data.id_rt) >= 0 ? true : false
                                const obj1 = Object.assign({ isDuplidate: isDuplidate }, data)
                                data = obj1
                                newData.push(data)
                            },)
                            this.setState({ datas: newData, selectedRows: [], couchdb }, () => {
                                this.bstable.current.reset();
                                this.cekUpdateValidasi(newData)
                            })
                            
                        } else {
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                text: 'Pencarian Data Gagal, Harap Coba lagi!',
                            })
                            this.setState({ blocking: false });
                        }


                    })
                    .catch((error) => {
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: 'Pencarian Data Gagal, Harap Coba lagi!',
                        })
                        this.setState({ blocking: false });
                    });
            } else {
                Swal.fire({
                    title: 'Info!',
                    icon: 'info',
                    text: 'Filter wilayah harus dipilih minimal tingkat kecamatan dan filter SDM wajib dipilih!',
                    showConfirmButton: true
                })
                this.setState({ blocking: false });
            }

        }

    }

    handleChangeInput = (e, tab) => {
        if (e.target.name) {
            if (isNumber(e.target.value)) {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {

            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    handleChange = (image) => {
        this.setState({
            setFile: image
        })
    };


    handleSubmit = () => {
        let Data = new FormData();
        Data.append("id_rt", this.state.id_rt);
        Data.append("user_name", JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName);
        Data.append("yang_ada", this.state.yang_ada);
        Data.append("didata", +this.state.ditemui + +this.state.baru);
        Data.append("ditemui", this.state.ditemui);
        Data.append("baru", this.state.baru);
        Data.append("pindah", this.state.pindah);
        Data.append("meninggal", this.state.meninggal);
        Data.append("tidak_ditemukan", this.state.tidak_ditemukan);
        Data.append("bercerai", this.state.bercerai);
        Data.append("status", this.state.status);
        // Data.append("image", this.state.setFile);

        const dataTable = {
            // "role": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
            //"id": this.state.id,
            "id_rt": this.state.id_rt,
            "user_name": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
            "yang_ada": this.state.yang_ada,
            "didata": +this.state.ditemui + +this.state.baru,
            "ditemui": this.state.ditemui,
            "baru": this.state.baru,
            "pindah": this.state.pindah,
            "meninggal": this.state.meninggal,
            "tidak_ditemukan": this.state.tidak_ditemukan,
            "bercerai": this.state.bercerai,
            "status": 1
            // "image" : this.state.setFile
        }

        if (+this.state.ditemui + +this.state.baru < +this.state.yang_ada) {


            Swal.fire({
                title: 'Apakah Anda Yakin?',
                text: "Ingin Entri Formulir ini?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak'
            }).then((result) => {
                if (result.isConfirmed) {
                    APIVerval.post('portalpk/metodepembayaranpk/insertFormulirPapi?periodeSensus=' + this.state.value_priode, dataTable)
                        .then((response) => {
                            if (response.status === 200) {
                                this.handleOpenClose()
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'success',
                                    text: 'Data Berhasil Di Approve',
                                })
                                this.handleSearch()

                                this.setState({ blocking: false })
                            }

                        })
                        .catch((error) => {
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                        });
                }

            })
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Keluarga yang didata tidak boleh melebihin data keluarga yang ada',
                showConfirmButton: true
            })
        }
    }


    handleCetak = (e) => {
        e.preventDefault()
        // if (this.handleValidation()) {
        this.setState({ blocking: true });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw } = this.state;

        // const addFilter = this.state.additionalFilter
        // let newFilter = []
        // addFilter.forEach(row => {
        //     if (row.filtValue && row.valkey) {
        //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
        //     }
        // })

        Swal.fire({
            title: 'Cetak Formulir',
            //text: "Anda akan mencetak formulir",
            icon: 'info',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Excel',
            confirmButtonColor: '#3085d6',
            showDenyButton: true,
            denyButtonText: 'PDF',
            denyButtonColor: '#4ea842',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        }).then((result) => {

            let url = 'viewReport?filterPencarian=%7B'

            if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
            if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
            if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
            if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
            if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

            let urlCetakPdf = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
            let urlCetakExcel = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

            if (result.isConfirmed) {
                APIReport.get(urlCetakExcel, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'xlsx', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });

            } else if (result.isDenied) {
                APIReport.get(urlCetakPdf, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'pdf', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });


            } else {
                this.setState({ blocking: false });
            }
        })
    }

    createdFormatDuplicate = (cell, row) => {
        if (row.isDuplidate === true || row.validasiPembayaran === false || row.status_validasi === "1" || row.status_validasi === "2") {
            return 'fc3021'
        } else {
            return ''
        }
    }

    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    handleSelectRow = (cat, row, isSelect) => {
        this.setState({ disApprov: false })
        let data = this.state.datas
        let dataAll = []
        if (isSelect === true && cat === 'single') {
            this.setState({ selectedRows: this.state.selectedRows.concat(row), dataFilter: this.state.selectedRows.concat(row) });
        } else if (isSelect === true && cat === 'all') {
            if (this.state.textCari !== '') {
                dataAll = this.state.datas.filter(el => el.id_rt === row.id_rt)
                this.setState({ selectedRows: dataAll, dataFilter: dataAll });
            } else {

                dataAll = this.state.datas.filter(el => el.status === 0 || el.status === 7 || el.status === 8)
                this.setState({ selectedRows: data, dataFilter: dataAll });
            }
        } else if (cat === 'single') {
            var array = JSON.parse(JSON.stringify(this.state.selectedRows));
            var index = array.findIndex(el => el.id_rt === row.id_rt);
            if (index !== -1) {
                array.splice(index, 1);
                if (this.state.selectedRows?.length > 1) {
                    this.setState({ selectedRows: array, disApprov: false });
                } else {
                    this.setState({ selectedRows: array, disApprov: true });
                }
            }
        }
        else {
            this.setState({ selectedRows: [], disApprov: true });
        }


    }

    onSearchChange = (searchText, colInfos, multiColumnSearch) => {
        this.setState({ textCari: searchText })

    }

    createFormatDuplicateRow = (cell, row) => {
        if (row.isDuplidate === true) {
            return <div className='divBtnTable'>
                <Tooltip title="Data Duplicate"><p>2</p></Tooltip>
            </div>
        } else if (row.validasiPembayaran === false) {
            return <div className='divBtnTable'>
                <Tooltip title="Masih terdapat Data Susulan dalam Proses"><p>1</p></Tooltip>
            </div>
        } else if (row.isDuplidate === false && row.validasiPembayaran === false) {

            return <div className='divBtnTable'>
                <Tooltip title="Data Ada Duplicate dan Masih terdapat Data Susulan dalam Proses"><p>3</p></Tooltip>
            </div>
        } else if (row.status_validasi === "1") {

            return <div className='divBtnTable'>
                <Tooltip title="KDR Belum Di Approve"><p>OPR 1</p></Tooltip>
            </div>
        } else if (row.status_validasi === "2") {


            return <div className='divBtnTable'>
                <Tooltip title="Data KDR Dan OPR Tidak Sama"><p>OPR 2</p></Tooltip>
            </div>
        }
        else {
            return ''
        }
    }


    render() {

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10000px',
            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRow('single', row, isSelect)
            },
            onSelectAll: (isSelect, row, e) => {
                if (isSelect) {
                    this.handleSelectRow('all', row, isSelect)
                    return this.state.datas.map(row => row.id_rt)
                } else {
                    this.handleSelectRow('all', row, isSelect)
                    return [];
                }
            }

        };

        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                <div className='content'>
                    <div className='title-content'><ImPlay3 className='icon-brd' />Approval Operasional</div>
                    <div className='body-content'>
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Provinsi
                                                        <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kabupaten
                                                        <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kecamatan
                                                        <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kelurahan
                                                        <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        RW
                                                        <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        SDM
                                                        <Select options={this.state.dt_sdm} onChange={this.sel_sdm} value={this.filterSDM} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                {(this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) &&
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl sx={{ width: '100%' }} size="small">
                                                            User Role
                                                            <Select options={this.state.dt_user_role} onChange={this.sel_user_role} value={this.filterUserRole} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                        </FormControl>
                                                    </Grid>
                                                }

                                                {/* <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        SDM
                                                        <Select options={this.state.sdm} onChange={this.state.sel_sdm} value={this.state.sdmValue} id="prov" name="provinsi" placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid> */}
                                                <Grid item xs={12} md={4}>
                                                    <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails >
                            </Accordion >
                        </Box >

                        <h3>Approval Operasional Role {this.state.nameRole}</h3>
                        <Paper sx={{ width: "100%", boxShadow: "none" }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                <Grid item xs={12} md={12}>
                                    {/* <div>
                                    <span className='btnTable'><Tooltip title="Keterangan" placement="left" arrow><Button style={{ float: 'right' }} onClick={this.handleOpenCloseInfo} ><BsInfoCircle size={20} /></Button></Tooltip></span>
                                        {(this.state.roleUserLogin !== 19 && this.state.roleUserLogin !== 99) &&
                                            <div>
                                                {this.state.disAprrovalProv === true && <Button disabled={this.state.selectedRows?.length === 0 || this.state.disApprov} onClick={this.handleApproveRejectAll} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Approve</Button>}
                                            </div>
                                        }
                                        {(this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) &&
                                            <div>

                                                {this.state.disAprrovaladmin === true && <Button disabled={this.state.selectedRows?.length === 0 || this.state.disApprov} onClick={this.handleApproveRejectAll} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Approve</Button>}
                                            </div>
                                        }
                                    </div> */}
                                    <div>
                                        {/* <Button disabled={this.state.selectedRows?.length === 0}  sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: '#B22222', ':hover': { bgcolor: '#B22222', color: 'white' } }} variant="contained"><BsXCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Reject</Button> */}
                                        <span className='btnTable'><Tooltip title="Keterangan" placement="left" arrow><Button style={{ float: 'right' }} onClick={this.handleOpenCloseInfo} ><BsInfoCircle size={20} /></Button></Tooltip></span>
                                        {(this.state.roleUserLogin !== 19 && this.state.roleUserLogin !== 99) &&
                                            <>
                                                {this.state.disAprrovalProv === true && <Button disabled={this.state.selectedRows?.length === 0 || this.state.disApprov} onClick={this.handleApproveRejectAll} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Approve</Button>}
                                            </>
                                        }
                                        {((this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) || this.state.roleUserLogin === 99) &&
                                            <>
                                                {this.state.disAprrovaladmin === true && <Button disabled={this.state.selectedRows?.length === 0 || this.state.disApprov} onClick={this.handleApproveRejectAll} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Approve</Button>}
                                            </>
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                            <div className="rekap-table">
                                <>
                                    <BootstrapTable
                                        ref={this.bstable}
                                        keyField='id_rt'
                                        data={this.state.datas}
                                        pagination={true}
                                        options={options}
                                        search={true}
                                        selectRow={selectRow}
                                    >

                                        {/* <TableHeaderColumn width="80" dataField="#" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.noFormatter}   >NO</TableHeaderColumn> */}
                                        {/* {this.state.disDupli === true && <TableHeaderColumn width="180" dataField="#" headerAlign="center" dataAlign="center" dataFormat={this.TextFormatter}  >Status </TableHeaderColumn>} */}

                                        <TableHeaderColumn width="80" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn>
                                        <TableHeaderColumn width="80" dataField="kode_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >KODE RW </TableHeaderColumn>
                                        <TableHeaderColumn width="120" dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >NAMA RW </TableHeaderColumn>

                                        <TableHeaderColumn width="80" dataField="kode_rt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} columnTitle >KODE RT</TableHeaderColumn>
                                        <TableHeaderColumn width="120" dataField="nama_rt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} >NAMA RT </TableHeaderColumn>
                                        <TableHeaderColumn width="100" dataField="sdm" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} >SDM </TableHeaderColumn>

                                        <TableHeaderColumn width="180" dataField="pendata" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} >PENDATA </TableHeaderColumn>
                                        {/* <TableHeaderColumn width="60" dataField="targetkk" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TARGET KK </TableHeaderColumn> */}
                                        <TableHeaderColumn width="70" dataField="yang_ada" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} >YANG ADA </TableHeaderColumn>
                                        <TableHeaderColumn width="70" dataField="didata" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >DIDATA </TableHeaderColumn>
                                        <TableHeaderColumn width="70" dataField="ditemui" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} >DITEMUI </TableHeaderColumn>
                                        <TableHeaderColumn width="70" dataField="baru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} >BARU </TableHeaderColumn>
                                        <TableHeaderColumn width="70" dataField="pindah" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} >PINDAH </TableHeaderColumn>
                                        <TableHeaderColumn width="70" dataField="meninggal" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} >MENINGGAL </TableHeaderColumn>
                                        <TableHeaderColumn width="70" dataField="tidak_ditemukan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}>TIDAK DITEMUKAN </TableHeaderColumn>
                                        <TableHeaderColumn width="70" dataField="bercerai" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} columnClassName={this.createdFormatDuplicate}>BERCERAI </TableHeaderColumn>
                                        <TableHeaderColumn width="80" dataField="file" headerAlign="center" dataAlign="center" dataFormat={this.fileFormatter} columnClassName={this.createdFormatDuplicate} >FILE </TableHeaderColumn>
                                        <TableHeaderColumn width='100' dataField='status' dataFormat={this.createFormatDuplicateRow} headerAlign='center' dataAlign='center' columnClassName={this.createdFormatDuplicate}>KETERANGAN</TableHeaderColumn>

                                    </BootstrapTable>
                                    <div className="sizePerPage">
                                        Size Per Page
                                        <select
                                            name="category"
                                            value={this.state.sizePerPage}
                                            onChange={this.handleChangeSizePerPage}
                                        >
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value={this.state.datas?.length}>All</option>
                                        </select>
                                    </div>
                                    <div className="totalData">
                                        <b>Total Data: {this.state.datas?.length}</b>
                                    </div>
                                </>


                            </div>

                        </Paper>
                        <Dialog open={this.state.openInfo} onClose={this.handleOpenCloseInfo} width={'xs'}>
                            <DialogTitle style={{ paddingLeft: '25px' }}><BsInfoCircle size={15} />&nbsp; Info</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 1 }} style={{ marginTop: '10px', marginBottom: '15px', paddingLeft: '15px' }}> */}
                                    Info Code di Column KETERANGAN <br />
                                    ALL : <br />
                                    - Keterangan 1 adalah Masih terdapat Data Susulan dalam Proses <br />
                                    - Keterangan 2 adalah Data Duplicate<br />
                                    - Keterangan 3 adalah Data Ada Duplicate dan Masih terdapat Data Susulan dalam Proses<br />
                                    <br />
                                    OPR: <br />
                                    - Keterangan OPR 1 adalah Data KADER Belum Di Approve <br />
                                    - Keterangan OPR 2 adalah Data KADER Dan OPR Tidak Sama<br />


                                    {/* </Grid> */}

                                </DialogContentText>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleOpenCloseInfo} variant="outlined" color="primary">Tutup</Button>
                            </DialogActions>
                        </Dialog>

                        <div className='uploadmodal'>
                            <Dialog open={this.state.openDialogUploadSK} onClose={this.handleOpenClose} maxWidth>
                                <DialogTitle>Upload SK</DialogTitle>
                                {/* {this.state.id ? 'Ubah' : 'Tambah'} */}
                                <DialogContent>
                                    <DialogContentText>
                                        {/* RW: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_rw}<br /> */}
                                    </DialogContentText>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Grid item xs={12} md={6} style={{ marginTop: '20px' }}>
                                            <FileUploader
                                                handleChange={this.handleChange}
                                                name="pdf"
                                                label="Upload File SK"
                                                types={fileTypes}
                                                hoverTitle="Upload File"
                                                style={{ width: '100%' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button disabled={this.state.disabledSimpanRt} variant="outlined" style={{ color: 'blue' }}>Simpan</Button>

                                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenCloseUpload} variant="outlined" color="error">Batal</Button>
                                </DialogActions>
                            </Dialog>
                        </div>



                    </div >
                </div >
            </BlockUi >
        )
    }
}

export default approvalOperasional;