import React, { Component } from 'react'
import { ImFileExcel, ImFilePdf, ImPlay3, ImPrinter, ImSearch } from 'react-icons/im';
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIPemutakhiranMonitoring from "../../../services/APIPemutakhiranMonitoring";
import APIDashboard from "../../../services/APIDashboard";
import APIReport from '../../../services/APIReport';
import CommonTable from '../../../commons/table/Table';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdAlternateEmail, MdPassword, MdPlace } from 'react-icons/md';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import APIMonitoringBaru from '../../../services/APIMonitoringBaru';

class Metode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panel1: true,
      sizePerPage: 5,
      title: '',
      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',

      datas: [],
      no: "",
      wilayah: [],
      namaKelurahan: [],
      metodePendataan: [],
      smartphone: 0,

      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

      dt_status: [{ value: 1, label: 'Non-Aktif' }, { value: 2, label: 'Aktif' }],
      selectedStatus: [], id_status: 0, label_status: '',

      sendDatas: {
        "idProv": 0,
        "idKab": 0,
        "idKec": 0,
        "idKel": 0,
        "idRW": 0,
        "idRt": 0,
        "limit": 300
      },
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
    }
  }

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      },() => {
        this.setHeaderTable()    
        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if(role === 1 || role === 99 ) {
          this.setState({dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
          dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
          dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
          dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
          dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
          dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',})
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }
    
            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  setHeaderTable = () => {
    var headerColumns = [
      // { width: "50", row: '0', rowSpan: '2', title: 'NO', dataField: "no", headerAlign: 'center', dataAlign: 'center', editable: false },

      // headerColumnsProv
      { width: "300", row: '0', colSpan: '2', title: 'PROVINSI', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriProv', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_prov', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKab
      { width: "300", row: '0', colSpan: '2', title: 'KABUPATEN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKab', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kab', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKec
      { width: "300", row: '0', colSpan: '2', title: 'KECAMATAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKec', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kec', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKel
      { width: "300", row: '0', colSpan: '2', title: 'KELURAHAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKel', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kel', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerMetodePendataan
      { width: "120", row: '0', rowSpan: '2', title: 'METODE PENDATAAN', dataField: "metodePendataan", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", row: '0', rowSpan: '2', title: 'STATUS', dataField: 'statusActive', dataFormat: 'statusAktif', headerAlign: 'center', dataAlign: 'center', editable: false, fontColor: '#CD5C5C' },

    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidMount() {
    this.setHeaderTable()
    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    let title2, text11, text21

    if (idTingkatWilayah == 0) {
      title2 = ''

      this.setState({
        sendDatas: {
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      },
        () => { this.getBackend(this.state.sendDatas) }
      )


    } else {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });
    }

    this.setState({ title: title2, text1: text11, text2: text21 },
      () => { this.setHeaderTable() }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
              }
            }
          );


          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  //API (Wilayah)
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      //handleSearch
      this.setState({
        sendDatas: {
          "idProv": e.value,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
      );

      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    } else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": e.value,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
        , id_kecamatan: 0, id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    } else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": e.value,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
    () => {this.getBackend(this.state.sendDatas)} */
      );

      /*
            this.setState(prevState => ({
              sendDatas:
                  {
                      ...prevState.sendDatas, ['idKec']: e.value
                  }
              },
              () => {this.getBackend(this.state.sendDatas)}
              ))
      */

      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
        , id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    } else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": this.state.sendDatas.idKec,
          "idKel": e.value,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );

      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
        , id_rw: 0

      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    } else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": this.state.sendDatas.idKec,
          "idKel": this.state.sendDatas.idKel,
          "idRW": e.value,
          "idRt": 0,
        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    } else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    } else {
      this.setState({
        rt: null, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  handleChangeStatus = (e) => {
    if (e) {
      this.setState({
        selectedStatus: e,
        id_status: e.value,
        label_status: e.label
      })
    } else {
      this.setState({
        selectedStatus: null,
        id_status: null,
        label_status: null
      })
    }
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  handleSearch = () => {
    // 
    // 

    const { id_provinsi, id_kabupaten, id_kecamatan, selectedStatus, id_status } = this.state
    this.setLabel()
    let status = ''
    let urlGet = ''
    if (selectedStatus !== null) {

      if (id_status === 1) {
        status = false
        urlGet = '&v_is_active=' + status
      } else if (id_status === 2) {
        status = true
        urlGet = '&v_is_active=' + status
      }
    } else {
      urlGet = ''
    }
    // 

    if (id_provinsi !== 0) {

      var newData = [];
      APIMonitoringBaru.get('portalpk/monitoring/getMetodePendataanV2?v_id_provinsi=' + id_provinsi + '&v_id_kabupaten=' + id_kabupaten + '&v_id_kecamatan=' + id_kecamatan + urlGet)
        .then((res) => {
          // 
          this.setState({ blocking: false });
          if (res.status === 200) {
            if (res.data.data.length === 0) {
              this.setState({
                blocking: false,
                smartphone: 0,
                datas: [],
                wilayah: null
              });
            } else {

              res.data.data.forEach(data => {

                const obj = { no: res.data.data.indexOf(data) + 1, kodeDepdagriProv: data.kode_prov, nama_prov: data.nama_provinsi, kodeDepdagriKab: data.kode_kab, nama_kab: data.nama_kabupaten, kodeDepdagriKec: data.kode_kec, nama_kec: data.nama_kecamatan, kodeDepdagriKel: data.kode_kel, nama_kel: data.nama_kelurahan, metodePendataan: data.metode_pendataan !== null ? data.metode_pendataan : "-", statusActive: data.active === true ? 'Aktif' : (data.active === false ? 'Non-Aktif' : '-') }
                // const obj = { no: data.no, namaKelurahan: data.namaKelurahan, metodePendataan: data.metodePendataan !== null ? data.metodePendataan : "Manual" };
                newData.push(obj);
              });
              const x = newData.filter(el => el.metodePendataan === 'SMARTPHONE')
              // 
              this.setState({
                blocking: false,
                smartphone: x.length,
                datas: newData,
                wilayah: res.data.data[0].wilayah ? res.data.data[0].wilayah : null
              });
            }
          }
          
        })
        .catch((error) => {
          this.setState({ blocking: false });
          
        });
    } else {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Filter wilayah harus dipilih minimal Provinsi',
        showConfirmButton: true
      })
      this.setState({ blocking: false });
    }
  }

  handleCetak = (e) => {
    e.preventDefault()
    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw } = this.state;

    // const addFilter = this.state.additionalFilter
    // let newFilter = []
    // addFilter.forEach(row => {
    //     if (row.filtValue && row.valkey) {
    //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
    //     }
    // })

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#4ea842',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#FF8000',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      let urlCetakPdf = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
      let urlCetakExcel = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // 
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            // 
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // 
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })
  }

  statusFormatter = (cell, row) => {
    
    if (row.status_active === true) {
      return <div className='userActive'>< MdOutlineCheckBox style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Aktif</div>
    } else {
      return <div className='userNonActive'><MdOutlineCheckBoxOutlineBlank style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Non-Aktif</div>
    }

  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Metode Pendataan</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Status
                            <Select options={this.state.dt_status} isClearable onChange={this.handleChangeStatus} value={this.state.selectedStatus} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          Kelurahan
                          <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl sx={{ width: '100%' }} size="small">
                          RW
                          <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                        </FormControl>
                      </Grid> */}
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleCetak} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', backgroundColor: 'green', marginRight: '5px', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>
                          {/* <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={12}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '5px' }} variant="contained">Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Metode Pendataan {this.state.title}</h3>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
              />
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='1'>1</option>
                  <option value='5'>5</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              <div className='totalData'>
                <b style={{ marginRight: '15px' }}>Total Data: {this.state.datas.length}</b>
                <b style={{ marginRight: '15px' }}>Smartphone: {this.state.smartphone}</b>
                <b style={{ marginRight: '15px' }}>Manual: {this.state.datas.length - this.state.smartphone}</b>
              </div>
            </Paper>
          </div>
        </div>
      </BlockUi>
    )
  }
}

export default Metode;