import React, { Component } from "react";
import { Select as MuiSelect, TextField, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, TableCell, Input, Checkbox } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../../services/APIWilayah';
import APIUpload from '../../../../services/APIUpload';
import APIDashboard from "../../../../services/APIDashboard";
import Swal from 'sweetalert2';
import { HiOutlineDocumentText, HiOutlineDocumentDuplicate } from "react-icons/hi";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
//import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';

import { ImPlay3, ImSearch, ImPrinter, ImUsers, ImDownload2 } from "react-icons/im";
import { BsPencilSquare, BsPrinterFill, BsInfoCircle } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import APIReport from '../../../../services/APIReport';
import download from 'downloadjs'
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import APIMonPendataan from "../../../../services/APIMonPendataan";
import APIPemutakhiran from "../../../../services/APIPemutakhiran";
import APIVerval from "../../../../services/APIVerval";

class MonitoringApproval extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panel1: true,
            sizePerPage: 10,
            sizePerPage2: 10,
            sizePerPage3: 10,

            title: '',
            title2: '',
            title3: '',

            blocking: false,
            tableHead: [],
            datas: [],

            // datas: [{'id': 0, 'sdm': 'Pendata', 'pendata':'Pendata A', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':48, 'KKdidata':'48', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': '', 'nokuitansi':'00012'},
            //         {'id': 1,'sdm': 'Pendata','pendata':'Pendata B', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':50, 'KKdidata':'50', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': '', 'nokuitansi':'00013'},
            //         {'id': 2,'sdm': 'Pendata','pendata':'Pendata C', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':51, 'KKdidata':'50', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 1, 'nokuitansi':'00014'},
            //         {'id': 3,'sdm': 'Pendata','pendata':'Pendata D', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 3, 'nokuitansi':'00015'},
            //         {'id': 4,'sdm': 'Manager Data','pendata':'Manager A', 'kecamatan':'ABC', 'kelurahan': '', 'jumlahRT': '100', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 2, 'nokuitansi':'00016'},
            //         {'id': 5,'sdm': 'Supervisor','pendata':'Supervisor A', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '20', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 1, 'nokuitansi':'00017'},
            //         {'id': 6,'sdm': 'Pendata','pendata':'Pendata E', 'kecamatan':'ABC', 'kelurahan': '', 'jumlahRT': '100', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 4, 'nokuitansi':'00016', 'nosp2d':'5555123'},],

            //Wilayah
            dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
            dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

            openRejectDialog: false,
            datasView: [],
            roleName: '',
            namaProvinsi: '',
            namaKelurahan: '',
            namaKecamatan: '',
            namaKabupaten: '',
            created_by_md: '',
            created_by_mp: '',
            created_by_spv: '',
            created_date_md: '',
            created_date_mp: '',
            created_date_spv: '',
            openPreviewRekapRT: false,
            openPreviewPenjabat: false,
            dataRekap: [],
            openPreviewPenjabat: false,
            datasPenjabat: [],

            roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
            dt_sdm: [{ value: 25, label: 'Pengumpul Desa/Kelurahan', name: 'Pengumpul' }, { value: 26, label: 'Pengolah Kecamatan', name: 'Pengolah' },
            { value: 27, label: 'Verifikator Kecamatan', name: 'Verifikator' }],
            filterSDM: [], selectedSDM: null, selectedSDMCetak: null,
            disCetakKuitansi: false,
            openDetailRT: false,
            openCloseInputSP2D: false,
            dt_metode: [{ value: 'Tunai', label: 'Tunai' }, { value: 'Non-Tunai', label: 'Non Tunai' }, { value: '', label: 'DRAFT' }],
            dt_tunggakan: [{ value: true, label: 'Ya' }, { value: false, label: 'Tidak' }],
            selectedMetode: null, metodeTampil: [],
            selectedTunggakan: null, tunggakanTampil: [],

            data_RT: [{ kode: ' 001', nama_rt: '001' }, { kode: '002', nama_rt: '002' }, { kode: '003', nama_rt: '003' }],

            selectedRows: [], rowDipilih: [],
            selectedRowsKedua: [], rowDipilih2: [],
            rowSDM: null,
            dt_status: [{ value: "1", label: 'Pending PPK' }, { value: "2", label: 'Pending Bendahara' },
            { value: "3", label: 'Completed' }],
            disStatus: false,
            dt_pembayaran: [{ value: ' TUNAI', label: 'TUNAI' }, { value: 'NON TUNAI', label: 'NON TONAI' }],
            filterStatus: [], selectedStatus: 0, filterPembayaran: [], selectedPembayaran: null,

            openInfo: false,
            textCari: '',

            usernameBiodata: '',

            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }


    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID


                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    componentDidMount() {
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
        let title2, text11, text21

        // 

        // if(this.state.roleUserLogin === 15){ //pelaksana kegiatan
        //     const data_pelaksana = this.state.datas.filter(data => data.flag === 1)
        //     this.setState({datas: data_pelaksana})
        // } else if(this.state.roleUserLogin === 16){//PPK
        //     const data_ppk = this.state.datas.filter(data => data.flag === 2)
        //     this.setState({datas: data_ppk})
        // } 

        if (idTingkatWilayah == 0) {
            title2 = ''

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": 1,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0
                }
            }
            );

        } else if (idTingkatWilayah == 1) {
            title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 1,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": 0,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }


            )


        } else if (idTingkatWilayah == 2) {
            title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 2,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                },
                () => { }

            )


        } else {
            title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan


            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 3,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }
            )
        }

        this.setState({ title: title2 },
        )

        if (rest === false) { this.props.history.push('/401') }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
            // Mengambil roleID dari atribut user dalam objek pertama di array 'rest'
            let role = rest[0].attributes.user.roleID;

            if (role === 26 || role === 27) {
                this.sel_status({ value: "3", label: 'Completed' });

                this.setState({
                    disStatus: true
                });
            }



            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }
                const wilProvinsi = rest[0].attributes.user.wilProvinsi
                const wilKabupaten = rest[0].attributes.user.wilKabupaten
                const wilKecamatan = rest[0].attributes.user.wilKecamatan
                const wilKelurahan = rest[0].attributes.user.wilKelurahan

                if (Object.keys(wilProvinsi).length > 0) {

                    const objProp = {
                        'value': wilProvinsi.id_provinsi,
                        'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
                    };

                    this.setState({ dt_prov: objProp },
                        () => {
                            if (this.state.auto_prov === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(objProp)
                            }
                        }
                    );
                    //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                //        alert(wilKabupaten.length)
                if (Object.keys(wilKabupaten).length > 0) {
                    //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    const objKab = {
                        'value': wilKabupaten.id_kabupaten,
                        'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
                    };

                    this.setState({ dt_kab: objKab },
                        () => {
                            if (this.state.auto_kab === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kab(objKab)
                            }
                        }
                    );

                }
                if (Object.keys(wilKecamatan).length > 0) {
                    const objKec = {
                        'value': wilKecamatan.id_kecamatan,
                        'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
                    };

                    this.setState({ dt_kec: objKec },
                        () => {
                            if (this.state.auto_kec === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kec(objKec)
                            }
                        }
                    );
                    //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(wilKelurahan).length > 0) {
                    const objKel = {
                        'value': wilKelurahan.id_kelurahan,
                        'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
                    };

                    this.setState({ dt_kel: objKel },
                        () => {
                            if (this.state.auto_des === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_des(objKel)
                            }
                        }
                    );


                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    //API (Wilayah)
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            //handleSearch
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": e.value,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
            );

            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )


        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
                , id_kecamatan: 0, id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "idKec": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            });


            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
                , id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": e.value,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
                , id_rw: 0

            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": e.value,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_sdm = (e) => {
        if (e) {
            this.setState({
                filterSDM: e, selectedSDM: e.value, selectedSDMCetak: e.cetak
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterSDM: null, selectedSDM: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_status = (e) => {
        console.log(e)
        if (e) {
            this.setState({
                filterStatus: e, selectedStatus: e.value,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterStatus: null, selectedStatus: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_pembayaran = (e) => {
        if (e) {
            this.setState({
                filterPembayaran: e, selectedPembayaran: e.value,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterPembayaran: null, selectedPembayaran: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_metode = (e) => {
        if (e) {
            this.setState({
                selectedMetode: e.value, metodeTampil: e,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                selectedMetode: null, metodeTampil: null,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_Tunggakan = (e) => {
        if (e) {
            this.setState({
                selectedTunggakan: e.value, tunggakanTampil: e,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                selectedTunggakan: null, tunggakanTampil: null,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handlePageChange = (page, rowsPerPage) => {
        this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    }

    handleSearch = () => {


        var newData = [];
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, selectedSDM, selectedStatus, selectedMetode, selectedTunggakan } = this.state
        if (id_provinsi !== null && selectedSDM !== null && id_kabupaten !== 0 && id_kabupaten !== undefined) {
            this.setState({ blocking: true, selectedRows: [] })

            const param = {
                "id_prov": +id_provinsi,
                "id_kab": id_kabupaten,
                "id_kec": id_kecamatan,
                "id_kel": id_des,
                "status_approval": selectedStatus,
                "role_sdm": selectedSDM,
                "sumber_dana": "APBN"
            }
            APIVerval.post("siga/pembayaranverval/getMonitoringApprovalPembayaranVerval?periodeSensus=" + this.state.value_priode, param)
                .then((res) => {

                    if (res.data.data.length === 0) {
                        Swal.fire({
                            title: 'Info!',
                            icon: 'warning',
                            text: 'Data Tidak Ditemukan',
                        })
                        this.setState({ blocking: false, datas: [], selectedRows: [] });
                    } else {

                        if (res.status === 200) {
                            res.data.data.forEach(data => {
                                let verval = data.jml_kk_ada + data.jml_kk_baru;
                                const obj1 = Object.assign({ no: res.data.data.indexOf(data) + 1 }, { terverval: verval }, data)
                                data = obj1
                                newData.push(data)
                            },)
                            this.setState(
                                {
                                    datas: newData,

                                    blocking: false, selectedRows: []
                                }

                            )

                        } else {
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                text: 'Pencarian Data Gagal, Harap Coba lagi!',
                            })
                            this.setState({ blocking: false });
                        }
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: 'Pencarian Data Gagal, Harap Coba lagi!',
                    })
                    this.setState({ blocking: false });
                });
        } else {
            Swal.fire({
                title: 'Info',
                icon: 'info',
                text: 'Filter wilayah minimal dipilih tingkat kabupaten dan serta filter SDM',
                showConfirmButton: true,
            });
        }

    }

    BANKFormatter = (cell, row) => {
        if (row.rekening_bank === " / ") {
            return ''
        } else {
            return <div>{row.rekening_bank}</div>
        }
    }
    buttonFormatter = (cell, row) => {
        // Fungsi untuk memeriksa validitas bank
        const isBankValid = (bank) => {
            // Pisahkan bank berdasarkan tanda '-'
            const parts = bank.split(' - ');
            if (parts.length !== 2) {
                return false;
            }

            const [part1, part2] = parts;

            // Cek apakah salah satu bagian atau kedua bagian kurang dari 5 karakter atau hanya "-"
            if (
                part1.length < 5 ||
                part2.length < 5 ||
                part1 === '-' ||
                part2 === '-' ||
                bank === '-'
            ) {
                return false;
            }

            return true;
        };

        // Gunakan fungsi validasi
        if (isBankValid(row.bank)) {
            return (
                <div className='divBtnTable'>
                    {row.status !== 1 ? (
                        <Tooltip title="Cetak Kuitansi & Lampiran">
                            <span>
                                <Button
                                    id={row.id}
                                    onClick={(e) => this.handleCetakKuitansi(e, row, 'kuitansi')}
                                    className='btnTable'
                                    variant="outlined"
                                >
                                    <ImPrinter style={{ color: 'red' }} />
                                </Button>
                            </span>
                        </Tooltip>
                    ) : (
                        <>-</>
                    )}
                </div>
            );
        } else {
            return (
                <div className='divBtnTable'>
                    <span>Bank Tidak Sesuai</span>
                </div>
            );
        }
    };



    downloadSP2D = (e, row) => {

        let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten
        const link = document.createElement("a");
        link.target = "_blank";

        let Data = new FormData();
        Data.append('userId ', row.id_user)
        Data.append('userName ', row.user_name)




        APIVerval.get('siga/pembayaranverval/downloadSp2d?userId=' + row.id_user + '&userName=' + row.user_name, {
            responseType: 'blob',
        })
            .then((res) => {


                link.href = URL.createObjectURL(
                    new Blob([res.data], { type: "application/pdf" })
                );
                this.setState({
                    // previewModal: !this.state.previewModal,
                    // urlPdf: link.href
                })

                link.click();

            })
            .catch((error) => {
                let message = error;
                Swal.fire({
                    title: 'Error!',
                    icon: 'warning',
                    // text: 'Jaringan bermasalah.',
                    text: 'Download Gagal Dikarenakan Jaringan',
                })

            });
    }

    previewFormatter = (cell, row) => {


        return <div className='divBtnTable'>
            <Tooltip title="View Rekap RT"><Button id={row.id} onClick={(e) => this.viewRekapRT(e, row)} className='btnTable' variant="outlined"><HiOutlineDocumentText style={{ color: 'green' }} /></Button></Tooltip>
            {/* <Tooltip title="View Profil"><Button id={row.id} className='btnTable' variant="outlined"><CgProfile style={{ color: 'blue' }} /></Button></Tooltip> */}
            <Tooltip title="Download SP2D"><Button id={row.id} onClick={(e) => this.downloadSP2D(e, row)} className='btnTable' variant="outlined"><ImDownload2 style={{ color: 'blue' }} /></Button></Tooltip>

        </div>
    }
    viewJabatan = (e, row) => {

        // this.setState({ blocking: true });
        this.setState({ blocking: true }, () => { this.openClosePreviewPenjabat() })
        const reqParam = {
            "userName": row.user_name
        }
        this.setState({ usernameBiodata: row.user_name })


        APIPemutakhiran.get('portalpk/metodepembayaran/getDetailProfilePembayaran?userName=' + row.user_name)
            .then((res) => {

                // 
                this.setState({ blocking: false });
                if (res.status === 200) {

                    this.setState({
                        datasViewJabatan: res.data.data,
                        roleName: res.data.data[0].roleName,

                    })
                }



            })
            .catch((error) => {
                let message = error;
                Swal.fire({
                    title: 'Error!',
                    icon: 'warning',
                    // text: 'Jaringan bermasalah.',
                    text: message,
                })
                this.setState({ blocking: false });

            })
    }

    handleCetakBiodata = (e, row) => {


        this.setState({ blocking: true });

        Swal.fire({
            title: 'Cetak Biodata',
            text: "Anda akan mencetak biodata",
            icon: 'info',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        }).then((result) => {

            let url = 'viewReport?filterPencarian=%7B' + '%22userName%22%3A%22' + this.state.usernameBiodata + '%22%2C%22reportName%22%3A%22' + 'ProfileSdmPembayaran2023' + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + 'PROFILE' + '%22%7D'

            if (result.isConfirmed) {

                APIReport.get(url, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Biodata ' + this.state.usernameBiodata + '.pdf', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        // 
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: 'Biodata gagal dicetak, silakan coba lagi',
                        })
                        this.setState({ blocking: false });
                    });

            } else {
                this.setState({ blocking: false });
            }
        })


    }

    viewRekapRT = (e, row) => {

        // this.setState({ blocking: true });
        this.setState({ blocking: false, dataRekap: [row] }, () => { this.openClosePreviewRekapRT(e, row) })
    }

    previewSK = (e, row) => {
        //let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKecamatan.id_kecamatan
        this.setState({ blocking: true })
        const link = document.createElement("a");
        link.target = "_blank";

        let Data = new FormData();
        Data.append('idWilayah', row.id_wilayah_file)
        Data.append('idFileTypeUpload', '2')
        Data.append('tingkatwilayah', row.tingkat_wilayah_file)
        //

        if (row.id_wilayah_file === 0 || row.id_wilayah_file === null) {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'File SK tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
            })
        } else {

            APIUpload.post('userpembiayaan/downloadFileUser', Data, {
                responseType: 'blob',
            })
                .then((res) => {

                    if (res.status === 200) {
                        this.setState({ blocking: false })
                    }


                    link.href = URL.createObjectURL(
                        new Blob([res.data], { type: "application/pdf" })
                    );

                    link.click();

                })
                .catch((error) => {
                    this.setState({ blocking: false });

                });
        }



    }

    numberingFormat2 = (cell, row) => {

        const index = +this.state.data_RT.indexOf(row)
        const noUrut = index + 1
        return <div>{noUrut} </div>
    }

    createdFormat = (cell, row) => {
        if (row.status === 3) {
            return 'c7ebcd'
        } else if (+row.status === 5 || +row.status === 6 || +row.status === 7) {
            return 'fc3021'
        } else {
            return ''
        }
    }

    createdFormatTunggakan = (cell, row) => {
        if (row.dataTunggakan === true) {
            return 'fffacd'
        } else {
            return ''
        }
    }



    StatusFormatter = (cell, row) => {
        if (+row.status === 1) {
            return 'Pending PPK'
        } else if (+row.status === 2) {
            return 'Pending BPP'
        } else if (+row.status === 3) {
            return 'Completed'
        } else if (+row.status === 5) {
            return 'Reject PK'
        } else if (+row.status === 6) {
            return 'Reject PPK'
        } else if (+row.status === 7) {
            return 'Reject BPP'
        } else {
            return ''
        }
    }

    rupiahFormatter = (cell, row) => {
        var bilangan = cell;
        //

        var number_string = bilangan?.toString(),
            sisa = number_string?.length % 3,
            rupiah = number_string?.substr(0, sisa),
            ribuan = number_string?.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
            var separator = ''
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan?.join('.');

            return rupiah
        } else {
            if (cell === 0) {
                return '0'
            } else {
                return '0'

            }
        }
        //     const numericValue = parseFloat(cell);

        //     // Check if the numericValue is a valid number
        //     if (isNaN(numericValue)) {
        //       return 0;
        //     }

        //     // Format the number as Rupiah with "sen"
        //     const rupiahOptions = {
        //       style: "currency",
        //       currency: "IDR",
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     };
        //   let coba = numericValue.toLocaleString("id-ID",rupiahOptions).toString().substring(3)
        //   
        //     return coba

    }

    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    handleChangeSizePerPage2 = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage2: x })
    }

    cellClick = (cell, row) => {

        return <div style={{ cursor: "pointer" }} onClick={(e) => this.viewDetail(e, row)}>
            {cell}
        </div>
    }

    viewDetail = (e, row) => {
        e.stopPropagation();



        this.setState({}, () => { this.openCloseDetailRT() })


    }


    handleOpenCloseInfo = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openInfo: !this.state.openInfo })
    }

    openCloseDetailRT = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openDetailRT: !this.state.openDetailRT },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )

    }

    //inputSP2D

    inputSP2D = (e) => {

        //this.setState({selectedRows: rowDipilih})

        this.setState({}, () => { this.openCloseInputSP2D() })
        //this.setState({}, ()=> this.onSelectRow())
    }

    openCloseInputSP2D = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openInputSP2D: !this.state.openInputSP2D },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )

    }



    //select row

    handleSelectRow = (cat, row, isSelect) => {
        let data = this.state.datas
        let dataAll = []
        if (data !== undefined) {
            if (isSelect === true && cat === 'single') {
                this.setState({ selectedRows: this.state.selectedRows.concat(row) });
            } else if (isSelect === true && cat === 'all') {
                if (this.state.textCari !== '') {
                    dataAll = this.state.datas.filter(el => el.id_user === row.id_user)

                    this.setState({ selectedRows: this.state.selectedRows.concat(row) });
                } else {
                    this.setState({ selectedRows: data });
                }
            } else if (cat === 'single') {
                var array = JSON.parse(JSON.stringify(this.state.selectedRows));
                var index = array.findIndex(el => el.id_user === row.id_user);
                if (index !== -1) {
                    array.splice(index, 1);
                    if (this.state.selectedRows?.length > 1) {
                        this.setState({ selectedRows: array });
                    } else {
                        this.setState({ selectedRows: array });
                    }
                }
            } else {
                this.setState({ selectedRows: [] });
            }
        }


    }

    handleSelectRowKedua = (cat, row, isSelect) => {
        let data = this.state.datas
        let dataAll = []
        if (data !== undefined) {
            if (isSelect === true && cat === 'single') {
                this.setState({ selectedRowsKedua: this.state.selectedRowsKedua.concat(row) });
            } else if (isSelect === true && cat === 'all') {
                if (this.state.textCari !== '') {
                    dataAll = this.state.datas.filter(el => el.namaLengkap === row.namaLengkap)

                    this.setState({ selectedRowsKedua: this.state.selectedRowsKedua.concat(row) });
                } else {
                    this.setState({ selectedRowsKedua: data });
                }
            } else if (cat === 'single') {
                var array = JSON.parse(JSON.stringify(this.state.selectedRowsKedua));
                var index = array.findIndex(el => el.namaLengkap === row.namaLengkap);
                if (index !== -1) {
                    array.splice(index, 1);
                    if (this.state.selectedRowsKedua?.length > 1) {
                        this.setState({ selectedRowsKedua: array });
                    } else {
                        this.setState({ selectedRowsKedua: array });
                    }
                }
            } else {
                this.setState({ selectedRowsKedua: [] });
            }
        }


    }
    onSearchChange = (searchText, colInfos, multiColumnSearch) => {
        this.setState({ textCari: searchText })

    }

    handleCetakCSV = () => {
        // Membuat listId dengan format yang sesuai
        const isBankValid = (bank) => {
            // Pisahkan bank berdasarkan tanda '-'
            const parts = bank.split(' - ');
            if (parts.length !== 2) {
                return false;
            }

            const [part1, part2] = parts;

            if (
                part1.length < 5 ||
                part2.length < 5 ||
                part1 === '-' ||
                part2 === '-' ||
                bank === '-'
            ) {
                return false;
            }

            return true;
        };

        let listId = this.state.selectedRows
            .filter(el => el.status === 2 && isBankValid(el.bank))
            .map(val => `userId=${val.id_user}`)
            .join('&');


        // Memastikan bahwa URL dibentuk dengan benar
        if (this.state.roleUserLogin === 26 || this.state.roleUserLogin === 27) {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'User Tdak Punya Akses!',
            });
            this.setState({ blocking: false });
            return;
        }

        if (!listId) {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data Harus Dilengkapi!'
            });
            this.setState({ blocking: false });
            return;
        }

        // Fungsi untuk memeriksa validitas bank



        // Gunakan fungsi validasi
        const url = `transaksi/downloadRekening?sumberPendanaan=APBN&${listId}`;
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Menggunakan padStart untuk format dua digit
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Bulan di JavaScript dimulai dari 0
        const year = today.getFullYear();

        // Membentuk nama file dengan format TanggalBulanTahun
        const fileName = `CSV-${day}${month}${year}.zip`;

        // Mengirimkan request ke API
        APIVerval.post(url, null, {
            responseType: 'blob' // Memastikan bahwa respons berupa blob untuk file
        })
            .then((res) => {
                // Membuat link unduhan dan mengkliknya secara programatik
                const blob = new Blob([res.data], { type: 'application/zip' });
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', fileName); // Menggunakan nama file dengan tanggal
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Membersihkan URL objek setelah digunakan
                window.URL.revokeObjectURL(blobUrl);

                this.setState({ blocking: false });
                this.handleSearch();
            })
            .catch(error => {
                console.error("Error during API call:", error);
                this.setState({ blocking: false });
            });


        // Mendapatkan tanggal hari ini

    }





    cetakBaru = (row) => {
        this.setState({ blocking: true });

        let dataRow = this.state.selectedRowsKedua;
        let id = [];

        const idCetak = []
        const Vcetak = []

        let rowsDipilih = this.state.selectedRows
        let SDMOne = this.state.selectedRows[0]?.sdm
        let sdmDipilih = []
        rowsDipilih.forEach((el) => sdmDipilih.push(el.sdm))
        rowsDipilih.forEach((el) => idCetak.push(el.id_user))
        rowsDipilih.forEach((el) => Vcetak.push({ id_user: el.id_user, versi_cetak: el.versi_cetak }))
        const hasZeroVersiCetak = Vcetak.some(el => el.versi_cetak === 0);

        let cekSDMDuplicatet = rowsDipilih.filter(el => el.sdm !== SDMOne)
        let metodeTunai = rowsDipilih.filter(el => el.metode === "Tunai")
        let metodeNTunai = rowsDipilih.filter(el => el.metode === "Non-Tunai")
        let metodeDraftTunai = rowsDipilih.filter(el => el.metode === null)

        let PK = dataRow.filter(el => /^PK[^.]*$/.test(el.userName));
        let PPK = dataRow.filter(el => /^PPK[^.]*$/.test(el.userName));
        let BPP = dataRow.filter(el => /^BPP[^.]*$/.test(el.userName));

        this.state.selectedRows.forEach(el => {
            if (el.versi_cetak === 0) {
                id.push(el.id_user);
            }
        });

        let idop = this.state.rowSDM !== null ? [this.state.rowSDM.id_user] : id;


        if ([PK, PPK, BPP].some(arr => arr.length !== 1)) {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Harap Melengkapi Jabatan dengan benar!',
            });
            this.setState({ blocking: false });
            return;
        }

        let dataParam = {
            sumberPendanaan: "APBN",
            userId: idop,
            namaLengkapPk: PK[0]?.namaLengkap || "",
            namaLengkapPpk: PPK[0]?.namaLengkap || "",
            namaLengkapBp: BPP[0]?.namaLengkap || ""
        };



        APIVerval.post('tt/updateNamaPejabat', dataParam)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ blocking: true });
                    if (this.state.rowSDM === null) {

                        if (cekSDMDuplicatet.length > 0) {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Hanya Boleh 1 Kategori SDM Saja, Mohon Di Cek Kembali',
                                showConfirmButton: true,
                            })
                            this.setState({ blocking: false });

                        } else {
                            this.setState({ blocking: false });

                            if (metodeTunai.length > 0 && metodeNTunai.length > 0 && metodeDraftTunai.length > 0) {
                                Swal.fire({
                                    title: 'Cetak Kuitansi',
                                    text: "Silahkan Cetak Sesuai Metode Yang Dibutuhkan Untuk Kuitansi & Lampiran",
                                    icon: 'info',
                                    showCancelButton: true,
                                    cancelButtonColor: '#d33',
                                    cancelButtonText: 'DRAFT',

                                    showConfirmButton: true,
                                    confirmButtonColor: '#3CB371',
                                    confirmButtonText: 'Tunai',

                                    showDenyButton: true,
                                    denyButtonColor: '#3085d6',
                                    denyButtonText: 'Non Tunai',

                                    showCloseButton: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.kuitansiTunai()
                                    } else if (result.isDenied) {
                                        this.kuitansiNonTunai()
                                    } else if (result.isCanceled) {
                                        this.kuitansiDRAFT()
                                    } else {
                                        this.setState({ blocking: false })
                                    }
                                })
                            } else if (metodeTunai.length > 0 && metodeNTunai.length > 0) {
                                Swal.fire({
                                    title: 'Cetak Kuitansi',
                                    text: "Silahkan Cetak Sesuai Metode Yang Dibutuhkan Untuk Kuitansi & Lampiran",
                                    icon: 'info',

                                    showConfirmButton: true,
                                    confirmButtonColor: '#3CB371',
                                    confirmButtonText: 'Tunai',

                                    showDenyButton: true,
                                    denyButtonColor: '#3085d6',
                                    denyButtonText: 'Non Tunai',

                                    showCloseButton: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.kuitansiTunai()
                                    } else if (result.isDenied) {
                                        this.kuitansiNonTunai()
                                    } else {
                                        this.setState({ blocking: false })
                                    }
                                })
                            } else if (metodeTunai.length > 0 && metodeDraftTunai.length > 0) {
                                Swal.fire({
                                    title: 'Cetak Kuitansi',
                                    text: "Silahkan Cetak Sesuai Metode Yang Dibutuhkan Untuk Kuitansi & Lampiran",
                                    icon: 'info',
                                    showDenyButton: true,
                                    denyButtonColor: '#d33',
                                    denyButtonText: 'DRAFT',

                                    showConfirmButton: true,
                                    confirmButtonColor: '#3CB371',
                                    confirmButtonText: 'Tunai',
                                    showCloseButton: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.kuitansiTunai()
                                    } else if (result.isDenied) {
                                        this.kuitansiDRAFT()
                                    } else {
                                        this.setState({ blocking: false })
                                    }
                                })
                            } else if (metodeNTunai.length > 0 && metodeDraftTunai.length > 0) {
                                Swal.fire({
                                    title: 'Cetak Kuitansi',
                                    text: "Silahkan Cetak Sesuai Metode Yang Dibutuhkan Untuk Kuitansi & Lampiran",
                                    icon: 'info',
                                    showconfirmButton: true,
                                    confirmButtonColor: '#d33',
                                    confirmButtonText: 'Non Tunai',

                                    showDenyButton: true,
                                    denyButtonColor: '#3085d6',
                                    denyButtonText: 'DRAFT',

                                    showCloseButton: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.kuitansiNonTunai()
                                    } else if (result.isDenied) {
                                        this.kuitansiDRAFT()
                                    } else {
                                        this.setState({ blocking: false })
                                    }
                                })
                            } else if (metodeTunai.length > 0 || metodeNTunai.length > 0 || metodeDraftTunai.length > 0) {
                                let textmetode

                                if (metodeTunai.length > 0) {
                                    textmetode = "Tunai"
                                } else if (metodeNTunai.length > 0) {
                                    textmetode = "Non Tunai"
                                } else {
                                    textmetode = " DRAFT"
                                }
                                Swal.fire({
                                    title: 'Cetak Kuitansi',
                                    text: "Anda akan mencetak kuitansi " + rowsDipilih.length + " dengan Metode " + textmetode,
                                    icon: 'info',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#3CB371',
                                    confirmButtonText: 'Ya',

                                    showDenyButton: true,
                                    denyButtonColor: '#3085d6',
                                    denyButtonText: 'Tidak',

                                    showCloseButton: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        if (metodeTunai.length > 0) {
                                            this.kuitansiTunai()
                                        } else if (metodeNTunai.length > 0) {
                                            this.kuitansiNonTunai()
                                        } else {
                                            this.kuitansiDRAFT()
                                        }
                                    }
                                })
                            }
                        }
                    } else {
                        Swal.fire({
                            title: 'Cetak Kuitansi',
                            //text: "Anda akan mencetak formulir",
                            icon: 'info',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Ya',
                            confirmButtonColor: '#3085d6',
                            showCancelButton: true,
                            cancelButtonText: 'Batal',
                        }).then((result) => {

                            let reportName
                            let reportKategori = 'VERVAL'
                            let sumberDM = this.state.rowSDM?.sdm
                            if (sumberDM === "Pengumpul Data Verval") {
                                reportName = "KuitansiVervalKaderPDK24"
                            } else if (sumberDM === "Pengolah Data Verval") {
                                reportName = "KuitansiVervalPDKC24"
                            } else {
                                reportName = "KuitansiVervalVDKC24"
                            }

                            let metoder
                            if (this.state.rowSDM?.metode === "Non-Tunai") {
                                metoder = 'TT'
                            } else if (this.state.rowSDM?.metode === "Tunai") {
                                metoder = 'TUNAI'
                            } else {
                                metoder = 'DRAFT'
                            }
                            let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + this.state.rowSDM?.id_user + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%2C%22metode%22%3A%22' + metoder + '%22%7D'

                            if (result.isConfirmed) {
                                APIReport.get(url, { responseType: 'blob' })
                                    .then(res => {
                                        if (res.status === 200) {
                                            const content = res.headers['content-type'];
                                            download(res.data, 'Kuitansi & Lampiran' + '.pdf', content)
                                            this.setState({ blocking: false, rowSDM: null })
                                        } else {
                                            Swal.fire({
                                                title: 'Info!',
                                                icon: 'info',
                                                text: 'Data tidak ditemukan.',
                                                showConfirmButton: false,
                                                timer: 1500
                                            })
                                            this.setState({ blocking: false });
                                        }
                                    }).catch((error) => {
                                        // 
                                        let message = error;
                                        Swal.fire({
                                            title: 'Error!',
                                            icon: 'warning',
                                            // text: 'Jaringan bermasalah.',
                                            text: 'Kuitansi gagal dicetak, silakan coba lagi',
                                        })
                                        this.setState({ blocking: false });
                                    });

                            } else {
                                this.setState({ blocking: false });
                            }
                        })
                    }

                    this.openClosePreviewPenjabat();
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        icon: 'warning',
                        text: 'Data Penjabat Teah Gagal Di Tambah!',
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error',
                    icon: 'warning',
                    text: `${error}, Harap Hubungi Administrator`,
                });
                this.setState({ selectedRows: [], blocking: false });
                this.handleSearch();
            });

    }





    handleCetakKuitansi = (e, row, cat) => {
        const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;

        if (row.versi_cetak === 0) {
            this.setState({ blocking: true, selectedRowsKedua: [] }, () => {
                APIVerval.get("/tt/getUserWilayah?provinsi=" + row.id_provinsi + "&sumberPendanaan=APBN")
                    .then((res) => {
                        this.setState({ datasPenjabat: res.data, blocking: false, rowSDM: row })
                        this.openClosePreviewPenjabat(row)

                    })
                    .catch((error) => {
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: 'Pencarian Data Gagal, Harap Coba lagi!',
                        })
                        this.setState({ blocking: false });
                    });
            });
        } else {



            Swal.fire({
                title: 'Cetak Kuitansi',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {

                let reportName
                let reportKategori = 'VERVAL'
                let sumberDM = row.sdm
                if (sumberDM === "Pengumpul Data Verval" || sumberDM === "PENGUMPUL DATA VERVAL") {
                    reportName = "KuitansiVervalKaderPDK24"
                } else if (sumberDM === "Pengolah Data Verval" || sumberDM === "PENGOLAH DATA VERVAL") {
                    reportName = "KuitansiVervalPDKC24"
                } else {
                    reportName = "KuitansiVervalVDKC24"
                }

                let metoder
                if (row.metode === "Non-Tunai") {
                    metoder = 'TT'
                } else if (row.metode === "Tunai") {
                    metoder = 'TUNAI'
                } else {
                    metoder = 'DRAFT'
                }
                let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + row.id_user + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%2C%22metode%22%3A%22' + metoder + '%22%7D'

                if (result.isConfirmed) {
                    APIReport.get(url, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Kuitansi & Lampiran' + '.pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                                this.handleSearch();

                            }
                        }).catch((error) => {
                            // 
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: 'Kuitansi gagal dicetak, silakan coba lagi',
                            })
                            this.setState({ blocking: false });

                        });

                } else {
                    this.setState({ blocking: false });
                }
            })
        }

    }

    fileFormatter = (cell, row) => {
        const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
        if (roleId === 3 || roleId === 6 || roleId === 19) {

            return <div className='divBtnTable'>
                <Tooltip title="Download View SPV"><Button id={row.id_rt} onClick={(e) => this.handleDownloadFileSPV(e, row)} className='btnTable' variant="outlined"><ImDownload2 style={{ color: 'blue' }} /></Button></Tooltip>

            </div>
        } else {
            return '-'
        }
        // }

    }

    handleCetak = (e) => {
        e.preventDefault()
        // if (this.handleValidation()) {
        this.setState({ blocking: true });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, selectedSDMCetak, selectedStatus, selectedMetode, selectedSDM } = this.state;

        // const addFilter = this.state.additionalFilter
        // let newFilter = []
        // addFilter.forEach(row => {
        //     if (row.filtValue && row.valkey) {
        //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
        //     }
        // })

        if (id_provinsi !== null && selectedSDM !== null) {

            Swal.fire({
                title: 'Cetak Formulir',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Excel',
                confirmButtonColor: '#3085d6',
                showDenyButton: true,
                denyButtonText: 'PDF',
                denyButtonColor: '#4ea842',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
            }).then((result) => {

                let url = 'viewReport?filterPencarian=%7B'

                if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
                if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
                if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
                if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
                if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }
                if (selectedSDM !== 0 && selectedSDM !== null) { url += '%22sdm%22%3A%22' + selectedSDM + '%22%2C' }
                if (selectedStatus !== 0 && selectedStatus !== null) { url += '%22status%22%3A%22' + selectedStatus + '%22%2C' }
                if (selectedMetode !== '' && selectedMetode !== null) { url += '%22metode%22%3A%22' + selectedMetode + '%22%2C' }
                url += '%22sumberDana%22%3A%22' + 'APBN' + '%22%2C'


                let urlCetakPdf = url + '%22reportName%22%3A%22MonitoringApprovalVerval2024%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MONITORING%22%7D'
                let urlCetakExcel = url + '%22reportName%22%3A%22MonitoringApprovalVerval2024%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MONITORING%22%7D'

                if (result.isConfirmed) {
                    APIReport.get(urlCetakExcel, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Laporan Monitoring Approval.' + 'xlsx', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // 
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });

                } else if (result.isDenied) {
                    APIReport.get(urlCetakPdf, { responseType: 'blob' })
                        .then(res => {
                            // 
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Laporan Monitoring Approval.' + 'pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // 
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });


                } else {
                    this.setState({ blocking: false });

                }
            })
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter wilayah minimal dipilih tingkat Provinsi, serta filter SDM',
                showConfirmButton: true,
            })
            this.setState({ blocking: false });

        }

    }

    handleCetakMultiple = (e) => {
        const urls = []
        const idCetak = []
        const Vcetak = []

        let rowsDipilih = this.state.selectedRows
        let SDMOne = this.state.selectedRows[0]?.sdm
        let sdmDipilih = []
        rowsDipilih.forEach((el) => sdmDipilih.push(el.sdm))
        rowsDipilih.forEach((el) => idCetak.push(el.id_user))
        rowsDipilih.forEach((el) => Vcetak.push({ id_user: el.id_user, versi_cetak: el.versi_cetak }))
        const hasZeroVersiCetak = Vcetak.some(el => el.versi_cetak === 0);

        let cekSDMDuplicatet = rowsDipilih.filter(el => el.sdm !== SDMOne)
        let metodeTunai = rowsDipilih.filter(el => el.metode === "Tunai")
        let metodeNTunai = rowsDipilih.filter(el => el.metode === "Non-Tunai")
        let metodeDraftTunai = rowsDipilih.filter(el => el.metode === null)
        if (hasZeroVersiCetak) {
            this.setState({ blocking: true, selectedRowsKedua: [] }, () => {
                APIVerval.get("/tt/getUserWilayah?provinsi=" + rowsDipilih[0].id_provinsi + "&sumberPendanaan=APBN")
                    .then((res) => {
                        this.setState({ datasPenjabat: res.data, blocking: false })
                        this.openClosePreviewPenjabat()

                    })
                    .catch((error) => {
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: 'Pencarian Data Gagal, Harap Coba lagi!',
                        })
                        this.setState({ blocking: false });
                    });
            });
        } else {

            if (cekSDMDuplicatet.length > 0) {
                Swal.fire({
                    title: 'Info!',
                    icon: 'info',
                    text: 'Hanya Boleh 1 Kategori SDM Saja, Mohon Di Cek Kembali',
                    showConfirmButton: true,
                })
            } else {
                if (metodeTunai.length > 0 && metodeNTunai.length > 0 && metodeDraftTunai.length > 0) {
                    Swal.fire({
                        title: 'Cetak Kuitansi',
                        text: "Silahkan Cetak Sesuai Metode Yang Dibutuhkan Untuk Kuitansi & Lampiran",
                        icon: 'info',
                        showCancelButton: true,
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'DRAFT',

                        showConfirmButton: true,
                        confirmButtonColor: '#3CB371',
                        confirmButtonText: 'Tunai',

                        showDenyButton: true,
                        denyButtonColor: '#3085d6',
                        denyButtonText: 'Non Tunai',

                        showCloseButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.kuitansiTunai()
                        } else if (result.isDenied) {
                            this.kuitansiNonTunai()
                        } else if (result.isCanceled) {
                            this.kuitansiDRAFT()
                        } else {
                            this.setState({ blocking: false })
                        }
                    })
                } else if (metodeTunai.length > 0 && metodeNTunai.length > 0) {
                    Swal.fire({
                        title: 'Cetak Kuitansi',
                        text: "Silahkan Cetak Sesuai Metode Yang Dibutuhkan Untuk Kuitansi & Lampiran",
                        icon: 'info',

                        showConfirmButton: true,
                        confirmButtonColor: '#3CB371',
                        confirmButtonText: 'Tunai',

                        showDenyButton: true,
                        denyButtonColor: '#3085d6',
                        denyButtonText: 'Non Tunai',

                        showCloseButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.kuitansiTunai()
                        } else if (result.isDenied) {
                            this.kuitansiNonTunai()
                        } else {
                            this.setState({ blocking: false })
                        }
                    })
                } else if (metodeTunai.length > 0 && metodeDraftTunai.length > 0) {
                    Swal.fire({
                        title: 'Cetak Kuitansi',
                        text: "Silahkan Cetak Sesuai Metode Yang Dibutuhkan Untuk Kuitansi & Lampiran",
                        icon: 'info',
                        showDenyButton: true,
                        denyButtonColor: '#d33',
                        denyButtonText: 'DRAFT',

                        showConfirmButton: true,
                        confirmButtonColor: '#3CB371',
                        confirmButtonText: 'Tunai',
                        showCloseButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.kuitansiTunai()
                        } else if (result.isDenied) {
                            this.kuitansiDRAFT()
                        } else {
                            this.setState({ blocking: false })
                        }
                    })
                } else if (metodeNTunai.length > 0 && metodeDraftTunai.length > 0) {
                    Swal.fire({
                        title: 'Cetak Kuitansi',
                        text: "Silahkan Cetak Sesuai Metode Yang Dibutuhkan Untuk Kuitansi & Lampiran",
                        icon: 'info',
                        showconfirmButton: true,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'Non Tunai',

                        showDenyButton: true,
                        denyButtonColor: '#3085d6',
                        denyButtonText: 'DRAFT',

                        showCloseButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.kuitansiNonTunai()
                        } else if (result.isDenied) {
                            this.kuitansiDRAFT()
                        } else {
                            this.setState({ blocking: false })
                        }
                    })
                } else if (metodeTunai.length > 0 || metodeNTunai.length > 0 || metodeDraftTunai.length > 0) {
                    let textmetode

                    if (metodeTunai.length > 0) {
                        textmetode = "Tunai"
                    } else if (metodeNTunai.length > 0) {
                        textmetode = "Non Tunai"
                    } else {
                        textmetode = " DRAFT"
                    }
                    Swal.fire({
                        title: 'Cetak Kuitansi',
                        text: "Anda akan mencetak kuitansi " + rowsDipilih.length + " dengan Metode " + textmetode,
                        icon: 'info',
                        showConfirmButton: true,
                        confirmButtonColor: '#3CB371',
                        confirmButtonText: 'Ya',

                        showDenyButton: true,
                        denyButtonColor: '#3085d6',
                        denyButtonText: 'Tidak',

                        showCloseButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (metodeTunai.length > 0) {
                                this.kuitansiTunai()
                            } else if (metodeNTunai.length > 0) {
                                this.kuitansiNonTunai()
                            } else {
                                this.kuitansiDRAFT()
                            }
                        }
                    })
                }
            }
        }
    }

    kuitansiTunai = () => {

        this.setState({ blocking: true })

        const isBankValid = (bank) => {
            const parts = bank.split(' - ');
            if (parts.length !== 2) {
                return false;
            }

            const [part1, part2] = parts;

            if (
                part1.length < 5 ||
                part2.length < 5 ||
                part1 === '-' ||
                part2 === '-' ||
                bank === '-'
            ) {
                return false;
            }

            return true;
        };


        let listId = ''
        let reportName
        let reportKategori = 'VERVAL'
        let metodeTunai = this.state.selectedRows.filter(el => el.metode === "Tunai" && isBankValid(el.bank));

        let metodeNTunai = this.state.selectedRows.filter(el => el.metode === "Non-Tunai")
        let metodeDraftTunai = this.state.selectedRows.filter(el => el.metode === null)
        let metodeBayar = "TUNAI"
        metodeTunai.forEach((val, key, arr) => {
            // listId = listId.concat(el.id)
            if (Object.is(arr.length - 1, key)) {
                listId = listId + val.id_user
            } else {
                listId = listId + val.id_user + ','
            }

        })





        let sumberDM = metodeTunai[0]?.sdm
        if (sumberDM === "Pengumpul Data Verval" || sumberDM === "PENGUMPUL DATA VERVAL") {
            reportName = "KuitansiVervalKaderPDK24"
        } else if (sumberDM === "Pengolah Data Verval" || sumberDM === "PENGOLAH DATA VERVAL") {
            reportName = "KuitansiVervalPDKC24"
        } else {
            reportName = "KuitansiVervalVDKC24"
        }
        let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + listId + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22metode%22%3A%22' + metodeBayar + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'

        APIReport.get(url, { responseType: 'blob' })
            .then(res => {
                if (res.status === 200) {
                    const content = res.headers['content-type'];
                    download(res.data, 'Kuitansi & Lampiran' + '.pdf', content)
                    this.setState({ blocking: false })
                    this.handleSearch();

                } else {
                    Swal.fire({
                        title: 'Info!',
                        icon: 'info',
                        text: 'Data tidak ditemukan.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.setState({ blocking: false });
                }
            }).catch((error) => {
                let message = error;
                Swal.fire({
                    title: 'Error!',
                    icon: 'warning',
                    text: 'Kuitansi gagal dicetak, silakan coba lagi',
                })
                this.setState({ blocking: false });
            })
        // )
        // })

        // Promise.all(promises).then(() => {

        // })


    }
    kuitansiNonTunai = () => {

        this.setState({ blocking: true })

        const isBankValid = (bank) => {
            const parts = bank.split(' - ');
            if (parts.length !== 2) {
                return false;
            }

            const [part1, part2] = parts;

            if (
                part1.length < 5 ||
                part2.length < 5 ||
                part1 === '-' ||
                part2 === '-' ||
                bank === '-'
            ) {
                return false;
            }

            return true;
        };
        let listId = ''
        let reportName
        let reportKategori = 'VERVAL'
        let metodeTunai = this.state.selectedRows.filter(el => el.metode === "Tunai")
        let metodeNTunai = this.state.selectedRows.filter(el => el.metode === "Non-Tunai" && isBankValid(el.bank))
        let metodeDraftTunai = this.state.selectedRows.filter(el => el.metode === null)
        let metodeBayar = "TT"
        metodeNTunai.forEach((val, key, arr) => {
            // listId = listId.concat(el.id)
            if (Object.is(arr.length - 1, key)) {
                listId = listId + val.id_user
            } else {
                listId = listId + val.id_user + ','
            }
        })

        let sumberDM = metodeNTunai[0]?.sdm
        if (sumberDM === "Pengumpul Data Verval" || sumberDM === "PENGUMPUL DATA VERVAL") {
            reportName = "KuitansiVervalKaderPDK24"
        } else if (sumberDM === "Pengolah Data Verval" || sumberDM === "PENGOLAH DATA VERVAL") {
            reportName = "KuitansiVervalPDKC24"
        } else {
            reportName = "KuitansiVervalVDKC24"
        }

        let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + listId + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22metode%22%3A%22' + metodeBayar + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'

        APIReport.get(url, { responseType: 'blob' })
            .then(res => {
                if (res.status === 200) {
                    const content = res.headers['content-type'];
                    download(res.data, 'Kuitansi & Lampiran' + '.pdf', content)
                    this.setState({ blocking: false })
                    this.handleSearch();

                } else {
                    Swal.fire({
                        title: 'Info!',
                        icon: 'info',
                        text: 'Data tidak ditemukan.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.setState({ blocking: false });
                    this.handleSearch();

                }
            }).catch((error) => {
                let message = error;
                Swal.fire({
                    title: 'Error!',
                    icon: 'warning',
                    text: 'Kuitansi gagal dicetak, silakan coba lagi',
                })
                this.setState({ blocking: false });
                this.handleSearch();

            })
        // )
        // })

        // Promise.all(promises).then(() => {

        // })


    }
    kuitansiDRAFT = () => {

        this.setState({ blocking: true })
        const isBankValid = (bank) => {
            const parts = bank.split(' - ');
            if (parts.length !== 2) {
                return false;
            }

            const [part1, part2] = parts;

            if (
                part1.length < 5 ||
                part2.length < 5 ||
                part1 === '-' ||
                part2 === '-' ||
                bank === '-'
            ) {
                return false;
            }

            return true;
        };
        let listId = ''
        let reportName
        let reportKategori = 'VERVAL'
        let metodeTunai = this.state.selectedRows.filter(el => el.metode === "Tunai")
        let metodeNTunai = this.state.selectedRows.filter(el => el.metode === "Non-Tunai")
        let metodeDraftTunai = this.state.selectedRows.filter(el => el.metode === null && isBankValid(el.bank))
        let metodeBayar = "DRAFT"
        metodeDraftTunai.forEach((val, key, arr) => {
            // listId = listId.concat(el.id)
            if (Object.is(arr.length - 1, key)) {
                listId = listId + val.id_user
            } else {
                listId = listId + val.id_user + ','
            }


        })
        let sumberDM = metodeDraftTunai[0]?.sdm
        if (sumberDM === "Pengumpul Data Verval" || sumberDM === "PENGUMPUL DATA VERVAL") {
            reportName = "KuitansiVervalKaderPDK24"
        } else if (sumberDM === "Pengolah Data Verval" || sumberDM === "PENGOLAH DATA VERVAL") {
            reportName = "KuitansiVervalPDKC24"
        } else {
            reportName = "KuitansiVervalVDKC24"
        }
        let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + listId + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22metode%22%3A%22' + metodeBayar + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'

        APIReport.get(url, { responseType: 'blob' })
            .then(res => {
                if (res.status === 200) {
                    const content = res.headers['content-type'];
                    download(res.data, 'Kuitansi & Lampiran' + '.pdf', content)
                    this.setState({ blocking: false })
                    this.handleSearch();

                } else {
                    Swal.fire({
                        title: 'Info!',
                        icon: 'info',
                        text: 'Data tidak ditemukan.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.setState({ blocking: false });

                }
            }).catch((error) => {
                let message = error;
                Swal.fire({
                    title: 'Error!',
                    icon: 'warning',
                    text: 'Kuitansi gagal dicetak, silakan coba lagi',
                })
                this.setState({ blocking: false });
            })
        // )
        // })

        // Promise.all(promises).then(() => {

        // })


    }
    openClosePreviewRekapRT = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openPreviewRekapRT: !this.state.openPreviewRekapRT },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )
    }

    openClosePreviewPenjabat = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openPreviewPenjabat: !this.state.openPreviewPenjabat },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )
    }

    emailFormatter = (cell, row) => {

        if (row.email === "0") {
            return <div> </div>
        } else {
            return <div> {row.email} </div>
        }

    }

    render() {

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10000px',
            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRow('single', row, isSelect)
            },
            onSelectAll: (isSelect, row, e) => {
                if (isSelect) {
                    this.handleSelectRow('all', row, isSelect)
                    return this.state.datas.map(row => row.id_user)
                } else {
                    this.handleSelectRow('all', row, isSelect)
                    return [];
                }
            }

        };
        const selectRowKedua = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10000px',
            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRowKedua('single', row, isSelect)
            },
            onSelectAll: (isSelect, row, e) => {
                if (isSelect) {
                    this.handleSelectRowKedua('all', row, isSelect)
                    return this.state.datas.map(row => row.namaLengkap)
                } else {
                    this.handleSelectRowKedua('all', row, isSelect)
                    return [];
                }
            }

        };

        const options3 = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage2, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };

        const options2 = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage2, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };

        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };

        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >
                <div className="content">
                    <div className="title-content">
                        <ImPlay3 className="icon-brd" />
                        Monitoring Approval APBN KRS
                    </div>
                    <div className="body-content">
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Provinsi
                                                        <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kabupaten
                                                        <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kecamatan
                                                        <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kelurahan
                                                        <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        SDM
                                                        <Select options={this.state.dt_sdm} onChange={this.sel_sdm} value={this.filterSDM} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Status
                                                        <Select isDisabled={this.state.disStatus} options={this.state.dt_status} onChange={this.sel_status} value={this.filterStatus} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>

                                                {/* <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Metode
                                                        <Select options={this.state.dt_metode} onChange={this.sel_metode} value={this.metodeTampil} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid> */}
                                                {/* <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Data Tunggakan
                                                        <Select options={this.state.dt_tunggakan} onChange={this.sel_Tunggakan} value={this.tunggakanTampil} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid> */}
                                                {/* <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Metode Pembayaran
                                                        <Select options={this.state.dt_pembayaran} onChange={this.sel_pembayaran} value={this.filterPembayaran} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid> */}
                                                <Grid item xs={12} md={4}>
                                                    <Grid item xs={12}>
                                                        <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                                                        <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <h3>Monitoring Approval KRS</h3>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                            <Grid item xs={12} md={12}>
                                <span className='btnTable'><Tooltip title="Keterangan" placement="left" arrow><Button style={{ float: 'right' }} onClick={this.handleOpenCloseInfo} ><BsInfoCircle size={20} /></Button></Tooltip></span>
                                <Button disabled={this.state.selectedRows.length === 0} onClick={(e) => this.handleCetakMultiple(e)} sx={{ width: 'calc(80%/4 - 5px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsPrinterFill className='icon-brd' style={{ marginRight: '5px' }} /> Cetak Kuitansi & Lampiran</Button>

                                <Button disabled={this.state.selectedRows.length === 0} onClick={(e) => this.handleCetakCSV(e)} sx={{ width: 'calc(50%/4 - 5px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'red', ':hover': { bgcolor: 'red', color: 'white' } }} variant="contained"><BsPrinterFill className='icon-brd' style={{ marginRight: '5px' }} /> Cetak CSV Supplier</Button>



                            </Grid>
                        </Grid>

                        <Paper sx={{ width: "100%", boxShadow: "none" }}>


                            <div className="rekap-table">
                                <BootstrapTable
                                    ref={this.bstable}
                                    data={this.state.datas}
                                    pagination={true}
                                    options={options}
                                    keyField="id_user"
                                    search={true}
                                    selectRow={selectRow}
                                >
                                    <TableHeaderColumn width="120" row="0" rowSpan="2" dataField="kode_kel" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.PKFormatter}  >KODE KELURAHAN</TableHeaderColumn>
                                    <TableHeaderColumn width="120" row="0" rowSpan="2" dataField="nama_kelurahan" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.PKFormatter}  >NAMA KELURAHAN</TableHeaderColumn>
                                    <TableHeaderColumn width="120" row="0" rowSpan="2" dataField="user_name" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.PKFormatter}  >USERNAME</TableHeaderColumn>
                                    <TableHeaderColumn width="120" row="0" rowSpan="2" dataField="nama_lengkap" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.PKFormatter}  >NAMA LENGKAP</TableHeaderColumn>
                                    <TableHeaderColumn width="80" row="0" rowSpan="2" dataField="jml_dibayar" headerAlign="center" dataAlign="right" dataFormat={this.rupiahFormatter}>JUMLAH DIBAYAR</TableHeaderColumn>
                                    <TableHeaderColumn width="80" row="0" rowSpan="2" dataField="jml_pajak" headerAlign="center" dataAlign="right" dataFormat={this.rupiahFormatter}>PAJAK</TableHeaderColumn>
                                    <TableHeaderColumn width="80" row="0" rowSpan="2" dataField="jml_diterima" headerAlign="center" dataAlign="right" dataFormat={this.rupiahFormatter} >JUMLAH DITERIMA</TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="120"
                                        row="0"
                                        colSpan="3"
                                        headerAlign="center"
                                        dataAlign="center"
                                    >
                                        Jumlah Sasaran KRS Verval
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="150"
                                        row="1"
                                        dataField="total_verval_ada"
                                        headerAlign="center"
                                        dataAlign="center"
                                    >
                                        Jumlah Keluarga Ada
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="150"
                                        row="1"
                                        dataField="total_verval_baru"
                                        headerAlign="center"
                                        dataAlign="center"
                                    >
                                        Jumlah Keluarga Baru
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="100"
                                        row="1"
                                        headerAlign="center"
                                        dataField="total_verval_adabaru"
                                        dataAlign="center"
                                    >
                                        Total
                                    </TableHeaderColumn>
                                    <TableHeaderColumn row="0" rowSpan="2" width="140" dataField="bank" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.PKFormatter}  >BANK / NO. REKENING</TableHeaderColumn>
                                    <TableHeaderColumn row="0" rowSpan="2" width="120" dataField="no_kuitansi" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.PKFormatter}  >NO KWITANSI</TableHeaderColumn>
                                    <TableHeaderColumn row="0" rowSpan="2" width="120" dataField="no_sp2d" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.PKFormatter}  >NO. SP2D</TableHeaderColumn>
                                    <TableHeaderColumn row="0" rowSpan="2" width="120" dataField="jumlahkk_tdk_resiko" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.StatusFormatter}  >STATUS</TableHeaderColumn>
                                    <TableHeaderColumn row="0" rowSpan="2" width="120" dataField="jumlahkk_tdk_resiko" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.previewFormatter}  >PREVIEW</TableHeaderColumn>
                                    <TableHeaderColumn row="0" rowSpan="2" width="110" dataField="action" headerAlign="center" dataAlign="center" dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn>

                                </BootstrapTable>
                            </div>
                            <div className="sizePerPage">
                                Size Per Page
                                <select
                                    name="category"
                                    value={this.state.sizePerPage}
                                    onChange={this.handleChangeSizePerPage}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value={this.state.datas?.length}>All</option>
                                </select>
                            </div>
                            <div className="totalData">
                                <b>Total Data: {this.state.datas?.length}</b>
                            </div>
                        </Paper>

                        <Dialog open={this.state.openPreviewRekapRT} onClose={this.openClosePreviewRekapRT}
                            fullWidth maxWidth={'xl'}>
                            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >

                                <DialogTitle>Detail Rekap VERVAL </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <Paper sx={{ width: "100%", boxShadow: "none" }}>

                                            <div className="rekap-table">
                                                <BootstrapTable
                                                    data={this.state.dataRekap}
                                                    pagination={true}
                                                    options={options2}
                                                    keyField='kodeRT'

                                                >
                                                    {/* <TableHeaderColumn width="90" isKey={true} dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA RT </TableHeaderColumn> */}

                                                    <TableHeaderColumn width="80" row='0' colSpan='12' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Sasaran KRS (Baseline)</TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PUS </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PUS Hamil/Ibu Hamil </TableHeaderColumn>
                                                    <TableHeaderColumn width="180" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Keluarga punya anak 0-23 bulan </TableHeaderColumn>
                                                    <TableHeaderColumn width="180" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Keluarga punya anak 24-59 bulan </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TOTAL </TableHeaderColumn>

                                                    <TableHeaderColumn width="80" row='2' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PRIORITAS SASARAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PRIORITAS TERVERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SELAIN ADA/BARU TERVERVAL </TableHeaderColumn>

                                                    <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >ADA </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >BARU </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >JUMLAH </TableHeaderColumn>

                                                    <TableHeaderColumn width="80" row='0' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Sasaran KRS Verval</TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Keluarga Ada </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Keluarga Baru </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Total </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_ada" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >5 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_baru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >6 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_adabaru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >7 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pushamil_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >8 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pushamil_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >9 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="baduta_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >10 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="baduta_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >11 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="balita_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >12 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="balita_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >13 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_prioritas" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >14 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_prioritas_terverval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >15 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_notin_adabaru_terverval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >15 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_ada" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >17 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_baru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >18 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_adabaru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >19=17+18 </TableHeaderColumn>
                                                </BootstrapTable>


                                            </div>

                                        </Paper>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.openClosePreviewRekapRT}>Tutup</Button>
                                </DialogActions>
                            </BlockUi>
                        </Dialog>


                        <Dialog open={this.state.openInfo} onClose={this.handleOpenCloseInfo} width={'xs'}>
                            <DialogTitle style={{ paddingLeft: '25px' }}><BsInfoCircle size={15} />&nbsp; Info</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 1 }} style={{ marginTop: '10px', marginBottom: '15px', paddingLeft: '15px' }}>
                                        Cetak kuitansi berangkap hanya tersedia jika SDM dan metode yang dipilih sama.


                                    </Grid>

                                </DialogContentText>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleOpenCloseInfo} variant="outlined" color="primary">Tutup</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openPreviewPenjabat} onClose={this.openClosePreviewPenjabat}
                            fullWidth maxWidth={'sm'}>
                            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >

                                <DialogTitle>Detail Penjabat VERVAL </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <Paper sx={{ width: "100%", boxShadow: "none" }}>

                                            <div className="rekap-table">
                                                <BootstrapTable
                                                    data={this.state.datasPenjabat}
                                                    pagination={true}
                                                    options={options3}
                                                    keyField='namaLengkap'
                                                    selectRow={selectRowKedua}

                                                >
                                                    {/* <TableHeaderColumn width="90" isKey={true} dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA RT </TableHeaderColumn> */}

                                                    <TableHeaderColumn width="120" dataField="namaLengkap" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Nama Penjabat</TableHeaderColumn>
                                                    <TableHeaderColumn width="120" dataField="userName" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Username Penjabat</TableHeaderColumn>
                                                    <TableHeaderColumn width="120" dataField="namaWilayah" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Penjabat Wilayah </TableHeaderColumn>

                                                </BootstrapTable>


                                            </div>

                                        </Paper>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.openClosePreviewPenjabat}>Tutup</Button>
                                    <Button onClick={this.cetakBaru}>Cetak</Button>

                                </DialogActions>
                            </BlockUi>
                        </Dialog>


                    </div>
                </div>
            </BlockUi>
        );
    }
}

export default MonitoringApproval;
