import React, { Component } from 'react';
import { Tabs, Tab, Box, Grid, Link, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Dialog } from '@mui/material';
import { ImPlay3, ImBin } from 'react-icons/im';
import { AiOutlineUpload } from 'react-icons/ai';
import { FileUploader } from "react-drag-drop-files";
import Dropzone from 'react-dropzone';
import APIUpload from '../../../services/APIUpload';
import APIVerval from '../../../services/APIVerval';
import BlockUi from 'react-block-ui';
import Swal from 'sweetalert2';

const fileTypes = ["PDF", "IMG"];
class SuratKeputusan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: '',
            fileInfos: [],
            blocking: false,
            previewModal: false,
            urlPdf: '',
            datas: [],
            namaFile: ''
        }
    }

    componentDidMount() {
        this.handleCari()

    }

    handleCari = () => {
        this.setState({ blocking: true });
        let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten
        
        let Data = new FormData();
        Data.append('idWilayah', wilayah)
        Data.append('tingkatWilayah', '2')
        
        // this.setLabel()
        const dataUpload = {

            "idWilayah": wilayah,
            "tingkatWilayah": 2,
            "jenis":"VERVAL",
            "periodeSensus": 2024,

          }

          APIUpload.post('userpembiayaan/getListFileByIdWilayah', dataUpload)
            .then((res) => {

                if(res.status === 200){
                    this.setState({ datas: res.data, namaFile: res.data === "" ? '' : res.data.file_name   , blocking: false   })

                }else{
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        // text: 'Jaringan bermasalah.',
                        text: "Pencarian Surat Keputasan Gagal",
                      })
                      this.setState({ blocking: false });
                }
                

                
            })
            .catch((error) => {
                let message = error;
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  // text: 'Jaringan bermasalah.',
                  text: "Pencarian Surat Keputasan Gagal",
                })
                this.setState({ blocking: false });
                
            });
    }
    preview = (row, action) => {
        let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten
        const link = document.createElement("a");
        link.target = "_blank";

        let Data = new FormData();
        Data.append('idWilayah', wilayah)
        Data.append('idFileTypeUpload', '2')
        Data.append('tingkatwilayah', '2')
        Data.append('jenis', 'VERVAL')
        Data.append('periodeSensus', 2024)

        

        APIUpload.post('userpembiayaan/downloadFileUser', Data, {
            responseType: 'blob',
        })
            .then((res) => {
                

                link.href = URL.createObjectURL(
                    new Blob([res.data], { type: "application/pdf" })
                );
                this.setState({
                    // previewModal: !this.state.previewModal,
                    // urlPdf: link.href
                })

                link.click();

            })
            .catch((error) => {
                let message = error;
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  // text: 'Jaringan bermasalah.',
                  text: message,
                })
                
            });
    }

    upload = () => {
        this.setState({blocking: true})

        let currentFile = this.state.selectedFiles[0];
        let wilayahRole = JSON.parse(localStorage.getItem('authority'))[0].attributes.role
        let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten
        let Data = new FormData();
        Data.append("idFileTypeUpload", '2');
        Data.append('createdby', localStorage.getItem('username'))
        // Data.append("idWilayah", this.state.id_rt);
        Data.append("idWilayah", wilayah);
        Data.append("tingkatwilayah", '2');
        Data.append("jenis", 'VERVAL');
        Data.append("doc", currentFile);
        if(wilayahRole === "Admin Kabupaten"){  

        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin Upload file?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                APIUpload.post('userpembiayaan/uploadFileUser', Data)
                    .then((response) => {
                        
                        if(response.status === 200){
                            Swal.fire({
                                title: 'Info!',
                                icon: 'success',
                                text: 'File berhasil diupload',
                            })
                            this.setState({blocking: false } , () => {
                                this.handleCari()
                            })
                          }


                    })
                    .catch((error) => {
                        // Handle error.
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: 'Gagal upload file'
                        })
                        this.setState({ blocking: false });
                        
                    });
            }else{
                this.setState({ blocking: false });
            }

        })
    }else{
        Swal.fire({
            title: 'warning!',
            icon: 'warning',
            text: 'Upload Hanya Untuk Admin kabupaten',
        })
        this.setState({ blocking: false });
    }


    }

    onDrop = (files) => {
        
        if (files[0].size >= 41943040) {
            Swal.fire({
                title: 'warning!',
                icon: 'warning',
                text: 'File Melebihi 40MB',
            })

        } else if(files[0].type !== "application/pdf"){
            Swal.fire({
                title: 'warning!',
                icon: 'warning',
                text: 'Format File Diharuskan PDF!',
            })
        }else{
            if (files.length > 0) {
                this.setState({ selectedFiles: files })
            }
        }


    }

    render() {
        const { selectedFiles, currentFile, progress, message, fileInfos, namaFile } = this.state
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                <div className='content'>
                    <div className='title-content'><ImPlay3 className='icon-brd' />Upload Surat Keputusan VERVAL</div>
                    <div className='body-content'>
                        {namaFile !== '' ?
                        <h3>Surat Keputusan VERVAL Sudah Di Upload!</h3> : <h3>Surat Keputusan VERVAL Belum Di Upload!</h3>  }
                        
                        {/* <Box sx={{ width: '100%' }}>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Grid item xs={12} md={6} style={{ marginTop: '20px' }}>
                                <FileUploader
                                    handleChange={this.handleChange}
                                    name="image"
                                    types={fileTypes}
                                    style={{ width: '100px' }}

                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ marginTop: '20px' }}>
                                <Button  sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><AiOutlineUpload className='icon-brd' style={{ marginRight: '5px' }} />Simpan File</Button>
                                <Button  sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px', backgroundColor: 'red', ':hover': { bgcolor: 'red', color: 'white' }}} variant="contained"><ImBin className='icon-brd' style={{ marginRight: '5px' }} />Hapus</Button>

                            </Grid>
                        </Grid>
                    </Box> */}
                        <div>
                            {currentFile && (
                                <div className='progress mb-3'>
                                    <div
                                        className='progress-bar progress-bar-info progress-bar-striped'
                                        role='progressbar'
                                        aria-valuenow={progress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: progress + '%' }}
                                    >
                                        {progress}%
                                    </div>
                                </div>
                            )}

                                <Dropzone onDrop={this.onDrop} multiple={false}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            {selectedFiles && selectedFiles[0].name || namaFile ? (
                                                <div className="selected-file">
                                                    {selectedFiles && selectedFiles[0].name || namaFile}
                                                </div>
                                            ) : (
                                                'Drag and drop file here, or click to select file'
                                            )}
                                        </div>
                                        <aside className="selected-file-wrapper">

                                            <Button
                                                className="btn btn-success"
                                                disabled={!selectedFiles}
                                                onClick={this.upload}
                                                sx={{ width: '20%', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><AiOutlineUpload className='icon-brd' style={{ marginRight: '5px' }} />
                                                Upload File VERVAL
                                            </Button>
                                            <Button
                                                className="btn btn-success"
                                                // disabled={!selectedFiles}
                                                onClick={this.preview}
                                                sx={{ width: '20%', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'blue', ':hover': { bgcolor: 'blue', color: 'white' } }} variant="contained"><AiOutlineUpload className='icon-brd' style={{ marginRight: '5px' }} />
                                                Preview File
                                            </Button>
                                        </aside>
                                    </section>
                                )}
                            </Dropzone>

                            <div className='alert alert-light' role='alert'>
                                {message}
                            </div>

                            {fileInfos.length > 0 && (
                                <div className='card'>
                                    <div className='card-header'>List of Files</div>
                                    <ul className='list-group list-group-flush'>
                                        {fileInfos.map((file, index) => (
                                            <li className="list-group-item" key={index}>
                                                <a href={file.url}>{file.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </BlockUi>
        )
    }
}

export default SuratKeputusan;