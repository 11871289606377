import { Box, Button, FormControl, Grid, TextField } from '@mui/material';
import download from 'downloadjs';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { ImPlay3, ImPrinter } from 'react-icons/im';
import Select from 'react-select';
import Swal from 'sweetalert2';
import TableTarget from '../../../commons/table/TableTarget';
import APIReport from '../../../services/APIReport';
import APIWilayah from '../../../services/APIWilayah';

const isNumber = (str) => /^[0-9]*$/.test(str);

class Target extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultData: [],
      roleId: 0,
      detailTarget: {
        'belumTerdata': 0,
        'idKecamatan': 0,
        'jumlahData': 0,
        'targetKK': 0,
        'terdata': 0,
        'maksTargetKK': 0
      },
      persen: 0,
      disKelAction: true,
      statusLockKel: false,
      disBtn: false,
      disMax: false,

      //Wilayah
      dt_prov: [], provinsi: null, id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: null, id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: null, id_rw: 0, dis_rw: true,
      dt_rt: [], rt: null, rtID: '', id_rt: 0, dis_rt: true,

      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }, { value: '2022', label: '2022' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,

      targetkkmaks: 0

    }
  }

  componentDidMount = () => {
    const rest = JSON.parse(localStorage.getItem("authority"));
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const nm = rest[0].attributes.user.TingkatWilayahID
      const ri = +rest[0].attributes.user.roleID;
      // 
      let x = false
      if (+ri === 3 || +ri === 4 || +ri === 9 || +ri === 2) {
        x = true
      }
      this.setState({ roleId: ri, disMax: x },
        () => {
          if (+nm === 0) {
            this.setState({ flagSearch: false },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }

            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        }
      )

    } else {
      this.setProv(null);
    }
  }

  componentDidUpdate = () => {


  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // 
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'nama': data.nama_kecamatan };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // 
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/targetkk/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'target': data.targetKK };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false, defaultData: res.data },
          () => {
            APIWilayah.get('pkportal/targetkk/detilTargetKecamatan?idKecamatan=' + idKec + '&periodeSensus=' + this.state.value_priode)
              .then(res => {
                if (res.status === 200 && Object.keys(res.data).length > 0) {
                  this.setState({ blocking: false, detailTarget: res.data },
                    () => {
                      let x = 0
                      if (this.state.detailTarget.terdata !== 0) {
                        (Number.isInteger(100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData))) ?
                          x = 100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData) :
                          x = (100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData)).toFixed(2)
                      }
                      this.setState({ persen: x })
                    }
                  );
                } else {
                  this.setState({ blocking: false });
                }
              }).catch((error) => {
                this.setState({ blocking: false });
              });
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024' + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // 
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        defaultData: []
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        defaultData: []
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  getStatusLock = () => {
    APIWilayah.get('/pkportal/targetkk/detilTargetKecamatan?idKecamatan=' + this.state.id_kecamatan + '&periodeSensus=' + this.state.value_priode)
      .then(res => {

        this.setState({ statusLockKel: res.data.statusLock, detailTarget: res.data },
          () => {
            let x = 0
            if (this.state.detailTarget.terdata !== 0) {
              (Number.isInteger(100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData))) ?
                x = 100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData) :
                x = (100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData)).toFixed(2)
            }
            this.setState({ persen: x, disBtn: this.state.statusLockKel === true ? true : false })
          })

        //

      }).catch((error) => {
        this.setState({ blocking: false })

      })

  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            }, () => this.getStatusLock())

            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }

        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        defaultData: []

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }


  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      var newData = [];
      this.setState({ defaultData: [] })
      if (this.state.id_kecamatan !== 0) {
        APIWilayah.get('pkportal/targetkk/getListKelurahanByIdKecamatan?id_kecamatan=' + this.state.id_kecamatan + '&periodeSensus=' + e.value)
          .then(res => {
            this.setState({
              priode: e, value_priode: e.value, dt_des: newData, blocking: false, defaultData: res.data
            }, () =>
              this.getStatusLock()
            )
          })
      } else {
        Swal.fire({
          title: 'Info',
          icon: 'info',
          text: 'Filter wilayah minimal dipilih tingkat Kecamatan'
        });
      }


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }


  handleFocus = (event) => event.target.select();

  runUpdateData = () => {
    if (this.state.targetkkmaks === 1) {
      this.setState({ blocking: true, targetkkmaks: 0 })
      APIWilayah.post('pkportal/targetkk/upsertTargetKkKecamatan?idKecamatan=' + this.state.id_kecamatan + '&periodeSensus='+ this.state.value_priode + '&targetKK=' + this.state.detailTarget.maksTargetKK + '&username=' + localStorage.getItem('username'))
        .then(res => {
          if (res.status === 200 && res.data.AddMaksTargetKK === 'Gagal, input harus lebih besar dari jumlah TargetKK') {
            this.setState({ blocking: false });
            Swal.fire({
              title: 'Gagal',
              icon: 'error',
              text: 'Gagal, input harus lebih besar dari jumlah TargetKK',
            });
          } else if (res.status === 200 && res.data.AddMaksTargetKK !== "Gagal, input harus lebih besar dari jumlah TargetKK") {
            this.setState({ blocking: false },
              () => {
                Swal.fire({
                  title: 'Success',
                  icon: 'success',
                  text: 'Data berhasil disimpan',
                });
              }
            );
          } else {
            this.setState({ blocking: false });
          }
        }).catch((error) => {
          this.setState({ blocking: false });
        });
    }
  }

  handleChangeInput = (e) => {
    const val = Number(e.target.value)
    if (isNumber(val)) {
      this.setState({ detailTarget: { ...this.state.detailTarget, maksTargetKK: val }, targetkkmaks: 1 })
    }
  }

  keyPress = (e) => {
    if (e.keyCode == 13) {
      if (isNumber(e.target.value)) {
        const val = Number(e.target.value)
        this.setState({ detailTarget: { ...this.state.detailTarget, maksTargetKK: val } },
          () => {
            e.target.blur();
          }
        )
      }
    }
  }

  updateHeader = () => {
    this.setState({ blocking: true })
    APIWilayah.get('pkportal/targetkk/detilTargetKecamatan?idKecamatan=' + this.state.kecamatan.value + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        if (res.status === 200 && Object.keys(res.data).length > 0) {
          this.setState({ blocking: false, detailTarget: res.data },
            () => {
              let x = 0
              if (this.state.detailTarget.terdata !== 0) {
                (Number.isInteger(100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData))) ?
                  x = 100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData) :
                  x = (100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData)).toFixed(2)
              }
              this.setState({ persen: x })
            }
          );
        } else {
          this.setState({ blocking: false });
        }
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  handleCetak = (e) => {
    e.preventDefault()
    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, value_priode } = this.state;

    // const addFilter = this.state.additionalFilter
    // let newFilter = []
    // addFilter.forEach(row => {
    //     if (row.filtValue && row.valkey) {
    //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
    //     }
    // })
    // 
    if (id_kecamatan !== 0 && id_kecamatan !== undefined) {
      Swal.fire({
        title: 'Cetak Formulir',
        //text: "Anda akan mencetak formulir",
        icon: 'info',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Excel',
        confirmButtonColor: '#4ea842',
        showDenyButton: true,
        denyButtonText: 'PDF',
        denyButtonColor: '#FF8000',
        showCancelButton: true,
        cancelButtonText: 'Batal',
      }).then((result) => {

        let url = 'viewReport?filterPencarian=%7B'

        // if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
        // if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
        if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
        if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
        if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }
        if (value_priode !== 0 && value_priode !== null) { url += '%22periodeSensus%22%3A%22' + value_priode + '%22%2C' }


        let urlCetakPdf = url + '%22reportName%22%3A%22targetKK%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22TargetKk%22%7D'
        let urlCetakExcel = url + '%22reportName%22%3A%22targetKK%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22TargetKk%22%7D'

        if (result.isConfirmed) {
          APIReport.get(urlCetakExcel, { responseType: 'blob' })
            .then(res => {
              if (res.status === 200) {
                const content = res.headers['content-type'];
                download(res.data, 'Laporan Target KK Periode-' + value_priode + '.' + 'xlsx', content)
                this.setState({ blocking: false })
              } else {
                Swal.fire({
                  title: 'Info!',
                  icon: 'info',
                  text: 'Data tidak ditemukan.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.setState({ blocking: false });
              }
            }).catch((error) => {
              // 
              let message = error;
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                // text: 'Jaringan bermasalah.',
                text: message,
              })
              this.setState({ blocking: false });
            });

        } else if (result.isDenied) {
          APIReport.get(urlCetakPdf, { responseType: 'blob' })
            .then(res => {
              // 
              if (res.status === 200) {
                const content = res.headers['content-type'];
                download(res.data, 'Laporan Target KK Periode-' + value_priode + '.' + 'pdf', content)
                this.setState({ blocking: false })
              } else {
                Swal.fire({
                  title: 'Info!',
                  icon: 'info',
                  text: 'Data tidak ditemukan.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.setState({ blocking: false });
              }
            }).catch((error) => {
              // 
              let message = error;
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                // text: 'Jaringan bermasalah.',
                text: message,
              })
              this.setState({ blocking: false });
            });


        } else {
          this.setState({ blocking: false });
        }
      })
    } else {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Filter wilayah minimal dipilih tingkat Kecamatan',
        showConfirmButton: true,
      })
      this.setState({ blocking: false });

    }
  }

  render() {
    return (
      <div className='content'>
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
          <div className='title-content'><ImPlay3 className='icon-brd' />Penetapan Target KK Wilayah</div>
          <div className='body-content'>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
              <Grid item xs={12} md={12}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }} size="small" >
                      Provinsi
                      <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }} size="small">
                      Kabupaten
                      <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }} size="small">
                      Kecamatan
                      <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }} size="small">
                      Periode
                      <Select options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button onClick={this.handleCetak} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', backgroundColor: 'green', marginRight: '5px', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {this.state.id_kecamatan === 0 ? <></> : <>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} sx={{ mt: 3 }}>

                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%', textAlign: 'center' }} size="small" >
                    <Box sx={{ lineHeight: 2 }}>Maks. total target Kecamatan</Box>
                    {/* {} */}
                    {/* {} */}
                    <TextField
                      disabled={this.state.disMax || this.state.id_kecamatan === 0 || this.state.disBtn}
                      inputProps={{ min: 0, style: { textAlign: 'center' } }}
                      id="filled-required"
                      label=""
                      variant="standard"
                      onChange={this.handleChangeInput}
                      onBlur={this.runUpdateData}
                      onFocus={this.handleFocus}
                      onKeyDown={this.keyPress}
                      value={this.state.detailTarget.maksTargetKK}
                      name="maksTargetKK"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%', textAlign: 'center' }} size="small" >
                    <Box sx={{ lineHeight: 2 }}>Jumlah target kk</Box>
                    <TextField
                      disabled
                      inputProps={{ min: 0, style: { textAlign: 'center' } }}
                      id="filled-required"
                      label=""
                      variant="standard"
                      // onChange={this.handleChangeInput}
                      value={this.state.detailTarget.targetKK?.toLocaleString()}
                      name="targetKK"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={2}>
                  <FormControl sx={{ width: '100%', textAlign: 'center' }} size="small" >
                    <div style={{ fontSize: '24px', fontWeight: '600' }}>
                      {this.state.persen}%
                    </div>
                    <div>({this.state.detailTarget.terdata}/{this.state.detailTarget.jumlahData} RT)</div>
                  </FormControl>
                </Grid>
              </Grid>
            </>}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Grid item xs={12} md={12}>
                <Box sx={{ width: '100%' }}>
                  {/* {} */}
                  <TableTarget
                    defaultData={this.state.defaultData}
                    roleId={this.state.roleId}
                    value_priode={this.state.value_priode}
                    kecamatan={this.state.kecamatan}
                    updateHeader={this.updateHeader}
                    detailTarget={this.state.detailTarget}
                    disKelAction={this.state.disKelAction}
                    statusLockKel={this.state.statusLockKel}
                    disBtn={this.state.disBtn}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </BlockUi>
      </div>
    )
  }
}

export default Target;