import React, { Component } from 'react'
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, Checkbox, FormControlLabel } from '@mui/material';

import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIPemutakhiranMonitoring from "../../../services/APIPemutakhiranMonitoring";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock, AiOutlineVerticalAlignBottom, AiFillFilePdf, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ImSearch, ImBin, ImCross, ImFloppyDisk, ImLoop2, ImMap, ImMap2, ImPlay3, ImPlus } from 'react-icons/im';
import { BsFillArrowDownCircleFill, BsCheck2Circle, BsCheckLg } from "react-icons/bs";
// import { RxCheckCircled } from "react-icons/rx";
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill, BsInfoCircle } from "react-icons/bs";

import APIDashboard from "../../../services/APIDashboard";
import APIReport from '../../../services/APIReport';
import CommonTable from '../../../commons/table/Table';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { FileUploader } from "react-drag-drop-files";
import APIPemutakhiran from '../../../services/APIPemutakhiran';
import APIUpload from '../../../services/APIUpload';
import APIButton from '../../../services/APIButtonAkses';


import APIMonitoring from '../../../services/APIMonitoringBackend';

const fileTypes = ["PDF", "JPG", "PNG"];
const isNumber = (str) => /^[0-9]*$/.test(str);
class pendataanSupervisor extends Component {
  constructor (props) {
    super(props);
    this.state = {
      panel1: true,
      sizePerPage: 10,
      title: '',

      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      openDialog: false,
      ada: '',
      Tditemui: '',
      menolak: '',
      tMampu: '',
      jumlah: '',
      setFile: null,
      id_rt: 0,
      kode_rt: 0,
      nama_rt: 0,
      pendata: null,
      jumlahkelsar: 0,
      jumlahresstan: 0,
      resikostunting: 0,
      lapangan1: 0,
      lapangan2: 0,
      status: 0,
      id: 0,
      userId: 0,
      didataTambah: 0,
      id_provinsirow: 0,
      id_kabupatenrow: 0,
      id_kecamatanrow: 0,
      id_rwrow: 0,
      id_kelurahanrow: 0,
      datas: [],
      no: "",
      openInfo: false,
      wilayah: [],
      namaKelurahan: [],
      metodePendataan: [],
      smartphone: 0,
      role: JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
      sdm: [
        { value: '4', label: 'Supervisor' },
        { value: '10', label: 'Operator' },
      ],
      filterSDM: [], selectedSDM: null,
      sdmValue: '',
      disAprrovaladmin: false,

      isChecked1: false,
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,

      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,
      selectedRows: [], rowDipilih: [],

      onSubmit: false,
      disAprrovalProv: false,
      duplicate: 0,
      susulan: 0,
      roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,

    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  handleOpenClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openDialog: !this.state.openDialog },
      () => { if (!this.state.openDialog) { this.clearForm() } }
    )
  }

  clearForm = () => {
    this.setState({})
  }

  setHeaderTable = () => {

  }

  handleOpenCloseInfo = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openInfo: !this.state.openInfo })
  }

  componentDidMount() {
    // this.setHeaderTable()
    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    const idTingkatWilayah2 = rest[0].attributes.user.alamat_provinsi === 0 ? rest[0].attributes.user.wilProvinsi.id_provinsi : rest[0].attributes.user.alamat_provinsi;
    let created = new Date().toISOString().substring(0, 10)
    let temp = new Date("2023-06-25").toISOString().substring(0, 10)
    let title2, text11, text21

    if (idTingkatWilayah == 0) {
      title2 = ''

      this.setState({
        sendDatas: {
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      },
        () => { this.getBackend(this.state.sendDatas) }
      )


    } else {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan?.nama_kecamatan

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });
    }

    this.setState({ title: title2, text1: text11, text2: text21 },
      () => { this.setHeaderTable() }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
              }
            }
          );


          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // console.log(vl, 'vale kab')
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // console.log(vl, 'vale kec')
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              // console.log(vl, 'vale des')
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // console.log('masuk false')
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di prov')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }

          if (this.state.id_provinsi) {
            this.setState({ blocking: true })
            APIButton.get("/aktif/getTombolAktifByProvId?provinsiId=" + this.state.id_provinsi)
              .then((res) => {
                console.log(res.data[0])
                this.setState({ blocking: false });
                if (res.status === 200) {
                  this.setState(
                    {
                      disAprrovalProv: res.data[0].ButtonStatus, disAprrovaladmin: res.data[0].ButtonStatus
                    }

                  )
                }

              })
              .catch((error) => {
                this.setState({ blocking: false });
                console.log(error, "dataaa ERROR");
              });
          }
        }
      )
    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kab')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kec')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di des')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  sel_sdm = (e) => {
    if (e) {
      this.setState({
        filterSDM: e, selectedSDM: e.value,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        filterSDM: null, selectedSDM: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  statusFormatter = (cell, row) => {
    let label = ''
    if (row.status === 1) {
      label = 'Pending MD'
    } else if (row.status === 2) {
      label = 'Pending MP'
    } else if (row.status === 3) {
      label = 'Complete'
    } else if (row.status === 7) {
      label = 'Reject MD'
    } else if (row.status === 8) {
      label = 'Reject MP'
    } else if (row.status === 9) {
      label = 'Reject PK'
    } else if (row.status === 10) {
      label = 'Reject PPK'
    } else if (row.status === 11) {
      label = 'Reject BPP'
    }

    return <div>{label}</div>
  }

  buttonFormatter = (cell, row) => {

    return <div className='divBtnTable'>
      <Button onClick={(e) => this.handleEdit(e, row)} id={row.id} className='btnTable' variant="outlined"><BsFillArrowDownCircleFill style={{ color: 'green' }} /></Button>

      {/* {row.status === 0 || row.status === 7 || row.status === 8 || row.status === 9 || row.status === 10 || row.status === 11 ?
        <>
          {this.state.roleUserLogin === 19 &&
            <>
              {this.state.disAprrovaladmin === true ?
                <>
                  <Button onClick={(e) => this.handleEdit(e, row)} id={row.id} className='btnTable' variant="outlined"><BsFillArrowDownCircleFill style={{ color: 'green' }} /></Button>
                </> : <>-</>
              }

            </>
          }
          {(this.state.roleUserLogin !== 19 && this.state.roleUserLogin !== 99) &&
            <>
              {this.state.disAprrovalProv === true ?
                <>
                  <Button onClick={(e) => this.handleEdit(e, row)} id={row.id} className='btnTable' variant="outlined"><BsFillArrowDownCircleFill style={{ color: 'green' }} /></Button>
                </> : <>-</>
              }

            </>
          }
        </>
        :
        <>
          -
        </>
      } */}
    </div>

  }

  handleHapus = () => {
    Swal.fire({
      title: 'Apakah Anda Yakin?',
      text: "Ingin Menghapus Formulir PAPI ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {

    })
  }

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      },() => {
        this.setHeaderTable()
        this.getRole();
    
        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if(role === 1 || role === 99 ) {
          this.setState({dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
          dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
          dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
          dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
          dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
          dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',})
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }
    
            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handleApprove = (e, row, action) => {
    const roleId = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID
    const dataApprov = {
      "id": [
        row.id
      ],
      "username": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "role_id": roleId,
      "status": roleId === 6 ? 7 : 8
    }

    const reject = {
      "id": [
        row.id
      ],
      "username": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "role_id": roleId,
      "status": 0
    }
    // console.log(action)
    if (action === 'APPROV') {
      Swal.fire({
        title: 'Apakah Anda Yakin?',
        text: "Ingin Approve Formulir PAPI ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        if (result.isConfirmed) {
          APIPemutakhiran.post('portalpk/metodepembayaran/updateFormulirPapi', dataApprov)
            .then((response) => {
              if (response.status === 200) {
                this.setState({ blocking: false, onSubmit: true })
                Swal.fire({
                  title: 'Info!',
                  icon: 'success',
                  text: 'Data Berhasil Di Approv!',
                })
                this.handleSearch()
              }

            })
            .catch((error) => {
              // Handle error.
              console.log(error.response);
            });
        } else {
          this.setState({blocking: false})
        }

      })
    } else {
      Swal.fire({
        title: 'Apakah Anda Yakin?',
        text: "Ingin Reject Formulir PAPI ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        if (result.isConfirmed) {
          APIPemutakhiran.post('portalpk/metodepembayaran/updateFormulirPapi', reject)
            .then((response) => {
              console.log("Genetic Report", response);
              this.handleSearch()
              this.setState({
              }, () => {

              })
            })
            .catch((error) => {
              // Handle error.
              console.log(error.response);
            });
        } else {
          this.setState({blocking: false})
        }
      })
    }
  }

  handleEdit = (e, row) => {
    Swal.fire({
      title: 'Apakah Anda Yakin?',
      text: "Ingin Approv Formulir ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'info!',
          icon: 'warning',
          text: 'Berhasil DiApprove',
        })
        this.setState({ blocking: false, disApprov: false })
      } else {
        this.setState({blocking: false })
      }
    })
  }

  Ceklis = (cell, row) => {
    if (row.status === true) {
      return <div className='divBtnTable'>
        <Button style={{ width: 10 }}><BsCheckLg size={14} style={{ color: 'green' }} /></Button>
      </div>
    } else {

      return <div className='divBtnTable'>
        <AiOutlineClose size={18} style={{ color: 'red' }} />
      </div>
    }


  }

  handleCheckbox1Change = (event, row, e) => {
    console.log(row, e)
    Swal.fire({
      title: 'Info!',
      text: "Apakah Anda Ingin Mengubah Status?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ blocking: true });

        const reqParam = {
          "idKelurahan": row.id_kelurahan,
          "lastModifiedBy": localStorage.getItem("username"),
          "sumberDana": e
        }

        var newData = [];
        APIWilayah.post('pkportal/targetkk/updateSumberPendanaanKelurahan', reqParam)
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Sumber Pendanaan Berhasil DiUpdate',
              }).then(resp => {
                this.handleSearch()
                this.setState({ selectedRows: [], blocking: true })
              })
            } else {
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                text: 'Data Gagal Diupdate',
              })
              this.setState({ blocking: false });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              text: 'Data Gagal Diupdate',
            })
            this.setState({ blocking: false });
          });

      } else {
        this.setState({blocking: false })
      }

    })

  };

  checkboxcek = () => {
    Swal.fire({
      title: 'Error!',
      icon: 'warning',
      text: 'Harap Ceklis Yang Lain!',
    })
  }
  checkbox1 = (cell, row) => {
    let status = null;
    if (row.jenis_anggaran === 2) {
      status = true;
    } else if (row.jenis_anggaran === 1) {
      status = false;
    } return (
      <div className='divBtnTable'>
        <Checkbox style={{ transform: 'scale(0.8)', padding: '2px', margin: '0' }} checked={status} onChange={status === true ? this.checkboxcek : (e) => this.handleCheckbox1Change(e, row, "2")} />
      </div>
    );
  }

  checkbox2 = (cell, row) => {
    let status = null;
    if (row.jenis_anggaran === 1) {
      status = true;
    } else if (row.jenis_anggaran === 2) {
      status = false;
    }
    return (
      <div className='divBtnTable'>
        <Checkbox style={{ transform: 'scale(0.8)', padding: '2px', margin: '0' }} checked={status} onChange={status === true ? this.checkboxcek : (e) => this.handleCheckbox1Change(2, row, "1")} />
      </div>
    );
  }

  cekUpdateValidasi = async (param) => {
    console.log('im in')
    this.setState({ blocking: true })
    // this.setState({blocking:true})
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw } = this.state
    let promises = []

    param.map(async (el) => {
      const reqParamValue = {
        "idProvinsi": id_provinsi,
        "idKabupaten": id_kabupaten,
        "idKecamatan": id_kecamatan,
        "idKelurahan": id_des,
        "idRw": el.id_rw,
        "idRt": el.id_rt
      }
      promises.push(
        await APIPemutakhiran.post("/portalpk/metodepembayaran/getValidasiPembayaran", reqParamValue)
          .then((res) => {
            let ndt = this.state.datas
            this.setState(prevState => {
              let dt = prevState.datas
              dt = dt.map(row => (row.id_rt === el.id_rt && row.roleId !== 18 ? { ...el, validasiPembayaran: res.data.validasiPembayaran } : row))
              return { datas: dt }
            },
              () => {
              })

          }).catch(() => {
            console.log("ERROR")
          })
      )
    })
    Promise.all(promises).then(() => {
      this.setState({ blocking: false })
    }).catch(() => {
      this.setState({ blocking: false })
    })

  }

  handleSearch = () => {
    this.setState({ blocking: true });

    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw, selectedSDM, role, title } = this.state
    const reqParam = {
      "id_kabupaten": id_kabupaten,
      "id_kecamatan": id_kecamatan,
      "id_kelurahan": id_des,
      "id_provinsi": id_provinsi,
      "id_rt": 0,
      "id_rw": 0
    }
    // this.setLabel()
    // console.log(this.state.kecamatan)
    if (title === ' di Kecamatan ' + undefined) {
      this.setState({ title: ' di Kecamatan ' + this.state.kecamatan?.namaWil })
    }

    if (id_kabupaten !== 0) {

      var newData = [];
      APIWilayah.post('pkportal/targetkk/getListSumberPendanaanKelurahan?periodeSensus=' + this.state.value_priode, reqParam)
        .then((res) => {
          if (res.status === 200) {
            console.log(res)
            this.setState(
              {
                datas: res.data,
                selectedRows: [],
                blocking: false
              }, () => {

              }
            )

          } else {
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              text: 'Pencarian Data Gagal, Harap Coba lagi!',
            })
            this.setState({ blocking: false });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error!',
            icon: 'warning',
            text: 'Pencarian Data Gagal, Harap Coba lagi!',
          })
          this.setState({ blocking: false });
        });
    } else {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Filter wilayah harus dipilih minimal tingkat Kabupaten',
        showConfirmButton: true
      })
      this.setState({ blocking: false });
    }
  }

  handleChangeInput = (e, tab) => {
    if (e.target.name) {
      if (isNumber(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value })
      }
    } else {

      this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    }
  }

  handleChange = (pdf) => {

    if (this.state.file_name !== '' && this.state.file_name !== null) {
      Swal.fire({
        title: 'File Sudah Pernah di Upload',
        text: "Apakah Ingin Upload Ulang?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            setFile: pdf,
            onsubmit: false
          })
        } else {
          this.setState({blocking : false })
        }

      })
    } else {
      this.setState({
        setFile: pdf,
        onsubmit: false
      })
    }

  };


  handleSubmit = () => {
    this.setState({ blocking: false, onSubmit: false })

    const dataTable = {
      // "role": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
      "id": this.state.id,
      "userID": this.state.userId,
      "id_rt": this.state.id_rt,
      "role_id": 4,
      "user_name": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "yang_ada": this.state.yang_ada,
      "didata": +this.state.ditemui + +this.state.baru,
      "ditemui": this.state.ditemui,
      "baru": this.state.baru,
      "pindah": this.state.pindah,
      "meninggal": this.state.meninggal,
      "tidak_ditemukan": this.state.tidak_ditemukan,
      "bercerai": this.state.bercerai,
      "status": 1,
      "createTunggakanBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      'id_provinsi': this.state.id_provinsirow,
      'id_kabupaten': this.state.id_kabupatenrow,
      'id_kecamatan': this.state.id_kecamatanrow,
      'id_rw': this.state.id_rwrow,
      'id_kelurahan': this.state.id_kelurahanrow,
      // "pdf" : this.state.setFile
    }

    console.log(dataTable, "PARAM")

    if (this.state.lapangan1 <= this.state.jumlahkelsar && this.state.lapangan2 <= this.state.jumlahresstan) {
      Swal.fire({
        title: 'Apakah Anda Yakin?',
        text: "Ingin Entri Formulir PAPI ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ blocking: true, onSubmit: true })

          let Data = new FormData();
          Data.append("idFileTypeUpload", this.state.setFile.type === 'image/png' || this.state.setFile.type === 'image/jpeg' ? '1' : '2');
          Data.append('createdby', localStorage.getItem('username'))
          Data.append("idWilayah", this.state.id_rt);
          Data.append("tingkatwilayah", '6');
          Data.append("doc", this.state.setFile);

          APIPemutakhiran.post('portalpk/metodepembayaran/insertFormulirPapi', dataTable)
            .then((response) => {
              console.log(response, "dijwij")
              if (response.status === 200) {
                APIUpload.post('userpembiayaan/uploadFileUser', Data)
                  .then((response) => {
                    if (response.status === 200) {
                      this.setState({ blocking: false, onSubmit: true })

                      Swal.fire({
                        title: 'Info!',
                        icon: 'success',
                        text: 'Data Berhasil Ditambah!',
                      })
                      this.handleOpenClose()
                      this.handleSearch()
                    }
                  })
                  .catch((error) => {
                    let message = error;
                    this.setState({ blocking: false, onSubmit: false })

                    Swal.fire({
                      title: 'Error!',
                      icon: 'warning',
                      text: 'Penyimpanan Data Gagal, Harap Diinput Kembali ',
                      // text: message,
                    })
                  });
              }
            })
            .catch((error) => {
              let message = error;
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                // text: 'Jaringan bermasalah.',
                text: message,
              })
              this.setState({ blocking: false, onSubmit: false });
            });
        } else {
          this.setState({ blocking: false, onSubmit: false });
        }
      })
    } else {
      Swal.fire({
        title: 'Info!',
        icon: 'warning',
        text: 'Hasil Verval Lapangan Tidak Boleh Melebihi Jumlah Keluarga Yang ada',
        showConfirmButton: true
      })
      this.setState({ blocking: false, onSubmit: false });
    }

  }


  handleCetak = (e) => {
    e.preventDefault()
    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw } = this.state;

    // const addFilter = this.state.additionalFilter
    // let newFilter = []
    // addFilter.forEach(row => {
    //     if (row.filtValue && row.valkey) {
    //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
    //     }
    // })

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#3085d6',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#4ea842',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      let urlCetakPdf = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
      let urlCetakExcel = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  handleSelectRow = (cat, row, isSelect) => {
    var data = this.state.datas
    let dataAll = []

    if (isSelect === true && cat === 'single') {
      this.setState({ selectedRows: this.state.selectedRows?.concat(row), disApprov: false });
    } else if (isSelect === true && cat === 'all') {
      this.setState({ selectedRows: data });
    } else if (cat === 'single') {
      if (this.state.selectedRows?.length !== 0) {
        var array = JSON.parse(JSON.stringify(this.state.selectedRows));
        var index = array.findIndex(el => el.id_kelurahan === row.id_kelurahan);
        if (index !== -1) {
          array.splice(index, 1);
          if (this.state.selectedRows?.length > 1) {
            this.setState({ selectedRows: array, disApprov: false });
          } else {
            this.setState({ selectedRows: array, disApprov: true });
          }

        }
        console.log(this.state.selectedRows)
      }
    }
    else {
      this.setState({ selectedRows: [], disApprov: true });
    }
  }

  handlesumberSelect = (e, param) => {
    if (this.state.selectedRows?.length === 0) {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Tidak Ada Data Yang di Update.',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      console.log(this.state.selectedRows)
      let id = []
      let idkec = []
      this.state.selectedRows.forEach(el => {
        id.push(el.id_kelurahan.toString())
        idkec.push(el.id_kecamatan.toString())
      })
      console.log(id)
      Swal.fire({
        title: 'Apakah Anda Yakin?',
        text: "Total " + this.state.selectedRows?.length + " Kelurahan, Silahkan Memilih Sumber Pendanaan",
        icon: 'warning',
        showDenyButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'APBD',
        denyButtonText: 'APBN',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ blocking: true });

          const reqParam = {
            "idProvinsi": this.state.id_provinsi,
            "idKabupaten": this.state.id_kabupaten,
            "idKecamatanList": idkec,
            "idKelurahanList": id,

            "lastModifiedBy": localStorage.getItem("username"),
            "jenisDana": 2
          }

          var newData = [];
          APIWilayah.post('pkportal/targetkk/updateJenisPendanaanKelurahan', reqParam)
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Info!',
                  icon: 'info',
                  text: 'Sumber Pendanaan Berhasil DiUpdate',
                }).then(resp => {
                  this.handleSearch()
                  this.setState({ selectedRows: [], blocking: true })
                })
              } else {
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  text: 'Data Gagal Diupdate',
                })
                this.setState({ blocking: false });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                text: 'Data Gagal Diupdate',
              })
              this.setState({ blocking: false });
            });

        } else if (result.isDenied) {
          this.setState({ blocking: true });

          const reqParam = {
            "idProvinsi": this.state.id_provinsi,
            "idKabupaten": this.state.id_kabupaten,
            "idKecamatanList": idkec,
            "idKelurahanList": id,

            "lastModifiedBy": localStorage.getItem("username"),
            "jenisDana": 1
          }
          console.log(reqParam)

          var newData = [];
          APIWilayah.post('pkportal/targetkk/updateJenisPendanaanKelurahan', reqParam)
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Info!',
                  icon: 'info',
                  text: 'Sumber Pendanaan Berhasil DiUpdate',
                }).then(resp => {
                  this.handleSearch()
                  this.setState({ selectedRows: [], blocking: true })
                })
              } else {
                Swal.fire({
                  title: 'Error!',
                  icon: 'warning',
                  text: 'Data Gagal Diupdate',
                })
                this.setState({ blocking: false });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                text: 'Data Gagal Diupdate',
              })
              this.setState({ blocking: false });
            });
        }

      })
    }
  }

  createdFormatDuplicate = (cell, row) => {
    if (row.isDuplidate === true || row.validasiPembayaran === false) {
      return 'fc3021'
    } else {
      return ''
    }
  }

  createFormatDuplicateRow = (cell, row) => {
    if (row.isDuplidate === true) {
      return <div className='divBtnTable'>
        <Tooltip title="Data Duplicate"><p style={{ width: '100%', height: '100%' }}>2</p></Tooltip>
      </div>
    } else if (row.validasiPembayaran === false) {
      return <div className='divBtnTable'>
        <Tooltip title="Masih terdapat Data Susulan dalam Proses"><p style={{ width: '100%', height: '100%' }}>1</p></Tooltip>
      </div>
    } else if (row.isDuplidate === false && row.validasiPembayaran === false) {

      <div className='divBtnTable'>
        <Tooltip title="Data Ada Duplicate dan Masih terdapat Data Susulan dalam Proses"><p style={{ width: '100%', height: '100%' }}>3</p></Tooltip>
      </div>
    } else {
      return ''
    }
  }




  render() {

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: '#e3e8e4',
      size: '10px',

      headerColumnStyle: { backgroundColor: 'blue' },
      // marginLeft: 'auto',
      // marginRight:'auto'
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handleSelectRow('single', row, isSelect)
      },
      onSelectAll: (isSelect, row, e) => {
        if (isSelect) {
          this.handleSelectRow('all', row, isSelect)
          return this.state.datas.map(row => row.id_kelurahan)
        } else {
          this.handleSelectRow('all', row, isSelect)
          return [];
        }
      }
    };
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "1",
          value: 1,
        },
        {
          text: "10",
          value: 10,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "<", // Previous page button text
      nextPage: ">", // Next page button text
      firstPage: "<<", // First page button text
      lastPage: ">>", // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
    };
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Monitoring Sumber Pendanaan</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                      <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>

            <h3>Sumber Pendanaan</h3>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
              <Grid item xs={12} md={12}>
                <div>
                  {/* <Button disabled={this.state.selectedRows?.length === 0}  sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: '#B22222', ':hover': { bgcolor: '#B22222', color: 'white' } }} variant="contained"><BsXCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Reject</Button> */}

                  <Button onClick={(e) => this.handlesumberSelect()} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px', float: 'left' }} sx={{ float: 'left' }} /> Update Sumber Pendanaan</Button>

                </div>
              </Grid>
            </Grid>
            <Paper sx={{ width: "100%", boxShadow: "none" }}>

              <div className="rekap-table">
                <BootstrapTable
                  data={this.state.datas}
                  pagination={true}
                  keyField='id_kelurahan'
                  options={options}
                  search={true}
                  selectRow={selectRow}
                >
                  {/* <TableHeaderColumn width="120" row='0' rowSpan='2' dataField="yang_ada_byuser" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter}  >ACTION </TableHeaderColumn> */}
                  <TableHeaderColumn row='0' rowSpan='2' width="200" dataField="nama_provinsi" headerAlign="center" dataAlign="center"  >PROVINSI</TableHeaderColumn>
                  <TableHeaderColumn row='0' rowSpan='2' width="200" dataField="nama_kabupaten" headerAlign="center" dataAlign="center" >KABUPATEN</TableHeaderColumn>
                  <TableHeaderColumn row='0' rowSpan='2' width="200" dataField="nama_kecamatan" headerAlign="center" dataAlign="center" >KECAMATAN</TableHeaderColumn>
                  <TableHeaderColumn row='0' rowSpan='2' width="200" dataField="nama_kelurahan" headerAlign="center" dataAlign="center" >KELURAHAN</TableHeaderColumn>
                  {/* <TableHeaderColumn row='0' rowSpan='2' width="200" dataField="status" headerAlign="center" dataAlign="center" columnClassName={this.createPKFormat} dataFormat={this.Ceklis}  >APBN/APBD</TableHeaderColumn> */}
                  <TableHeaderColumn width="120" row='0' colSpan='2' headerAlign="center" dataAlign="center" >Jenis Anggaran </TableHeaderColumn>
                  <TableHeaderColumn width="120" row='1' dataField="jenis_anggaran" headerAlign="center" dataAlign="center" dataFormat={this.checkbox1}  >APBD </TableHeaderColumn>
                  <TableHeaderColumn width="120" row='1' dataField="jenis_anggaran" headerAlign="center" dataAlign="center" dataFormat={this.checkbox2} > APBN </TableHeaderColumn>

                  {/* <TableHeaderColumn width="100" dataField="resikostunting" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >HASIL RESIKO STUNTING </TableHeaderColumn>
                  <TableHeaderColumn width="100" dataField="sasaranverval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >KELUARGA SASARAN </TableHeaderColumn> */}

                  {/* <TableHeaderColumn width='100' dataField='status' dataFormat={this.createFormatDuplicateRow} headerAlign='center' dataAlign='center' >KETERANGAN</TableHeaderColumn> */}

                </BootstrapTable>
                <div className="sizePerPage">
                  Size Per Page
                  <select
                    name="category"
                    value={this.state.sizePerPage}
                    onChange={this.handleChangeSizePerPage}
                  >
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value={this.state.datas?.length}>All</option>
                  </select>
                </div>
                <div className='totalData'>
                  <b style={{ marginRight: '15px' }}>Total Data: {this.state.datas?.length}</b>
                  {/* <b style={{ marginRight: '15px' }}>Data Duplicate dan Susulan: {this.state.dupsul}</b> */}
                </div>

              </div>

            </Paper>
            {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}> */}

            <Dialog open={this.state.openInfo} onClose={this.handleOpenCloseInfo} width={'30%'}>
              <DialogTitle style={{ paddingLeft: '25px' }}><BsInfoCircle size={15} />&nbsp; Info</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {/* <Grid container columnSpacing={{ xs: 12, sm: 1, md: 1 }} style={{ marginTop: '10px', marginBottom: '15px', paddingLeft: '15px' }}> */}
                  Info Code di Column KETERANGAN <br />
                  - Keterangan 1 adalah Masih terdapat Data Susulan dalam Proses <br />
                  - Keterangan 2 adalah Data Duplicate<br />
                  - Keterangan 3 adalah Data Ada Duplicate dan Masih terdapat Data Susulan dalam Proses



                  {/* </Grid> */}

                </DialogContentText>

              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleOpenCloseInfo} variant="outlined" color="primary">Tutup</Button>
              </DialogActions>
            </Dialog>
            {/* </Grid> */}

            <Dialog open={this.state.openDialog} onClose={this.handleOpenClose}>
              <DialogTitle>Formulir Stunting</DialogTitle>
              {/* {this.state.id ? 'Ubah' : 'Tambah'} */}
              <DialogContent>
                <DialogContentText>
                  {/* RW: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_rw}<br /> */}
                </DialogContentText>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <Grid item xs={12} md={6}>
                    {/* <FormControl sx={{ width: '100%' }} size="small" > */}
                    KELURAHAN : <b>CILANGKAP</b>
                    {/* <Select options={this.state.pendata} id="pendataa" name="pendataa" onChange={this.sep_pendata} value={this.state.pendataa} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} /> */}
                    {/* </FormControl> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="filled-required"
                      label="JUMLAH KELUARGA SASARAN PK22"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      value={this.state.jumlahkelsar}
                      name="jumlahkelsar"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="filled-required"
                      label="JUMLAH KELUARGA RESIKO STUNTING PK22"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      value={this.state.jumlahresstan}
                      name="jumlahresstan"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="filled-required"
                      label="HASIL VERVAL LAPANGAN KELUARGA SASARAN"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      value={this.state.lapangan1}
                      name="lapangan1"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="filled-required"
                      label="HASIL VERVAL LAPANGAN KELUARGA RESIKO STUNTING"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      value={this.state.lapangan2}
                      name="lapangan2"
                      fullWidth
                    />
                  </Grid>
                </Grid>

              </DialogContent>
              <DialogActions>
                {/* <Button disabled={this.state.disabledSimpanRt} onClick={this.handleAddRt} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Upload PDF</Button> */}
                {this.state.onSubmit === false ?
                  <>
                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleSubmit} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenClose} variant="outlined" color="error">Batal</Button>
                  </> :
                  <div style={{ paddingRight: '1rem', fontWeight: '600' }}>
                    Loading, please wait...
                  </div>
                }
                {/* <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenClose} variant="outlined" color="error">Batal</Button> */}

              </DialogActions>
            </Dialog>
          </div>
        </div>
      </BlockUi>
    )
  }
}

export default pendataanSupervisor;