import React, { useState } from 'react';
import Layout from './components/Layout'
import { Outlet } from 'react-router';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import Header from './components/Header'

const WithNav = () => {
  const [toggled, setToggled] = useState(false);
  
  const [collapsed, setCollapsed] = useState(false);
  
  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>
      <Layout toggled={toggled} handleToggleSidebar={handleToggleSidebar} collapsed={collapsed} handleCollapsedChange={handleCollapsedChange}/>
      <main>
        <SimpleBar style={{ maxHeight: '100vh' }}>
          <Header toggled={toggled} handleToggleSidebar={handleToggleSidebar}/>
          <Outlet />
        </SimpleBar>
      </main>
    </div>
  );
};

export default WithNav;