import React, { Component } from "react";
import 'react-block-ui/style.css';
import {
    Grid,
    Box,
    Container,
    Input,
    TextField,
    InputLabel,
    Button,
    Divider,
    FormControl,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import { ImPhone, ImLocation,ImManWoman,ImCalendar,ImListNumbered } from "react-icons/im";
import { MdAlternateEmail } from "react-icons/md";
import { AiOutlineIdcard, AiFillLock } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { BsPersonCheckFill, BsPersonCheck } from "react-icons/bs";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Select from 'react-select';

import authServices from "../../services/authServices";
import APIWilayah from '../../services/APIWilayah';
import Swal from "sweetalert2";
import moment from 'moment';
import { ConstructionOutlined } from "@mui/icons-material";


const isNumber = (str) => /^[0-9]*$/.test(str);

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: localStorage.getItem("username"),
            namaLengkap: '',
            email: '',
            noTelepone: '',
            nik: '',
            alamat: '',
            nip: '',
            namaIbuKandung: '',
            npwp: '',
            statusPekerjaan:'',
            // valueStatus:'',
            showPasswordLama: false,
            showPasswordBaru: false,
            showPasswordBaruKonfirm: false,
            password: '',
            passwordBaru: '',
            hideNpwp:'',
            errors: {},
            dis_simpan : true,
            tanggal_lahir: '',
            jenis_kelamin: '',
            kodePos:'',
            flagProv: true,
            flagKab: true,
            flagKec: true,
            flagDes: true,
            rt2:'',
            rw2:'',
            //Inputan Wilayah Alamat
            dt_prov2: [], provinsi2: null, id_provinsi2: 0, depdagriProv2: '', dis_prov2: false, auto_prov2: false,
            dt_kab2: [], kabupaten2: null, id_kabupaten2: 0, dis_kab2: false, depdagriKab2: '', auto_kab2: false,
            dt_kec2: [], kecamatan2: null, id_kecamatan2: 0, dis_kec2: false, depdagriKec2: '', auto_kec2: false,
            dt_des2: [], desa2: null, id_des2: 0, dis_des2: false, depdagriKel2: '', auto_des2: false,
            dt_rw2: [], rw2: '', id_rw2: 0, dis_rw2: false, depdagriRw2: '',
            dt_rt2: [], rt2: '', id_rt2: '', id_rt2: 0, dis_rt2: false, depdagriRt2: ''
        };
    }

    getData = () => {
        this.setState(
            {
                a:''
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setProv2();
                this.setKab2(parseInt(rest[0].attributes.user.alamat_provinsi));
                this.setKec2(parseInt(rest[0].attributes.user.alamat_kabupaten));
                this.setDes2(parseInt(rest[0].attributes.user.alamat_kecamatan));
            }
        )
    }

    componentDidMount = () => {
        this.getData();
        const dt = JSON.parse(localStorage.getItem("authority"));
        const wilProv = dt[0].attributes.user.wilProvinsi;
        const wilKab = dt[0].attributes.user.wilKabupaten;
        const wilKec = dt[0].attributes.user.wilKecamatan;
        const wilKel = dt[0].attributes.user.wilKelurahan;
        const atr = dt[0].attributes;
        //const user_name = atr.user_name
        const nama_lengkap = atr.user.namaLengkap ? atr.user.namaLengkap : ''
        const email = atr.user.email ? atr.user.email : ''
        const noTelephone = atr.user.noTelepone ? atr.user.noTelepone : ''
        const nik = atr.user.NIK ? atr.user.NIK : ''
        const alamat = atr.user.alamat ? atr.user.alamat : ''
        const nip = atr.user.NIP ? atr.user.NIP : ''
        // const npwp = atr.user.npwp ? atr.user.npwp : ''
        const namaIbuKandung = atr.user.namaIbuKandung? atr.user.namaIbuKandung : ''
        const statusPekerjaan = atr.user.statusPekerjaan
        const golongan = atr.user.golongan
        const no_rekening = atr.user.no_rekening? atr.user.no_rekening : ''
        const bank = atr.user.bank? atr.user.bank : ''
        const npwp = atr.user.NPWP? atr.user.NPWP : ''
        const status_npwp = atr.user.status_npwp
        const kodePos = atr.user.kode_pos? atr.user.kode_pos : 0
        const alamat_rw = atr.user.alamat_rw? atr.user.alamat_rw : ''
        const alamat_rt = atr.user.alamat_rt? atr.user.alamat_rt : ''
        const tempat_lahir = atr.user.tempat_lahir ? atr.user.tempat_lahir : ''
        const jenis_kelamin = atr.user.jenis_kelamin ? atr.user.jenis_kelamin : 1
        const tanggal_lahir = atr.user.tanggal_lahir ? moment(atr.user.tanggal_lahir).format("YYYY-MM-DD") : ''
        //console.log(dt, "dt");

        this.setState({
            //username:user_name,
            namaLengkap: nama_lengkap,
            email: email,
            noTelepone: noTelephone,
            nik: nik,
            alamat: alamat,
            nip: nip,
            namaIbuKandung: namaIbuKandung,
            statusPekerjaan: (statusPekerjaan == null) ? 'Belum Memilih Status Pekerjaan' : statusPekerjaan,
            golongan:(golongan == 1) ? 'Golongan I' :
            (golongan == 2) ? 'Golongan II' :
                (golongan == 3) ? 'Golongan III' :
                    (golongan == 4) ? 'Golongan IV' :
                        'Belum Memilih Golongan',
            password: '',
            passwordBaru: '',
            no_rekening:no_rekening,
            bank:bank,
            npwp:npwp,
            status_npwp:status_npwp,
            kodePos:kodePos,
            rw2:alamat_rw,
            rt2:alamat_rt,
            tempat_lahir:tempat_lahir,
            jenis_kelamin: jenis_kelamin,
            tanggal_lahir: tanggal_lahir
        })
    };

    componentDidUpdate = () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        if (this.state.dt_prov2.length > 0 && this.state.flagProv === true) {
            const newdatanya = this.state.dt_prov2.filter(item => item.value === parseInt(rest[0].attributes.user.alamat_provinsi))
            this.setState({
                provinsi2: newdatanya.length > 0 ? newdatanya[0] : null, flagProv: false })
        }
        if (this.state.dt_kab2.length > 0 && this.state.flagKab === true) {
            const newdatanya = this.state.dt_kab2.filter(item => item.value === parseInt(rest[0].attributes.user.alamat_kabupaten))
            this.setState({
                kabupaten2: newdatanya.length > 0 ? newdatanya[0] : null, flagKab: false })
        }
        if (this.state.dt_kec2.length > 0 && this.state.flagKec === true) {
            const newdatanya = this.state.dt_kec2.filter(item => item.value === parseInt(rest[0].attributes.user.alamat_kecamatan))
            this.setState({ kecamatan2: newdatanya.length > 0 ? newdatanya[0] : null, flagKec: false })
        }
        if (this.state.dt_des2.length > 0 && this.state.flagDes === true) {
            const newdatanya = this.state.dt_des2.filter(item => item.value === parseInt(rest[0].attributes.user.alamat_kelurahan))
            this.setState({ desa2: newdatanya.length > 0 ? newdatanya[0] : null, flagDes: false })
        }
    }

    handleClickShowPassword = () => {
        this.setState({
            showPasswordLama: !this.state.showPasswordLama,
            showPasswordBaru: !this.state.showPasswordBaru,
        });
    };

    handleChangeKelamin = (e) => {
        this.setState({
            jenis_kelamin: e.target.value
        });
    }

    sel_tanggal_lahir = (e) => {
        if (e) {
            this.setState({
            tanggal_lahir: e.target.value,
            });
        }else {
            this.setState({
            tanggal_lahir: ''
            })
        }
    }

    handleClickShowPasswordKonfirm = () => {
        this.setState({
            showPasswordBaruKonfirm: !this.state.showPasswordBaruKonfirm,
        })
    }

    // handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     event.preventDefault();
    //     };

    // handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValues({ ...values, [prop]: event.target.value });
    // };

    handleChangeForm = (e) => {
        if (e.target.name === 'noTelepone' || e.target.name === "nik" || e.target.name === "nip" || e.target.name === "no_rekening" || e.target.name === "npwp") {
            if (isNumber(e.target.value)) {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }

    }

    handleChangeNpwp = (e) => {
        if (e.target.checked) {
            this.setState({ hideNpwp: false, status_npwp: true })
        } else {
            this.setState({ hideNpwp: 'none', npwp: '', status_npwp: false })
        }
    }
    // handleSelectStatus = (e) => {
    //     console.log('tes')
    //     if(e){
    //         console.log(e)
    //         this.setState({
    //             valueStatus: e,
    //             statusPekerjaan: e.label
    //         })

    //     }

    // }


    handleChangePassword = (e) => {
        if (e) {
            if (e.target.name === 'passwordBaru') {
                this.setState({ password: e.target.value })
            } else if (e.target.name === 'passwordBaruKonfirm') {
                this.setState({ passwordBaru: e.target.value })
            }

        }

        // console.log(this.state.password, 'password')
        // console.log(this.state.passwordBaru, 'passwordBaru')

    }

    handleValidationProfil = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.nik) {
            formIsValid = false;
            errors["nik"] = "*Wajib diisi";
        } else if (!this.state.namaLengkap) {
            formIsValid = false;
            errors["namaLengkap"] = "*Wajib diisi";
        } else if (!this.state.email) {
            formIsValid = false;
            errors["email"] = "*Wajib diisi";
        } else if (!this.state.noTelepone) {
            formIsValid = false;
            errors["noTelepone"] = "*Wajib diisi";
        }


        this.setState({ errors: errors })
        return formIsValid;

    }

    handleChangeValidasi = (e) => {
        if(e.target.checked){
            this.setState({dis_simpan:false})
        }else{
            this.setState({dis_simpan:true})
        }
    }

    setProv2 = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri+' - '+data.nama, 'kode': data.kodeDepdagri };
                        newData2.push(obj);
                    });
                    this.setState({ dt_prov2: newData2 },
                        () => {
                            if(id !== null && this.state.auto_prov2 === true){
                                const vl = this.state.dt_prov2.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov2(vl[0])
                                console.log(this.state.provinsi2,'bawah')
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab2(idProv2, idKab2) {
        this.setState({ blocking: true });
        this.setState({ dt_kab2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv2 + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj2 = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData2.push(obj2);
                });

                this.setState({ dt_kab2: newData2, blocking: false },
                    () => {
                        if (idKab2 !== null && this.state.auto_kab2 === true) {
                            const vl = this.state.dt_kab2.filter(item => parseInt(item.value) === parseInt(idKab2))
                            // console.log(vl, 'vale kab')
                            this.sel_kab2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec2(idKab2, idKec2) {
        this.setState({ blocking: true });
        this.setState({ dt_kec2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab2 + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj2 = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData2.push(obj2);
                });

                // const x = newData2.filter(el => el.value === this.state.datauser.alamat_kecamatan)
                // this.setState({
                //     kecamatan2: x, id_kecamatan2: x.value, depdagriKec2: x.kode,
                // })
                // this.setDes2(this.state.datauser.alamat_kecamatan,this.state.datauser.alamat_kelurahan)

                this.setState({ dt_kec2: newData2, blocking: false },
                    () => {
                        if (idKec2 !== null && this.state.auto_kec2 === true) {
                            const vl = this.state.dt_kec2.filter(item => parseInt(item.value) === parseInt(idKec2))
                            // console.log(vl, 'vale kec')
                            this.sel_kec2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes2(idKec2, idDes2) {
        this.setState({ blocking: true });
        this.setState({ dt_des2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec2 + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj2 = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData2.push(obj2);
                });

                // const x = newData2.filter(el => el.value === this.state.datauser.alamat_kelurahan)
                // this.setState({
                //     desa2: x, id_des2: x.value, depdagriKel2: x.kode,
                // })
                // this.setRW2(this.state.datauser.alamat_kelurahan,this.state.datauser.alamat_rw)

                this.setState({ dt_des2: newData2, blocking: false },
                    () => {
                        if (idDes2 !== null && this.state.auto_des2 === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes2))
                            // console.log(vl, 'vale des')
                            this.sel_des2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    // setRW2(id2) {
    //     this.setState({ blocking: true });
    //     this.setState({ dt_rw2: [] });
    //     var newData2 = [];
    //     APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024'2)
    //         .then(res => {
    //             res.data.forEach(data => {
    //                 const obj2 = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
    //                 newData2.push(obj2);
    //             });

    //             const x = newData2.filter(el => el.value === this.state.datauser.alamat_rw)
    //             this.setState({
    //                 rw2: x, id_rw2: x.value, depdagriRw2: x.kode,
    //             })
    //             this.setRT2(this.state.datauser.alamat_rw,this.state.datauser.alamat_rt)

    //             this.setState({ dt_rw2: newData2, blocking: false });
    //         }).catch((error) => {
    //             this.setState({ blocking: false });
    //         });
    // }

    sel_prov2 = (e) => {
        if (e) {
            console.log('sel prov')
            this.setState({
                provinsi2: e, id_provinsi2: e.value, depdagriProv2: e.kode,
            },
                () => {
                    console.log('if sel prov')
                    if (this.state.id_provinsi2 && this.state.auto_kab2 === false) {
                        this.setState({
                            dis_kab2: false, kabupaten2: null, kecamatan2: '', desa2: null,
                            dis_kec2: true, dis_des2: true, dis_rw2: true, dis_rt2: true
                        })
                        this.setKab2(this.state.id_provinsi2, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di prov')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                provinsi2: null, kabupaten2: null, kecamatan2: null, desa2: null,
                dis_kab2: true, dis_kec2: true, dis_des2: true, dis_rw2: true, dis_rt2: true,
                id_provinsi2: 0, id_kabupaten2: 0, id_kecamatan2: 0, id_des2: 0, id_rw2: 0, id_rt2: 0,
                sendDatas: {},
            }
                ,
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab2 = (e) => {
        if (e) {
            console.log('sel kab')
            this.setState({
                kabupaten2: e, id_kabupaten2: e.value, depdagriKab2: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten2 && this.state.auto_kec2 === false) {
                        console.log('if dalem sel kab')
                        this.setState({
                            dis_kec2: false, kecamatan2: null, desa2: null,
                            dis_des2: true, dis_rw2: true, dis_rt2: true
                        })
                        this.setKec2(this.state.id_kabupaten2, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di kab')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                kabupaten2: null, kecamatan2: null, desa2: null,
                dis_kec2: true, dis_des2: true, dis_rw2: true, dis_rt2: true,
                id_kabupaten2: 0, id_kecamatan2: 0, id_des2: 0, id_rw2: 0, id_rt2: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec2 = (e) => {
        if (e) {
            console.log('sel kec')
            this.setState({
                kecamatan2: e, id_kecamatan2: e.value, depdagriKec2: e.kode,
            },
                () => {
                    console.log('if dalem sel kec')
                    if (this.state.id_kecamatan2 && this.state.auto_des2 === false) {
                        this.setState({
                            desa2: null,
                            dis_des2: false, dis_rw2: true, dis_rt2: true
                        })
                        this.setDes2(this.state.id_kecamatan2, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di kec')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                kecamatan2: null, desa2: null,
                dis_des2: true, dis_rw2: true, dis_rt2: true,
                id_kecamatan2: 0, id_des2: 0, id_rw2: 0, id_rt2: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des2 = (e) => {
        if (e) {
            console.log('sel kelurahan')
            this.setState({
                desa2: e, id_des2: e.value, depdagriKel2: e.kode, dis_rw2: false, dis_rt2: true,
            },
                () => {
                    console.log('if sel kelurahan')
                    if (this.state.id_des2) {
                        // this.setRW2(this.state.id_des2);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di des')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                desa2: null, dis_rw2: true, dis_rt2: true,
                id_des2: 0, id_rw2: 0, id_rt2: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleSaveProfil = (e) => {
        e.preventDefault();
        if (this.handleValidationProfil()) {
            Swal.fire({
                title: "Edit Profil",
                text: "Apakah anda yakin untuk mengubah data profil?",
                icon: "warning",
                cancelButtonColor: "#d33",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya!",
                confirmButtonColor: "#3085d6",
                showCancelButton: true,
            }).then((result) => {

                if(this.state.provinsi2 === null || Object.keys(this.state.provinsi2).length === 0 ||
                    this.state.kabupaten2 === null || Object.keys(this.state.kabupaten2).length === 0 ||
                    this.state.kecamatan2 === null || Object.keys(this.state.kecamatan2).length === 0 ||
                    this.state.desa2 === null || Object.keys(this.state.desa2).length === 0 ||
                    this.state.alamat === '' || this.state.kodePos === 0 || this.state.kodePos === '' ||
                    this.state.rt2 === '' || this.state.rw2 === ''
                    ){
                    Swal.fire(
                        'Peringatan !',
                        'Alamat Dan Wilayah Tidak Boleh Kosong',
                        'info'
                    )
                    return false;
                }

                if(this.state.tanggal_lahir === '' || this.state.tempat_lahir === ''){
                    Swal.fire(
                        'Peringatan !',
                        'Mohon Isi Tanggal Lahir & Tempat Lahir !',
                        'info'
                    )
                    return false;
                }

                if(this.state.jenis_kelamin <= 0){
                    Swal.fire(
                        'Peringatan !',
                        'Mohon Pilih Jenis Kelamin !',
                        'info'
                    )
                    return false;
                }

                if (this.state.hideNpwp === false && this.state.npwp === "") {
                    Swal.fire(
                        'Peringatan !',
                        'Npwp Wajib Di Isi Apabila Anda Mempunyai NPWP !',
                        'info'
                    )
                    return false;
                }
                if(this.state.bank?.length > 0 && this.state.no_rekening?.length === 0){
                    Swal.fire(
                        'Peringatan !',
                        'No Rekening Wajib Di Isi Apabila Anda Mengisi Nama Bank !',
                        'info'
                    )
                    return false;
                }
                if(this.state.no_rekening?.length > 0 && this.state.bank?.length === 0){
                    Swal.fire(
                        'Peringatan !',
                        'Nama Bank Wajib Di Isi Apabila Anda Mengisi Nomor Rekening !',
                        'info'
                    )
                    return false;
                }
                if (result.value) {
                    try {
                        let dataSubmit = {};
                        dataSubmit = {
                            alamat: this.state.alamat,
                            email: this.state.email,
                            namalengkap: this.state.namaLengkap,
                            nik: this.state.nik,
                            nip: this.state.nip,
                            notelepon: this.state.noTelepone,
                            username: this.state.username,
                            namaIbuKandung: this.state.namaIbuKandung,
                            no_rekening:this.state.no_rekening,
                            bank:this.state.bank,
                            npwp:this.state.npwp,
                            status_npwp:this.state.status_npwp,
                            alamat_provinsi: this.state.provinsi2.value,
                            alamat_kabupaten: this.state.kabupaten2.value,
                            alamat_kecamatan: this.state.kecamatan2.value,
                            alamat_kelurahan: this.state.desa2.value,
                            alamat_rw: this.state.rw2,
                            alamat_rt: this.state.rt2,
                            tanggal_lahir:new Date(this.state.tanggal_lahir.toLocaleString({ timeZone: "Asia/Jakarta" })).toISOString(),
                            tempat_lahir:this.state.tempat_lahir,
                            kode_pos: parseInt(this.state.kodePos)
                        };

                        authServices.put("/pemutakhiran/userupdate?periodeSensus=2024", dataSubmit)
                            .then((response) => {
                                if (response.status === 200) {
                                    Swal.fire({
                                        title: "Sukses!",
                                        icon: "success",
                                        text: "Data berhasil disimpan.",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    })

                                } else {
                                    Swal.fire({
                                        title: "Error!",
                                        icon: "error",
                                        text: "Simpan Gagal.",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    })
                                }
                            })
                            .catch((error) => {
                                Swal.fire({
                                    title: "Error!",
                                    icon: "error",
                                    text: "Simpan Gagal.",
                                    showConfirmButton: false,
                                    timer: 1500,

                                })
                            })
                    } catch (error) {
                        Swal.fire({
                            title: "Error!",
                            icon: "error",
                            text: "Simpan Gagal.",
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                } //else {
                //this.setState({blocking: false});
                //}
            })

        }

    }

    handleSavePassword = (e) => {
        e.preventDefault();

        if (this.state.password === '' || this.state.passwordBaru === '') {
            Swal.fire({
                title: 'Info',
                icon: 'info',
                text: 'Masukkan kata sandi!',
            });
        } else {
            const formPassword = {
                password: this.state.passwordBaru,
                updateBy: this.state.username,
                updateDate: moment().toJSON(),
                username: this.state.username
            }
            if (this.state.password === this.state.passwordBaru) {
                authServices.put('/user/changePassword?periodeSensus=2024', formPassword)
                    .then(response => {
                        if (response.status === 200) {
                            Swal.fire({
                                title: 'Ubah Kata Sandi Berhasil',
                                icon: 'success',
                                text: 'Anda akan diarahkan ke halaman login dan masukkan kata sandi yang baru',
                            });

                            localStorage.clear();
                            setTimeout(
                                () => window.open("/login", "_self"),
                                5000
                            );

                        } else {
                            Swal.fire({
                                title: 'Info',
                                icon: 'warning',
                                text: 'Update password gagal!',
                            });
                        }
                    }).catch((error) => {
                        Swal.fire({
                            title: 'Error',
                            icon: 'warning',
                            text: error,
                        });
                    })
            } else {
                Swal.fire({
                    title: 'Gagal',
                    icon: 'warning',
                    text: 'Kata sandi yang dimasukkan tidak sama!',
                });
            }
        }

    }



    render() {
        return (

            <Container>
                <div>
                    <h4>Profil Pengguna</h4>
                </div>
                <Box sx={{ width: "100%" }} m={3}>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={2} style={{ display: 'inline-block' }}>
                            <div >
                                <FaUser style={{top:'2px', position:'relative'}} />&nbsp; Username
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="username"
                                variant="outlined"
                                style={{ width: '80%', bottom: '5px' }}
                                value={this.state.username}
                                size="small"
                                disabled
                                onChange={this.handleChangeForm}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ display: 'inline-block' }}>
                            <div>
                                <BsPersonCheckFill style={{top:'2px', position:'relative'}} />&nbsp; NIK
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                error={this.state.errors["nik"] ? true : false}
                                name="nik"
                                variant="outlined"
                                style={{ width: '80%', bottom: '5px' }}
                                value={this.state.nik}
                                size="small"
                                onChange={this.handleChangeForm}
                                helperText={this.state.errors["nik"] ? this.state.errors["nik"] : ''}
                                inputProps={{maxLength: 16}}
                            />
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={2} style={{ display: 'inline-block' }}>
                            <div>
                                <AiOutlineIdcard style={{top:'2px', position:'relative'}}/>&nbsp; Nama Lengkap
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                error={this.state.errors["namaLengkap"] ? true : false}
                                name="namaLengkap"
                                variant="outlined"
                                type="text"
                                style={{ width: '80%', bottom: '5px' }}
                                value={this.state.namaLengkap}
                                size="small"
                                onChange={this.handleChangeForm}
                                helperText={this.state.errors["namaLengkap"] ? this.state.errors["namaLengkap"] : ''}

                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}

                        >
                            <div>
                                <ImManWoman />
                                &nbsp; Jenis Kelamin <a style={{ color: 'red' }}>*</a>
                            </div>

                        </Grid>

                        <Grid
                            item xs={4}
                        >
                            <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="jenis_kelamin"
                                onChange={this.handleChangeKelamin}
                                value={this.state.jenis_kelamin}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Laki-Laki" />
                                <FormControlLabel value="2" control={<Radio />} label="Perempuan" />
                            </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImCalendar />
                                        &nbsp; Tanggal Lahir <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>

                                <Grid item xs={4}>
                                <FormControl sx={{ width: '80%' }}>
                                    <TextField name='tanggal_lahir' onChange={this.sel_tanggal_lahir} value={this.state.tanggal_lahir} inputProps={{ style: { height: "6px" }, max: moment(new Date()).format("YYYY-MM-DD") }} type="date" id="outlined-basic" isClearable />
                                </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div >
                                        <ImLocation />&nbsp; Tempat Lahir <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="tempat_lahir"
                                        variant="outlined"
                                        value={this.state.tempat_lahir}
                                        type="text"
                                        style
                                        ={{
                                            width: '80%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid>
                            </Grid>
                    <p></p>
                    <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <AiOutlineIdcard />&nbsp; Nama Bank
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="bank"
                                        value={this.state.bank}
                                        variant="outlined"
                                        type="text"
                                        style
                                        ={{
                                            width: '80%'
                                        }}
                                        size="small"
                                        inputProps={{maxLength: 30}}
                                        onChange={this.handleChangeForm} />
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <AiOutlineIdcard />&nbsp; No Rekening
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                <TextField
                                    name="no_rekening"
                                    variant="outlined"
                                    style={{ width: '80%', bottom: '5px' }}
                                    value={this.state.no_rekening}
                                    size="small"
                                    onChange={this.handleChangeForm}
                                    inputProps={{maxLength: 18}}
                                />
                                </Grid>
                            </Grid>
                    <p></p>

                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >

                        <Grid item xs={2} style={{ display: 'inline-block' }}>
                            <div >
                                <MdAlternateEmail style={{top:'2px', position:'relative'}}/>&nbsp; Email
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                error={this.state.errors["email"] ? true : false}
                                name="email"
                                variant="outlined"
                                type="email"
                                style={{ width: '80%', bottom: '5px' }}
                                value={this.state.email}
                                size="small"
                                onChange={this.handleChangeForm}
                                helperText={this.state.errors["email"] ? this.state.errors["email"] : ''}

                            />
                        </Grid>

                        <Grid item xs={2} style={{ display: 'inline-block' }}>
                            <div>
                                <ImPhone style={{top:'2px', position:'relative'}}/> &nbsp; No. Telepon
                            </div>

                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                error={this.state.errors["noTelepone"] ? true : false}
                                name="noTelepone"
                                variant="outlined"
                                style={{ width: '80%', bottom: '5px' }}
                                value={this.state.noTelepone}
                                size="small"
                                onChange={this.handleChangeForm}
                                helperText={this.state.errors["noTelepone"] ? this.state.errors["noTelepone"] : ''}
                                inputProps={{maxLength: 13}}

                            />
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid item xs={2} style={{ display: 'inline-block' }}>
                            <div>
                                <BsPersonCheck style={{top:'2px', position:'relative'}}/>&nbsp; NIP
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="nip"
                                variant="outlined"
                                style={{ width: '80%', bottom: '5px' }}
                                value={this.state.nip}
                                size="small"
                                onChange={this.handleChangeForm}
                                inputProps={{maxLength: 18}}
                            />
                        </Grid>
                    </Grid>
                    <p></p>
                    <fieldset>
                        <legend>ALAMAT (HARUS SESUAI KTP)</legend>
                            <p></p>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImLocation />&nbsp; Alamat <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        inputProps={{
                                            maxLength: 30,
                                        }}
                                        name="alamat"
                                        value={this.state.alamat}
                                        variant="outlined"
                                        type="text"
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImListNumbered />
                                        &nbsp; Kode POS <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        inputProps={
                                            { maxLength: 5 }
                                        }
                                        name="kodePos"
                                        variant="outlined"
                                        value={this.state.kodePos}
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid>
                            </Grid>
                            <p></p>

                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImLocation />
                                        &nbsp; Provinsi <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <FormControl sx={{ width: '100%' }} size="small" >
                                        <Select isDisabled={this.state.dis_prov2} options={this.state.dt_prov2} id="prov2" name="provinsi2" onChange={this.sel_prov2} value={this.state.provinsi2} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                    </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImLocation />
                                        &nbsp; Kabupaten <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                            <Select options={this.state.dt_kab2} isClearable onChange={this.sel_kab2} value={this.state.kabupaten2} placeholder="Cari..." isDisabled={this.state.dis_kab2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <p></p>

                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImLocation />
                                        &nbsp; Kecamatan <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>
                                <Grid item xs={4} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            <Select options={this.state.dt_kec2} isClearable onChange={this.sel_kec2} value={this.state.kecamatan2} placeholder="Cari..." isDisabled={this.state.dis_kec2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImLocation />
                                        &nbsp; Kelurahan <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>
                                <Grid item xs={4} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            <Select options={this.state.dt_des2} isClearable onChange={this.sel_des2} value={this.state.desa2} placeholder="Cari..." isDisabled={this.state.dis_des2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                </Grid>
                            </Grid>
                            <p></p>

                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImLocation />
                                        &nbsp; RW <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>
                                <Grid item xs={4} md={4}>
                                <TextField
                                        required
                                        inputProps={
                                            { maxLength: 10 }
                                        }
                                        name="rw2"
                                        variant="outlined"
                                        value={this.state.rw2}
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImLocation />
                                        &nbsp; RT <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>
                                <Grid item xs={4} md={4}>
                                <TextField
                                        required
                                        inputProps={
                                            { maxLength: 10 }
                                        }
                                        name="rt2"
                                        variant="outlined"
                                        value={this.state.rt2}
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid>
                            </Grid>
                            <p></p>
                        </fieldset>
                    <p></p>
                    <FormControlLabel control={<Checkbox
                                onChange={this.handleChangeNpwp}
                                checked={this.state.status_npwp === true ? true : false}
                            />} label="Apakah Anda Memiliki NPWP ?" />
                            <p></p>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{
                            xs: 1,
                            sm: 2,
                            md: 3
                        }}>

                        <Grid
                            item
                            xs={2}
                            style={{
                                display: 'inline-block',
                                display: this.state.status_npwp === true ? false : 'none'
                            }}>
                            <div>
                                <AiOutlineIdcard />&nbsp; NPWP
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                required
                                name="npwp"
                                value={this.state.npwp}
                                variant="outlined"
                                style
                                ={{
                                    width: '80%',
                                    display: this.state.status_npwp === true ? false : 'none'
                                }}
                                size="small"
                                onChange={this.handleChangeForm}
                                autocomplete="new-password"
                                inputProps={{maxLength: 16}} />
                        </Grid>
                    </Grid>
                    <p></p>
                    <p></p>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={2} style={{ display: 'inline-block' }}>
                            <div >
                                <FaUser style={{top:'2px', position:'relative'}} />&nbsp; status
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="username"
                                variant="outlined"
                                style={{ width: '80%', bottom: '5px' }}
                                value={this.state.statusPekerjaan}
                                size="small"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2} style={{ display: 'inline-block' }}>
                            <div>
                                <FaUser style={{top:'2px', position:'relative'}} />&nbsp; Golongan
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                style={{ width: '80%', bottom: '5px' }}
                                value={this.state.golongan}
                                size="small"
                                disabled
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControlLabel control={<Checkbox
                            onChange={this.handleChangeValidasi}
                        />} label="Data yang dimasukkan sudah benar dan dapat dipertanggung jawabkan." /><br></br>
                        <Button disabled={this.state.dis_simpan} variant="contained" onClick={this.handleSaveProfil}>Simpan</Button>
                    </Grid>



                </Box>


                <div>
                    <Divider />
                    <h4>Perubahan Password</h4>
                </div>
                <Box sx={{ width: "100%" }}>
                    {/* <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
            <Grid item xs={4} style={{display: 'inline-block'}}>
                <div >
                    <AiFillLock />&nbsp; Password Lama
                </div>
            </Grid>
            <Grid item xs={8}>
            <FormControl sx={{ width: '80%' }} size="small" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
            style={{width : '80%'}}
            //size="medium"
            //id="outlined-adornment-password"
            name="passwordLama"
            type={this.state.showPasswordLama ? 'text' : 'password'}
            value={this.state.password}
            onChange={this.handleChangePassword}

            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                >
                    {this.state.showPasswordLama ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                </InputAdornment>
            }
            label="Password"
            />
            </FormControl>

            </Grid>

            </Grid>
            <p></p> */}
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={4} style={{ display: 'inline-block' }}>
                            <div >
                                <AiFillLock style={{top:'2px', position:'relative'}}/>&nbsp; Password Baru
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl sx={{ width: '80%' }} size="small" variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    style={{ width: '80%' }}
                                    name="passwordBaru"
                                    type={this.state.showPasswordBaru ? 'text' : 'password'}
                                    value={this.state.password}
                                    onChange={this.handleChangePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                //onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.showPasswordBaru ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>

                        </Grid>

                    </Grid>

                    <p></p>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={4} style={{ display: 'inline-block' }}>
                            <div >
                                <AiFillLock style={{top:'2px', position:'relative'}}/>&nbsp; Konfirmasi Password Baru
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl sx={{ width: '80%' }} size="small" variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    name="passwordBaruKonfirm"
                                    style={{ width: '80%' }}
                                    //size="medium"
                                    //id="outlined-adornment-password"
                                    type={this.state.showPasswordBaruKonfirm ? 'text' : 'password'}
                                    value={this.state.passwordBaru}
                                    onChange={this.handleChangePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPasswordKonfirm}
                                                //onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.showPasswordBaruKonfirm ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>

                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={4} style={{ display: 'inline-block' }}>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" onClick={this.handleSavePassword}>Ubah Password</Button>
                        </Grid>
                    </Grid>


                </Box>
            </Container>


        );
    }
}
export default UserProfile;
