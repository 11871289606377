import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import moment from 'moment';
import Select from 'react-select';
import React, { Component } from 'react';
import { AiOutlineIdcard } from "react-icons/ai";
import { BsGearWideConnected, BsPersonCheck, BsPersonCheckFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { ImBin, ImCalendar, ImCross, ImFloppyDisk, ImListNumbered, ImLocation, ImManWoman, ImPhone, ImPlay3 } from 'react-icons/im';
import { MdAlternateEmail } from "react-icons/md";
import Swal from 'sweetalert2';
import APIWilayah from '../../../services/APIWilayah';
import authServices from '../../../services/authServices';
import APIButtonAkses from '../../../services/APIButtonAkses';


const isNumber = (str) => /^[0-9]*$/.test(str);

class CreateUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dt_roles: [],
            dt_wilayah: [],
            role: [],
            tk: [],

            blocking: false,
            dt_prov: [], provinsi: null, id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: false, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: false, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: null, id_des: 0, dis_des: false, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: null, id_rw: 0, dis_rw: false, depdagriRw: '',
            dt_rt: [], rt: null, id_rt: '', id_rt: 0, dis_rt: false, depdagriRt: '',
            hideProvinsi: 'none', hideKabupaten: 'none', hideKecamatan: 'none', hideKelurahan: 'none', hideRW: 'none', hideRT: 'none', hideInpuWilayah: 'none',
            hideIbuKandung: '',
            wilayahUser: [],
            newValues: [],
            nomer: 1,
            username: '', nik: '', nip: '', email: '', namaLengkap: '', alamat: '', noTelepon: '', password: 'Bkkbn@123456',
            disSave: true, smartphoneInput: 'none', captionPendata: 'none', statusNpwp: false, npwp: '', validasiData: false,
            // smartphone: false
            dataStatus: [
                { value: 'PNS', label: 'PNS' },
                { value: 'PPPK', label: 'PPPK' },
                { value: 'Bukan PNS', label: 'Bukan PNS' },
            ],
            golongStatus: [
                { value: '1', label: 'Golongan I' },
                { value: '2', label: 'Golongan II' },
                { value: '3', label: 'Golongan III' },
                { value: '4', label: 'Golongan IV' },
            ],
            namaIbuKandung: '', npwp: '', statusPekerjaan: '', valueStatus: '', valueGolongan: '', hideNpwp: 'none', no_rekening: '',
            tanggal_lahir: '', tempat_lahir: '',
            disButtonSave: false, jenis_kelamin: '',

            //Inputan Wilayah Alamat
            dt_prov2: [], provinsi2: null, id_provinsi2: 0, depdagriProv2: '', dis_prov2: false, auto_prov2: false,
            dt_kab2: [], kabupaten2: null, id_kabupaten2: 0, dis_kab2: false, depdagriKab2: '', auto_kab2: false,
            dt_kec2: [], kecamatan2: null, id_kecamatan2: 0, dis_kec2: false, depdagriKec2: '', auto_kec2: false,
            dt_des2: [], desa2: null, id_des2: 0, dis_des2: false, depdagriKel2: '', auto_des2: false,
            dt_rw2: [], rw2: '', id_rw2: 0, dis_rw2: false, depdagriRw2: '',
            dt_rt2: [], rt2: '', id_rt2: '', id_rt2: 0, dis_rt2: false, depdagriRt2: '', kodePos: '',
            value_priode: this.props.value_priode,
            flagProv: true,
            flagKab: true,
            flagKec: true,
            flagDes: true,
            disableUsername: false,
            optBank: [],
            kodebank: null,
            namabank: null,
            bank: null

        }
    }

    sel_bank = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                bank: e, kodebank: e.value, namabank: e.label
            }, () => {
            })


        }
        else {
            this.setState({
                bank: [], kodebank: null,
                namabank: null,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleSelectRole = (e) => {

        if (e) {
            this.setState({ role: e, hideInpuWilayah: '', tk: null, wilayahUser: [] })
            if (e.value == 2) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: true,
                    username: 'otomatis sesuai prefix'
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }

                    })
                })
            } else if (e.value == 28 || e.value == 29 || e.value == 30) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 2,
                            tingkatWilayah: "Kabupaten"
                        }],
                        tk: { value: 2, label: 'Kabupaten' }
                    })
                })
            } else if (e.value === 25) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            } else if (e.value === 26) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value === 27) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 1) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    smartphoneInput: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false,
                    // username: ''
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 15) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    smartphoneInput: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false,
                    // username: ''
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 10) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    smartphoneInput: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false,

                    // username: ''
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            } else if (e.value == 16) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 17) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 18) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: '',
                    hideRT: '',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 6,
                            tingkatWilayah: "RT"
                        }],
                        tk: { value: 6, label: 'RT' }
                    })
                })
            } else if (e.value == 5) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: '',
                    hideRT: '',
                    smartphoneInput: 'none',
                    captionPendata: '',
                    hideIbuKandung: '',
                    disableUsername: false,
                    // username: ''
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 6,
                            tingkatWilayah: "RT"
                        }],
                        tk: { value: 6, label: 'RT' }
                    })
                })
            } else if (e.value == 3) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: true,
                    username: 'otomatis sesuai prefix'
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: "Kecamatan" }
                    })
                })
            } else if (e.value == 4) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: false,
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: true,
                    username: 'otomatis sesuai prefix'
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            } else if (e.value == 6) {
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: true,
                    username: 'otomatis sesuai prefix'
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 7) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 8) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 9) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 2,
                            tingkatWilayah: "Kabupaten"
                        }],
                        tk: { value: 2, label: 'Kabupaten' }
                    })
                })
            } else if (e.value == 10) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideRT: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 11) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideRT: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 12) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 2,
                            tingkatWilayah: "Kabupaten"
                        }],
                        tk: { value: 2, label: 'Kabupaten' }
                    })
                })
            } else if (e.value == 13) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 14) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: 'none',
                    hideRT: 'none',
                    smartphoneInput: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: '',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            } else if (e.value == 20) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: 'none',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 1,
                            tingkatWilayah: "Provinsi"
                        }],
                        tk: { value: 1, label: 'Provinsi' }
                    })
                })
            } else if (e.value == 21) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: 'none',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 2,
                            tingkatWilayah: "Kabupaten"
                        }],
                        tk: { value: 2, label: 'Kabupaten' }
                    })
                })
            } else if (e.value == 22) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: 'none',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 3,
                            tingkatWilayah: "Kecamatan"
                        }],
                        tk: { value: 3, label: 'Kecamatan' }
                    })
                })
            } else if (e.value == 23) {
                if (this.state.username === 'otomatis sesuai prefix') {
                    this.setState({ username: '' })
                }
                this.setState({
                    hideProvinsi: '',
                    hideKabupaten: '',
                    hideKecamatan: '',
                    hideKelurahan: '',
                    hideRW: 'none',
                    smartphoneInput: 'none',
                    hideRT: 'none',
                    captionPendata: 'none',
                    hideIbuKandung: 'none',
                    disableUsername: false
                }, () => {
                    this.setState({
                        dt_wilayah: [{
                            id: 4,
                            tingkatWilayah: "Kelurahan"
                        }],
                        tk: { value: 4, label: 'Kelurahan' }
                    })
                })
            }
        } else {
            this.setState({
                role: [],
                hideInpuWilayah: 'none',
                hideProvinsi: 'none',
                hideKabupaten: 'none',
                hideKecamatan: 'none',
                hideKelurahan: 'none',
                hideRW: 'none',
                hideRT: 'none',
                smartphoneInput: 'none',
                captionPendata: 'none',
                hideIbuKandung: '',
                wilayahUser: [],
                tk: null,
                dt_wilayah: [],
                disableUsername: false
            })
        }
    }

    handleSelectTK = (e) => {
        this.setState({ tk: e })
    }

    handleSelectStatus = (e) => {
        if (e) {
            this.setState({
                statusPekerjaan: e,
                statusPekerjaannya: e.label
            })
            if (e.value === "Bukan PNS") {
                this.setState({
                    valueGolongannya: '',
                    valueGolongan: '',
                    nip: ''
                })
            } else if (e.value === "PPPK") {
                this.setState({
                    valueGolongannya: '',
                    valueGolongan: ''
                })
            }
        } else {
            this.setState({
                statusPekerjaannya: null
            })
        }
    }

    handleSelectGolongan = (e) => {
        this.setState({
            valueGolongan: e,
            valueGolongannya: e.value
            // statusPekerjaan: e.label
        })
    }

    getBank = () => {
        APIButtonAkses.get('transaksi/getRefBank?periode=' + this.state.value_priode)
            .then((res) => {

                this.setState({
                    optBank: res.data,
                    blocking: false
                })
            })
    }

    getRole = (e) => {
        authServices
            .get("/roles/list?periodeSensus=" + this.state.value_priode)
            .then((res) => {
                this.setState({ blocking: false });
                if (res.status === 200) {
                    this.setState({
                        dt_roles: res.data
                    }, () => {
                        const role = JSON.parse(localStorage.getItem('authority'))[0].attributes.role
                        if (role === 'Supervisor') {
                            this.setState({
                                dt_roles: []
                            })
                        } else if (role === 'Manager Pengelolaan' || role === 'Manager Data') {
                            const manager = [res.data[3], res.data[4], res.data[9], res.data[13], res.data[17]]
                            this.setState({
                                dt_roles: manager
                            })
                        } else if (role === 'Admin Kabupaten') {
                            const manager = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[9], res.data[12], res.data[13]]
                            this.setState({
                                dt_roles: manager
                            })
                        } else if (role === 'Admin Provinsi') {
                            const manager = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[8], res.data[9], res.data[10], res.data[11], res.data[12], res.data[13], res.data[14], res.data[15], res.data[16], res.data[17]]

                            this.setState({
                                dt_roles: manager
                            })
                        } else if (role === 'Pengumpul Data Verval' || role === 'Pengolah Data Verval' || role === 'Verifikator Data Verval') {
                            const manager = [res.data[20], res.data[21], res.data[22]]
                            this.setState({
                                dt_roles: manager
                            })
                        } else {
                            this.setState({
                                dt_roles: res.data
                            })
                        }
                    })
                }
            })
            .catch((error) => {
                this.setState({ blocking: false });

            });
    }

    getTingkatWilayah = (e) => {
        // authServices
        //     .get("/roles/wilayah")
        //     .then((res) => {
        //         this.setState({blocking: false});
        //         if (res.status === 200) {
        //             this.setState({
        //                 dt_wilayah: res.data
        //             }, () => {
        //             })
        //         }
        //     })
        //     .catch((error) => {
        //         this.setState({blocking: false});
        //         
        //     });
    }

    preventCopyPaste = (e) => {
        e.preventDefault();
    }

    handleChangeUsername = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9_]/g, '')
        });
    }
    
    handleChangeEmail = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.@ ]/g, '')
        });
    }

    handleChangeKelamin = (e) => {
        this.setState({
            jenis_kelamin: e.target.value
        });
    }

    handleChangeTempatLahir = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeNamaBank = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeAlamat = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeRW = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeRT = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z_0-9-.' ]/g, '')
        });
    }

    handleChangeForm = (e) => {
        if (e.target.name === 'noTelepon' || e.target.name === 'no_rekening' || e.target.name === 'npwp' || e.target.name === 'kodePos' || e.target.name === 'nik') {
            if (isNumber(e.target.value)) {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    handleChangeNpwp = (e) => {
        if (e.target.checked) {
            this.setState({ hideNpwp: false, statusNpwp: true })
        } else {
            this.setState({ hideNpwp: 'none', npwp: '', statusNpwp: false })
        }
    }

    handleChangeValidasi = (e) => {
        if (e.target.checked) {
            this.setState({ validasiData: true })
        } else {
            this.setState({ validasiData: false })
        }
    }

    getBackend = (formData) => {
        // 
        this.setState({ disButtonSave: true, blocking: true })
        authServices.post("user/createuser", formData)
            .then((res) => {
                this.setState({ blocking: false, disButtonSave: false });

                if (res.data.status == '400') {
                    Swal.fire(
                        'Peringatan !',
                        res.data['create-user'] + ', Mohon Hubungi Administrator',
                        'info'
                    )
                } else if (res.status === 400) {
                    Swal.fire(
                        'Peringatan !',
                        res.data.message,
                        'info'
                    )
                } else if (res.status === 200) {
                    Swal.fire(
                        'Berhasil Simpan !',
                        'User berhasil di tambahkan.',
                        'success'
                    ).then(
                        // window.location.href = "/user";
                        this.props.handleOpenCloseAddUser(null, null)
                    );
                }
            })
            .catch((error) => {
                Swal.fire(
                    'Gagal Simpan !',
                    error.response.data.message,
                    'error'
                )
                this.setState({ blocking: false, disButtonSave: false });

            });
    }

    refreshPage = (e) => {
        window.location.href = "/user";
    }

    handleSaveProfil = (e) => {
        console.log(this.state.no_rekening?.lengthing)
        if (this.state.jenis_kelamin <= 0) {
            Swal.fire(
                'Peringatan !',
                'Mohon Pilih Jenis Kelamin !',
                'info'
            )
            return false;
        }
        if (this.state.bank === null && this.state.no_rekening?.length === 0) {
            Swal.fire(
                'Peringatan !',
                'No Rekening Wajib Di Isi Apabila Anda Mengisi Nama Bank !',
                'info'
            )
            return false;
        }
        if (this.state.no_rekening?.length > 0 && this.state.bank === null) {
            Swal.fire(
                'Peringatan !',
                'Nama Bank Wajib Di Isi Apabila Anda Mengisi Nomor Rekening !',
                'info'
            )
            return false;
        }

        if (this.state.statusPekerjaan === '') {
            Swal.fire(
                'Peringatan !',
                'Status Pekerjaan Wajib diisi.',
                'info'
            )
            return false;
        } else {
            if (this.state.statusPekerjaannya === "PNS" && (this.state.nip === "" || this.state.valueGolongannya === '' || this.state.valueGolongannya === 0)) {
                Swal.fire(
                    'Peringatan !',
                    'PNS Wajib Mengisi NIP Dan Gologan .',
                    'error'
                )
                return false;
            }
            // else if(this.state.statusPekerjaannya === "PPPK" && this.state.nip === ""){
            //     Swal.fire(
            //         'Peringatan !',
            //         'PPPK Wajib Mengisi NIP.',
            //         'error'
            //     )
            //     return false;
            // }
        }

        if (this.state.statusPekerjaannya === null || this.state.statusPekerjaannya?.length === 0) {
            Swal.fire(
                'Peringatan !',
                'Status Pekerjaan Wajib diisi.',
                'info'
            )
            return false;
        }

        if (this.state.provinsi2 === null || Object.keys(this.state.provinsi2).length === 0 ||
            this.state.kabupaten2 === null || Object.keys(this.state.kabupaten2).length === 0 ||
            this.state.kecamatan2 === null || Object.keys(this.state.kecamatan2).length === 0 ||
            this.state.desa2 === null || Object.keys(this.state.desa2).length === 0 ||
            this.state.alamat === '' || this.state.kodePos === 0 || this.state.kodePos === '' ||
            this.state.rt2 === '' || this.state.rw2 === ''
        ) {
            Swal.fire(
                'Peringatan !',
                'Alamat Dan Wilayah Tidak Boleh Kosong',
                'info'
            )
            return false;
        }

        if (this.state.kodePos.length !== 5) {
            Swal.fire(
                'Info!',
                'Mohon isi kode pos minimal 5 angka, sesuai dengan kode pos daerah.',
                'info'
            );
            return false
        }

        if (this.state.tanggal_lahir === '' || this.state.tempat_lahir === '') {
            Swal.fire(
                'Peringatan !',
                'Mohon Isi Tanggal Lahir & Tempat Lahir !',
                'info'
            )
            return false;
        }


        if (this.state.hideNpwp === false && this.state.npwp === "") {
            Swal.fire(
                'Peringatan !',
                'Npwp Wajib Di Isi Apabila Anda Mempunyai NPWP !',
                'info'
            )
            return false;
        }

        if (this.state.wilayahUser?.length === 0) {
            Swal.fire(
                'Peringatan !',
                'Wilayah Kerja Tidak Boleh Kosong.',
                'info'
            )
            return false;
        }

        if (this.state.role.value == 5) {
            if (this.state.statusPekerjaan == '') {
                Swal.fire(
                    'Peringatan !',
                    'Status Pekerjaan untuk pendata Harus Diisi.',
                    'info'
                )
                return false;
            }
        }

        if (this.state.role.value == 6) {
            if (this.state.statusPekerjaan == '') {
                Swal.fire(
                    'Peringatan !',
                    'Status Pekerjaan untuk Manager Data Harus Diisi.',
                    'info'
                )
                return false;
            }
        }

        if (this.state.validasiData === false) {
            Swal.fire(
                'Peringatan !',
                'Mohon Ceklis Bahwa Anda Menyetujui Bahwa Anda Dapat Mempertanggungjawabkan Data Yang Anda Input.',
                'info'
            )
            return false;
        }

        // if(this.state.role.value === 2 || this.state.role.value === 3 || this.state.role.value === 4 || this.state.role.value === 6 ){
        //     if(this.state.username !== ''){
        //         return false
        //     }
        // } else {
        //     if(this.state.username === ''){
        //         Swal.fire(
        //             'Peringatan !',
        //             'Username wajib diisi',
        //             'info'
        //         )
        //         return false;
        //     }
        // }

        if (
            this.state.username == '' ||
            this.state.namaLengkap == '' ||
            this.state.nik == '' ||
            this.state.alamat == '' ||
            this.state.email == '' ||
            // this.state.nip == '' ||
            // this.state.noTelepon == '' ||
            this.state.tk == []
        ) {
            Swal.fire(
                'Peringatan !',
                'Mohon Isi Semua Kolom Yang Tersedia.',
                'info'
            )
            return false;
        }
        this.setState({
            dataFormat: {
                namalengkap: this.state.namaLengkap,
                email: this.state.email,
                notelepon: this.state.noTelepon,
                roleid: this.state.role.value,
                alamat: this.state.alamat,
                nik: this.state.nik,
                password: this.state.password,
                nip: this.state.nip,
                isactive: true,
                istemporary: true,
                periodesensus: this.state.value_priode,
                smartphone: this.state.smartphone,
                tingkatwilayahid: this.state.tk.value,
                namaIbuKandung: this.state.namaIbuKandung,
                npwp: this.state.npwp,
                statusPekerjaan: this.state.statusPekerjaannya,
                createdby: this.state.createdby,
                golongan: this.state.valueGolongannya,
                status_npwp: this.state.statusNpwp,
                userWilayah: this.state.wilayahUser,
                flagFirstLogin: true,
                username: this.state.username,
                bank: this.state.kodebank,
                no_rekening: this.state.no_rekening,
                tanggal_lahir: new Date(this.state.tanggal_lahir.toLocaleString({ timeZone: "Asia/Jakarta" })).toISOString(),
                tempat_lahir: this.state.tempat_lahir,
                kode_pos: parseInt(this.state.kodePos),
                alamat_provinsi: this.state.provinsi2.value,
                alamat_kabupaten: this.state.kabupaten2.value,
                alamat_kecamatan: this.state.kecamatan2.value,
                alamat_kelurahan: this.state.desa2.value,
                alamat_rw: this.state.rw2,
                alamat_rt: this.state.rt2,
                jenis_kelamin: parseInt(this.state.jenis_kelamin)
            }
        }, function () {
            let dataOri = this.state.dataFormat
            if (this.state.role.value === 10) {
                dataOri = { ...dataOri, tingkatwilayahid: 6 }
            }
            // 
            this.getBackend(dataOri)
        })
    }

    getData = () => {
        this.setState(
            {
                a: ''
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setProv2();
                this.setKab2(parseInt(rest[0].attributes.user.wilProvinsi.id_provinsi));
                this.setKec2(parseInt(rest[0].attributes.user.wilKabupaten.id_kabupaten));
                this.setDes2(parseInt(rest[0].attributes.user.wilKecamatan.id_kecamatan));
            }
        )
    }

    componentDidMount() {
        this.getData();
        this.getBank()
        this.getRole();
        this.getTingkatWilayah();
        if (this.state.role) {
            this.setState({
                tampil: 'visible'
            })
        } else {
            this.setState({
                tampil: 'none'
            })
        }
        const rest = JSON.parse(localStorage.getItem("authority"));
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
            const nm = rest[0].attributes.user.TingkatWilayahID
            const createdby = rest[0].attributes.user_name
            this.setState({
                createdby: createdby
            })

            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                        // this.setProv2(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }

                if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                    // this.setProv2(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    // this.setKab2(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                }
                if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                    // this.setKec2(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                    // this.setDes2(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            const createdby = rest[0].attributes.user_name
            this.setProv(null);
            this.setProv2(null);
        }
    }

    componentDidUpdate = () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        if (this.state.dt_prov2.length > 0 && this.state.flagProv === true) {
            const newdatanya = this.state.dt_prov2.filter(item => item.value === parseInt(rest[0].attributes.user.wilProvinsi.id_provinsi))
            this.setState({
                provinsi2: newdatanya.length > 0 ? newdatanya[0] : null, flagProv: false
            })
        }
        if (this.state.dt_kab2.length > 0 && this.state.flagKab === true) {
            const newdatanya = this.state.dt_kab2.filter(item => item.value === parseInt(rest[0].attributes.user.wilKabupaten.id_kabupaten))
            this.setState({
                kabupaten2: newdatanya.length > 0 ? newdatanya[0] : null, flagKab: false
            })
        }
        if (this.state.dt_kec2.length > 0 && this.state.flagKec === true) {
            const newdatanya = this.state.dt_kec2.filter(item => item.value === parseInt(rest[0].attributes.user.wilKecamatan.id_kecamatan))
            this.setState({ kecamatan2: newdatanya.length > 0 ? newdatanya[0] : null, flagKec: false })
        }
        // if (this.state.dt_des2.length > 0 && this.state.flagDes === true) {
        //     const newdatanya = this.state.dt_des2.filter(item => item.value === parseInt(this.state.datauser.alamat_kelurahan))
        //     this.setState({ desa2: newdatanya.length > 0 ? newdatanya[0] : null, flagDes: false })
        // }
    }
    //API Wilayah
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            // 
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            // 
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            // 
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        // 
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {},
            }
                ,
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                rt: null, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_tanggal_lahir = (e) => {
        if (e) {
            this.setState({
                tanggal_lahir: e.target.value,
            });
        } else {
            this.setState({
                tanggal_lahir: ''
            })
        }
    }

    hapus = (e, row) => {
        this.setState({
            blocking: false,
            wilayahUser: this.state.wilayahUser.filter(item => item !== row)
        });
    }

    resetWilayah = (e) => {
        this.setState({
            wilayahUser: []
        })
    }

    handleSimpanWilayah = (e) => {
        this.setState({
            disSave: false
        })
        if (this.state.role.value === 10) {
            const newData = []
            APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + (this.state.desa.value === null ? 0 : this.state.desa.value) + '&periodeSensus=' + this.state.value_priode)
                .then(response => {
                    response.data.forEach(elRw => {
                        APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + elRw.id_rw + '&periodeSensus=' + this.state.value_priode)
                            .then(responseRt => {
                                responseRt.data.forEach(elRt => {
                                    const obj = {
                                        'id': this.state.nomer,
                                        'id_provinsi': (this.state.provinsi == null ? 0 : this.state.provinsi.value),
                                        'nama_provinsi': (this.state.provinsi == null ? '-' : this.state.provinsi.label),
                                        'id_kabupaten': (this.state.kabupaten == null ? 0 : this.state.kabupaten.value),
                                        'nama_kabupaten': (this.state.kabupaten == null ? '-' : this.state.kabupaten.label),
                                        'id_kecamatan': (this.state.kecamatan == null ? 0 : this.state.kecamatan.value),
                                        'nama_kecamatan': (this.state.kecamatan == null ? '-' : this.state.kecamatan.label),
                                        'id_kelurahan': (this.state.desa == null ? 0 : this.state.desa.value),
                                        'nama_kelurahan': (this.state.desa == null ? '-' : this.state.desa.label),
                                        'id_rt': (elRt.id_rt == null ? 0 : elRt.id_rt),
                                        'nama_rt': (elRt.nama_rt == null ? 0 : elRt.nama_rt),
                                        'nama_rw': (elRw.nama_rw == null ? 0 : elRw.nama_rw),
                                        'id_rw': (elRt.id_rw == null ? 0 : elRt.id_rw)
                                    };
                                    newData.push(obj);
                                })
                                this.setState({
                                    wilayahUser: newData,
                                });
                            })
                    })
                })
        } else {
            const newData = this.state.wilayahUser
            // Buat nambah kebawah
            // const newData = []
            const obj = {
                'id': this.state.nomer,
                'id_provinsi': (this.state.provinsi == null ? 0 : this.state.provinsi.value),
                'nama_provinsi': (this.state.provinsi == null ? '-' : this.state.provinsi.label),
                'id_kabupaten': (this.state.kabupaten == null ? 0 : this.state.kabupaten.value),
                'nama_kabupaten': (this.state.kabupaten == null ? '-' : this.state.kabupaten.label),
                'id_kecamatan': (this.state.kecamatan == null ? 0 : this.state.kecamatan.value),
                'nama_kecamatan': (this.state.kecamatan == null ? '-' : this.state.kecamatan.label),
                'id_kelurahan': (this.state.desa == null ? 0 : this.state.desa.value),
                'nama_kelurahan': (this.state.desa == null ? '-' : this.state.desa.label),
                'id_rt': (this.state.rt == null ? 0 : this.state.rt.value),
                'nama_rt': (this.state.rt == null ? '-' : this.state.rt.label),
                'nama_rw': (this.state.rw == null ? '-' : this.state.rw.label),
                'id_rw': (this.state.rw == null ? 0 : this.state.rw.value)
            };

            if (this.state.tk.value === 1 && this.state.provinsi === null) {
                Swal.fire(
                    'Wilayah Provinsi Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 2 && this.state.kabupaten === null) {
                Swal.fire(
                    'Wilayah Kabupaten Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 3 && this.state.kecamatan === null) {
                Swal.fire(
                    'Wilayah Kecamatan Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 4 && this.state.desa === null) {
                Swal.fire(
                    'Wilayah Kelurahan Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 5 && this.state.rw === null) {
                Swal.fire(
                    'Wilayah RW Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else if (this.state.tk.value === 6 && this.state.rt === null) {
                Swal.fire(
                    'Wilayah RT Tidak Boleh Kosong !',
                    'Mohon Isi Semua Kolom Wilayah Yang Tersedia.',
                    'info'
                )
                return false
            } else {
                if (this.state.tk.value === 6) {
                    const dataSelect = newData.find(el => el.id_kelurahan === this.state.desa.value)
                    if (this.state.wilayahUser?.length === 0 || dataSelect !== undefined) {
                        const dataRt = newData.find(el => el.id_rt === this.state.rt.value)
                        if (dataRt === undefined) {
                            newData.push(obj);
                        } else {
                            Swal.fire(
                                'Wilayah RT Telah Di Pilih !',
                                'Mohon Ganti RT Untuk Melanjutkan',
                                'info'
                            )
                        }
                    } else {
                        Swal.fire(
                            'Pendata Tidak Boleh Berbeda Kelurahan !',
                            'Wilayah Pendata Hanya Boleh Di Kelurahan Yang Sama.',
                            'info'
                        )
                    }
                } else {
                    const dataSelect = newData.find(el => el.provinsi === this.state.provinsi.value)
                    if (this.state.wilayahUser?.length === 0 || dataSelect !== undefined) {
                        newData.push(obj);
                    } else {
                        Swal.fire(
                            'Role Ini Hanya Boleh Memilih 1 Wilayah !',
                            '',
                            'info'
                        )
                    }
                }
            }
            this.setState({
                wilayahUser: newData,
                nomer: this.state.nomer++
            });
        }
    }

    handleToggle = (e) => {
        if (e.target.checked == true) {
            this.setState({
                smartphone: true
            })
        } else {
            this.setState({
                smartphone: false
            })
        }
    };

    //API Wilayah
    setProv2 = (id2) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj2 = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData2.push(obj2);
                    });

                    //Value Sesuai Login
                    // const rest = JSON.parse(localStorage.getItem("authority"));
                    // const x = newData2.filter(el => el.value === parseInt(id2))
                    // this.setState({
                    //     provinsi2: x, id_provinsi2: x.value, depdagriProv2: x.kode,
                    // })
                    // this.setKab2(id2,rest[0].attributes.user.wilKabupaten.id_kabupaten)

                    this.setState({ dt_prov2: newData2 },
                        () => {
                            if (id2 !== null && this.state.auto_prov2 === true) {
                                const vl = this.state.dt_prov2.filter(item => parseInt(item.value) === parseInt(id2))
                                this.sel_prov2(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab2(idProv2, idKab2) {
        this.setState({ blocking: true });
        this.setState({ dt_kab2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv2 + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj2 = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData2.push(obj2);
                });

                // const rest = JSON.parse(localStorage.getItem("authority"));
                // const x = newData2.filter(el => el.value === idKab2)
                // this.setState({
                //     kabupaten2: x, id_kabupaten2: x.value, depdagriKab2: x.kode,
                // })
                // this.setKec2(idKab2,rest[0].attributes.user.wilKecamatan.id_kecamatan)

                this.setState({ dt_kab2: newData2, blocking: false },
                    () => {
                        if (idKab2 !== null && this.state.auto_kab2 === true) {
                            const vl = this.state.dt_kab2.filter(item => parseInt(item.value) === parseInt(idKab2))
                            // 
                            this.sel_kab2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec2(idKab2, idKec2) {
        this.setState({ blocking: true });
        this.setState({ dt_kec2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab2 + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj2 = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData2.push(obj2);
                });

                // const rest = JSON.parse(localStorage.getItem("authority"));
                // const x = newData2.filter(el => el.value === idKec2)
                // this.setState({
                //     kecamatan2: x, id_kecamatan2: x.value, depdagriKec2: x.kode,
                // })
                // this.setDes2(idKec2,rest[0].attributes.user.wilKelurahan.id_kelurahan)

                this.setState({ dt_kec2: newData2, blocking: false },
                    () => {
                        if (idKec2 !== null && this.state.auto_kec2 === true) {
                            const vl = this.state.dt_kec2.filter(item => parseInt(item.value) === parseInt(idKec2))
                            // 
                            this.sel_kec2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes2(idKec2, idDes2) {
        this.setState({ blocking: true });
        this.setState({ dt_des2: [] });
        var newData2 = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec2 + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj2 = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData2.push(obj2);
                });

                // const x = newData2.filter(el => el.value === idDes2)
                // this.setState({
                //     desa2: x, id_des2: x.value, depdagriKel2: x.kode,
                // })

                this.setState({ dt_des2: newData2, blocking: false },
                    () => {
                        if (idDes2 !== null && this.state.auto_des2 === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes2))
                            // 
                            this.sel_des2(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    // setRW2(id2) {
    //     this.setState({ blocking: true });
    //     this.setState({ dt_rw2: [] });
    //     var newData2 = [];
    //     APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=' + this.state.value_priode2)
    //         .then(res => {
    //             res.data.forEach(data => {
    //                 const obj2 = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
    //                 newData2.push(obj2);
    //             });
    //             this.setState({ dt_rw2: newData2, blocking: false });
    //         }).catch((error) => {
    //             this.setState({ blocking: false });
    //         });
    // }

    // get rt
    // setRT2(id2) {
    //     this.setState({ blocking: true });
    //     this.setState({ dt_rt2: [] });
    //     var newData2 = [];
    //     APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id2)
    //         .then(res => {
    //             res.data.forEach(data => {
    //                 const obj2 = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
    //                 newData2.push(obj2);
    //             });
    //             this.setState({ dt_rt2: newData2, blocking: false });
    //         }).catch((error) => {
    //             this.setState({ blocking: false });
    //         });
    // }

    sel_prov2 = (e) => {
        if (e) {
            this.setState({
                provinsi2: e, id_provinsi2: e.value, depdagriProv2: e.kode,
            },
                () => {
                    if (this.state.id_provinsi2 && this.state.auto_kab2 === false) {
                        // 
                        this.setState({
                            dis_kab2: false, kabupaten2: null, kecamatan2: null, desa2: null, rw2: null,
                            rt2: null, dis_kec2: true, dis_des2: true, dis_rw2: true, dis_rt2: true
                        })
                        this.setKab2(this.state.id_provinsi2, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                provinsi2: null, kabupaten2: null, kecamatan2: null, desa2: null, rw2: null, rt2: null,
                dis_kab2: true, dis_kec2: true, dis_des2: true, dis_rw2: true, dis_rt2: true,
                id_provinsi2: 0, id_kabupaten2: 0, id_kecamatan2: 0, id_des2: 0, id_rw2: 0, id_rt2: 0,
                sendDatas: {},
            }
                ,
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab2 = (e) => {
        if (e) {
            this.setState({
                kabupaten2: e, id_kabupaten2: e.value, depdagriKab2: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten2 && this.state.auto_kec2 === false) {
                        this.setState({
                            dis_kec2: false, kecamatan2: null, desa2: null, rw2: null, rt2: null,
                            dis_des2: true, dis_rw2: true, dis_rt2: true
                        })
                        this.setKec2(this.state.id_kabupaten2, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                kabupaten2: null, kecamatan2: null, desa2: null, rw2: null, rt2: null,
                dis_kec2: true, dis_des2: true, dis_rw2: true, dis_rt2: true,
                id_kabupaten2: 0, id_kecamatan2: 0, id_des2: 0, id_rw2: 0, id_rt2: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec2 = (e) => {
        if (e) {
            this.setState({
                kecamatan2: e, id_kecamatan2: e.value, depdagriKec2: e.kode,
            },
                () => {
                    if (this.state.id_kecamatan2 && this.state.auto_des2 === false) {
                        this.setState({
                            desa2: null, rw2: null,
                            rt2: null, dis_des2: false, dis_rw2: true, dis_rt2: true
                        })
                        this.setDes2(this.state.id_kecamatan2, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            // this.handleSelectRole()
            this.setState({
                kecamatan2: null, desa2: null, rw2: null, rt2: null,
                dis_des2: true, dis_rw2: true, dis_rt2: true,
                id_kecamatan2: 0, id_des2: 0, id_rw2: 0, id_rt2: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des2 = (e) => {
        if (e) {
            this.setState({
                desa2: e, id_des2: e.value, depdagriKel2: e.kode, rw2: null, rt2: null, dis_rw2: false, dis_rt2: true,
            },
                () => {
                    if (this.state.id_des2) {
                        // this.setRW2(this.state.id_des2);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            // this.handleSelectRole()
            this.setState({
                desa2: null, rw2: null, rt2: null, dis_rw2: true, dis_rt2: true,
                id_des2: 0, id_rw2: 0, id_rt2: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    // sel_rw2 = (e) => {
    //     if (e) {
    //         this.setState({
    //             rw2: e, id_rw2: e.value, depdagriRw2: e.kode, rt2: null, dis_rt2: false,
    //         },
    //             () => {
    //                 if (this.state.id_rw2) {
    //                     this.setRT2(this.state.id_rw2);
    //                     // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
    //                 }
    //             })
    //     }
    //     else {
    //         // this.handleSelectRole()
    //         this.setState({
    //             rw2: null, rt2: null, dis_rt2: true, id_rw2: 0, id_rt2: 0,
    //         },
    //             () => {
    //                 if (e === null) {
    //                     // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
    //                 }
    //             }
    //         )
    //     }
    // }

    // sel_rt2 = (e) => {
    //     if (e) {
    //         this.setState({
    //             rt2: e, id_rt2: e.value, depdagriRt2: e.kode,
    //         },
    //             () => {
    //                 if (e) {
    //                     // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
    //                 }
    //             }
    //         )
    //     }
    //     else {
    //         // this.handleSelectRole()
    //         this.setState({
    //             rt2: null, id_rt2: 0,
    //         },
    //             () => {
    //                 if (e === null) {
    //                     // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
    //                 }
    //             }
    //         )
    //     }
    // }
    //-----end wilayah
    render() {
        const roleUserLogin = +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID
        return (
            <div className='content'>
                <div className='title-content'><ImPlay3 className='icon-brd' />Tambah User</div>
                <div className='body-content'>
                    <Container>
                        <Box
                            sx={{
                                width: "100%"
                            }}
                            m={3}>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div >
                                        <FaUser />&nbsp; Username  <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="username"
                                        variant="outlined"
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        value={this.state.username}
                                        onChange={this.handleChangeUsername}
                                        onPaste={this.preventCopyPaste}
                                        onCopy={this.preventCopyPaste}
                                        disabled={this.state.disableUsername} />
                                    <FormControl style={{ display: this.state.captionPendata }}>
                                        <FormHelperText id="component-helper-text">
                                            Username Pendata akan diberikan prefix <a style={{ fontWeight: 'bold' }}>p.KodeProvinsi.KodeKabupaten.KodeKecamatan.KodeKelurahan</a>
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <BsPersonCheckFill />&nbsp; NIK <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        inputProps={
                                            { maxLength: 16 }
                                        }
                                        name="nik"
                                        variant="outlined"
                                        value={this.state.nik}
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid>
                            </Grid>
                            <p></p>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <AiOutlineIdcard />&nbsp; Nama Lengkap <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="namaLengkap"
                                        value={this.state.namaLengkap}
                                        variant="outlined"
                                        type="text"
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid>

                                {/* <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: this.state.hideIbuKandung
                                    }}>
                                    <div>
                                        <AiOutlineIdcard />&nbsp; Ibu Kandung
                                    </div>
                                </Grid>
                                <Grid item xs={4} style={{ display: this.state.hideIbuKandung }}>
                                    <TextField

                                        name="namaIbuKandung"
                                        value={this.state.namaIbuKandung}
                                        variant="outlined"
                                        type="text"
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid> */}

                                <Grid
                                    item
                                    xs={2}

                                >
                                    <div>
                                        <ImManWoman />
                                        &nbsp; Jenis Kelamin <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>

                                <Grid
                                    item xs={4}
                                >
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="jenis_kelamin"
                                            onChange={this.handleChangeKelamin}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="Laki-Laki" />
                                            <FormControlLabel value="2" control={<Radio />} label="Perempuan" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                            </Grid>
                            <p></p>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImCalendar />
                                        &nbsp; Tanggal Lahir <a style={{ color: 'red' }}>*</a>
                                    </div>

                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField name='tanggal_lahir' onChange={this.sel_tanggal_lahir} value={this.state.tanggal_lahir} inputProps={{ style: { height: "6px" }, max: moment(new Date()).format("YYYY-MM-DD") }} type="date" id="outlined-basic" isClearable />
                                    </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div >
                                        <ImLocation />&nbsp; Tempat Lahir <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="tempat_lahir"
                                        variant="outlined"
                                        value={this.state.tempat_lahir}
                                        type="text"
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeTempatLahir}
                                        onPaste={(e) => { e.preventDefault(); return false; }} />
                                </Grid>
                            </Grid>
                            <p></p>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImPhone />
                                        &nbsp; No. Telepon
                                    </div>

                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        inputProps={
                                            { maxLength: 13 }
                                        }
                                        name="noTelepon"
                                        variant="outlined"
                                        value={this.state.noTelepon}
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm} />
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div >
                                        <MdAlternateEmail />&nbsp; Email <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="email"
                                        variant="outlined"
                                        value={this.state.email}
                                        type="email"
                                        style
                                        ={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        onChange={this.handleChangeEmail} />
                                </Grid>
                            </Grid>
                            <p></p>
                            <p></p>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <AiOutlineIdcard />&nbsp; Nama Bank <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <Select options={this.state.optBank?.map(({ kode, bank }) => ({ value: kode, label: bank }))} isClearable onChange={this.sel_bank} value={this.state.bank} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <ImListNumbered />&nbsp; No Rekening <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="no_rekening"
                                        variant="outlined"
                                        style={{ width: '100%', bottom: '5px' }}
                                        value={this.state.no_rekening}
                                        size="small"
                                        onChange={this.handleChangeForm}
                                        inputProps={{ maxLength: 18 }}
                                    />
                                </Grid>
                            </Grid>
                            <p></p>
                            <fieldset>
                                <legend>ALAMAT (HARUS SESUAI KTP)</legend>
                                <p></p>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImLocation />&nbsp; Alamat <a style={{ color: 'red' }}>*</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            inputProps={{
                                                maxLength: 30,
                                            }}
                                            name="alamat"
                                            value={this.state.alamat}
                                            variant="outlined"
                                            type="text"
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeAlamat}
                                            onPaste={(e) => { e.preventDefault(); return false; }} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImListNumbered />
                                            &nbsp; Kode POS <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            inputProps={
                                                { maxLength: 5 }
                                            }
                                            name="kodePos"
                                            variant="outlined"
                                            value={this.state.kodePos}
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onBlur={(e) => {
                                                const value = e.target.value;
                                                if (value.length < 5) {
                                                    Swal.fire(
                                                        'Info!',
                                                        'Mohon isi kode pos minimal 5 angka, sesuai dengan kode pos daerah.',
                                                        'info'
                                                    );
                                                }
                                            }}
                                            onChange={this.handleChangeForm} />
                                    </Grid>
                                </Grid>
                                <p></p>

                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImLocation />
                                            &nbsp; Provinsi <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small" >
                                            <Select isDisabled={this.state.dis_prov2} options={this.state.dt_prov2} id="prov2" name="provinsi2" onChange={this.sel_prov2} value={this.state.provinsi2} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImLocation />
                                            &nbsp; Kabupaten <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            <Select options={this.state.dt_kab2} isClearable onChange={this.sel_kab2} value={this.state.kabupaten2} placeholder="Cari..." isDisabled={this.state.dis_kab2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <p></p>

                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImLocation />
                                            &nbsp; Kecamatan <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            <Select options={this.state.dt_kec2} isClearable onChange={this.sel_kec2} value={this.state.kecamatan2} placeholder="Cari..." isDisabled={this.state.dis_kec2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImLocation />
                                            &nbsp; Kelurahan <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            <Select options={this.state.dt_des2} isClearable onChange={this.sel_des2} value={this.state.desa2} placeholder="Cari..." isDisabled={this.state.dis_des2} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <p></p>

                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImLocation />
                                            &nbsp; RW <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <TextField
                                            required
                                            inputProps={
                                                { maxLength: 10 }
                                            }
                                            name="rw2"
                                            variant="outlined"
                                            value={this.state.rw2}
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeRW}
                                            onPaste={(e) => { e.preventDefault(); return false; }} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'inline-block'
                                        }}>
                                        <div>
                                            <ImLocation />
                                            &nbsp; RT <a style={{ color: 'red' }}>*</a>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <TextField
                                            required
                                            inputProps={
                                                { maxLength: 10 }
                                            }
                                            name="rt2"
                                            variant="outlined"
                                            value={this.state.rt2}
                                            style
                                            ={{
                                                width: '100%'
                                            }}
                                            size="small"
                                            onChange={this.handleChangeRT}
                                            onPaste={(e) => { e.preventDefault(); return false; }} />
                                    </Grid>
                                </Grid>
                                <p></p>
                            </fieldset>
                            <p></p>
                            <p></p>

                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>


                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <BsPersonCheck />
                                        &nbsp; Status
                                    </div>

                                </Grid>

                                <Grid item xs={4}>
                                    <Select
                                        name="status"
                                        value={this.state.statusPekerjaan}
                                        options={this.state.dataStatus}
                                        onChange={this.handleSelectStatus}
                                        isClearable
                                    />
                                </Grid>
                                {(this.state.statusPekerjaannya === 'PNS') ?
                                    <>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <BsPersonCheck />
                                                &nbsp; Golongan
                                            </div>

                                        </Grid>

                                        <Grid item xs={4}>
                                            <Select
                                                name="status"
                                                value={this.state.valueGolongan}
                                                options={this.state.golongStatus}
                                                onChange={this.handleSelectGolongan}
                                            />
                                        </Grid>
                                    </>
                                    :
                                    <></>
                                }

                                {(this.state.statusPekerjaannya === 'PNS' || this.state.statusPekerjaannya === "PPPK") ?
                                    <>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'inline-block'
                                            }}>
                                            <div>
                                                <BsPersonCheck />&nbsp; NIP
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                required
                                                inputProps={{
                                                    maxLength: 18,
                                                }}
                                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                                name="nip"
                                                variant="outlined"
                                                value={this.state.nip}
                                                style
                                                ={{
                                                    width: '100%'
                                                }}
                                                size="small"
                                                onChange={this.handleChangeForm} />
                                        </Grid></>
                                    :
                                    <></>
                                }
                            </Grid>
                            <FormControlLabel control={<Checkbox
                                onChange={this.handleChangeNpwp}
                            />} label="Apakah Anda Memiliki NPWP ?" />
                            <p></p>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: this.state.hideNpwp
                                    }}>
                                    <div>
                                        <AiOutlineIdcard />&nbsp; NPWP
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="npwp"
                                        value={this.state.npwp}
                                        variant="outlined"
                                        style
                                        ={{
                                            width: '100%',
                                            display: this.state.hideNpwp
                                        }}
                                        size="small"
                                        onChange={this.handleChangeForm}
                                        inputProps={{ maxLength: 16 }} />
                                </Grid>
                            </Grid>
                            <p></p>
                            <Divider />
                            <p></p>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div >
                                        <BsGearWideConnected />&nbsp; Role <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        sx={{
                                            width: '100%'
                                        }}

                                        size="small">
                                        <Select
                                            options={this.state.dt_roles.map(({ id, roleName }) => ({ value: id, label: roleName }))}
                                            // options={
                                            //     roleUserLogin === 1 ?
                                            //         this.state.dt_roles.map(({ id, roleName }) => ({ value: id, label: roleName }))
                                            //         :
                                            //         (this.state.dt_roles.filter(role => { return role.id !== 11 && role.id !== 12 && role.id !== 13 && role.id !== 14 && role.id !== 15 && role.id !== 16 && role.id !== 17 })
                                            //             .map(({ id, roleName }) => ({ value: id, label: roleName })))
                                            // }
                                            isClearable
                                            onChange={this.handleSelectRole}
                                            value={this.state.role}
                                            placeholder="Cari..."
                                            styles={{
                                                menuPortal: base => ({
                                                    ...base,
                                                    zIndex: 9999
                                                })
                                            }}
                                            menuPortalTarget={document.body} />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'inline-block'
                                    }}>
                                    <div>
                                        <BsPersonCheckFill />&nbsp; Tingkat Wilayah <a style={{ color: 'red' }}>*</a>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        sx={{
                                            width: '100%'
                                        }}
                                        size="small">
                                        <Select
                                            id='selectWil'
                                            options={this.state.dt_wilayah.map(({ id, tingkatWilayah }) => ({ value: id, label: tingkatWilayah }))}
                                            isClearable
                                            onChange={this.handleSelectTK}
                                            value={this.state.tk}
                                            placeholder="Cari..."
                                            styles={{
                                                menuPortal: base => ({
                                                    ...base,
                                                    zIndex: 9999
                                                })
                                            }}
                                            menuPortalTarget={document.body} />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: this.state.smartphoneInput
                                    }}
                                >
                                    <div>
                                        <ImPhone />
                                        &nbsp; Metode Pendataan Smartphone
                                    </div>

                                </Grid>

                                <Grid
                                    item xs={4}
                                    style={{
                                        display: this.state.smartphoneInput
                                    }}
                                >
                                    <FormGroup>
                                        <FormControlLabel control={<Switch
                                            onChange={this.handleToggle}
                                            value={this.state.smartphone}
                                            name='smartphoneCheck'
                                        />}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <p></p>
                            {/* State buat hide*/}
                            <Grid item xs={12} md={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                    <Grid style={{ display: this.state.hideProvinsi }} item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small" >
                                            Provinsi
                                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ display: this.state.hideKabupaten }} item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kabupaten
                                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ display: this.state.hideKecamatan }} item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kecamatan
                                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ display: this.state.hideKelurahan }} item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kelurahan
                                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ display: this.state.hideRW }} item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            RW
                                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ display: this.state.hideRT }} item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            RT
                                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <p></p>
                            </Grid>
                            <Grid style={{ display: this.state.hideInpuWilayah }}>
                                <Button variant="contained" onClick={this.handleSimpanWilayah}>Tambah Wilayah</Button>&nbsp;
                                <Button color="warning" variant="contained" onClick={this.resetWilayah}>Reset Wilayah</Button>
                                <p></p>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Provinsi</TableCell>
                                                <TableCell>Kabupaten</TableCell>
                                                <TableCell>Kecamatan</TableCell>
                                                <TableCell>Kelurahan</TableCell>
                                                <TableCell>RW</TableCell>
                                                <TableCell>RT</TableCell>
                                                <TableCell>Aksi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.wilayahUser.map((row) => (
                                                <TableRow
                                                    key={this.state.nomer++}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="left">{row.nama_provinsi}</TableCell>
                                                    <TableCell align="left">{row.nama_kabupaten}</TableCell>
                                                    <TableCell align="left">{row.nama_kecamatan}</TableCell>
                                                    <TableCell align="left">{row.nama_kelurahan}</TableCell>
                                                    <TableCell align="left">{row.nama_rw}</TableCell>
                                                    <TableCell align="left">{row.nama_rt}</TableCell>
                                                    <TableCell align="left">
                                                        <Button onClick={(e) => this.hapus(e, row)} className='btnTable' color='warning' variant="contained"><ImBin className='icon-brd' /></Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <p></p>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox
                                    onChange={this.handleChangeValidasi}
                                />} label="Data yang dimasukkan sudah benar dan dapat dipertanggung jawabkan" /><br></br>

                                <Button disabled={this.state.disSave || this.state.disButtonSave} onClick={this.handleSaveProfil} sx={{ marginRight: '5px' }} variant="contained"><ImFloppyDisk className='icon-brd' style={{ marginRight: '5px' }} /> Simpan</Button>
                                &nbsp;
                                <Button disabled={this.state.disButtonSave} onClick={this.props.handleOpenCloseAddUser} sx={{ marginRight: '5px' }} color='error' variant="contained"><ImCross className='icon-brd' style={{ marginRight: '5px' }} /> Batal</Button>
                            </Grid>
                        </Box>
                    </Container>
                </div>
            </div >
        )
    }
}

export default CreateUser;