import React, { Component } from 'react'
import { ImPlay3 } from 'react-icons/im';
import { Box, Grid } from '@mui/material';
import TableExpand from '../../commons/table/TableExpand';
import APIWilayah from '../../services/APIWilayah';

export default class TestTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blocking: false,
      defaultData: [],
      //---wilayah
      tingkatWilayahId: 0,
      roleId: 0,
      wilKabupaten: {},
      wilKecamatan: {},
      wilKelurahan: {}
      //---end wilayah
    }
  }

  componentDidMount = () => {
    const rest = JSON.parse(localStorage.getItem("authority"));
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const wilProvinsi = rest[0].attributes.user.wilProvinsi;
      const wilKabupaten = rest[0].attributes.user.wilKabupaten;
      const wilKecamatan = rest[0].attributes.user.wilKecamatan;
      const wilKelurahan = rest[0].attributes.user.wilKelurahan;
      const tw = +rest[0].attributes.user.TingkatWilayahID;
      const ri = +rest[0].attributes.user.roleID;
      this.setState({wilKabupaten, wilKecamatan, wilKelurahan, tingkatWilayahId: tw, roleId: ri})
      if(tw === 0){ //user pusat
        this.setState({ blocking: true });
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
        .then(res => {
          this.setState({ defaultData: res.data });
          this.setState({ blocking: false });
        }).catch((error) => {
          this.setState({ blocking: false });
        });
      }else{ //user provinsi
        const arr = [{
          id_provinsi: +wilProvinsi.id_provinsi,
          nama: wilProvinsi.nama_provinsi,
          kodeDepdagri: wilProvinsi.id_provinsi_depdagri
        }]
        this.setState({ defaultData: arr });
      }
    }
  }

  render() {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    return (
      <div className='content'>
        <div className='title-content'><ImPlay3 className='icon-brd' />Master Wilayah</div>
        <div className='body-content'>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{marginTop: '15px', marginBottom: '15px'}}>
            <Grid item xs={12} md={12}>
              <Box sx={{ width: '100%' }}>
                  <TableExpand 
                    defaultData={this.state.defaultData}
                    tingkatWilayahId={this.state.tingkatWilayahId} 
                    roleId={this.state.roleId} 
                    wilKabupaten={this.state.wilKabupaten}
                    wilKecamatan={this.state.wilKecamatan}
                    wilKelurahan={this.state.wilKelurahan}
                  />
              </Box>
            </Grid>
          </Grid>   
        </div>
      </div>
    )
  }
}