import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { QrReader } from 'react-qr-reader';
import { BrowserMultiFormatReader } from '@zxing/library';
import APIVerval from '../services/APIVerval';
import axios from 'axios';
import { Button, CssBaseline, TextField, Typography, Paper, Box, Grid, withStyles, Select, Dialog, DialogTitle, DialogContent, DialogContentText, MenuItem, DialogActions } from '@material-ui/core';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Tracking = (props) => {
  const [ciphertext, setCipherText] = useState('')
  const [result, setResult] = useState('')
  const [file, setFile] = useState(null)
  const [username, setUsername] = useState('')
  const [qrText, setQrText] = useState('')
  const [facingMode, setFacingMode] = useState('environment')
  const [disResult, setDisResult] = useState(false)
  const [blocking, setBlocking] = useState(false)
  const [openDialog, setopenDialog] = useState(false)
  const [nmr, setnmr] = useState()
  const [pengaduan, setpengaduan] = useState('')
  const [disnmr, setdisnmr] = useState(false)
  const [jumlahDibayar, setjumlahDibayar] = useState(0)
  const [jumlahDiterima, setjumlahDiterima] = useState(0)
  const [jumlahPajak, setjumlahPajak] = useState(0)

  const isNumber = (str) => /^[0-9]*$/.test(str);


  const [data, setData] = useState({})

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const bilne = ["", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sembilan", "sepuluh", "sebelas"];
  const terbilang = (angka) => {
    if (angka < 12) {
      return bilne[angka];
    } else if (angka < 20) {
      return terbilang(angka - 10) + " belas";
    } else if (angka < 100) {
      return terbilang(Math.floor(parseInt(angka) / 10)) + " puluh " + terbilang(parseInt(angka) % 10);
    } else if (angka < 200) {
      return "seratus " + terbilang(parseInt(angka) - 100);
    } else if (angka < 1000) {
      return terbilang(Math.floor(parseInt(angka) / 100)) + " ratus " + terbilang(parseInt(angka) % 100);
    } else if (angka < 2000) {
      return "seribu " + terbilang(parseInt(angka) - 1000);
    } else if (angka < 1000000) {
      return terbilang(Math.floor(parseInt(angka) / 1000)) + " ribu " + terbilang(parseInt(angka) % 1000);
    } else if (angka < 1000000000) {
      return terbilang(Math.floor(parseInt(angka) / 1000000)) + " juta " + terbilang(parseInt(angka) % 1000000);
    } else if (angka < 1000000000000) {
      return terbilang(Math.floor(parseInt(angka) / 1000000000)) + " milyar " + terbilang(parseInt(angka) % 1000000000);
    } else if (angka < 1000000000000000) {
      return terbilang(Math.floor(parseInt(angka) / 1000000000000)) + " trilyun " + terbilang(parseInt(angka) % 1000000000000);
    }
  }

  const handleScan = data => {
    // console.log(data)
    if (data) {
      setQrText(data)
      // handleClick()
    }
  }
  const handleOpenDialogfalse = (e) => {
    setopenDialog(false)
    setnmr()
    setpengaduan()
  }
  const handleOpenAduan = (e) => {
    if (data?.jumlah_aduan < 2) {
      setopenDialog(true)
    }else{
      Swal.fire({
        title: 'Info!',
        icon: 'Info',
        text: 'Jumlah Pengaduan Sudah Melebihi Batas, Mohon Menunggu!',
      }).then(() => {
        handleOpenDialogfalse()
      })
    }
  }

  useEffect(() => {
    if (qrText) {
      // console.log('run click', qrText)
      handleClick()
    }
  }, [qrText])

  // const cameraClick = () => {
  //   if(facingMode === 'environment'){
  //     setFacingMode('user')
  //   }else{
  //     setFacingMode('environment')
  //   }
  // }

  useEffect(() => {
    if (file) {
      try {
        const codeReader = new BrowserMultiFormatReader()
        const img = document.getElementById('img', 'pdf')
        codeReader.decodeFromImage(img).then((result) => {
          console.log(result)
          setQrText(result.text)
          // handleClick()
        }).catch((err) => {
          // console.error(err)
        })
      } catch (error) {
        // console.log('catch err')
      }
    }
  }, [file])


  const handleError = err => {
    console.error(err)
  }

  const handlenmr = (e) => {
    const re = /^[0-9]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value.length < 12) {
        setdisnmr(true)
      } else {
        setdisnmr(false)
      }

      setnmr(e.target.value)
    }
  }

  const handlePengaduan = (e) => {
    // console.log(e)
    setpengaduan(e.target.value)
  }

  const handleSutmitpengaduan = (e) => {

    const payload = {
      "user_id": data?.userID,
      "id_prov": data?.provinsi_id,
      "id_kab": data?.kabupaten_id,
      "id_kec": data?.kecamatan_id,
      "id_kel": data?.kelurahan_id,
      "user_name": data?.userName,
      "create_by": data?.userName,
      "no_telepon": nmr,
      "deskripsi": pengaduan,
      "kode_deskripsi": nmr === 'Nominal Tidak Sesuai' ? 1 : 2
    }
    console.log(payload)
    if (nmr && pengaduan) {
      Swal.fire({
        title: 'Apakah Anda Yakin?',
        text: "Anda mengadu tentang " + pengaduan + " dengan Kwitansi dan dengan Nomor Telpon " + nmr + "?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        APIVerval.post('portalpk/metodepembayaran/insertDataAduanPembayaran?', payload)
          .then(res => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Info!',
                icon: 'success',
                text: 'Data Berhasil Diterima, Mohon Menunggu Atas Permintaan Pengaduan ini',
              }).then(() => {
                handleOpenDialogfalse()
              })
            }
            console.log(res)

          })
      })
    } else {
      Swal.fire({
        title: 'Error!',
        icon: 'warning',
        // text: 'Jaringan bermasalah.',
        text: 'Mohon Diisi Column Tersedia',
      })
    }

  }

  const handleClick = (e) => {
    if (e) e.preventDefault()
    console.log(qrText, 'qr text')
    const arrText = qrText?.split(' ')
    const unicode = arrText ? arrText[0] : ''
    const userid = arrText ? arrText[1] : ''
    // console.log(arrText)
    // console.log(unicode, 'unicode')
    // console.log(userid, 'userid')
    setBlocking(true)
    setDisResult(false)
    APIVerval.get('portalpk/metodepembayaranpk/getRekapDetailPembayaran?periodeSensus=2024&userId=' + userid + '&unicode=' + unicode)
      .then(res => {
        if (res.status === 200) {
          setData(res.data)
          console.log(res.data)
          var bilangan = res.data.jumlah_Dibayar;
          var number_string = bilangan?.toString(),
            sisa = number_string?.length % 3,
            rupiah = number_string?.substr(0, sisa),
            ribuan = number_string?.substr(sisa).match(/\d{3}/g);
          if (ribuan) {
            var separator = ''
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan?.join('.');
            setjumlahDibayar(rupiah)
          } else {
            return '0'
          }

          var bilanganditerima = res.data.jumlah_Diterima;
          var number_stringditerima = bilanganditerima?.toString(),
            sisaditerima = number_stringditerima?.length % 3,
            rupiahditerima = number_stringditerima?.substr(0, sisaditerima),
            ribuanditerima = number_stringditerima?.substr(sisaditerima).match(/\d{3}/g);
          if (ribuanditerima) {
            var separatorditerima = ''
            separatorditerima = sisaditerima ? '.' : '';
            rupiahditerima += separatorditerima + ribuanditerima?.join('.');
            setjumlahDiterima(rupiahditerima)
          } else {
            return '0'
          }

          var bilanganpajak = res.data.jumlah_Pajak;
          var number_stringpajak = bilanganpajak?.toString(),
            sisapajak = number_stringpajak?.length % 3,
            rupiahpajak = number_stringpajak?.substr(0, sisapajak),
            ribuanpajak = number_stringpajak?.substr(sisapajak).match(/\d{3}/g);
          if (ribuanpajak) {
            var separatorpajak = ''
            separatorpajak = sisapajak ? '.' : '';
            rupiahpajak += separatorpajak + ribuanpajak?.join('.');
            setjumlahPajak(rupiahpajak)
          } else {
            return '0'
          }
          setBlocking(false)
        } else {
          setBlocking(false)
        }
        setDisResult(true)
      }).catch((error) => {
        // console.log("error ===> ", error)
        let message = error;
        Swal.fire({
          title: 'Error!',
          icon: 'warning',
          // text: 'Jaringan bermasalah.',
          text: 'Format QR Code Tidak Sesuai',
        })
        setBlocking(false)
      });
  }

  const handleUploadImage = (e) => {
    if (e) {
      setFile(URL.createObjectURL(e.target.files[0]))
    }
  }

  const handleClear = () => {
    setCipherText('')
    setResult('')
    setFile(null)
    setQrText('')
    setDisResult(false)
    setData({})
    document.getElementById("uploadImg").value = ""
  }

  return (
    <div>
      <BlockUi tag="div" blocking={blocking} message="Please wait">
        <div className="App" >
          <div className="header" style={{ backgroundColor: 'palegreen' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '1rem' }}>
              <img src='../../assets/img/logo2024.png' width="130" height="35" alt="login" />
              {/* <Link to="/" className='btnBack'><ArrowBackIcon/> Kembali ke halaman Login</Link> */}
            </div>
          </div>
          <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12}>
              <div style={{ textAlign: 'center', marginBottom: '-10px' }}><h3>Cek Bukti Bayar</h3></div>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>Silakan gunakan scan atau pilih file untuk pengecekan kuitansi</div>
            </Grid>

            <Grid item rid md={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '200px' }}>
                <b>Scan QR Code</b>
                {/* {facingMode === 'environment' ? */}
                <QrReader
                  key="environment"
                  constraints={{ facingMode: 'environment' }}
                  scanDelay={300}
                  onResult={(result, error) => {
                    if (!!result) {
                      handleScan(result?.text)
                    }

                    if (!!error) {
                      console.info('nf');
                    }
                  }}
                  className="qr-image-wrapper"
                  style={{ width: '100%' }}
                />
                {/* <Button style={{padding: '2px 4px', marginBottom: '10px'}} variant='contained' onClick={cameraClick}>Switch Camera</Button> */}
              </div>
            </Grid>
            <Grid item md={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '200px' }}>
                <b>Pilih Kuitansi</b><br />
                <input type="file" id="uploadImg" onChange={handleUploadImage} className="hidden" />
                <label htmlFor="uploadImg" className='btnInputFile'>Silakan pilih gambar</label>
                <img id="img" src={file} style={{ marginTop: '10px', maxWidth: '170px' }} />
              </div>
            </Grid>
            {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <code><i>{qrText}</i></code>
          </Grid> */}
            <Grid item xs={12} style={{ margin: '10px 0' }}>
              <Button color='primary' variant='contained' onClick={handleClick}>Simpan</Button>{' '}
              <Button className="btn btn-info" onClick={handleClear}>Clear</Button>
              {disResult ?
                <Button color='warning' variant='contained' onClick={handleOpenAduan}>Aduan</Button>
                : <></>}

            </Grid>
          </Grid>
          {disResult &&
            <Grid container style={{ padding: '1rem' }}>
              <Grid item xs={12}>
                <div style={{ border: '1px solid #cdcdcd', borderRadius: '4px', padding: '1rem' }}>
                  {data ?
                    <>
                      <div
                        style={{ float: 'left', textAlign: "center", padding: "0.95rem 0.5rem 0.6rem" }}
                      >
                        <img src='../../assets/img/logo2024.png' width="130" height="35" alt="login" />
                      </div>
                      <div style={{ float: 'right', fontStyle: 'italic', border: '2px solid black', padding: '4px 10px' }}><b>No Kuitansi & QR Code <span style={{ color: 'green' }}>SESUAI / VALID</span></b></div>
                      <br></br> <br></br>

                      <div style={{ float: 'right', fontStyle: 'italic', border: '2px solid black', padding: '4px 10px' }}><b>No Kuitansi : <span style={{ color: 'green' }}>{data?.nomor_Kwitansi}</span></b></div>

                      <div style={{ margin: '40px 1rem 10px' }}>
                        <Grid container>
                          <Grid xs={2} md={2}></Grid>
                          <Grid xs={2} md={2}></Grid>

                          <Grid xs={6} md={4}>
                            <b style={{ fontStyle: 'italic', fontSize: 15 }}> BUKTI PEMBAYARAN OPERASIONAL KADER {data?.roleName.toUpperCase()} </b>

                          </Grid>
                          
                          <Grid xs={2} md={2}></Grid>



                        </Grid>
                        <br></br>
                        <br></br>

                        <Grid container>
                          <Grid item xs={12} md={3} style={{ float:'right', }}>
                            Provinsi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:  <b>{data?.nama_provinsi}</b><br />
                            Kabupaten&nbsp;: <b>{data?.nama_kabupaten}</b><br />
                            Kecamatan: <b>{data?.nama_kecamatan}</b><br />
                            Kelurahan&nbsp;&nbsp;&nbsp;: <b>{data?.nama_kelurahan}</b><br />
                          </Grid>
                          <Grid item xs={12} md={6}></Grid>
                          <Grid item xs={12} md={3} style={{ float:'right', }}>
                            {/* Username: <b>{data?.userName}</b><br />
                            No. Kuitansi: <b>{data?.nomor_Kwitansi}</b><br />
                            Role: <b>{data?.roleID}</b><br />
                            Jumlah RT: <b>{data?.jumlah_rt}</b><br />
                            Target KK: <b>{data?.target_kk}</b><br />
                            Jumlah KK Didata: <b>{+data?.ditemukan + +data?.baru}</b><br />
                            Terbilang: <b>{terbilang(data?.jumlah_Dibayar)}</b> */}

                            ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <b>{data?.userID}</b><br />
                            Nama&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <b>{data?.userName}</b><br />
                            Jabatan&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <b>{data?.roleName}</b><br />
                            Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <b> {data?.status_pekerjaan}</b><br />
                            Golongan&nbsp;&nbsp;: <b>{data?.golongan}</b><br />
                            NPWP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <b>{data?.npwp}</b><br />


                          </Grid>
                          <Grid item xs={12}>
                            <table className='custable'>
                              <thead>
                                <tr>
                                  <th style={{ width: '10%' }}>No</th>
                                  {/* <th style={{ width: '10%' }}>RW</th>
                                  <th style={{ width: '10%' }}>RT</th> */}
                                  <th style={{ width: '10%' }}>Jumlah KK Baru</th>
                                  <th style={{ width: '10%' }}>Jumlah KK Update</th>
                                  <th style={{ width: '10%' }}>Jumlah Yang Dibayarkan</th>
                                  <th style={{ width: '10%' }}>Pajak</th>
                                  <th style={{ width: '10%' }}>Jumlah Yang Diterima</th>

                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className='f16'>1</td>
                                  {/* <td className='f16'>{data?.yang_pindah}</td>
                                  <td className='f16'>{data?.baru}</td> */}
                                  <td className='f16'>{data?.jumlah_kk_baru}</td>
                                  <td className='f16'>{data?.jumlah_kk_update}</td>
                                  <td className='f16'>{jumlahDibayar}</td>
                                  <td className='f16'>{jumlahPajak}</td>
                                  <td className='f16'>{jumlahDiterima}</td>


                                </tr>
                              </tbody>
                            </table>
                          </Grid>
                          <Grid item xs={12} md={6} style={{ marginTop: '15px' }}>
                            Terbilang Jumlah Yang Diterima: <b>{terbilang(data?.jumlah_Dibayar)}</b>

                          </Grid>
                        </Grid>
                      </div>
                    </>
                    :
                    <>
                      <div style={{ textAlign: 'center', border: '2px solid black', padding: '4px 10px' }}>
                        <b>
                          <span style={{ color: 'red' }}>No Kuitansi & QR Code tidak ditemukan. Silakan periksa dan verifikasi lagi atau hubungi Admin terkait.</span>
                        </b>
                      </div>
                    </>
                  }
                </div>
              </Grid>
            </Grid>
          }
          <Dialog open={openDialog} onClose={handleOpenDialogfalse}
            fullWidth maxWidth={'xs'}>
            <DialogTitle>Form Pengaduan Pembayaran </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Grid
                  container
                  rowSpacing={2}
                // columnSpacing={{ xs: 0, sm: 0, md: 0 }}
                >

                  <Grid item xs={4} style={{ display: 'inline-block' }}>
                    <div >
                      Nomor telpon <a style={{ color: 'red' }}>*</a>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      //error={this.state.errors["email"] ? true : false}
                      value={nmr}
                      onChange={handlenmr}
                      inputProps={
                        { maxLength: 13 }
                      }

                      error={disnmr}
                      // helperText={disnmr === true ? 'Nomor Kurang dari 12' : ''}

                      variant="standard"
                      //type="email"
                      style={{ width: '100%', bottom: '5px', marginLeft: '20px' }}
                      // value={this.state.sp2d}
                      size="small"
                    // onChange={this.handleChangeFormSp2d}
                    //helperText={this.state.errors["email"] ? this.state.errors["email"] : ''}

                    />
                  </Grid>
                </Grid>
                <p></p>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >

                  <Grid item xs={4} style={{ display: 'inline-block' }}>
                    <div >
                      Jenis Pengaduan <a style={{ color: 'red' }}>*</a>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <Select
                      style={{ width: '100%', bottom: '5px', marginLeft: '20px' }}

                      onChange={handlePengaduan}
                      //  value={optPengaduan.label} 
                      placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} >

                      <MenuItem value={'Nominal Tidak Sesuai'}>Nominal Tidak Sesuai</MenuItem>
                      <MenuItem value={'Nama Lengkap Tidak Sesuai'}>Nama Lengkap Tidak Sesuai</MenuItem>
                      {/* <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>


                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSutmitpengaduan}>Simpan</Button>
              <Button onClick={handleOpenDialogfalse} >Batal</Button>
            </DialogActions>
          </Dialog>
        </div>
      </BlockUi>
    </div>
  )
}

export default Tracking;