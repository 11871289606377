import { Box, Grid, FormControl } from '@mui/material';
import React, { Component } from 'react';
import Select from 'react-select';

import { ImPlay3 } from 'react-icons/im';

import TableExpand from '../../../commons/table/TableExpand';

import APIWilayah from '../../../services/APIWilayah';


export default class TestTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blocking: false,
      defaultData: [],
      //---wilayah
      tingkatWilayahId: 0,
      roleId: 0,
      wilKabupaten: {},
      wilKecamatan: {},
      wilKelurahan: {},
      dt_priode: [{ value: 2024, label: '2024' }, { value: 2023, label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
      //---end wilayah
    }
  }

  componentDidMount = async () => {
    const rest = JSON.parse(localStorage.getItem("authority"));

    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const { wilProvinsi, wilKabupaten, wilKecamatan, wilKelurahan } = rest[0].attributes.user;
      const tw = +rest[0].attributes.user.TingkatWilayahID;
      const ri = +rest[0].attributes.user.roleID;

      this.setState({
        wilKabupaten,
        wilKecamatan,
        wilKelurahan,
        tingkatWilayahId: tw,
        roleId: ri,
      });

      if (tw === 0) {
        try {
          this.setState({ blocking: true });
          const res = await APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode);
          this.setState({ defaultData: res.data });
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          this.setState({ blocking: false });
        }
      } else {

        const arr = [{
          id_provinsi: +wilProvinsi.id_provinsi,
          nama: wilProvinsi.nama_provinsi,
          kodeDepdagri: wilProvinsi.id_provinsi_depdagri,
          statusLock: wilProvinsi.status_lock,
          value_priode:this.state.value_priode

        }];
        this.setState({ defaultData: arr }, () => {
        });
      }
    }
  }


  sel_priode = (e) => {
    this.setState({defaultData: []})
    const rest = JSON.parse(localStorage.getItem("authority"));
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      },
        () => {
          if (e !== null) {

            const wilProvinsi = rest[0].attributes.user.wilProvinsi;
            const wilKabupaten = rest[0].attributes.user.wilKabupaten;
            const wilKecamatan = rest[0].attributes.user.wilKecamatan;
            const wilKelurahan = rest[0].attributes.user.wilKelurahan;
            const tw = +rest[0].attributes.user.TingkatWilayahID;
            const ri = +rest[0].attributes.user.roleID;
            this.setState({ wilKabupaten, wilKecamatan, wilKelurahan, tingkatWilayahId: tw, roleId: ri, priode: e, value_priode: e.value, }, () => {

            })
            if (tw === 0) { //user pusat
              this.setState({ blocking: true });
              APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
                .then(res => {
                  this.setState({ defaultData: res.data, });
                  this.setState({ blocking: false });
                }).catch((error) => {
                  this.setState({ blocking: false });
                });
            } else { //user provinsi
              const arr = [{
                id_provinsi: +wilProvinsi.id_provinsi,
                nama: wilProvinsi.nama_provinsi,
                kodeDepdagri: wilProvinsi.id_provinsi_depdagri,
                statusLock: wilProvinsi.status_lock,
                value_priode:this.state.value_priode

              }]
              console.log(arr,"ARR")
              this.setState({ defaultData: arr}, () => {
              });
            }
          }
        }
      )
    } else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  render() {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    const periode = this.state.value_priode

    return (
      <div className='content'>
        <div className='title-content'><ImPlay3 className='icon-brd' />Master Wilayah</div>
        <div className='body-content'>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
            <Grid item xs={12} md={12}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                <Grid item xs={12} md={12}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={4}>
                      <FormControl sx={{ width: '100%' }} size="small">
                        Periode
                        <Select options={this.state.dt_priode} onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={12} md={12}>
                  {/* {} */}
                  <Box sx={{ width: '100%' }}>
                    <TableExpand
                      defaultData={this.state.defaultData}
                      tingkatWilayahId={this.state.tingkatWilayahId}
                      roleId={this.state.roleId}
                      wilKabupaten={this.state.wilKabupaten}
                      wilKecamatan={this.state.wilKecamatan}
                      wilKelurahan={this.state.wilKelurahan}
                      value_priode={periode}

                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}