import React, { Component } from 'react'
import { ImFileExcel, ImFilePdf, ImPlay3, ImSearch, ImBackward2, } from 'react-icons/im';
import { IoArrowBackCircleOutline } from 'react-icons/io5';

import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import APIWilayah from '../../services/APIWilayah';
import APIReport from '../../services/APIReport';
import Tabulasi from '../../services/Tabulasi';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';


class TabulasiKRSDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panel1: true,
      blocking: false,
      urlLaporan:'',
      urlNew:'',
      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,
      periode: [
        {value: '2021',label:'2021'},
        {value: '2022',label:'2022'}
      ],
      periode_select:'',
      sendDatas: {
        "idProv": 0,
        "idKab": 0,
        "idKec": 0,
        "idKel": 0,
        "idRW": 0,
        "idRt": 0,
        "limit": 300
      },
      phone_auth: JSON.parse(sessionStorage.getItem('phone_auth')),
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  setHeaderTable = () => {
    var headerColumns = [
      // { width: "50", row: '0', rowSpan: '2', title: 'NO', dataField: "no", headerAlign: 'center', dataAlign: 'center', editable: false },

      // headerColumnsProv
      { width: "300", row: '0', colSpan: '2', title: 'PROVINSI', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriProv', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_prov', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKab
      { width: "300", row: '0', colSpan: '2', title: 'KABUPATEN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKab', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kab', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKec
      { width: "300", row: '0', colSpan: '2', title: 'KECAMATAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKec', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kec', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKel
      { width: "300", row: '0', colSpan: '2', title: 'KELURAHAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKel', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kel', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerMetodePendataan
      { width: "120", row: '0', rowSpan: '2', title: 'METODE PENDATAAN', dataField: "metodePendataan", headerAlign: 'center', dataAlign: 'center', editable: false },
    ];
    this.setState({ tableHead: headerColumns });
  }

    componentDidMount() {
    const rest = JSON.parse(localStorage.getItem('authority'))
    let idTingkatWilayah = 0;

    if(rest === null){
      idTingkatWilayah = 0;
    }else{
      idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    }

    let title2, text11, text21

    if (idTingkatWilayah == 0) {
      title2 = ''

      this.setState({
        sendDatas: {
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 2) {
        title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten
    this.setState({
        sendDatas: {
        "idProv": parseInt(rest[0].attributes.user.wilProvinsi.id_provinsi),
        "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
        "idKec": 0,
        "idKel": 0,
        "idRW": 0,
        "idRW": 0,
        "idRt": 0,
        "limit": 300
        }
    }
        // () => { this.getBackend(this.state.sendDatas) }
    )


    } else {
    title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan

    this.setState({
    sendDatas: {
        "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
        "idKab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
        "idKec": 0,
        "idKel": 0,
        "idRW": 0,
        "idRt": 0,
        "limit": 300
    }
    });
    }

    this.setState({ title: title2, text1: text11, text2: text21 },
        () => { this.setHeaderTable() }
    )

    if(rest === null){
      this.setProv(null);
    }else{
      if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

        const nm = rest[0].attributes.user.TingkatWilayahID
        if (+nm === 0) {
          this.setState({ flagSearch: false },
            () => {
              this.setProv(null)
            }
          )
        } else {
          if (+nm === 1) { this.setState({ flagNumber: 1 }) }
          else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
          else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
          else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
          //--prepare by level
          if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
          } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
          } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
          } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
            this.setState({ dis_prov: true, auto_prov: true })
          }
          const wilProvinsi = rest[0].attributes.user.wilProvinsi
          const wilKabupaten = rest[0].attributes.user.wilKabupaten
          const wilKecamatan = rest[0].attributes.user.wilKecamatan
          const wilKelurahan = rest[0].attributes.user.wilKelurahan

          if (Object.keys(wilProvinsi).length > 0) {

            const objProp = {
              'value': wilProvinsi.id_provinsi,
              'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
            };

            this.setState({ dt_prov: objProp },
              () => {
                if (this.state.auto_prov === true) {
                  //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                  this.sel_prov(objProp)
                }
              }
            );
            //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
          }
          //        alert(wilKabupaten.length)
          if (Object.keys(wilKabupaten).length > 0) {
            //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            const objKab = {
              'value': wilKabupaten.id_kabupaten,
              'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
            };

            this.setState({ dt_kab: objKab },
              () => {
                if (this.state.auto_kab === true) {
                  //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                  this.sel_kab(objKab)
                }
              }
            );

          }
          if (Object.keys(wilKecamatan).length > 0) {
            const objKec = {
              'value': wilKecamatan.id_kecamatan,
              'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
            };

            this.setState({ dt_kec: objKec },
              () => {
                if (this.state.auto_kec === true) {
                  //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                  this.sel_kec(objKec)
                }
              }
            );
            //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
          }
          if (Object.keys(wilKelurahan).length > 0) {
            const objKel = {
              'value': wilKelurahan.id_kelurahan,
              'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
            };

            this.setState({ dt_kel: objKel },
              () => {
                if (this.state.auto_des === true) {
                  //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                  this.sel_des(objKel)
                }
              }
            );


            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
          }
        }
      } else {
        this.setProv(null);
      }
    }
    // if (rest === false) { this.props.history.push('/401') }

  }

  //API (Wilayah)
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      //handleSearch
      this.setState({
        sendDatas: {
          "idProv": e.value,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
      );

      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    } else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": e.value,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
        , id_kecamatan: 0, id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    } else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": e.value,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
    () => {this.getBackend(this.state.sendDatas)} */
      );

      /*
            this.setState(prevState => ({
              sendDatas:
                  {
                      ...prevState.sendDatas, ['idKec']: e.value
                  }
              },
              () => {this.getBackend(this.state.sendDatas)}
              ))
      */

      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
        , id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    } else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": this.state.sendDatas.idKec,
          "idKel": e.value,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );

      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
        , id_rw: 0

      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    } else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": this.state.sendDatas.idKec,
          "idKel": this.state.sendDatas.idKel,
          "idRW": e.value,
          "idRt": 0,
        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    } else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    } else {
      this.setState({
        rt: null, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  sel_periode = (e) => {
    if (e) {
      this.setState({
        periode_select: {label:e.value,value:e.value}
      })
    }
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  handleCetak = () => {
    const pathname = window.location.href;
    var splitUrl = pathname.split('/');
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw , id_rt,periode_select} = this.state;

    if(this.state.kecamatan === null || this.state.kecamatan === '' || this.state.kecamatan === undefined){
      Swal.fire(
        'Peringatan !',
        'Mohon PIlih Hingga Kecamatan',
        'warning'
      )
      return false;
    }

    const link = document.createElement("a");
    let urlnew = ''
    let namaReportnya = splitUrl[4]
    if(this.state.periode_select.value === '2021'){
      namaReportnya = splitUrl[4]
    }else{
      namaReportnya = splitUrl[4]+'2022'
    }

    //kalo 2021 panggil yang lama kalo 2022 yang baru
    if(id_rt){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22rtId%22%3A%22${id_rt}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_rw){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_des){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_kecamatan){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_kabupaten){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_provinsi){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else{
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }
    link.target = "_blank";
    link.download = "Tabulasi.pdf"
    Tabulasi
      .get(urlnew, {
        responseType: "blob",
      })
      .then((res) => {
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: "blob" })
        );
        link.click();
      });
  }

  handleCetakExcel = () => {
    const pathname = window.location.href;
    var splitUrl = pathname.split('/');
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw , id_rt,periode_select} = this.state;

    if(this.state.kecamatan === null || this.state.kecamatan === '' || this.state.kecamatan === undefined){
      Swal.fire(
        'Peringatan !',
        'Mohon PIlih Hingga Kecamatan',
        'warning'
      )
      return false;
    }

    const link = document.createElement("a");
    let urlnew = ''
    let namaReportnya = splitUrl[4]
    if(this.state.periode_select.value === '2021'){
      namaReportnya = splitUrl[4]
    }else{
      namaReportnya = splitUrl[4]+'2022'
    }

    //kalo 2021 panggil yang lama kalo 2022 yang baru
    if(id_rt){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22rtId%22%3A%22${id_rt}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_rw){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_des){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_kecamatan){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_kabupaten){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_provinsi){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else{
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }
    link.target = "_blank";
    link.download = "Tabulasi.xlsx"
    Tabulasi
      .get(urlnew, {
        responseType: "blob",
      })
      .then((res) => {
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: "blob" })
        );
        link.click();
      });
  }

  handleSearch = () => {
    const pathname = window.location.href;
    var splitUrl = pathname.split('/');

    if(this.state.periode_select === ''){
      Swal.fire(
        'Peringatan !',
        'Mohon Pilih Periode',
        'warning'
      )
      return false;
    }

    if(this.state.kecamatan === null || this.state.kecamatan === '' || this.state.kecamatan === undefined){
      Swal.fire(
        'Peringatan !',
        'Mohon PIlih Hingga Kecamatan',
        'warning'
      )
      return false;
    }

    const rest = JSON.parse(localStorage.getItem('authority'))
    if(rest === null){
      // console.log('Pake OTP')
      let valuenya = {
        'userName': sessionStorage.getItem('nama'),
        'flag': 2,
        'instansi': sessionStorage.getItem('instansi'),
        'noHp': sessionStorage.getItem('email'),
        'email': sessionStorage.getItem('email'),
        'namaReport': splitUrl[4],
        'reportCategory': splitUrl[5],
      }
      axios.post(`https://reset-password-api.bkkbn.go.id/portalpk/monitoring/insertHistoryActivity`,
      {'histParam':valuenya})
      .then((res) => {
            // console.log(res)
      })
      .catch((error) => {
          Swal.fire(
              'Gagal Simpan !',
              'Terjadi Kesalahan',
              'error'
          )
          this.setState({ blocking: false, disButtonSave: false });
          console.log(error, "dataaa ERROR");
      });
    }else{
      let valuenya = {
        'userName': localStorage.getItem('username'),
        'flag': 1,
        'instansi': '',
        'noHp': localStorage.getItem('noTelepon'),
        'email': localStorage.getItem('username'),
        'namaReport': splitUrl[4],
        'reportCategory': splitUrl[5],
      }
      axios.post(`https://reset-password-api.bkkbn.go.id/portalpk/monitoring/insertHistoryActivity`,
      {'histParam':valuenya})
      .then((res) => {
            // console.log(res)
      })
      .catch((error) => {
          Swal.fire(
              'Gagal Simpan !',
              'Terjadi Kesalahan',
              'error'
          )
          this.setState({ blocking: false, disButtonSave: false });
          console.log(error, "dataaa ERROR");
      });
    }

    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw , id_rt,periode_select} = this.state;
    this.setLabel()
    let urlnew = ''
    let namaReportnya = splitUrl[4]
    if(this.state.periode_select.value === '2021'){
      namaReportnya = splitUrl[4]
    }else{
      namaReportnya = splitUrl[4]+'2022'
    }

    //kalo 2021 panggil yang lama kalo 2022 yang baru
    if(id_rt){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22rtId%22%3A%22${id_rt}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_rw){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_des){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_kecamatan){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_kabupaten){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else if(id_provinsi){
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }else{
        urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select.value}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%7D`;
    }

    if(this.state.phone_auth !== null){
      this.setState({
        urlLaporan: urlnew,
        htmlLaporan:''
      })
      Tabulasi.get(urlnew)
          .then((res) => {
          this.setState({ blocking: false });
          if (res.status === 200) {
              this.setState({
                  htmlLaporan: res.data
              })
          }
          })
          .catch((error) => {
              this.setState({ blocking: false });
              Swal.fire(
                'Peringatan !',
                error.message,
                'error'
              )
              // console.log(error, "DATA ERROR");
          });
    }else{
      if (rest[0].attributes.user.TingkatWilayahID !== 0 && id_provinsi === 0) {
        Swal.fire({
            title: 'Info!',
            icon: 'info',
            text: 'Filter wilayah harus dipilih minimal Provinsi',
            showConfirmButton: true
        })
        return false;
    }else{
        this.setState({
            urlLaporan: urlnew,
            htmlLaporan:''
        })
        Tabulasi.get(urlnew)
            .then((res) => {
            this.setState({ blocking: false });
            if (res.status === 200) {
                this.setState({
                    htmlLaporan: res.data
                })
            }
            })
            .catch((error) => {
                this.setState({ blocking: false });
                Swal.fire(
                  'Peringatan !',
                  error.message,
                  'error'
                )
                // console.log(error, "DATA ERROR");
            });
        }
      }
    }

    resizeFrame(object) {
        object.style.height = object.contentWindow.document.documentElement.scrollHeight + 'px';
    }
    render() {
        const mystyle = {
            // position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            border: "none"
        };
        const pathname = window.location.href
        let splitUrl = pathname.split('/')
        console.log('split url', splitUrl);
    return (
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>

          <div className='title-content'>

          {splitUrl[3] === "tabulasi-krs" ?
          <Link to='/tabulasi-krs' className='btnBackTab' >Kembali</Link> :
          <Link to='/laporan-tabulasi-krs' className='btnBackTab' >Kembali</Link>
          }

          </div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
                <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                    className='panelSummary'
                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                        </Grid>
                        {this.state.phone_auth !== null ? <div></div> : <>
                        <Grid item xs={12} md={4}>
                        <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                        </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                        <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                        </Grid> */}
                        
                         </>}
                        <Grid item xs={12} md={4}>
                        <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select options={this.state.periode} isClearable onChange={this.sel_periode} value={this.state.periode_select} placeholder="Cari..."  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button onClick={this.handleCetak} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', backgroundColor: 'green', marginRight: '5px', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImFilePdf className='icon-brd' style={{ marginRight: '5px' }} /> Cetak PDF</Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button onClick={this.handleCetakExcel} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', backgroundColor: 'green', marginRight: '5px', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImFileExcel className='icon-brd' style={{ marginRight: '5px' }} /> Cetak Excel</Button>
                        </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '5px' }} variant="contained">Cari</Button>
                        </Grid>
                        </Grid>
                    </Grid> */}
                    </Grid>
                </AccordionDetails>
                </Accordion>
            </Box>
            <h3>Tabulasi {this.state.title}</h3>
            <div>
              <div
                dangerouslySetInnerHTML={{__html: this.state.htmlLaporan}}
              />
            </div>
            </div>
        </div>
        </BlockUi>
    )
    }
}
export default TabulasiKRSDetail;