import React, { Component } from 'react';
import { Tabs, Tab, Box, Grid, TextField, Button } from '@mui/material';
import { ImPlay3 } from 'react-icons/im';
import TabulasiApi from "../../services/Tabulasi";
import { resolvePath } from 'react-router-dom';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { authentication } from '../../firebase-config';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Swal from 'sweetalert2';
import axios from 'axios';

class TabulasiKRS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueTab: 0,
      auth: JSON.parse(localStorage.getItem('authority')),
      phone_auth: JSON.parse(sessionStorage.getItem('phone_auth')),
      nohp: '',
      otp: '',
      disButton: true,
      expand: false,
      nama:'',
      email:'',
      instansi:'',
      catatan:''
    }
  }

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChangeTab = (event, newValue) => {
    this.setState({ valueTab: newValue });
  }

  getListTabulasi = (e) => {
    TabulasiApi
      .get("list/tabulasi2022")
      .then((res) => {
        this.setState({
          listCakupan: res.data[0].CAKUPAN,
          listRekapitulasi: res.data[4].REKAPITULASI,
          listStunting: (this.state.phone_auth !== null || this.state.auth !== null ) ? res.data[5].STUNTING.filter( el => el.nama_Report === 'Tabel4') : res.data[5].STUNTING,
          listIk: res.data[1]["INDICATOR KEPENDUDUKAN (IK)"],
          listIkb: res.data[2]["INDICATOR KELUARGA BERENCANA (IKB)"],
          listIpk: res.data[2]["INDICATOR PEMBANGUNAN KELUARGA (IPK)"],
        })
        console.log('res.data>>>', res.data,)
      }).catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });
  }

  componentDidMount = () => {
    if (this.state.auth || this.state.phone_auth) {
      this.getListTabulasi()
    }
  }

  generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      }
    }, authentication);
  }

  handleChangeHp = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({ [name]: value },
      () => {
        if (this.state.nohp.length === 12 && this.state.nama !== '' && this.state.email !== '' && this.state.instansi !== '' && this.state.catatan !== '') {
          this.setState({ disButton: false })
        } else {
          this.setState({ disButton: true })
        }
      }
    )
  }

  handleChangeOTP = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({ [name]: value },
      () => {
        if (this.state.otp.length === 6) {
          let confirmationResult = window.confirmationResult
          confirmationResult.confirm(this.state.otp).then((result) => {
            const user = result.user;
            const valuenya = {
              'nama': this.state.nama,
              'email': this.state.email,
              'nomorHp': this.state.nohp,
              'instansi': this.state.instansi,
              'catatan': this.state.catatan
            }
            console.log(user, 'user')
            //apinya disini
            axios.post(`https://reset-password-api.bkkbn.go.id/portalpk/monitoring/insertHistoryLoginTabulasi`,
            {'reqParam':valuenya})
            .then((res) => {
                  console.log(res)
            })
            .catch((error) => {
                this.setState({ blocking: false, disButtonSave: false });
                console.log(error, "dataaa ERROR");
            });
            sessionStorage.setItem('phone_auth', JSON.stringify(user))
            sessionStorage.setItem('nama', this.state.nama)
            sessionStorage.setItem('email', this.state.email)
            sessionStorage.setItem('instansi', this.state.instansi)
            sessionStorage.setItem('catatan', this.state.catatan)
            sessionStorage.setItem('no_hp', this.state.nohp)
            this.setState({phone_auth: user})
          }).catch((error) => {
            console.log(error)
          });
        }
      }
    )
  }

  handleSendOtp = () => {
    this.generateRecaptcha()
    let appVerifier = window.recaptchaVerifier
    let phoneNumber = '+62' + this.state.nohp.substring(1, 12)
    console.log(phoneNumber, 'phoneNumber')
    signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult
        this.setState({ disButton: true, expand: true })
        this.getListTabulasi()
      }).catch((error) => {
        // Error; SMS not sent
        // ...
        console.log(error)
      });
  }

  render() {
    console.log('list stunting', this.state.listStunting);
    return (
      <div className='content'>
        {this.state.auth || this.state.phone_auth ?
          <>
            <div className='title-content'><ImPlay3 className='icon-brd' />Laporan Tabulasi Keluarga Berisiko Stunting</div>
            <div className='body-content'>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={this.state.valueTab} onChange={this.handleChangeTab} aria-label="basic tabs example">
                    {/* <Tab label="Cakupan" {...this.a11yProps(0)} />
                    <Tab label="Indikator Kependudukan" {...this.a11yProps(1)} />
                    <Tab label="Indikator Keluarga Berencana" {...this.a11yProps(2)} />
                    <Tab label="Indikator Pembangunan Keluarga" {...this.a11yProps(3)} />
                    <Tab label="Rekapitulasi" {...this.a11yProps(4)} /> */}
                    <Tab label="KELUARGA BERISIKO STUNTING" {...this.a11yProps(0)} />
                  </Tabs>
                </Box>
                {/* <div role="tabpanel" hidden={this.state.valueTab !== 0}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Grid item xs={12} md={12}>
                    {this.state.phone_auth !== null ?
                        this.state.listCakupan?.map(d => (
                        <div className="isiLaporan"><Link to={`/tabulasi/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        )) :
                        this.state.listCakupan?.map(d => (
                          <div className="isiLaporan"><Link to={`/laporan-tabulasi-krs/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        ))
                      }
                    </Grid>
                  </Grid>
                </div>
                <div role="tabpanel" hidden={this.state.valueTab !== 1}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Grid item xs={12} md={12}>
                      {this.state.phone_auth !== null ?
                        this.state.listIk?.map(d => (
                        <div className="isiLaporan"><Link to={`/tabulasi/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        )) :
                        this.state.listIk?.map(d => (
                          <div className="isiLaporan"><Link to={`/laporan-tabulasi-krs/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        ))
                      }
                    </Grid>
                  </Grid>
                </div>
                <div role="tabpanel" hidden={this.state.valueTab !== 2}>
                {this.state.phone_auth !== null ?
                        this.state.listIkb?.map(d => (
                        <div className="isiLaporan"><Link to={`/tabulasi/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        )) :
                        this.state.listIkb?.map(d => (
                          <div className="isiLaporan"><Link to={`/laporan-tabulasi-krs/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        ))
                      }
                </div>
                <div role="tabpanel" hidden={this.state.valueTab !== 3}>
                {this.state.phone_auth !== null ?
                        this.state.listIpk?.map(d => (
                        <div className="isiLaporan"><Link to={`/tabulasi/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        )) :
                        this.state.listIpk?.map(d => (
                          <div className="isiLaporan"><Link to={`/laporan-tabulasi-krs/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        ))
                      }
                </div>
                <div role="tabpanel" hidden={this.state.valueTab !== 4}>
                {this.state.phone_auth !== null ?
                        this.state.listRekapitulasi?.map(d => (
                        <div className="isiLaporan"><Link to={`/tabulasi/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        )) :
                        this.state.listRekapitulasi?.map(d => (
                          <div className="isiLaporan"><Link to={`/laporan-tabulasi-krs/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        ))
                      }
                </div> */}
                <div role="tabpanel" hidden={this.state.valueTab !== 0}>
                {this.state.phone_auth !== null ?
                        this.state.listStunting?.map(d => (
                        <div className="isiLaporan"><Link to={`/tabulasi-krs/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        )) :
                        this.state.listStunting?.map(d => (
                          <div className="isiLaporan"><Link to={`/laporan-tabulasi-krs/${d.group}/${d.nama_Report.replace(/ /g, '')}`} key={d.nama_Report}>{d.nama_Report} - {d.header_Report}</Link></div>
                        ))
                      }
                </div>
              </Box>
            </div>
          </>
          :
          <>
            <div className='centerDiv'>
              <div style={{ border: '1px solid #ececec', padding: '2rem', borderRadius: '4px', textAlign: 'center',boxShadow: `0 0 2px 2px gray` }}>
              Nama<br />
              <TextField
                name="nama"
                variant="outlined"
                value={this.state.nama}
                size="small"
                onChange={this.handleChangeHp} /><br /><br />

                Email<br />
                <TextField
                name="email"
                variant="outlined"
                value={this.state.email}
                size="small"
                type="email"
                onChange={this.handleChangeHp} /><br /><br />

                Instansi/Lembaga/Umum/Universitas<br />
                <TextField
                name="instansi"
                variant="outlined"
                value={this.state.instansi}
                size="small"
                onChange={this.handleChangeHp} /><br /><br />

                Catatan<br />
                <TextField
                name="catatan"
                variant="outlined"
                value={this.state.catatan}
                size="small"
                onChange={this.handleChangeHp} /><br /><br />

                Silakan masukkan nomor Handphone<br />
                <TextField
                  inputProps={{
                    maxLength: 12,
                  }}
                  onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                  name="nohp"
                  variant="outlined"
                  value={this.state.nohp}
                  size="small"
                  onChange={this.handleChangeHp} /><br />

                <Button disabled={this.state.disButton} onClick={this.handleSendOtp} sx={{ marginRight: '5px' }} variant="contained" style={{ marginTop: '5px' }}> Kirim OTP</Button><br/>
                {this.state.expand && <>
                  Silakan masukkan nomor OTP<br />
                  <TextField
                    inputProps={{
                      maxLength: 12,
                    }}
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                    name="otp"
                    variant="outlined"
                    value={this.state.otp}
                    size="small"
                    onChange={this.handleChangeOTP} />
                </>}
              </div>
            </div>
            <div id="sign-in-button"></div>
          </>
        }
      </div>
    )
  }
}

export default TabulasiKRS;