import React, { Component } from 'react';
import { FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImPlay3, ImUserPlus, ImSearch } from 'react-icons/im';
import Select from 'react-select';
import APIWilayah from '../services/APIWilayah';
import TransferList from './TransferList';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import APIDashboard from '../services/APIDashboard';
import APIMutasiKK from '../services/APIMutasiKK';

class TransferKKDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blocking: false,
            panel: true,
            kodeDepdagri: this.props.kodeDepdagri,
            nama: this.props.nama,
            id: this.props.id,
            namaTujuan: '',
            idTujuan: 0,
            listPindah: '',
            open: this.props.open,
            openSave: false,
            wilayah: this.props.wilayah,
            dataWilayah: this.props.dataWilayah,
            wilayahId: this.props.wilayahId,
            tkWilayah: this.props.tkWilayah,
            rightOpen: false,
            onSubmit: false,
            //---table
            sizePerPage: 1,
            tableHead: [],
            datas: [],
            //---end table

            //---wilayah
            flagNumber: -1,
            flagOpen: true,
            blocking: false,
            dt_prov: [], provinsi: null, id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: null, id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: null, id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: null, id_rt: 0, dis_rt: true, depdagriRt: '',
            //---end wilayah


            tingkatWilayah: '-',
            tingkatWilayahTujuan: '-',
            dataWilayah: [],
            dataWilayahTujuanTemp: [],
            dataWilayahTujuan: [],
            dataPindah: {},

            jumlahKabTerdampak: 0,
            jumlahKecTerdampak: 0,
            jumlahKelTerdampak: 0,
            jumlahRwTerdampak: 0,
            jumlahRtTerdampak: 0,
            jumlahKkTerdampak: 0,
            jumlahPusTerdampak: 0,
            jumlahPusKbTerdampak: 0,
            jumlahUserTerdampak: 0,

            timer: 5
        }
    }

    loadDataWilayah = () => {
        const { wilayahId } = this.state
        let newData = []
        this.props.dataWilayah?.forEach(data => {
            let obj = {}
            if (+wilayahId === 2) {
                obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
            } else if (+wilayahId === 3) {
                obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
            } else if (+wilayahId === 4) {
                obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
            } else if (+wilayahId === 5) {
                obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
            } else if (+wilayahId === 6) {
                obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
            } else if (+wilayahId === 7) {
                obj = { 'value': data.id_frm, 'label': data.nik + ' - ' + data.nama, 'kode': data.kki };

            }
            newData.push(obj);
        });
        this.setState({ dataWilayah: newData })
    }

    clearWilayah = () => {
        this.setState({
            dt_prov: [], provinsi: null, id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: null, id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: null, id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: null, id_rt: 0, dis_rt: true, depdagriRt: ''
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.open !== prevProps.open && this.props.open === true) {
            this.clearWilayah()
            this.loadDataWilayah()
            const rest = JSON.parse(localStorage.getItem("authority"));
            if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                const nm = rest[0].attributes.user.TingkatWilayahID
                if (+nm === 0) {
                    this.setState({ flagSearch: false },
                        () => {
                            this.setProv(null)
                        }
                    )
                } else {
                    if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                    else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                    else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                    else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                    //--prepare by level
                    if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                        this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                    } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                        this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                    } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                        this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                    } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                        this.setState({ dis_prov: true, auto_prov: true })
                    }

                    if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                        this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                    }
                    if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                        this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    }
                    if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                        this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                    }
                    if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                        this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                    }
                }
            } else {
                this.setProv(null);
            }
        }
        if (this.state.timer === 1) {
            window.location.reload()
        }
    }

    //API (Wilayah)
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData, dataWilayahTujuanTemp: newData, dataWilayahTujuan: [] },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false, dataWilayahTujuanTemp: newData, dataWilayahTujuan: [] },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false, dataWilayahTujuanTemp: newData, dataWilayahTujuan: [] },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false, dataWilayahTujuanTemp: newData, dataWilayahTujuan: [] },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false, dataWilayahTujuanTemp: newData, dataWilayahTujuan: [] });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false, dataWilayahTujuanTemp: newData, dataWilayahTujuan: [] });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    setKK(id) {
        this.setState({ blocking: true });
        var newData = [];
        APIMutasiKK.get('/pkportal/targetkk/getListKKByIdRt?idRT=' + id)
        .then(res => {
            res.data.forEach(data => {
                const obj = { 'value': data.id_frm, 'label': data.nik + ' - ' +data.nama, 'kode': data.kki };
                newData.push(obj);
            });
            this.setState({ blocking: false, dataWilayahTujuanTemp: newData, dataWilayahTujuan: [] });
        }).catch((error) => {
            console.log(error, 'err')
            this.setState({ blocking: false });
        });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, tingkatWilayahTujuan: 'Kabupaten'
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                dataWilayahTujuanTemp: [], dataWilayahTujuan: []
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode, tingkatWilayahTujuan: 'Kecamatan'
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                dataWilayahTujuan: [], dataWilayahTujuanTemp: []
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode, tingkatWilayahTujuan: 'Kelurahan'
            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                dataWilayahTujuan: [], dataWilayahTujuanTemp: []
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true, tingkatWilayahTujuan: 'RW'
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0, dataWilayahTujuan: [], dataWilayahTujuanTemp: []
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false, tingkatWilayahTujuan: 'RT'
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0, dataWilayahTujuan: [], dataWilayahTujuanTemp: []
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode, tingkatWilayahTujuan: 'KK'
            },
                () => {
                    if (this.state.id_rt) {
                        this.setKK(this.state.id_rt)
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0, dataWilayahTujuan: [], dataWilayahTujuanTemp: []
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ rightOpen: false })
        this.props.onClose()
    };

    handleChangePanel = (e) => {
        e.preventDefault();
        this.setState({ panel: !this.state.panel })
    }

    handleSearch = () => {
        const { tkWilayah, tingkatWilayahTujuan, nama, id, rt, rw, desa, kecamatan, kabupaten, provinsi } = this.state
        // console.log('provinsi', provinsi)
        let namaTujuan = rt ? rt.label : '';
        let idTujuan = rt ? rt.value : '';
        // console.log(tkWilayah, 'tkWilayah')
        // console.log(tingkatWilayahTujuan, 'tingkatWilayahTujuan')
        // console.log(nama, 'asal')
        namaTujuan = namaTujuan.length > 0 ? namaTujuan.split('-')[1].replace(' ', '') : ''
        // console.log(namaTujuan, 'nama tujuan')
        if (tingkatWilayahTujuan === '-' || namaTujuan.length === 0) {
            Swal.fire({
                title: 'Gagal',
                icon: 'warning',
                text: 'Silakan pilih wilayah sampai dengan RT!',
            });
        } else if (tkWilayah !== tingkatWilayahTujuan) {
            Swal.fire({
                title: 'Gagal',
                icon: 'warning',
                text: 'Tingkat wilayah asal dan tujuan tidak sesuai!',
            });
        } else if (tkWilayah === tingkatWilayahTujuan && +id === +idTujuan) {
            Swal.fire({
                title: 'Gagal',
                icon: 'warning',
                text: 'Wilayah asal dan tujuan tidak boleh sama!',
            });
        } else {
            this.setState({ dataWilayahTujuan: this.state.dataWilayahTujuanTemp, namaTujuan, rightOpen: true })
        }
    }

    handleSelect = (list) => {
        let arr = []
        list.forEach(el => {//ambil yg status !x
            if (!el.hasOwnProperty('status')) {
                arr = arr.concat(el)
            }
        })
        let ids = []
        let names = []
        arr.forEach(el => {
            ids = ids.concat(el.value)
            names = names.concat(el.label)
        })
        // console.log(arr, 'data arr select')
        // console.log(ids, 'ids select')
        //get id parent tujuan
        const dataPindah = {
            id_frm: ids,
            userLogin: localStorage.getItem('username'),
        }
        this.setState({ dataPindah, listPindah: names.toString() })
    }

    handleSimpan = () => {
        const { dataPindah } = this.state
        let customPindah = dataPindah
        let i = 0
        let param = ''

        for (i = 0; i < dataPindah.id_frm.length; i++) {
            if (i !== dataPindah.id_frm.length - 1) {
                param += (dataPindah.id_frm[i] + '%2C%20')
            } else {
                param += dataPindah.id_frm[i]
            }
        }

        if (dataPindah.id_frm.length === 0) {
            Swal.fire({
                title: 'Peringatan',
                icon: 'warning',
                text: 'Belum ada data yang dipindahkan!',
            });
        } else {
            const { dataPindah } = this.state
            Swal.fire({
                title: 'Peringatan',
                text: "Apakah anda yakin menjalanan pemindahan KK ?",
                icon: 'warning',
                cancelButtonText: 'Batal!',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya!',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    this.setState({ blocking: true, onSubmit: true })
                    const idProv = this.state.provinsi.value
                    const idKab = this.state.kabupaten.value
                    const idKec = this.state.kecamatan.value
                    const idKel = this.state.desa.value
                    const idRw = this.state.rw.value
                    const idRt = this.state.rt.value
                    const newPindah = {
                        ...dataPindah,
                        id_prov: idProv,
                        id_kab: idKab,
                        id_kec: idKec,
                        id_kel: idKel,
                        id_rw: idRw,
                        id_rt: idRt
                    }
                    // console.log(newPindah, 'data pindah')
                    APIMutasiKK.post('/pkportal/targetkk/mutasiKK', newPindah)
                        .then(res => {
                            if (res.status === 200) {
                                this.setState({ blocking: false, onSubmit: true })
                                Swal.fire({
                                    title: 'Sukses',
                                    icon: 'success',
                                    text: 'Data berhasil disimpan, halaman akan di muat ulang (5s) untuk memuat ulang data KK',
                                    allowOutsideClick: false,
                                    showConfirmButton: false,
                                    allowEscapeKey: false
                                })
                                setInterval(this.timerDown, 1000)
                                // this.setState({ openSave: false }, () => { this.props.onClose() })
                            } else {
                                this.setState({ blocking: false, onSubmit: false })
                                Swal.fire({
                                    title: 'Error',
                                    icon: 'warning',
                                    text: 'Data gagal disimpan',
                                });
                            }
                        }).catch((error) => {
                            this.setState({ blocking: false, onSubmit: false })
                            Swal.fire({
                                title: 'Error',
                                icon: 'warning',
                                text: 'Data gagal disimpan',
                            });
                        });
                }
            })
        }
    }


    handleOpenCloseSave = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openSave: !this.state.openSave })
    }

    timerDown = () => {
        this.setState({ timer: this.state.timer - 1 })
    }

    render() {
        const { wilayahId, rt, rw, desa, kecamatan, kabupaten, provinsi } = this.state
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                    <Dialog open={this.props.open} onClose={this.handleClose}
                        fullWidth maxWidth={'md'}>

                        <DialogTitle>Pemutakhiran Wilayah Tingkat {this.state.wilayah}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                RT: {this.state.kodeDepdagri + '-' + this.state.nama}
                            </DialogContentText>
                            <Box sx={{ width: '100%' }}>
                                <h4>Step 1. Pilih Wilayah Tujuan</h4>
                                <Accordion expanded={this.state.panel} onChange={this.handleChangePanel} className='accordionRoot' style={{ marginBottom: '1rem' }}>
                                    <AccordionSummary
                                        className='panelSummary'
                                        expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                    >
                                        <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                            <Grid item xs={12} md={12}>
                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl sx={{ width: '100%' }} size="small" >
                                                            Provinsi
                                                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                        </FormControl>
                                                    </Grid>
                                                    {+wilayahId >= 3 ?
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl sx={{ width: '100%' }} size="small">
                                                                Kabupaten
                                                                <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                            </FormControl>
                                                        </Grid>
                                                        :
                                                        <></>
                                                    }
                                                    {+wilayahId >= 4 ?
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl sx={{ width: '100%' }} size="small">
                                                                Kecamatan
                                                                <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                            </FormControl>
                                                        </Grid>
                                                        :
                                                        <></>
                                                    }
                                                    {+wilayahId >= 5 ?
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl sx={{ width: '100%' }} size="small">
                                                                Kelurahan
                                                                <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                            </FormControl>
                                                        </Grid>
                                                        :
                                                        <></>
                                                    }
                                                    {+wilayahId >= 6 ?
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl sx={{ width: '100%' }} size="small">
                                                                RW
                                                                <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                            </FormControl>
                                                        </Grid>
                                                        :
                                                        <></>
                                                    }
                                                    {+wilayahId >= 7 ?
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl sx={{ width: '100%' }} size="small">
                                                                RT
                                                                <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                            </FormControl>
                                                        </Grid>
                                                        :
                                                        <></>
                                                    }
                                                    <Grid item xs={12} md={4}>
                                                        <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px' }} variant="outlined"><ImSearch style={{ marginRight: '5px' }} /> Load Data</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <h4>Step 2. Pindah KK</h4>
                                <TransferList
                                    wilayah={this.state.nama}
                                    dataWilayah={this.state.dataWilayah}
                                    wilayahTujuan={rt ? rt : { label: '-' }}
                                    dataWilayahTujuan={this.state.dataWilayahTujuan}
                                    handleSelect={this.handleSelect}
                                    rightOpen={this.state.rightOpen}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleSimpan}>Simpan</Button>
                            <Button onClick={this.handleClose}>Batal</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.openSave} onClose={this.handleOpenCloseSave} fullWidth maxWidth={'md'}>
                        <DialogTitle>RINGKASAN PEMINDAHAN WILAYAH</DialogTitle>
                        <DialogContent>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Grid item xs={12} md={12}>
                                    Dari: <b>{this.state.nama}</b>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    Tujuan: <b>{this.state.namaTujuan}</b>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    Data Mutasi {this.state.tingkatWilayahTujuan}: {this.state.listPindah.replace(/,/g, ", ")}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    Jumlah Wilayah Terdampak: <br />
                                </Grid>
                                <Grid item xs={6}>
                                    - Jumlah Kabupaten: <b>{this.state.jumlahKabTerdampak}</b> <br />
                                    - Jumlah Kecamatan: <b>{this.state.jumlahKecTerdampak}</b> <br />
                                    - Jumlah Kelurahan: <b>{this.state.jumlahKelTerdampak}</b> <br />
                                    - Jumlah RW: <b>{this.state.jumlahRwTerdampak}</b> <br />
                                    - Jumlah RT: <b>{this.state.jumlahRtTerdampak}</b> <br />
                                    {/* </Grid>
                                    <Grid item xs={6}>
                                    - Jumlah KK: <b>{this.state.jumlahKkTerdampak}</b> <br />
                                    - Jumlah PUS: <b>{this.state.jumlahPusTerdampak}</b> <br />
                                    - Jumlah PUS Peserta KB: <b>{this.state.jumlahPusKbTerdampak}</b> <br /> */}
                                    - Jumlah User: <b>{this.state.jumlahUserTerdampak}</b> <br />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {this.state.onSubmit === false ?
                                <>
                                    <Button onClick={this.handleFinalSimpan} variant="contained" color="error">Lanjutkan Pindah Wilayah</Button>
                                    <Button onClick={this.handleOpenCloseSave} variant="outlined" color="primary">Batal</Button>
                                </> :
                                <div style={{ paddingRight: '1rem', fontWeight: '600' }}>
                                    Loading, please wait...
                                </div>
                            }
                        </DialogActions>
                    </Dialog>
                </BlockUi>
            </div >
        );
    }
}

export default TransferKKDialog;