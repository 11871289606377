import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { AiFillCaretRight, AiFillCaretDown, AiFillLock } from 'react-icons/ai';
import { ImPlus, ImLoop2, ImPencil, ImBin, ImSearch, ImStatsBars } from 'react-icons/im';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../services/APIWilayah';
import TransferDialog from '../TransferDialog';
import Swal from 'sweetalert2';

const isNumber = (str) => /^[0-9]*$/.test(str);

//---table rt---
class BSTableRt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panelKk: true,
            id: null,
            kodeDepdagri: '',
            nama: '',
            dataRt: [
                { 'id': '433655', 'nama_rt': '012', 'dibayar': '1453', 'pajak': '1322', 'diterima': '1323', 'targetKK': '13' },
                { 'id': '433435', 'nama_rt': '0113', 'dibayar': '1423', 'pajak': '400', 'diterima': '1500', 'targetKK': '13' }
            ],
            dataRtOld: this.props.data,
            tingkatWilayahId: this.props.tingkatWilayahId,
            roleId: this.props.roleId,
            blocking: false,
            statusDuplikat: false,
            kodeDepdagriLama: '',
            disabledSimpanRt: false,
            provinsiId: this.props.provinsiId,
            idKelamatan: this.props.idKelamatan,
            disKelAction: this.props.disKelAction,
            statusLockKel: this.props.statusLockKel,
            check: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.data?.length !== prevProps.data?.length) {
            this.setState({ dataRt: this.props.data, dataRtOld: this.props.data })
        }
        if (this.props.roleId !== prevProps.roleId) {
            this.setState({ roleId: this.props.roleId })
        }
        if (this.props.provinsiId !== prevProps.provinsiId) {
            this.setState({ provinsiId: this.props.provinsiId })
        }
        if (this.props.disKelAction !== prevProps.disKelAction) {
            this.setState({ disKelAction: this.props.disKelAction })
        }
        if (this.props.statusLockKel !== prevProps.statusLockKel) {
            console.log('rt statuts lock kel')
            this.setState({ statusLockKel: this.props.statusLockKel })
        }

    }

    handleFocus = (event) => event.target.select();

    resetValue = (id) => {
        const row = this.state.dataRtOld.find(el => el.id_rt === id)
        this.setState({ dataRt: this.state.dataRt.map(el => (el.id_rt === id ? Object.assign({}, el, row) : el)) })
    }

    runUpdateData = () => {
        this.setState({ blocking: true })
        const row = this.state.dataRt.find(el => el.id_rt === +this.state.id)
        APIWilayah.post('pkportal/targetkk/upsertTargetKKRt?id_rt=' + row.id_rt + '&periodeSensus=2022' + '&targetKK=' + row.targetKK + '&idKelamatan=' + this.props.idKelamatan)
            .then(res => {

                if (res.status === 200 && res.data.AddTargetKK === "Gagal, input melebihi batas maksimal") {
                    Swal.fire({
                        title: 'Gagal',
                        icon: 'error',
                        text: 'Gagal, input melebihi batas maksimal',
                    });
                    this.setState({ blocking: false });
                    this.resetValue(+this.state.id)
                } else if (res.status === 200 && res.data.AddTargetKK !== "Gagal, input melebihi batas maksimal") {
                    this.setState({ blocking: false },
                        () => {
                            Swal.fire({
                                title: 'Sukses',
                                icon: 'success',
                                text: 'Data berhasil disimpan',
                            });
                            this.props.handleChangeDataRt(this.state.dataRt)
                        }
                    );
                } else {
                    Swal.fire({
                        title: 'Gagal',
                        icon: 'error',
                        text: 'Target KK tidak boleh melebihi maksimal total target kecamatan',
                    });
                    this.setState({ blocking: false });
                    this.resetValue(+this.state.id)
                }
            }).catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    icon: 'error',
                    text: 'Data gagal disimpan',
                });
                this.setState({ blocking: false });
                this.resetValue(+this.state.id)
            });
    }

    handleBlur = (e) => {
        const val = Number(e.target.value)
        const name = e.target.name
        if (isNumber(val)) {
            this.setState({ [name]: val },
                () => {
                    const id = name.split('-')[0]
                    let row = this.state.dataRt.find(el => el.id_rt === +id)
                    row = { ...row, targetKK: val }
                    this.setState({ id: id, dataRt: this.state.dataRt.map(el => (el.id_rt === +id ? Object.assign({}, el, row) : el)) },
                        () => {
                            this.runUpdateData()
                        }
                    )
                }
            )
        }
    }

    handleChangeInput = (e) => {
        const val = Number(e.target.value)
        const name = e.target.name
        if (isNumber(val)) {
            this.setState({ [name]: val },
                () => {
                    const id = name.split('-')[0]
                    let row = this.state.dataRt.find(el => el.id_rt === +id)
                    row = { ...row, targetKK: val }
                    this.setState({ id: id, dataRt: this.state.dataRt.map(el => (el.id_rt === +id ? Object.assign({}, el, row) : el)) })
                }
            )
        }
    }

    keyPress = (e) => {
        if (e.keyCode == 13) {
            console.log('run enter')
            document.getElementById('nextFocus').focus();
            // const val = Number(e.target.value)
            // const name = e.target.name
            // if (isNumber(val)) {
            //   this.setState({ [name]: val },
            //     () => {
            //       const id = name.split('-')[0]
            //       let row = this.state.dataRt.find(el => el.id_rt === +id)
            //       row = { ...row, targetKK: val }
            //       this.setState({id:id, dataRt: this.state.dataRt.map(el => (el.id_rt === +id ? Object.assign({}, el, row) : el))},
            //         () => {
            //           this.runUpdateData()
            //         } 
            //       )
            //     }
            //   )
            // }
        }
    }

    buttonFormatter = (cell, row) => {
        if (this.state.statusLockKel === true || +this.state.roleId !== 6) {
            return <div className='divBtnTable'>
                {cell}
            </div>
        } else {
            return <div className='divBtnTable'>
                <TextField
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    id="filled-required"
                    label=""
                    variant="standard"
                    onChange={this.handleChangeInput}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    onKeyDown={this.keyPress}
                    value={cell}
                    name={row.id_rt + "-target"}
                    fullWidth
                />
            </div>



        }
        //       return <div className='divBtnTable'>
        //   <TextField
        //     inputProps={{ min: 0, style: { textAlign: 'center' } }}
        //     id="filled-required"
        //     label=""
        //     variant="standard"
        //     onChange={this.handleChangeInput}
        //     onBlur={this.handleBlur}
        //     onFocus={this.handleFocus}
        //     onKeyDown={this.keyPress}
        //     value={cell}
        //     name={row.id_rt + "-target"}
        //     fullWidth
        //   />
        // </div>

    }

    // buttonFormatter2 = (cell, row) => {
    //       return <div className='divBtnTable'>
    //       {cell}
    //     </div>
    // }

    columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
        if (row.statusDuplikat === true) {
            return 'edafaf'
        } else {
            return ''
        }
    }

    render() {
        const options = {
            expandRowBgColor: '#F5F5DC',
            expandBy: 'column',

            page: 1,
            sizePerPage: 5,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: '<',
            nextPage: '>',
            firstPage: '<<',
            lastPage: '>>',
            paginationShowsTotal: false,
            paginationPosition: 'bottom',
            hideSizePerPage: true
        };
        return (
            <>
                <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                    <Accordion expanded={this.props.panelRt} className='accordionRoot panelExpand'>
                        <AccordionSummary
                            className='panelSummary'
                            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.props.handleChangePanelRt} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: '12px' }}><b>Data RT</b> di RW {this.props.row.nama_rw}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='panelDetail'>
                            {(this.state.dataRt) ?
                                <div tabIndex="-1" id="nextFocus" className='no-outline'>
                                    <BootstrapTable data={this.state.dataRt}
                                        pagination={true}
                                        search={true}
                                        options={options}
                                    >
                                        <TableHeaderColumn width='120px' dataField='id' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode RT</TableHeaderColumn>
                                        <TableHeaderColumn dataField='nama_rt' headerAlign='center' expandable={false}>Nama RT</TableHeaderColumn>
                                        <TableHeaderColumn dataField='dibayar' headerAlign='center' expandable={false}>Jumlah Yang Dibayarkan</TableHeaderColumn>
                                        <TableHeaderColumn dataField='pajak' headerAlign='center' expandable={false}>Pajak</TableHeaderColumn>
                                        <TableHeaderColumn dataField='diterima' headerAlign='center' expandable={false}>Jumlah Yang Diterima</TableHeaderColumn>
                                        {/* <TableHeaderColumn width='120px' dataField='targetKK' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Target</TableHeaderColumn> */}

                                    </BootstrapTable>
                                    <div className='totalDataExpand'>Total Data: {this.state.dataRt?.length}</div>
                                </div>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Tidak ada data</div>
                            }
                        </AccordionDetails>
                    </Accordion>
                </BlockUi>
            </>
        )
    }
}

//---table rw---
class BSTableRw extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panelRt: true,
            id: null,
            kodeDepdagri: '',
            nama: '',
            dataRw: [{ 'id': '4335', 'nama_rw': '003', 'dibayar': '14353', 'pajak': '13232', 'diterima': '13223', 'targetKK': '133' }],
            roleId: this.props.roleId,
            blocking: false,
            kodeDepdagriLama: '',
            disabledSimpanRw: false,
            provinsiId: this.props.provinsiId,
            idKelamatan: this.props.idKelamatan,
            disKelAction: this.props.disKelAction,
            statusLockKel: this.props.statusLockKel,

        }
    }

    componentDidUpdate = (prevProps) => {

        if (this.props.data?.length !== prevProps.data?.length) {
            this.setState({ dataRw: this.props.data })
        }
        if (this.props.roleId !== prevProps.roleId) {
            this.setState({ roleId: this.props.roleId })
        }
        if (this.props.provinsiId !== prevProps.provinsiId) {
            this.setState({ provinsiId: this.props.provinsiId })
        }
        if (this.props.disKelAction !== prevProps.disKelAction) {
            this.setState({ disKelAction: this.props.disKelAction })
        }
        if (this.props.statusLockKel !== prevProps.statusLockKel) {
            console.log("sdasdasdasdas")
            this.setState({ statusLockKel: this.props.statusLockKel })
        }
    }

    isExpandableRow(row) {
        return true
    }

    handleChangePanelRt = (e) => {
        e.preventDefault();
        this.setState({ panelRt: !this.state.panelRt })
    }

    handleChangeDataRt = (newData) => {
        let newTarget = 0
        newData.forEach(el => {
            if (el.targetKK) {
                newTarget += +el.targetKK
            }
        });
        const id_rw = newData[0].id_rw
        this.setState({ dataRw: this.state.dataRw.map(el => el.id_rw === +id_rw ? Object.assign({}, el, { targetKK: newTarget }) : el) },
            () => {
                this.props.handleChangeDataRW(this.state.dataRw)
            }
        )
    }

    expandComponent = (row) => {
        return (
            <BSTableRt row={row} data={row.children} handleChangePanelRt={this.handleChangePanelRt}
                handleChangeDataRt={this.handleChangeDataRt} panelRt={this.state.panelRt} idKelamatan={this.props.idKelamatan}
                tingkatWilayahId={this.state.tingkatWilayahId} roleId={this.state.roleId} provinsiId={this.state.provinsiId}
                statusLockKel={this.state.statusLockKel} />
        );
    }

    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
        } else {
            content = ' ';
        }
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
        );
    }

    buttonFormatter = (cell, row) => {
        return <div className='divBtnTable'>
            {cell}
        </div>
    }

    handleOpenCloseAddRw = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openAddRw: !this.state.openAddRw },
            () => { if (!this.state.openAddRw) { this.clearForm() } }
        )
    }

    handleOpenClosePindahRw = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openPindahRw: !this.state.openPindahRw })
    }

    onRowClick = (row) => {
        if (!row.hasOwnProperty('children')) {
            this.setState({ blocking: true })
            APIWilayah.get('/pkportal/targetkk/getListRtByIdRw?id_rw=' + row.id_rw)
                .then(res => {
                    this.setState({ blocking: false, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, provinsiId: this.props.provinsiId, dataRw: this.state.dataRw.map(el => (el.id_rw === row.id_rw ? { ...el, children: res.data } : el)) });
                }).catch((error) => {
                    this.setState({ blocking: false })
                    console.log(error, 'err')
                });
        }
    }

    columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
        if (row.statusDuplikat === true) {
            return 'edafaf'
        } else {
            return ''
        }
    }

    render() {
        const options = {
            onRowClick: this.onRowClick,
            expandRowBgColor: '#F5F5DC',
            expandBy: 'column',

            page: 1,
            sizePerPage: 5,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: '<',
            nextPage: '>',
            firstPage: '<<',
            lastPage: '>>',
            paginationShowsTotal: false,
            paginationPosition: 'bottom',
            hideSizePerPage: true
        };
        return (
            <>
                <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                    <Accordion expanded={this.props.panelRw} className='accordionRoot panelExpand'>
                        <AccordionSummary
                            className='panelSummary'
                            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.props.handleChangePanelRw} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: '12px' }}><b>Data RW</b> di Kelurahan {this.props.row.nama_kelurahan}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='panelDetail'>
                            {(this.state.dataRw) ?
                                <>
                                    <BootstrapTable data={this.state.dataRw}
                                        pagination={true}
                                        search={true}
                                        options={options}
                                        expandableRow={this.isExpandableRow}
                                        expandComponent={this.expandComponent}
                                        expandColumnOptions={{
                                            expandColumnVisible: true,
                                            expandColumnComponent: this.expandColumnComponent,
                                            columnWidth: 40
                                        }}>
                                        <TableHeaderColumn width='120px' dataField='id' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode RW</TableHeaderColumn>
                                        <TableHeaderColumn dataField='nama_rw' headerAlign='center' expandable={false}>Nama RW</TableHeaderColumn>
                                        <TableHeaderColumn dataField='dibayar' headerAlign='center' expandable={false}>Jumlah Yang Dibayarkan</TableHeaderColumn>
                                        <TableHeaderColumn dataField='pajak' headerAlign='center' expandable={false}>Pajak</TableHeaderColumn>
                                        <TableHeaderColumn dataField='diterima' headerAlign='center' expandable={false}>Jumlah Yang Diterima</TableHeaderColumn>
                                        {/* <TableHeaderColumn width='120px' dataField='targetKK' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Target</TableHeaderColumn> */}
                                    </BootstrapTable>
                                    <div className='totalDataExpand'>Total Data: {this.state.dataRw?.length}</div>
                                </>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Tidak ada data</div>
                            }
                        </AccordionDetails>
                    </Accordion>
                </BlockUi>
            </>
        )
    }
}

//---table kel---
class BSTableKel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panel: true,
            panelRw: true,
            id: null,
            kodeDepdagri: '',
            kelData: [{'id' : '1','nama_kelurahan' : 'BANJARAN','dibayar' : '14353','pajak' : '13232','diterima' : '13223','targetKK' : '133' }],
            nama: '',
            roleId: 0,
            defaultData: this.props.defaultData,
            blocking: false,
            disabledSimpankel: false,
            kecamatan: this.props.kecamatan,
            disKelAction: this.props.disKelAction,
            statusLockKel: this.props.statusLockKel,
            disBtn: this.props.disBtn,
            timer: 3
        }
    }

    componentDidUpdate = (prevProps) => {

        // if (this.props.kelData.length !== prevProps.kelData.length) {
        //     this.setState({ kelData: this.props.kelData })
        // }
        if (this.props.roleId !== prevProps.roleId) {
            this.setState({ roleId: this.props.roleId })
        }
        if (this.props.kecamatan !== prevProps.kecamatan) {
            this.setState({ kecamatan: this.props.kecamatan })
        }
        if (this.props.disKelAction !== prevProps.disKelAction) {
            this.setState({ disKelAction: this.props.disKelAction })
        }
        if (this.props.statusLockKel !== prevProps.statusLockKel) {
            this.setState({ statusLockKel: this.props.statusLockKel })
        }

        if (this.state.timer === 1) {
            window.location.reload()
        }



    }

    handleChangePanel = (e) => {
        e.preventDefault();
        this.setState({ panel: !this.state.panel, kode_depdagri_kelurahan: '', nama_kelurahan: '' })
    }

    isExpandableRow(row) {
        return true
    }

    handleChangePanelRw = (e) => {
        e.preventDefault();
        this.setState({ panelRw: !this.state.panelRw })
    }

    handleChangeDataRW = (dataRw) => {
        let newTarget = 0
        dataRw.forEach(el => {
            if (el.targetKK) {
                newTarget += +el.targetKK
            }
        });
        const id_kelurahan = dataRw[0].id_kelurahan
        this.setState({ defaultData: this.state.defaultData.map(el => el.id_kelurahan === +id_kelurahan ? Object.assign({}, el, { targetKK: newTarget }) : el) },
            () => {
                this.props.updateHeader()
            }
        )
    }


    expandComponent = (row) => {

        return (
            <BSTableRw row={row} data={row.children} handleChangePanelRw={this.handleChangePanelRw} panelRw={this.state.panelRw}
                roleId={this.state.roleId} handleChangeDataRW={this.handleChangeDataRW} idKelamatan={this.props.kecamatan?.value}
                statusLockKel={this.state.statusLockKel} />
        );
    }

    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
        } else {
            content = ' ';
        }
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
        );
    }

    buttonFormatter = (cell, row) => {
        return <div className='divBtnTable'>
            {cell}
        </div>
    }

    onRowClick = (row) => {


        if (!row.hasOwnProperty('children')) {
            this.setState({ blocking: true })
            APIWilayah.get('pkportal/targetkk/getListRwByIdKelurahan?id_kelurahan=' + row.id_kelurahan)
                .then(res => {
                    this.setState({ blocking: false, roleId: this.props.roleId, defaultData: this.state.defaultData.map(el => (el.id_kelurahan === row.id_kelurahan ? { ...el, children: res.data } : el)) });
                }).catch((error) => {
                    this.setState({ blocking: false })
                    console.log(error, 'err')
                });
        }
    }

    columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
        if (row.statusDuplikat === true) {
            return 'edafaf'
        } else {
            return ''
        }
    }

    handleLockTargetKK = (prevProps, action) => {
        // console.log('id kec', this.state.kecamatan.value)
        // console.log('kec', this.state.kecamatan)

        if (action === "UnLock") {
            Swal.fire({
                title: 'Peringatan',
                text: "Apakah anda yakin untuk " + action + " Target KK Kelamatan ini ?",
                icon: 'warning',
                cancelButtonText: 'Batal!',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya!',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
            }).then((result) => {

                if (result.value) {
                    this.setState({ blocking: true })

                    APIWilayah.post('/pkportal/targetkk/statusLockTargetKK?idKelamatan=' + this.state.kecamatan.value + '&periodeSensus=2022' + '&statusLock=false' + '&username=' + localStorage.getItem('username'))
                        .then(res => {
                            if (res.status === 200) {
                                Swal.fire({
                                    title: 'Sukses',
                                    icon: 'success',
                                    text: 'Data berhasil diunlock',
                                    // allowOutsideClick: false,
                                    //               showConfirmButton: false,
                                    //               allowEscapeKey: false
                                })
                                // setInterval(this.timerDown, 1000)
                                this.setState({
                                    blocking: false, statusLockKel: false, disBtn: false
                                }, () => console.log('status lock edit', this.state.statusLockKel))
                            } else {
                                Swal.fire({
                                    title: 'Error',
                                    icon: 'warning',
                                    text: 'Data gagal diunlock',

                                });
                                this.setState({ blocking: false })
                            }
                        }).catch((error) => {
                            Swal.fire({
                                title: 'Error',
                                icon: 'warning',
                                text: 'Data gagal diunlock',
                            });
                            this.setState({ blocking: false })
                        })

                }

            })
        } else {
            if (action === "Lock") {
                Swal.fire({
                    title: 'Peringatan',
                    text: "Apakah anda yakin untuk " + action + " Target KK Kelamatan ini ?",
                    icon: 'warning',
                    cancelButtonText: 'Batal!',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya!',
                    confirmButtonColor: '#3085d6',
                    showCancelButton: true,
                }).then((result) => {

                    if (result.value) {
                        this.setState({ blocking: true })

                        APIWilayah.post('/pkportal/targetkk/statusLockTargetKK?idKelamatan=' + this.state.kecamatan.value + '&periodeSensus=2022' + '&statusLock=true' + '&username=' + localStorage.getItem('username'))
                            .then(res => {
                                if (res.status === 200) {
                                    Swal.fire({
                                        title: 'Sukses',
                                        icon: 'success',
                                        text: 'Data berhasil dilock',
                                        // allowOutsideClick: false,
                                        //             showConfirmButton: false,
                                        //             allowEscapeKey: false
                                    })
                                    // setInterval(this.timerDown, 1000)


                                    this.setState({
                                        blocking: false, statusLockKel: true, disBtn: false
                                    }, () => console.log('status lock edit', this.state.statusLockKel))
                                } else {
                                    Swal.fire({
                                        title: 'Error',
                                        icon: 'warning',
                                        text: 'Data gagal dilock',
                                    });
                                    this.setState({ blocking: false })
                                }
                            }).catch((error) => {
                                Swal.fire({
                                    title: 'Error',
                                    icon: 'warning',
                                    text: 'Data gagal dilock',
                                });
                                this.setState({ blocking: false })
                            })

                    }

                })
            }
        }
    }

    timerDown = () => {
        this.setState({ timer: this.state.timer - 1 })
    }

    render() {

        const options = {
            onRowClick: this.onRowClick,
            expandRowBgColor: '#F5F5DC',
            expandBy: 'column',

            page: 1,
            sizePerPage: 5,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: '<',
            nextPage: '>',
            firstPage: '<<',
            lastPage: '>>',
            paginationShowsTotal: false,
            paginationPosition: 'bottom',
            hideSizePerPage: true
        };
        return (
            <>
                <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                    <Accordion expanded={this.state.panel} className='accordionRoot'>
                        <AccordionSummary
                            className='panelSummary'
                            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.handleChangePanelKel} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: '12px' }}><b>Data Kelurahan</b> {this.props.kecamatan ? 'di Kelamatan ' + this.props.kecamatan.nama : ''}</Typography>
                            {+this.state.roleId === 3 ?
                                (this.state.statusLockKel === true ?
                                    <div className='divBtnPanel'>
                                        <Button onClick={(e) => this.handleLockTargetKK(e, "UnLock")} className='btnPanel' ><AiFillLock style={{ marginRight: '3px' }} /> UnLock</Button>
                                    </div>

                                    :
                                    <>
                                        <div className='divBtnPanel'>
                                            <Button style={{ color: 'red' }} className='btnPanel' onClick={(e) => this.handleLockTargetKK(e, "Lock")} ><AiFillLock style={{ marginRight: '3px', color: 'red' }} /> Lock</Button>

                                        </div>
                                    </>
                                )
                                :
                                <></>
                            }
                        </AccordionSummary>
                        <AccordionDetails className='panelDetail'>
                            <BootstrapTable data={this.state.kelData}
                                pagination={true}
                                search={true}
                                options={options}
                                expandableRow={this.isExpandableRow}
                                expandComponent={this.expandComponent}

                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    expandColumnComponent: this.expandColumnComponent,
                                    columnWidth: 40
                                }}>
                                <TableHeaderColumn width='120px' dataField='id' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode Kelurahan</TableHeaderColumn>
                                <TableHeaderColumn dataField='nama_kelurahan' headerAlign='center' expandable={false}>Nama Kelurahan</TableHeaderColumn>
                                <TableHeaderColumn dataField='dibayar' headerAlign='center' expandable={false}>Jumlah Yang Dibayarkan</TableHeaderColumn>
                                <TableHeaderColumn dataField='pajak' headerAlign='center' expandable={false}>Pajak</TableHeaderColumn>
                                <TableHeaderColumn dataField='diterima' headerAlign='center' expandable={false}>Jumlah Yang Diterima</TableHeaderColumn>
                                {/* <TableHeaderColumn dataField='targetKK' width='120px' headerAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Target</TableHeaderColumn> */}
                            </BootstrapTable>
                            <div className='totalDataExpand'>Total Data: {this.state.defaultData?.length}</div>
                        </AccordionDetails>
                    </Accordion>
                </BlockUi>
            </>
        );
    }
}
export default class TableTarget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panel: true,
            panelKel: true,
            id: null,
            kodeDepdagri: '',
            kelData: [{'id' : '1','nama_kelurahan' : 'BANJARAN','dibayar' : '14353','pajak' : '13232','diterima' : '13223','targetKK' : '133' }],
            nama: '',
            roleId: 0,
            defaultData: this.props.defaultData,
            blocking: false,
            disabledSimpankel: false,
            kecamatan: this.props.kecamatan,
            disKelAction: this.props.disKelAction,
            statusLockKel: this.props.statusLockKel,
            disBtn: this.props.disBtn,
            timer: 3
        }
    }

    componentDidUpdate = (prevProps) => {

        if (this.props.defaultData.length !== prevProps.defaultData.length) {
            this.setState({ defaultData: this.props.defaultData })
        }
        if (this.props.roleId !== prevProps.roleId) {
            this.setState({ roleId: this.props.roleId })
        }
        if (this.props.kecamatan !== prevProps.kecamatan) {
            this.setState({ kecamatan: this.props.kecamatan })
        }
        if (this.props.disKelAction !== prevProps.disKelAction) {
            this.setState({ disKelAction: this.props.disKelAction })
        }
        if (this.props.statusLockKel !== prevProps.statusLockKel) {
            this.setState({ statusLockKel: this.props.statusLockKel })
        }

        if (this.state.timer === 1) {
            window.location.reload()
        }



    }

    handleChangePanel = (e) => {
        e.preventDefault();
        this.setState({ panel: !this.state.panel, kode_depdagri_kelurahan: '', nama_kelurahan: '' })
    }

    isExpandableRow(row) {
        return true
    }

    handleChangePanelKel = (e) => {
        e.preventDefault();
        this.setState({ panelKel: !this.state.panelKel })
    }

    handleChangeDataKel = (dataKel) => {
        let newTarget = 0
        dataKel.forEach(el => {
            if (el.targetKK) {
                newTarget += +el.targetKK
            }
        });
        const id_kelurahan = dataKel[0].id_kelurahan
        this.setState({ defaultData: this.state.defaultData.map(el => el.id_kelurahan === +id_kelurahan ? Object.assign({}, el, { targetKK: newTarget }) : el) },
            () => {
                this.props.updateHeader()
            }
        )
    }


    expandComponent = (row) => {

        return (
            <BSTableKel row={row} data={row.children} handleChangePanelKel={this.handleChangePanelKel} panelKel={this.state.panelKel}
                roleId={this.state.roleId} handleChangeDataKel={this.handleChangeDataKel} idKelamatan={this.props.kecamatan?.value}
                statusLockKel={this.state.statusLockKel} />
        );
    }

    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
        } else {
            content = ' ';
        }
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
        );
    }

    buttonFormatter = (cell, row) => {
        return <div className='divBtnTable'>
            {cell}
        </div>
    }

    onRowClick = (row) => {


        if (!row.hasOwnProperty('children')) {
            this.setState({ blocking: true })
            APIWilayah.get('pkportal/targetkk/getListKelByIdKelurahan?id_kelurahan=' + row.id_kelurahan)
                .then(res => {
                    this.setState({ blocking: false, roleId: this.props.roleId, defaultData: this.state.defaultData.map(el => (el.id_kelurahan === row.id_kelurahan ? { ...el, children: res.data } : el)) });
                }).catch((error) => {
                    this.setState({ blocking: false })
                    console.log(error, 'err')
                });
        }
    }

    columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
        if (row.statusDuplikat === true) {
            return 'edafaf'
        } else {
            return ''
        }
    }

    handleLockTargetKK = (prevProps, action) => {
        // console.log('id kec', this.state.kecamatan.value)
        // console.log('kec', this.state.kecamatan)

        if (action === "UnLock") {
            Swal.fire({
                title: 'Peringatan',
                text: "Apakah anda yakin untuk " + action + " Target KK Kelamatan ini ?",
                icon: 'warning',
                cancelButtonText: 'Batal!',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya!',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
            }).then((result) => {

                if (result.value) {
                    this.setState({ blocking: true })

                    APIWilayah.post('/pkportal/targetkk/statusLockTargetKK?idKelamatan=' + this.state.kecamatan.value + '&periodeSensus=2022' + '&statusLock=false' + '&username=' + localStorage.getItem('username'))
                        .then(res => {
                            if (res.status === 200) {
                                Swal.fire({
                                    title: 'Sukses',
                                    icon: 'success',
                                    text: 'Data berhasil diunlock',
                                    // allowOutsideClick: false,
                                    //               showConfirmButton: false,
                                    //               allowEscapeKey: false
                                })
                                // setInterval(this.timerDown, 1000)
                                this.setState({
                                    blocking: false, statusLockKel: false, disBtn: false
                                }, () => console.log('status lock edit', this.state.statusLockKel))
                            } else {
                                Swal.fire({
                                    title: 'Error',
                                    icon: 'warning',
                                    text: 'Data gagal diunlock',

                                });
                                this.setState({ blocking: false })
                            }
                        }).catch((error) => {
                            Swal.fire({
                                title: 'Error',
                                icon: 'warning',
                                text: 'Data gagal diunlock',
                            });
                            this.setState({ blocking: false })
                        })

                }

            })
        } else {
            if (action === "Lock") {
                Swal.fire({
                    title: 'Peringatan',
                    text: "Apakah anda yakin untuk " + action + " Target KK Kelamatan ini ?",
                    icon: 'warning',
                    cancelButtonText: 'Batal!',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya!',
                    confirmButtonColor: '#3085d6',
                    showCancelButton: true,
                }).then((result) => {

                    if (result.value) {
                        this.setState({ blocking: true })

                        APIWilayah.post('/pkportal/targetkk/statusLockTargetKK?idKelamatan=' + this.state.kecamatan.value + '&periodeSensus=2022' + '&statusLock=true' + '&username=' + localStorage.getItem('username'))
                            .then(res => {
                                if (res.status === 200) {
                                    Swal.fire({
                                        title: 'Sukses',
                                        icon: 'success',
                                        text: 'Data berhasil dilock',
                                        // allowOutsideClick: false,
                                        //             showConfirmButton: false,
                                        //             allowEscapeKey: false
                                    })
                                    // setInterval(this.timerDown, 1000)


                                    this.setState({
                                        blocking: false, statusLockKel: true, disBtn: false
                                    }, () => console.log('status lock edit', this.state.statusLockKel))
                                } else {
                                    Swal.fire({
                                        title: 'Error',
                                        icon: 'warning',
                                        text: 'Data gagal dilock',
                                    });
                                    this.setState({ blocking: false })
                                }
                            }).catch((error) => {
                                Swal.fire({
                                    title: 'Error',
                                    icon: 'warning',
                                    text: 'Data gagal dilock',
                                });
                                this.setState({ blocking: false })
                            })

                    }

                })
            }
        }
    }

    timerDown = () => {
        this.setState({ timer: this.state.timer - 1 })
    }

    render() {

        const options = {
            onRowClick: this.onRowClick,
            expandRowBgColor: '#F5F5DC',
            expandBy: 'column',

            page: 1,
            sizePerPage: 5,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: '<',
            nextPage: '>',
            firstPage: '<<',
            lastPage: '>>',
            paginationShowsTotal: false,
            paginationPosition: 'bottom',
            hideSizePerPage: true
        };
        return (
            <>
                <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                    <Accordion expanded={this.state.panel} className='accordionRoot'>
                        <AccordionSummary
                            className='panelSummary'
                            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.handleChangePanel} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: '12px' }}><b>Data Kecamatan</b> {this.props.kecamatan ? 'di Kelamatan ' + this.props.kecamatan.nama : ''}</Typography>
                            {+this.state.roleId === 3 ?
                                (this.state.statusLockKel === true ?
                                    <div className='divBtnPanel'>
                                        <Button onClick={(e) => this.handleLockTargetKK(e, "UnLock")} className='btnPanel' ><AiFillLock style={{ marginRight: '3px' }} /> UnLock</Button>
                                    </div>

                                    :
                                    <>
                                        <div className='divBtnPanel'>
                                            <Button style={{ color: 'red' }} className='btnPanel' onClick={(e) => this.handleLockTargetKK(e, "Lock")} ><AiFillLock style={{ marginRight: '3px', color: 'red' }} /> Lock</Button>

                                        </div>
                                    </>
                                )
                                :
                                <></>
                            }
                        </AccordionSummary>
                        <AccordionDetails className='panelDetail'>
                            <BootstrapTable data={this.state.defaultData}
                                pagination={true}
                                search={true}
                                options={options}
                                expandableRow={this.isExpandableRow}
                                expandComponent={this.expandComponent}

                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    expandColumnComponent: this.expandColumnComponent,
                                    columnWidth: 40
                                }}>
                                <TableHeaderColumn width='120px' dataField='id' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode Kecamatan</TableHeaderColumn>
                                <TableHeaderColumn dataField='nama_kelurahan' headerAlign='center' expandable={false}>Nama Kecamatan</TableHeaderColumn>
                                <TableHeaderColumn dataField='dibayar' headerAlign='center' expandable={false}>Jumlah Yang Dibayarkan</TableHeaderColumn>
                                <TableHeaderColumn dataField='pajak' headerAlign='center' expandable={false}>Pajak</TableHeaderColumn>
                                <TableHeaderColumn dataField='diterima' headerAlign='center' expandable={false}>Jumlah Yang Diterima</TableHeaderColumn>
                                {/* <TableHeaderColumn dataField='targetKK' width='120px' headerAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Target</TableHeaderColumn> */}
                            </BootstrapTable>
                            <div className='totalDataExpand'>Total Data: {this.state.defaultData?.length}</div>
                        </AccordionDetails>
                    </Accordion>
                </BlockUi>
            </>
        );
    }
}