import React, { Component } from 'react'
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';

import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../../services/APIWilayah';
import APIVerval from "../../../../services/APIVerval";
import APIPemutakhiranMonitoring from "../../../../services/APIPemutakhiranMonitoring";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock, AiOutlineVerticalAlignBottom, AiFillFilePdf } from "react-icons/ai";
import { ImSearch, ImBin, ImCross, ImFloppyDisk, ImLoop2, ImMap, ImMap2, ImPlay3, ImPlus } from 'react-icons/im';
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill, BsInfoCircle } from "react-icons/bs";
import APIDashboard from "../../../../services/APIDashboard";
import APIReport from '../../../../services/APIReport';
import CommonTable from '../../../../commons/table/Table';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import { BootstrapTable, TableHeaderColumn, Table } from 'react-bootstrap-table';
import { FileUploader } from "react-drag-drop-files";
import APIPemutakhiran from '../../../../services/APIPemutakhiran';
import APIUpload from '../../../../services/APIUpload';
import APIButton from '../../../../services/APIButtonAkses';


import APIMonitoring from '../../../../services/APIMonitoringBackend';

const fileTypes = ["PDF", "JPG", "PNG"];
const isNumber = (str) => /^[0-9]*$/.test(str);
class KRSPengumpulan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panel1: true,
      sizePerPage: 10,
      title: '',

      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      openDialog: false,
      datas: [],
      no: "",
      openInfo: false,
      role: JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
      sdm: [
        { value: '4', label: 'Supervisor' },
        { value: '10', label: 'Operator' },
      ],
      filterSDM: [], selectedSDM: null,
      sdmValue: '',
      disAprrovaladmin: false,
      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,
      selectedRows: [], rowDipilih: [],

      onSubmit: false,
      disAprrovalProv: false,
      duplicate: 0,
      susulan: 0,
      roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
    }
  }

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      }, () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if (role === 1 || role === 99) {
          this.setState({
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
          })
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }

            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  handleOpenClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openDialog: !this.state.openDialog },
      () => { if (!this.state.openDialog) { this.clearForm() } }
    )
  }

  clearForm = () => {
    this.setState({})
  }

  handleOpenCloseInfo = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openInfo: !this.state.openInfo })
  }

  componentDidMount() {
    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    const idTingkatWilayah2 = rest[0].attributes.user.alamat_provinsi === 0 ? rest[0].attributes.user.wilProvinsi.id_provinsi : rest[0].attributes.user.alamat_provinsi;
    let created = new Date().toISOString().substring(0, 10)
    let temp = new Date("2023-06-25").toISOString().substring(0, 10)
    let title2, text11, text21

    if (idTingkatWilayah == 0) {
      title2 = ''

      this.setState({
        sendDatas: {
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      },
        () => { this.getBackend(this.state.sendDatas) }
      )


    } else {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan?.nama_kecamatan

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });
    }

    this.setState({ title: title2, text1: text11, text2: text21 }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
              }
            }
          );


          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {

    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
              id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
          if (this.state.id_provinsi) {
            this.setState({ blocking: true })
            APIButton.get("/aktif/getTombolAktifByProvId?provinsiId=" + this.state.id_provinsi)
              .then((res) => {

                this.setState({ blocking: false });
                if (res.status === 200) {
                  this.setState(
                    {
                      disAprrovalProv: res.data[0].ButtonStatus, disAprrovaladmin: res.data[0].ButtonStatus
                    }

                  )
                }

              })
              .catch((error) => {
                this.setState({ blocking: false });

              });
          }
        }
      )
    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        disAprrovalProv: false, disAprrovaladmin: false
      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true,
              id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true,
              id_des: 0, id_rw: 0, id_rt: 0,
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode,
        rw: null, rt: null, dis_rw: false, dis_rt: true,
        id_rw: 0, id_rt: 0,
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {

          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
        id_rt: 0,
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0,

      },
        () => {
          if (e === null) {
          }
        }
      )
    }
  }

  //-----end wilayah

  sel_sdm = (e) => {
    if (e) {
      this.setState({
        filterSDM: e, selectedSDM: e.value,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        filterSDM: null, selectedSDM: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 }
    )
  }

  buttonFormatter = (cell, row) => {

    let label = ''
    if (row.status_approval === 1) {
      label = 'Pending Verifikator'
    } else if (row.status_approval === 2) {
      label = 'Pending PK'
    } else if (row.status_approval === 4) {
      label = 'Reject Verifikator'
    } else if (row.status_approval === 5) {
      label = 'Reject PK'
    } else if (row.status_approval === 6) {
      label = 'Reject PPK'
    } else if (row.status_approval === 7) {
      label = 'Reject BPP'
    }
    return <div>{label}</div>

  }

  handleSearch = () => {
    this.setState({ selectedRows: [], blocking: true })
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw, selectedSDM, selectedUserRole, roleUserLogin } = this.state

    const reqParam = {
      "idProv": +id_provinsi,
      "idKab": id_kabupaten,
      "idKec": id_kecamatan,
      "idKel": id_des,
    }
    if (id_des !== 0) {
      APIVerval.post('siga/pembayaranverval/getRekapPembayaranVervalPengumpul?periodeSensus=' + this.state.value_priode, reqParam)
        .then((res) => {

          if (res.status === 200) {
            let cekdata = [];

            res.data.data.forEach(data => {
              let verval = data.jumlahkk_ada + data.jumlahkk_baru;

              data = Object.assign({ terverval: verval }, data);
              cekdata.push(data);
            });
            this.setState({ datas: cekdata, selectedRows: [], blocking: false }, () => {
            })
          } else {
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              text: 'Pencarian Data Gagal, Harap Coba lagi!',
            })
            this.setState({ blocking: false });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error!',
            icon: 'warning',
            text: 'Pencarian Data Gagal, Harap Coba lagi!',
          })
          this.setState({ blocking: false });
        });
    } else {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Filter wilayah harus dipilih minimal tingkat Kelurahan',
        showConfirmButton: true
      })
      this.setState({ blocking: false });
    }
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  buttonFormatterLokus = (cell, row) => {

    if (row.lokus === true) {
      return <div>LOKUS</div>
    } else {
      return <div>BUKAN LOKUS</div>
    }

  }

  handleSelectRow = (cat, row, isSelect) => {
    if (isSelect === true && cat === 'single') {
      this.setState({ selectedRows: this.state.selectedRows.concat(row) });
    } else if (isSelect === true && cat === 'all') {
      this.setState({ selectedRows: row });
    } else if (cat === 'single') {
      var array = this.state.selectedRows;
      var index = array.findIndex(el => el.id_user === row.id_user);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ selectedRows: array });
      }
    } else {
      this.setState({ selectedRows: [] });
    }


  }

  render() {

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: '#e3e8e4',
      size: '10px',

      headerColumnStyle: { backgroundColor: 'blue' },
      // marginLeft: 'auto',
      // marginRight:'auto'
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handleSelectRow('single', row, isSelect)
      },
      onSelectAll: (isSelect, rows, e) => {
        if (isSelect) {
          this.handleSelectRow('all', rows, isSelect)
          return this.state.datas.map(row => row.id_user)
        } else {
          this.handleSelectRow('all', rows, isSelect)
          return [];
        }
      }
    };
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "1",
          value: 1,
        },
        {
          text: "10",
          value: 10,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "<", // Previous page button text
      nextPage: ">", // Next page button text
      firstPage: "<<", // First page button text
      lastPage: ">>", // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
    };

    // const footerData = [
    //   [
    //     {
    //       label: 'Total',
    //       columnIndex: 0
    //     },
    //     {
    //       label: 'Total value',
    //       columnIndex: 1,
    //       align: 'right',
    //       formatter: (tableData) => {
    //         let label = 0;
    //         for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
    //           label += tableData[i].price;
    //         }
    //         return (
    //           <strong>{ label }</strong>
    //         );
    //       }
    //     },
    //     {
    //       label: 'Total value',
    //       columnIndex: 2,
    //       align: 'right',
    //       formatter: (tableData) => {
    //         let label = 0;
    //         for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
    //           label += tableData[i].price;
    //         }
    //         return (
    //           <strong>{ label }</strong>
    //         );
    //       }
    //     }
    //   ]
    // ];
    const tableStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      overflowX: 'auto', // Enable horizontal scrolling
    };

    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Approval Pengumpulan</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>

            <h3>Pengumpulan Data KRS</h3>
            <Paper sx={{ width: "100%", boxShadow: "none" }}>

              <div className="rekap-table" >
                <BootstrapTable
                  data={this.state.datas}
                  pagination={true}
                  keyField='id_user'
                  options={options}
                  search={true}
                  // footerData={footerData}
                  // footer
                // selectRow= { selectRow }
                >

                  <TableHeaderColumn row="0" rowSpan='4' width="80"  headerAlign="center" dataAlign="center"  >STATUS</TableHeaderColumn>
                  <TableHeaderColumn row="0" rowSpan='4' width="80"  headerAlign="center" dataAlign="center"  >NAMA KELURAHAN</TableHeaderColumn>
                  
                  <TableHeaderColumn row="0" rowSpan='4' width="80"  headerAlign="center" dataAlign="center"  >SUMBER PENDANAAN</TableHeaderColumn>
                  <TableHeaderColumn row="0" rowSpan='4' width="80"  headerAlign="center" dataAlign="center"  >USERNAME</TableHeaderColumn>
                  <TableHeaderColumn row="0" rowSpan='4' width="80"  headerAlign="center" dataAlign="center"  >NAMA LENGKAP</TableHeaderColumn>
                  <TableHeaderColumn width="80" row='0' colSpan='12' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Sasaran KRS (Baseline)</TableHeaderColumn>
                  <TableHeaderColumn width="80" row='1' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PUS </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PUS Hamil/Ibu Hamil </TableHeaderColumn>
                  <TableHeaderColumn width="180" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Keluarga punya anak 0-23 bulan </TableHeaderColumn>
                  <TableHeaderColumn width="180" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Keluarga punya anak 24-59 bulan </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='1' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TOTAL </TableHeaderColumn>

                  <TableHeaderColumn width="80" row='2' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PRIORITAS SASARAN </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PRIORITAS TERVERVAL </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SELAIN ADA/BARU TERVERVAL </TableHeaderColumn>

                  <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >ADA </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >BARU </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >JUMLAH </TableHeaderColumn>

                  <TableHeaderColumn width="80" row='0' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Sasaran KRS Verval</TableHeaderColumn>
                  <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Keluarga Ada </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Keluarga Baru </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Total </TableHeaderColumn>

                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="status_approval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >1 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1'  dataField="nama_kelurahan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >2</TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sumber_pendanaan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  ></TableHeaderColumn>
                  <TableHeaderColumn width="120" row='4' colSpan='1' dataField="user_name" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >3</TableHeaderColumn>
                  <TableHeaderColumn width="120" row='4' colSpan='1' dataField="nama_lengkap" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >4 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_ada"  headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >5 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_baru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >6 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_adabaru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >7 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pushamil_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >8 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pushamil_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >9 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="baduta_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >10 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="baduta_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >11 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="balita_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >12 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="balita_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >13 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_prioritas" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >14 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_prioritas_terverval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >15 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_notin_adabaru_terverval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >15 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_ada" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >17 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_baru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >18 </TableHeaderColumn>
                  <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_adabaru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >19=17+18 </TableHeaderColumn>


                  


                </BootstrapTable>
                <div className="sizePerPage">
                  Size Per Page
                  <select
                    name="category"
                    value={this.state.sizePerPage}
                    onChange={this.handleChangeSizePerPage}
                  >
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value={this.state.datas?.length}>All</option>
                  </select>
                </div>
                <div className='totalData'>
                  <b style={{ marginRight: '15px' }}>Total Data: {this.state.datas.length}</b>
                  {/* <b style={{ marginRight: '15px' }}>Data Duplicate dan Susulan: {this.state.dupsul}</b> */}
                </div>

              </div>

            </Paper>
            {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}> */}

            <Dialog open={this.state.openInfo} onClose={this.handleOpenCloseInfo} width={'30%'}>
              <DialogTitle style={{ paddingLeft: '25px' }}><BsInfoCircle size={15} />&nbsp; Info</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {/* <Grid container columnSpacing={{ xs: 12, sm: 1, md: 1 }} style={{ marginTop: '10px', marginBottom: '15px', paddingLeft: '15px' }}> */}
                  Info Code di Column KETERANGAN <br />
                  - Keterangan 1 adalah Masih terdapat Data Susulan dalam Proses <br />
                  - Keterangan 2 adalah Data Duplicate<br />
                  - Keterangan 3 adalah Data Ada Duplicate dan Masih terdapat Data Susulan dalam Proses

                </DialogContentText>

              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleOpenCloseInfo} variant="outlined" color="primary">Tutup</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </BlockUi>
    )
  }
}

export default KRSPengumpulan;