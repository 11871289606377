import * as React from "react";
import axios from 'axios';
import Swal from 'sweetalert2';

const authContext = React.createContext();

function useAuth() {
  const [authed, setAuthed] = React.useState(false);

  return {
    authed,
    login(fields) {
      axios.post(`http://pkportalauthorizeservice-bkkbn.apps.tkp.platform.lintasarta.net/auth/signin`)
      .then(result => {
        if(result.data.accessToken.length > 0){                    
            Swal.fire({
                title: 'Sukses!',
                icon: 'success',
                text: 'Login Berhasil.',
                showConfirmButton: false,
                timer: 1500
            })
            .then(() => {
              return new Promise((res) => {
                localStorage.setItem('cekToen', true);
                localStorage.setItem('token', res.data.accessToken);
                localStorage.setItem('refreshToken', res.data.refreshToken);
                localStorage.setItem('username', res.data.authorities[0].attributes.user_name);
                localStorage.setItem('name', res.data.authorities[0].attributes.user.namaLengkap);
                localStorage.setItem('authority', JSON.stringify(res.data.authority));
                setAuthed(true);
                res();
              });
            })                  
        }else{
            Swal.fire({  
                title: 'Peringatan',  
                icon: 'warning',  
                text: 'ID anda belum terdaftar.',  
            });
        }
      }).catch((error) => {
        if(error && error.response && error.response.status === 401){
            Swal.fire({  
                title: 'Peringatan',  
                icon: 'warning',  
                text: 'Terdapat kesalahan ID atau password.',  
            });
        }else{
            Swal.fire({  
                title: 'Error',  
                icon: 'error',  
                text: 'Silakan cek koneksi jaringan internet anda.',  
            });
        }
      });
    },
    logout() {
      return new Promise((res) => {
        localStorage.clear()
        setAuthed(false);
        res();
      });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}