import React, { Component } from 'react'
import { ImPlay3, ImPrinter, ImSearch } from 'react-icons/im';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Grid, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import CommonTable from '../../../commons/table/TableMonitoring';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import APIDashboard from "../../../services/APIDashboard";
import APIReport from '../../../services/APIReport';
import { BsInfoCircle } from 'react-icons/bs';
import Tooltip from '@mui/material/Tooltip';
import download from 'downloadjs'
import APIPemutakhiran from '../../../services/APIPemutakhiran';

class Pemutakhiran extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panel1: true,
      open: false,
      openInfo: false,
      sizePerPage: 10,
      title: '',
      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      text1: 'PROVINSI',
      text2: 'JUMLAH KABUPATEN',
      text4: 'LAMA',
      text3: 'BARU',
      text5: 'IN',
      text6: 'OUT',
      text7: 'BARU',
      text8: 'IN',
      text9: 'OUT',
      text10: 'PEMUTAKHIRAN DUKCAPIL',
      text11: 'PEMUTAKHIRAN VIA TOOLS',
      text12: 'TOTAL',
      text13: 'HAPUS',
      text14: 'HAPUS',
      datas: [],
      kode_laporan: '',
      judul_laporan: '',

      //Wilayah
      dt_prov: [], provinsi: null, id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: null, id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: null, id_rw: 0, dis_rw: true,
      dt_rt: [], rt: null, rtID: '', id_rt: 0, dis_rt: true,

      sendDatas: { tingkatWilayahID: JSON.parse(localStorage.getItem("authority"))[0].attributes.user.TingkatWilayahID, },

      //dialog
      title: '',
      content: '',
      cell: '',
    }


  }

  setHeaderTable = () => {
    let { text1, text2, text3, text4, text5, text6, text7, text8, text9, text10, text11, text12, text13, text14 } = this.state
    const { provinsi, kabupaten, kecamatan, desa, rw } = this.state
    let header = []
    var headerNamaWilayah = [
      { width: "150", row: '0', rowSpan: '3', title: text1, dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'center', editable: false, className: '', columnClassName: '' },
    ]
    var headerColumns = [
      //{ width: "150", row: '0', rowSpan: '3', title: 'PROVINSI', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'center', editable: false, className: '', columnClassName: '' },
      { width: "800", row: '0', colSpan: '10', title: 'JUMLAH KABUPATEN', dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'dce9fa', columnClassName: 'dce9fa' },
      { width: "80", row: '1', rowSpan: '2', colSpan: '1', title: text12, dataField: 'kabTotal', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'edafaf', columnClassName: 'edafaf' },
      { width: "400", row: '1', colSpan: '5', title: text10, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "300", row: '1', colSpan: '4', title: text11, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: 'LAMA', dataField: "kabToolsLama", dataFormat: 'systemLama', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text3, dataField: "kabCapilBaru", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "60", row: '2', colSpan: '1', title: text13, dataField: "kabCapilHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text5, dataField: "kabCapilIn", dataFormat: 'systemIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text6, dataField: "kabCapilOut", dataFormat: 'systemOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text7, dataField: "kabToolsBaru", dataFormat: 'manualBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text14, dataField: "kabToolsHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text8, dataField: "kabToolsIn", dataFormat: 'manualIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text9, dataField: "kabToolsOut", dataFormat: 'manualOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' }
    ];

    var headerColumnsKec = [
      //{ width: "150", row: '0', rowSpan: '3', title: 'KELURAHAN', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'center', editable: false, className: '', columnClassName: '' },
      { width: "800", row: '0', colSpan: '10', title: 'JUMLAH KECAMATAN', dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'dce9fa', columnClassName: 'dce9fa' },
      { width: "80", row: '1', rowSpan: '2', colSpan: '1', title: text12, dataField: 'kecTotal', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'edafaf', columnClassName: 'edafaf' },
      { width: "400", row: '1', colSpan: '5', title: text10, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "300", row: '1', colSpan: '4', title: text11, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text4, dataField: "kecToolsLama", dataFormat: 'systemLama', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text3, dataField: "kecCapilBaru", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "60", row: '2', colSpan: '1', title: text13, dataField: "kecCapilHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text5, dataField: "kecCapilIn", dataFormat: 'systemIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text6, dataField: "kecCapilOut", dataFormat: 'systemOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text7, dataField: "kecToolsBaru", dataFormat: 'manualBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text14, dataField: "kecToolsHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text8, dataField: "kecToolsIn", dataFormat: 'manualIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text9, dataField: "kecToolsOut", dataFormat: 'manualOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' }

    ]

    var headerColumnsKel = [
      //{ width: "150", row: '0', rowSpan: '3', title: 'RW', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'center', editable: false, className: '', columnClassName: '' },
      { width: "800", row: '0', colSpan: '10', title: 'JUMLAH KELURAHAN', dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'dce9fa', columnClassName: 'dce9fa' },
      { width: "80", row: '1', rowSpan: '2', colSpan: '1', title: text12, dataField: 'kelTotal', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'edafaf', columnClassName: 'edafaf' },
      { width: "400", row: '1', colSpan: '5', title: text10, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "300", row: '1', colSpan: '4', title: text11, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text4, dataField: "kelToolsLama", dataFormat: 'systemLama', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text3, dataField: "kelCapilBaru", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "60", row: '2', colSpan: '1', title: text13, dataField: "kelCapilHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text5, dataField: "kelCapilIn", dataFormat: 'systemIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text6, dataField: "kelCapilOut", dataFormat: 'systemOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text7, dataField: "kelToolsBaru", dataFormat: 'manualBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text14, dataField: "kelToolsHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text8, dataField: "kelToolsIn", dataFormat: 'manualIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text9, dataField: "kelToolsOut", dataFormat: 'manualOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' }

    ]

    var headerColumnsRW = [
      //{ width: "150", row: '0', rowSpan: '3', title: 'RW', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'center', editable: false, className: '', columnClassName: '' },
      { width: "800", row: '0', colSpan: '10', title: 'JUMLAH RW', dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'dce9fa', columnClassName: 'dce9fa' },
      { width: "80", row: '1', rowSpan: '2', colSpan: '1', title: text12, dataField: 'rwTotal', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'edafaf', columnClassName: 'edafaf' },
      { width: "400", row: '1', colSpan: '5', title: text10, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "300", row: '1', colSpan: '4', title: text11, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text4, dataField: "rwToolsLama", dataFormat: 'systemLama', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text3, dataField: "rwCapilBaru", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "60", row: '2', colSpan: '1', title: text13, dataField: "rwCapilHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text5, dataField: "rwCapilIn", dataFormat: 'systemIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text6, dataField: "rwCapilOut", dataFormat: 'systemOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text7, dataField: "rwToolsBaru", dataFormat: 'manualBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text14, dataField: "rwToolsHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text8, dataField: "rwToolsIn", dataFormat: 'manualIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text9, dataField: "rwToolsOut", dataFormat: 'manualOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' }

    ]

    var headerColumnsRT = [
      { width: "800", row: '0', colSpan: '10', title: 'JUMLAH RT', dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'dce9fa', columnClassName: 'dce9fa' },
      { width: "80", row: '1', rowSpan: '2', colSpan: '1', title: text12, dataField: 'rtTotal', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'edafaf', columnClassName: 'edafaf' },
      { width: "400", row: '1', colSpan: '5', title: text10, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "300", row: '1', colSpan: '4', title: text11, dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text4, dataField: "rtToolsLama", dataFormat: 'systemLama', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text3, dataField: "rtCapilBaru", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "60", row: '2', colSpan: '1', title: text13, dataField: "rtCapilHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text5, dataField: "rtCapilIn", dataFormat: 'systemIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text6, dataField: "rtCapilOut", dataFormat: 'systemOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
      { width: "50", row: '2', colSpan: '1', title: text7, dataField: "rtToolsBaru", dataFormat: 'manualBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "60", row: '2', colSpan: '1', title: text14, dataField: "rtToolsHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text8, dataField: "rtToolsIn", dataFormat: 'manualIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
      { width: "50", row: '2', colSpan: '1', title: text9, dataField: "rtToolsOut", dataFormat: 'manualOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' }

    ]

    let head = rw ? header = headerNamaWilayah.concat(headerColumnsRT) :
      desa ? header = headerNamaWilayah.concat(headerColumnsRT) :
        kecamatan ? header = headerNamaWilayah.concat(headerColumnsRW, headerColumnsRT) :
          kabupaten ? header = headerNamaWilayah.concat(headerColumnsKel, headerColumnsRW, headerColumnsRT) :
            provinsi ? header = headerNamaWilayah.concat(headerColumnsKec, headerColumnsKel, headerColumnsRW, headerColumnsRT) :
              header = headerNamaWilayah.concat(headerColumns, headerColumnsKec, headerColumnsKel, headerColumnsRW, headerColumnsRT)

    this.setState({ tableHead: head });
  }

  componentDidMount = () => {
    this.setHeaderTable()

    const rest = JSON.parse(localStorage.getItem("authority"));
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }

        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
        }
        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // console.log(vl, 'vale kab')
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // console.log(vl, 'vale kec')
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              // console.log(vl, 'vale des')
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // console.log('masuk false')
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di prov')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kab')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kec')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di des')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  setJudulLaporan = () => {
    let judul_laporan, kode_laporan
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      kode_laporan = this.state.rt.kode
      judul_laporan = this.state.rt.namaWil
    } else {
      kode_laporan = ''
      judul_laporan = ''
      if (id_rw !== 0) {
        kode_laporan = this.state.rw.kode
        judul_laporan = this.state.rw.namaWil
      } else {
        kode_laporan = ''
        judul_laporan = ''
        if (id_des !== 0) {
          kode_laporan = this.state.desa.kode
          judul_laporan = this.state.desa.namaWil
        } else {
          kode_laporan = ''
          judul_laporan = ''
          if (id_kecamatan !== 0) {
            kode_laporan = this.state.kecamatan.kode
            judul_laporan = this.state.kecamatan.namaWil
          } else {
            kode_laporan = ''
            judul_laporan = ''
            if (id_kabupaten !== 0) {
              kode_laporan = this.state.kabupaten.kode
              judul_laporan = this.state.kabupaten.namaWil
            } else {
              kode_laporan = ''
              judul_laporan = ''
              if (id_provinsi !== 0) {
                kode_laporan = this.state.provinsi.kode
                judul_laporan = this.state.provinsi.namaWil
              } else {
                kode_laporan = ''
                judul_laporan = ' Tingkat Nasional'
              }
            }
          }
        }
      }
    }

    this.setState({ judul_laporan: judul_laporan, kode_laporan: kode_laporan })
  }

  handleSearch = () => {
    this.setState({ blocking: true });

    const { provinsi, kabupaten, kecamatan, desa, rw } = this.state


    // const payload = rw? {idWilayah: rw.value, tingkatWilayahID:5} :
    //                 desa? {idWilayah: desa.value, tingkatWilayahID:4} :
    //                 kecamatan? {idWilayah: kecamatan.value, tingkatWilayahID:3} :
    //                 kabupaten? {idWilayah: kabupaten.value, tingkatWilayahID:2} :
    //                 provinsi? {idWilayah: provinsi.value, tingkatWilayahID:1} :
    //                 {idWilayah: 0, tingkatWilayahID:0}

    this.setLabel()

    let url = 'pkportal/monitoring/getPemutakhiran'
    if (provinsi && provinsi.value !== null) { url += '?idProvinsi=' + provinsi.value }
    if (kabupaten && kabupaten.value !== null) { url += '&idKabupaten=' + kabupaten.value }
    if (kecamatan && kecamatan.value !== null) { url += '&idKecamatan=' + kecamatan.value }
    if (desa && desa.value !== null) { url += '&idKelurahan=' + desa.value }
    if (rw && rw.value !== null) { url += '&idRw=' + rw.value }

    APIPemutakhiran.get(url)
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState({ datas: res.data })
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });

  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  handleCellClicked = (e, cell, row, param) => {
    e.preventDefault()
    let title = ''
    if (param === 'systemTotal') {
      title = 'Total Data ' + this.state.text2.replace('JUMLAH ', '') + ' ' + row.nama
    } else if (param === 'systemLama') {
      title = 'Data Lama ' + this.state.text2.replace('JUMLAH ', '') + ' ' + row.nama + ' (System)'
    } else if (param === 'systemBaru') {
      title = 'Data Baru ' + this.state.text2.replace('JUMLAH ', '') + ' ' + row.nama + ' (System)'
    } else if (param === 'systemIn') {
      title = 'Data Mutasi In ' + this.state.text2.replace('JUMLAH ', '') + ' ' + row.nama + ' (System)'
    } else if (param === 'systemOut') {
      title = 'Data Mutasi Out ' + this.state.text2.replace('JUMLAH ', '') + ' ' + row.nama + ' (System)'
    } else if (param === 'manualBaru') {
      title = 'Data Baru ' + this.state.text2.replace('JUMLAH ', '') + ' ' + row.nama + ' (Manual)'
    } else if (param === 'manualIn') {
      title = 'Data Mutasi In ' + this.state.text2.replace('JUMLAH ', '') + ' ' + row.nama + ' (Manual)'
    } else if (param === 'manualOut') {
      title = 'Data Mutasi Out ' + this.state.text2.replace('JUMLAH ', '') + ' ' + row.nama + ' (Manual)'
    }
    title = title.toUpperCase()
    this.setState({ title, cell: cell, content: JSON.stringify(row), open: true })
  }

  handleOpenClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ open: !this.state.open })
  }

  handleOpenCloseInfo = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openInfo: !this.state.openInfo })
  }


  handleCetak = (e) => {


    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;
    //this.setJudulLaporan()

    let judul_lap, kode_lap
    //const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      kode_lap = this.state.rt.kode
      judul_lap = this.state.rt.namaWil
    } else {
      kode_lap = ''
      judul_lap = ''
      if (id_rw !== 0) {
        kode_lap = this.state.rw.kode
        judul_lap = this.state.rw.namaWil
      } else {
        kode_lap = ''
        judul_lap = ''
        if (id_des !== 0) {
          kode_lap = this.state.desa.kode
          judul_lap = this.state.desa.namaWil
        } else {
          kode_lap = ''
          judul_lap = ''
          if (id_kecamatan !== 0) {
            kode_lap = this.state.kecamatan.kode
            judul_lap = this.state.kecamatan.namaWil
          } else {
            kode_lap = ''
            judul_lap = ''
            if (id_kabupaten !== 0) {
              kode_lap = this.state.kabupaten.kode
              judul_lap = this.state.kabupaten.namaWil
            } else {
              kode_lap = ''
              judul_lap = ''
              if (id_provinsi !== 0) {
                kode_lap = this.state.provinsi.kode
                judul_lap = this.state.provinsi.namaWil
              } else {
                kode_lap = ''
                judul_lap = ' Tingkat Nasional'
              }
            }
          }
        }
      }
    }

    this.setState({ judul_laporan: judul_lap, kode_laporan: kode_lap }, () => console.log('judul', this.state.judul_laporan, 'kode', this.state.kode_laporan))

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#4ea842',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#FF8000',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      //let urlCetakPdf = url +'%22ketWilayah%22%3A%22'+'tes' + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C'+ '%22title%22%3A%22' +this.state.title+ '%22%2C' +'%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'


      //viewReport?filterPencarian=%7B%22provinsiId%22%3A%221%22%2C%22kabupatenId%22%3A%227%22%2C%22kecamatanId%22%3A%224155%22%2C%22ketWilayah%22%3A%22Nama%20wilayah%20inputan%22%2C%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D

      //{"provinsiId":"1","kabupatenId":"7","kecamatanId":"4155","ketWilayah":"Nama wilayah inputan","reportName":"TABEL1","reportType":"pdf","reportCategory":"MasterWilayah"}

      //let coba =''
      //coba = decodeURI(this.state.judul_laporan)
      let urlCetakPdf = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
      console.log('pdf', urlCetakPdf)
      let urlCetakExcel = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // console.log("error ===> ", error)
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            // console.log(res, 'res report')
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // console.log("error ===> ", error)
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })

    e.preventDefault()



    // try{
    //     reportService.get(url, { responseType: 'blob' })
    //     .then(res => {
    //         // console.log(res, 'res report')
    //         if (res.status === 200) {
    //             const content = res.headers['content-type'];
    //             download(res.data, 'Rekapitulasi Data Keluarga.' + extFile, content)
    //             this.setState({ blocking: false })
    //         } else {
    //             Swal.fire({
    //                 title: 'Info!',
    //                 icon: 'info',
    //                 text: 'Data tidak ditemukan.',
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             })
    //             this.setState({ blocking: false });
    //         }
    //     }).catch((error) => {
    //         // console.log("error ===> ", error)
    //         let message = error;
    //         Swal.fire({
    //             title: 'Error!',
    //             icon: 'warning',
    //             // text: 'Jaringan bermasalah.',
    //             text: message,
    //         })
    //         this.setState({ blocking: false });
    //     });
    // }catch(error){
    //     let message = error;
    //     Swal.fire({
    //         title: 'Error!',
    //         icon: 'warning',
    //         // text: 'Jaringan bermasalah.',
    //         text: message,
    //     })
    //     this.setState({ blocking: false });
    // }

    // } else {
    //     Swal.fire({
    //         title: 'Info!',
    //         icon: 'info',
    //         text: 'Wilayah harus dimasukkan minimal Desa/Kelurahan',
    //         showConfirmButton: false,
    //         timer: 2000
    //     })
    //     this.setState({ blocking: false });
    // }
  }

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' /> Laporan Pemutakhiran Wilayah</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                          <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>

                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={12}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '5px' }} variant="contained">Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <span>
              <h3>Hasil Pemutakhiran Wilayah {this.state.title} <span className='btnTable'><Tooltip title="Keterangan" placement="left" arrow><Button style={{ float: 'right' }} onClick={this.handleOpenCloseInfo}><BsInfoCircle size={20} /></Button></Tooltip></span></h3>

            </span>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
                handleCellClicked={this.handleCellClicked}
              />
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div>
            </Paper>
          </div>
        </div>
        <Dialog open={this.state.open} onClose={this.handleOpenClose} fullWidth maxWidth={'md'}>
          <DialogTitle>{this.state.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Jumlah {this.state.cell}
            </DialogContentText>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Grid item xs={12} md={12}>
                {this.state.content}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleOpenClose} variant="outlined" color="primary">Tutup</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.openInfo} onClose={this.handleOpenCloseInfo} width={'md'}>
          <DialogTitle style={{ paddingLeft: '25px' }}><BsInfoCircle size={15} />&nbsp; Keterangan</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 1 }} style={{ marginTop: '10px', marginBottom: '15px', paddingLeft: '15px' }}>
                <Grid item xs={4}><li>Pemutakhiran Dukcapil</li></Grid>
                <Grid item xs={8}>: Proses pemutakhiran menggunakan data Dukcapil</Grid>
                <Grid item xs={4}><li>Pemutakhiran Via Tools</li></Grid>
                <Grid item xs={8}>: Proses pemutakhiran menggunakan aplikasi oleh user</Grid>
                <Grid item xs={4}><li>Total</li></Grid>
                <Grid item xs={8}>: Jumlah wilayah aktual</Grid>
                <Grid item xs={4}><li>Lama</li></Grid>
                <Grid item xs={8}>: Jumlah wilayah sebelum pemutakhiran</Grid>
                <Grid item xs={4}><li>Baru</li></Grid>
                <Grid item xs={8}>: Jumlah penambahan wilayah baru</Grid>
                <Grid item xs={4}><li>Hapus</li></Grid>
                <Grid item xs={8}>: Jumlah wilayah yang telah dihapus</Grid>
                <Grid item xs={4}><li>In</li></Grid>
                <Grid item xs={8}>: Jumlah wilayah mutasi masuk dari wilayah lain</Grid>
                <Grid item xs={4}><li>Out</li></Grid>
                <Grid item xs={8}>: Jumlah wilayah mutasi keluar ke wilayah lain</Grid>

              </Grid>

            </DialogContentText>
            {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Grid item xs={12} md={12}>
                {this.state.content}
              </Grid>
            </Grid> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleOpenCloseInfo} variant="outlined" color="primary">Tutup</Button>
          </DialogActions>
        </Dialog>
      </BlockUi>
    )
  }
}

export default Pemutakhiran;