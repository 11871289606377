import React, { Component } from "react";
import { Select as MuiSelect, TextField, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, TableCell, Input } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../../services/APIWilayah';
import APIUpload from '../../../../services/APIUpload';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
//import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';
import { ImPlay3, ImSearch, ImPrinter } from "react-icons/im";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Swal from 'sweetalert2';
import APIReport from "../../../../services/APIReport";
import download from 'downloadjs'

class MonitoringPembayaran extends Component {
    constructor(props) {
        super(props);

        this.state = {
            htmlFrame:'',
            panel1: true,
            sizePerPage: 5,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],

            datas: [],
            datasView: [],
            namaProvinsi: '',
            namaKelurahan: '',
            namaKecamatan: '',
            namaKabupaten: '',
            created_by_md: '',
            created_by_mp: '',
            created_by_spv: '',
            created_date_md: '',
            created_date_mp: '',
            created_date_spv: '',

            //Wilayah
            dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
            dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

            openRejectDialog: false,

            roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
            dt_sdm: [{ value: 5, label: 'Pendata', name: 'PENDATA' }, { value: 10, label: 'Operator', name: 'OPERATOR' },
            { value: 18, label: 'Kader Formulir', name: 'KADER' }],
            filterSDM: [], selectedSDM: null,
            disCetakKuitansi: false,
            openDetailRT: false,
            openCloseInputSP2D: false,
            openPreviewRekapRT: false,
            rowSDM: '',

            data_RT: [{ kode: ' 001', nama_rt: '001' }, { kode: '002', nama_rt: '002' }, { kode: '003', nama_rt: '003' }],

            selectedRows: [], rowDipilih: [],
            dt_metode: [{ value: 'Tunai', label: 'Tunai' }, { value: 'Non-Tunai', label: 'Non Tunai' }],
            selectedMetode: null, metodeTampil: [],
            sp2d: '', tgl_sp2d: '',
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    componentDidMount() {
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
        let title2, text11, text21

        if (this.state.roleUserLogin === 15) { //pelaksana kegiatan
            const data_pelaksana = this.state.datas.filter(data => data.flag === 1)
            this.setState({ datas: data_pelaksana })
        } else if (this.state.roleUserLogin === 16) {//PPK
            const data_ppk = this.state.datas.filter(data => data.flag === 2)
            this.setState({ datas: data_ppk })
        } else if (this.state.roleUserLogin === 17) {
            const data_bendahara = this.state.datas.filter(data => data.flag === 3)
            this.setState({ datas: data_bendahara })
        }

        if (idTingkatWilayah == 0) {
            title2 = ''

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": 1,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0
                }
            }
            );

        } else if (idTingkatWilayah == 1) {
            title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 1,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": 0,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }


            )


        } else if (idTingkatWilayah == 2) {
            title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 2,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                },
                () => {  }

            )


        } else {
            title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan


            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 3,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }
            )
        }

        this.setState({ title: title2 },
        )

        if (rest === false) { this.props.history.push('/401') }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }
                const wilProvinsi = rest[0].attributes.user.wilProvinsi
                const wilKabupaten = rest[0].attributes.user.wilKabupaten
                const wilKecamatan = rest[0].attributes.user.wilKecamatan
                const wilKelurahan = rest[0].attributes.user.wilKelurahan

                if (Object.keys(wilProvinsi).length > 0) {

                    const objProp = {
                        'value': wilProvinsi.id_provinsi,
                        'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
                    };

                    this.setState({ dt_prov: objProp },
                        () => {
                            if (this.state.auto_prov === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(objProp)
                            }
                        }
                    );
                    //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                //        alert(wilKabupaten.length)
                if (Object.keys(wilKabupaten).length > 0) {
                    //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    const objKab = {
                        'value': wilKabupaten.id_kabupaten,
                        'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
                    };

                    this.setState({ dt_kab: objKab },
                        () => {
                            if (this.state.auto_kab === true) {
                                console.log("objKab", objKab)
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kab(objKab)
                            }
                        }
                    );

                }
                if (Object.keys(wilKecamatan).length > 0) {
                    const objKec = {
                        'value': wilKecamatan.id_kecamatan,
                        'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
                    };

                    this.setState({ dt_kec: objKec },
                        () => {
                            if (this.state.auto_kec === true) {
                                console.log("objKec", objKec)
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kec(objKec)
                            }
                        }
                    );
                    //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(wilKelurahan).length > 0) {
                    const objKel = {
                        'value': wilKelurahan.id_kelurahan,
                        'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
                    };

                    this.setState({ dt_kel: objKel },
                        () => {
                            if (this.state.auto_des === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_des(objKel)
                            }
                        }
                    );


                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    //API (Wilayah)
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            //handleSearch
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": e.value,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
            );

            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )


        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
                , id_kecamatan: 0, id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "idKec": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            });


            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
                , id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": e.value,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
                , id_rw: 0

            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": e.value,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_sdm = (e) => {
        if (e) {
            this.setState({
                filterSDM: e, selectedSDM: e.value,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterSDM: null, selectedSDM: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_metode = (e) => {
        if (e) {
            this.setState({
                selectedMetode: e.value,
                metodeTampil: e
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                selectedMetode: null,
                metodeTampil: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }


    handlePageChange = (page, rowsPerPage) => {
        this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    }

    handleSearch = () => {
        this.setState({ blocking: true, flagOpen: false });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, selectedSDM, selectedMetode} = this.state;

        let SDMdipilih = 0
        if (selectedSDM === 18) {
            SDMdipilih = 4

        } else {
            SDMdipilih = selectedSDM
        }
        

        let url = 'viewReport?filterPencarian=%7B'

        if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
        if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
        if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
        if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
        if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

        url += '%22roleId%22%3A%22' + SDMdipilih + '%22%2C'

        url += '%22reportName%22%3A%22RekapWilayah02%22%2C'
        url += '%22reportType%22%3A%22HTML%22%2C'
        url += '%22reportCategory%22%3A%22REKAPWILAYAH%22'

        if (selectedMetode !== null ){
            url += '%2C%22metode%22%3A%22'+ selectedMetode +'%22'
        }
        url += '%7D'


        // if (catTable === 'Dallap-Bulanan') { url += '%2C%22bulan%22%3A' + bulan }
        // if (id_provinsi != 0) { url += '%2C%22provinsiId%22%3A%22' + id_provinsi + '%22' }
        // if (id_kabupaten != 0) { url += '%2C%22kabupatenId%22%3A%22' + id_kabupaten + '%22'; newLabel = labelTable }
        // if (id_kecamatan != 0) { url += '%2C%22kecamatanId%22%3A%22' + id_kecamatan + '%22'; newLabel = labelTable }
        // url += '%2C%22reportName%22%3A%22' + newLabel + '%22'
        // url += '%2C%22reportType%22%3A%22html' + '%22'
        // url += '%2C%22reportCategory%22%3A%22' + catTable + '%22%7D'
        // url += '%22%2C%22category%22%3A%22Dallap%22%7D'
        if(selectedSDM !== null && id_kabupaten !== 0 && id_kabupaten !== undefined){
            APIReport.get(url)
                .then(res => {
                    // console.log(res, 'res report')
                    if (res.status === 200) {
                        this.setState({ htmlFrame: res.data, blocking: false })
                    } else {
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: 'Pencarian Data Gagal, Harap Coba lagi!',
                          })
                        this.setState({ blocking: false });
                    }
                }).catch((error) => {
                    // console.log("error ===> ", error)
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        // text: 'Jaringan bermasalah.',
                        text: 'Pencarian Data Gagal, Harap Coba lagi!',
                        showConfirmButton: true,
                        // timer: 1500
                    })
                    this.setState({ blocking: false });
                });

        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter wilayah minimal dipilih tingkat kabupaten, serta filter SDM harus dipilih.',
                showConfirmButton: true,
                timer: 1500
            })
            this.setState({ blocking: false });
        }
    }

    handleCetak = () => {
        this.setState({ blocking: true, flagOpen: false });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, selectedSDM, selectedMetode} = this.state;
        
        if(selectedSDM !== null && id_kabupaten !== 0 && id_kabupaten !== undefined){
            // console.log(id_kabupaten, 'id_kabupaten')
            let SDMdipilih = 0
            if(selectedSDM === 18){
                SDMdipilih = 4
                
            } else {
                SDMdipilih = selectedSDM
            }

            Swal.fire({
                title: 'Cetak Laporan',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Excel',
                confirmButtonColor: '#3085d6',
                showDenyButton: true,
                denyButtonText: 'PDF',
                denyButtonColor: '#4ea842',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {

                let url = 'viewReport?filterPencarian=%7B'

                if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
                if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
                if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
                if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
                if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

                url += '%22roleId%22%3A%22' + SDMdipilih + '%22%2C'

                url += '%22reportName%22%3A%22RekapWilayah02%22%2C'

                let urlCetakPdf = url + '%22reportType%22%3A%22pdf%22%2C' + '%22reportCategory%22%3A%22REKAPWILAYAH%22'
                let urlCetakExcel = url + '%22reportType%22%3A%22xlsx%22%2C' + '%22reportCategory%22%3A%22REKAPWILAYAH%22'

                if (selectedMetode !== null){
                    urlCetakPdf += '%2C%22metode%22%3A%22'+ selectedMetode +'%22'
                    urlCetakExcel += '%2C%22metode%22%3A%22'+ selectedMetode +'%22'
                }

                urlCetakPdf += '%7D'
                urlCetakExcel += '%7D'
        
                if (result.isConfirmed) {
                    APIReport.get(urlCetakExcel, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Rekapitulasi Pembayaran Operasional.' + 'xlsx', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });
    
                } else if (result.isDenied) {
                    APIReport.get(urlCetakPdf, { responseType: 'blob' })
                        .then(res => {
                            // console.log(res, 'res report')
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Rekapitulasi Pembayaran Operasional.' + 'pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });
    
    
                } else {
                    this.setState({ blocking: false });
                }
            })
            

        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter wilayah minimal dipilih tingkat kabupaten, serta filter SDM harus dipilih.',
                showConfirmButton: true,
                timer: 1500
            })
            this.setState({ blocking: false });
        }
    }


    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    //select row

    handleSelectRow = (cat, row, isSelect) => {
        if (isSelect === true && cat === 'single') {
            this.setState({ selectedRows: this.state.selectedRows.concat(row) });
        } else if (isSelect === true && cat === 'all') {
            this.setState({ selectedRows: row });
        } else if (cat === 'single') {
            var array = this.state.selectedRows;
            var index = array.findIndex(el => el.id === row.id);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ selectedRows: array });
            }
        } else {
            this.setState({ selectedRows: [] });
        }


    }


    render() {

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10000px',
            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRow('single', row, isSelect)
            },
            onSelectAll: (isSelect, rows, e) => {
                if (isSelect) {
                    this.handleSelectRow('all', rows, isSelect)
                    return this.state.datas.map(row => row.id)
                } else {
                    this.handleSelectRow('all', rows, isSelect)
                    return [];
                }
                // console.log('isSelect', isSelect);
                // console.log('rows', rows);
                // console.log('e', e);
            }

        };
        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "1",
                    value: 1,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
        };

        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >
                <div className="content">
                    <div className="title-content">
                        <ImPlay3 className="icon-brd" />
                        Monitoring Pembayaran APBN
                    </div>
                    <div className="body-content">
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Provinsi
                                                        <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kabupaten
                                                        <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kecamatan
                                                        <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kelurahan
                                                        <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        SDM
                                                        <Select options={this.state.dt_sdm} onChange={this.sel_sdm} value={this.filterSDM} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Metode
                                                        <Select options={this.state.dt_metode} onChange={this.sel_metode} value={this.metodeTampil} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <Grid item xs={12}>
                                                        <Button onClick={(e) => this.handleSearch()} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} />Cari</Button>
                                                        <Button onClick={(e) => this.handleCetak()} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        {/* <h3>Monitoring Pembayaran</h3> */}


                        <Paper sx={{ width: "100%", boxShadow: "none" }}>
                            
                                <Grid style={{ width: '100%', overflow: 'auto' }} md="12">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.htmlFrame }}></div>
                                </Grid>
                            

                        </Paper>


                    </div>
                </div>
            </BlockUi>
        );
    }
}

export default MonitoringPembayaran;
