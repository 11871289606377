import React, { Component } from "react";
import { Select as MuiSelect, TextField, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, TableCell, Input } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../../services/APIWilayah';
import APIDashboard from "../../../../services/APIDashboard";
import APIUpload from '../../../../services/APIUpload';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
//import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';
import { ImPlay3, ImSearch, ImPrinter } from "react-icons/im";
import { HiOutlineDocumentText } from "react-icons/hi";
import APIVerval from "../../../../services/APIVerval";
import { FileUploader } from "react-drag-drop-files";

import { BsPencilSquare } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";

import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from "moment";
import APIPemutakhiran from "../../../../services/APIPemutakhiran";
import APIButton from '../../../../services/APIButtonAkses';

import Swal from 'sweetalert2';
import axios from 'axios';
//import couchdb
import cdb1 from '../../../../services/cdb1.json';
import cdb2 from '../../../../services/cdb2.json';
import cdb3 from '../../../../services/cdb3.json';
import cdb4 from '../../../../services/cdb4.json';
import cdbKab from '../../../../services/cdbKab1.json';




const fileTypes = ["PDF"];
const isNumber = (str) => /^[0-9]*$/.test(str);
class ApprovalPendataan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            couchdb: "",
            USER_KEY_PG: process.env.REACT_APP_USER_KEY_PROXY_GATEWAY,
            dataRekap: [],
            dataSP2D: [],
            panel1: true,
            sizePerPage: 10,
            sizePerPage2: 10,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],
            disAprrovalProv: false,
            datas: [],
            datasView: [],
            namaProvinsi: '',
            namaKelurahan: '',
            namaKecamatan: '',
            namaKabupaten: '',
            created_by_md: '',
            created_by_mp: '',
            created_by_spv: '',
            created_date_md: '',
            created_date_mp: '',
            created_date_spv: '',
            datapromises: [],
            disButtonCari: false,
            disApprov: true,
            setFile: null,
            file_name: '',



            //Wilayah
            dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
            dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

            openRejectDialog: false,
            role: JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,

            roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
            dt_sdm: [{ value: 25, label: 'Pengumpul Desa/Kelurahan', name: 'Pengumpul' }, { value: 26, label: 'Pengolah Kecamatan', name: 'Pengolah' },
            { value: 27, label: 'Verifikator Kecamatan', name: 'Verifikator' }],
            dt_role: [{ value: 15, label: 'Pelaksana Kegiatan', name: 'Pelaksana Kegiatan' }, { value: 16, label: 'PPK', name: 'PPK' }, { value: 17, label: 'BPP', name: 'BPP' }],
            filterSDM: [], selectedSDM: null,
            filterRole: [], selectedRole: null, nameRole: '',
            dt_tunggakan: [{ value: true, label: 'Ya' }, { value: false, label: 'Tidak' }],
            selectedTunggakan: null, tunggakanTampil: [],
            disCetakKuitansi: false,
            openDetailRT: false,
            openCloseInputSP2D: false,
            openPreviewRekapRT: false,
            rowSDM: '',
            textCari: '',
            disAprrovaladmin: false,

            data_RT: [{ kode: ' 001', nama_rt: '001' }, { kode: '002', nama_rt: '002' }, { kode: '003', nama_rt: '003' }],

            selectedRows: [], rowDipilih: [],
            metode: [{ value: 'Tunai', label: 'Tunai' }, { value: 'Non-Tunai', label: 'Non Tunai' }],
            selectedMetode: [], metodeTampil: [],
            sp2d: '', tgl_sp2d: '',
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    componentDidMount() {
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.alamat_provinsi;
        let created = new Date().toISOString().substring(0, 10)
        let temp = new Date("2023-06-25").toISOString().substring(0, 10)
        let title2, text11, text21

        if (this.state.roleUserLogin === 15) { //pelaksana kegiatan
            const data_pelaksana = this.state.datas.filter(data => data.flag === 1)
            this.setState({ datas: data_pelaksana })
        } else if (this.state.roleUserLogin === 16) {//PPK
            const data_ppk = this.state.datas.filter(data => data.flag === 2)
            this.setState({ datas: data_ppk })
        } else if (this.state.roleUserLogin === 17) {
            const data_bendahara = this.state.datas.filter(data => data.flag === 3)
            this.setState({ datas: data_bendahara })
        }

        if (idTingkatWilayah == 0) {
            title2 = ''

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": 1,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0
                }
            }
            );

        } else if (idTingkatWilayah == 1) {
            title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 1,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": 0,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }


            )


        } else if (idTingkatWilayah == 2) {
            title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 2,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                },
                // () => { this.getBackend(this.state.sendDatas) }

            )


        } else {
            title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan


            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 3,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }
            )
        }

        this.setState({ title: title2 },
        )

        if (rest === false) { this.props.history.push('/401') }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }
                const wilProvinsi = rest[0].attributes.user.wilProvinsi
                const wilKabupaten = rest[0].attributes.user.wilKabupaten
                const wilKecamatan = rest[0].attributes.user.wilKecamatan
                const wilKelurahan = rest[0].attributes.user.wilKelurahan

                if (Object.keys(wilProvinsi).length > 0) {

                    const objProp = {
                        'value': wilProvinsi.id_provinsi,
                        'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
                    };

                    this.setState({ dt_prov: objProp },
                        () => {
                            if (this.state.auto_prov === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(objProp)
                            }
                        }
                    );
                    //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                //        alert(wilKabupaten.length)
                if (Object.keys(wilKabupaten).length > 0) {
                    //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    const objKab = {
                        'value': wilKabupaten.id_kabupaten,
                        'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
                    };

                    this.setState({ dt_kab: objKab },
                        () => {
                            if (this.state.auto_kab === true) {

                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kab(objKab)
                            }
                        }
                    );

                }
                if (Object.keys(wilKecamatan).length > 0) {
                    const objKec = {
                        'value': wilKecamatan.id_kecamatan,
                        'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
                    };

                    this.setState({ dt_kec: objKec },
                        () => {
                            if (this.state.auto_kec === true) {

                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kec(objKec)
                            }
                        }
                    );
                    //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(wilKelurahan).length > 0) {
                    const objKel = {
                        'value': wilKelurahan.id_kelurahan,
                        'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
                    };

                    this.setState({ dt_kel: objKel },
                        () => {
                            if (this.state.auto_des === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_des(objKel)
                            }
                        }
                    );


                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    //API (Wilayah)
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            //handleSearch
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": e.value,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
            );

            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )


        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                },
                disAprrovalProv: false, disAprrovaladmin: false
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
                , id_kecamatan: 0, id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }

                    if (this.state.id_provinsi) {
                        this.setState({ blocking: true })
                        APIButton.get("/aktif/getTombolAktifByProvIdKabId?periodeSensus=" + this.state.value_priode + "&kabupatenId=" + this.state.id_kabupaten + "&provinsiId=" + this.state.id_provinsi + "&modulPembayaran=VERVAL" + "&periodeSensus=" + this.state.value_priode)
                            .then((res) => {

                                this.setState({ blocking: false });
                                if (res.status === 200) {
                                    this.setState(
                                        {
                                            disAprrovalProv: res.data[0].ButtonStatusVerval, disAprrovaladmin: res.data[0].ButtonStatusVerval
                                        }

                                    )
                                }

                            })
                            .catch((error) => {
                                this.setState({ blocking: false });

                            });
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "idKec": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            });


            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
                , id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": e.value,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
                , id_rw: 0

            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": e.value,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_sdm = (e) => {
        if (e) {
            this.setState({
                filterSDM: e, selectedSDM: e.value,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterSDM: null, selectedSDM: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_role = (e) => {
        this.setState({ datas: [], selectedRows: [] })
        if (e) {
            this.setState({
                filterRole: e, selectedRole: e.value, nameRole: e.name, disButtonCari: true
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterRole: null, selectedRole: null, nameRole: '', disButtonCari: false
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handlePageChange = (page, rowsPerPage) => {
        this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    }



    handleSearch = () => {
        // this.setState({ selectedRows:  });
        // let couchdb = this.selectCouchdb()
        this.setState({ selectedRows: [] })
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw, selectedSDM, filterSDM } = this.state
        let role = this.state.selectedRole === null ? this.state.roleUserLogin : this.state.selectedRole

        const reqParam = {
            "id_prov": +id_provinsi,
            "id_kab": id_kabupaten,
            "id_kec": id_kecamatan,
            "id_kel": id_des,
            "role_get": role,
            "role_id": selectedSDM,
            "sumber_dana": "APBN"
        }

        if (id_kabupaten !== 0 && selectedSDM !== null) {
            this.setState({ blocking: true })

            APIVerval.post("/siga/pembayaranverval/getDataApprovalPembayaranVerval?periodeSensus=" + this.state.value_priode, reqParam)
                .then((res) => {
                    this.setState({ blocking: false });
                    if (res.status === 200) {
                        let cekdata = [];

                        res.data.data.forEach(data => {
                            let verval = data.jml_kk_ada + data.jml_kk_baru;

                            data = Object.assign({ terverval: verval }, data);
                            cekdata.push(data);
                        });
                        this.setState(
                            {
                                datas: cekdata, blocking: false

                            }
                        )

                    } else {
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: 'Pencarian Data Gagal, Harap Coba lagi!',
                        })
                        this.setState({ blocking: false });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: 'Pencarian Data Gagal, Harap Coba lagi!',
                    })
                    this.setState({ blocking: false });
                });

        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter SDM harus dipilih serta filter wilayah harus dipilih minimal tingkat kabupaten',
                showConfirmButton: true
            })
            this.setState({ blocking: false })
        }

    }

    rupiahFormatter = (cell, row) => {
        const numericValue = parseFloat(cell);

        // Check if the numericValue is a valid number
        if (isNaN(numericValue)) {
            return 0;
        }

        // Format the number as Rupiah with "sen"
        const rupiahOptions = {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        let coba = numericValue.toLocaleString("id-ID", rupiahOptions).toString().substring(3)

        return coba

    }

    buttonFormatter = (cell, row) => {

        return <div className='divBtnTable'>
            {(this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) &&
                <>
                    {this.state.disAprrovaladmin === true ?
                        <>
                            {(this.state.selectedRole === 17 || this.state.roleUserLogin === 17) ?
                                <>
                                    <Button onClick={(e) => this.openCloseInputSP2D(e, row)} id={row.id} className='btnTable' variant="outlined"><BsPencilSquare style={{ color: 'green' }} /></Button>
                                    <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleReject(e, row)} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>

                                </>
                                :
                                <>
                                    <Button onClick={(e) => this.handleApprove(e, row, 'APPROV')} id={row.id} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button>
                                    <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleReject(e, row)} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>

                                </>
                            }
                        </>
                        :
                        <>-</>
                    }

                </>
            }
            {(this.state.roleUserLogin !== 19 && this.state.roleUserLogin !== 99) &&
                <>
                    {this.state.disAprrovalProv === true ?
                        <>
                            {(this.state.selectedRole === 17 || this.state.roleUserLogin === 17) ?
                                <>
                                    <Button onClick={(e) => this.openCloseInputSP2D(e, row)} id={row.id} className='btnTable' variant="outlined"><BsPencilSquare style={{ color: 'green' }} /></Button>
                                    <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleReject(e, row)} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>

                                </>
                                :
                                <>
                                    <Button onClick={(e) => this.handleApprove(e, row, 'APPROV')} id={row.id} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button>
                                    <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleReject(e, row)} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>

                                </>
                            }
                        </>
                        :
                        <>-</>
                    }

                </>
            }

        </div>

    }

    handleChange = (pdf) => {

        // if (this.state.file_name !== '' && this.state.file_name !== null) {
        Swal.fire({
            title: 'File Sudah Pernah di Upload',
            text: "Apakah Ingin Upload Ulang?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    setFile: pdf,
                    onsubmit: false
                })
            } else {

            }

        })
        // } else {
        //   this.setState({
        //     setFile: pdf,
        //     onsubmit: false
        //   })
        // }

    };
    buttonFormattere = (cell, row) => {
        if (this.state.selectedRole === 17 || this.state.roleUserLogin === 17) {
            return <div className='divBtnTable'>
                <Button onClick={(e) => this.openCloseInputSP2D(e, row)} id={row.id} className='btnTable' variant="outlined"><BsPencilSquare style={{ color: 'green' }} /></Button>
                <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleReject(e, row)} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>

            </div>
        } else {
            return <div className='divBtnTable'>
                <Button onClick={(e) => this.handleApprove(e, row, 'APPROV')} id={row.id} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button>
                <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleReject(e, row)} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>

            </div>
        }
    }

    ValidasiPekerjaan = (row) => {

        if (row?.status_pekerjaan === null) {
            return true;
        } else if (row?.golongan === null) {
            return true;
        } else if (row?.file_name === null) {
            return true;
        } else if (row?.file_name === null) {
            return true;
        } else {
            return false;
        }
    }
    ValidasiPekerjaanMulti = (row) => {
        let pekerjaan = row?.filter(el => el.status_pekerjaan === null)
        let golonganValue = row?.filter(el => el.golongan === null)
        let filename = row?.filter(el => el.file_name === null)

        if (pekerjaan.length > 0) {
            return true;
        } else if (golonganValue > 0) {
            return true;
        } else if (filename > 0) {
            return true;
        } else {
            return false;
        }
    }

    ValidasiPekerjaanText = (row) => {
        if (row?.status_pekerjaan === null) {
            return 'Status Pekerjaan Tidak Ada, Harap Hubungi Administrator'
        } else if (row?.golongan === null) {
            return 'Status Golongan Tidak Ada, Harap Hubungi Administrator'
        } else if (row?.file_name === null) {
            return 'Surat Keputusan Belum di Upload, Harap Di Upload Terlebih Dahulu'
        } else {
            return ''
        }
    }
    ValidasiPekerjaanTextMulti = (row) => {
        let pekerjaan = row?.filter(el => el.status_pekerjaan === null)
        let golonganValue = row?.filter(el => el.golongan === null)
        let filename = row?.filter(el => el.file_name === null)

        if (pekerjaan.length > 0) {
            return 'Status Pekerjaan Tidak Ada, Harap Hubungi Administrator'
        } else if (golonganValue > 0) {
            return 'Status Golongan Tidak Ada, Harap Hubungi Administrator'
        } else if (filename > 0) {
            return 'Surat Keputusan Belum di Upload, Harap Di Upload Terlebih Dahulu'
        } else {
            return ''
        }
    }

    handleApprove = (e, row, param) => {
        if (this.state.selectedRole === 15 || this.state.roleUserLogin === 15) {
            this.handleApprovePK(row)

        } else if (this.state.selectedRole === 16 || this.state.roleUserLogin === 16) {
            this.handleApprovePPK(row)
        }
    }

    handleReject = (e, row) => {
        let role = this.state.selectedRole === null ? this.state.roleUserLogin : this.state.selectedRole
        let LokasiStatus = 0
        if (this.state.selectedRole === 15 || this.state.roleUserLogin === 15) {
            LokasiStatus = 5
        } else if (this.state.selectedRole === 16 || this.state.roleUserLogin === 16) {
            LokasiStatus = 6
        } else if (this.state.selectedRole === 17 || this.state.roleUserLogin === 17) {
            LokasiStatus = 7
        }

        let id = []
        this.state.selectedRows.forEach(el => {
            id.push(el.id_user)
        })

        const dataPost = {
            "createBy": this.state.role,
            "role_sdm": this.state.selectedSDM,
            "status": LokasiStatus,
            "userId":
                [row.id_user]
        }

        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin Reject Data Verval ini?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                APIVerval.post('siga/pembayaranverval/rejectDataPembayaranVerval', dataPost)
                    .then(async (response) => {
                        if (response.status === 200 && response.data["Reject Data Pembayaran"] === "SUCCESS") {
                            Swal.fire({
                                title: 'Berhasil!',
                                icon: 'info',
                                text: 'Data Verval Berhasil Di Approve!',
                            }).then(() => {
                                this.setState({ blocking: true })
                                this.handleSearch()
                            })
                        } else {
                            Swal.fire({
                                title: 'Gagal!',
                                icon: 'warning',
                                text: 'Data Verval Gagal Di Approve!',
                            }).then(() => {
                                this.setState({ blocking: true })
                                this.handleSearch()
                            })
                        }
                    }).catch((error) => {
                        Swal.fire({
                            title: 'Error',
                            icon: 'warning',
                            text: error + ", Harap Hubungi Administrator",
                        })
                        this.setState({ selectedRows: [], blocking: false })
                        this.handleSearch()
                    })
            } else {
                this.setState({ selectedRows: [], blocking: false })
            }
        }
        )
    }

    handleApprovePK = (row) => {
        let role = this.state.selectedRole === null ? this.state.roleUserLogin : this.state.selectedRole

        const dataPost = [
            {
                "created_by": this.state.role,
                "id_kab": row.id_kabupaten,
                "id_kec": row.id_kecamatan,
                "id_kel": row.id_kelurahan,
                "id_prov": row.id_provinsi,
                "jml_dibayar": row.jml_dibayar,
                "jml_diterima": row.jml_diterima,
                "jml_pajak": row.jml_pajak,
                "role_id": row.role_id,
                "sdm": row.sdm,
                "status_approval": 1,
                "sumber_pendanaan": "APBN",
                "user_id": row.id_user
            }
        ]
        if (this.ValidasiPekerjaan(row)) {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: this.ValidasiPekerjaanText(row),
            }).then(() => {
                this.setState({ blocking: false })
            })
        } else {
            Swal.fire({
                title: 'Apakah Anda Yakin?',
                text: "Ingin Approve Data Verval ini?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak'
            }).then((result) => {
                if (result.isConfirmed) {
                    APIVerval.post('siga/pembayaranverval/insertApprovalPembayaranVerval', dataPost)
                        .then(async (response) => {
                            if (response.status === 200) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    icon: 'info',
                                    text: 'Data Verval Berhasil Di Approve!',
                                }).then(() => {
                                    this.setState({ blocking: true, selectedRows: [] })
                                    this.handleSearch()
                                })
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    icon: 'warning',
                                    text: 'Data Verval Gagal Di Approve!',
                                }).then(() => {
                                    this.setState({ blocking: true, selectedRows: [] })
                                    this.handleSearch()
                                })
                            }
                        }).catch((error) => {
                            Swal.fire({
                                title: 'Error',
                                icon: 'warning',
                                text: error + ", Harap Hubungi Administrator",
                            })
                            this.setState({ selectedRows: [], blocking: false })
                            this.handleSearch()
                        })
                } else {
                    this.setState({ selectedRows: [], blocking: false })
                }
            }
            )
        }
    }

    handleApprovePPK = (row) => {
        let role = this.state.selectedRole === null ? this.state.roleUserLogin : this.state.selectedRole
        const data = new FormData();
        data.append("approval_role", role);
        data.append("create_by", this.state.role);
        data.append("metode", "");
        data.append("sp2d", "");
        data.append("status_approval", 2);
        data.append("user_id", row.id_user);

        const dataPost = {
            "approval_role": role,
            "create_by": this.state.role,
            "metode": "",
            "sp2d": "",
            "status_approval": 2,
            "user_id": [
                row.id_user
            ]
        }
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin Approve Data Verval ini?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                APIVerval.post('siga/pembayaranverval/updateApprovalDataPembayaranVerval', data)
                    .then(async (response) => {
                        if (response.status === 200) {
                            Swal.fire({
                                title: 'Berhasil!',
                                icon: 'info',
                                text: 'Data Verval Berhasil Di Approve!',
                            }).then(() => {
                                this.setState({ blocking: true })
                                this.handleSearch()
                            })
                        } else {
                            Swal.fire({
                                title: 'Gagal!',
                                icon: 'warning',
                                text: 'Data Verval Gagal Di Approve!',
                            }).then(() => {
                                this.setState({ blocking: true })
                                this.handleSearch()
                            })
                        }
                    }).catch((error) => {
                        Swal.fire({
                            title: 'Error',
                            icon: 'warning',
                            text: error + ", Harap Hubungi Administrator",
                        })
                        this.setState({ selectedRows: [], blocking: false })
                        this.handleSearch()
                    })
            } else {
                this.setState({ selectedRows: [], blocking: false })
            }
        }
        )
    }

    saveSP2D = () => {

        let role = this.state.selectedRole === null ? this.state.roleUserLogin : this.state.selectedRole;
        let id = [];
        this.state.selectedRows.forEach(el => {
            id.push(el.id_user);
        });

        const data = new FormData();
        data.append("approval_role", role);
        data.append("create_by", this.state.role);
        data.append("metode", this.state.selectedMetode);
        data.append("createdDateSp2d", this.state.tgl_sp2d);
        data.append("sp2d", this.state.sp2d);
        data.append("status_approval", 3);
        data.append("file", this.state.setFile);


        if (this.state.dataSP2D === undefined) {
            id.forEach(userId => data.append("user_id", userId));
        } else {
            data.append("user_id", this.state.dataSP2D.id_user);
        }
        

        if (this.state.sp2d === '' || this.state.selectedMetode.length === 0 || this.state.setFile === null) {
            Swal.fire({
                title: 'Info!',
                icon: 'warning',
                text: "Kolom Semua Wajib Di Isi!",
            })
        } else {
            Swal.fire({
                title: 'Apakah Anda Yakin?',
                text: "Data Verval ini?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak'
            }).then((result) => {
                if (result.isConfirmed) {
                    APIVerval.post('siga/pembayaranverval/updateApprovalDataPembayaranVerval', data)
                        .then(async (response) => {
                            if (response.status === 200) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    icon: 'info',
                                    text: 'Data Verval Berhasil Di Approve!',
                                }).then(() => {
                                    this.setState({
                                        blocking: true, dataSP2D: undefined, onSubmit: true, metodeTampil: [],
                                        sp2d: '', tgl_sp2d: '', selectedRows: []
                                    })
                                    this.handleSearch()
                                    this.openCloseInputSP2D()

                                })
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    icon: 'warning',
                                    text: 'Data Verval Gagal Di Approve!',
                                }).then(() => {
                                    this.setState({ blocking: true, dataSP2D: undefined, selectedRows: [] })
                                    this.handleSearch()
                                })
                            }
                        }).catch((error) => {
                            Swal.fire({
                                title: 'Error',
                                icon: 'warning',
                                text: error + ", Harap Hubungi Administrator",
                            })
                            this.setState({ selectedRows: [], blocking: false, dataSP2D: undefined })
                            this.handleSearch()
                        })
                } else {
                    this.setState({ selectedRows: [], blocking: false, dataSP2D: undefined })
                }
            }
            )
        }
    }

    handleApproveAll = () => {
        if (this.state.selectedRole === 15 || this.state.roleUserLogin === 15) {
            this.handleApproveAllPK()
        } else if (this.state.selectedRole === 16 || this.state.roleUserLogin === 16) {
            this.handleApproveAllPPK()
        }
    }

    handleApproveAllPK = () => {

        let dataAll = []
        dataAll = this.state.selectedRows

        if (false) {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                // text: this.ValidasiPekerjaanTextMulti(this.state.selectedRows),
            }).then(() => {
                this.setState({ blocking: false })
            })
        } else {
            if (dataAll.length > 0) {
                Swal.fire({
                    title: 'Apakah Anda Yakin?',
                    text: "Ingin Approve Data VERVAL ini?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // "jml_kk_ada": el.jml_kk_ada,
                        // "jml_kk_baru": el.jml_kk_baru,
                        // "jml_kk_resiko": el.jml_kk_resiko,
                        // "jml_kk_tdk_resiko": el.jml_kk_tdk_resiko,

                        // "jml_pajak": el.jml_pajak,
                        // "jml_resiko_stunting": el.jml_kk_krs,
                        // "jml_sasaran": el.jml_sasaran,
                        // "jml_total_sasaran": el.jml_total_sasaran,
                        this.setState({ selectedRows: [], blocking: true, datas: [] })
                        let promises = []
                        Promise.all(dataAll.map(async (el) => {
                            const dataPost = [
                                {
                                    "created_by": this.state.role,
                                    "id_kab": el.id_kabupaten,
                                    "id_kec": el.id_kecamatan,
                                    "id_kel": el.id_kelurahan,
                                    "id_prov": el.id_provinsi,
                                    "jml_dibayar": el.jml_dibayar,
                                    "jml_diterima": el.jml_diterima,
                                    "jml_pajak": el.jml_pajak,
                                    "role_id": el.role_id,
                                    "sdm": el.sdm,
                                    "status_approval": 1,
                                    "sumber_pendanaan": "APBN",
                                    "user_id": el.id_user
                                }
                            ]
                            promises.push(

                                APIVerval.post('siga/pembayaranverval/insertApprovalPembayaranVerval', dataPost)
                                    .then(async (response) => {
                                        let obj = {};
                                        var timeStamp = response;
                                        obj = timeStamp;
                                        this.state.datapromises.push(obj);
                                    })
                            )

                        })).then(() => {
                            Promise.all(promises).then(() => {

                                let datapro = []
                                let datapromise = []
                                datapro = this.state.datapromises.filter(el => el.data["Insert Approval Pembayaran Verval"] === "FAILED")
                                datapromise = this.state.datapromises.filter(el => el.data["Insert Approval Pembayaran Verval"] === "SUCCESS")

                                if (datapro.length > 0) {
                                    Swal.fire({
                                        title: 'Info!',
                                        icon: 'warning',
                                        text: 'Data VERVAL Berhasil di Aprrove dan Ada ' + datapro.length + ' Data yang Gagal',
                                    }).then(resp => {
                                        this.handleSearch()
                                        this.setState({ selectedRows: [], blocking: true })
                                    })

                                } else if (datapromise.length === this.state.datapromises.length) {
                                    Swal.fire({
                                        title: 'Info!',
                                        icon: 'success',
                                        text: 'Data VERVAL Berhasil di Aprrove',
                                    }).then(resp => {
                                        this.handleSearch()
                                        this.setState({ selectedRows: [], blocking: true })
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Info!',
                                        icon: 'success',
                                        text: 'Data Gagal di Aprrove',
                                    }).then(resp => {
                                        this.handleSearch()
                                        this.setState({ selectedRows: [], blocking: true })
                                    })
                                }
                            })
                        })
                    } else {
                        this.setState({blocking:false})
                    }
                })
            } else {
                Swal.fire({
                    title: 'Info!',
                    icon: 'warning',
                    text: 'Data Tidak Bisa DiApprove!',
                })
            }
        }
    }

    handleApproveAllPPK = () => {

        let role = this.state.selectedRole === null ? this.state.roleUserLogin : this.state.selectedRole
        let id = []

        this.state.selectedRows.forEach(el => {
            id.push(el.id_user)
        })

        // const dataPost = {
        //     "approval_role": role,
        //     "create_by": this.state.role,
        //     "metode": "",
        //     "sp2d": "",
        //     "status_approval": 2,
        //     "user_id": id
        // }

        const data = new FormData();
        data.append("approval_role", role);
        data.append("create_by", this.state.role);
        data.append("metode", "");
        data.append("sp2d", "");
        data.append("status_approval", 2);
        data.append("user_id", id);

        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin Approve Data Verval ini?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                APIVerval.post('siga/pembayaranverval/updateApprovalDataPembayaranVerval', data)
                    .then(async (response) => {
                        if (response.status === 200) {
                            Swal.fire({
                                title: 'Berhasil!',
                                icon: 'info',
                                text: 'Data Verval Berhasil Di Approve!',
                            }).then(() => {
                                this.setState({ blocking: true })
                                this.handleSearch()
                            })
                        } else {
                            Swal.fire({
                                title: 'Gagal!',
                                icon: 'warning',
                                text: 'Data Verval Gagal Di Approve!',
                            }).then(() => {
                                this.setState({ blocking: true })
                                this.handleSearch()
                            })
                        }
                    }).catch((error) => {
                        Swal.fire({
                            title: 'Error',
                            icon: 'warning',
                            text: error + ", Harap Hubungi Administrator",
                        })
                        this.setState({ selectedRows: [], blocking: false })
                        this.handleSearch()
                    })
            } else {
                this.setState({ selectedRows: [], blocking: false })
            }
        }
        )
    }




    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }


    //inputSP2D

    handleChangeForm = (e) => {
        if (e.target.name === 'sp2d') {
            if (isNumber(e.target.value)) {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }

    }

    handleChangeFormSp2d = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9-/.@ ]/g, '')
        });

    }

    sel_tanggal = (e) => {

        // .format('YYYY-MM-DDTHH:mm:ss.sssZ'))
        if (e) {
            this.setState({
                //tgl_sp2d: moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss.sssZ').toString()
                tgl_sp2d: e.target.value
            })
        }
    }

    sel_metode = (e) => {
        if (e) {
            this.setState({
                selectedMetode: e.value,
                metodeTampil: e
            });

        }
        else {
            this.setState({
                selectedMetode: null
            }
            )
        }
    }



    openCloseInputSP2D = (reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openInputSP2D: !this.state.openInputSP2D, dataSP2D: row === undefined ? undefined : row },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )

    }


    openClosePreviewRekapRT = (event, reason, row) => {

        if (reason && reason === "backdropClick")
            return;
        this.setState({ openPreviewRekapRT: !this.state.openPreviewRekapRT },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )
    }



    //select row

    handleSelectRow = (cat, row, isSelect) => {
        this.setState({ disApprov: false });
        let data = this.state.datas
        let dataAll = []
        if (isSelect === true && cat === 'single') {
            this.setState({ selectedRows: this.state.selectedRows.concat(row) });
        } else if (isSelect === true && cat === 'all') {
            if (this.state.textCari !== '') {
                dataAll = this.state.datas.filter(el => el.userName === row.userName)
                this.setState({ selectedRows: dataAll, dataFilter: dataAll });
            } else {
                this.setState({ selectedRows: data });
            }
        } else if (cat === 'single') {
            var array = JSON.parse(JSON.stringify(this.state.selectedRows));
            var index = array.findIndex(el => el.id_user === row.id_user);
            if (index !== -1) {
                array.splice(index, 1);
                if (this.state.selectedRows?.length > 1) {
                    this.setState({ selectedRows: array, disApprov: false });
                } else {
                    this.setState({ selectedRows: array, disApprov: true });
                }
            }
        } else {
            this.setState({ selectedRows: [], disApprov: true });
        }


    }

    sel_Tunggakan = (e) => {
        if (e) {
            this.setState({
                selectedTunggakan: e.value, tunggakanTampil: e,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                selectedTunggakan: null, tunggakanTampil: null,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    onSearchChange = (searchText, colInfos, multiColumnSearch) => {
        this.setState({ textCari: searchText })

    }

    previewFormatter = (cell, row) => {


        return <div className='divBtnTable'>
            <Tooltip title="View Rekap RT"><Button id={row.id} onClick={(e) => this.viewRekapRT(e, row)} className='btnTable' variant="outlined"><HiOutlineDocumentText style={{ color: 'green' }} /></Button></Tooltip>
            {/* <Tooltip title="View Profil"><Button id={row.id} className='btnTable' variant="outlined"><CgProfile style={{ color: 'blue' }} /></Button></Tooltip> */}

        </div>
    }

    viewRekapRT = (e, row) => {


        // this.setState({ blocking: true });
        this.setState({ blocking: false, dataRekap: [row] }, () => { this.openClosePreviewRekapRT(e, row) })
    }


    render() {

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10000px',
            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRow('single', row, isSelect)
            },
            onSelectAll: (isSelect, rows, e) => {
                if (isSelect) {
                    this.handleSelectRow('all', rows, isSelect)
                    return this.state.datas.map(row => row.id_user)
                } else {
                    this.handleSelectRow('all', rows, isSelect)
                    return [];
                }
                // 
                // 
                // 
            }

        };
        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };

        const options2 = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage2, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };

        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >
                <div className="content">
                    <div className="title-content">
                        <ImPlay3 className="icon-brd" />
                        Approval Pembayaran APBN KRS
                    </div>
                    <div className="body-content">
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Provinsi
                                                        <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kabupaten
                                                        <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kecamatan
                                                        <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kelurahan
                                                        <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        SDM
                                                        <Select options={this.state.dt_sdm} onChange={this.sel_sdm} value={this.filterSDM} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                {(this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) &&
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl sx={{ width: '100%' }} size="small">
                                                            User Role
                                                            <Select options={this.state.dt_role} onChange={this.sel_role} value={this.filterRole} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} md={4}>
                                                    <Grid item xs={12}>
                                                        <Button onClick={(e) => this.handleSearch()} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} />Cari</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <h3>Approval Pembayaran Verval </h3>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                            <Grid item xs={12} md={12}>
                                {(this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) &&
                                    <>
                                        {(this.state.selectedRole === 17) && // Bendahara
                                            <>
                                                {this.state.disAprrovaladmin === true && <Button disabled={this.state.selectedRows.length === 0 || this.state.disApprov} onClick={(e) => this.openCloseInputSP2D(e)} sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsPencilSquare className='icon-brd' style={{ marginRight: '5px' }} /> Simpan SP2D</Button>}
                                            </>
                                        }
                                        {((this.state.selectedRole === 15 || this.state.selectedRole === 16)) && //Pelaksana Kegiatan
                                            <>
                                                {this.state.disAprrovaladmin === true && <Button disabled={this.state.selectedRows.length === 0 || this.state.disApprov} onClick={(e) => this.handleApproveAll()} sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Approve </Button>}
                                            </>
                                        }
                                    </>
                                }
                                {((this.state.roleUserLogin !== 19 && this.state.roleUserLogin !== 99)) &&
                                    <>
                                        {(this.state.roleUserLogin === 17) && // Bendahara
                                            <>
                                                {this.state.disAprrovalProv === true && <Button disabled={this.state.selectedRows.length === 0 || this.state.disApprov} onClick={(e) => this.openCloseInputSP2D(e)} sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsPencilSquare className='icon-brd' style={{ marginRight: '5px' }} /> Simpan SP2D</Button>}
                                            </>
                                        }
                                        {((this.state.roleUserLogin === 15 || this.state.roleUserLogin === 16)) && //Pelaksana Kegiatan
                                            <>
                                                {this.state.disAprrovalProv === true && <Button disabled={this.state.selectedRows.length === 0 || this.state.disApprov} onClick={(e) => this.handleApproveAll()} sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Approve </Button>}
                                            </>
                                        }
                                    </>
                                }

                            </Grid>
                        </Grid>

                        <Paper sx={{ width: "100%", boxShadow: "none" }}>


                            <div className="rekap-table">
                                <BootstrapTable
                                    keyField="id_user"
                                    data={this.state.datas}
                                    pagination={true}
                                    options={options}
                                    search={true}
                                    selectRow={selectRow}
                                    trStyle={this.rowStyleFormat}
                                >
                                    <TableHeaderColumn
                                        row="0"
                                        rowSpan="2"
                                        width="80"
                                        dataField="action"
                                        headerAlign="center"
                                        dataAlign="center"
                                        columnClassName={this.createdFormat}
                                        dataFormat={this.buttonFormatter}
                                    >
                                        ACTION
                                    </TableHeaderColumn>

                                    {this.state.selectedSDM === 25 ? (
                                        <TableHeaderColumn
                                            row="0"
                                            rowSpan="2"
                                            width="120"
                                            dataField="kode_kel"
                                            headerAlign="center"
                                            dataAlign="center"
                                            columnClassName={this.createPKFormat}
                                            dataFormat={this.PKFormatter}
                                        >
                                            KODE KELURAHAN
                                        </TableHeaderColumn>
                                    ) : (
                                        <TableHeaderColumn
                                            row="0"
                                            rowSpan="2"
                                            width="120"
                                            dataField="kode_kec"
                                            headerAlign="center"
                                            dataAlign="center"
                                            columnClassName={this.createPKFormat}
                                            dataFormat={this.PKFormatter}
                                        >
                                            KODE KECAMATAN
                                        </TableHeaderColumn>
                                    )}

                                    {this.state.selectedSDM === 25 ? (
                                        <TableHeaderColumn
                                            row="0"
                                            rowSpan="2"
                                            width="120"
                                            dataField="nama_kelurahan"
                                            headerAlign="center"
                                            dataAlign="center"
                                            columnClassName={this.createPKFormat}
                                            dataFormat={this.PKFormatter}
                                        >
                                            NAMA KELURAHAN
                                        </TableHeaderColumn>
                                    ) : (
                                        <TableHeaderColumn
                                            row="0"
                                            rowSpan="2"
                                            width="120"
                                            dataField="nama_kecamatan"
                                            headerAlign="center"
                                            dataAlign="center"
                                            columnClassName={this.createPKFormat}
                                            dataFormat={this.PKFormatter}
                                        >
                                            NAMA KECAMATAN
                                        </TableHeaderColumn>
                                    )}

                                    <TableHeaderColumn
                                        row="0"
                                        rowSpan="2"
                                        width="120"
                                        dataField="user_name"
                                        headerAlign="center"
                                        dataAlign="center"
                                        columnClassName={this.createPKFormat}
                                        dataFormat={this.PKFormatter}
                                    >
                                        USERNAME
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        row="0"
                                        rowSpan="2"
                                        width="120"
                                        dataField="nama_lengkap"
                                        headerAlign="center"
                                        dataAlign="center"
                                        columnClassName={this.createPKFormat}
                                        dataFormat={this.PKFormatter}
                                    >
                                        NAMA LENGKAP
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        row="0"
                                        rowSpan="2"
                                        width="100"
                                        dataField="jml_dibayar"
                                        headerAlign="center"
                                        dataAlign="right"
                                        columnClassName={this.createdFormat}
                                        dataFormat={this.rupiahFormatter}
                                    >
                                        JUMLAH DIBAYAR
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        row="0"
                                        rowSpan="2"
                                        width="100"
                                        dataField="jml_pajak"
                                        headerAlign="center"
                                        dataAlign="right"
                                        columnClassName={this.createdFormat}
                                        dataFormat={this.rupiahFormatter}
                                    >
                                        PAJAK
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        row="0"
                                        rowSpan="2"
                                        width="140"
                                        dataField="jml_diterima"
                                        headerAlign="center"
                                        dataAlign="right"
                                        columnClassName={this.createdFormat}
                                        dataFormat={this.rupiahFormatter}
                                    >
                                        JUMLAH YANG DITERIMA
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="100"
                                        row="0"
                                        colSpan="3"
                                        headerAlign="center"
                                        dataAlign="center"
                                        columnClassName={this.createdFormat}
                                    >
                                        Jumlah Sasaran KRS Verval
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="120"
                                        row="1"
                                        headerAlign="center"
                                        dataField="total_verval_ada"
                                        dataAlign="center"
                                        columnClassName={this.createdFormat}
                                    >
                                        Jumlah Keluarga Ada
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="120"
                                        row="1"
                                        dataField="total_verval_baru"
                                        headerAlign="center"
                                        dataAlign="center"
                                        columnClassName={this.createdFormat}
                                    >
                                        Jumlah Keluarga Baru
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="100"
                                        row="1"
                                        dataField="total_verval_adabaru"
                                        headerAlign="center"
                                        dataAlign="center"
                                        columnClassName={this.createdFormat}
                                    >
                                        Total
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        row="0"
                                        rowSpan="2"
                                        width="100"
                                        dataField="diterima"
                                        headerAlign="center"
                                        dataAlign="center"
                                        columnClassName={this.createdFormatDuplicate}
                                        dataFormat={this.previewFormatter}
                                    >
                                        PREVIEW
                                    </TableHeaderColumn>
                                </BootstrapTable>


                            </div>
                            <div className="sizePerPage">
                                Size Per Page
                                <select
                                    name="category"
                                    value={this.state.sizePerPage}
                                    onChange={this.handleChangeSizePerPage}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value={this.state.datas?.length}>All</option>
                                </select>
                            </div>
                            <div className="totalData">
                                <b>Total Data: {this.state.datas?.length}</b>
                            </div>
                        </Paper>

                        <Dialog open={this.state.openPreviewRekapRT} onClose={this.openClosePreviewRekapRT}
                            fullWidth maxWidth={'lg'}>
                            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >

                                <DialogTitle>Detail Rekap VERVAL </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <Paper sx={{ width: "100%", boxShadow: "none" }}>

                                            <div className="rekap-table">
                                                <BootstrapTable
                                                    data={this.state.dataRekap}
                                                    pagination={true}
                                                    options={options2}
                                                    keyField='kodeRT'

                                                >
                                                    <TableHeaderColumn width="80" row='0' colSpan='12' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Sasaran KRS (Baseline)</TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PUS </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PUS Hamil/Ibu Hamil </TableHeaderColumn>
                                                    <TableHeaderColumn width="180" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Keluarga punya anak 0-23 bulan </TableHeaderColumn>
                                                    <TableHeaderColumn width="180" row='1' colSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Keluarga punya anak 24-59 bulan </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TOTAL </TableHeaderColumn>

                                                    <TableHeaderColumn width="80" row='2' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SASARAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >VERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PRIORITAS SASARAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PRIORITAS TERVERVAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='2' colSpan='1' rowSpan='2' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >SELAIN ADA/BARU TERVERVAL </TableHeaderColumn>

                                                    <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >ADA </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >BARU </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='3' colSpan='1' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >JUMLAH </TableHeaderColumn>

                                                    <TableHeaderColumn width="80" row='0' colSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Sasaran KRS Verval</TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Keluarga Ada </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Jumlah Keluarga Baru </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='1' colSpan='1' rowSpan='3' headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >Total </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_ada" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >5 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_baru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >6 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pus_verval_adabaru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >7 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pushamil_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >8 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="pushamil_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >9 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="baduta_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >10 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="baduta_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >11 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="balita_sasaran" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >12 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="balita_verval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >13 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_prioritas" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >14 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_prioritas_terverval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >15 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="sasaran_notin_adabaru_terverval" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >15 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_ada" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >17 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_baru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >18 </TableHeaderColumn>
                                                    <TableHeaderColumn width="80" row='4' colSpan='1' dataField="total_verval_adabaru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >19=17+18 </TableHeaderColumn>
                                                </BootstrapTable>

                                            </div>

                                        </Paper>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.openClosePreviewRekapRT}>Tutup</Button>
                                </DialogActions>
                            </BlockUi>
                        </Dialog>
                        <Dialog open={this.state.openInputSP2D} onClose={this.openCloseInputSP2D}
                            fullWidth maxWidth={'md'}>
                            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >

                                <DialogTitle>Input SP2D </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <Grid
                                            container
                                            rowSpacing={3}
                                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                        >
                                            <Grid item xs={2} style={{ display: 'inline-block' }}>
                                                <div >
                                                    Tanggal
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField name='tgl_sp2d' onChange={this.sel_tanggal} value={this.state.tgl_sp2d} inputProps={{ style: { height: "6px" } }} type="date" id="outlined-basic" isClearable />
                                            </Grid>
                                            {/* <Grid item xs={2} style={{ display: 'inline-block' }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                Tanggal
                                                <TextField name='tgl_sp2d' onChange={this.sel_tanggal} value={this.state.tgl_sp2d} inputProps={{ style: { height: "6px" } }} type="date" id="outlined-basic" isClearable />
                                            </FormControl>

                                        </Grid> */}
                                            {/* 
                                        <Grid item xs={4}>
                                            <div>
                                                <label>{moment().format('DD-MM-YYYY')}</label>
                                            </div>
                                        </Grid> */}
                                        </Grid>
                                        <p></p>
                                        <p></p>
                                        <Grid
                                            container
                                            rowSpacing={3}
                                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                        >

                                            <Grid item xs={2} style={{ display: 'inline-block' }}>
                                                <div >
                                                    No. SP2D
                                                </div>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <TextField
                                                    //error={this.state.errors["email"] ? true : false}
                                                    name="sp2d"
                                                    variant="outlined"
                                                    //type="email"
                                                    style={{ width: '100%', bottom: '5px' }}
                                                    value={this.state.sp2d}
                                                    size="small"
                                                    onChange={this.handleChangeFormSp2d}
                                                //helperText={this.state.errors["email"] ? this.state.errors["email"] : ''}

                                                />
                                            </Grid>
                                        </Grid>
                                        <p></p>
                                        <Grid
                                            container
                                            rowSpacing={3}
                                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                        >

                                            <Grid item xs={2} style={{ display: 'inline-block' }}>
                                                <div >
                                                    Metode
                                                </div>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Select options={this.state.metode} onChange={this.sel_metode} value={this.state.metodeTampil} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />


                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ marginTop: '20px' }}>
                                            <h5 >UPLOAD SP2D</h5>

                                            <FileUploader
                                                handleChange={this.handleChange}
                                                name="pdf"
                                                label={'Upload File SP2D'}
                                                types={fileTypes}
                                                hoverTitle="Upload File"
                                                // disabled={this.state.file_name === '' ? false : true}
                                                style={{ width: '100px' }}

                                            />

                                        </Grid>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.saveSP2D} >Simpan</Button>
                                    <Button onClick={this.openCloseInputSP2D}>Batal</Button>
                                </DialogActions>
                            </BlockUi>
                        </Dialog>
                    </div>
                </div>
            </BlockUi >
        );
    }
}

export default ApprovalPendataan;
