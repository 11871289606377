import React, { Component } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImPlay3, ImUserPlus, ImSearch, ImPhone, ImLocation, ImFloppyDisk, ImCross, ImPrinter } from 'react-icons/im';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdAlternateEmail, MdPassword } from 'react-icons/md';
import { FcProcess } from "react-icons/fc";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { BsPersonCheckFill, BsPersonCheck } from "react-icons/bs";
import CommonTable from '../../../commons/table/Table';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import APIWilayah from '../../../services/APIWilayah';
import APIUser from "../../../services/APIUser";
import authServices from "../../../services/authServices";
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import download from 'downloadjs'
import APIReport from '../../../services/APIReport';
import APIFormKK from '../../../services/APIFormKK';


class CetakFormulirKK extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sendDatas: {},
            panel: true,

            //---table
            sizePerPage: 10,
            tableHead: [],
            datas: [],
            //---end table

            //---wilayah
            flagNumber: -1,
            flagOpen: true,
            blocking: false,
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
            //---end wilayah

            dt_roles: [],
            role: [],
            openEditUser: false,

            changePassword: false,
            showPasswordLama: false,
            showPasswordBaru: false,
            showPasswordBaruKonfirm: false,
            password: '',
            passwordBaru: '',
            updateBy: localStorage.getItem("username"),
            userId: 0,
            tableHead: [],
            text21: '',
            text1: 'PROVINSI',
        }
    }

    getRole = (e) => {
        authServices
            .get("/roles/list")
            .then((res) => {
                this.setState({ blocking: false });
                if (res.status === 200) {
                    this.setState({
                        dt_roles: res.data
                    }, () => {
                    })
                }
            })
            .catch((error) => {
                this.setState({ blocking: false });
                
            });
    }

    activeBtn = (e) => {
        const idUser = parseInt(e.currentTarget.id);
        let status = e.currentTarget.value;
        if (status == "true") {
            status = false;
        } else {
            status = true;
        }
        this.setState({
            updateStatus: {
                id: idUser,
                isActive: status
            }
        })

        Swal.fire({
            title: 'Apakah Kamu Yakin?',
            text: "Ingin mengubah status User ini?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ blocking: true });
                authServices.post("daftar/updateUserStatus?id=" + this.state.updateStatus.id + "&isActive=" + this.state.updateStatus.isActive)
                    .then((res) => {
                        this.setState({ blocking: false });
                        if (res.status === 200) {
                            //Update state berdasarkan id yg diubah
                            const arr = { id: this.state.updateStatus.id, isActive: this.state.updateStatus.isActive }
                            this.setState({
                                datas: this.state.datas.map(el => el.id === this.state.updateStatus.id ? Object.assign({}, el, arr) : el)
                            })
                            Swal.fire(
                                'Berhasil!',
                                'Kamu Berhasil Mengubah Status User ini.',
                                'success'
                            )
                            this.setLabel()
                        }
                    })
                    .catch((error) => {
                        this.setState({ blocking: false });
                        
                    });
            } else {
                this.setState({blocking: false })
            }
        })
    }


    clearForm = () => {
        this.setState({ id: null, passwordBaru: '', password: '' })
    }

    buttonFormatter = (cell, row) => {
        return <div className='divBtnTable'>
            <Tooltip title="Ubah Status User"><Button onClick={this.activeBtn} id={row.id} value={row.isActive} className='btnTable' variant="outlined"><FcProcess /></Button></Tooltip>
            <Tooltip title="Ubah Password"><Button onClick={(e) => this.editPassword(e, row)} id={row.id} className='btnTable' variant="outlined"><MdPassword /></Button></Tooltip>
            {/* <Button onClick={(e) => this.handleEditUser(e, row)} id={row.id}  className='btnTable' variant="outlined"><AiTwotoneEdit style={{color:'green'}} /></Button> */}
            <Tooltip title="Edit User">
                <Button className='btnTable' variant="outlined">
                    <Link to={`/edit-user/${row.username}`}><AiTwotoneEdit style={{ color: 'green' }} /></Link>
                </Button>
            </Tooltip>
        </div>
    }

    statusFormatter = (cell, row) => {
        if (row.isActive === true) {
            return <div className='userActive'>< MdOutlineCheckBox style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Aktif</div>
        } else {
            return <div className='userNonActive'><MdOutlineCheckBoxOutlineBlank style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Non-Aktif</div>
        }
    }

    createdFormat = (cell, row) => {
        if (row?.roleName === "Supervisor" && row?.validasiDate === null) {
            return 'adadad'
        } else {
            return ''
        }
    }

    setHeaderTable = () => {
        let { text1 } = this.state
        const { provinsi, kabupaten, kecamatan, desa, rw } = this.state
        let header = []
        var headerNamaWilayah = [
            { width: "100", row: '0', rowSpan: '3', title: text1, dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'center', editable: false, className: '', columnClassName: '' },
        ]
        var headerColumns = [
            //{ width: "150", row: '0', rowSpan: '3', title: 'PROVINSI', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'center', editable: false, className: '', columnClassName: '' },

            { width: "50", row: '1', colSpan: '1', title: "Individu Usia < 5 Tahun", dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
            { width: "50", row: '1', colSpan: '3', title: "Individu Usia < 10 Tahun", dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
            { width: "300", row: '1', colSpan: '1', title: "Individu Usia < 14 Tahun", dataField: '#', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'c7ebcd', columnClassName: 'c7ebcd' },
            { width: "60", row: '2', colSpan: '1', title: 'Yang Sekolah', dataField: "kabToolsLama", dataFormat: 'systemLama', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
            { width: "50", row: '2', colSpan: '1', title: 'Status Kawin', dataField: "kabCapilBaru", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
            { width: "60", row: '2', colSpan: '1', title: 'Status Bekerja', dataField: "kabCapilHapus", dataFormat: 'systemBaru', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
            { width: "50", row: '2', colSpan: '1', title: 'Sebagai Kepala keluarga', dataField: "kabCapilIn", dataFormat: 'systemIn', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
            { width: "50", row: '2', colSpan: '1', title: 'Tidak Memiliki NIK', dataField: "kabCapilOut", dataFormat: 'systemOut', headerAlign: 'center', dataAlign: 'right', editable: false, className: 'fcfad7', columnClassName: 'fcfad7' },
            { width: "40", row: '1', rowSpan: '2', colSpan: '1', title: 'Anomali', dataField: 'kabTotal', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'edafaf', columnClassName: 'edafaf' },
            { width: "50", row: '1', rowSpan: '2', colSpan: '1', title: 'Anomali Terkonfirmasi', dataField: 'kabTotal', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'edafaf', columnClassName: 'edafaf' },
            { width: "40", row: '1', rowSpan: '2', colSpan: '1', title: 'Aksi', dataField: 'kabTotal', dataFormat: 'systemTotal', headerAlign: 'center', dataAlign: 'center', editable: false, className: 'edafaf', columnClassName: 'edafaf' },
        ];


        let head = rw ? header = headerNamaWilayah.concat(headerColumns) :
            desa ? header = headerNamaWilayah.concat(headerColumns) :
                kecamatan ? header = headerNamaWilayah.concat(headerColumns) :
                    kabupaten ? header = headerNamaWilayah.concat(headerColumns) :
                        provinsi ? header = headerNamaWilayah.concat(headerColumns) :
                            header = headerNamaWilayah.concat(headerColumns)

        this.setState({ tableHead: head });
    }

    componentDidMount = () => {
        this.setHeaderTable()

        const rest = JSON.parse(localStorage.getItem("authority"));
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }

                if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                }
                if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    //API Wilayah
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            // 
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            // 
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            // 
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
                sendDatas: { ...this.state.sendDatas, idProvinsi: e.value }
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        // 
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.handleSelectRole()
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {},
            }
                ,
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
                sendDatas: { ...this.state.sendDatas, idKabupaten: e.value }
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.handleSelectRole()
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "idProvinsi": this.state.sendDatas.idProvinsi,
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
                sendDatas: { ...this.state.sendDatas, idKecamatan: e.value }
            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.handleSelectRole()
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "idProvinsi": this.state.sendDatas.idProvinsi,
                    "idKabupaten": this.state.sendDatas.idKabupaten,
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
                sendDatas: { ...this.state.sendDatas, idKelurahan: e.value }
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.handleSelectRole()
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "idProvinsi": this.state.sendDatas.idProvinsi,
                    "idKabupaten": this.state.sendDatas.idKabupaten,
                    "idKecamatan": this.state.sendDatas.idKecamatan,
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
                sendDatas: { ...this.state.sendDatas, idRw: e.value }
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.handleSelectRole()
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "idProvinsi": this.state.sendDatas.idProvinsi,
                    "idKabupaten": this.state.sendDatas.idKabupaten,
                    "idKecamatan": this.state.sendDatas.idKecamatan,
                    "idKelurahan": this.state.sendDatas.idKelurahan,
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
                sendDatas: { ...this.state.sendDatas, idRt: e.value }
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.handleSelectRole()
            this.setState({
                rt: null, id_rt: 0,
                sendDatas: {
                    "idProvinsi": this.state.sendDatas.idProvinsi,
                    "idKabupaten": this.state.sendDatas.idKabupaten,
                    "idKecamatan": this.state.sendDatas.idKecamatan,
                    "idKelurahan": this.state.sendDatas.idKelurahan,
                    "idRw": this.state.sendDatas.idRW,
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    handleSelectRole = (e) => {
        if (e) {
            this.setState({ role: e })
            this.setState({ sendDatas: { ...this.state.sendDatas, roleId: e.value } });
        } else {
            this.setState({ role: [], sendDatas: { ...this.state.sendDatas, roleId: '' } })
        }

    }

    handleChangePanel = (e) => {
        e.preventDefault();
        this.setState({ panel: !this.state.panel })
    }

    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    setLabel = () => {
        let title, text1, text2
        const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
        if (id_rt !== 0) {
            title = ' di RT ' + this.state.rt.label
        } else {
            title = ''
            if (id_rw !== 0) {
                title = ' di RW ' + this.state.rw.label
                text1 = 'RT'
                text2 = 'JUMLAH KK'
            } else {
                title = ''
                if (id_des !== 0) {
                    title = ' di Kelurahan ' + this.state.desa.label
                    text1 = 'RW'
                    text2 = 'JUMLAH RT'
                } else {
                    title = ''
                    if (id_kecamatan !== 0) {
                        title = ' di Kecamatan ' + this.state.kecamatan.label
                        text1 = 'KELURAHAN'
                        text2 = 'JUMLAH RW'
                    } else {
                        title = ''
                        if (id_kabupaten !== 0) {
                            title = ' di Kabupaten ' + this.state.kabupaten.label
                            text1 = 'KECAMATAN'
                            text2 = 'JUMLAH KELURAHAN'
                        } else {
                            title = ''
                            if (id_provinsi !== 0) {
                                title = ' di Provinsi ' + this.state.provinsi.label
                                text1 = 'KABUPATEN'
                                text2 = 'JUMLAH KECAMATAN'
                            } else {
                                title = ' Tingkat Nasional'
                                text1 = 'PROVINSI'
                                text2 = 'JUMLAH KABUPATEN'
                            }
                        }
                    }
                }
            }
        }

        this.setState({ title: title, text1: text1, text2: text2 },
            () => { this.setHeaderTable() }
        )
    }

    handleSearch = () => {
        this.setLabel()
        this.getBackend(this.state.sendDatas)
    }
    handleCetak = (e) => {
        const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;

        if(id_kecamatan !== 0 && id_kecamatan !== undefined){

            // if (this.handleValidation()) {
            this.setState({ blocking: true });

            Swal.fire({
                title: 'Cetak Formulir',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                // showDenyButton: true,
                // denyButtonText: 'PDF',
                // denyButtonColor: '#FF8000',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {
    
                let url = 'viewReport?filterPencarian=%7B'
    
                if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
                if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
                if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
                if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
                if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }
                if (id_rt !== 0 && id_rt !== undefined) { url += '%22rtId%22%3A%22' + id_rt + '%22%2C' }

    
    
                //viewReport?filterPencarian=%7B%22provinsiId%22%3A%222%22%2C%22reportName%22%3A%22FormKK%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22CetakForm%22%7D
    
                let urlCetakPdf = url + '%22reportName%22%3A%22FormKKProd' + '%22%2C' + '%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22CetakForm%22%7D'
                
                let urlCetakExcel = url + '%22reportName%22%3A%22FormKKProd' + '%22%2C' + '%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22CetakForm%22%7D'
    
                // if (result.isConfirmed) {
                //     APIReport.get(urlCetakExcel, { responseType: 'blob' })
                //         .then(res => {
                //             if (res.status === 200) {
                //                 const content = res.headers['content-type'];
                //                 download(res.data, 'Formulir KK' + '.xlsx', content)
                //                 this.setState({ blocking: false })
                //             } else {
                //                 Swal.fire({
                //                     title: 'Info!',
                //                     icon: 'info',
                //                     text: 'Data tidak ditemukan.',
                //                     showConfirmButton: false,
                //                     timer: 1500
                //                 })
                //                 this.setState({ blocking: false });
                //             }
                //         }).catch((error) => {
                //             // 
                //             let message = error;
                //             Swal.fire({
                //                 title: 'Error!',
                //                 icon: 'warning',
                //                 // text: 'Jaringan bermasalah.',
                //                 text: message,
                //             })
                //             this.setState({ blocking: false });
                //         });
    
                // } 
                // else 
                if (result.isConfirmed) {
                    APIFormKK.get(urlCetakPdf, { responseType: 'blob' })
                        .then(res => {
                            // 
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Formulir KK' +'.pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // 
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });
    
    
                } else {
                    this.setState({ blocking: false });
                }
            })
    
            e.preventDefault()
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter minimal dipilih sampai tingkat kecamatan',
                showConfirmButton: true,
            })
            this.setState({ blocking: false });
        }


    }


    render() {

        const options = {
            page: 1,
            sizePerPageList: [{
                text: '1', value: 1
            }, {
                text: '10', value: 10
            }], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
        };
        return (
            
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >
            <div className="content">
                <div className="title-content">
                    <ImPlay3 className="icon-brd" />
                    Cetak Formulir KK
                </div>
                <div className="body-content">
                    <Box sx={{ width: '100%' }}>
                        <Accordion expanded={true} onChange={this.handleChangePanel1} className='accordionRoot'>
                            <AccordionSummary
                                className='panelSummary'
                                // expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                            >
                                <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%' }} size="small" >
                                                    Provinsi
                                                    <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Kabupaten
                                                    <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Kecamatan
                                                    <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Kelurahan
                                                    <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    RW
                                                    <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    RT
                                                    <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Grid item xs={12}>
                                                    <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    {/* <h3>Approval Pemutakhiran KK</h3>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                            <Grid item xs={12} md={12}>
                                {this.state.roleUserLogin === 17 ?
                                    <Button disabled={this.state.selectedRows.length === 0} onClick={(e) => this.inputSP2D(e)} sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsPencilSquare className='icon-brd' style={{ marginRight: '5px' }} /> Simpan SP2D</Button>
                                    : <></>}
                                {(this.state.roleUserLogin == 6 || this.state.roleUserLogin == 15 || this.state.roleUserLogin == 16) ?
                                    <div>
                                        <Button disabled={this.state.selectedRows.length === 0}  sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: '#B22222', ':hover': { bgcolor: '#B22222', color: 'white' } }} variant="contained"><BsXCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Reject</Button>
                                        <Button disabled={this.state.selectedRows.length === 0}  sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px' }} /> Approve</Button>
                                    </div>
                                    : <></>
                                }
                            </Grid>
                            </Grid> */}



                    
                </div>
            </div>
            </BlockUi>
        
        )
    }
}

export default CetakFormulirKK;