import React, { Component } from "react";
import { Select as MuiSelect, TextField, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, TableCell, Input,Checkbox } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIUpload from '../../../services/APIUpload';
import APIDashboard from "../../../services/APIDashboard";
import Swal from 'sweetalert2';
import { HiOutlineDocumentText, HiOutlineDocumentDuplicate } from "react-icons/hi";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
//import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';

import { ImPlay3, ImSearch, ImPrinter, ImUsers } from "react-icons/im";
import { BsPencilSquare, BsPrinterFill, BsInfoCircle } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import APIReport from '../../../services/APIReport';
import download from 'downloadjs'
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import APIMonPendataan from "../../../services/APIMonPendataan";
import APIPemutakhiran from "../../../services/APIPemutakhiran";

class MonitoringBelumDibayar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panel1: true,
            sizePerPage: 10,
            sizePerPage2: 10,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],

            // datas: [{'id': 0, 'sdm': 'Pendata', 'pendata':'Pendata A', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':48, 'KKdidata':'48', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': '', 'nokuitansi':'00012'},
            //         {'id': 1,'sdm': 'Pendata','pendata':'Pendata B', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':50, 'KKdidata':'50', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': '', 'nokuitansi':'00013'},
            //         {'id': 2,'sdm': 'Pendata','pendata':'Pendata C', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':51, 'KKdidata':'50', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 1, 'nokuitansi':'00014'},
            //         {'id': 3,'sdm': 'Pendata','pendata':'Pendata D', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 3, 'nokuitansi':'00015'},
            //         {'id': 4,'sdm': 'Manager Data','pendata':'Manager A', 'kecamatan':'ABC', 'kelurahan': '', 'jumlahRT': '100', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 2, 'nokuitansi':'00016'},
            //         {'id': 5,'sdm': 'Supervisor','pendata':'Supervisor A', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '20', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 1, 'nokuitansi':'00017'},
            //         {'id': 6,'sdm': 'Pendata','pendata':'Pendata E', 'kecamatan':'ABC', 'kelurahan': '', 'jumlahRT': '100', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 4, 'nokuitansi':'00016', 'nosp2d':'5555123'},],

            //Wilayah
            dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
            dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

            openRejectDialog: false,
            datasView: [],
            roleName: '',
            namaProvinsi: '',
            namaKelurahan: '',
            namaKecamatan: '',
            namaKabupaten: '',
            created_by_md: '',
            created_by_mp: '',
            created_by_spv: '',
            created_date_md: '',
            created_date_mp: '',
            created_date_spv: '',
            openPreviewRekapRT: false,
            openPreviewPenjabat: false,

            roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
            dt_sdm: [{ value: 1, label: 'Pendata', name: 'Pendata', cetak: 'PENDATA' },
            { value: 2, label: 'Kader Formulir', name: 'Kader Formulir', cetak: 'KADER' },
            { value: 3, label: 'Operator', name: 'Operator', cetak: 'OPR' },
            { value: 4, label: 'Supervisor', name: 'Supervisor', cetak: 'SPV' },
            { value: 5, label: 'Manager Data', name: 'Manager Data', cetak: 'MD' },
            { value: 6, label: 'Manager Pengelola', name: 'Manager Pengelola', cetak: 'MP' }],
            filterSDM: [], selectedSDM: null, selectedSDMCetak: null,
            disCetakKuitansi: false,
            openDetailRT: false,
            openCloseInputSP2D: false,
            dt_metode: [{ value: 'Tunai', label: 'Tunai' }, { value: 'Non-Tunai', label: 'Non Tunai' }, { value: '', label: 'DRAFT' }],
            dt_tunggakan: [{ value: true, label: 'Ya' }],
            selectedMetode: null, metodeTampil: [],
            selectedTunggakan: false, tunggakanTampil: [],

            data_RT: [{ kode: ' 001', nama_rt: '001' }, { kode: '002', nama_rt: '002' }, { kode: '003', nama_rt: '003' }],

            selectedRows: [], rowDipilih: [],
            dt_status: [{ value: "1", label: 'Pending PPK' }, { value: "2", label: 'Pending Bendahara' },
            { value: "3", label: 'Completed' }],
            dt_pembayaran: [{ value: ' TUNAI', label: 'TUNAI' }, { value: 'NON TUNAI', label: 'NON TONAI' }],
            filterStatus: [], selectedStatus: null, filterPembayaran: [], selectedPembayaran: null,

            openInfo: false,
            textCari: '',

            usernameBiodata: '',
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    componentDidMount() {
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
        let title2, text11, text21

        // console.log(citrus)

        // if(this.state.roleUserLogin === 15){ //pelaksana kegiatan
        //     const data_pelaksana = this.state.datas.filter(data => data.flag === 1)
        //     this.setState({datas: data_pelaksana})
        // } else if(this.state.roleUserLogin === 16){//PPK
        //     const data_ppk = this.state.datas.filter(data => data.flag === 2)
        //     this.setState({datas: data_ppk})
        // } 

        if (idTingkatWilayah == 0) {
            title2 = ''

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": 1,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0
                }
            }
            );

        } else if (idTingkatWilayah == 1) {
            title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 1,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": 0,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }


            )


        } else if (idTingkatWilayah == 2) {
            title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 2,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                },
                () => { this.getBackend(this.state.sendDatas) }

            )


        } else {
            title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan


            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 3,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }
            )
        }

        this.setState({ title: title2 },
        )

        if (rest === false) { this.props.history.push('/401') }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }
                const wilProvinsi = rest[0].attributes.user.wilProvinsi
                const wilKabupaten = rest[0].attributes.user.wilKabupaten
                const wilKecamatan = rest[0].attributes.user.wilKecamatan
                const wilKelurahan = rest[0].attributes.user.wilKelurahan

                if (Object.keys(wilProvinsi).length > 0) {

                    const objProp = {
                        'value': wilProvinsi.id_provinsi,
                        'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
                    };

                    this.setState({ dt_prov: objProp },
                        () => {
                            if (this.state.auto_prov === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(objProp)
                            }
                        }
                    );
                    //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                //        alert(wilKabupaten.length)
                if (Object.keys(wilKabupaten).length > 0) {
                    //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    const objKab = {
                        'value': wilKabupaten.id_kabupaten,
                        'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
                    };

                    this.setState({ dt_kab: objKab },
                        () => {
                            if (this.state.auto_kab === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kab(objKab)
                            }
                        }
                    );

                }
                if (Object.keys(wilKecamatan).length > 0) {
                    const objKec = {
                        'value': wilKecamatan.id_kecamatan,
                        'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
                    };

                    this.setState({ dt_kec: objKec },
                        () => {
                            if (this.state.auto_kec === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kec(objKec)
                            }
                        }
                    );
                    //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(wilKelurahan).length > 0) {
                    const objKel = {
                        'value': wilKelurahan.id_kelurahan,
                        'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
                    };

                    this.setState({ dt_kel: objKel },
                        () => {
                            if (this.state.auto_des === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_des(objKel)
                            }
                        }
                    );


                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    //API (Wilayah)
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            //handleSearch
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": e.value,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
            );

            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )


        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
                , id_kecamatan: 0, id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "idKec": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            });


            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
                , id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": e.value,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
                , id_rw: 0

            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": e.value,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_sdm = (e) => {
        if (e) {
            this.setState({
                filterSDM: e, selectedSDM: e.name, selectedSDMCetak: e.cetak
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterSDM: null, selectedSDM: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_status = (e) => {
        if (e) {
            this.setState({
                filterStatus: e, selectedStatus: e.value,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterStatus: null, selectedStatus: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_pembayaran = (e) => {
        if (e) {
            this.setState({
                filterPembayaran: e, selectedPembayaran: e.value,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterPembayaran: null, selectedPembayaran: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_metode = (e) => {
        if (e) {
            this.setState({
                selectedMetode: e.value, metodeTampil: e,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                selectedMetode: null, metodeTampil: null,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_Tunggakan = (e) => {
        if (e) {
            this.setState({
                selectedTunggakan: e.value, tunggakanTampil: e,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                selectedTunggakan: false, tunggakanTampil: null,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handlePageChange = (page, rowsPerPage) => {
        this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    }

    handleSearch = () => {
        console.log(this.state.selectedMetode, 'metode')
        console.log(this.state.selectedSDM, 'sdm')
        var newData = [];
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, selectedSDM, selectedStatus, selectedMetode, selectedTunggakan } = this.state
        if (id_provinsi !== null && selectedSDM !== null && selectedMetode !== null && id_kabupaten !== 0 && id_kabupaten !== undefined) {
            this.setState({ blocking: true, selectedRows: [] })

            const param = {
                "kabupatenId": +id_kabupaten,
                "provinsiId": +id_provinsi,
                "kecamatanId": +id_kecamatan,
                "kelurahanId": +id_des,
                "rwId": +id_rw,
                "status_approval": selectedStatus,
                "sdm": selectedSDM,
                "metode_pembayaran": selectedMetode,
                "dataTunggakan": selectedTunggakan,

            }
            APIMonPendataan.post("/portalpk/metodepembayaran/getMonitoringApprovalPembayaran", param)
                .then((res) => {

                    if (res.data.data.length === 0) {
                        Swal.fire({
                            title: 'Info!',
                            icon: 'warning',
                            text: 'Data Tidak Ditemukan',
                        })
                        this.setState({ blocking: false, datas: [], selectedRows: [] });
                    } else {

                        if (res.status === 200) {
                            res.data.data.forEach(data => {
                                const obj1 = Object.assign({ no: res.data.data.indexOf(data) + 1 }, data)
                                data = obj1
                                newData.push(data)
                            },)
                            this.setState(
                                {
                                    datas: newData, blocking: false, selectedRows: []
                                }

                            )

                        } else {
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                text: 'Pencarian Data Gagal, Harap Coba lagi!',
                            })
                            this.setState({ blocking: false });
                        }
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: 'Pencarian Data Gagal, Harap Coba lagi!',
                    })
                    this.setState({ blocking: false });
                });
        } else {
            Swal.fire({
                title: 'Info',
                icon: 'info',
                text: 'Filter wilayah minimal dipilih tingkat kabupaten, serta filter SDM & Metode harus dipilih.',
                showConfirmButton: true,
            });
        }

    }

    BANKFormatter = (cell, row) => {
        if (row.rekening_bank === " / ") {
            return ''
        } else {
            return <div>{row.rekening_bank}</div>
        }
    }
    buttonFormatter = (cell, row) => {
        if(row.status === 0 || row.status === "0"){
            return <div className='divBtnTable'>-</div>
        }else{

        

        if (row.sdm === 'PENDATA' || row.sdm === 'KADER') {
            return <div className='divBtnTable'>
                <Tooltip title="Cetak Kuitansi"><span><Button id={row.id} onClick={(e) => this.handleCetakKuitansi(e, row, 'kuitansi')} className='btnTable' variant="outlined"><ImPrinter style={{ color: 'red' }} /></Button></span></Tooltip>
                <Tooltip title="Cetak Lampiran"><span><Button id={row.id} onClick={(e) => this.handleCetakKuitansi(e, row, 'lampiran')} className='btnTable' variant="outlined"><HiOutlineDocumentText style={{ color: 'green' }} /></Button></span></Tooltip>
                <Tooltip title="Cetak Kuitansi & Lampiran"><span><Button id={row.id} onClick={(e) => this.handleCetakKuitansi(e, row, 'lengkap')} className='btnTable' variant="outlined"><HiOutlineDocumentDuplicate style={{ color: 'blue' }} /></Button></span></Tooltip>
            </div>

        } else if (row.sdm === 'MD' || row.sdm === 'MANAGER DATA' || row.sdm === 'MANAJER DATA' || row.sdm === 'MP' || row.sdm === 'MANAGER PENGELOLA' || row.sdm === 'MANAJER DATA') {
            return <div className='divBtnTable'>
                <Tooltip title="Cetak Kuitansi"><span><Button id={row.id} onClick={(e) => this.handleCetakKuitansi(e, row, 'kuitansi')} className='btnTable' variant="outlined"><ImPrinter style={{ color: 'red' }} /></Button></span></Tooltip>
            </div>

        } else if (row.sdm === 'OPERATOR' || row.sdm === 'OPR') {
            return <div className='divBtnTable'>
                <Tooltip title="Cetak Kuitansi"><span><Button id={row.id} onClick={(e) => this.handleCetakKuitansi(e, row, 'kuitansi')} className='btnTable' variant="outlined"><ImPrinter style={{ color: 'red' }} /></Button></span></Tooltip>
                <Tooltip title="Cetak Lampiran"><span><Button id={row.id} onClick={(e) => this.handleCetakKuitansi(e, row, 'lampiran')} className='btnTable' variant="outlined"><HiOutlineDocumentText style={{ color: 'green' }} /></Button></span></Tooltip>
                <Tooltip title="Cetak Kuitansi & Lampiran"><span><Button id={row.id} onClick={(e) => this.handleCetakKuitansi(e, row, 'lengkap')} className='btnTable' variant="outlined"><HiOutlineDocumentDuplicate style={{ color: 'blue' }} /></Button></span></Tooltip>
            </div>

        } else if (row.sdm === 'SUPERVISOR' || row.sdm === 'SPV' || row.sdm === 'SPV PAPI' || row.sdm === 'SPV CAPI') {
            return <div className='divBtnTable'>
                <Tooltip title="Cetak Kuitansi"><span><Button id={row.id} onClick={(e) => this.handleCetakKuitansi(e, row, 'kuitansi')} className='btnTable' variant="outlined"><ImPrinter style={{ color: 'red' }} /></Button></span></Tooltip>
            </div>

        } else {
            return <div className='divBtnTable'>-</div>
        }
    }

    }

    previewFormatter = (cell, row) => {


        return <div className='divBtnTable'>
            <Tooltip title="View SK"><Button id={row.id} onClick={(e) => this.previewSK(e, row)} className='btnTable' variant="outlined"><AiOutlineFilePdf style={{ color: 'red' }} /></Button></Tooltip>
            {row.sdm === 'OPERATOR' || row.sdm === 'KADER' ? <></> : <Tooltip title="View Rekap RT"><Button id={row.id} onClick={(e) => this.viewRekapRT(e, row)} className='btnTable' variant="outlined"><HiOutlineDocumentText style={{ color: 'green' }} /></Button></Tooltip>}
            <Tooltip title="View Biodata"><Button id={row.id} onClick={(e) => this.viewJabatan(e, row)} className='btnTable' variant="outlined"><ImUsers style={{ color: 'blue' }} /></Button></Tooltip>
            {/* <Tooltip title="View Profil"><Button id={row.id} className='btnTable' variant="outlined"><CgProfile style={{ color: 'blue' }} /></Button></Tooltip> */}

        </div>

    }
    viewJabatan = (e, row) => {
        console.log(row)
        // this.setState({ blocking: true });
        this.setState({ blocking: true }, () => { this.openClosePreviewPenjabat() })
        const reqParam = {
            "userName": row.user_name
        }
        this.setState({ usernameBiodata: row.user_name })


        APIPemutakhiran.get('portalpk/metodepembayaran/getDetailProfilePembayaran?userName=' + row.user_name)
            .then((res) => {

                // console.log('RESSSS', res)
                this.setState({ blocking: false });
                if (res.status === 200) {

                    this.setState({
                        datasViewJabatan: res.data.data,
                        roleName: res.data.data[0].roleName,

                    })
                }


                console.log(this.state.datas, " DATA >>>>>>>>>")
            })
            .catch((error) => {
                let message = error;
                Swal.fire({
                    title: 'Error!',
                    icon: 'warning',
                    // text: 'Jaringan bermasalah.',
                    text: message,
                })
                this.setState({ blocking: false });
                console.log(error, "DATA ERROR");
            })
    }

    handleCetakBiodata = (e, row) => {
        console.log('tess')
        console.log(row, 'row')
        this.setState({ blocking: true });

        Swal.fire({
            title: 'Cetak Biodata',
            text: "Anda akan mencetak biodata",
            icon: 'info',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        }).then((result) => {

            let url = 'viewReport?filterPencarian=%7B' + '%22userName%22%3A%22' + this.state.usernameBiodata + '%22%2C%22reportName%22%3A%22' + 'ProfileSdmPembayaran' + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + 'PROFILE' + '%22%7D'

            if (result.isConfirmed) {
                console.log('confirmed')
                APIReport.get(url, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Biodata ' + this.state.usernameBiodata + '.pdf', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        // console.log("error ===> ", error)
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: 'Biodata gagal dicetak, silakan coba lagi',
                        })
                        this.setState({ blocking: false });
                    });

            } else {
                this.setState({ blocking: false });
            }
        })


    }

    viewRekapRT = (e, row) => {
        console.log(row)
        this.setState({ blocking: true });
        this.setState({ blocking: true }, () => { this.openClosePreviewRekapRT() })
        const reqParam = {
            "sdm": row.sdm,
            "userName": row.user_name
        }

        if (row.sdm === 'SPV') {
            this.setState({ rowSDM: 'SPV' })

            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {

                    // console.log('RESSSS', res)

                    if (res.status === 200) {
                        if (res.data.data.length === 0) {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'Info!',
                                text: 'Data Tidak ada',
                            })
                            this.openClosePreviewRekapRT()

                        }
                        this.setState({
                            datasView: res.data.data,
                            namaProvinsi: res.data.data[0].namaProvinsi,
                            namaKabupaten: res.data.data[0].namaKabupaten,
                            namaKelurahan: res.data.data[0].namaKelurahan,
                            namaKecamatan: res.data.data[0].namaKecamatan,
                            created_by_md: res.data.data[0].created_by_md,
                            created_by_mp: res.data.data[0].created_by_mp,
                            created_by_spv: res.data.data[0].created_by_spv,
                            created_date_md: new Date(res.data.data[0].created_date_md).toISOString().substring(0, 10),
                            created_date_mp: new Date(res.data.data[0].created_date_mp).toISOString().substring(0, 10),
                            created_date_spv: new Date(res.data.data[0].created_date_spv).toISOString().substring(0, 10),
                            blocking: false
                        })
                    }


                    console.log(this.state.datas, " DATA >>>>>>>>>")
                })
                .catch((error) => {
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        // text: 'Jaringan bermasalah.',
                        text: message,
                    })
                    this.setState({ blocking: false });
                    console.log(error, "DATA ERROR");
                })


        }
        else if (row.sdm === 'MD' || row.sdm === 'MP') {
            this.setState({ rowSDM: row.sdm === 'MD' ? 'MD' : 'MP' })
            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {
                    console.log(res)
                    let data = this.state.datasView
                    if (res.status === 200) {
                        if (res.data.data.length === 0) {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'Info!',
                                text: 'Data Tidak ada',
                            })
                            this.openClosePreviewRekapRT()

                        }
                        this.setState({ blocking: false });
                    }
                    if (res.data.data && res.data.data.length > 0) {

                        res.data.data.forEach(el => {
                            const checkKelurahan = data.find(item => item.namaKelurahan === el.namaKelurahan)
                            console.log(checkKelurahan)
                            if (checkKelurahan) {
                                console.log('a')


                                let yang_ada = checkKelurahan.yang_ada + el.yang_ada
                                let didata = checkKelurahan.didata + el.didata
                                let ditemui = checkKelurahan.ditemui + el.ditemui
                                let baru = checkKelurahan.baru + el.baru
                                let pindah = checkKelurahan.pindah + el.pindah
                                let meninggal = checkKelurahan.meninggal + el.meninggal
                                let tidak_ditemukan = checkKelurahan.tidak_ditemukan + el.tidak_ditemukan
                                let bercerai = checkKelurahan.bercerai + el.bercerai

                                const dt = {
                                    "namaProvinsi": el.namaProvinsi,
                                    "namaKabupaten": el.namaKabupaten,
                                    "namaKecamatan": el.namaKecamatan,
                                    "kodeKelurahan": el.kodeKelurahan,
                                    "namaKelurahan": el.namaKelurahan,
                                    "kodeRT": el.kodeRT,
                                    "nama_rt": el.nama_rt,
                                    "kodeRW": el.kodeRW,
                                    "nama_rw": el.nama_rw,
                                    "nama": el.nama,
                                    "yang_ada": yang_ada,
                                    "didata": didata,
                                    "ditemui": ditemui,
                                    "baru": baru,
                                    "pindah": pindah,
                                    "meninggal": meninggal,
                                    "tidak_ditemukan": tidak_ditemukan,
                                    "bercerai": bercerai,
                                    "status": el.status,
                                    "created_by_spv": el.created_by_spv,
                                    "created_date_spv": el.created_date_spv,
                                    "created_by_md": el.created_by_md,
                                    "created_date_md": el.created_date_md,
                                    "created_by_mp": el.created_by_mp,
                                    "created_date_mp": el.created_date_mp
                                }
                                data = data.map(el => (el.namaKelurahan === checkKelurahan.namaKelurahan ? Object.assign({}, el, dt) : el))

                                this.setState({
                                    datasView: data,
                                    namaProvinsi: data[0].namaProvinsi,
                                    namaKabupaten: data[0].namaKabupaten,
                                    namaKelurahan: data[0].namaKelurahan,
                                    namaKecamatan: data[0].namaKecamatan,
                                    created_by_md: data[0].created_by_md,
                                    created_by_mp: data[0].created_by_mp,
                                    created_by_spv: data[0].created_by_spv,
                                    created_date_md: new Date(data[0].created_date_md).toISOString().substring(0, 10),
                                    created_date_mp: new Date(data[0].created_date_mp).toISOString().substring(0, 10),
                                    created_date_spv: new Date(data[0].created_date_spv).toISOString().substring(0, 10),
                                    blocking: false
                                })

                            } else {
                                console.log('b')
                                data = data.concat(el)
                                this.setState({
                                    datasView: data,
                                    namaProvinsi: data[0].namaProvinsi,
                                    namaKabupaten: data[0].namaKabupaten,
                                    namaKelurahan: data[0].namaKelurahan,
                                    namaKecamatan: data[0].namaKecamatan,
                                    created_by_md: data[0].created_by_md,
                                    created_by_mp: data[0].created_by_mp,
                                    created_by_spv: data[0].created_by_spv,
                                    created_date_md: new Date(data[0].created_date_md).toISOString().substring(0, 10),
                                    created_date_mp: new Date(data[0].created_date_mp).toISOString().substring(0, 10),
                                    created_date_spv: new Date(data[0].created_date_spv).toISOString().substring(0, 10),
                                    blocking: false
                                })
                            }
                        })

                    }
                })
                .catch((error) => {
                    this.setState({ blocking: false });
                    console.log(error, "DATA ERROR");
                });
        } else if (row.sdm === 'PENDATA') {
            this.setState({ rowSDM: 'PENDATA' })

            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {

                    // console.log('RESSSS', res)
                    if (res.status === 200) {
                        if (res.data.data.length === 0) {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'Info!',
                                text: 'Data Tidak ada',
                            })
                            this.openClosePreviewRekapRT()

                        }
                        this.setState({
                            datasView: res.data.data,
                            namaProvinsi: res.data.data[0].namaProvinsi,
                            namaKabupaten: res.data.data[0].namaKabupaten,
                            namaKelurahan: res.data.data[0].namaKelurahan,
                            namaKecamatan: res.data.data[0].namaKecamatan,
                            created_by_md: res.data.data[0].created_by_md,
                            created_by_mp: res.data.data[0].created_by_mp,
                            created_by_spv: res.data.data[0].created_by_spv,
                            created_date_md: new Date(res.data.data[0].created_date_md).toISOString().substring(0, 10),
                            created_date_mp: new Date(res.data.data[0].created_date_mp).toISOString().substring(0, 10),
                            created_date_spv: new Date(res.data.data[0].created_date_spv).toISOString().substring(0, 10),
                            blocking: false

                        })
                    }


                    console.log(this.state.datas, " DATA >>>>>>>>>")
                })
                .catch((error) => {
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        // text: 'Jaringan bermasalah.',
                        text: message,
                    })
                    this.setState({ blocking: false });
                    console.log(error, "DATA ERROR");
                })


        }
    }

    previewSK = (e, row) => {
        //let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKecamatan.id_kecamatan
        this.setState({ blocking: true })
        const link = document.createElement("a");
        link.target = "_blank";

        let Data = new FormData();
        Data.append('idWilayah', row.id_wilayah_file)
        Data.append('idFileTypeUpload', '2')
        Data.append('tingkatwilayah', row.tingkat_wilayah_file)
        //console.log(row.jumlah_diterima)

        if (row.id_wilayah_file === 0 || row.id_wilayah_file === null) {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'File SK tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
            })
        } else {

            APIUpload.post('userpembiayaan/downloadFileUser', Data, {
                responseType: 'blob',
            })
                .then((res) => {
                    console.log(res, "DATADOWNLOAD")
                    if (res.status === 200) {
                        this.setState({ blocking: false })
                    }


                    link.href = URL.createObjectURL(
                        new Blob([res.data], { type: "application/pdf" })
                    );

                    link.click();

                })
                .catch((error) => {
                    this.setState({ blocking: false });
                    console.log(error, "dataaa ERROR");
                });
        }



    }

    numberingFormat2 = (cell, row) => {

        const index = +this.state.data_RT.indexOf(row)
        const noUrut = index + 1
        return <div>{noUrut} </div>
    }

    createdFormat = (cell, row) => {
        if (row.status === 'Completed') {
            return 'c7ebcd'
        } else if (row.status === '0' || row.status === 0 | row.status === "Rejected") {
            return 'fc3021'
        } else {
            return ''
        }
    }

    createdFormatTunggakan = (cell, row) => {
        if (row.dataTunggakan === true) {
            return 'fffacd'
        }else{
            return ''
        }
    }

    StatusFormatter = (cell, row) => {
        if (row.status === 'Completed') {
            return <div >Completed</div>
        } else if (row.status === '0' || row.status === 0 || row.status === "Rejected") {
            return <div >Reject</div>
        } else {
            return ''
        }
    }

    statusFormatter = (cell, row) => {
        if (+row.flag === 1) {
            return 'Pelaksana Kegiatan'
        } else if (+row.flag === 2) {
            return 'PPK'
        } else if (+row.flag === 3) {
            return 'Bendahara'
        } else if (+row.flag === 4) {
            return 'Completed'
        }
        else {
            return ''
        }
    }

    rupiahFormatter = (cell, row) => {
        var bilangan = cell;
        //console.log(cell)

        var number_string = bilangan.toString(),
            sisa = number_string.length % 3,
            rupiah = number_string.substr(0, sisa),
            ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
            var separator = ''
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');

            return rupiah
        } else {
            return '0'
        }

    }

    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    handleChangeSizePerPage2 = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage2: x })
    }

    cellClick = (cell, row) => {

        return <div style={{ cursor: "pointer" }} onClick={(e) => this.viewDetail(e, row)}>
            {cell}
        </div>
    }

    viewDetail = (e, row) => {
        e.stopPropagation();
        console.log('rowwwwww', row)
        console.log('prov', this.state.provinsi)
        console.log('kab', this.state.kabupaten)
        this.setState({}, () => { this.openCloseDetailRT() })


    }
    handleOpenCloseInfo = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openInfo: !this.state.openInfo })
    }

    openCloseDetailRT = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openDetailRT: !this.state.openDetailRT },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )

    }

    //inputSP2D

    inputSP2D = (e) => {

        //this.setState({selectedRows: rowDipilih})
        console.log('selectedRows', this.state.selectedRows)
        this.setState({}, () => { this.openCloseInputSP2D() })
        //this.setState({}, ()=> this.onSelectRow())
    }

    openCloseInputSP2D = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openInputSP2D: !this.state.openInputSP2D },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )

    }



    //select row

    handleSelectRow = (cat, row, isSelect) => {
        console.log(cat, "CAAATTT", isSelect, "SELECT")
        let data = this.state.datas
        let dataAll = []
        console.log(data)
        if (isSelect === true && cat === 'single') {
            this.setState({ selectedRows: this.state.selectedRows.concat(row) });
        } else if (isSelect === true && cat === 'all') {
            if (this.state.textCari !== '') {
                dataAll = this.state.datas.filter(el => el.nik === row.nik)
                console.log(dataAll)
                this.setState({ selectedRows: this.state.selectedRows.concat(row)});
            } else {
                this.setState({ selectedRows: data });
            }
        } else if (cat === 'single') {
            var array = JSON.parse(JSON.stringify(this.state.selectedRows));
            var index = array.findIndex(el => el.nik === row.nik);
            if (index !== -1) {
                array.splice(index, 1);
                if (this.state.selectedRows?.length > 1) {
                    this.setState({ selectedRows: array });
                } else {
                    this.setState({ selectedRows: array });
                }
            }
        } else {
            this.setState({ selectedRows: [] });
        }


    }

    onSearchChange = (searchText, colInfos, multiColumnSearch) => {
        this.setState({ textCari: searchText })

    }




    handleCetakKuitansi = (e, row, cat) => {
        // if (this.handleValidation()) {
        this.setState({ blocking: true });
        const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;

        if (cat === 'kuitansi') {

            Swal.fire({
                title: 'Cetak Kuitansi',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {

                let reportName = ''
                let reportKategori = ''

                if (row.sdm === 'SPV PAPI' || row.sdm === 'SPV CAPI' || row.sdm === 'SUPERVISOR' || row.sdm === 'SPV') {
                    if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                        reportName = 'KuitansiSpvCash'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiSpvTT'
                    } else {
                        reportName = 'KuitansiSpvDraft'
                    }
                    reportKategori = 'SPV'
                } else if (row.sdm === 'PENDATA') {
                    if (row.metode && row.metode.length !== 0 !== null && row.metode === 'Tunai') {
                        reportName = 'KuitansiPendataCashOnly'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiPendataTTOnly'
                    } else {
                        reportName = 'KuitansiPendataDraftOnly'
                    }
                    reportKategori = 'PENDATA'
                } else if (row.sdm === 'MANAJER DATA' || row.sdm === 'MANAGER DATA' || row.sdm === 'MD') {
                    if (row.metode && row.metode.length !== 0 !== null && row.metode === 'Tunai') {
                        reportName = 'KuitansiMdCash'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiMdTT'
                    } else {
                        reportName = 'KuitansiMdDraft'
                    }
                    reportKategori = 'MD'
                } else if (row.sdm === 'OPERATOR' || row.sdm === 'OPR') {
                    if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                        reportName = 'KuitansiOprCashOnly'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiOprTTOnly'
                    } else {
                        reportName = 'KuitansiOprDraftOnly'
                    }
                    reportKategori = 'OPR'
                } else if (row.sdm === 'KADER') {
                    if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                        reportName = 'KuitansiKdrCashOnly'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiKdrTTOnly'
                    } else {
                        reportName = 'KuitansiKdrDraftOnly'
                    }
                    reportKategori = 'KDR'
                } else if (row.sdm === 'MP') {
                    if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                        reportName = 'KuitansiMpCash'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiMpTT'
                    } else {
                        reportName = 'KuitansiMpDraft'
                    }
                    reportKategori = 'MP'
                }

                let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + row.user_id + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'

                if (result.isConfirmed) {
                    APIReport.get(url, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Kuitansi' + '.pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: 'Kuitansi gagal dicetak, silakan coba lagi',
                            })
                            this.setState({ blocking: false });
                        });

                } else {
                    this.setState({ blocking: false });
                }
            })
        } else if (cat === 'lampiran') {
            // console.log('lampiran')
            Swal.fire({
                title: 'Cetak Lampiran Kuitansi',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {

                let reportName = ''
                let reportKategori = ''

                // if (row.sdm === 'SPV PAPI' || row.sdm === 'SPV CAPI' || row.sdm === 'SUPERVISOR' || row.sdm === 'SPV') {
                //     reportName = 'LampiranKuitansiSpv'
                //     reportKategori = 'SPV'
                // } 
                if (row.sdm === 'PENDATA') {
                    reportName = 'LampiranKuitansiPendata'
                    reportKategori = 'PENDATA'
                }
                // else if (row.sdm === 'MANAJER DATA' || row.sdm === 'MANAGER DATA' || row.sdm === 'MD') {
                //     reportName = 'LampiranKuitansiMd'
                //     reportKategori = 'MD'
                // }
                else if (row.sdm === 'OPERATOR' || row.sdm === 'OPR') {
                    reportName = 'LampiranKuitansiOpr'
                    reportKategori = 'OPR'
                } else if (row.sdm === 'KADER') {
                    reportName = 'LampiranKuitansiKader'
                    reportKategori = 'KDR'
                }


                let url = 'viewReport?filterPencarian=%7B' //+ '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' 
                if (id_provinsi !== null && id_provinsi !== 0) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
                if (id_kabupaten !== null && id_kabupaten !== 0) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
                if (id_kecamatan !== null && id_kecamatan !== 0) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
                if (id_des !== null && id_des !== 0) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }

                let urlLampuiran = url + '%22userId%22%3A%22' + row.user_id + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'

                if (result.isConfirmed) {
                    APIReport.get(urlLampuiran, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Lampiran Kuitansi' + '.pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: 'Lampiran gagal dicetak, silakan coba lagi',
                            })
                            this.setState({ blocking: false });
                        });

                } else {
                    this.setState({ blocking: false });
                }
            })

        } else if (cat === 'lengkap') {
            // console.log('lengkap')
            Swal.fire({
                title: 'Cetak Kuitansi & Lampiran',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {

                let reportName = ''
                let reportKategori = ''

                // if (row.sdm === 'SPV PAPI' || row.sdm === 'SPV CAPI' || row.sdm === 'SUPERVISOR' || row.sdm === 'SPV') {
                //     if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                //         reportName = 'KuitansiSpvCash'
                //     } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non Tunai') {
                //         reportName = 'KuitansiSpvTT'
                //     } else {
                //         reportName = 'KuitansiSpvDraft'
                //     }
                //     reportKategori = 'SPV'
                // } 
                if (row.sdm === 'PENDATA') {
                    if (row.metode && row.metode.length !== 0 !== null && row.metode === 'Tunai') {
                        reportName = 'KuitansiPendataCash'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiPendataTT'
                    } else {
                        reportName = 'KuitansiPendataDraft'
                    }
                    reportKategori = 'PENDATA'
                }
                // else if (row.sdm === 'MANAJER DATA' || row.sdm === 'MANAGER DATA' || row.sdm === 'MD') {
                //     if (row.metode && row.metode.length !== 0 !== null && row.metode === 'Tunai') {
                //         reportName = 'KuitansiMdCash'
                //     } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non Tunai') {
                //         reportName = 'KuitansiMdTT'
                //     } else {
                //         reportName = 'KuitansiMdDraft'
                //     }
                //     reportKategori = 'MD'
                // } 
                else if (row.sdm === 'OPERATOR' || row.sdm === 'OPR') {
                    if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                        reportName = 'KuitansiOprCash'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiOprTT'
                    } else {
                        reportName = 'KuitansiOprDraft'
                    }
                    reportKategori = 'OPR'
                } else if (row.sdm === 'KADER') {
                    if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                        reportName = 'KuitansiKdrCash'
                    } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                        reportName = 'KuitansiKdrTT'
                    } else {
                        reportName = 'KuitansiKdrDraft'
                    }
                    reportKategori = 'KDR'
                }

                let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + row.user_id + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'

                if (result.isConfirmed) {
                    APIReport.get(url, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Kuitansi & Lampiran' + '.pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: 'Kuitansi & lampiran gagal dicetak, silakan coba lagi',
                            })
                            this.setState({ blocking: false });
                        });

                } else {
                    this.setState({ blocking: false });
                }
            })

        }

    }

    handleCetak = (e) => {
        e.preventDefault()
        // if (this.handleValidation()) {
        this.setState({ blocking: true });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, selectedSDMCetak, selectedStatus, selectedMetode, selectedSDM } = this.state;

        // const addFilter = this.state.additionalFilter
        // let newFilter = []
        // addFilter.forEach(row => {
        //     if (row.filtValue && row.valkey) {
        //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
        //     }
        // })

        if (id_provinsi !== null && selectedSDM !== null) {

            Swal.fire({
                title: 'Cetak Formulir',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Excel',
                confirmButtonColor: '#3085d6',
                showDenyButton: true,
                denyButtonText: 'PDF',
                denyButtonColor: '#4ea842',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {

                let url = 'viewReport?filterPencarian=%7B'

                if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
                if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
                if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
                if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
                if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }
                if (selectedSDMCetak !== 0 && selectedSDMCetak !== null) { url += '%22sdm%22%3A%22' + selectedSDMCetak + '%22%2C' }
                if (selectedStatus !== 0 && selectedStatus !== null) { url += '%22status%22%3A%22' + selectedStatus + '%22%2C' }
                if (selectedMetode !== '' && selectedMetode !== null) { url += '%22metode%22%3A%22' + selectedMetode + '%22%2C' }

                let urlCetakPdf = url + '%22reportName%22%3A%22MonitoringApproval%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MONITORING%22%7D'
                let urlCetakExcel = url + '%22reportName%22%3A%22MonitoringApproval%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MONITORING%22%7D'

                if (result.isConfirmed) {
                    APIReport.get(urlCetakExcel, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Laporan Monitoring Approval.' + 'xlsx', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });

                } else if (result.isDenied) {
                    APIReport.get(urlCetakPdf, { responseType: 'blob' })
                        .then(res => {
                            // console.log(res, 'res report')
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Laporan Monitoring Approval.' + 'pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });


                } else {
                    this.setState({ blocking: false });
                }
            })
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter wilayah minimal dipilih tingkat Provinsi, serta filter SDM',
                showConfirmButton: true,
            })
            this.setState({ blocking: false });

        }

    }

    handleCetakMultiple = (e) => {
        const urls = []
        const idCetak = []
        let rowsDipilih = this.state.selectedRows
        let sdmDipilih = []
        let reportName = ''
        let reportKategori = ''

        rowsDipilih.forEach((el) => sdmDipilih.push(el.sdm))
        rowsDipilih.forEach((el) => idCetak.push(el.user_id))

        console.log(rowsDipilih, 'row dipilih')


        const checkSpvMdMp = rowsDipilih.find(item => item.sdm === 'SPV' || item.sdm === 'SPV PAPI' || item.sdm === 'SPV CAPI' || item.sdm === 'MD' || item.sdm === 'MANAGER DATA' || item.sdm === 'MP')
        const checkAllValue = sdmDipilih.every(val => val === sdmDipilih[0])

        console.log(sdmDipilih, 'sdm dipilih')
        console.log(checkAllValue, 'checkAllValue')
        console.log(idCetak, 'idCetak')

        // this.setState({}, () => console.log('URLS', urls))
        //this.setState({}, ()=> this.handleZipKuitansi('Kuitansi.zip', urls))

        // if (rowsDipilih.length > 0) {
        if (checkAllValue) {
            Swal.fire({
                title: 'Cetak Kuitansi',
                text: "Anda akan mencetak " + rowsDipilih.length + " kuitansi",
                icon: 'warning',
                // showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Kuitansi & Lampiran',

                showConfirmButton: true,
                confirmButtonColor: '#3CB371',
                confirmButtonText: 'Kuitansi',

                showDenyButton: rowsDipilih[0].sdm === 'PENDATA' ? true : false,
                denyButtonColor: '#3085d6',
                denyButtonText: 'Lampiran',

                showCloseButton: true,



            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ blocking: true })

                    let listId = ''
                    rowsDipilih.forEach((val, key, arr) => {
                        // listId = listId.concat(el.id)
                        if (Object.is(arr.length - 1, key)) {
                            listId = listId + val.user_id
                        } else {
                            listId = listId + val.user_id + ','
                        }

                    })

                    if (rowsDipilih[0].sdm === 'SPV PAPI' || rowsDipilih[0].sdm === 'SPV CAPI' || rowsDipilih[0].sdm === 'SUPERVISOR' || rowsDipilih[0].sdm === 'SPV') {
                        if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Tunai') {
                            reportName = 'KuitansiSpvCash'
                        } else if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Non-Tunai') {
                            reportName = 'KuitansiSpvTT'
                        } else {
                            reportName = 'KuitansiSpvDraft'
                        }
                        reportKategori = 'SPV'
                    } else if (rowsDipilih[0].sdm === 'PENDATA') {
                        if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Tunai') {
                            reportName = 'KuitansiPendataCashOnly'
                        } else if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Non-Tunai') {
                            reportName = 'KuitansiPendataTTOnly'
                        } else {
                            reportName = 'KuitansiPendataDraftOnly'
                        }
                        reportKategori = 'PENDATA'
                    } else if (rowsDipilih[0].sdm === 'MANAGER DATA', rowsDipilih[0].sdm === 'MD') {
                        if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Tunai') {
                            reportName = 'KuitansiMdCash'
                        } else if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Non-Tunai') {
                            reportName = 'KuitansiMdTT'
                        } else {
                            reportName = 'KuitansiMdDraft'
                        }
                        reportKategori = 'MD'
                    } else if (rowsDipilih[0].sdm === 'OPERATOR' || rowsDipilih[0].sdm === 'OPR') {
                        if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Tunai') {
                            reportName = 'KuitansiOprCashOnly'
                        } else if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Non-Tunai') {
                            reportName = 'KuitansiOprTTOnly'
                        } else {
                            reportName = 'KuitansiOprDraftOnly'
                        }
                        reportKategori = 'OPR'
                    } else if (rowsDipilih[0].sdm === 'KADER') {
                        if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Tunai') {
                            reportName = 'KuitansiKdrCashOnly'
                        } else if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Non-Tunai') {
                            reportName = 'KuitansiKdrTTOnly'
                        } else {
                            reportName = 'KuitansiKdrDraftOnly'
                        }
                        reportKategori = 'KDR'
                    } else if (rowsDipilih[0].sdm === 'MP') {
                        if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Tunai') {
                            reportName = 'KuitansiMpCash'
                        } else if (rowsDipilih[0].metode !== null && rowsDipilih[0].metode.length !== 0 && rowsDipilih[0].metode === 'Non-Tunai') {
                            reportName = 'KuitansiMpTT'
                        } else {
                            reportName = 'KuitansiMpDraft'
                        }
                        reportKategori = 'MP'
                    }

                    let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + listId + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'

                    APIReport.get(url, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Kuitansi' + '.pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                text: 'Kuitansi gagal dicetak, silakan coba lagi',
                            })
                            this.setState({ blocking: false });
                        })
                    // )
                    // })

                    // Promise.all(promises).then(() => {

                    // })

                } else if (result.isDenied) {
                    console.log(checkSpvMdMp)
                    this.setState({ blocking: true })
                    if (checkSpvMdMp) {
                        Swal.fire({
                            title: 'Info!',
                            icon: 'info',
                            text: 'Tidak ada lampiran untuk SDM Supervisor, Manager Data, dan Manager Pengelola',
                            showConfirmButton: true
                        })
                        this.setState({ blocking: false });
                    } else {

                        let listId = ''
                        rowsDipilih.forEach((val, key, arr) => {
                            // listId = listId.concat(el.id)
                            if (Object.is(arr.length - 1, key)) {
                                listId = listId + val.user_id
                            } else {
                                listId = listId + val.user_id + ','
                            }

                        })

                        if (rowsDipilih[0].sdm === 'PENDATA') {
                            reportName = 'LampiranKuitansiPendata'
                            reportKategori = 'PENDATA'
                        } else if (rowsDipilih[0].sdm === 'OPERATOR' || rowsDipilih[0].sdm === 'OPR') {
                            reportName = 'LampiranKuitansiOpr'
                            reportKategori = 'OPR'
                        } else if (rowsDipilih[0].sdm === 'KADER') {
                            reportName = 'LampiranKuitansiKader'
                            reportKategori = 'MD'
                        }

                        let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + listId + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'

                        APIReport.get(url, { responseType: 'blob' })
                            .then(res => {
                                if (res.status === 200) {
                                    const content = res.headers['content-type'];
                                    download(res.data, 'Lampiran' + '.pdf', content)
                                    this.setState({ blocking: false })
                                } else {
                                    Swal.fire({
                                        title: 'Info!',
                                        icon: 'info',
                                        text: 'Data tidak ditemukan.',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                    this.setState({ blocking: false });
                                }
                            }).catch((error) => {
                                let message = error;
                                Swal.fire({
                                    title: 'Error!',
                                    icon: 'warning',
                                    text: 'Lampiran gagal dicetak, silakan coba lagi',
                                })
                                this.setState({ blocking: false });
                            })


                    }


                } else if (result.isCanceled) {
                    this.setState({ blocking: true })
                    rowsDipilih.forEach(row => {
                        if (row.sdm === 'SPV PAPI' || row.sdm === 'SPV CAPI' || row.sdm === 'SUPERVISOR' || row.sdm === 'SPV') {
                            if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                                reportName = 'KuitansiSpvCash'
                            } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                                reportName = 'KuitansiSpvTT'
                            } else {
                                reportName = 'KuitansiSpvDraft'
                            }
                            reportKategori = 'SPV'
                        } else if (row.sdm === 'PENDATA') {
                            if (row.metode && row.metode.length !== 0 !== null && row.metode === 'Tunai') {
                                reportName = 'KuitansiPendataCash'
                            } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                                reportName = 'KuitansiPendataTT'
                            } else {
                                reportName = 'KuitansiPendataDraft'
                            }
                            reportKategori = 'PENDATA'
                        } else if (row.sdm === 'MANAJER DATA' || row.sdm === 'MANAGER DATA' || row.sdm === 'MD') {
                            if (row.metode && row.metode.length !== 0 !== null && row.metode === 'Tunai') {
                                reportName = 'KuitansiMdCash'
                            } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                                reportName = 'KuitansiMdTT'
                            } else {
                                reportName = 'KuitansiMdDraft'
                            }
                            reportKategori = 'MD'
                        } else if (row.sdm === 'OPERATOR') {
                            if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                                reportName = 'KuitansiOprCash'
                            } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                                reportName = 'KuitansiOprTT'
                            } else {
                                reportName = 'KuitansiOprDraft'
                            }
                            reportKategori = 'OPR'
                        } else if (row.sdm === 'KADER') {
                            if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Tunai') {
                                reportName = 'KuitansiKdrCash'
                            } else if (row.metode !== null && row.metode.length !== 0 && row.metode === 'Non-Tunai') {
                                reportName = 'KuitansiKdrTT'
                            } else {
                                reportName = 'KuitansiKdrDraft'
                            }
                            reportKategori = 'KDR'
                        }

                        //let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%' + row.user_id + '%22%2C%22reportName%22%3A%22KuitansiSpv%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22Pembayaran%22%7D'
                        // filter = 'filterPencarian=%7B%22userId%22%3A%2254968%22%2C%22reportName%22%3A%22KuitansiKpTT%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22KP%22%7D'
                        let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + row.user_id + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'
                        urls.push(url)

                    })

                    let promises = []
                    let kuitansiTercetak = []
                    urls.forEach((el) => {
                        promises.push(
                            APIReport.get(el, { responseType: 'blob' })
                                .then(res => {
                                    if (res.status === 200) {
                                        const content = res.headers['content-type'];
                                        download(res.data, 'Kuitansi & Lampiran' + '.pdf', content)
                                        kuitansiTercetak.push(el)
                                        this.setState({ blocking: false }, () => console.log(kuitansiTercetak, 'kuitansi tercetak'))
                                    } else {
                                        Swal.fire({
                                            title: 'Info!',
                                            icon: 'info',
                                            text: 'Data tidak ditemukan.',
                                            showConfirmButton: false,
                                            timer: 1500
                                        })
                                        this.setState({ blocking: false });
                                    }
                                }).catch((error) => {
                                    // console.log("error ===> ", error)
                                    let message = error;
                                    Swal.fire({
                                        title: 'Error!',
                                        icon: 'warning',
                                        // text: 'Jaringan bermasalah.',
                                        text: 'Kuitansi & lampiran gagal dicetak, silakan coba lagi',
                                    })
                                    this.setState({ blocking: false });
                                })
                        )
                    })

                    Promise.all(promises).then(() => {

                    })


                } else {
                    this.setState({ blocking: false })
                }
            })
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Cetak multiple kuitansi hanya tersedia jika SDM dan metode yg dipilih sama.',
                showConfirmButton: true,
            })
            this.setState({ blocking: false });
        }



    }

    openClosePreviewRekapRT = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openPreviewRekapRT: !this.state.openPreviewRekapRT },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )
    }

    openClosePreviewPenjabat = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openPreviewPenjabat: !this.state.openPreviewPenjabat },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )
    }

    emailFormatter = (cell, row) => {

        if (row.email === "0") {
            return <div> </div>
        } else {
            return <div> {row.email} </div>
        }

    }

    render() {

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10000px',
            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRow('single', row, isSelect)
            },
            onSelectAll: (isSelect, row, e) => {
                if (isSelect) {
                    this.handleSelectRow('all', row, isSelect)
                    return this.state.datas.map(row => row.nik)
                } else {
                    this.handleSelectRow('all', row, isSelect)
                    return [];
                }
            }

        };


        const options2 = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage2, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };

        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };

        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >
                <div className="content">
                    <div className="title-content">
                        <ImPlay3 className="icon-brd" />
                        Monitoring Belum Dibayar
                    </div>
                    <div className="body-content">
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Provinsi
                                                        <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kabupaten
                                                        <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kecamatan
                                                        <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kelurahan
                                                        <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        SDM
                                                        <Select options={this.state.dt_sdm} onChange={this.sel_sdm} value={this.filterSDM} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Status
                                                        <Select options={this.state.dt_status} onChange={this.sel_status} value={this.filterStatus} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Metode
                                                        <Select options={this.state.dt_metode} onChange={this.sel_metode} value={this.metodeTampil} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Data Tunggakan
                                                        <Select options={this.state.dt_tunggakan} onChange={this.sel_Tunggakan} value={this.tunggakanTampil} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />                                                 
                                                         </FormControl>
                                                </Grid>
                                                {/* <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Metode Pembayaran
                                                        <Select options={this.state.dt_pembayaran} onChange={this.sel_pembayaran} value={this.filterPembayaran} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid> */}
                                                <Grid item xs={12} md={4}>
                                                    <Grid item xs={12}>
                                                        <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                                                        <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <h3>Monitoring Belum Dibayar</h3>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                            <Grid item xs={12} md={12}>
                                <span className='btnTable'><Tooltip title="Keterangan" placement="left" arrow><Button style={{ float: 'right' }} onClick={this.handleOpenCloseInfo} ><BsInfoCircle size={20} /></Button></Tooltip></span>
                                <Button disabled={this.state.selectedRows.length === 0 || this.state.selectedMetode === null || this.state.selectedSDM === null} onClick={(e) => this.handleCetakMultiple(e)} sx={{ width: 'calc(100%/6 - 15px)', float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsPrinterFill className='icon-brd' style={{ marginRight: '5px' }} /> Cetak Kuitansi</Button>

                            </Grid>
                        </Grid>

                        <Paper sx={{ width: "100%", boxShadow: "none" }}>


                            <div className="rekap-table">
                                <BootstrapTable
                                    ref={this.bstable}
                                    data={this.state.datas}
                                    pagination={true}
                                    options={options}
                                    keyField="nik"
                                    search={true}
                                    selectRow={selectRow}
                                >

                                    {/* <TableHeaderColumn width="40" dataField="no" headerAlign="center" dataAlign="center"  >NO </TableHeaderColumn> */}
                                    <TableHeaderColumn width="100" dataField="nama_kecamatan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  >KECAMATAN </TableHeaderColumn>
                                    <TableHeaderColumn width="100" dataField="nama_kelurahan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  >KELURAHAN </TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="sdm" headerAlign="center" dataAlign="center"columnClassName={this.createdFormatTunggakan}   >SDM </TableHeaderColumn>
                                    <TableHeaderColumn width="180" dataField="nik" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  >NIK </TableHeaderColumn>
                                    <TableHeaderColumn width="250" dataField="user_name" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  >USERNAME </TableHeaderColumn>
                                    <TableHeaderColumn width="180" dataField="nama_lengkap" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}   >NAMA LENGKAP </TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="jumlah_rt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  >JUMLAH RT </TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="target_kk" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}   >YANG ADA </TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="jumlah_kk" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >DIDATA</TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="jumlah_dibayar" headerAlign="center" dataAlign="right" columnClassName={this.createdFormatTunggakan}  dataFormat={this.rupiahFormatter} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >JUMLAH YG DIBAYARKAN </TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="jumlah_pajak" headerAlign="center" dataAlign="right" columnClassName={this.createdFormatTunggakan}  dataFormat={this.rupiahFormatter} >PAJAK </TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="jumlah_diterima" headerAlign="center" dataAlign="right" columnClassName={this.createdFormatTunggakan}  dataFormat={this.rupiahFormatter} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >JUMLAH YG DITERIMA </TableHeaderColumn>
                                    <TableHeaderColumn width="140" dataField="rekening_bank" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  dataFormat={this.BANKFormatter}  >BANK / NO REKENING </TableHeaderColumn>
                                    <TableHeaderColumn width="140" dataField="nomor_kwitansi" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  >NO KUITANSI </TableHeaderColumn>
                                    <TableHeaderColumn width="140" dataField="sp2d" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatTunggakan}  >NO SP2D </TableHeaderColumn>
                                    <TableHeaderColumn width="120" dataField="status" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.StatusFormatter}  >STATUS </TableHeaderColumn>
                                    <TableHeaderColumn width="110" dataField="action" headerAlign="center" dataAlign="center" dataFormat={this.previewFormatter} >PREVIEW </TableHeaderColumn>
                                    <TableHeaderColumn width="110" dataField="action" headerAlign="center" dataAlign="center" dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn>



                                </BootstrapTable>
                            </div>
                            <div className="sizePerPage">
                                Size Per Page
                                <select
                                    name="category"
                                    value={this.state.sizePerPage}
                                    onChange={this.handleChangeSizePerPage}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value={this.state.datas?.length}>All</option>
                                </select>
                            </div>
                            <div className="totalData">
                                <b>Total Data: {this.state.datas?.length}</b>
                            </div>
                        </Paper>


                        <Dialog open={this.state.openDetailRT} onClose={this.openCloseDetailRT}
                            fullWidth maxWidth={'md'}>

                            <DialogTitle>Detail RT </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Provinsi : {this.state.provinsi?.label} <br></br>
                                    Kabupaten : {this.state.kabupaten?.label} <br></br>
                                    Kecamatan : {this.state.kecamatan?.label} <br></br>
                                    Kelurahan : {this.state.kelurahan?.label} <br></br>
                                    <br></br>
                                    Jumlah RT : {this.state.data_RT?.length}
                                    <Paper sx={{ width: "100%", boxShadow: "none" }}>

                                        <div className="rekap-table">
                                            <BootstrapTable
                                                data={this.state.data_RT}
                                                options={options}
                                            >
                                                <TableHeaderColumn width="60" dataField="" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.numberingFormat2} >NO </TableHeaderColumn>
                                                <TableHeaderColumn width="120" dataField="kode" isKey={true} headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >KODE </TableHeaderColumn>
                                                <TableHeaderColumn width="120" dataField="nama_rt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NAMA RT </TableHeaderColumn>


                                            </BootstrapTable>
                                        </div>
                                    </Paper>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.openCloseDetailRT}>Tutup</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openPreviewRekapRT} onClose={this.openClosePreviewRekapRT}
                            fullWidth maxWidth={'md'}>
                            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >

                                <DialogTitle>Detail Rekap RT </DialogTitle>
                                <DialogContent>
                                    <Grid container columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Grid item xs={12} md={2}>
                                            Provinsi :
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            {this.state.namaProvinsi}
                                        </Grid>
                                    </Grid>
                                    <Grid container columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Grid item xs={12} md={2}>
                                            Kabupaten :
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            {this.state.namaKabupaten}
                                        </Grid>
                                    </Grid>
                                    <Grid container columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Grid item xs={12} md={2}>
                                            Kecamatan :
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            {this.state.namaKecamatan}
                                        </Grid>
                                    </Grid>
                                    {this.state.rowSDM !== 'MD' &&
                                        <Grid container columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                            <Grid item xs={12} md={2}>
                                                Kelurahan :
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                {this.state.namaKelurahan}
                                            </Grid>
                                        </Grid>
                                    }
                                    <DialogContentText>
                                        <Paper sx={{ width: "100%", boxShadow: "none" }}>

                                            <div className="rekap-table">
                                                <BootstrapTable
                                                    data={this.state.datasView}
                                                    pagination={true}
                                                    options={options2}
                                                    search={true}
                                                    keyField='kodeRT'

                                                >
                                                    {this.state.rowSDM === 'PENDATA' && <TableHeaderColumn width="50" dataField="kodeRW" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >KODE RW </TableHeaderColumn>}
                                                    {this.state.rowSDM === 'PENDATA' && <TableHeaderColumn width="90" dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA RW </TableHeaderColumn>}
                                                    {this.state.rowSDM === 'PENDATA' && <TableHeaderColumn width="50" dataField="kodeRT" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >KODE RT </TableHeaderColumn>}
                                                    {/* <TableHeaderColumn width="90" isKey={true} dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA RT </TableHeaderColumn> */}
                                                    {this.state.rowSDM === 'PENDATA' && <TableHeaderColumn width="90" dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA RT </TableHeaderColumn>}
                                                    {this.state.rowSDM === 'SPV' && <TableHeaderColumn width="50" dataField="kodeRW" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >KODE RW </TableHeaderColumn>}
                                                    {this.state.rowSDM === 'SPV' && <TableHeaderColumn width="90" dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA RW </TableHeaderColumn>}
                                                    {this.state.rowSDM === 'SPV' && <TableHeaderColumn width="50" dataField="kodeRT" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >KODE RT </TableHeaderColumn>}
                                                    {/* <TableHeaderColumn width="90" isKey={true} dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA RT </TableHeaderColumn> */}
                                                    {this.state.rowSDM === 'SPV' && <TableHeaderColumn width="90" dataField="nama_rw" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA RT </TableHeaderColumn>}
                                                    {this.state.rowSDM === 'MD' && <TableHeaderColumn width="90" dataField="namaKelurahan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA KELURAHAN </TableHeaderColumn>}
                                                    {this.state.rowSDM === 'MP' && <TableHeaderColumn width="90" dataField="namaKelurahan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >NAMA KELURAHAN </TableHeaderColumn>}
                                                    <TableHeaderColumn width="60" dataField="yang_ada" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >YANG ADA </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="didata" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >DIDATA </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="ditemukan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >DITEMUI </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="baru" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >BARU </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="pindah" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PINDAH </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="meninggal" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >MENINGGAL </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="tidak_ditemukan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TIDAK DITEMUKAN </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="bercerai" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>BERCERAI </TableHeaderColumn>
                                                    {/* <TableHeaderColumn width="100" dataField="status" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.statusFormatter} >STATUS </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn> */}
                                                </BootstrapTable>

                                                <div className="sizePerPage">
                                                    Size Per Page
                                                    <select
                                                        name="category"
                                                        value={this.state.sizePerPage2}
                                                        onChange={this.handleChangeSizePerPage2}
                                                    >
                                                        <option value="1">1</option>
                                                        <option value="10">10</option>
                                                        <option value={this.state.datasView?.length}>All</option>
                                                    </select>
                                                </div>

                                            </div>

                                        </Paper>

                                        {/* <Grid item xs={3} style={{ display: 'inline-block' }}>
                                            Yang Ada :
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'inline-block' }}>
                                            Didata :
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'inline-block' }}>
                                            Ditemui :
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'inline-block' }}>
                                            Baru :
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'inline-block' }}>
                                            Pindah :
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'inline-block' }}>
                                            Meninggal :
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'inline-block' }}>
                                            Tdk ditemukan :
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'inline-block' }}>
                                            Bercerai :
                                        </Grid> */}
                                        {/* </Grid> */}
                                        <br></br>
                                        <b>Approved By</b>
                                        <br></br>
                                        {(this.state.rowSDM === 'Pendata' || this.state.rowSDM === 'Operator' || this.state.rowSDM === 'Kader Formulir') && <Grid container columnSpacing={{ xs: 12, sm: 2, md: 2 }} style={{ marginTop: '15px', marginBottom: '15px' }}>

                                            <Grid item xs={12} md={3}>
                                                Supervisor :
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                {this.state.created_by_spv}, {this.state.created_date_spv}
                                            </Grid>

                                        </Grid>
                                        }
                                        {this.state.rowSDM !== 'MD' && <Grid container columnSpacing={{ xs: 12, sm: 2, md: 2 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                            <Grid item xs={12} md={3}>
                                                Manager Data :
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                {this.state.created_by_md}, {this.state.created_date_md}
                                            </Grid>
                                        </Grid>
                                        }
                                        <Grid container columnSpacing={{ xs: 12, sm: 2, md: 2 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                            <Grid item xs={12} md={3}>
                                                Manager Pengelola :
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                {this.state.created_by_mp}, {this.state.created_date_mp}
                                            </Grid>
                                        </Grid>
                                        {/* Pelaksana Kegiatan  : {this.state.namaKelurahan}, {this.state.namaKelurahan} <br></br>
                                    PPK                 : {this.state.namaKelurahan}, {this.state.namaKelurahan} <br></br>
                                    Bendahara           : {this.state.namaKelurahan}, {this.state.namaKelurahan} <br></br> */}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.openClosePreviewRekapRT}>Tutup</Button>
                                </DialogActions>
                            </BlockUi>
                        </Dialog>

                        <Dialog open={this.state.openPreviewPenjabat} onClose={this.openClosePreviewPenjabat}
                            fullWidth maxWidth={'md'}>
                            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >

                                {/* <DialogTitle>Detail Rekap RT </DialogTitle> */}
                                <DialogContent>
                                    <Grid container columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Grid item xs={12} md={3}>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <p style={{ textAlign: 'center' }}>BIODATA IDENTITAS SDM LINI LAPANGAN JABATAN</p>
                                            <p style={{ textAlign: 'center' }}>{this.state.roleName}</p>
                                        </Grid>
                                        <Grid item xs={12} md={2}>

                                        </Grid>

                                    </Grid>
                                    <DialogContentText>
                                        <Paper sx={{ width: "100%", boxShadow: "none" }}>

                                            <div className="rekap-table">
                                                <BootstrapTable
                                                    data={this.state.datasViewJabatan}
                                                    // pagination={true}
                                                    options={options}
                                                    // search={true}
                                                    keyField='userID'

                                                >
                                                    <TableHeaderColumn width="160" dataField="userName" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >ID USER </TableHeaderColumn>
                                                    <TableHeaderColumn width="140" dataField="namaLengkap" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NAMA </TableHeaderColumn>
                                                    <TableHeaderColumn width="140" dataField="nik" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NIK </TableHeaderColumn>
                                                    <TableHeaderColumn width="100" dataField="tempatLahir" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TEMPAT LAHIR </TableHeaderColumn>
                                                    <TableHeaderColumn width="100" dataField="tanggalLahir" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TANGGAL LAHIR</TableHeaderColumn>
                                                    <TableHeaderColumn width="100" dataField="jenisKelamin" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >JENIS KELAMIN </TableHeaderColumn>
                                                    <TableHeaderColumn width="140" dataField="alamat" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >ALAMAT </TableHeaderColumn>
                                                    <TableHeaderColumn width="140" dataField="noTelepon" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}>NO TELEPON/HP </TableHeaderColumn>
                                                    <TableHeaderColumn width="160" dataField="email" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.emailFormatter}  >EMAIL </TableHeaderColumn>
                                                    <TableHeaderColumn width="160" dataField="npwp" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NPWP </TableHeaderColumn>
                                                    <TableHeaderColumn width="120" dataField="namaBank" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NAMA BANK </TableHeaderColumn>
                                                    <TableHeaderColumn width="120" dataField="nomorRekening" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NO REKENING </TableHeaderColumn>
                                                    {/* <TableHeaderColumn width="100" dataField="status" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.statusFormatter} >STATUS </TableHeaderColumn>
                                                    <TableHeaderColumn width="60" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn> */}
                                                </BootstrapTable>

                                            </div>

                                        </Paper>


                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCetakBiodata}>Cetak</Button>
                                    <Button onClick={this.openClosePreviewPenjabat}>Tutup</Button>
                                </DialogActions>
                            </BlockUi>
                        </Dialog>

                        <Dialog open={this.state.openInfo} onClose={this.handleOpenCloseInfo} width={'xs'}>
                            <DialogTitle style={{ paddingLeft: '25px' }}><BsInfoCircle size={15} />&nbsp; Info</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 1 }} style={{ marginTop: '10px', marginBottom: '15px', paddingLeft: '15px' }}>
                                        Cetak kuitansi berangkap hanya tersedia jika SDM dan metode yang dipilih sama.


                                    </Grid>

                                </DialogContentText>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleOpenCloseInfo} variant="outlined" color="primary">Tutup</Button>
                            </DialogActions>
                        </Dialog>


                    </div>
                </div>
            </BlockUi>
        );
    }
}

export default MonitoringBelumDibayar;
