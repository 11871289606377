import React, { Component } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImPlay3, ImUserPlus, ImSearch, ImPhone, ImLocation, ImFloppyDisk, ImCross, ImPrinter } from 'react-icons/im';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import download from 'downloadjs'

import APIWilayah from '../../../services/APIWilayah';
import APIUser from "../../../services/APIUser";
import authServices from "../../../services/authServices";
import APIReport from '../../../services/APIReport';


class KRSCetakSDM extends Component {
    constructor(props){
        super(props);
        this.state = {
          htmlFrame:'',
            sendDatas: {},
            panel: true,

            //---table
            sizePerPage: 10,
            tableHead: [],
            datas: [],
            //---end table

            //---wilayah
            flagNumber: -1,
            flagOpen: true,
            blocking: false,
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
            //---end wilayah

            dt_roles: [],
            role: {},
            roleTampil: [],
            updateBy: localStorage.getItem("username"),
            userId: 0,
            userRole: JSON.parse(localStorage.getItem('authority'))[0].attributes.role,
            dt_sdm: [{ value: 26, label: 'Pengolah', name: 'Pengolah' }, { value: 25, label: 'Pengumpul', name: 'Pengumpul' },
            { value: 27, label: 'Verifikator', name: 'Verifikator' }],
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }


    componentDidMount = () => {
        this.getRole();
        this.handleSelectRole();

        
    
        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.user.roleID
        })
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }
    
            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      }

      getRole = (e) => {
        authServices
          .get("/roles/list")
          .then((res) => {
            this.setState({ blocking: false });
            if (res.status === 200) {
                const dtRole = [res.data[3], res.data[4], res.data[5], res.data[9], res.data[17]]
              this.setState({
                dt_roles: dtRole
              })
            }
          })
          .catch((error) => {
            this.setState({ blocking: false });
            
          });
      }
    
      //API Wilayah
      setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
          .then(res => {
            if (res.status === 200) {
              res.data.forEach(data => {
                const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                newData.push(obj);
              });
              this.setState({ dt_prov: newData },
                () => {
                  if (id !== null && this.state.auto_prov === true) {
                    const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                    this.sel_prov(vl[0])
                  }
                }
              );
            }
            this.setState({ blocking: false });
          }).catch((error) => {
            this.setState({ blocking: false });
          });
      }
    
      // get kabupaten
      setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
          .then(res => {
            res.data.forEach(data => {
              const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
              newData.push(obj);
            });
            this.setState({ dt_kab: newData, blocking: false },
              () => {
                if (idKab !== null && this.state.auto_kab === true) {
                  const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                  // 
                  this.sel_kab(vl[0])
                }
              }
            );
          }).catch((error) => {
            this.setState({ blocking: false });
          });
      }
    
      // get kecamatan
      setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
          .then(res => {
            res.data.forEach(data => {
              const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
              newData.push(obj);
            });
            this.setState({ dt_kec: newData, blocking: false },
              () => {
                if (idKec !== null && this.state.auto_kec === true) {
                  const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                  // 
                  this.sel_kec(vl[0])
                }
              }
            );
          }).catch((error) => {
            this.setState({ blocking: false });
          });
      }
    
      // get desa
      setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
          .then(res => {
            res.data.forEach(data => {
              const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
              newData.push(obj);
            });
            this.setState({ dt_des: newData, blocking: false },
              () => {
                if (idDes !== null && this.state.auto_des === true) {
                  const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                  // 
                  this.sel_des(vl[0])
                }
              }
            );
          }).catch((error) => {
            this.setState({ blocking: false });
          });
      }
    
      // get rw
      setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
          .then(res => {
            res.data.forEach(data => {
              const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
              newData.push(obj);
            });
            this.setState({ dt_rw: newData, blocking: false });
          }).catch((error) => {
            this.setState({ blocking: false });
          });
      }
    
      // get rt
      setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id)
          .then(res => {
            res.data.forEach(data => {
              const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
              newData.push(obj);
            });
            this.setState({ dt_rt: newData, blocking: false });
          }).catch((error) => {
            this.setState({ blocking: false });
          });
      }
    
      sel_prov = (e) => {
        if (e) {
          this.setState({
            provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
            sendDatas: {
              "idProvinsi": e.value,
              "idKabupaten":0,
              "idKecamatan":0,
              "idKelurahan":0,
              "idRw":0,
            }
          },
            () => {
              if (this.state.id_provinsi && this.state.auto_kab === false) {
                // 
                this.setState({
                  dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                  rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                })
                this.setKab(this.state.id_provinsi, null);
              }
              if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                // 
                this.setState({ flagNumber: this.state.flagNumber - 1 })
              }
            }
          )
        }
        else {
          // this.handleSelectRole()
          this.setState({
            provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
            dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
            id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            sendDatas: {
              "idProvinsi": 0,
              "idKabupaten":0,
              "idKecamatan":0,
              "idKelurahan":0,
              "idRw":0,
            },
          }
            ,
            () => {
              if (e === null) {
                // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
            }
          )
        }
      }
    
      sel_kab = (e) => {
        if (e) {
          this.setState({
            kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten":e.value,
              "idKecamatan":0,
              "idKelurahan":0,
              "idRw":0,
            }
          },
            () => {
              if (this.state.id_kabupaten && this.state.auto_kec === false) {
                this.setState({
                  dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                  dis_des: true, dis_rw: true, dis_rt: true
                })
                this.setKec(this.state.id_kabupaten, null);
              }
              if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                // 
                this.setState({ flagNumber: this.state.flagNumber - 1 })
              }
            })
        }
        else {
          // this.handleSelectRole()
          this.setState({
            kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
            dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
            id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten":0,
              "idKecamatan":0,
              "idKelurahan":0,
              "idRw":0,
            }
          },
            () => {
              if (e === null) {
                // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
            }
          )
        }
      }
    
      sel_kec = (e) => {
        if (e) {
          this.setState({
            kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten":this.state.sendDatas.idKabupaten,
              "idKecamatan":e.value,
              "idKelurahan":0,
              "idRw":0,
            }
          },
            () => {
              if (this.state.id_kecamatan && this.state.auto_des === false) {
                this.setState({
                  desa: null, rw: null,
                  rt: null, dis_des: false, dis_rw: true, dis_rt: true
                })
                this.setDes(this.state.id_kecamatan, null);
              }
              if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                // 
                this.setState({ flagNumber: this.state.flagNumber - 1 })
              }
            })
        }
        else {
          // this.handleSelectRole()
          this.setState({
            kecamatan: null, desa: null, rw: null, rt: null,
            dis_des: true, dis_rw: true, dis_rt: true,
            id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten":this.state.sendDatas.idKabupaten,
              "idKecamatan":0,
              "idKelurahan":0,
              "idRw":0,
            }
          },
            () => {
              if (e === null) {
                // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
            }
          )
        }
      }
    
      sel_des = (e) => {
        if (e) {
          this.setState({
            desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten":this.state.sendDatas.idKabupaten,
              "idKecamatan":this.state.sendDatas.idKecamatan,
              "idKelurahan":e.value,
              "idRw":0,
            }
          },
            () => {
              if (this.state.id_des) {
                this.setRW(this.state.id_des);
              }
              if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                // 
                this.setState({ flagNumber: this.state.flagNumber - 1 })
              }
            }
          )
        }
        else {
          // this.handleSelectRole()
          this.setState({
            desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
            id_des: 0, id_rw: 0, id_rt: 0,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten":this.state.sendDatas.idKabupaten,
              "idKecamatan":this.state.sendDatas.idKecamatan,
              "idKelurahan":0,
              "idRw":0,
            }
          },
            () => {
              if (e === null) {
                // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
            }
          )
        }
      }
    
      sel_rw = (e) => {
        if (e) {
          this.setState({
            rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten":this.state.sendDatas.idKabupaten,
              "idKecamatan":this.state.sendDatas.idKecamatan,
              "idKelurahan":this.state.sendDatas.idRW,
              "idRw":e.value,
            }
          },
            () => {
              if (this.state.id_rw) {
                this.setRT(this.state.id_rw);
                // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
            })
        }
        else {
          // this.handleSelectRole()
          this.setState({
            rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten":this.state.sendDatas.idKabupaten,
              "idKecamatan":this.state.sendDatas.idKecamatan,
              "idKelurahan":this.state.sendDatas.idRW,
              "idRw":0,
            }
          },
            () => {
              if (e === null) {
                // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
            }
          )
        }
      }
    
      sel_rt = (e) => {
        if (e) {
          this.setState({
            rt: e, id_rt: e.value, depdagriRt: e.kode,
            sendDatas: { ...this.state.sendDatas, idRt: e.value }
          },
            () => {
              if (e) {
                // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
            }
          )
        }
        else {
          // this.handleSelectRole()
          this.setState({
            rt: null, id_rt: 0,
            sendDatas: {
              "idProvinsi": this.state.sendDatas.idProvinsi,
              "idKabupaten": this.state.sendDatas.idKabupaten,
              "idKecamatan": this.state.sendDatas.idKecamatan,
              "idKelurahan": this.state.sendDatas.idKelurahan,
              "idRw": this.state.sendDatas.idRW,
            }
          },
            () => {
              if (e === null) {
                // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
            }
          )
        }
      }
      //-----end wilayah

      handleSelectRole = (e) => {
        if (e) {
          this.setState({ role: e, roleTampil: e, roleId: e.value })
        } else {
          this.setState({ role: {}, roleTampil:[], roleId: null })
        }
    
      }

      handleChangePanel = (e) => {
        e.preventDefault();
        this.setState({ panel: !this.state.panel })
      }
    
      handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
          x = +e.target.value
        }
        this.setState({ sizePerPage: x })
      }

      handleSearch = (e) => {
        const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, roleId, value_priode } = this.state;
        

        let idprov = this.state.sendDatas.idProvinsi
        let idkab = this.state.sendDatas.idKabupaten
        let idkec = this.state.sendDatas.idKecamatan
        let iddes = this.state.sendDatas.idKelurahan
        let idrw = this.state.sendDatas.idRW

        if(id_kabupaten !== 0 && id_kabupaten !== undefined){

            // if (this.handleValidation()) {
            this.setState({ blocking: true });

            // Swal.fire({
            //     title: 'Cetak Formulir',
            //     //text: "Anda akan mencetak formulir",
            //     icon: 'info',
            //     cancelButtonColor: '#d33',
            //     // showDenyButton: true,
            //     // denyButtonText: 'PDF',
            //     // denyButtonColor: '#FF8000',
            //     showCancelButton: true,
            //     cancelButtonText: 'Batal',
            // }).then((result) => {
          let report = value_priode === 2024 ? 'SdmPerbankanVerval24' : 'SdmPerbankanVerval'
                let url = 'viewReport?filterPencarian=%7B'
    
                if (idprov !== 0 && idprov !== undefined) { url += '%22provinsiId%22%3A%22' + idprov + '%22%2C' }
                if (idkab !== 0 && idkab !== undefined) { url += '%22kabupatenId%22%3A%22' + idkab + '%22%2C' }
                if (idkec !== 0 && idkec !== undefined) { url += '%22kecamatanId%22%3A%22' + idkec + '%22%2C' }
                if (iddes !== 0 && iddes !== undefined) { url += '%22kelurahanId%22%3A%22' + iddes + '%22%2C' }

                if (roleId !== 0 && roleId !== null && roleId !== undefined){ url += '%22roleId%22%3A%22' + roleId + '%22%2C'}



                let urlCetakExcel = url + '%22reportName%22%3A%22'+ report + '%22%2C' + '%22reportType%22%3A%22HTML%22%2C%22reportCategory%22%3A%22SDM%22%7D'

                // if (result.isConfirmed) {
                    APIReport.get(urlCetakExcel)
                        .then(res => {
                            // 
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                // download(res.data, 'Daftar SDM' +'.xlsx', content)
                                this.setState({ blocking: false, htmlFrame: res.data })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // 
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });
    
    
                // } else {
                //     this.setState({ blocking: false });
                // }
            // })
    
            e.preventDefault()
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter minimal dipilih sampai tingkat Kabupaten',
                showConfirmButton: true,
            })
            this.setState({ blocking: false });
        }
      }

      getBackend() {
        this.setState({ blocking: true });
        let url = '/daftar/listDaftarUser?'
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, role } = this.state;
    
        if (id_provinsi !== 0 && id_provinsi !== undefined) { url += 'idProvinsi=' + id_provinsi }
        if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '&idKabupaten=' + id_kabupaten }
        if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '&idKecamatan=' + id_kecamatan }
        if (id_des !== 0 && id_des !== undefined) { url += '&idKelurahan=' + id_des }
        if (id_rw !== 0 && id_rw !== undefined) { url += '&idRw=' + id_rw }
        if (id_rt !== 0 && id_rt !== undefined) { url += '&idRt=' + id_rt }
        if (Object.keys(role).length > 0) {
          if (id_provinsi !== 0 && id_provinsi !== undefined) {
            url += '&roleId=' + role.value
          } else {
            url += 'roleId=' + role.value
          }
        }
    
        authServices.get(url)
          .then((res) => {
            this.setState({ blocking: false });
            if (res.status === 200) {
              this.setState(
                {
                  datas: res.data
                }, () => {
                  // 
                }
              )
            }
          })
          .catch((error) => {
            this.setState({ blocking: false });
            // 
          });
      }

      handleCetak = (e) => {
        const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, roleId, value_priode } = this.state;
        

        if(id_kabupaten !== 0 && id_kabupaten !== undefined){

            // if (this.handleValidation()) {
            this.setState({ blocking: true });

          let idprov = this.state.sendDatas.idProvinsi
          let idkab = this.state.sendDatas.idKabupaten
          let idkec = this.state.sendDatas.idKecamatan
          let iddes = this.state.sendDatas.idKelurahan
          let idrw = this.state.sendDatas.idRW

            Swal.fire({
                title: 'Cetak Formulir',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                // showDenyButton: true,
                // denyButtonText: 'PDF',
                // denyButtonColor: '#FF8000',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {
              let report = value_priode === 2024 ? 'SdmPerbankanVerval24' : 'SdmPerbankanVerval'

                let url = 'viewReport?filterPencarian=%7B'
    
                if (idprov !== 0 && idprov !== undefined) { url += '%22provinsiId%22%3A%22' + idprov + '%22%2C' }
                if (idkab !== 0 && idkab !== undefined) { url += '%22kabupatenId%22%3A%22' + idkab + '%22%2C' }
                if (idkec !== 0 && idkec !== undefined) { url += '%22kecamatanId%22%3A%22' + idkec + '%22%2C' }
                if (iddes !== 0 && iddes !== undefined) { url += '%22kelurahanId%22%3A%22' + iddes + '%22%2C' }

                if (roleId !== 0 && roleId !== null && roleId !== undefined){ url += '%22roleId%22%3A%22' + roleId + '%22%2C'}
                // if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }
                // if (id_rt !== 0 && id_rt !== undefined) { url += '%22rtId%22%3A%22' + id_rt + '%22%2C' }

    
    
                //viewReport?filterPencarian=%7B%22provinsiId%22%3A%222%22%2C%22reportName%22%3A%22FormKK%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22CetakForm%22%7D
    
                // let urlCetakPdf = url + '%22reportName%22%3A%22FormKKProd' + '%22%2C' + '%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22CetakForm%22%7D'
                // 
                let urlCetakExcel = url + '%22reportName%22%3A%22'+ report + '%22%2C' + '%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22SDM%22%7D'
    
                // if (result.isConfirmed) {
                //     APIReport.get(urlCetakExcel, { responseType: 'blob' })
                //         .then(res => {
                //             if (res.status === 200) {
                //                 const content = res.headers['content-type'];
                //                 download(res.data, 'Formulir KK' + '.xlsx', content)
                //                 this.setState({ blocking: false })
                //             } else {
                //                 Swal.fire({
                //                     title: 'Info!',
                //                     icon: 'info',
                //                     text: 'Data tidak ditemukan.',
                //                     showConfirmButton: false,
                //                     timer: 1500
                //                 })
                //                 this.setState({ blocking: false });
                //             }
                //         }).catch((error) => {
                //             // 
                //             let message = error;
                //             Swal.fire({
                //                 title: 'Error!',
                //                 icon: 'warning',
                //                 // text: 'Jaringan bermasalah.',
                //                 text: message,
                //             })
                //             this.setState({ blocking: false });
                //         });
    
                // } 
                // else 
                if (result.isConfirmed) {
                    APIReport.get(urlCetakExcel, { responseType: 'blob' })
                        .then(res => {
                            // 
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Daftar SDM' +'.xlsx', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // 
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: message,
                            })
                            this.setState({ blocking: false });
                        });
    
    
                } else {
                    this.setState({ blocking: false });
                }
            })
    
            e.preventDefault()
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter minimal dipilih sampai tingkat Kabupaten',
                showConfirmButton: true,
            })
            this.setState({ blocking: false });
        }


    }

    render() {
        const options = {
            page: 1,
            sizePerPageList: [{
                text: '1', value: 1
            }, {
                text: '10', value: 10
            }], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
        };

        return (
            <div className='content'>
                <div className='title-content'><ImPlay3 className='icon-brd' />Daftar SDM VERVAL</div>
                <div className='body-content'>
                    <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel} onChange={this.handleChangePanel} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Provinsi
                                                        <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kabupaten
                                                        <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kecamatan
                                                        <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kelurahan
                                                        <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                {/* <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        RW
                                                        <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        RT
                                                        <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid> */}
                                                <Grid item xs={12} md={4}>
                                                    Role
                                                    <FormControl
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                        size="small">
                                                        <Select
                                                            options={this.state.dt_sdm}
                                                            isClearable
                                                            onChange={this.handleSelectRole}
                                                            value={this.state.roleTampil}
                                                            placeholder="Cari..."
                                                            styles={{
                                                                menuPortal: base => ({
                                                                    ...base,
                                                                    zIndex: 9999
                                                                })
                                                            }}
                                                            menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                                                    <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak Excel</Button>


                                                </Grid>
                                                <Grid item xs={12} md={4}>


                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>


                            </Accordion>
                            

                        </Box>

                        <Paper sx={{ width: "100%", boxShadow: "none" }}>
                            
                                <Grid style={{ width: '100%', overflow: 'auto' }} md="12">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.htmlFrame }}></div>
                                </Grid>
                            

                        </Paper>
                        {/* <h3>Daftar SDM Pemutakhiran PK22</h3> */}
                        {/* <Paper sx={{ width: '100%', boxShadow: 'none' }}>
                            <div className='user-table'>
                                <BootstrapTable data={this.state.datas}
                                    pagination={true}
                                    options={options}
                                    search={true}
                                >
                                    <TableHeaderColumn width='100' dataField='isActive' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat} dataFormat={this.statusFormatter}>STATUS</TableHeaderColumn>
                                    <TableHeaderColumn width='175' isKey={true} dataField='username' headerAlign='center' columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign='center'>USERNAME</TableHeaderColumn>
                                    <TableHeaderColumn width='160' dataField='namaLengkap' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>NAMA</TableHeaderColumn>
                                    <TableHeaderColumn width='120' dataField='roleName' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>ROLE</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField='namaWilayah' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>WILAYAH</TableHeaderColumn>
                                    <TableHeaderColumn width='180' dataField='alamat' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat} >ALAMAT</TableHeaderColumn>
                                    <TableHeaderColumn width='110' dataField='noTelepon' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>TELEPON</TableHeaderColumn>
                                    <TableHeaderColumn width='180' dataField='email' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>EMAIL</TableHeaderColumn>
                                    <TableHeaderColumn width='90' dataField='createdby' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>CreatedBY</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                            <div className='sizePerPage'>
                                Size Per Page
                                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                                    <option value='1'>1</option>
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value={this.state.datas.length}>All</option>
                                </select>
                            </div>
                            <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div>


                        </Paper> */}
                    </BlockUi>

                </div>

            </div>
          )
    }
}
export default KRSCetakSDM;