import React, { Component } from 'react'
import { ImPlay3, ImSearch } from 'react-icons/im';
import { TextField, Select as MuiSelect, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, Input } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonTable from '../../../commons/table/TableMonitoring';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import Swal from 'sweetalert2';
import APIWilayah from '../../../services/APIWilayah';
import APIPemutakhiran from '../../../services/APIPemutakhiran';
import APIPemutakhiranMonitoring from '../../../services/APIPemutakhiranMonitoring';
import APIHistory from '../../../services/APIHistory';

class History extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panel1: true,
      blocking: false,
      tableHead: [],
      page: 1,
      sizePerPage: 10,
      totalSizeTable: 0,

      datas: [],
      aktivitas: [
        { value: 0, label: 'Semua AktiVitas' },
        { value: 1, label: 'Pindah Wilayah' },
        { value: 2, label: 'Tambah Wilayah' },
        { value: 3, label: 'Hapus Wilayah' },
        { value: 4, label: 'Ubah Wilayah' },
        { value: 5, label: 'Tambah User' },
        { value: 6, label: 'Ubah User' },
        { value: 7, label: 'Lock Wilayah' },
        { value: 8, label: 'Lock Target' },
        { value: 9, label: 'Lock RT PWA' }
      ],

      startdate: '',
      enddate: '',
      dis_enddate: true,
      usname: '',
      selectedAktivitas: null,

      blocking: false,

      payload: {
        "aktifitas": 0,
        "enddate": '',
        "limit": 10,
        "page": 1,
        "sorting": {
          "column": "tanggal",
          "sort": "desc"
        },
        "startdate": '',
        "username": ''
      }
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  componentDidMount() {
    var headerColumns = [
      { width: '150', row: '0', rowSpan: '3', colSpan: '1', title: 'DATE', dataField: "tanggal", dataFormat: 'startDate', headerAlign: 'center', dataAlign: 'center', editable: false, },
      { width: '150', row: '0', rowSpan: '3', colSpan: '1', title: 'CREATED BY', dataField: "userName", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: '280', row: '0', rowSpan: '3', colSpan: '1', title: 'AKTIVITAS', dataField: "aktivitas", headerAlign: 'center', dataAlign: 'left', editable: false },
      { row: '0', rowSpan: '1', colSpan: '6', title: 'JUMLAH DATA TERDAMPAK', dataField: "#", headerAlign: 'center', dataAlign: 'right', editable: false },
      { row: '1', rowSpan: '1', colSpan: '5', title: 'WILAYAH', dataField: "#", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '90', row: '2', rowSpan: '1', colSpan: '1', title: 'KABUPATEN', dataField: "implKab", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '90', row: '2', rowSpan: '1', colSpan: '1', title: 'KECAMATAN', dataField: "implKec", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '90', row: '2', rowSpan: '1', colSpan: '1', title: 'KELURAHAN', dataField: "implKel", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '90', row: '2', rowSpan: '1', colSpan: '1', title: 'RW', dataField: "implRw", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '90', row: '2', rowSpan: '1', colSpan: '1', title: 'RT', dataField: "implRt", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width:'100', row:'1', col : '8', rowSpan: '2', colSpan:'1', title: 'KK', dataField: "implKK", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '90', row: '1', rowSpan: '2', colSpan: '1', title: 'USER', dataField: "implUser", headerAlign: 'center', dataAlign: 'right', editable: false },

    ];
    this.setState({ tableHead: headerColumns });
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  handleChangeUsername = (e) => {
    if (e) {
      this.setState({ usname: e.target.value },
        () => this.setState({
          payload: {
            ...this.state.payload,
            "username": e.target.value
          }
        }))
    }
  }

  sel_aktv = (e) => {
    
    if (e) {
      this.setState({
        payload: {
          ...this.state.payload,
          "aktifitas": e.value
        },
        selectedAktivitas: e
      });
    } else {
      this.setState({
        payload: {
          ...this.state.payload,
          "aktifitas": 0
        },
        selectedAktivitas: null
      })
    }
  }

  sel_startDate = (e) => {
    if (e) {
      this.setState({
        startdate: e.target.value,
        enddate: '',
        payload: {
          ...this.state.payload,
          "startdate": e.target.value,
          "enddate": '',
        }
      },
        () => {
          if (this.state.startdate) {
            this.setState({
              dis_enddate: false
            })
          }
        }
      );
    }
    else {
      this.setState({
        startdate: '',
        enddate: '',
        payload: {
          ...this.state.payload,
          "startdate": '',
          "enddate": '',
        },
        dis_enddate: true,
      })
    }
  }

  sel_endDate = (e) => {
    
    if (e) {
      this.setState({
        enddate: e.target.value,
        payload: {
          ...this.state.payload,
          "enddate": e.target.value
        }
      });
    }
    else {
      this.setState({
        enddate: '',
        payload: {
          ...this.state.payload,
          "enddate": ''
        }
      })
    }
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  handleSearch = () => {
    this.setState({ datas: [], page: 1 },
      () => {
        this.loadApiGet()
      }
    )
    // if (this.state.startdate) {
    //   if (this.state.enddate) {
    //     if (this.state.startdate === this.state.enddate || this.state.startdate < this.state.enddate) {
    //       
    //       this.setState({ blocking: true })
    //       APIPemutakhiran.get("/pk/audit/loadHistoryWilayah", this.state.payload)
    //         .then((res) => {
    //           this.setState({ blocking: false });
    //           if (res.status === 200) {
    //             this.setState({ datas: res.data })
    //           }
    //         })
    //         .catch((error) => {
    //           this.setState({ blocking: false });
    //           
    //         });

    //     } else {
    //       Swal.fire({
    //         title: 'Gagal',
    //         icon: 'warning',
    //         text: 'Periksa kembali Start Date dan End Date yang dipilih.',
    //       });
    //     }

    //   } else {
    //     Swal.fire({
    //       title: 'Gagal',
    //       icon: 'warning',
    //       text: 'Silakan pilih End Date!',
    //     });
    //   }
    // } else if (this.state.startdate === '' && this.state.enddate) {
    //   Swal.fire({
    //     title: 'Gagal',
    //     icon: 'warning',
    //     text: 'Periksa kembali Start Date dan End Date yang dipilih.',
    //   });

    // } else {
    //   this.setState({ blocking: true })
    //   

    //   APIWilayah.get("/history/showLogKec")
    //     .then((res) => {
    //       
    //       this.setState({ blocking: false });
    //       if (res.status === 200) {
    //         this.setState(
    //           {
    //             datas: res.data.Data
    //           }
    //         )
    //       }
    //     })
    //     .catch((error) => {
    //       this.setState({ blocking: false });
    //       
    //     });
    // }
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    // this.setState({ sizePerPage: x, payload: {...this.state.payload, "limit": x} })
    this.handleTableChange(this.state.page, x)
  }

  handleTableChange = (page, sizePerPage) => {
    this.setState({ page: page, sizePerPage: sizePerPage, payload: { ...this.state.payload, "limit": sizePerPage, "page": page } },
      () => {
        this.loadApiGet()
      }
    )
  }

  loadApiGet = () => {
    this.setState({ blocking: true, totalSizeTable: 0 });
    
    const { payload } = this.state
    const inisialPage = (payload.page - 1) * 10 + 1
    
    // APIHistory.get("/portalpk/monitoring/getLogHistoryMutation?v_user_name=" + payload.username + "&v_kode_aktivitas=" + payload.aktifitas + "&v_start_date=" + payload.startdate + "&v_end_date=" + payload.enddate + "&v_page=" + inisialPage + "&v_limit=" + payload.limit + '&v_user_login=' + localStorage.getItem('username'))
    APIHistory.post("/history/MonitoringHistoryWilayah?userName=" + payload.username + "&kodeAktivitas=" + payload.aktifitas + "&startDate=" + payload.startdate + "&endDate=" + payload.enddate + "&page=" + inisialPage + "&limit=" + payload.limit + '&userLogin=' + localStorage.getItem('username'))
      .then((res) => {
        if (res.status === 200) {
          
          let tRecord = 0
          if (res.data.listlog.length > 0) {
            tRecord = res.data.listlog[0].total_record
          }
          this.setState({ datas: res.data.listlog, totalSizeTable: tRecord })
        }
        this.setState({ blocking: false });
      })
      .catch((error) => {
        this.setState({ blocking: false });
        
      });
  }

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />History</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Username
                            <TextField size='small' id="usname" name="usname" onChange={this.handleChangeUsername} value={this.state.usname} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Aktivitas
                            <Select options={this.state.aktivitas} isClearable onChange={this.sel_aktv} value={this.state.selectedAktivitas} placeholder="Cari..." />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }}>
                            Start Date
                            <TextField name='start-date' onChange={this.sel_startDate} value={this.state.startdate} inputProps={{ style: { height: "6px" }, max: moment(new Date()).format("YYYY-MM-DD") }} type="date" id="outlined-basic" isClearable />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            End Date
                            <TextField name='end-date' onChange={this.sel_endDate} value={this.state.enddate} inputProps={{ style: { height: "6px" }, min: this.state.startdate }} type="date" id="outlined-basic" isClearable disabled={this.state.dis_enddate} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid item xs={12}>
                            <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>History</h3>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                sizePerPage={+this.state.sizePerPage}
                totalDataSize={this.state.totalSizeTable}
                onPageChange={this.handleTableChange}
                page={this.state.page}
              />
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='40'>40</option>
                  <option value='50'>50</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div>
            </Paper>
          </div>
        </div>
      </BlockUi>
    )
  }
}

export default History;