import React, { Component } from "react";
import { Select as MuiSelect, TextField, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, TableCell, Input } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIUpload from '../../../services/APIUpload';
import APIDashboard from "../../../services/APIDashboard";
import Swal from 'sweetalert2';
import { HiOutlineDocumentText } from "react-icons/hi";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
//import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';

import { ImPlay3, ImSearch, ImPrinter } from "react-icons/im";
import { BsPencilSquare } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import APIReport from '../../../services/APIReport';
import download from 'downloadjs'
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import APIMonPendataan from "../../../services/APIMonPendataan";
import APIPemutakhiran from "../../../services/APIPemutakhiran";

const isNumber = (str) => /^[0-9]*$/.test(str);

class monitoringOperasional extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panel1: true,
            sizePerPage: 10,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],
            // defaultData: [{ 'nama_rw': 'RW001', 'nama_rt': 'RT011', 'entri': 'Sukses', 'ada': '70', 'didata': '69', 'ditemui': '67', 'baru': '2', 'pindah': '2', 'meninggaldunia': '0', 'tidakditemukan': '1', 'cerai': '0' },],
            roleId: 0,
            persen: 0,
            datas: [],
            dt_metode: [{ value: 1, label: 'CAPI (SmartPhone)', name: 'CAPI' }, { value: 2, label: 'PAPI', name: 'PAPI' }],
            filterMetode: [], selectedMetode: null,

            //Wilayah
            dt_prov: [], provinsi: null, id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: null, id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: null, id_rw: 0, dis_rw: true,
            dt_rt: [], rt: null, rtID: '', id_rt: 0, dis_rt: true,
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    componentDidMount = () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
            const nm = rest[0].attributes.user.TingkatWilayahID
            const ri = +rest[0].attributes.user.roleID;
            let x = false
            if (+ri === 3 || +ri === 4 || +ri === 9) {
                x = true
            }
            this.setState({ roleId: ri, disMax: x },
                () => {
                    if (+nm === 0) {
                        this.setState({ flagSearch: false },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                }
            )

        } else {
            this.setProv(null);
        }
    }

    //API Wilayah
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            // console.log(vl, 'vale kab')
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            // console.log(vl, 'vale kec')
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            // console.log(vl, 'vale des')
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        // console.log('masuk false')
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di prov')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di kab')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di kec')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di des')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah
    sel_metode = (e) => {
        if (e) {
            this.setState({
                filterMetode: e, selectedMetode: e.name,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterMetode: null, selectedMetode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    createFormatColor = (cell, row) => {
        return 'fcfad7'
    }

    handleSearch = () => {
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, selectedMetode, selectedStatus } = this.state

        this.setState({ blocking: true })

        const param = {
            "kabupatenId": id_kabupaten,
            "provinsiId": id_provinsi,
            "kecamatanId": id_kecamatan,
            "kelurahanId": id_des,
        }
        if (id_kabupaten !== 0 && id_kabupaten !== undefined) {
            APIPemutakhiran.get("/portalpk/metodepembayaran/getMonitoringPerbandinganDataOprKdr?provinsiId=" + id_provinsi + "&kabupatenId=" + id_kabupaten + "&kecamatanId=" + id_kecamatan + "&kelurahanId=" + id_des)
                .then((res) => {
                    console.log(res)
                    this.setState({ blocking: false });
                    if (res.data.data.length === 0) {
                        Swal.fire({
                            title: 'Info!',
                            icon: 'warning',
                            text: 'Data Tidak Ditemukan',
                        })
                        this.setState({ blocking: false, datas: [] });
                    } else {

                        if (res.status === 200) {
                            this.setState(
                                {
                                    datas: res.data.data
                                }

                            )
                            console.log(this.state.datas, "DATAAAA")
                        }
                    }

                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: 'Pencarian Data Gagal, Harap Coba lagi!',
                    })
                    this.setState({ blocking: false });
                });
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter wilayah minimal dipilih tingkat Kabupaten,',
                showConfirmButton: true
            })
            this.setState({ blocking: false })
        }
    }



    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }


    handleChangeInput = (e) => {
        const val = Number(e.target.value)
        if (isNumber(val)) {
            this.setState({ detailTarget: { ...this.state.detailTarget, maksTargetKK: val } })
        }
    }

    handleCetak = (e) => {
        console.log('tes')
        // e.preventDefault()
        this.setState({ blocking: true });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, selectedMetode } = this.state;
        console.log(id_provinsi, 'prov')

        if (id_provinsi !== null && selectedMetode !== null && id_kabupaten !== 0 && id_kabupaten !== undefined) {
            Swal.fire({
                title: 'Cetak Laporan',
                //text: "Anda akan mencetak formulir",
                icon: 'info',
                cancelButtonColor: '#d33',
                confirmButtonText: 'PDF',
                confirmButtonColor: '#3085d6',
                showDenyButton: true,
                denyButtonColor: '#3CB371',
                denyButtonText: 'Excel',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {

                let metode = ''

                //{"provinsiId":"12","kabupatenId":"169","kecamatanId":"961","kelurahanId":"8746","metode":"CAPI","reportName":"ApprovalOperasional","reportType":"pdf","reportCategory":"APPROVAL"}

                if (selectedMetode === 'CAPI') {
                    metode = 'MonitoringAppOprCapi'
                } else {
                    metode = 'MonitoringAppOprPapi'
                }

                // let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + row.user_id + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'
                let url = 'viewReport?filterPencarian=%7B' //+ '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' 
                if (id_provinsi !== null && id_provinsi !== 0) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
                if (id_kabupaten !== null && id_kabupaten !== 0) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
                if (id_kecamatan !== null && id_kecamatan !== 0) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
                if (id_des !== null && id_des !== 0) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
                // url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C'
                // url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C'
                // url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C'
                let urlCetakPdf = url + '%22reportName%22%3A%22' + metode + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MONITORING%22%7D'
                let urlCetakExcel = url + '%22reportName%22%3A%22' + metode + '%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MONITORING%22%7D'

                if (result.isConfirmed) {
                    APIReport.get(urlCetakPdf, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Laporan Monitoring Approval Operasional' + '.pdf', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: 'Laporan gagal dicetak, silakan coba lagi',
                            })
                            this.setState({ blocking: false });
                        });

                } else if (result.isDenied) {
                    APIReport.get(urlCetakExcel, { responseType: 'blob' })
                        .then(res => {
                            if (res.status === 200) {
                                const content = res.headers['content-type'];
                                download(res.data, 'Laporan Monitoring Approval Operasional' + '.xlsx', content)
                                this.setState({ blocking: false })
                            } else {
                                Swal.fire({
                                    title: 'Info!',
                                    icon: 'info',
                                    text: 'Data tidak ditemukan.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.setState({ blocking: false });
                            }
                        }).catch((error) => {
                            // console.log("error ===> ", error)
                            let message = error;
                            Swal.fire({
                                title: 'Error!',
                                icon: 'warning',
                                // text: 'Jaringan bermasalah.',
                                text: 'Laporan gagal dicetak, silakan coba lagi',
                            })
                            this.setState({ blocking: false });
                        });

                } else {
                    this.setState({ blocking: false });
                }
            })
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter wilayah minimal dipilih tingkat kabupaten, serta filter SDM harus dipilih.',
                showConfirmButton: true,
            })
            this.setState({ blocking: false });
        }

    }

    render() {
        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
        };
        return (
            <div className='content'>
                <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                    <div className='title-content'><ImPlay3 className='icon-brd' />Monitoring Perbandingan</div>
                    <div className='body-content'>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                            <Grid item xs={12} md={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Periode
                                            <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small" >
                                            Provinsi
                                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kabupaten
                                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kecamatan
                                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kelurahan
                                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                                        {/* <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <h3>Monitoring Perbandingan Operator Dan Kader</h3>
                        <Paper sx={{ width: "100%", boxShadow: "none" }}>


                            <div className="rekap-table">
                                <BootstrapTable
                                    data={this.state.datas}
                                    pagination={true}
                                    options={options}
                                    search={true}
                                >
                                    {/* <TableHeaderColumn width="40" dataField="" headerAlign="center" dataAlign="center" dataFormat={this.numberingFormat} >NO </TableHeaderColumn> */}


                                    <TableHeaderColumn width="100" row="0" rowSpan='3' dataField="namaKelurahan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NAMA KELURAHAN</TableHeaderColumn>
                                    <TableHeaderColumn width="120" row="0" rowSpan='3' dataField="namaRw" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor}  >NAMA RW</TableHeaderColumn>
                                    <TableHeaderColumn width="120" row="0" rowSpan='3' dataField="namaRt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NAMA RT</TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' dataField="@" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >JUMLAH RT </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahRtKdr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahRtOpr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' isKey={true} dataField="yang_ada_byrt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >YANG ADA </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahYangAdaKdr" headerAlign="center" dataAlign="center" columnClassName={this.createMDKDRFormat} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahYangAdaOpr" headerAlign="center" dataAlign="center" columnClassName={this.createMDOPRFormat} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' dataField="yang_ada_byrt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >DIDATA </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahDidataKdr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahYangDidataOpr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' dataField="yang_ada_byrt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >DITEMUI </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahDitemukanKdr" headerAlign="center" dataAlign="center" columnClassName={this.createMDKDRFormat} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahDitemukanOpr" headerAlign="center" dataAlign="center" columnClassName={this.createMDOPRFormat} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' dataField="yang_ada_byrt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >BARU </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahKkBaruKdr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahKkBaruOpr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' dataField="yang_ada_byrt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >PINDAH </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahPindahKdr" headerAlign="center" dataAlign="center" columnClassName={this.createMDKDRFormat} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahPindahOpr" headerAlign="center" dataAlign="center" columnClassName={this.createMDOPRFormat} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' dataField="yang_ada_byrt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >MENINGGAL </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahMeninggalKdr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahMeninggalOpr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' dataField="yang_ada_byrt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TIDAK DITEMUKAN </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahTdkDitemukanKdr" headerAlign="center" dataAlign="center" columnClassName={this.createMDKDRFormat} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahTdkDitemukanOpr" headerAlign="center" dataAlign="center" columnClassName={this.createMDOPRFormat} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>
                                    <TableHeaderColumn width="120" row='1' colSpan='2' dataField="yang_ada_byrt" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >BERCERAI </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahBerceraiKdr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDKDRFormatter}  > KDR </TableHeaderColumn>
                                    <TableHeaderColumn width="70" row='2' dataField="jumlahBerceraiOpr" headerAlign="center" dataAlign="center" columnClassName={this.createFormatColor} dataFormat={this.MDOPRFormatter}  >OPR </TableHeaderColumn>


                                    {/* <TableHeaderColumn width="60" dataField="targetkk" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >TARGET KK </TableHeaderColumn> */}
                                    {/* <TableHeaderColumn width="80" dataField="file" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.fileFormatter} >FILE </TableHeaderColumn> */}
                                    {/* <TableHeaderColumn width="80" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn> */}
                                </BootstrapTable>

                            </div>
                            <div className="sizePerPage">
                                Size Per Page
                                <select
                                    name="category"
                                    value={this.state.sizePerPage}
                                    onChange={this.handleChangeSizePerPage}
                                >
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value={this.state.datas?.length}>All</option>
                                </select>
                            </div>
                            <div className="totalData">
                                <b>Total Data: {this.state.datas?.length}</b>
                            </div>
                        </Paper>

                    </div>
                </BlockUi >
            </div >
        )
    }
}

export default monitoringOperasional;