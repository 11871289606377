import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, Paper, Typography } from '@mui/material';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { AiFillLock, AiTwotoneEdit } from "react-icons/ai";
import { FcProcess } from "react-icons/fc";
import { ImPlay3, ImPrinter, ImSearch, ImUserPlus } from 'react-icons/im';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdPassword, MdVerified } from 'react-icons/md';
import Select from 'react-select';
import Swal from 'sweetalert2';


import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from '@mui/material/OutlinedInput';

import Tooltip from '@mui/material/Tooltip';
import download from 'downloadjs';
import moment from 'moment';
import { Link } from 'react-router-dom';
import APIReport from '../../../services/APIReport';
import APIWilayah from '../../../services/APIWilayah';
import authServices from "../../../services/authServices";
import CreateUser from './CreateUser';
import EditUser from './EditUser';


class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendDatas: {},
      panel: true,

      //---table
      sizePerPage: 10,
      tableHead: [],
      datas: [],
      //---end table

      //---wilayah
      flagNumber: -1,
      flagOpen: true,
      blocking: false,
      dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
      dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
      //---end wilayah

      dt_roles: [],
      role: {},
      openEditUser: false,
      openAddUser: false,
      isClearable: true,

      changePassword: false,
      showPasswordLama: false,
      showPasswordBaru: false,
      showPasswordBaruKonfirm: false,
      password: '',
      passwordBaru: '',
      updateBy: localStorage.getItem("username"),
      userId: 0,
      userRole: JSON.parse(localStorage.getItem('authority'))[0].attributes.role,
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
      optMenu: [
        { value: 1, label: 'isActive' }, { value: 2, label: 'Bank' },
        { value: 3, label: 'No Rekening' }, { value: 4, label: 'Status Pekerjaan' },
        { value: 5, label: 'NPWP' }, { value: 6, label: 'Status NPWP' },
        { value: 7, label: 'Golongan' }, { value: 8, label: 'Status Pembayaran' }
      ],
      menu: null,
      menuValue: null,
      disRole: false
    }
  }

  sel_Menu = (e) => {
    console.log(e)
    if (e) {
      this.setState({
        menuValue: e.value, menu: e,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        menuValue: null, menu: null,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  getRole = (e) => {
    authServices
      .get("/roles/list?periodeSensus=" + this.state.value_priode)
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState({
            dt_roles: res.data,
            disRole: false
          }, () => {
            const role = JSON.parse(localStorage.getItem('authority'))[0].attributes.role
            const smartphone = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.Smartphone
            if (role === 'Supervisor' && smartphone === false) {
              const manager = [res.data[9], res.data[17], res.data[27]]
              this.setState({
                dt_roles: manager
              })
            } else if (role === 'Supervisor' && smartphone === true) {
              const manager = [res.data[4], res.data[27]]
              this.setState({
                dt_roles: manager
              })
            } else if (role === 'Manager Pengelolaan' || role === 'Manager Data') {
              const manager = [res.data[3], res.data[4], res.data[9], res.data[13], res.data[17], res.data[27]]
              this.setState({
                dt_roles: manager
              })
            } else if (role === 'Admin Kabupaten') {
              const manager = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[9], res.data[12], res.data[13], res.data[27]]
              this.setState({
                dt_roles: manager
              })
            } else if (role === 'Admin Provinsi') {
              const manager = [res.data[2], res.data[3], res.data[4], res.data[5], res.data[8], res.data[9], res.data[10], res.data[11], res.data[12], res.data[13], res.data[14], res.data[15], res.data[16], res.data[17], res.data[27]]
              this.setState({
                dt_roles: manager
              })
            } else if (role === 'Pengumpul Data Verval') {
              const manager = [res.data[20]]
              this.handleSelectRole({ value: res.data[20].id, label: res.data[20].roleName })
              this.setState({
                dt_roles: manager,
                disRole: true
              })
            } else if (role === 'Pengolah Data Verval' || role === 'Verifikator Data Verval') {
              const manager = [res.data[20], res.data[21], res.data[22]]
              this.handleSelectRole({ value: res.data[20].id, label: res.data[20].roleName })
              this.setState({
                dt_roles: manager,
                isClearable: false
              })
            } else {
              this.setState({
                dt_roles: res.data
              })
            }
          })
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });

      });
  }

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      }, () => {
        this.setHeaderTable()
        this.getRole();

        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if (role === 1 || role === 99) {
          this.setState({
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
          })
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }

            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  activeBtn = (e) => {
    const idUser = parseInt(e.currentTarget.id);
    let status = e.currentTarget.value;
    const rest = JSON.parse(localStorage.getItem("authority"));
    const lastModifiedBy = rest[0].attributes.user_name

    if (status == "true") {
      status = false;
    } else {
      status = true;
    }
    this.setState({
      updateStatus: {
        id: idUser,
        isActive: status
      }
    })

    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: "Ingin mengubah status User ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ blocking: true });
        authServices.post("daftar/updateUserStatus?id=" + this.state.updateStatus.id + "&isactive=" + this.state.updateStatus.isActive + "&lastmodifiedby=" + lastModifiedBy + '&periodeSensus=' + this.state.value_priode)
          .then((res) => {

            this.setState({ blocking: false });
            if (res.status === 200 && res.data['update-status'] === '400') {
              Swal.fire(
                'Gagal !',
                'Gagal Mengubah Status User ini, Wilayah Telah Digunakan.',
                'error'
              )
              return false;
            } else {
              //Update state berdasarkan id yg diubah
              const arr = { id: this.state.updateStatus.id, isActive: this.state.updateStatus.isActive }
              this.setState({
                datas: this.state.datas.map(el => el.id === this.state.updateStatus.id ? Object.assign({}, el, arr) : el)
              })
              Swal.fire(
                'Berhasil!',
                'Kamu Berhasil Mengubah Status User ini.',
                'success'
              )
              this.setLabel()
            }
          })
          .catch((error) => {
            this.setState({ blocking: false });
            Swal.fire(
              'Info !',
              error.response.data.message,
              'error'
            )
            return false;

          });
      } else {
        this.setState({ blocking: false })
      }
    })
  }

  // handleEditUser = (e, row) => {
  //   this.setState({}, () => { this.handleOpenCloseEditUser() })


  // }

  handleOpenCloseEditUser = (e, row, data) => {
    if (e) e.preventDefault()
    // if (reason && reason === "backdropClick")
    //   return;
    this.setState({ dataEdit: row },
      () => {
        this.setState({ openEditUser: !this.state.openEditUser },
          () => {
            if (data && data !== undefined) {

              this.setState({ datas: this.state.datas.map(el => el.id === data.id ? Object.assign({}, el, data) : el) })
            }
          }
        )
      }
    )
  }

  handleOpenCloseAddUser = (event, reason) => {
    if (event) event.preventDefault()
    // if (reason && reason === "backdropClick")
    //   return;
    this.setState({ openAddUser: !this.state.openAddUser })
  }

  clearForm = () => {
    this.setState({ id: null, passwordBaru: '', password: '' })
  }

  editPassword = (e, row) => {

    this.setState({ usn: row.username }, () => { this.handleOpenCloseChangePassword() })
  }

  detailWilayah = (e, row) => {

    this.setState({ usn: row.username, userId: row.id }, () => { this.handleOpenCloseDetailWilayah() })
  }

  handleOpenCloseDetailWilayah = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    this.setState({ detailWilayah: !this.state.detailWilayah },
      () => { if (!this.state.detailWilayah) { this.clearForm() } }
    )
  }

  handleOpenCloseChangePassword = (event, reason) => {
    //
    if (reason && reason == "backdropClick")
      return;
    this.setState({ changePassword: !this.state.changePassword },
      () => { if (!this.state.changePassword) { this.clearForm() } }
    )
  }

  handleChangePassword = (e) => {
    if (e) {
      if (e.target.name === 'passwordBaru') {
        this.setState({ password: e.target.value })
      } else if (e.target.name === 'passwordBaruKonfirm') {
        this.setState({ passwordBaru: e.target.value })
      }

    }



  }

  handleClickShowPassword = () => {
    this.setState({
      showPasswordLama: !this.state.showPasswordLama,
      showPasswordBaru: !this.state.showPasswordBaru,
    });
  };

  handleClickShowPasswordKonfirm = () => {
    this.setState({
      showPasswordBaruKonfirm: !this.state.showPasswordBaruKonfirm,
    })
  }

  handleSavePassword = (e) => {
    e.preventDefault();

    if (this.state.password === '' || this.state.passwordBaru === '') {
      Swal.fire({
        title: 'Info',
        icon: 'info',
        text: 'Masukkan kata sandi!',
      });
    } else {

      Swal.fire({
        title: 'Peringatan Ubah Password',
        text: "Apakah anda yakin akan mengubah password user - " + this.state.usn + " ?",
        icon: 'warning',
        cancelButtonText: 'Batal!',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          const formPassword = {
            password: this.state.passwordBaru,
            updateBy: this.state.updateBy,
            updateDate: moment().toJSON(),
            username: this.state.usn
          }



          if (this.state.password === this.state.passwordBaru) {
            authServices.put('/user/changePassword?periodeSensus=' + this.state.value_priode, formPassword)
              .then(response => {
                if (response.status === 200) {
                  Swal.fire({
                    title: 'Ubah Password Berhasil',
                    icon: 'success',
                    //text: 'Anda akan diarahkan ke halaman login dan masukkan kata sandi yang baru',
                  });
                  this.setState({ changePassword: false }, () => { this.clearForm() })

                  // localStorage.clear();
                  // setTimeout(
                  //     () => window.open("/login", "_self"),
                  //     5000
                  // );

                } else {
                  Swal.fire({
                    title: 'Info',
                    icon: 'warning',
                    text: 'Ubah password gagal!',
                  });
                }
              }).catch((error) => {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: error,
                });
              })
          } else {
            Swal.fire({
              title: 'Gagal',
              icon: 'warning',
              text: 'Password yang dimasukkan tidak sama!',
            });
          }
        }
      })
    }
  }

  notifvalidasi = () => {
    Swal.fire({
      title: 'Info!',
      icon: 'info',
      text: 'Data Sudah Ada Di Modul Pembayaran Atau Wilayah Telah diLock, Hubungi Administrator',
    });
  }

  buttonFormatter = (cell, row) => {
    const role = JSON.parse(localStorage.getItem('authority'))[0].attributes.role
    console.log(row)
    if (this.state.value_priode === '2023') {
      return <div className='divBtnTable'>
        -
      </div>
    } else {

      if (this.state.userRole === 'Supervisor' || this.state.userRole === "Verifikator Data Verval") {
        return <div className='divBtnTable'>
          -
        </div>
      } else {
        if (row.isActive === false) {
          return <div className='divBtnTable'>
            {row.statusPembayaran === true ?
              <Tooltip title="Ubah Status User"><Button onClick={this.notifvalidasi} disabled={false} id={row.id} value={row.isActive} className='btnTable' variant="outlined"> <FcProcess di /></Button></Tooltip>
              :
              <Tooltip title="Ubah Status User"><Button onClick={this.activeBtn} disabled={false} id={row.id} value={row.isActive} className='btnTable' variant="outlined"> <FcProcess di /></Button></Tooltip>
            }        </div>
        } else {
          return (
            <div className='divBtnTable'>
              {row.roleId === 32 ? (
                <Tooltip title="Edit User">
                  <Button
                    className='btnTable'
                    variant="outlined"
                    onClick={(e) => this.handleOpenCloseEditUser(e, row, null)}
                  >
                    <AiTwotoneEdit style={{ color: 'green' }} />
                  </Button>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Ubah Status User">
                    <Button
                      onClick={row.statusPembayaran ? this.notifvalidasi : this.activeBtn}
                      id={row.id}
                      value={row.isActive}
                      className='btnTable'
                      variant="outlined"
                    >
                      <FcProcess />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Ubah Password">
                    <Button
                      onClick={(e) => this.editPassword(e, row)}
                      id={row.id}
                      className='btnTable'
                      variant="outlined"
                    >
                      <MdPassword />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit User">
                    <Button
                      className='btnTable'
                      variant="outlined"
                      onClick={(e) => this.handleOpenCloseEditUser(e, row, null)}
                    >
                      <AiTwotoneEdit style={{ color: 'green' }} />
                    </Button>
                  </Tooltip>
                </>
              )}
            </div>
          );

        }
      }
    }

  }

  statusFormatter = (cell, row) => {
    if (row.isActive === true) {

      return <div className='userActive'> {row.validasi_nik === true && <Tooltip title="Verifikasi SIAK"> <MdVerified style={{ fontSize: '18px', position: 'relative', top: '4px' }} /></Tooltip>} < MdOutlineCheckBox style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Aktif</div>
    } else {
      return <div className='userNonActive'>{row.validasi_nik === true && <Tooltip title="Verifikasi SIAK"> <MdVerified style={{ fontSize: '18px', position: 'relative', top: '4px' }} /></Tooltip>} <MdOutlineCheckBoxOutlineBlank style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Non - Aktif</div >
    }
  }

  createdFormat = (cell, row) => {

    // if (row?.roleName === "Supervisor" && row?.validasiDate === null) {
    //   return 'adadad'
    // } else {
    //   return ''
    // }
    let created = row.created ? new Date(row.created.substring(0, 10)) : null
    let modified = row.lastModified ? new Date(row.lastModified.substring(0, 10)) : null
    let temp = new Date("2024-06-01")
    if (modified >= temp || created >= temp) {
      // if (row.createdby === "superadmin" || row.createdby === "superadmin") {
      return 'c7ebcd'
    } else {
      return ''
    }

  }

  setHeaderTable = () => {
    var headerColumns = [
      { width: "80", title: 'ACTION', dataField: "action", headerAlign: 'center', dataAlign: 'center', editable: false, dataFormat: "action" },
      { width: "100", title: 'STATUS', dataField: "isActive", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", title: 'USERNAME', dataField: "username", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'NAMA', dataField: 'namaLengkap', headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'ROLE', dataField: "roleName", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "150", title: 'WILAYAH', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'ALAMAT', dataField: "alamat", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'TELEPON', dataField: "noTelepon", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", title: 'EMAIL', dataField: "email", headerAlign: 'center', dataAlign: 'left', editable: false },

    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidMount = () => {
    this.setHeaderTable()
    this.getRole();

    const rest = JSON.parse(localStorage.getItem("authority"));
    this.setState({
      roleUser: rest[0].attributes.role
    })
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({
          flagSearch: false,
          roleUser: rest[0].attributes.role
        },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }

        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
        }
        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // 
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // 
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024' + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              // 
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
        sendDatas: { ...this.state.sendDatas, idProvinsi: e.value }
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // 
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {},
      }
        ,
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
        sendDatas: { ...this.state.sendDatas, idKabupaten: e.value }
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
        sendDatas: { ...this.state.sendDatas, idKecamatan: e.value }
      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
        sendDatas: { ...this.state.sendDatas, idKelurahan: e.value }
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // 
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
        sendDatas: { ...this.state.sendDatas, idRw: e.value }
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
        sendDatas: { ...this.state.sendDatas, idRt: e.value }
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        rt: null, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
          "idRw": this.state.sendDatas.idRW,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  handleSelectRole = (e) => {
    if (e) {
      this.setState({ role: e })
      this.setState({ sendDatas: { ...this.state.sendDatas, roleId: e.value } });
    } else {
      this.setState({ role: {}, sendDatas: { ...this.state.sendDatas, roleId: '' } })
    }

  }

  handleChangePanel = (e) => {
    e.preventDefault();
    this.setState({ panel: !this.state.panel })
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa?.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan?.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten?.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi?.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  handleSearch = () => {
    this.setLabel();
    // const { userRole, role } = this.state;
    // console.log(Object.keys(role).length)
    // if (userRole === "Admin Developer"|| userRole === "Administrator") {
    this.getBackend();
    // }
    // else if ((userRole !== "Admin Developer" && Object.keys(role).length > 0)|| (userRole !== "Administrator" && Object.keys(role).length > 0)) {
    //   this.getBackend();
    // } else {
    //   Swal.fire({
    //     title: 'Info',
    //     icon: 'info',
    //     text: 'Harap Isi Role!',
    //   });
    // }
  }


  handleCetak = (e) => {

    // e.preventDefault()
    this.setState({ blocking: true });
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, selectedMetode, role, value_priode } = this.state;



    if (id_provinsi) {
      Swal.fire({
        title: 'Cetak Laporan User',
        //text: "Anda akan mencetak formulir",
        icon: 'info',
        cancelButtonColor: '#d33',
        confirmButtonText: 'PDF',
        confirmButtonColor: '#3085d6',
        showDenyButton: true,
        denyButtonColor: '#3CB371',
        denyButtonText: 'Excel',
        showCancelButton: true,
        cancelButtonText: 'Batal',
      }).then((result) => {

        let metode = ''

        //{"provinsiId":"12","kabupatenId":"169","kecamatanId":"961","kelurahanId":"8746","metode":"CAPI","reportName":"ApprovalOperasional","reportType":"pdf","reportCategory":"APPROVAL"}

        if (selectedMetode === 'CAPI') {
          metode = 'MonitoringAppOprCapi'
        } else {
          metode = 'MonitoringAppOprPapi'
        }

        // let url = 'viewReport?filterPencarian=%7B' + '%22userId%22%3A%22' + row.user_id + '%22%2C%22reportName%22%3A%22' + reportName + '%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22' + reportKategori + '%22%7D'
        let url = 'viewReport?filterPencarian=%7B' //+ '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' 
        if (id_provinsi !== null && id_provinsi !== 0) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
        if (id_kabupaten !== null && id_kabupaten !== 0) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
        if (id_kecamatan !== null && id_kecamatan !== 0) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
        if (id_des !== null && id_des !== 0) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
        if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }
        if (id_rt !== 0 && id_rt !== undefined) { url += '%22rtId%22%3A%22' + id_rt + '%22%2C' }
        if (role?.value !== 0 && role?.value !== undefined) { url += '%22roleId%22%3A%22' + role?.value + '%22%2C' }
        if (value_priode !== 0 && value_priode !== undefined) { url += '"periodeSensus":"' + value_priode + '",' }

        // url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C'
        // url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C'
        // url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C'
        let urlCetakPdf = url + '%22reportName%22%3A%22DaftarUserPK24%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22SDM%22%7D'
        let urlCetakExcel = url + '%22reportName%22%3A%22DaftarUserPK24%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22SDM%22%7D'

        if (result.isConfirmed) {
          APIReport.get(urlCetakPdf, { responseType: 'blob' })
            .then(res => {
              if (res.status === 200) {
                const content = res.headers['content-type'];
                download(res.data, 'Laporan Daftar User' + '.pdf', content)
                this.setState({ blocking: false })
              } else {
                Swal.fire({
                  title: 'Info!',
                  icon: 'info',
                  text: 'Data tidak ditemukan.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.setState({ blocking: false });
              }
            }).catch((error) => {
              // 
              let message = error;
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                // text: 'Jaringan bermasalah.',
                text: 'Laporan gagal dicetak, silakan coba lagi',
              })
              this.setState({ blocking: false });
            });

        } else if (result.isDenied) {
          APIReport.get(urlCetakExcel, { responseType: 'blob' })
            .then(res => {
              if (res.status === 200) {
                const content = res.headers['content-type'];
                download(res.data, 'Laporan Daftar User' + '.xlsx', content)
                this.setState({ blocking: false })
              } else {
                Swal.fire({
                  title: 'Info!',
                  icon: 'info',
                  text: 'Data tidak ditemukan.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.setState({ blocking: false });
              }
            }).catch((error) => {
              // 
              let message = error;
              Swal.fire({
                title: 'Error!',
                icon: 'warning',
                // text: 'Jaringan bermasalah.',
                text: 'Laporan gagal dicetak, silakan coba lagi',
              })
              this.setState({ blocking: false });
            });

        } else {
          this.setState({ blocking: false });
        }
      })
    } else {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Filter wilayah minimal dipilih tingkat kabupaten, serta filter SDM harus dipilih.',
        showConfirmButton: true,
      })
      this.setState({ blocking: false });
    }

  }

  getBackend() {
    this.setState({ blocking: true });
    let url = '/daftar/listDaftarUser?'
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, role, value_priode } = this.state;

    if (id_provinsi !== 0 && id_provinsi !== undefined) { url += 'idProvinsi=' + id_provinsi }
    if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '&idKabupaten=' + id_kabupaten }
    if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '&idKecamatan=' + id_kecamatan }
    if (id_des !== 0 && id_des !== undefined) { url += '&idKelurahan=' + id_des }
    if (id_rw !== 0 && id_rw !== undefined) { url += '&idRw=' + id_rw }
    if (id_rt !== 0 && id_rt !== undefined) { url += '&idRt=' + id_rt }
    if (value_priode !== 0 && value_priode !== undefined) { url += '&periodeSensus=' + value_priode }
    if (Object.keys(role).length > 0) {
      if (id_provinsi !== 0 && id_provinsi !== undefined) {
        url += '&roleId=' + role.value
      } else {
        url += 'roleId=' + role.value
      }
    }

    authServices.get(url)
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState(
            {
              datas: res.data
            }, () => {
              // 
            }
          )
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        // 
      });
  }

  render() {

    const options = {
      page: 1,
      sizePerPageList: [{
        text: '1', value: 1
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
    };
    return (
      <div className='content'>
        <div className='title-content'><ImPlay3 className='icon-brd' />Pengaturan User PK {this.state.roleUser}</div>
        <div className='body-content'>
          <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel} onChange={this.handleChangePanel} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          Role
                          <FormControl
                            sx={{
                              width: '100%'
                            }}
                            size="small">
                            <Select
                              options={this.state.dt_roles.map(({ id, roleName }) => ({ value: id, label: roleName }))}
                              isClearable={this.state.isClearable}
                              onChange={this.handleSelectRole}
                              value={this.state.role}
                              placeholder="Cari..."
                              isDisabled={this.state.disRole}
                              styles={{
                                menuPortal: base => ({
                                  ...base,
                                  zIndex: 9999
                                })
                              }}
                              menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                          <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>
                        </Grid>
                        <Grid item xs={12} md={4}>

                          {/* {this.state.value_priode === '2023' || this.state.userRole === "Supervisor" || this.state.userRole === 'Supervisor Provinsi' || this.state.userRole === 'Supervisor Kabupaten' || this.state.userRole === 'Supervisor Kecamatan' || this.state.userRole === 'Supervisor Kelurahan' || this.state.userRole === 'Supervisor Kecamatan' || this.state.userRole === 'Pengolah Data Verval' || this.state.userRole === 'SpvPembayaran'
                           ? '' :
                            <Link to="/create-user" style={{ textDecoration: 'none' }}>
                              <Button onClick={this.handleOpenCloseAddUser} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', backgroundColor: 'green', marginRight: '5px', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImUserPlus style={{ marginRight: '5px' }} /> Tambah User</Button>
                            </Link>
                          } */}
                          {(this.state.userRole === "Admin Developer" || this.state.userRole === "Administrator" || this.state.userRole === "Manager Data" || this.state.userRole === "Admin Kabupaten" || this.state.userRole === "Admin Provinsi") &&

                            <Link to="/create-user" style={{ textDecoration: 'none' }}>
                              <Button onClick={this.handleOpenCloseAddUser} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', backgroundColor: 'green', marginRight: '5px', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImUserPlus style={{ marginRight: '5px' }} /> Tambah User</Button>
                            </Link>}

                          {/* <Button sx={{ width: '100%', minHeight: '36px', marginTop: '22px' }} variant="outlined" color="success"><ImUserPlus style={{ marginRight: '5px' }} /> Tambah User <Link to="/laporan-tabulasi" /></Button> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Daftar User {this.state.title}</h3>
            <Grid item xs={6} md={3}>
              <FormControl sx={{ width: '30%' }} size="small">
                Tambahan Menu
                <Select
                  options={this.state.optMenu}
                  isClearable
                  onChange={this.sel_Menu}
                  value={this.state.menu}
                  placeholder="Cari..."
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  sx={{ width: '100%' }}
                  isMulti
                />
              </FormControl>
            </Grid>


            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              {/* <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
              > */}
              <div className='user-table'>
                <BootstrapTable
                  data={this.state.datas}
                  pagination={true}
                  options={options}
                  search={true}
                >
                  <TableHeaderColumn width='120' dataField='action' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatD} dataFormat={this.buttonFormatter}>ACTION</TableHeaderColumn>
                  <TableHeaderColumn width='100' dataField='isActive' headerAlign='center' dataAlign='center' columnClassName={this.createdFormatD} dataFormat={this.statusFormatter}>STATUS</TableHeaderColumn>
                  <TableHeaderColumn width='175' isKey={true} dataField='username' headerAlign='center' columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign='center'>USERNAME</TableHeaderColumn>
                  <TableHeaderColumn width='160' dataField='namaLengkap' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>NAMA</TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='roleName' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>ROLE</TableHeaderColumn>
                  <TableHeaderColumn width='150' dataField='namaWilayah' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>WILAYAH</TableHeaderColumn>
                  <TableHeaderColumn width='180' dataField='alamat' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat} >ALAMAT</TableHeaderColumn>
                  <TableHeaderColumn width='110' dataField='noTelepon' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>TELEPON</TableHeaderColumn>
                  <TableHeaderColumn width='180' dataField='email' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>EMAIL</TableHeaderColumn>
                  <TableHeaderColumn width='90' dataField='createdby' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>CreatedBY</TableHeaderColumn>
                  {console.log(this.state.menuValue)}
                  {this.state.menu && this.state.menu.length > 0 && this.state.menu.filter(el => el.value === 1).length > 0 &&
                    <TableHeaderColumn width='90' dataField='isActive' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Is Active</TableHeaderColumn>
                  }
                  {this.state.menu && this.state.menu.length > 0 && this.state.menu.filter(el => el.value === 2).length > 0 &&
                    <TableHeaderColumn width='120' dataField='bank' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Bank</TableHeaderColumn>
                  }
                  {this.state.menu && this.state.menu.length > 0 && this.state.menu.filter(el => el.value === 3).length > 0 &&
                    <TableHeaderColumn width='120' dataField='no_rekening' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Rekening</TableHeaderColumn>
                  }
                  {this.state.menu && this.state.menu.length > 0 && this.state.menu.filter(el => el.value === 4).length > 0 &&
                    <TableHeaderColumn width='120' dataField='statusPekerjaan' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Status Pekerjaan</TableHeaderColumn>
                  }
                  {this.state.menu && this.state.menu.length > 0 && this.state.menu.filter(el => el.value === 5).length > 0 &&
                    <TableHeaderColumn width='100' dataField='npwp' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>NPWP</TableHeaderColumn>
                  }
                  {this.state.menu && this.state.menu.length > 0 && this.state.menu.filter(el => el.value === 6).length > 0 &&
                    <TableHeaderColumn width='90' dataField='status_npwp' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Status NPWP</TableHeaderColumn>
                  }
                  {this.state.menu && this.state.menu.length > 0 && this.state.menu.filter(el => el.value === 7).length > 0 &&
                    <TableHeaderColumn width='70' dataField='golongan' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Golongan</TableHeaderColumn>
                  }
                  {this.state.menu && this.state.menu.length > 0 && this.state.menu.filter(el => el.value === 8).length > 0 &&
                    <TableHeaderColumn width='100' dataField='statusPembayaran' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>Status Pembayaran</TableHeaderColumn>
                  }


                  {/* <TableHeaderColumn width='150' dataField='' headerAlign='center' dataAlign='center'>CREATED BY</TableHeaderColumn> */}
                </BootstrapTable>
              </div>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='1'>1</option>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div>
            </Paper>

            <Dialog fullScreen open={this.state.openAddUser} onClose={this.handleOpenCloseAddUser}>
              {/* <DialogTitle>Tambah User</DialogTitle> */}
              <DialogContent>
                <Box sx={{ width: "100%" }}>
                  <CreateUser handleOpenCloseAddUser={this.handleOpenCloseAddUser} value_priode={this.state.value_priode} />
                </Box>
              </DialogContent>
              {/* <DialogActions>
                <Button sx={{ marginRight: '5px' }} variant="contained"><ImFloppyDisk className='icon-brd' style={{ marginRight: '5px' }} /> Simpan</Button>
                <Button onClick={this.handleOpenCloseAddUser} sx={{ marginRight: '5px' }} color='error' variant="contained"><ImCross className='icon-brd' style={{ marginRight: '5px' }} /> Batal</Button>
              </DialogActions> */}
            </Dialog>

            <Dialog fullScreen open={this.state.openEditUser} onClose={this.handleOpenCloseEditUser}>
              {/* <DialogTitle>Edit User</DialogTitle> */}
              <DialogContent>
                <Box sx={{ width: "100%" }}>
                  <EditUser dataEdit={this.state.dataEdit} value_priode={this.state.value_priode} handleOpenCloseEditUser={this.handleOpenCloseEditUser} />
                </Box>
              </DialogContent>
              {/* <DialogActions>
                <Button sx={{ marginRight: '5px' }} variant="contained"><ImFloppyDisk className='icon-brd' style={{ marginRight: '5px' }} /> Simpan</Button>
                <Button onClick={this.handleOpenCloseEditUser} sx={{ marginRight: '5px' }} color='error' variant="contained"><ImCross className='icon-brd' style={{ marginRight: '5px' }} /> Batal</Button>
              </DialogActions> */}
            </Dialog>
          </BlockUi>
        </div>

        {/* DIalog ubah password  */}

        <Dialog open={this.state.changePassword} onCLose={this.handleOpenCloseChangePassword}>
          <DialogTitle>Ubah Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Username : {this.state.usn}
            </DialogContentText>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Grid item xs={4} style={{ display: 'inline-block' }}>
                <div >
                  <AiFillLock style={{ top: '2px', position: 'relative' }} />&nbsp; Password Baru
                </div>
              </Grid>
              <Grid item xs={8}>
                <FormControl sx={{ width: '80%' }} size="small" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    name="passwordBaru"
                    style={{ width: '100%' }}
                    //size="medium"
                    //id="outlined-adornment-password"
                    type={this.state.showPasswordBaru ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          //onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPasswordBaru ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>

            </Grid>

            <p></p>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Grid item xs={4} style={{ display: 'inline-block' }}>
                <div >
                  <AiFillLock style={{ top: '2px', position: 'relative' }} />&nbsp; Konfirmasi Password Baru
                </div>
              </Grid>
              <Grid item xs={8}>
                <FormControl sx={{ width: '80%' }} size="small" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    name="passwordBaruKonfirm"
                    style={{ width: '100%' }}
                    //size="medium"
                    //id="outlined-adornment-password"
                    type={this.state.showPasswordBaruKonfirm ? 'text' : 'password'}
                    value={this.state.passwordBaru}
                    onChange={this.handleChangePassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPasswordKonfirm}
                          //onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPasswordBaruKonfirm ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSavePassword} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Ubah Password</Button>

            <Button onClick={this.handleOpenCloseChangePassword} variant="outlined" color="error">Batal</Button>

          </DialogActions>
        </Dialog>

        <Dialog open={this.state.detailWilayah} onCLose={this.handleOpenCloseDetailWilayah}>
          <DialogTitle>DetailWilayah</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Username : {this.state.usn}
              &nbsp;||&nbsp;
              UserID : {this.state.userId}
            </DialogContentText>
            <p></p>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Grid item xs={8}>
                <FormControl sx={{ width: '80%' }} size="small" variant="outlined">
                  DATA WILAYAH
                </FormControl>
              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleOpenCloseDetailWilayah} variant="outlined" color="error">Batal</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default User;