import axios from 'axios';

let url = ''

if (process.env.REACT_APP_ENV === 'development') {
    url = "http://dev-pemutakhiran-service-bkkbn.apps.tkp.platform.lintasarta.net/"
}

if (process.env.REACT_APP_ENV === 'production') {
    url = "https://siga-api-gateway.bkkbn.go.id/modul-stunting-sigabaru"
}

export default axios.create({
    baseURL: url,
    headers: {
        // Authorization: localStorage.getItem('tokenMtg') !== 'null' ? 'Bearer ' + localStorage.getItem('tokenMtg') : '',
        // 'Authorization' : 'Basic YWRtaW46cGFzc3dvcmQ=',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        // 'user_key': '6ab0c0c4f7c40f3c7747329890cc5e78',
        'Content-Type': 'application/json',
    },
    responseType: 'json'
});
