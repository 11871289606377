import axios from 'axios';

// let url = "http://dev-pkportalauthorizeservice-bkkbn.apps.tkp.platform.lintasarta.net/daftar/"
let url = "http://localhost:3008"

export default axios.create({
    baseURL: url,
    headers: {
        Authorization: localStorage.getItem('tokenMtg') !== 'null' ? 'Bearer ' + localStorage.getItem('tokenMtg') : '',
        // 'Access-Control-Allow-Origin': 'http://localhost:3000',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        // 'Content-Type': 'application/json',
    },
    responseType: 'json'
});
