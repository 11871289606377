import React, { Component } from "react";
import { Select as MuiSelect, TextField, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, TableCell, Input } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIDashboard from "../../../services/APIDashboard";
import APIUpload from '../../../services/APIUpload';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
//import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';
import { ImPlay3, ImSearch, ImPrinter } from "react-icons/im";
import { HiOutlineDocumentText } from "react-icons/hi";

import { BsPencilSquare } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";

import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from "moment";
import APIPemutakhiran from "../../../services/APIPemutakhiran";
import Swal from 'sweetalert2';
import axios from 'axios';
//import couchdb
import cdb1 from '../../../services/cdb1.json';
import cdb2 from '../../../services/cdb2.json';
import cdb3 from '../../../services/cdb3.json';
import cdb4 from '../../../services/cdb4.json';
import cdbKab from '../../../services/cdbKab1.json';




const isNumber = (str) => /^[0-9]*$/.test(str);
const sp2d = (str) => /[^a-zA-Z0-9-/.@ ]/.test(str);
class ApprovalPendataan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            couchdb: "",
            USER_KEY_PG: process.env.REACT_APP_USER_KEY_PROXY_GATEWAY,

            panel1: true,
            sizePerPage: 10,
            sizePerPage2: 10,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],
            disAprrovalProv: false,

            // datas: [{'id_id': 0, 'sdm': 'Pendata', 'pendata':'Pendata A', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':48, 'KKdidata':'48', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': '', 'nokuitansi':'00012'},
            //         {'id_id': 1,'sdm': 'Pendata','pendata':'Pendata B', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':50, 'KKdidata':'50', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': '', 'nokuitansi':'00013'},
            //         {'id_id': 2,'sdm': 'Pendata','pendata':'Pendata C', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':51, 'KKdidata':'50', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 1, 'nokuitansi':'00014'},
            //         {'id_id': 3,'sdm': 'Pendata','pendata':'Pendata D', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 3, 'nokuitansi':'00015'},
            //         {'id_id': 4,'sdm': 'Manager Data','pendata':'Manager A', 'kecamatan':'ABC', 'kelurahan': '', 'jumlahRT': '100', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 2, 'nokuitansi':'00016'},
            //         {'id_id': 5,'sdm': 'Supervisor','pendata':'Supervisor A', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '20', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 1, 'nokuitansi':'00017'},
            //         {'id_id': 6,'sdm': 'Pendata','pendata':'Pendata E', 'kecamatan':'ABC', 'kelurahan': '001', 'jumlahRT': '1', 'targetKK':67, 'KKdidata':'55', 'jumlahDibayar':'144.000', 'pajak':'3.600', 'jumlahDiterima':'140.400', 'flag': 3, 'nokuitansi':'00018'},
            //     ],
            datas: [],
            datasView: [],
            namaProvinsi: '',
            namaKelurahan: '',
            namaKecamatan: '',
            namaKabupaten: '',
            created_by_md: '',
            created_by_mp: '',
            created_by_spv: '',
            created_date_md: '',
            created_date_mp: '',
            created_date_spv: '',
            datapromises: [],
            disButtonCari: false,
            disApprov: true,

            //Wilayah
            dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
            dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

            openRejectDialog: false,

            roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
            dt_sdm: [{ value: 1, label: 'Pendata', name: 5 }, { value: 2, label: 'Kader Formulir', name: 18 }, { value: 3, label: 'Operator', name: 10 }, { value: 4, label: 'Supervisor', name: 4 }, { value: 5, label: 'Manager Data', name: 6 }, { value: 6, label: 'Manager Pengelola', name: 3 }],
            dt_role: [{ value: 15, label: 'Pelaksana Kegiatan', name: 'Pelaksana Kegiatan' }, { value: 16, label: 'PPK', name: 'PPK' }, { value: 17, label: 'BPP', name: 'BPP' }],
            filterSDM: [], selectedSDM: null,
            filterRole: [], selectedRole: null, nameRole: '',
            disCetakKuitansi: false,
            openDetailRT: false,
            openCloseInputSP2D: false,
            openPreviewRekapRT: false,
            rowSDM: '',
            textCari: '',

            data_RT: [{ kode: ' 001', nama_rt: '001' }, { kode: '002', nama_rt: '002' }, { kode: '003', nama_rt: '003' }],

            selectedRows: [], rowDipilih: [],
            metode: [{ value: 'Tunai', label: 'Tunai' }, { value: 'Non-Tunai', label: 'Non Tunai' }],
            selectedMetode: [], metodeTampil: [],
            sp2d: '', tgl_sp2d: '',
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    componentDidMount() {
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.alamat_provinsi;
        console.log(idTingkatWilayah)
        if (idTingkatWilayah === 7) {
            this.setState({ disAprrovalProv: true })
        }
        let title2, text11, text21

        if (this.state.roleUserLogin === 15) { //pelaksana kegiatan
            const data_pelaksana = this.state.datas.filter(data => data.flag === 1)
            this.setState({ datas: data_pelaksana })
        } else if (this.state.roleUserLogin === 16) {//PPK
            const data_ppk = this.state.datas.filter(data => data.flag === 2)
            this.setState({ datas: data_ppk })
        } else if (this.state.roleUserLogin === 17) {
            const data_bendahara = this.state.datas.filter(data => data.flag === 3)
            this.setState({ datas: data_bendahara })
        }

        if (idTingkatWilayah == 0) {
            title2 = ''

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": 1,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0
                }
            }
            );

        } else if (idTingkatWilayah == 1) {
            title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 1,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": 0,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }


            )


        } else if (idTingkatWilayah == 2) {
            title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 2,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                },
                () => { this.getBackend(this.state.sendDatas) }

            )


        } else {
            title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan


            this.setState(
                {
                    sendDatas: {
                        "tingkatWilayahID": 3,
                        "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "kab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
                        "kec": 0,
                        "kel": 0,
                        "rw": 0,
                        "tahun": 0
                    }
                }
            )
        }

        this.setState({ title: title2 },
        )

        if (rest === false) { this.props.history.push('/401') }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }
                const wilProvinsi = rest[0].attributes.user.wilProvinsi
                const wilKabupaten = rest[0].attributes.user.wilKabupaten
                const wilKecamatan = rest[0].attributes.user.wilKecamatan
                const wilKelurahan = rest[0].attributes.user.wilKelurahan

                if (Object.keys(wilProvinsi).length > 0) {

                    const objProp = {
                        'value': wilProvinsi.id_provinsi,
                        'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
                    };

                    this.setState({ dt_prov: objProp },
                        () => {
                            if (this.state.auto_prov === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(objProp)
                            }
                        }
                    );
                    //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                //        alert(wilKabupaten.length)
                if (Object.keys(wilKabupaten).length > 0) {
                    //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    const objKab = {
                        'value': wilKabupaten.id_kabupaten,
                        'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
                    };

                    this.setState({ dt_kab: objKab },
                        () => {
                            if (this.state.auto_kab === true) {
                                console.log("objKab", objKab)
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kab(objKab)
                            }
                        }
                    );

                }
                if (Object.keys(wilKecamatan).length > 0) {
                    const objKec = {
                        'value': wilKecamatan.id_kecamatan,
                        'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
                    };

                    this.setState({ dt_kec: objKec },
                        () => {
                            if (this.state.auto_kec === true) {
                                console.log("objKec", objKec)
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kec(objKec)
                            }
                        }
                    );
                    //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }
                if (Object.keys(wilKelurahan).length > 0) {
                    const objKel = {
                        'value': wilKelurahan.id_kelurahan,
                        'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
                    };

                    this.setState({ dt_kel: objKel },
                        () => {
                            if (this.state.auto_des === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_des(objKel)
                            }
                        }
                    );


                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    //API (Wilayah)
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": e.value,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
            );

            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )


        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": 0,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
                , id_kecamatan: 0, id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": 0,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "idKec": e.value,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            });


            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
                , id_des: 0, id_rw: 0

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": 0,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": e.value,
                    "rw": 0,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
                , id_rw: 0

            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": 0,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {

            this.setState({
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": e.value,
                    "tahun": 0

                }
            }/*,
      () => {this.getBackend(this.state.sendDatas)} */
            );


            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
                sendDatas: {
                    "tingkatWilayahID": this.state.sendDatas.tingkatWilayahID,
                    "prov": this.state.sendDatas.prov,
                    "kab": this.state.sendDatas.kab,
                    "kec": this.state.sendDatas.kec,
                    "kel": this.state.sendDatas.kel,
                    "rw": 0,
                    "tahun": 0

                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_sdm = (e) => {
        if (e) {
            this.setState({
                filterSDM: e, selectedSDM: e.name,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterSDM: null, selectedSDM: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_role = (e) => {
        this.setState({ datas: [], selectedRows: [] })
        if (e) {
            this.setState({
                filterRole: e, selectedRole: e.value, nameRole: e.name, disButtonCari: true
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                filterRole: null, selectedRole: null, nameRole: '', disButtonCari: false
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleChangeInput = (e, tab) => {
        if (e.target.name) {
            if (isNumber(e.target.value)) {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {

            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    handlePageChange = (page, rowsPerPage) => {
        this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    }

    selectCouchdb = () => {
        let setCdb = ""
        let provId = this.state.id_provinsi
        console.log(provId, 'id_provinsi')
        let CDB1 = process.env.REACT_APP_COUCHDB_1_URL
        let CDB2 = process.env.REACT_APP_COUCHDB_2_URL
        let CDB3 = process.env.REACT_APP_COUCHDB_3_URL
        let CDB4 = process.env.REACT_APP_COUCHDB_4_URL

        cdb1.map(key => {
            if (+key.id_provinsi === +provId) {
                setCdb = CDB1
            }
        })

        cdb2.map(key => {
            if (+key.id_provinsi === +provId) {
                setCdb = CDB2
            }
        })

        cdb3.map(key => {
            if (+key.id_provinsi === +provId) {
                setCdb = CDB3
            }
        })

        cdb4.map(key => {
            if (+key.id_provinsi === +provId) {
                setCdb = CDB4
            }
        })
        return setCdb
    }


    handleSearch = () => {
        // this.setState({ selectedRows:  });
        let couchdb = this.selectCouchdb()
        this.setState({ selectedRows: [] })
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, kelurahan, id_rw, selectedSDM, filterSDM } = this.state

        const reqParam = {
            "provId": +id_provinsi,
            "kabId": id_kabupaten,
            "kecId": id_kecamatan,
            "kelId": id_des,
            // "rwId": id_rw,
            "roleId": selectedSDM
        }

        if (+id_provinsi !== 0 && selectedSDM !== null) {
            this.setState({ blocking: true })

            APIPemutakhiran.post("/portalpk/metodepembayaran/getMonitorDataTunggakanPembayaran", reqParam)
                .then((res) => {
                    this.setState({ blocking: false });
                    if (res.status === 200) {
                        this.setState(
                            {
                                datas: res.data.data, blocking: false, couchdb
                            }
                        )

                    } else {
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: 'Pencarian Data Gagal, Harap Coba lagi!',
                        })
                        this.setState({ blocking: false });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: 'Pencarian Data Gagal, Harap Coba lagi!',
                    })
                    this.setState({ blocking: false });
                });

        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Filter SDM harus dipilih serta filter wilayah harus dipilih minimal tingkat kabupaten',
                showConfirmButton: true
            })
            this.setState({ blocking: false })
        }

    }


    rupiahFormatter = (cell, row) => {
        var bilangan = cell;
        //console.log(cell)

        var number_string = bilangan.toString(),
            sisa = number_string.length % 3,
            rupiah = number_string.substr(0, sisa),
            ribuan = number_string.substr(sisa).match(/\d{3}/g);

        // console.log(ribuan)

        if (ribuan) {
            var separator = ''
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');

            return rupiah
        } else {
            return '0'
        }

    }


    buttonFormatter = (cell, row) => {

        if (this.state.roleUserLogin === 16 || this.state.selectedRole === 16) { // PPK 
            return <div className='divBtnTable'>
                <Tooltip title="Approve"><Button id={row.id} onClick={(e) => this.handleApprovePPKSingle(e, row, 'APPROVE')} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button></Tooltip>
                {/* <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleApproveRejectPPK(e, 'REJECT')} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip> */}
                <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleApprovePPKSingle(e, row, 'REJECT')} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>

                {/* <Tooltip title="Cetak Draft Kuitansi"><span><Button id={row.id}  className='btnTable' variant="outlined"><ImPrinter style={{ color: 'blue' }} /></Button></span></Tooltip> */}


            </div>
        } else if (this.state.roleUserLogin === 15 || this.state.selectedRole === 15) { //Pelaksana Kegiatan
            return <div className='divBtnTable'>
                <Tooltip title="Approve"><Button id={row.id} onClick={(e) => this.handleApproveRejectRowPL(e, row, 'APPROVE')} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button></Tooltip>
                <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleApproveRejectRowPL(e, row, 'REJECT')} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>
                {/* <Tooltip title="Cetak Draft Kuitansi"><span><Button id={row.id}   className='btnTable' variant="outlined"><ImPrinter style={{ color: 'blue' }} /></Button></span></Tooltip> */}


            </div>

        } else if (this.state.roleUserLogin === 17 || this.state.selectedRole === 17 || this.state.roleUserLogin === 19 || this.state.roleUserLogin === 99) { //bendahara

            return <div className='divBtnTable'>
                {/* <Tooltip title="Cetak Kuitansi"><span><Button id={row.id}   className='btnTable' variant="outlined"><ImPrinter style={{ color: 'blue' }} /></Button></span></Tooltip> */}
                {/* <Tooltip title="Approve"><Button id={row.id} onClick={(e) => this.handleApproveRejectRowPL(e, row, 'APPROVE')} className='btnTable' variant="outlined"><BsCheckCircleFill style={{ color: 'green' }} /></Button></Tooltip> */}
                <Tooltip title="Reject"><Button id={row.id} onClick={(e) => this.handleApproveRejectRowBPP(e, row, 'REJECT')} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button></Tooltip>


            </div>

        } else {
            return <div className='divBtnTable'>

            </div>
        }
    }

    previewFormatter = (cell, row) => {


        return <div className='divBtnTable'>
            <Tooltip title="View SK"><Button id={row.id} onClick={(e) => this.previewSK(e, row)} className='btnTable' variant="outlined"><AiOutlineFilePdf style={{ color: 'red' }} /></Button></Tooltip>
            {/* <Tooltip title="View Profil"><Button id={row.id} className='btnTable' variant="outlined"><CgProfile style={{ color: 'blue' }} /></Button></Tooltip> */}
            {/* {row.sdm === 'OPERATOR' || row.sdm === 'KADER' ? <></> : <Tooltip title="View Rekap RT"><Button id={row.id} onClick={(e) => this.viewRekapRT(e, row)} className='btnTable' variant="outlined"><HiOutlineDocumentText style={{ color: 'green' }} /></Button></Tooltip>}
             */}
            <Tooltip title="View Rekap RT"><Button id={row.id} onClick={(e) => this.viewRekapRT(e, row)} className='btnTable' variant="outlined"><HiOutlineDocumentText style={{ color: 'green' }} /></Button></Tooltip>


        </div>

    }

    previewSK = (e, row, action) => {
        this.setState({ blocking: true })
        let wilayah = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKecamatan.id_kecamatan
        const link = document.createElement("a");
        link.target = "_blank";

        let Data = new FormData();
        Data.append('idWilayah', row.idwilayahFile)
        Data.append('idFileTypeUpload', '2')
        Data.append('tingkatwilayah', '2')

        APIUpload.post('userpembiayaan/downloadFileUser', Data, {
            responseType: 'blob',
        })
            .then((res) => {
                console.log(res, "DATADOWNLOAD")
                if (res.status === 200) {
                    this.setState({ blocking: false })
                }

                link.href = URL.createObjectURL(
                    new Blob([res.data], { type: "application/pdf" })
                );

                link.click();

            })
            .catch((error) => {
                this.setState({ blocking: false });
                console.log(error, "dataaa ERROR");
            });
    }

    numberingFormat = (cell, row) => {

        const index = +this.state.datas.indexOf(row)
        const noUrut = index + 1
        return <div>{noUrut} </div>
    }

    numberingFormat2 = (cell, row) => {

        const index = +this.state.data_RT.indexOf(row)
        const noUrut = index + 1
        return <div>{noUrut} </div>
    }



    statusFormatter = (cell, row) => {
        if (+row.status_approval === 1) {
            return 'Pending PPK'
        } else if (+row.status_approval === 2) {
            return 'Pending Bendahara'
        } else if (+row.status_approval === 3) {
            return 'Completed'
        } else {
            return ''
        }
    }

    createdFormat = (cell, row) => {
        if ((row.pajak === 0 && row.golongan === 3 && row.pekerjaan === 'PNS')
            || (row.pajak === 0 && row.golongan === 4 && row.pekerjaan === 'PNS')
            || (row.pajak === 0 && row.golongan === 3 && row.pekerjaan === 'PNS')
            || (row.pajak === 0 && row.golongan === 4 && row.pekerjaan === 'PNS')
            || (row.pajak === 0 && row.golongan === 0 && row.pekerjaan === 'PNS')
            || (row.pekerjaan === null)) {
            return 'fc3021'
        } else {
            return ''
        }
    }

    createdFormatBPP = (cell, row) => {
        if ((row.jumlah_Pajak === 0 && row.golongan === 3 && row.pekerjaan === 'PNS')
            || (row.jumlah_Pajak === 0 && row.golongan === 4 && row.pekerjaan === 'PNS')
            || (row.jumlah_Pajak === 0 && row.golongan === 3 && row.pekerjaan === 'PNS')
            || (row.jumlah_Pajak === 0 && row.golongan === 4 && row.pekerjaan === 'PNS')
            || (row.jumlah_Pajak === 0 && row.golongan === 0 && row.pekerjaan === 'PNS')
            || (row.pekerjaan === null)) {
            return 'fc3021'
        } else {
            return ''
        }
    }

    rowStyleFormat = (row, rowIdx) => {
        console.log(row, rowIdx)
        return { backgroundColor: 'blue' };
    }

    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    handleChangeSizePerPage2 = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage2: x })
    }

    cellClick = (cell, row) => {

        return <div style={{ cursor: "pointer" }} onClick={(e) => this.viewDetail(e, row)}>
            {cell}
        </div>
    }

    viewDetail = (e, row) => {
        e.stopPropagation();
        console.log('rowwwwww', row)
        console.log('prov', this.state.provinsi)
        console.log('kab', this.state.kabupaten)
        this.setState({}, () => { this.openCloseDetailRT() })


    }

    openCloseDetailRT = (event, reason, row) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openDetailRT: !this.state.openDetailRT },
            // () => { if (!this.state.openAddKab) { this.clearForm() } }
            () => { }
        )

    }


    //inputSP2D

    handleChangeForm = (e) => {
        if (e.target.name === 'sp2d') {
            if (isNumber(e.target.value)) {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }

    }

    handleChangeFormSp2d = (e) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9-/.@ ]/g, '')
        });

    }

    sel_metode = (e) => {
        if (e) {
            this.setState({
                selectedMetode: e.value,
                metodeTampil: e
            });

        }
        else {
            this.setState({
                selectedMetode: null
            }
            )
        }
    }

    //dialog preview rekap RT
    viewRekapRT = (e, row) => {
        this.setState({ blocking: true });
        console.log(row)
        this.setState({ blocking: true }, () => { this.openClosePreviewRekapRT() })
        const reqParam = {
            "sdm": row.sdm,
            "userName": row.userName
        }

        if (row.sdm === 'SPV') {
            this.setState({ rowSDM: 'SPV' })

            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {

                    // console.log('RESSSS', res)
                    this.setState({ blocking: false });
                    if (res.status === 200) {

                        this.setState({
                            datasView: res.data.data,
                            namaProvinsi: res.data.data[0].namaProvinsi,
                            namaKabupaten: res.data.data[0].namaKabupaten,
                            namaKelurahan: res.data.data[0].namaKelurahan,
                            namaKecamatan: res.data.data[0].namaKecamatan,
                            created_by_md: res.data.data[0].created_by_md,
                            created_by_mp: res.data.data[0].created_by_mp,
                            created_by_spv: res.data.data[0].created_by_spv,
                            created_date_md: new Date(res.data.data[0].created_date_md).toISOString().substring(0, 10),
                            created_date_mp: new Date(res.data.data[0].created_date_mp).toISOString().substring(0, 10),
                            created_date_spv: new Date(res.data.data[0].created_date_spv).toISOString().substring(0, 10),
                            blocking: false

                        })
                    } else {
                        this.setState({ blocking: false });
                    }


                })
                .catch((error) => {
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        // text: 'Jaringan bermasalah.',
                        text: message,
                    })
                    this.setState({ blocking: false });
                    console.log(error, "DATA ERROR");
                })


        }
        else if (row.sdm === 'MD') {
            this.setState({ rowSDM: 'MD' })
            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {
                    let data = this.state.datasView

                    if (res.data.data && res.data.data.length > 0) {

                        res.data.data.forEach(el => {
                            const checkKelurahan = data.find(item => item.namaKelurahan === el.namaKelurahan)
                            console.log(checkKelurahan)
                            if (checkKelurahan) {
                                let yang_ada = checkKelurahan.yang_ada + el.yang_ada
                                let didata = checkKelurahan.didata + el.didata
                                let ditemui = checkKelurahan.ditemui + el.ditemui
                                let baru = checkKelurahan.baru + el.baru
                                let pindah = checkKelurahan.pindah + el.pindah
                                let meninggal = checkKelurahan.meninggal + el.meninggal
                                let tidak_ditemukan = checkKelurahan.tidak_ditemukan + el.tidak_ditemukan
                                let bercerai = checkKelurahan.bercerai + el.bercerai

                                const dt = {
                                    "namaProvinsi": el.namaProvinsi,
                                    "namaKabupaten": el.namaKabupaten,
                                    "namaKecamatan": el.namaKecamatan,
                                    "kodeKelurahan": el.kodeKelurahan,
                                    "namaKelurahan": el.namaKelurahan,
                                    "kodeRT": el.kodeRT,
                                    "nama_rt": el.nama_rt,
                                    "kodeRW": el.kodeRW,
                                    "nama_rw": el.nama_rw,
                                    "nama": el.nama,
                                    "yang_ada": yang_ada,
                                    "didata": didata,
                                    "ditemui": ditemui,
                                    "baru": baru,
                                    "pindah": pindah,
                                    "meninggal": meninggal,
                                    "tidak_ditemukan": tidak_ditemukan,
                                    "bercerai": bercerai,
                                    "status": el.status,
                                    "created_by_spv": el.created_by_spv,
                                    "created_date_spv": el.created_date_spv,
                                    "created_by_md": el.created_by_md,
                                    "created_date_md": el.created_date_md,
                                    "created_by_mp": el.created_by_mp,
                                    "created_date_mp": el.created_date_mp
                                }
                                data = data.map(el => (el.namaKelurahan === checkKelurahan.namaKelurahan ? Object.assign({}, el, dt) : el))

                                this.setState({
                                    datasView: data,
                                    namaProvinsi: data[0].namaProvinsi,
                                    namaKabupaten: data[0].namaKabupaten,
                                    namaKelurahan: data[0].namaKelurahan,
                                    namaKecamatan: data[0].namaKecamatan,
                                    created_by_md: data[0].created_by_md,
                                    created_by_mp: data[0].created_by_mp,
                                    created_by_spv: data[0].created_by_spv,
                                    created_date_md: new Date(data[0].created_date_md).toISOString().substring(0, 10),
                                    created_date_mp: new Date(data[0].created_date_mp).toISOString().substring(0, 10),
                                    created_date_spv: new Date(data[0].created_date_spv).toISOString().substring(0, 10),
                                    blocking: false
                                })

                            } else {
                                console.log('b')
                                data = data.concat(el)
                                this.setState({
                                    datasView: data,
                                    namaProvinsi: data[0].namaProvinsi,
                                    namaKabupaten: data[0].namaKabupaten,
                                    namaKelurahan: data[0].namaKelurahan,
                                    namaKecamatan: data[0].namaKecamatan,
                                    created_by_md: data[0].created_by_md,
                                    created_by_mp: data[0].created_by_mp,
                                    created_by_spv: data[0].created_by_spv,
                                    created_date_md: new Date(data[0].created_date_md).toISOString().substring(0, 10),
                                    created_date_mp: new Date(data[0].created_date_mp).toISOString().substring(0, 10),
                                    created_date_spv: new Date(data[0].created_date_spv).toISOString().substring(0, 10),
                                    blocking: false
                                })
                            }
                        })

                    } else {
                        this.setState({ blocking: false });
                    }
                })
                .catch((error) => {
                    this.setState({ blocking: false });
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: message,
                    })
                });
        } else if (row.sdm === 'MP') {
            this.setState({ rowSDM: 'MP' })
            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {
                    let data = this.state.datasView

                    if (res.data.data && res.data.data.length > 0) {

                        res.data.data.forEach(el => {
                            const checkKelurahan = data.find(item => item.namaKelurahan === el.namaKelurahan)
                            console.log(checkKelurahan)
                            if (checkKelurahan) {
                                let yang_ada = checkKelurahan.yang_ada + el.yang_ada
                                let didata = checkKelurahan.didata + el.didata
                                let ditemui = checkKelurahan.ditemui + el.ditemui
                                let baru = checkKelurahan.baru + el.baru
                                let pindah = checkKelurahan.pindah + el.pindah
                                let meninggal = checkKelurahan.meninggal + el.meninggal
                                let tidak_ditemukan = checkKelurahan.tidak_ditemukan + el.tidak_ditemukan
                                let bercerai = checkKelurahan.bercerai + el.bercerai

                                const dt = {
                                    "namaProvinsi": el.namaProvinsi,
                                    "namaKabupaten": el.namaKabupaten,
                                    "namaKecamatan": el.namaKecamatan,
                                    "kodeKelurahan": el.kodeKelurahan,
                                    "namaKelurahan": el.namaKelurahan,
                                    "kodeRT": el.kodeRT,
                                    "nama_rt": el.nama_rt,
                                    "kodeRW": el.kodeRW,
                                    "nama_rw": el.nama_rw,
                                    "nama": el.nama,
                                    "yang_ada": yang_ada,
                                    "didata": didata,
                                    "ditemui": ditemui,
                                    "baru": baru,
                                    "pindah": pindah,
                                    "meninggal": meninggal,
                                    "tidak_ditemukan": tidak_ditemukan,
                                    "bercerai": bercerai,
                                    "status": el.status,
                                    "created_by_spv": el.created_by_spv,
                                    "created_date_spv": el.created_date_spv,
                                    "created_by_md": el.created_by_md,
                                    "created_date_md": el.created_date_md,
                                    "created_by_mp": el.created_by_mp,
                                    "created_date_mp": el.created_date_mp
                                }
                                data = data.map(el => (el.namaKelurahan === checkKelurahan.namaKelurahan ? Object.assign({}, el, dt) : el))

                                this.setState({
                                    datasView: data,
                                    namaProvinsi: data[0].namaProvinsi,
                                    namaKabupaten: data[0].namaKabupaten,
                                    namaKelurahan: data[0].namaKelurahan,
                                    namaKecamatan: data[0].namaKecamatan,
                                    created_by_md: data[0].created_by_md,
                                    created_by_mp: data[0].created_by_mp,
                                    created_by_spv: data[0].created_by_spv,
                                    created_date_md: new Date(data[0].created_date_md).toISOString().substring(0, 10),
                                    created_date_mp: new Date(data[0].created_date_mp).toISOString().substring(0, 10),
                                    created_date_spv: new Date(data[0].created_date_spv).toISOString().substring(0, 10),
                                    blocking: false
                                })

                            } else {
                                console.log('b')
                                data = data.concat(el)
                                this.setState({
                                    datasView: data,
                                    namaProvinsi: data[0].namaProvinsi,
                                    namaKabupaten: data[0].namaKabupaten,
                                    namaKelurahan: data[0].namaKelurahan,
                                    namaKecamatan: data[0].namaKecamatan,
                                    created_by_md: data[0].created_by_md,
                                    created_by_mp: data[0].created_by_mp,
                                    created_by_spv: data[0].created_by_spv,
                                    created_date_md: new Date(data[0].created_date_md).toISOString().substring(0, 10),
                                    created_date_mp: new Date(data[0].created_date_mp).toISOString().substring(0, 10),
                                    created_date_spv: new Date(data[0].created_date_spv).toISOString().substring(0, 10),
                                    blocking: false
                                })
                            }
                        })

                    } else {
                        this.setState({ blocking: false });
                    }
                })
                .catch((error) => {
                    this.setState({ blocking: false });
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: message,
                    })
                });
        } else if (row.sdm === 'OPR') {
            this.setState({ rowSDM: 'OPR' })
            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {
                    let data = this.state.datasView

                    if (res.data.data && res.data.data.length > 0) {

                        res.data.data.forEach(el => {
                            const checkKelurahan = data.find(item => item.namaKelurahan === el.namaKelurahan)
                            console.log(checkKelurahan)
                            if (checkKelurahan) {
                                let yang_ada = checkKelurahan.yang_ada + el.yang_ada
                                let didata = checkKelurahan.didata + el.didata
                                let ditemui = checkKelurahan.ditemui + el.ditemui
                                let baru = checkKelurahan.baru + el.baru
                                let pindah = checkKelurahan.pindah + el.pindah
                                let meninggal = checkKelurahan.meninggal + el.meninggal
                                let tidak_ditemukan = checkKelurahan.tidak_ditemukan + el.tidak_ditemukan
                                let bercerai = checkKelurahan.bercerai + el.bercerai

                                const dt = {
                                    "namaProvinsi": el.namaProvinsi,
                                    "namaKabupaten": el.namaKabupaten,
                                    "namaKecamatan": el.namaKecamatan,
                                    "kodeKelurahan": el.kodeKelurahan,
                                    "namaKelurahan": el.namaKelurahan,
                                    "kodeRT": el.kodeRT,
                                    "nama_rt": el.nama_rt,
                                    "kodeRW": el.kodeRW,
                                    "nama_rw": el.nama_rw,
                                    "nama": el.nama,
                                    "yang_ada": yang_ada,
                                    "didata": didata,
                                    "ditemui": ditemui,
                                    "baru": baru,
                                    "pindah": pindah,
                                    "meninggal": meninggal,
                                    "tidak_ditemukan": tidak_ditemukan,
                                    "bercerai": bercerai,
                                    "status": el.status,
                                    "created_by_spv": el.created_by_spv,
                                    "created_date_spv": el.created_date_spv,
                                    "created_by_md": el.created_by_md,
                                    "created_date_md": el.created_date_md,
                                    "created_by_mp": el.created_by_mp,
                                    "created_date_mp": el.created_date_mp
                                }
                                data = data.map(el => (el.namaKelurahan === checkKelurahan.namaKelurahan ? Object.assign({}, el, dt) : el))

                                this.setState({
                                    datasView: data,
                                    namaProvinsi: data[0].namaProvinsi,
                                    namaKabupaten: data[0].namaKabupaten,
                                    namaKelurahan: data[0].namaKelurahan,
                                    namaKecamatan: data[0].namaKecamatan,
                                    created_by_md: data[0].created_by_md,
                                    created_by_mp: data[0].created_by_mp,
                                    created_by_spv: data[0].created_by_spv,
                                    created_date_md: new Date(data[0].created_date_md).toISOString().substring(0, 10),
                                    created_date_mp: new Date(data[0].created_date_mp).toISOString().substring(0, 10),
                                    created_date_spv: new Date(data[0].created_date_spv).toISOString().substring(0, 10),
                                    blocking: false
                                })

                            } else {
                                console.log('b')
                                data = data.concat(el)
                                this.setState({
                                    datasView: data,
                                    namaProvinsi: data[0].namaProvinsi,
                                    namaKabupaten: data[0].namaKabupaten,
                                    namaKelurahan: data[0].namaKelurahan,
                                    namaKecamatan: data[0].namaKecamatan,
                                    created_by_md: data[0].created_by_md,
                                    created_by_mp: data[0].created_by_mp,
                                    created_by_spv: data[0].created_by_spv,
                                    created_date_md: new Date(data[0].created_date_md).toISOString().substring(0, 10),
                                    created_date_mp: new Date(data[0].created_date_mp).toISOString().substring(0, 10),
                                    created_date_spv: new Date(data[0].created_date_spv).toISOString().substring(0, 10),
                                    blocking: false
                                })
                            }
                        })

                    } else {
                        this.setState({ blocking: false });
                    }
                })
                .catch((error) => {
                    this.setState({ blocking: false });
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: message,
                    })
                });
        } else if (row.sdm === 'PENDATA') {
            this.setState({ rowSDM: 'PENDATA' })

            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {

                    // console.log('RESSSS', res)
                    this.setState({ blocking: false });
                    if (res.status === 200) {

                        this.setState({
                            datasView: res.data.data,
                            namaProvinsi: res.data.data[0].namaProvinsi,
                            namaKabupaten: res.data.data[0].namaKabupaten,
                            namaKelurahan: res.data.data[0].namaKelurahan,
                            namaKecamatan: res.data.data[0].namaKecamatan,
                            created_by_md: res.data.data[0].created_by_md,
                            created_by_mp: res.data.data[0].created_by_mp,
                            created_by_spv: res.data.data[0].created_by_spv,
                            created_date_md: new Date(res.data.data[0].created_date_md).toISOString().substring(0, 10),
                            created_date_mp: new Date(res.data.data[0].created_date_mp).toISOString().substring(0, 10),
                            created_date_spv: new Date(res.data.data[0].created_date_spv).toISOString().substring(0, 10),
                            blocking: false

                        })
                    } else {
                        this.setState({ blocking: false });
                    }


                    console.log(this.state.datas, " DATA >>>>>>>>>")
                })
                .catch((error) => {
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: message,
                    })
                    this.setState({ blocking: false });
                    console.log(error, "DATA ERROR");
                })


        } else if (row.sdm === 'KADER') {
            this.setState({ rowSDM: 'KADER' })

            APIPemutakhiran.post('portalpk/metodepembayaran/getListRekapPembayaran', reqParam)
                .then((res) => {

                    // console.log('RESSSS', res)
                    this.setState({ blocking: false });
                    if (res.status === 200) {

                        this.setState({
                            datasView: res.data.data,
                            namaProvinsi: res.data.data[0].namaProvinsi,
                            namaKabupaten: res.data.data[0].namaKabupaten,
                            namaKelurahan: res.data.data[0].namaKelurahan,
                            namaKecamatan: res.data.data[0].namaKecamatan,
                            created_by_md: res.data.data[0].created_by_md,
                            created_by_mp: res.data.data[0].created_by_mp,
                            created_by_spv: res.data.data[0].created_by_spv,
                            created_date_md: new Date(res.data.data[0].created_date_md).toISOString().substring(0, 10),
                            created_date_mp: new Date(res.data.data[0].created_date_mp).toISOString().substring(0, 10),
                            created_date_spv: new Date(res.data.data[0].created_date_spv).toISOString().substring(0, 10),
                            blocking: false

                        })
                    } else {
                        this.setState({ blocking: false });
                    }


                    console.log(this.state.datas, " DATA >>>>>>>>>")
                })
                .catch((error) => {
                    let message = error;
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: message,
                    })
                    this.setState({ blocking: false });
                    console.log(error, "DATA ERROR");
                })

        }
    }

    //select row

    handleSelectRow = (cat, row, isSelect) => {
        this.setState({ disApprov: false });
        let data = this.state.datas
        let dataAll = []
        if (isSelect === true && cat === 'single') {
            this.setState({ selectedRows: this.state.selectedRows.concat(row) });
        } else if (isSelect === true && cat === 'all') {
            if (this.state.textCari !== '') {
                dataAll = this.state.datas.filter(el => el.userName === row.userName)
                this.setState({ selectedRows: dataAll, dataFilter: dataAll });
            } else {
                this.setState({ selectedRows: data });
            }
        } else if (cat === 'single') {
            var array = JSON.parse(JSON.stringify(this.state.selectedRows));
            var index = array.findIndex(el => el.id === row.id);
            if (index !== -1) {
                array.splice(index, 1);
                if (this.state.selectedRows?.length > 1) {
                    this.setState({ selectedRows: array, disApprov: false });
                } else {
                    this.setState({ selectedRows: array, disApprov: true });
                }
            }
        } else {
            this.setState({ selectedRows: [], disApprov: true });
        }


    }

    onSearchChange = (searchText, colInfos, multiColumnSearch) => {
        this.setState({ textCari: searchText })

    }

    render() {

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10000px',
            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRow('single', row, isSelect)
            },
            onSelectAll: (isSelect, rows, e) => {
                if (isSelect) {
                    this.handleSelectRow('all', rows, isSelect)
                    return this.state.datas.map(row => row.id)
                } else {
                    this.handleSelectRow('all', rows, isSelect)
                    return [];
                }
                // console.log('isSelect', isSelect);
                // console.log('rows', rows);
                // console.log('e', e);
            }

        };
        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
            onSearchChange: (searchText, colInfos, multiColumnSearch) => {
                this.onSearchChange(searchText, colInfos, multiColumnSearch)
            },
        };
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait" >
                <div className="content">
                    <div className="title-content">
                        <ImPlay3 className="icon-brd" />
                        Monitoring Data Tunggakan
                    </div>
                    <div className="body-content">
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Provinsi
                                                        <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kabupaten
                                                        <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kecamatan
                                                        <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kelurahan
                                                        <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        SDM
                                                        <Select options={this.state.dt_sdm} onChange={this.sel_sdm} value={this.filterSDM} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid item xs={12}>
                                                        <Button onClick={(e) => this.handleSearch()} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} />Cari</Button>



                                                        {/* <Button sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button> */}

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </AccordionDetails>
                            </Accordion>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} sx={{ mt: 3 }}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%', textAlign: 'center' }} size="small" >
                                                    <Box sx={{ lineHeight: 2 }}>Jumlah Yang diUpload</Box>
                                                    <TextField
                                                        disabled
                                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                        id="filled-required"
                                                        label=""
                                                        variant="standard"
                                                        value={this.state.totalupload}
                                                        onChange={this.handleChangeInput}
                                                        name="maksTargetKK"
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}></Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl sx={{ width: '100%', textAlign: 'center' }} size="small" >
                                                    <Box sx={{ lineHeight: 2 }}>Jumlah Yang Belum diUpload</Box>
                                                    <TextField
                                                        disabled
                                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                        id="filled-required"
                                                        label=""
                                                        variant="standard"
                                                        value={this.state.belumupload}
                                                        onChange={this.handleChangeInput}
                                                        name="targetKK"
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                        </Box>
                        <h3>Data Tunggakan {this.state.nameRole} </h3>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                            <Grid item xs={12} md={12}>

                            </Grid>
                        </Grid>

                        <Paper sx={{ width: "100%", boxShadow: "none" }}>


                            <div className="rekap-table">
                                <BootstrapTable
                                    keyField="id"
                                    data={this.state.datas}
                                    pagination={true}
                                    options={options}
                                // search={true}
                                // selectRow={selectRow}
                                // trStyle={this.rowStyleFormat}
                                >

                                    <TableHeaderColumn width="100" dataField="kecamatan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>KECAMATAN </TableHeaderColumn>
                                    <TableHeaderColumn width="100" dataField="kelurahan" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>KELURAHAN </TableHeaderColumn>
                                    {/* <TableHeaderColumn width="80" dataField="sdm" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>SDM </TableHeaderColumn> */}


                                    <TableHeaderColumn width="200" dataField="userName" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >USERNAME </TableHeaderColumn>
                                    <TableHeaderColumn width="200" dataField="namaLengkap" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >NAMA LENGKAP </TableHeaderColumn>

                                    {/* <TableHeaderColumn width="70" dataField="jumlah" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >JUMLAH RT </TableHeaderColumn> */}
                                    <TableHeaderColumn width="70" dataField="targetKk" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat}  >YANG ADA </TableHeaderColumn>
                                    <TableHeaderColumn width="70" dataField="kkDiData" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >YANG DIDATA</TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="jumlahDiBayar" headerAlign="center" dataAlign="right" columnClassName={this.createdFormat} dataFormat={this.rupiahFormatter} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >JUMLAH YG DIBAYARKAN </TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="jumlahPajak" headerAlign="center" dataAlign="right" columnClassName={this.createdFormat} dataFormat={this.rupiahFormatter} >PAJAK </TableHeaderColumn>
                                    <TableHeaderColumn width="80" dataField="jumlahDiTerima" headerAlign="center" dataAlign="right" columnClassName={this.createdFormat} dataFormat={this.rupiahFormatter} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >JUMLAH YG DITERIMA </TableHeaderColumn>
                                    {/* <TableHeaderColumn  width="120" dataField="" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.statusFormatter} >STATUS </TableHeaderColumn> */}
                                    {/* <TableHeaderColumn width="120" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.previewFormatter} >PREVIEW </TableHeaderColumn> */}




                                </BootstrapTable>


                            </div>
                            <div className="sizePerPage">
                                Size Per Page
                                <select
                                    name="category"
                                    value={this.state.sizePerPage}
                                    onChange={this.handleChangeSizePerPage}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value={this.state.datas?.length}>All</option>
                                </select>
                            </div>
                            <div className="totalData">
                                <b>Total Data: {this.state.datas?.length}</b>
                            </div>
                        </Paper>





                    </div>
                </div>
            </BlockUi >
        );
    }
}

export default ApprovalPendataan;
