import React from 'react'
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div>
        <p>404</p>
        <Link to="/">Let's go Back</Link>
    </div>
  )
}
