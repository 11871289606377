import axios from 'axios';

let url = ''

if (process.env.REACT_APP_ENV === 'development') {
    url = "http://report-portal-pk-bkkbn.apps.tkp.platform.lintasarta.net/"
}

if (process.env.REACT_APP_ENV === 'production') {
    // url = "http://report-portal-pk-bkkbn.apps.tkp.platform.lintasarta.net/"
    url = "https://pk23-portal-gw.bkkbn.go.id/report"
}

export default axios.create({
    baseURL: url,
    headers: {
        // Authorization: localStorage.getItem('tokenMtg') !== 'null' ? 'Bearer ' + localStorage.getItem('tokenMtg') : '',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Authorization': 'Basic YWRtaW46cGFzc3dvcmQ=',
        'user_key': '6ab0c0c4f7c40f3c7747329890cc5e78',
        'Content-Type': 'application/json',
    },
    responseType: 'json'
});
