import React, { Component } from 'react'
import { ImPlay3, ImSearch, ImPrinter } from 'react-icons/im';
import { FcSearch } from "react-icons/fc";
import { Tabs, Tab, Link, Select as MuiSelect, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIDashboard from "../../../services/APIDashboard";
import APIPemutakhiran from '../../../services/APIPemutakhiran';
import authServices from '../../../services/authServices';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import APIReport from '../../../services/APIReport';
import Swal from 'sweetalert2';
import moment from 'moment';
import APIMonitoringBaru from '../../../services/APIMonitoringBaru';

// import Table from 'react-bootstrap/Table'

class DetailStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panel1: true,
      sizePerPage: 10,
      title: '',
      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      text1: 'Provinsi',

      datas: [],
      dataKependudukan: [],
      dataKB: [],
      dataPK: [],
      filterData: [
        { value: 1, label: 'Valid', name: 'Valid' },
        { value: 2, label: 'Not Valid', name: 'NotValid' },
        { value: 3, label: 'Anomali', name: 'Anomali' },
        { value: 4, label: 'Anomali Terkonfirmasi', name: 'Anomali Terkonfirmasi' },
        { value: 5, label: 'Anulir', name: 'Anulir' },
        { value: 6, label: 'Received', name: 'Received' },
        { value: 7, label: 'User Not Exists', name: 'UserNotExists' },
      ],
      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,
      dt_pendata: [], pendata: [], id_pendata: 0,

      sttsPendataan: [],
      openDetail: false,
      idFrm: '',

      //datas: [],
      //selectDatas: [],
      sendDatas: {
        "provinsiId": 0,
        "kabupatenId": 0,
        "kecamatanId": 0,
        "kelurahanId": 0,
        "rwId": 0,
        "statusSensus": null,
        "pendata": null
      },

      paramPendata: {},
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
      valueTab: 0,
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }
  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      }, () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if (role === 1 || role === 99) {
          this.setState({
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
          })
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }

            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  setHeaderTable = () => {
    let { text1 } = this.state
    var headerColumns = [
      { width: '100', title: 'NO KK', dataField: "noKK", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: '150', title: 'Nama KK', dataField: "namaKK", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: 'Kelurahan', dataField: "#kelurahan", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: 'RW', dataField: "rw", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: 'RT', dataField: "rt", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: 'No Urut Rumah', dataField: "noUrutRumah", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: 'Pendata', dataField: "pendata", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', title: 'Tanggal Dibuat', dataField: "tglBuat", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '200', title: 'Alasan', dataField: "tglBuat", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '150', title: 'Action', dataField: "tglBuat", headerAlign: 'center', dataAlign: 'right', editable: false }
    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidMount() {
    this.setHeaderTable()
    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    let title2, text11, text21

    if (idTingkatWilayah == 0) {
      title2 = ''

      this.setState({
        sendDatas: {
          "provinsiId": 0,
          "kabupatenId": 0,
          "kecamatanId": 0,
          "kelurahanId": 0,
          "rwId": 0,
          "statusSensus": null,
          "pendata": null
        },
      })

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      this.setState({
        sendDatas: {
          "provinsiId": +rest[0].attributes.user.wilProvinsi.id_provinsi,
          "kabupatenId": 0,
          "kecamatanId": 0,
          "kelurahanId": 0,
          "rwId": 0,
          "statusSensus": null,
          "pendata": null
        },
      })



    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

      this.setState({
        sendDatas: {
          "provinsiId": +rest[0].attributes.user.wilProvinsi.id_provinsi,
          "kabupatenId": +rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "kecamatanId": 0,
          "kelurahanId": 0,
          "rwId": 0,
          "statusSensus": null,
          "pendata": null
        },
      })


    } else if (idTingkatWilayah === 3) {

      this.setState({
        sendDatas: {
          "provinsiId": +rest[0].attributes.user.wilProvinsi.id_provinsi,
          "kabupatenId": +rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "kecamatanId": +rest[0].attributes.user.wilKecamatan.id_kecamatan,
          "kelurahanId": 0,
          "rwId": 0,
          "statusSensus": null,
          "pendata": null
        },
      })
    } else if (idTingkatWilayah === 4) {
      this.setState({
        sendDatas: {
          "provinsiId": +rest[0].attributes.user.wilProvinsi.id_provinsi,
          "kabupatenId": +rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "kecamatanId": +rest[0].attributes.user.wilKecamatan.id_kecamatan,
          "kelurahanId": +rest[0].attributes.user.wilKelurahan.id_kelurahan,
          "rwId": 0,
          "statusSensus": null,
          "pendata": null
        },
      })
    } else {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan


      // this.setState(
      //   {
      //     sendDatas:{
      //       "tingkatWilayahID":3,
      //       "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
      //       "kab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
      //       "kec": 0,
      //       "kel": 0,
      //       "rw": 0,
      //       "tahun": 0
      //     }
      //     }
      // )
    }

    this.setState({ title: title2 },
      () => { this.setHeaderTable() }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
                const paramPendata1 = { roleId: 5, idProvinsi: wilProvinsi.id_provinsi, periodeSensus: this.state.value_priode }
                this.setState({ paramPendata: paramPendata1 })
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {

                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
                const paramPendata1 = { roleId: 5, idProvinsi: wilProvinsi.id_provinsi, periodeSensus: this.state.value_priode, idKabupaten: wilKabupaten.id_kabupaten }
                this.setState({ paramPendata: paramPendata1 })
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {

                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
                const paramPendata1 = { roleId: 5, idProvinsi: wilProvinsi.id_provinsi, periodeSensus: this.state.value_priode, idKabupaten: wilKabupaten.id_kabupaten, idKecamatan: wilKecamatan.id_kecamatan }
                this.setState({ paramPendata: paramPendata1 })
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
                const paramPendata1 = {
                  roleId: 5, idProvinsi: wilProvinsi.id_provinsi, periodeSensus: this.state.value_priode, idKabupaten: wilKabupaten.id_kabupaten, idKecamatan: wilKecamatan.id_kecamatan,
                  idKelurahan: wilKelurahan.id_kelurahan
                }
                this.setState({ paramPendata: paramPendata1 })

              }
            }
          );


          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }
  //API (Wilayah)
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get pendata
  setPendata(idProv, idKab, idKec, idKel, idRw) {
    this.setState({ blocking: true });
    this.setState({ dt_pendata: [] });
    var newData = [];
    authServices.get('daftar/listDaftarUser', {
      params: {
        ...this.state.paramPendata,
        periodeSensus: 2024
      }
    })
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id, 'label': data.username };
          newData.push(obj);
        });
        this.setState({ dt_pendata: newData, blocking: false },
          () => {
            // const vl = this.state.dt_pendata.filter(item => parseInt(item.value) === parseInt(idKec))
            // this.sel_kec(vl[0])

          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      //handleSearch
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "provinsiId": +e.value,

        },
        paramPendata: {
          ...this.state.paramPendata, idProvinsi: +e.value, roleId: 5
        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
      );

      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,

            })
            this.setKab(this.state.id_provinsi, null);
            this.setPendata(this.state.id_provinsi, null, null, null, null)
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )


    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "provinsiId": 0,
          "kabupatenId": 0,
          "kecamatanId": 0,
          "kelurahanId": 0,
          "rwId": 0,

        },
        paramPendata: {}
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "kabupatenId": +e.value,
        },
        paramPendata: {
          ...this.state.paramPendata, idKabupaten: +e.value
        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
        , id_kecamatan: 0, id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
            this.setPendata(this.state.id_provinsi, this.state.id_kabupaten, null, null, null)
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "kabupatenId": 0,
          "kecamatanId": 0,
          "kelurahanId": 0,
          "rwId": 0,

        },
        paramPendata: {
          ...this.state.paramPendata,
          idKabupaten: null,
          idKecamatan: null,
          idKelurahan: null,
          idRw: null
        }

      },
        () => {
          if (e === null) {
            this.setPendata(this.state.id_provinsi, null, null, null, null)

            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "kecamatanId": +e.value,
        },
        paramPendata: {
          ...this.state.paramPendata,
          idKecamatan: +e.value
        }
      });


      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
        , id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
            this.setPendata(this.state.id_provinsi, this.state.id_kabupaten, this.state.id_kecamatan, null, null)

          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "kecamatanId": 0,
          "kelurahanId": 0,
          "rwId": 0,

        },
        paramPendata: {
          ...this.state.paramPendata,
          idKecamatan: null,
          idKelurahan: null,
          idRw: null
        }

      },
        () => {
          if (e === null) {
            this.setPendata(this.state.id_provinsi, this.state.id_kabupaten, null, null, null)

            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "kelurahanId": +e.value,

        },
        paramPendata: {
          ...this.state.paramPendata,
          idKelurahan: +e.value
        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
        , id_rw: 0

      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
            this.setPendata(this.state.id_provinsi, this.state.id_kabupaten, this.state.id_kecamatan, this.state.id_des, null)

          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "kelurahanId": 0,
          "rwId": 0,

        },
        paramPendata: {
          ...this.state.paramPendata,
          idKelurahan: null,
          idRw: null
        }
      },
        () => {
          if (e === null) {
            this.setPendata(this.state.id_provinsi, this.state.id_kabupaten, this.state.id_kecamatan, null, null)

            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "rwId": +e.value,

        },
        paramPendata: {
          ...this.state.paramPendata,
          idRw: +e.value
        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,

      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            this.setPendata(this.state.id_provinsi, this.state.id_kabupaten, this.state.id_kecamatan, this.state.id_des, this.state.id_rw)

            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "rwId": 0,

        },
        paramPendata: {
          ...this.state.paramPendata,
          idRw: null
        }
      },
        () => {
          if (e === null) {
            this.setPendata(this.state.id_provinsi, this.state.id_kabupaten, this.state.id_kelurahan, null, null)

            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  sel_status = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          statusSensus: e.name
        },
        sttsPendataan: e,
      })
    } else {
      this.setState({
        sttsPendataan: null,
        sendDatas: {
          ...this.state.sendDatas,
          statusSensus: null
        }
      })
    }

  }

  sel_pendata = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "pendata": e.label
        }
      })

      this.setState({
        pendata: e, id_pendata: e.value

      },
        // () => {
        //     if (this.state.id_kecamatan && this.state.auto_des === false) {
        //         this.setState({
        //             desa: null, rw: null,
        //             rt: null, dis_des: false, dis_rw: true, dis_rt: true
        //         })
        //         this.setDes(this.state.id_kecamatan, null);
        //     }
        //     if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
        //         this.setState({ flagNumber: this.state.flagNumber - 1 })
        //     }
        // }
      )
    } else {
      this.setState({
        pendata: null, id_pendata: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "pendata": null,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )

    }
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        //text1 = 'KODE RT'
        text1 = 'NAMA RT'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          //text1 = 'KODE RW'
          text1 = 'NAMA RW'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            //text1 = 'KODE KELURAHAN'
            text1 = 'NAMA KELURAHAN'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              //text1 = 'KODE KECAMATAN'
              text1 = 'NAMA KECAMATAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                //text1 = 'KODE KABUPATEN'
                text1 = 'NAMA KABUPATEN'
              }
              else {
                title = ''
                //text1 = 'KODE PROVINSI'
                text1 = 'NAMA PROVINSI'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1 },
      () => { this.setHeaderTable() }
    )
  }

  handleSearch = () => {
    // 
    // 
    if (this.state.id_kecamatan !== 0) {
      this.setLabel();
      this.setState({ blocking: true });
      APIMonitoringBaru.post(
        "/portalpk/monitoring/getListStatusPendataanDetail?periodeSensus=" + this.state.value_priode, this.state.sendDatas)
        .then((res) => {
          this.setState({ blocking: false });
          if (res.status === 200) {
            this.setState({
              datas: res.data.data,
            });
          }
        })
        .catch((error) => {
          this.setState({ blocking: false });

        });

    } else {
      Swal.fire({
        title: 'Info',
        icon: 'info',
        text: 'Filter wilayah minimal dipilih tingkat Kecamatan',
      });
    }


  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  buttonFormatter = (cell, row) => {
    if (row.status_sensus === 'Valid') {
      return <div className='divBtnTable'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            {/* <div style={{ color: 'green' }}><b>Valid</b></div> */}
            <Tooltip title="valid">
              <Chip label="valid" color="success" size="small" />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="View"><Button id={row.id} onClick={(e) => this.handleViewDetail(e, row)} className='btnTable' variant="outlined"><FcSearch style={{ color: 'blue' }} /></Button></Tooltip>
          </Grid>

        </Grid>

      </div>
    } else if (row.status_sensus === 'Not Valid' || row.status_sensus === 'NotValid') {
      return <div className='divBtnTable'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            {/* <div style={{ color: 'red' }}><b>Tidak Valid</b></div> */}
            <Tooltip title="not valid">
              <Chip label="not valid" color="error" size="small" />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="View"><Button id={row.id} onClick={(e) => this.handleViewDetail(e, row)} className='btnTable' variant="outlined"><FcSearch style={{ color: 'blue' }} /></Button></Tooltip>
          </Grid>

        </Grid>

      </div>
    } else if (row.status_sensus === 'Anomali Terkonfirmasi' || row.status_sensus === 'AnomaliTerkonfirmasi') {
      return <div className='divBtnTable'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            {/* <div style={{ color: 'red' }}><b>Tidak Valid</b></div> */}
            <Tooltip title="anomali terkonfirmasi">
              <Chip label='anomali terkonfirmasi' color="warning" size='small' />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="View"><Button id={row.id} onClick={(e) => this.handleViewDetail(e, row)} className='btnTable' variant="outlined"><FcSearch style={{ color: 'blue' }} /></Button></Tooltip>
          </Grid>

        </Grid>

      </div>

    } else if (row.status_sensus === 'Anulir') {
      return <div className='divBtnTable'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            {/* <div style={{ color: 'red' }}><b>Tidak Valid</b></div> */}
            <Tooltip title="anulir">
              <Chip label='anulir' color="secondary" size='small' />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="View"><Button id={row.id} onClick={(e) => this.handleViewDetail(e, row)} className='btnTable' variant="outlined"><FcSearch style={{ color: 'blue' }} /></Button></Tooltip>
          </Grid>

        </Grid>

      </div>
    } else if (row.status_sensus === 'Received') {
      return <div className='divBtnTable'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            {/* <div style={{ color: 'red' }}><b>Tidak Valid</b></div> */}
            <Tooltip title="received">
              <Chip label='received' color="info" size='small' />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="View"><Button id={row.id} onClick={(e) => this.handleViewDetail(e, row)} className='btnTable' variant="outlined"><FcSearch style={{ color: 'blue' }} /></Button></Tooltip>
          </Grid>

        </Grid>

      </div>
    } else if (row.status_sensus === 'UserNotExists') {
      return <div className='divBtnTable'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            {/* <div style={{ color: 'red' }}><b>Tidak Valid</b></div> */}
            <Tooltip title="user not exists">
              <Chip label='user not exists' color="error" size='small' />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="View"><Button id={row.id} onClick={(e) => this.handleViewDetail(e, row)} className='btnTable' variant="outlined"><FcSearch style={{ color: 'blue' }} /></Button></Tooltip>
          </Grid>

        </Grid>

      </div>
    } else if (row.status_sensus === 'Anomali') {
      return <div className='divBtnTable'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            {/* <div style={{ color: 'red' }}><b>Tidak Valid</b></div> */}
            <Tooltip title="anomali">
              <Chip label='anomali' color="default" size='small' />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="View"><Button id={row.id} onClick={(e) => this.handleViewDetail(e, row)} className='btnTable' variant="outlined"><FcSearch style={{ color: 'blue' }} /></Button></Tooltip>
          </Grid>

        </Grid>

      </div>
    } else {
      return <div className='divBtnTable'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            <></>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="View"><Button id={row.id} onClick={(e) => this.handleViewDetail(e, row)} className='btnTable' variant="outlined"><FcSearch style={{ color: 'blue' }} /></Button></Tooltip>
          </Grid>

        </Grid>

      </div>

    }

  }

  dateFormatter = (cell, row) => {
    if (cell) {
      const date = cell
      const format = 'DD-MM-YYYY'
      const tgl = moment(date).format(format)
      return tgl
    } else {
      return ''
    }

  }

  handleCetak = (e) => {
    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;
    //this.setJudulLaporan()

    let judul_lap, kode_lap
    //const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      kode_lap = this.state.rt.kode
      judul_lap = this.state.rt.namaWil
    } else {
      kode_lap = ''
      judul_lap = ''
      if (id_rw !== 0) {
        kode_lap = this.state.rw.kode
        judul_lap = this.state.rw.namaWil
      } else {
        kode_lap = ''
        judul_lap = ''
        if (id_des !== 0) {
          kode_lap = this.state.desa.kode
          judul_lap = this.state.desa.namaWil
        } else {
          kode_lap = ''
          judul_lap = ''
          if (id_kecamatan !== 0) {
            kode_lap = this.state.kecamatan.kode
            judul_lap = this.state.kecamatan.namaWil
          } else {
            kode_lap = ''
            judul_lap = ''
            if (id_kabupaten !== 0) {
              kode_lap = this.state.kabupaten.kode
              judul_lap = this.state.kabupaten.namaWil
            } else {
              kode_lap = ''
              judul_lap = ''
              if (id_provinsi !== 0) {
                kode_lap = this.state.provinsi.kode
                judul_lap = this.state.provinsi.namaWil
              } else {
                kode_lap = ''
                judul_lap = ' Tingkat Nasional'
              }
            }
          }
        }
      }
    }

    this.setState({ judul_laporan: judul_lap, kode_laporan: kode_lap })

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#4ea842',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#FF8000',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      //let urlCetakPdf = url +'%22ketWilayah%22%3A%22'+'tes' + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C'+ '%22title%22%3A%22' +this.state.title+ '%22%2C' +'%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'


      //viewReport?filterPencarian=%7B%22provinsiId%22%3A%221%22%2C%22kabupatenId%22%3A%227%22%2C%22kecamatanId%22%3A%224155%22%2C%22ketWilayah%22%3A%22Nama%20wilayah%20inputan%22%2C%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D

      //{"provinsiId":"1","kabupatenId":"7","kecamatanId":"4155","ketWilayah":"Nama wilayah inputan","reportName":"TABEL1","reportType":"pdf","reportCategory":"MasterWilayah"}

      //let coba =''
      //coba = decodeURI(this.state.judul_laporan)
      let urlCetakPdf = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      let urlCetakExcel = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data Tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // 
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            // 
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data Tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // 
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })

    e.preventDefault()



    // try{
    //     reportService.get(url, { responseType: 'blob' })
    //     .then(res => {
    //         // 
    //         if (res.status === 200) {
    //             const content = res.headers['content-type'];
    //             download(res.data, 'Rekapitulasi Data Keluarga.' + extFile, content)
    //             this.setState({ blocking: false })
    //         } else {
    //             Swal.fire({
    //                 title: 'Info!',
    //                 icon: 'info',
    //                 text: 'Data Tidak ditemukan.',
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             })
    //             this.setState({ blocking: false });
    //         }
    //     }).catch((error) => {
    //         // 
    //         let message = error;
    //         Swal.fire({
    //             title: 'Error!',
    //             icon: 'warning',
    //             // text: 'Jaringan bermasalah.',
    //             text: message,
    //         })
    //         this.setState({ blocking: false });
    //     });
    // }catch(error){
    //     let message = error;
    //     Swal.fire({
    //         title: 'Error!',
    //         icon: 'warning',
    //         // text: 'Jaringan bermasalah.',
    //         text: message,
    //     })
    //     this.setState({ blocking: false });
    // }

    // } else {
    //     Swal.fire({
    //         title: 'Info!',
    //         icon: 'info',
    //         text: 'Wilayah harus dimasukkan minimal Desa/Kelurahan',
    //         showConfirmButton: false,
    //         timer: 2000
    //     })
    //     this.setState({ blocking: false });
    // }
  }

  handleViewDetail = (e, row) => {
    e.stopPropagation();

    this.setState({ idFrm: row.id_frm }, () => { this.handleOpenCloseViewDetail() })


  }


  handleOpenCloseViewDetail = (event, reason, row) => {


    if (reason && reason === "backdropClick")
      return;

    APIMonitoringBaru.get("portalpk/monitoring/getListStatusPendataanDetailKK?idFrm=" + this.state.idFrm + '&periodeSensus=' + this.state.value_priode)
      .then((res) => {
        this.setState({ blocking: false });

        if (res.status === 200) {
          this.setState(
            {
              dataKependudukan: res.data.data
            }
          )
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });

      });

    this.setState({ openDetail: !this.state.openDetail },
      // () => { if (!this.state.openAddKab) { this.clearForm() } }
      () => { }
    )


    APIMonitoringBaru.get("/portalpk/monitoring/getListStatusPendataanDetailKB?idFRM=" + this.state.idFrm + '&periodeSensus=' + this.state.value_priode)
      .then((res) => {
        this.setState({ blocking: false });

        if (res.status === 200) {
          this.setState(
            {
              dataKB: res.data.data
            }
          )
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });

      });

    this.setState({ openDetail: !this.state.openDetail },
      // () => { if (!this.state.openAddKab) { this.clearForm() } }
      () => { }
    )

    APIMonitoringBaru.get("/portalpk/monitoring/getListStatusPendataanDetailPK?idFRM=" + this.state.idFrm + '&periodeSensus=' + this.state.value_priode)
      .then((res) => {
        this.setState({ blocking: false });

        if (res.status === 200) {
          this.setState(
            {
              dataPK: res.data.data
            }
          )
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });

      });

    this.setState({ openDetail: !this.state.openDetail },
      // () => { if (!this.state.openAddKab) { this.clearForm() } }
      () => { }
    )


  }

  //tab di view detail
  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChangeTab = (event, newValue) => {
    this.setState({ valueTab: newValue });
  }

  render() {
    // )
    const { dataKB, dataPK } = this.state
    const options = {
      page: 1,
      sizePerPageList: [{
        text: '1', value: 1
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
    };

    const mapKb6 = [
      "ingin hamil/anak",
      "Tidak tahu tentang KB",
      "alasan kesehatan",
      "efek samping/kegagalan KB",
      "tempat pelayanan jauh",
      "alat/obat/cara KB Tidak tersedia",
      "biaya mahal",
      "Tidak ada alat/obat/cara KB yang cocok",
      "suami/keluarga menolak",
      "alasan agama",
      "Tidak ada petugas pelayanan KB",
      "baru melahirkan",
      "6 bulan terakhir Tidak melakukan hubungan suami istri",
      "6 bulan terakhir Tidak menstruasi",
      "Tidak subur/mandul/lama menikah minimal 5 tahun dan belum memiliki anak",
      "menopause"
    ];

    const mapKb7 = [
      "MOW/steril wanita",
      "MOP/steril pria",
      "IUD/spiral/AKDR",
      "implant/susuk",
      "suntik",
      "pil",
      "kondom",
      "MAL",
      "tradisional"
    ];

    const mapKb8 = [
      "RS pemerintah/TNI/Polri",
      "RS swasta",
      "puskesmas /klinik TNI/Polri",
      "klinik swasta",
      "praktik dokter",
      "pustu/pusling/bidan desa",
      "praktik mandiri bidan",
      "mobil pelayanan KB",
      "toko obat/apotik",
      "lainnya"
    ];

    const mapYesNo = {
      null: '-',
      true: 'ya',
      false: 'Tidak',
      0: 'Tidak',
      1: 'ya'
    };

    const monthNames = [
      '-', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
      'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];

    const getAnswer = (value) => {
      switch (value) {
        case 1:
          return 'Ya';
        case 2:
          return 'Tidak';
        default:
          return 'Tidak Berlaku';
      }
    };

    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Status Pendataan (Detail)</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Status Pendataan
                            <Select options={this.state.filterData} isClearable onChange={this.sel_status} value={this.state.sttsPendataan} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Pendata
                            <Select options={this.state.dt_pendata} isClearable onChange={this.sel_pendata} value={this.state.pendata} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                          <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>

                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                        <Grid item xs={12}>
                          <Button onClick={this.handleSearch} sx={{ width: '30%', minHeight: '36px', marginTop: '22px', marginRight:'5px' }} variant="contained">Cari</Button>
                          <Button  sx={{ width: '34%', minHeight: '36px', marginTop: '22px', backgroundColor:'green', marginRight:'5px', ':hover': {bgcolor: 'green', color: 'white'} }} variant="contained">Cetak .xlsx</Button>
                          <Button  sx={{ width: '32%', minHeight: '36px', marginTop: '22px', backgroundColor:'#DC143C', ':hover': {bgcolor: '#DC143C', color: 'white'} }} variant="contained">Cetak .pdf</Button>
                        </Grid> */}
                        {/* <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl> */}
                        {/* </Grid> */}
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={12}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '5px' }} variant="contained">Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Status Pendataan (Detail) {this.state.title}</h3>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <div className='rekap-table'>
                <BootstrapTable data={this.state.datas}
                  pagination={true}
                  options={options}
                  search={true}
                >
                  <TableHeaderColumn width="130" dataField="no_kk" isKey={true} headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>NO KK </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="nama_kk" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>NAMA KK </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="kelurahan" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>KELURAHAN </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="rw" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>RW </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="rt" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>RT </TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="no_urutrmh" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>NO RUMAH</TableHeaderColumn>
                  <TableHeaderColumn width="150" dataField="pendata" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>PENDATA </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="tanggal_dibuat" headerAlign="center" dataFormat={this.dateFormatter} dataAlign="center" >TANGGAL DIBUAT</TableHeaderColumn>
                  <TableHeaderColumn width="180" dataField="alasan" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >ALASAN</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField="" headerAlign="center" dataAlign="center" dataFormat={this.buttonFormatter}>VIEW</TableHeaderColumn>
                </BootstrapTable>
              </div>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value={this.state.datas?.length}>All</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas?.length}</b></div>
            </Paper>

            <Dialog open={this.state.openDetail} onClose={this.handleOpenCloseViewDetail}
              fullWidth maxWidth>
              <DialogContent>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={this.state.valueTab} onChange={this.handleChangeTab} aria-label="basic tabs example">
                      <Tab label="Data KK" {...this.a11yProps(0)} />
                      <Tab label="Data KB" {...this.a11yProps(1)} />
                      <Tab label="Data PK" {...this.a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <div role="tabpanel" hidden={this.state.valueTab !== 0}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                      <Grid item xs={12} md={12}>

                        <Paper sx={{ width: '100%', boxShadow: 'none' }}>
                          <div className='rekap-table'>
                            <BootstrapTable data={this.state.dataKependudukan}
                              pagination={true}
                              options={options}
                              search={true}
                            >
                              <TableHeaderColumn width="125" dataField="nik_agt_klg" isKey={true} headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>NO KK </TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="nama_agt_klg" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>NAMA </TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="jenis_kelamin" headerAlign="center" dataAlign="center" >JENIS KELAMIN </TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="tgl_lahir" headerAlign="center" dataAlign="center" dataFormat={this.dateFormatter} >TGL LAHIR </TableHeaderColumn>
                              <TableHeaderColumn width="80" dataField="usia_kawin" headerAlign="center" dataAlign="center" >USIA KAWIN PERTAMA </TableHeaderColumn>
                              <TableHeaderColumn width="80" dataField="memiliki_akta" headerAlign="center" dataAlign="center" >AKTA KELAHIRAN</TableHeaderColumn>
                              <TableHeaderColumn width="150" dataField="hubungan_dg_kk" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>HUBUNGAN DGN KEPALA KELUARGA </TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="kd_ibu_kandung" headerAlign="center" dataAlign="center" >IBU KANDUNG</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="id_agama" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >AGAMA</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="jns_pekerjaan" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>JENIS PEKERJAAN</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="status_pekerjaan" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>STATUS PEKERJAAN</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="jns_pendidikan" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>PENDIDIKAN</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="kepesertaan_jkn" headerAlign="center" dataAlign="center" >KEPESERTAAN JKN</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="disabilitas" headerAlign="center" dataAlign="center" >DISABILITAS</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField="mengurus_diri" headerAlign="center" dataAlign="center" >MENGURUS DIRI</TableHeaderColumn>


                              <TableHeaderColumn width="120" dataField="mutasi_anggota_keluarga" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>MUTASI ANGGOTA KELUARGA</TableHeaderColumn>


                            </BootstrapTable>
                          </div>
                          <div className='sizePerPage'>
                            Size Per Page
                            <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                              <option value='10'>10</option>
                              <option value='20'>20</option>
                              <option value={this.state.dataKependudukan?.length}>All</option>
                            </select>
                          </div>
                          <div className='totalData'><b>Total Data: {this.state.dataKependudukan?.length}</b></div>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                  <div role="tabpanel" hidden={this.state.valueTab !== 1}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                      <Grid item xs={12} md={12}>
                        <Paper sx={{ width: '100%', boxShadow: 'none' }}>
                          <div className='rekap-table'>
                            <table className='table-kb'>
                              <thead>
                                <tr>
                                  <th width="5">NO</th>
                                  <th width="200">PERTANYAAN</th>
                                  <th width="150">JAWABAN</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className='nomor'>1.</td>
                                  <td>Sudah berapa kali ibu melahirkan?</td>
                                  <td>{dataKB?.kb1}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>1. Berapa jumlah anak lahir hidup</td>
                                  <td>Laki-laki : {dataKB?.kb1_1anak_lahir_hidup_lk}, Perempuan : {dataKB?.kb1_1anak_lahir_hidup_pr}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>2. Berapa jumlah anak masih hidup</td>
                                  <td>Laki-laki : {dataKB?.kb1_2anak_masih_hidup_lk}, Perempuan : {dataKB?.kb1_2anak_masih_hidup_pr}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>2.</td>
                                  <td>Menurut ibu, berapa jumlah anak ideal yang diinginkan</td>
                                  <td>{dataKB?.kb2}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>3.</td>
                                  <td>Apakah ibu saat ini sedang hamil?</td>
                                  <td>{dataKB ? mapYesNo[dataKB?.kb3_1ya !== null] : '-'}
                                    {dataKB?.kb3_1ya !== null && <>, usia kehamilan : {dataKB?.kb3_1_usia_kehamilan ? dataKB?.kb3_1_usia_kehamilan : '-'}</>}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>1. Jika YA, saat mulai hamil, apakah ibu memang ingin hamil saat itu, ingin hamil nanti, atau Tidak ingin anak lagi?</td>
                                  <td>{dataKB ? (dataKB.kb3_1_1ingin_hamil_saat_itu !== null ? 'ingin hamil saat itu' : (dataKB.kb3_1_1ingin_hamil_nanti !== null ? 'ingin hamil nanti' : (dataKB.kb3_1_1tdk_ingin_anak !== null ? 'Tidak ingin anak' : '-'))) : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>2. Apakah ibu menginginkan anak lagi?</td>
                                  <td>{dataKB ? (dataKB.kb3_2_1ingin_anak_segera !== null ? 'ingin anak segera' : (dataKB.kb3_2_1ingin_anak_nanti !== null ? 'ingin anak nanti' : (dataKB.kb3_2_1tdk_ingin_anak !== null ? 'Tidak ingin anak' : '-'))) : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>4.</td>
                                  <td>Apakah saat ini IBU atau SUAMI menggunakan alat/obat/cara KB (kontrasepsi)?</td>
                                  <td>{dataKB ? mapYesNo[dataKB?.kb4_1ya !== null] : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Kapan mulai menggunakan alat/obat KB (kontrasepsi) yang dipakai saat ini?</td>
                                  <td>{dataKB?.kb4_1ya !== null && <> bulan : {monthNames[dataKB?.kb4_1bulan]}, tahun : {dataKB?.kb4_1tahun ? dataKB?.kb4_1tahun : '-'} </>}</td>
                                </tr>

                                <tr>
                                  <td className='nomor'>5.</td>
                                  <td>Apa dalam 12 bulan terakhir, IBU atau SUAMI "PERNAH" menggunakan alat/obat/cara KB (kontrasepsi)?</td>
                                  <td>{dataKB ? mapYesNo[dataKB?.kb5_1ya !== null] : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Kapan mulai menggunakan alat/obat/cara kontrasepsi terakhir?</td>
                                  <td>{dataKB ? <>mulai: {dataKB?.kb5_1bulan_mulai_kb !== 0 ? dataKB?.kb5_1bulan_mulai_kb : '-'}-{dataKB?.kb5_1tahun_mulai_kb !== 0 ? dataKB?.kb5_1tahun_mulai_kb : '-'}</> : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Kapan berhenti menggunakan alat/obat/cara kontrasepsi terakhir?</td>
                                  <td>{dataKB ? <>berhenti: {dataKB?.kb5_1bulan_berhenti_kb !== 0 ? dataKB?.kb5_1bulan_berhenti_kb : '-'}-{dataKB?.kb5_1tahun_berhenti_kb !== 0 ? dataKB?.kb5_1tahun_berhenti_kb : '-'}</> : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>6.</td>
                                  <td>Alasan utama Tidak pakai KB atau putus KB </td>
                                  <td>{dataKB?.kb6 !== 0 ? mapKb6[dataKB?.kb6 - 1] : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>7.</td>
                                  <td>Jenis alat/obat/cara KB (kontrasepsi) yang dipakai saat ini atau terakhir dipakai</td>
                                  <td>{dataKB?.kb7 !== 0 ? mapKb7[dataKB?.kb7 - 1] : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>8.</td>
                                  <td>Sumber mendapatkan pelayanan alat/obat/cara KB terakhir</td>
                                  <td>{mapKb8[dataKB?.kb8 - 1]}
                                    {dataKB?.kb8_lainnyadesk !== null && <> lainnya : {dataKB?.kb8_lainnyadesk}</>}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>9.</td>
                                  <td>Pada saat pertama kali datang ke tempat pelayanan KB untuk mendapatkan alat/obat/cara kontrasepsi terakhir, apakah ibu atau suami mendapatkan informasi tentang:</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Jenis-jenis alat/obat/cara KB kontrasepsi?</td>
                                  <td>{dataKB?.kb9_1 === 1 ? 'Ya' : dataKB?.kb9_1 === 2 ? 'Tidak' : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Efek samping alat/obat/cara KB kontrasepsi yang dipakai?</td>
                                  <td>{dataKB?.kb9_2 === 1 ? 'Ya' : dataKB?.kb9_2 === 2 ? 'Tidak' : '-'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Apa yang harus dilakukan apabila mengalami efek samping alat/obat/cara KB kontrasepsi yang dipakai?</td>
                                  <td>{dataKB?.kb9_3 === 1 ? 'Ya' : dataKB?.kb9_3 === 2 ? 'Tidak' : '-'}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Paper>

                        {/* <div className="isiLaporan"><Link to="">Tabel1 - JUMLAH KEPALA KELUARGA LAKI-LAKI MENURUT KELOMPOK UMUR</Link></div> */}
                      </Grid>
                    </Grid>
                  </div>
                  <div role="tabpanel" hidden={this.state.valueTab !== 2}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                      <Grid item xs={12} md={12}>
                        <Paper sx={{ width: '100%', boxShadow: 'none' }}>
                          <div className='rekap-table'>
                            <table className='table-kb'>
                              <thead>
                                <tr>
                                  <th width="5" >NO</th>
                                  <th width="200">PERTANYAAN</th>
                                  <th width="150">JAWABAN</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className='nomor'>1.</td>
                                  <td>Selama 6 (enam) bulan terakhir, setiap anggota keluarga (usia 10 tahun ke atas) menjalankan ibadah secara rutin sesuai dengan tuntunan agama atau kepercayaan yang dianut</td>
                                  <td>{getAnswer(dataPK?.pk1)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>2.</td>
                                  <td>Keluarga memiliki buku/akta nikah yang disahkan oleh pejabat yang berwenang</td>
                                  <td>{getAnswer(dataPK?.pk2)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>3.</td>
                                  <td>Selama 6 (enam) bulan terakhir, terdapat konflik di antara anggota keluarga:</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>a. Tanpa tegur sapa (selama 3 hari berturut-turut)</td>
                                  <td>{getAnswer(dataPK?.pk3a)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>b. Pisah ranjang antara suami dan istri (selama 7 hari berturut-turut)</td>
                                  <td>{getAnswer(dataPK?.pk3b)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>c. Pergi dari rumah/minggat (selama 2 hari berturut-turut)</td>
                                  <td>{getAnswer(dataPK?.pk3c)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>d. Kekerasan dalam rumah tangga</td>
                                  <td>{getAnswer(dataPK?.pk3d)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>4.</td>
                                  <td>Selama 6 (enam) bulan terakhir, terdapat paling sedikit 1 (satu) anggota keluarga memiliki sumber penghasilan untuk memenuhi kebutuhan pokok per bulan</td>
                                  <td>{getAnswer(dataPK?.pk4)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>5.</td>
                                  <td>Selama 6 (enam) bulan terakhir, setiap anggota keluarga makan “makanan beragam” (makanan pokok, sayur/buah dan lauk) paling sedikit 2 (dua) kali sehari</td>
                                  <td>{getAnswer(dataPK?.pk5)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>6.</td>
                                  <td>Keluarga memiliki aset</td>
                                  <td>
                                    {dataPK?.pk6 === 1
                                      ? ` Ya, ${dataPK?.pk6_1 === 1 ? 'Tabungan (uang kontan/rekening aktif), ' : ''}
                                        ${dataPK?.pk6_2 === 2 ? 'AC, ' : ''}
                                        ${dataPK?.pk6_3 === 3 ? 'Televisi layar datar, ' : ''}
                                        ${dataPK?.pk6_4 === 4 ? 'Lemari es/kulkas, ' : ''}
                                        ${dataPK?.pk6_5 === 5 ? 'Komputer/laptop, ' : ''}
                                        ${dataPK?.pk6_6 === 6 ? 'Sepeda Motor, ' : ''}
                                        ${dataPK?.pk6_7 === 7 ? 'Mobil, ' : ''}
                                        ${dataPK?.pk6_8 === 8 ? 'Kapal/perahu motor/perahu, ' : ''}
                                        ${dataPK?.pk6_9 === 9 ? 'Emas/perhiasan (minimal 10 gram), ' : ''}
                                        ${dataPK?.pk6_10 === 10 ? 'Hewan Ternak, ' : ''}
                                        ${dataPK?.pk6_11 === 11 ? 'Lahan, ' : ''}
                                        ${dataPK?.pk6_12 === 12 ? 'Rumah di tempat lain' : ''}
                                      `
                                      : 'Tidak'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>7.</td>
                                  <td>Selama 1 (satu) bulan terakhir, terdapat anggota keluarga yang memiliki penyakit kronis sehingga meninggalkan aktivitas</td>
                                  <td>{getAnswer(dataPK?.pk7)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>8.</td>
                                  <td>Terdapat anggota keluarga yang disabilitas (fisik, netra/buta, rungu/wicara, mental/jiwa, fisik dan mental, serta lainnya)</td>
                                  <td>{getAnswer(dataPK?.pk8)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>9.</td>
                                  <td>Selama 6 (enam) bulan terakhir, terdapat paling sedikit 1 (satu) anggota keluarga mengakses informasi dari media online (internet)</td>
                                  <td>{getAnswer(dataPK?.pk9)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>10.</td>
                                  <td> Selama 6 (enam) bulan terakhir, setiap anggota keluarga memiliki waktu untuk berinteraksi setiap hari</td>
                                  <td>{getAnswer(dataPK?.pk10)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>11.</td>
                                  <td>Selama 6 (enam) bulan terakhir, pengasuhan anak dilakukan bersama antara suami dan istri</td>
                                  <td>{getAnswer(dataPK?.pk11)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>12.</td>
                                  <td>Selama 6 (enam) bulan terakhir, keluarga pernah berekreasi bersama di luar rumah</td>
                                  <td>{getAnswer(dataPK?.pk12)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>13.</td>
                                  <td>Selama 6 (enam) bulan terakhir, keluarga ikut serta dalam kegiatan sosial/gotong royong di lingkungan RT</td>
                                  <td>{getAnswer(dataPK?.pk13)}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>14.</td>
                                  <td>Jenis atap rumah terluas?</td>
                                  <td>
                                    {dataPK?.pk14_a === 1 ? 'Beton' :
                                      dataPK?.pk14_a === 2 ? 'Genteng' :
                                        dataPK?.pk14_a === 3 ? 'Seng' :
                                          dataPK?.pk14_a === 4 ? 'Asbes' :
                                            dataPK?.pk14_a === 5 ? 'Kayu/Sirap' :
                                              dataPK?.pk14_a === 6 ? 'Bambu' :
                                                dataPK?.pk14_a === 7 ? 'Jerami/Ijuk/Rumbia/Daun-daunan' :
                                                  dataPK?.pk14_a === 8 ? 'Lainnya' :
                                                    'Tidak Diketahui'}
                                  </td>
                                </tr>

                                <tr>
                                  <td className='nomor'></td>
                                  <td>Kondisi atap?</td>
                                  <td>
                                    {dataPK?.pk14_b === 1 ? 'Bagus/Kualitas Tinggi' :
                                      dataPK?.pk14_b === 2 ? 'Jelek/Kualitas Rendah' :
                                        'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>15.</td>
                                  <td>Jenis dinding rumah terluas?</td>
                                  <td>
                                    {dataPK?.pk15_a === 1 ? 'Tembok' :
                                      dataPK?.pk15_a === 2 ? 'Plesteran Anyaman Bambu/Kawat' :
                                        dataPK?.pk15_a === 3 ? 'Kayu/Papan/Gypsum' :
                                          dataPK?.pk15_a === 4 ? 'Anyaman Bambu' :
                                            dataPK?.pk15_a === 5 ? 'Batang Kayu' :
                                              dataPK?.pk15_a === 6 ? 'Bambu' :
                                                dataPK?.pk15_a === 7 ? 'Lainnya' :
                                                  'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Kondisi dinding?</td>
                                  <td>
                                    {dataPK?.pk15_b === 1 ? 'Bagus/Kualitas Tinggi' :
                                      dataPK?.pk15_b === 2 ? 'Jelek/Kualitas Rendah' :
                                        'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>16.</td>
                                  <td>Jenis lantai rumah terluas?</td>
                                  <td>
                                    {dataPK?.pk16_a === 1 ? 'Marmer/Granit' :
                                      dataPK?.pk16_a === 2 ? 'Keramik' :
                                        dataPK?.pk16_a === 3 ? 'Parket/Vinil/Karpet' :
                                          dataPK?.pk16_a === 4 ? 'Ubin/Tegel/Terazzo' :
                                            dataPK?.pk16_a === 5 ? 'Kayu/Papan' :
                                              dataPK?.pk16_a === 6 ? 'Semen/Bata Merah' :
                                                dataPK?.pk16_a === 7 ? 'Bambu' :
                                                  dataPK?.pk16_a === 8 ? 'Tanah' :
                                                    dataPK?.pk16_a === 9 ? 'Lainnya' :
                                                      'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Kondisi lantai?</td>
                                  <td>
                                    {dataPK?.pk16_b === 1 ? 'Bagus/Kualitas Tinggi' :
                                      dataPK?.pk16_b === 2 ? 'Jelek/Kualitas Rendah' :
                                        'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>17.</td>
                                  <td>Sumber penerangan utama?</td>
                                  <td>
                                    {dataPK?.pk17_a === 1 ? 'Listrik PLN Meteran' :
                                      dataPK?.pk17_a === 2 ? 'Listrik PLN Non Meteran' :
                                        dataPK?.pk17_a === 3 ? 'Listrik Non-PLN (Accu/Aki/Genset/Solar Cell/Pembangkit Listrik Tenaga Surya Tidak dikelola PLN, dll)' :
                                          dataPK?.pk17_a === 4 ? 'Bukan Listrik' :
                                            'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>Daya terpasang?</td>
                                  <td>
                                    {dataPK?.pk17_b === 1 ? '450 Watt' :
                                      dataPK?.pk17_b === 2 ? '900 Watt' :
                                        dataPK?.pk17_b === 3 ? '1.300 Watt' :
                                          dataPK?.pk17_b === 4 ? '> 1.300 Watt' :
                                            'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>18.</td>
                                  <td>Sumber air minum utama?</td>
                                  <td>
                                    {dataPK?.pk18 === 1 ? 'Air Kemasan/Isi Ulang' :
                                      dataPK?.pk18 === 2 ? 'Ledeng/PAM' :
                                        dataPK?.pk18 === 3 ? 'Sumur Bor/Pompa' :
                                          dataPK?.pk18 === 4 ? 'Sumur Terlindung' :
                                            dataPK?.pk18 === 5 ? 'Sumur Tak Terlindung' :
                                              dataPK?.pk18 === 6 ? 'Mata Air Terlindung' :
                                                dataPK?.pk18 === 7 ? 'Mata Air Tak Terlindung' :
                                                  dataPK?.pk18 === 8 ? 'Air Permukaan (Sungai/ Danau/Waduk/Kolam/Irigasi)' :
                                                    dataPK?.pk18 === 9 ? 'Air Hujan' :
                                                      dataPK?.pk18 === 10 ? 'Lainnya' :
                                                        'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>19.</td>
                                  <td>Memiliki fasilitas tempat buang air besar?</td>
                                  <td>
                                    {dataPK?.pk19 === 1 ? 'Ya, Memiliki Sendiri dengan Leher Angsa dan Tangki Septik/IPAL' :
                                      dataPK?.pk19 === 2 ? 'Ya, MCK Komunal dengan Leher Angsa dan Tangki Septik/IPAL' :
                                        dataPK?.pk19 === 3 ? 'Ya, Lainnya' :
                                          dataPK?.pk19 === 4 ? 'Tidak Ada' :
                                            'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>20.</td>
                                  <td>Luas rumah/bangunan keseluruhan (m&sup2;)</td>
                                  <td>{dataPK?.pk20}(m&sup2;)</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>21.</td>
                                  <td>Orang yang tinggal dan menetap di rumah/bangunan ini?</td>
                                  <td>{dataPK?.pk21}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>22.</td>
                                  <td>Bahan bakar utama untuk memasak?</td>
                                  <td>
                                    {dataPK?.pk22 === 1 ? 'Listrik' :
                                      dataPK?.pk22 === 2 ? 'Gas 12 kg' :
                                        dataPK?.pk22 === 3 ? 'Gas 5,5 kg/Blue Gaz' :
                                          dataPK?.pk22 === 4 ? 'Gas 3 kg' :
                                            dataPK?.pk22 === 5 ? 'Minyak Tanah' :
                                              dataPK?.pk22 === 6 ? 'Briket/Arang/Kayu' :
                                                dataPK?.pk22 === 7 ? 'Lainnya' :
                                                  'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>23.</td>
                                  <td>Kepemilikan rumah/bangunan tempat tinggal?</td>
                                  <td>
                                    {dataPK?.pk23 === 1 ? 'Milik Sendiri' :
                                      dataPK?.pk23 === 2 ? 'Kontrak/Sewa' :
                                        dataPK?.pk23 === 3 ? 'Bebas Sewa/Menumpang' :
                                          dataPK?.pk23 === 4 ? 'Dinas' :
                                            dataPK?.pk23 === 5 ? 'Lainnya' :
                                              'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>24.</td>
                                  <td>Keluarga pernah memperoleh/mendengar/melihat pesan/informasi program kependudukan, Keluarga Berencana dan Pembangunan Keluarga dari media?</td>
                                  <td>
                                    {dataPK?.pk24 === 1 ? 'Ya' : 'Tidak'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>25.</td>
                                  <td>Jika ya, dari mana info tersebut diperoleh :</td>
                                  <td>
                                    {dataPK?.pk24 === 1
                                      ? `${dataPK?.pk25_1 === 1 ? 'Media Sosial, ' : ''}
                                          ${dataPK?.pk25_2 === 2 ? 'Radio, ' : ''}
                                          ${dataPK?.pk25_3 === 3 ? 'Televisi, ' : ''}
                                          ${dataPK?.pk25_4 === 4 ? 'Koran/Majalah, ' : ''}
                                          ${dataPK?.pk25_5 === 5 ? 'Pamflet/Brosur, ' : ''}
                                          ${dataPK?.pk25_6 === 6 ? 'Lainnya' : ''}
      `
                                      : 'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'>26.</td>
                                  <td>Apakah keluarga pernah memperoleh/mendengar/melihat pesan/informasi program kependudukan, Keluarga Berencana dan Pembangunan Keluarga serta stunting dari petugas?</td>
                                  <td>{dataPK?.pk26 === 1 ? 'Ya' : 'Tidak'}</td>
                                </tr>
                                <tr>
                                  <td className='nomor'>27.</td>
                                  <td>Darimana keluarga menerima informasi tersebut?</td>
                                  <td>
                                    {dataPK?.pk26 === 1
                                      ? `${dataPK?.pk27_3 === 3 ? 'Petugas Kesehatan, ' : ''}
                                        ${dataPK?.pk27_4 === 4 ? 'Petugas KB, ' : ''}
                                        ${dataPK?.pk27_5 === 5 ? 'Petugas Stunting, ' : ''}
                                        ${dataPK?.pk27_6 === 6 ? 'Dinas Kesehatan, ' : ''}
                                        ${dataPK?.pk27_7 === 7 ? 'Posyandu, ' : ''}
                                        ${dataPK?.pk27_8 === 8 ? 'Puskesmas, ' : ''}
                                        ${dataPK?.pk27_9 === 9 ? 'Rumah Sakit, ' : ''}
                                        ${dataPK?.pk27_10 === 10 ? 'Bidan Desa, ' : ''}
                                        ${dataPK?.pk27_11 === 11 ? 'Lainnya' : ''}
      `
                                      : 'Tidak Diketahui'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='nomor'></td>
                                  <td>11. Lainnya</td>
                                  <td>{dataPK?.pk27_11 ? dataPK?.pk27_11 : '-'}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                </Box>


                {/* <DialogTitle>Detail</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Paper sx={{ width: '100%', boxShadow: 'none' }}>
                    <div className='rekap-table'>
                      <BootstrapTable data={this.state.dataKependudukan}
                        pagination={true}
                        options={options}
                        search={true}
                      >
                        <TableHeaderColumn width="125" dataField="nik_agt_klg" isKey={true} headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>NO KK </TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="nama_agt_klg" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>NAMA </TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="jenis_kelamin" headerAlign="center" dataAlign="center" >JENIS KELAMIN </TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="tgl_lahir" headerAlign="center" dataAlign="center" dataFormat={this.dateFormatter} >TGL LAHIR </TableHeaderColumn>
                        <TableHeaderColumn width="80" dataField="usia_kawin" headerAlign="center" dataAlign="center" >USIA KAWIN PERTAMA </TableHeaderColumn>
                        <TableHeaderColumn width="80" dataField="memiliki_akta" headerAlign="center" dataAlign="center" >AKTA KELAHIRAN</TableHeaderColumn>
                        <TableHeaderColumn width="150" dataField="hubungan_dg_kk" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>HUBUNGAN DGN KEPALA KELUARGA </TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="kd_ibu_kandung" headerAlign="center"  dataAlign="center" >IBU KANDUNG</TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="id_agama" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >AGAMA</TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="jns_pekerjaan" headerAlign="center" dataAlign="center"  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>JENIS PEKERJAAN</TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="status_pekerjaan" headerAlign="center" dataAlign="center"  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>STATUS PEKERJAAN</TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="jns_pendidikan" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>PENDIDIKAN</TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="kepesertaan_jkn" headerAlign="center" dataAlign="center" >KEPESERTAAN JKN</TableHeaderColumn>
                        <TableHeaderColumn width="120" dataField="mutasi_anggota_keluarga" headerAlign="center" dataAlign="center"  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>MUTASI ANGGOTA KELUARGA</TableHeaderColumn>


                      </BootstrapTable>
                    </div>
                    <div className='sizePerPage'>
                      Size Per Page
                      <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value={this.state.dataKependudukan?.length}>All</option>
                      </select>
                    </div>
                    <div className='totalData'><b>Total Data: {this.state.dataKependudukan?.length}</b></div>
                  </Paper>


                  
                </DialogContentText>
              </DialogContent> */}
              </DialogContent>
              <DialogActions>
                <Button sx={{ marginTop: '5px', marginLeft: '5px', backgroundColor: 'gray', ':hover': { bgcolor: 'gray', color: 'white' } }} variant="contained" onClick={this.handleOpenCloseViewDetail}> Tutup </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </BlockUi>
    )
  }
}
export default DetailStatus;