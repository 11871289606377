import React, { Component } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImPlay3, ImUserPlus, ImSearch, ImPhone, ImLocation, ImFloppyDisk, ImCross } from 'react-icons/im';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdAlternateEmail, MdOutlineDoNotDisturb, MdOutlineCheck } from 'react-icons/md';
import { FcProcess } from "react-icons/fc";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { BsPersonCheckFill, BsPersonCheck } from "react-icons/bs";
import CommonTable from '../../../commons/table/Table';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import APIWilayah from '../../../services/APIWilayah';
import APIUser from "../../../services/APIUser";
import authServices from "../../../services/authServices";
import { Link } from 'react-router-dom';


class Role extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendDatas: {},
      panel: true,

      //---table
      sizePerPage: 10,
      tableHead: [],
      datas: [],
      //---end table

      //---wilayah
      flagNumber: -1,
      flagOpen: true,
      blocking: false,
      dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
      dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
      //---end wilayah
      optPriodePendataan: [
        { "id": 1, "roleName": "Administrator", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 2, "roleName": "Admin Provinsi", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 3, "roleName": "Manager Pengelolaan", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 4, "roleName": "Supervisor", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 5, "roleName": "Pendata", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 6, "roleName": "Manager Data", detailRole: "admin", lihat: 1, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 7, "roleName": "TabulasiKS", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 8, "roleName": "Tabulasi", detailRole: "admin", lihat: 1, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 9, "roleName": "Admin Kabupaten", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 10, "roleName": "Operator", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 11, "roleName": "Supervisor Provinsi", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 12, "roleName": "Supervisor Kabupaten", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 13, "roleName": "Supervisor Kecamatan", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 14, "roleName": "Supervisor Kelurahan", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 15, "roleName": "Pelaksana Kegiatan", detailRole: "admin", lihat: 0, entri: 1, ubah: 1, hapus: 1, unduh: 1, cetak: 1 },
        { "id": 16, "roleName": "PPK" },
        {
          "id": 17, "roleName": "BPP"
        }
      ],
      dt_roles: [],
      role: [],
      openEditUser: false,
      userRole: JSON.parse(localStorage.getItem('authority'))[0].attributes.role
    }
  }

  getRole = (e) => {
    authServices
      .get("/roles/list?periodeSensus=2024")
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState({
            dt_roles: res.data
          }, () => {
          })
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });
  }

  activeBtn = (e) => {
    const idUser = parseInt(e.currentTarget.id);
    let status = e.currentTarget.value;
    if (status == "true") {
      status = false;
    } else {
      status = true;
    }
    this.setState({
      updateStatus: {
        id: idUser,
        isActive: status
      }
    })

    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: "Ingin mengubah status User ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ blocking: true });
        authServices.post("daftar/updateUserStatus?id=" + this.state.updateStatus.id + "&isActive=" + this.state.updateStatus.isActive)
          .then((res) => {
            this.setState({ blocking: false });
            if (res.status === 200) {
              //Update state berdasarkan id yg diubah
              const arr = { id: this.state.updateStatus.id, isActive: this.state.updateStatus.isActive }
              this.setState({
                datas: this.state.datas.map(el => el.id === this.state.updateStatus.id ? Object.assign({}, el, arr) : el)
              })
              Swal.fire(
                'Berhasil!',
                'Kamu Berhasil Mengubah Status User ini.',
                'success'
              )
              this.setLabel()
            }
          })
          .catch((error) => {
            this.setState({ blocking: false });
            console.log(error, "dataaa ERROR");
          });
      } else {
        this.setState({blocking : false})
      }
    })
  }

  handleEditUser = (e, row) => {
    this.setState({}, () => { this.handleOpenCloseEditUser() })
  }

  handleOpenCloseEditUser = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openEditUser: !this.state.openEditUser },
      // () => { if (!this.state.openEditUser) { this.clearForm() } }
    )
  }

  buttonFormatter = (cell, row) => {
    if (this.state.userRole === 'Admin Monitoring') {
      return <div className='divBtnTable'>
        -
      </div>
    } else {
      return <div className='divBtnTable'>
        <Button onClick={this.activeBtn} id={row.id} value={row.isActive} className='btnTable' variant="outlined"><FcProcess /></Button>
        <Button onClick={(e) => this.handleEditUser(e, row)} id={row.id} className='btnTable' variant="outlined"><AiTwotoneEdit style={{ color: 'green' }} /></Button>
      </div>
    }
  }

  buttonCeklis = (cell, row) => {
    // console.log(row)
    if (row === 1) {
      return <div className='divBtnTable'><MdOutlineCheck style={{ color: 'blue' }} /></div>
    } else {
      return <div className='divBtnTable'><MdOutlineDoNotDisturb style={{ color: 'red' }} /></div>
    }
  }
  buttonCeklisLihat = (cell, row) => {
    let lihat = row.lihat
    if (lihat === 1) {
      return <div><MdOutlineCheck style={{ color: 'blue' }} /></div>
    } else {
      return <div><MdOutlineDoNotDisturb style={{ color: 'red' }} /></div>
    }
  }
  buttonCeklisEntri = (cell, row) => {
    let lihat = row.entri
    if (lihat === 1) {
      return <div><MdOutlineCheck style={{ color: 'blue' }} /></div>
    } else {
      return <div><MdOutlineDoNotDisturb style={{ color: 'red' }} /></div>
    }
  }
  buttonCeklisubah = (cell, row) => {
    let lihat = row.ubah
    if (lihat === 1) {
      return <div><MdOutlineCheck style={{ color: 'blue' }} /></div>
    } else {
      return <div><MdOutlineDoNotDisturb style={{ color: 'red' }} /></div>
    }
  }
  buttonCeklishapus = (cell, row) => {
    let lihat = row.hapus
    if (lihat === 1) {
      return <div><MdOutlineCheck style={{ color: 'blue' }} /></div>
    } else {
      return <div><MdOutlineDoNotDisturb style={{ color: 'red' }} /></div>
    }
  }
  buttonCeklisunduh = (cell, row) => {
    let lihat = row.unduh
    if (lihat === 1) {
      return <div><MdOutlineCheck style={{ color: 'blue' }} /></div>
    } else {
      return <div><MdOutlineDoNotDisturb style={{ color: 'red' }} /></div>
    }
  }
  buttonCekliscetak = (cell, row) => {
    let lihat = row.cetak
    if (lihat === 1) {
      return <div><MdOutlineCheck style={{ color: 'blue' }} /></div>
    } else {
      return <div><MdOutlineDoNotDisturb style={{ color: 'red' }} /></div>
    }
  }


  statusFormatter = (cell, row) => {
    if (row.isActive === true) {
      return <div className='userActive'>< MdOutlineCheckBox style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Aktif</div>
    } else {
      return <div className='userNonActive'><MdOutlineCheckBoxOutlineBlank style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Non-Aktif</div>
    }

  }

  setHeaderTable = () => {
    var headerColumns = [
      { width: "80", title: 'ACTION', dataField: "action", headerAlign: 'center', dataAlign: 'center', editable: false, dataFormat: "action" },
      { width: "100", title: 'STATUS', dataField: "isActive", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", title: 'USERNAME', dataField: "username", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'NAMA', dataField: 'namaLengkap', headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'ROLE', dataField: "roleName", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "150", title: 'WILAYAH', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'ALAMAT', dataField: "alamat", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'TELEPON', dataField: "noTelepon", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", title: 'EMAIL', dataField: "email", headerAlign: 'center', dataAlign: 'left', editable: false },

    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidMount = () => {
    this.setHeaderTable()
    this.getRole();

    const rest = JSON.parse(localStorage.getItem("authority"));
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }

        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
        }
        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // console.log(vl, 'vale kab')
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // console.log(vl, 'vale kec')
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              // console.log(vl, 'vale des')
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
        sendDatas: { ...this.state.sendDatas, idProvinsi: e.value }
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // console.log('masuk false')
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di prov')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {},
      }
        ,
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
        sendDatas: { ...this.state.sendDatas, idKabupaten: e.value }
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kab')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
        sendDatas: { ...this.state.sendDatas, idKecamatan: e.value }
      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kec')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
        sendDatas: { ...this.state.sendDatas, idKelurahan: e.value }
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di des')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
        sendDatas: { ...this.state.sendDatas, idRW: e.value }
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
        sendDatas: { ...this.state.sendDatas, idRT: e.value }
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        rt: null, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
          "idRW": this.state.sendDatas.idRW,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  handleSelectRole = (e) => {
    if (e) {
      this.setState({ role: e })
      this.setState({ sendDatas: { ...this.state.sendDatas, roleId: e.value } });
    } else {
      this.setState({ role: [], sendDatas: { ...this.state.sendDatas, roleId: '' } })
    }

  }

  handleChangePanel = (e) => {
    e.preventDefault();
    this.setState({ panel: !this.state.panel })
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  handleSearch = () => {
    this.setLabel()
    this.getBackend(this.state.sendDatas)
  }

  getBackend(formData) {
    this.setState({ blocking: true });
    authServices.get("/daftar/listDaftarUser", { params: formData })
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState(
            {
              datas: res.data
            }, () => {
              // console.log(this.state.datas,"datas")
            }
          )
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        // console.log(error, "dataaa ERROR");
      });
  }

  render() {
    const options = {
      page: 1,
      sizePerPageList: [{
        text: '1', value: 1
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
    };
    return (
      <div className='content'>
        <div className='title-content'><ImPlay3 className='icon-brd' />Pengaturan User PK</div>
        <div className='body-content'>
          <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
            {/* <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel} onChange={this.handleChangePanel} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          Role
                        <FormControl
                                        sx={{
                                        width: '100%'
                                    }}
                                        size="small">
                                        <Select
                                            options={this.state.dt_roles.map(({ id, roleName }) => ({ value: id, label: roleName}))}
                                            isClearable
                                            onChange={this.handleSelectRole}
                                            value={this.state.role}
                                            placeholder="Cari..."
                                            styles={{
                                            menuPortal: base => ({
                                                ...base,
                                                zIndex: 9999
                                            })
                                        }}
                                            menuPortalTarget={document.body}/>
                                    </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px' }} variant="outlined"><ImSearch style={{ marginRight: '5px' }} /> Tampilkan</Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Link to="/create-user" style={{textDecoration: 'none'}}>
                            <Button sx={{ width: '100%', minHeight: '36px', marginTop: '22px' }} variant="outlined" color="success"><ImUserPlus style={{ marginRight: '5px'}} /> Tambah User</Button>
                          </Link>` */}
            {/* <Button sx={{ width: '100%', minHeight: '36px', marginTop: '22px' }} variant="outlined" color="success"><ImUserPlus style={{ marginRight: '5px' }} /> Tambah User <Link to="/laporan-tabulasi" /></Button> */}
            {/* </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails> */}
            {/* </Accordion>
            </Box> */}
            <h3>Daftar Role {this.state.title}</h3>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              {/* <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
              > */}
              <BootstrapTable data={this.state.dt_roles}
                pagination={true}
                options={options}
                search={true}>
                <TableHeaderColumn width='80' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter}>ACTION</TableHeaderColumn>
                <TableHeaderColumn width='100' dataField='isActive' headerAlign='center' dataAlign='center' dataFormat={this.statusFormatter}>STATUS</TableHeaderColumn>
                {/* <TableHeaderColumn width='100'  dataField='username' headerAlign='center' dataAlign='center'>USERNAME</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='180'  dataField='namaLengkap' headerAlign='center' dataAlign='center'>NAMA</TableHeaderColumn> */}
                <TableHeaderColumn width='120' isKey={true} dataField='roleName' headerAlign='center' dataAlign='center'>ROLE</TableHeaderColumn>
                <TableHeaderColumn width='150' dataField='detailRole' headerAlign='center' dataAlign='center'>DETAIL ROLE</TableHeaderColumn>
                <TableHeaderColumn width='100' dataField='lihat' headerAlign='center' dataAlign='center' dataFormat={this.buttonCeklisLihat}>LIHAT</TableHeaderColumn>
                <TableHeaderColumn width='100' dataField='entri' headerAlign='center' dataAlign='center' dataFormat={this.buttonCeklisEntri}>ENTRI</TableHeaderColumn>
                <TableHeaderColumn width='100' dataField='ubah' headerAlign='center' dataAlign='center' dataFormat={this.buttonCeklisubah}>UBAH</TableHeaderColumn>
                <TableHeaderColumn width='100' dataField='hapus' headerAlign='center' dataAlign='center' dataFormat={this.buttonCeklishapus}>HAPUS</TableHeaderColumn>
                <TableHeaderColumn width='100' dataField='unduh' headerAlign='center' dataAlign='center' dataFormat={this.buttonCeklisunduh}>UNDUH</TableHeaderColumn>
                <TableHeaderColumn width='100' dataField='cetak' headerAlign='center' dataAlign='center' dataFormat={this.buttonCekliscetak}>CETAK</TableHeaderColumn>

              </BootstrapTable>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='1'>1</option>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div>
            </Paper>

            <Dialog open={this.state.openEditUser} onClose={this.handleOpenCloseEditUser} style={{ maxWidth: '100%' }}>
              <DialogTitle>Edit User</DialogTitle>
              <DialogContent>
                <Box sx={{ width: "100%" }} m={3}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={2} style={{ display: 'inline-block' }}>
                      <div >
                        <FaUser style={{ top: '2px', position: 'relative' }} />&nbsp; Username
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="username"
                        variant="outlined"
                        style={{ width: '80%', bottom: '5px' }}
                        //value={this.state.username}
                        size="small"
                        disabled
                        onChange={this.handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'inline-block' }}>
                      <div>
                        <BsPersonCheckFill style={{ top: '2px', position: 'relative' }} />&nbsp; NIK
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        //error={this.state.errors["nik"] ? true : false}
                        name="nik"
                        variant="outlined"
                        style={{ width: '80%', bottom: '5px' }}
                        // value={this.state.nik}
                        size="small"
                        onChange={this.handleChangeForm}
                        //helperText={this.state.errors["nik"] ? this.state.errors["nik"] : ''}
                        inputProps={{ maxLength: 16 }}
                      />
                    </Grid>
                  </Grid>
                  <p></p>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={2} style={{ display: 'inline-block' }}>
                      <div>
                        <AiOutlineIdcard style={{ top: '2px', position: 'relative' }} />&nbsp; Nama Lengkap
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        //error={this.state.errors["namaLengkap"] ? true : false}
                        name="namaLengkap"
                        variant="outlined"
                        type="text"
                        style={{ width: '80%', bottom: '5px' }}
                        //value={this.state.namaLengkap}
                        size="small"
                        onChange={this.handleChangeForm}
                      //helperText={this.state.errors["namaLengkap"] ? this.state.errors["namaLengkap"] : ''}

                      />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'inline-block' }}>
                      <div>
                        <ImLocation style={{ top: '2px', position: 'relative' }} />&nbsp; Alamat
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="alamat"
                        variant="outlined"
                        type="text"
                        style={{ width: '80%', bottom: '5px' }}
                        //value={this.state.alamat}
                        size="small"
                        onChange={this.handleChangeForm}
                      />
                    </Grid>
                  </Grid>
                  <p></p>

                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >

                    <Grid item xs={2} style={{ display: 'inline-block' }}>
                      <div >
                        <MdAlternateEmail style={{ top: '2px', position: 'relative' }} />&nbsp; Email
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        //error={this.state.errors["email"] ? true : false}
                        name="email"
                        variant="outlined"
                        type="email"
                        style={{ width: '80%', bottom: '5px' }}
                        //value={this.state.email}
                        size="small"
                        onChange={this.handleChangeForm}
                      //helperText={this.state.errors["email"] ? this.state.errors["email"] : ''}

                      />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'inline-block' }}>
                      <div>
                        <BsPersonCheck style={{ top: '2px', position: 'relative' }} />&nbsp; NIP
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="nip"
                        variant="outlined"
                        style={{ width: '80%', bottom: '5px' }}
                        //value={this.state.nip}
                        size="small"
                        onChange={this.handleChangeForm}
                        inputProps={{ maxLength: 16 }}
                      />
                    </Grid>
                  </Grid>
                  <p></p>

                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >

                    <Grid item xs={2} style={{ display: 'inline-block' }}>
                      <div>
                        <ImPhone style={{ top: '2px', position: 'relative' }} /> &nbsp; No. Telepon
                      </div>

                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        //error={this.state.errors["noTelepone"] ? true : false}
                        name="noTelepone"
                        variant="outlined"
                        style={{ width: '80%', bottom: '5px' }}
                        //value={this.state.noTelepone}
                        size="small"
                        onChange={this.handleChangeForm}
                        //helperText={this.state.errors["noTelepone"] ? this.state.errors["noTelepone"] : ''}
                        inputProps={{ maxLength: 13 }}

                      />
                    </Grid>

                    {/* <Grid item xs={6}>
                            <Button variant="contained" >Simpan</Button>
                        </Grid> */}

                  </Grid>

                </Box>
              </DialogContent>
              <DialogActions>
                <Button sx={{ marginRight: '5px' }} variant="contained"><ImFloppyDisk className='icon-brd' style={{ marginRight: '5px' }} /> Simpan</Button>
                <Button onClick={this.handleOpenCloseEditUser} sx={{ marginRight: '5px' }} color='error' variant="contained"><ImCross className='icon-brd' style={{ marginRight: '5px' }} /> Batal</Button>
              </DialogActions>
            </Dialog>
          </BlockUi>
        </div>
      </div>
    )
  }
}

export default Role;