import React from 'react';
import Aside from './Aside';
import '../styles/Layout.css';

function Layout({ setLocale, toggled, handleToggleSidebar, collapsed, handleCollapsedChange }) {

  return (
    <>
      <Aside
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        collapsed={collapsed}
        handleCollapsedChange={handleCollapsedChange}
      />
    </>
  );
}

export default Layout;
