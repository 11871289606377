import React, { Component } from "react";
import unauthorized from '../../../assets/media/error/unauthorized.png';

class Page401 extends Component{
    render() {
        return (
              <div className="animated fadeIn">
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
                    <img src={unauthorized} alt=""/>
                </div>
              </div>
        );
      }
}
export default Page401;