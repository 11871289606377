import axios from 'axios';

//let url = 'http://dev-pkportalauthorizeservice-bkkbn-pk.apps.container.layanan.go.id/'
let url = ''

if (process.env.REACT_APP_ENV === 'development') {
    url = "http://dev-portal-api-gateway-portal-pk-staging.apps.ocp-dev.bkkbn.go.id/login"

}

if (process.env.REACT_APP_ENV === 'production') {
    url = "https://pk23-portal-gw.bkkbn.go.id/login"
}

var admin = 'admin'
var password = 'password'
// let auth = btoa(`${admin}:${password}`);
// export default axios.create({
//     baseURL: url,
//     headers: {
//         Authorization: localStorage.getItem('token') !== 'null' ? 'Bearer ' + localStorage.getItem('token') : '',
//         'Authorization' : 'Basic YWRtaW46cGFzc3dvcmQ=',
//         // 'Authorization' : 'Basic ' + btoa(admin + ':' + password),
//         // 'Access-Control-Allow-Origin': '*',
//         // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
//         // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
//         // 'user_key': '6ab0c0c4f7c40f3c7747329890cc5e78',
//         'Content-Type': 'application/json',
//     },
//     responseType: 'json'
// });

export default axios.create({
    baseURL: url,
    headers: {
        Authorization: localStorage.getItem('token') !== 'null' ? 'Bearer ' + localStorage.getItem('token') : '',
        'Authorization' : 'Basic YWRtaW46cGFzc3dvcmQ=',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        // 'user_key': '6ab0c0c4f7c40f3c7747329890cc5e78',
        'Content-Type': 'application/json',
    },
    responseType:'json'
});
