import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ImPlay3, ImSearch } from 'react-icons/im';
import Swal from 'sweetalert2';
import APIMonitoringPenggunaTabulasi from '../../../services/APIMonitoringPenggunaTabulasi';

class monitoringPenggunaTabulasi extends Component {
    constructor (props) {
        super(props);

        var curr = new Date();
        curr.setDate(curr.getDate());
        var date1 = curr.toISOString().substr(0, 10);

        var curr = new Date();
        curr.setDate(curr.getDate());
        var date2 = curr.toISOString().substr(0, 10);

        this.state = {
            panel1: true,
            sizePerPage: 10,
            title: '',
            blocking: false,
            datas: [],
            nama: '',
            instansi: '',
            email: '',
            nomor_hp: '',
            startDate: date1,
            endDate: date2,

            role: JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
            roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    clearForm = () => {
        this.setState({})
    }

    setHeaderTable = () => {

    }

    componentDidMount() {
        const rest = JSON.parse(localStorage.getItem('authority'))
    }

    handlePageChange = (page, rowsPerPage) => {
        this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    }

    handleSearch = () => {
        this.setState({ blocking: true });

        const { nama, instansi, startDate, endDate } = this.state

        if (startDate != null && endDate != null) {

            // this.setState({
            //     datas: newData,
            // }, () => {
            //     console.log(this.state.datas, "<<<<< DATAS");
            //     this.setState({ blocking: false });
            // });

            APIMonitoringPenggunaTabulasi.get('/portalpk/monitoring/getUserLogin?endDate=' + endDate + '&instansi=' + instansi + '&nama=' + nama + '&startDate=' + startDate)
                .then((res) => {

                    if (res && res.data.length > 0 && res.status == 200) {
                        console.log(res, "<<<<< RESPONSE")
                        this.setState({
                            datas: res.data,
                            // datas: newData,
                        }, () => {
                            console.log(this.state.datas, "<<<<< DATAS");
                            this.setState({ blocking: false });
                        });
                    } else {
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            text: 'Pencarian Data Gagal, Harap Coba lagi!',
                        })
                        this.setState({ blocking: false });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'warning',
                        text: 'Pencarian Data Gagal, Harap Coba lagi!',
                    })
                    this.setState({ blocking: false });
                });
        } else {
            Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Start Date dan End Date harus dipilih!',
                showConfirmButton: true
            })
            this.setState({ blocking: false });
        }
    }

    handleChangeForm = (e, tab) => {
        this.setState({ [e.target.name]: e.target.value })
        // this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    }
    handleChangeFormEnd = (event) => {
        const { name, value } = event.target;

        if (name === 'endDate') {
            // Validasi untuk memastikan endDate tidak melebihi hari ini
            const currentDate = new Date();
            const selectedDate = new Date(value);

            if (selectedDate > currentDate) {
                // Tanggal yang dipilih lebih besar dari hari ini
                Swal.fire({
                    title: 'Info!',
                    icon: 'info',
                    text: 'Tanggal akhir tidak boleh melebihi hari ini!',
                    showConfirmButton: true
                })
                return; // atau lakukan sesuatu sesuai kebutuhan Anda
            }
        }

        // Set nilai ke state
        this.setState({
            [name]: value
        });
    };

    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    render() {
        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "1",
                    value: 1,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
        };

        const currentDate = new Date().toISOString().split('T')[0]; // Format tanggal untuk input type date

        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() - 7);
        const maxDateFormatted = maxDate.toISOString().split('T')[0];
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                <div className='content'>
                    <div className='title-content'><ImPlay3 className='icon-brd' />Monitoring Pengguna Tabulasi</div>
                    <div className='body-content'>
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Nama
                                                        <TextField
                                                            name="nama"
                                                            variant="outlined"
                                                            type="text"
                                                            style={{ width: '100%' }}
                                                            value={this.state.nama}
                                                            size="small"
                                                            onChange={this.handleChangeForm}
                                                            placeholder="Nama..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Instansi
                                                        <TextField
                                                            name="instansi"
                                                            variant="outlined"
                                                            type="text"
                                                            style={{ width: '100%' }}
                                                            value={this.state.instansi}
                                                            size="small"
                                                            onChange={this.handleChangeForm}
                                                            placeholder="Instansi..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Start Date
                                                        <TextField
                                                            name="startDate"
                                                            variant="outlined"
                                                            type="date"
                                                            style={{ width: '100%' }}
                                                            value={this.state.startDate}
                                                            size="small"
                                                            onChange={this.handleChangeForm}
                                                            inputProps={{ max: currentDate }}

                                                            
                                                            placeholder="Start Date..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        End Date
                                                        <TextField
                                                            name="endDate"
                                                            variant="outlined"
                                                            type="date"
                                                            style={{ width: '100%' }}
                                                            value={this.state.endDate}
                                                            size="small"
                                                            onChange={this.handleChangeFormEnd}
                                                            inputProps={{ max: currentDate, min: maxDateFormatted }}
                                                            placeholder="End Date..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <h3>Pengguna Tabulasi</h3>

                        <Paper sx={{ width: "100%", boxShadow: "none" }}>
                            <div className="rekap-table">
                                <BootstrapTable
                                    data={this.state.datas}
                                    pagination={true}
                                    keyField='nama'
                                    options={options}
                                    search={true}
                                >
                                    {/* <TableHeaderColumn isKey={true} dataSort={true} row='1' rowSpan='1' width="50" dataField="no" headerAlign="center" dataAlign="center">NO</TableHeaderColumn> */}
                                    <TableHeaderColumn row='1' rowSpan='1' width="200" dataField="nama" headerAlign="center" dataAlign="center">NAMA</TableHeaderColumn>
                                    <TableHeaderColumn row='1' rowSpan='1' width="200" dataField="instansi" headerAlign="center" dataAlign="center">INSTANSI</TableHeaderColumn>
                                    <TableHeaderColumn row='1' rowSpan='1' width="200" dataField="catatan" headerAlign="center" dataAlign="center">CATATAN</TableHeaderColumn>
                                    {/* <TableHeaderColumn row='1' rowSpan='1' width="200" dataField="email" headerAlign="center" dataAlign="center">EMAIL</TableHeaderColumn>
                                    <TableHeaderColumn row='1' rowSpan='1' width="200" dataField="nomor_hp" headerAlign="center" dataAlign="center">NO. HP</TableHeaderColumn>
                                    <TableHeaderColumn row='1' rowSpan='1' width="200" dataField="startdate" headerAlign="center" dataAlign="center">START DATE</TableHeaderColumn>
                                    <TableHeaderColumn row='1' rowSpan='1' width="200" dataField="enddate" headerAlign="center" dataAlign="center">END DATE</TableHeaderColumn> */}
                                    <TableHeaderColumn row='1' rowSpan='1' width="200" dataField="startdate" headerAlign="center" dataAlign="center">TANGGAL DOWNLOAD</TableHeaderColumn>
                                </BootstrapTable>
                                <div className="sizePerPage">
                                    Size Per Page
                                    <select
                                        name="category"
                                        value={this.state.sizePerPage}
                                        onChange={this.handleChangeSizePerPage}
                                    >
                                        <option value="1">1</option>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value={this.state.datas?.length}>All</option>
                                    </select>
                                </div>
                                <div className='totalData'>
                                    <b style={{ marginRight: '15px' }}>Total Data: {this.state.datas?.length}</b>
                                </div>

                            </div>
                        </Paper>

                    </div>
                </div>
            </BlockUi>
        )
    }
}

export default monitoringPenggunaTabulasi;