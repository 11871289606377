import React from 'react';
//------react-bootstrap-table---------------
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Button } from '@mui/material';

const TableMonitoring = (props) => {

    function customFormatter(cell, row, param) {
        if (param === 'startDate') {
            var currentTime = new Date(cell)
            var month = currentTime.getMonth() + 1
            var day = currentTime.getDate()
            var year = currentTime.getFullYear()
            var hour = currentTime.getHours()
            var minute = currentTime.getMinutes()
            var second = currentTime.getSeconds()
            var dd = day < 10 ? '0'+day : day
            var mm = month < 10 ? '0'+month : month
            var hh = hour < 10 ? '0'+hour : hour
            var mi = minute < 10 ? '0'+minute : minute
            var ss = second < 10 ? '0'+second : second
            var date = dd + "-" + mm + "-" + year + ' ' + hh + ':' + mi + ':' + ss
            return date
        }
        else {
            return cell;
        }
    }

    const renderShowsTotal = (start, to, total) => (
        <span className="react-bootstrap-table-pagination-total" style={{ marginTop: '12px', position: 'absolute' }}>
            <b>Total</b>: {total}
        </span>
    );
    
    const options = { 
        pageStartIndex: 1,
        paginationSize: 3,
        prePage: '<',
        nextPage: '>',
        firstPage: '<<',
        lastPage: '>>',
        hideSizePerPage: true,
        paginationShowsTotal: false,
        paginationPosition: 'bottom',
        sizePerPage: props.sizePerPage,
        onPageChange: props.onPageChange,
        page: props.page,
        // sizePerPageList: [ 5, 10 ],
        // onSizePerPageList: this.props.onSizePerPageList 
    }
    
    return (
        <div>
            <BootstrapTable data={props.datas} striped hover version='4'
                remote={ true }
                pagination={true}
                width='1260px'
                search={false}
                keyField='id'
                wrapperClass='class-responsive'
                fetchInfo={ { dataTotalSize: props.totalDataSize } }
                options={ options }
            >
                {
                    props.tableHead.map((column, index) => {
                        return <TableHeaderColumn
                            row={column.row}
                            rowSpan={column.rowSpan}
                            colSpan={column.colSpan}
                            width={column.width}
                            key={column.dataField}
                            dataField={column.dataField}
                            headerAlign={column.headerAlign}
                            dataAlign={column.dataAlign}
                            dataSort={column.dataSort}
                            className={column.className}
                            columnClassName={column.columnClassName}
                            dataFormat={(cell, row) => customFormatter(cell, row, column.dataFormat)}
                            editable={false}
                            thStyle={{ whiteSpace: 'normal' }}
                        >
                            {column.title}
                        </TableHeaderColumn>
                    })
                }
            </BootstrapTable>
        </div>
    )
}

export default TableMonitoring