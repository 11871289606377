import React, { Component } from 'react'
import { ImPlay3, ImSearch, ImPrinter } from 'react-icons/im';
import { AiFillLock } from 'react-icons/ai';
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonTable from '../../../commons/table/TableMonitoring';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import APIReport from '../../../services/APIReport';
import Swal from 'sweetalert2';
import APIWilayah from '../../../services/APIWilayah';
import APIDashboard from "../../../services/APIDashboard";
import APIMonitoringBaru from '../../../services/APIMonitoringBaru';
import APIPemutakhiran from '../../../services/APIPemutakhiran';
import APIPemutakhiranMonitoring from '../../../services/APIPemutakhiranMonitoring';
import APIRekapitulasi from '../../../services/APIRekapitulasi';

class Rekapitulasi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panel1: true,
            sizePerPage: 10,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],

            // text1: 'KODE',
            // text2: 'NAMA PROVINSI',
            // text3: 'TARGET KK',
            // text4: 'DIDATA',
            // text5: 'TIDAK DITEMUI',
            // text6: 'DITOLAK',
            // text7: 'TIDAK MENJAWAB',
            // text8: 'BELUM DIDATA',
            // text9: 'ACTION',

            text1: 'KODE',
            text2: 'NAMA PROVINSI',
            text3: 'YANG ADA',
            text4: 'YANG DIDATA',
            text5: 'YANG DITEMUI',
            text6: 'BARU',
            text7: 'YANG PINDAH',
            text8: 'MENINGGAL DUNIA',
            text9: 'YANG TIDAK DITEMUKAN',
            text10: 'BERCERAI',
            text11: 'ACTION',

            btnLock: false,
            btnUnlock: false,

            datas: [],
            data_test: [{ kode: '01', nama: 'RT 001', statusLock: 'unlock' }, { kode: '02', nama: 'RT 002', statusLock: 'lock' }],

            optJenisRekap: [],

            selectedJenisRekapId: 0,
            jenis_rekap: '',

            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
            selectedPeriodePendataanId: 2023,
            periode_pendataan: [{ value: 2023, label: '2023' }],

            tk_wilayah: 0,


            //Wilayah
            dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
            dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

            cariRW: false,
            roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,

            //datas: [],
            //selectDatas: [],
            sendDatas: {
                // "tingkatWilayahID": 0,
                // "idProv": 0,
                // "idKab": 0,
                // "idKec": 0,
                // "idKel": 0,
                // "idRW": 0,
                // "periodePendataan": 0,

                "v_id_provinsi": 0,
                "v_id_kabupaten": 0,
                "v_id_kecamatan": 0,
                "v_id_kelurahan": 0,
                "v_id_rw": 0,
                "v_periode_sensus": 2023
            }
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    setHeaderTable = () => {
        let { text1, text2, text3, text4, text5, text6, text7, text8, text9, text10, text11 } = this.state
        var headerColumns = [
            // { width: '100', title: text1, dataField: "kode", headerAlign: 'center', dataAlign: 'center', editable: false },
            // { width: '250', title: text2, dataField: "nama", headerAlign: 'center', dataAlign: 'center', editable: false },
            // { width: '100', title: text3, dataField: "rowtargetKK", headerAlign: 'center', dataAlign: 'right', editable: false },
            // { width: '100', title: text4, dataField: "rowJumlahKKygDidata", headerAlign: 'center', dataAlign: 'right', editable: false },
            // { width: '100', title: text5, dataField: "rowJumlahygTidakDapatDitemui", headerAlign: 'center', dataAlign: 'right', editable: false },
            // { width: '100', title: text6, dataField: "rowKJmlKKygDitolak", headerAlign: 'center', dataAlign: 'right', editable: false },
            // { width: '100', title: text7, dataField: "rowJmlKKygTidakMampuMenjawab", headerAlign: 'center', dataAlign: 'right', editable: false },
            // { width: '100', title: text8, dataField: "rowKKygBelumDidata", headerAlign: 'center', dataAlign: 'right', editable: false },
            // { width: '100', title: text9, dataField: "rowMutasiOut", headerAlign: 'center', dataAlign: 'right', editable: false },

            { width: '100', title: text1, dataField: "kode", headerAlign: 'center', dataAlign: 'center', editable: false },
            { width: '250', title: text2, dataField: "nama_Daerah", headerAlign: 'center', dataAlign: 'center', editable: false },
            { width: '100', title: text3, dataField: "kk_Ada", headerAlign: 'center', dataAlign: 'right', editable: false },
            { width: '100', title: text4, dataField: "kk_diData", headerAlign: 'center', dataAlign: 'right', editable: false },
            { width: '100', title: text5, dataField: "kk_diTemui", headerAlign: 'center', dataAlign: 'right', editable: false },
            { width: '100', title: text6, dataField: "kk_Baru", headerAlign: 'center', dataAlign: 'right', editable: false },
            { width: '100', title: text7, dataField: "kk_Pindah", headerAlign: 'center', dataAlign: 'right', editable: false },
            { width: '100', title: text8, dataField: "kk_Meninggal", headerAlign: 'center', dataAlign: 'right', editable: false },
            { width: '100', title: text9, dataField: "kk_tdk_Ditemukan", headerAlign: 'center', dataAlign: 'right', editable: false },
            { width: '100', title: text10, dataField: "kk_Bercerai", headerAlign: 'center', dataAlign: 'right', editable: false },
            { width: '100', title: text11, dataField: "rowMutasiOut", headerAlign: 'center', dataAlign: 'right', editable: false },
        ];
        this.setState({ tableHead: headerColumns });
    }

    componentDidMount() {
        this.setState({ optJenisRekap: [] });
        this.setHeaderTable()
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
        let title2, newData

        if (this.state.roleUserLogin === 6) {
            const data_md = this.state.data_test.filter(data => data.statusLock === 'lock')
            this.setState({ data_test: data_md })
        }

        if (idTingkatWilayah == 0) {
            title2 = ''
            newData = [{ value: 1, label: 'PROVINSI' }, { value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
            { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

            this.setState({
                sendDatas: {
                    // "tingkatWilayahID": 0,
                    // "idProv": 0,
                    // "idKab": 0,
                    // "idKec": 0,
                    // "idKel": 0,
                    // "idRW": 0,
                    // "periodePendataan": 0,

                    "v_id_provinsi": 0,
                    "v_id_kabupaten": 0,
                    "v_id_kecamatan": 0,
                    "v_id_kelurahan": 0,
                    "v_id_rw": 0,
                    "v_periode_sensus": 0
                },
                optJenisRekap: newData
            });

        } else if (idTingkatWilayah == 1) {
            title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi
            newData = [{ value: 1, label: 'PROVINSI' }, { value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
            { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

            this.setState(
                {
                    sendDatas: {
                        // "tingkatWilayahID": 1,
                        // "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        // "idKab": 0,
                        // "idKec": 0,
                        // "idKel": 0,
                        // "idRW": 0,
                        // "periodePendataan": 0,

                        "v_id_provinsi": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "v_id_kabupaten": 0,
                        "v_id_kecamatan": 0,
                        "v_id_kelurahan": 0,
                        "v_id_rw": 0,
                        "v_periode_sensus": 0
                    },
                    optJenisRekap: newData
                }
            )

        } else if (idTingkatWilayah == 2) {
            title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten
            newData = [{ value: 2, label: 'KABUPATEN' }, { value: 3, label: 'KECAMATAN' },
            { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

            this.setState(
                {
                    sendDatas: {
                        // "tingkatWilayahID": 2,
                        // "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        // "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        // "idKec": 0,
                        // "idKel": 0,
                        // "idRW": 0,
                        // "periodePendataan": 0,

                        "v_id_provinsi": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "v_id_kabupaten": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        "v_id_kecamatan": 0,
                        "v_id_kelurahan": 0,
                        "v_id_rw": 0,
                        "v_periode_sensus": 0
                    },
                    optJenisRekap: newData
                },
                //() => {this.getBackend(this.state.sendDatas)}
            )

        } else if (idTingkatWilayah === 3) {
            title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan
            newData = [{ value: 3, label: 'KECAMATAN' }, { value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]

            this.setState(
                {
                    sendDatas: {
                        // "tingkatWilayahID": 3,
                        // "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        // "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        // "idKec": rest[0].attributes.user.wilKecamatan.id_kecamatan,
                        // "idKel": 0,
                        // "idRW": 0,
                        // "periodePendataan": 0,

                        "v_id_provinsi": rest[0].attributes.user.wilProvinsi.id_provinsi,
                        "v_id_kabupaten": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                        "v_id_kecamatan": rest[0].attributes.user.wilKecamatan.id_kecamatan,
                        "v_id_kelurahan": 0,
                        "v_id_rw": 0,
                        "v_periode_sensus": 0
                    },
                    optJenisRekap: newData
                }
            )

        } else if (idTingkatWilayah === 4) {
            newData = [{ value: 4, label: 'KELURAHAN' }, { value: 5, label: 'RW' }]
            this.setState({
                sendDatas: {
                    // "tingkatWilayahID": 4,
                    // "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
                    // "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                    // "idKec": rest[0].attributes.user.wilKecamatan.id_kecamatan,
                    // "idKel": rest[0].attributes.user.wilKelurahan.id_kelurahan,
                    // "idRW": 0,
                    // "periodePendataan": 0,

                    "v_id_provinsi": rest[0].attributes.user.wilProvinsi.id_provinsi,
                    "v_id_kabupaten": rest[0].attributes.user.wilKabupaten.id_kabupaten,
                    "v_id_kecamatan": rest[0].attributes.user.wilKecamatan.id_kecamatan,
                    "v_id_kelurahan": rest[0].attributes.user.wilKelurahan.id_kelurahan,
                    "v_id_rw": 0,
                    "v_periode_sensus": 0
                },
                optJenisRekap: newData
            })
        } else {
            newData = [{ value: 5, label: 'RW' }]
            this.setState({
                optJenisRekap: newData
            })

        }

        this.setState({ title: title2 },
            () => { this.setHeaderTable() }
        )

        if (rest === false) { this.props.history.push('/401') }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
                this.setState({ flagSearch: false },
                    () => {
                        this.setProv(null)
                    }
                )
            } else {
                if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                //--prepare by level
                if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                    this.setState({ dis_prov: true, auto_prov: true })
                }
                const wilProvinsi = rest[0].attributes.user.wilProvinsi
                const wilKabupaten = rest[0].attributes.user.wilKabupaten
                const wilKecamatan = rest[0].attributes.user.wilKecamatan
                const wilKelurahan = rest[0].attributes.user.wilKelurahan

                if (Object.keys(wilProvinsi).length > 0) {

                    const objProp = {
                        'value': wilProvinsi.id_provinsi,
                        'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
                    };

                    this.setState({ dt_prov: objProp },
                        () => {
                            if (this.state.auto_prov === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(objProp)
                            }
                        }
                    );
                    //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                }
                //        alert(wilKabupaten.length)
                if (Object.keys(wilKabupaten).length > 0) {
                    //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                    const objKab = {
                        'value': wilKabupaten.id_kabupaten,
                        'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
                    };

                    this.setState({ dt_kab: objKab },
                        () => {
                            if (this.state.auto_kab === true) {
                                console.log("objKab", objKab)
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kab(objKab)
                            }
                        }
                    );
                }

                if (Object.keys(wilKecamatan).length > 0) {
                    const objKec = {
                        'value': wilKecamatan.id_kecamatan,
                        'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
                    };

                    this.setState({ dt_kec: objKec },
                        () => {
                            if (this.state.auto_kec === true) {
                                console.log("objKec", objKec)
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_kec(objKec)
                            }
                        }
                    );
                    //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                }

                if (Object.keys(wilKelurahan).length > 0) {
                    const objKel = {
                        'value': wilKelurahan.id_kelurahan,
                        'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
                    };

                    this.setState({ dt_kel: objKel },
                        () => {
                            if (this.state.auto_des === true) {
                                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_des(objKel)
                            }
                        }
                    );
                    this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                }
            }
        } else {
            this.setProv(null);
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }


    //API (Wilayah)
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            //handleSearch
            this.setState({
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 1,
                    // "idProv": +e.value,

                    "kategori_Wilayah": "Provinsi",
                    "v_id_provinsi": +e.value,
                }
            }/*,
                () => {this.getBackend(this.state.sendDatas)}*/
            );

            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        } else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 0,
                    // "idProv": 0,
                    // "idKab": 0,
                    // "idKec": 0,
                    // "idKel": 0,
                    // "idRW": 0,

                    "kategori_Wilayah": "Provinsi",
                    "v_id_provinsi": 0,
                    "v_id_kabupaten": 0,
                    "v_id_kecamatan": 0,
                    "v_id_kelurahan": 0,
                    "v_id_rw": 0,
                    "v_periode_sensus": 0
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 2,
                    // "idProv": this.state.sendDatas.idProv,
                    // "idKab": +e.value,
                    // "idKec": 0,
                    // "idKel": 0,
                    // "idRW": 0,

                    "kategori_Wilayah": "Kabupaten",
                    "v_id_kabupaten": +e.value,
                }
            }/*,
                () => {this.getBackend(this.state.sendDatas)} */
            );
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
                , id_kecamatan: 0, id_des: 0, id_rw: 0
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        } else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 1,
                    // "idProv": this.state.sendDatas.idProv,
                    // "idKab": 0,
                    // "idKec": 0,
                    // "idKel": 0,
                    // "idRW": 0,

                    "kategori_Wilayah": "Kabupaten",
                    // "v_id_provinsi": 0,
                    "v_id_kabupaten": 0,
                    "v_id_kecamatan": 0,
                    "v_id_kelurahan": 0,
                    "v_id_rw": 0,
                    "v_periode_sensus": 0
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 3,
                    // "idProv": this.state.sendDatas.idProv,
                    // "idKab": this.state.sendDatas.idKab,
                    // "idKec": +e.value,
                    // "idKel": 0,
                    // "idRW": 0,

                    "kategori_Wilayah": "Kecamatan",
                    "v_id_kecamatan": +e.value,
                }
            });
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
                , id_des: 0, id_rw: 0
            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        } else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 2,
                    // "idProv": this.state.sendDatas.idProv,
                    // "idKab": this.state.sendDatas.idKab,
                    // "idKec": 0,
                    // "idKel": 0,
                    // "idRW": 0,

                    "kategori_Wilayah": "Kecamatan",
                    // "v_id_provinsi": 0,
                    // "v_id_kabupaten": 0,
                    "v_id_kecamatan": 0,
                    "v_id_kelurahan": 0,
                    "v_id_rw": 0,
                    "v_periode_sensus": 0
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 4,
                    // "idProv": this.state.sendDatas.idProv,
                    // "idKab": this.state.sendDatas.idKab,
                    // "idKec": this.state.sendDatas.idKec,
                    // "idKel": +e.value,
                    // "idRW": 0,

                    "kategori_Wilayah": "Kelurahan",
                    "v_id_kelurahan": +e.value,
                }
            }/*,
                () => {this.getBackend(this.state.sendDatas)} */
            );
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
                , id_rw: 0
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        } else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 3,
                    // "idProv": this.state.sendDatas.idProv,
                    // "idKab": this.state.sendDatas.idKab,
                    // "idKec": this.state.sendDatas.idKec,
                    // "idKel": 0,
                    // "idRW": 0,

                    "kategori_Wilayah": "Kelurahan",
                    // "v_id_provinsi": 0,
                    // "v_id_kabupaten": 0,
                    // "v_id_kecamatan": 0,
                    "v_id_kelurahan": 0,
                    "v_id_rw": 0,
                    "v_periode_sensus": 0
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 5,
                    // "idProv": this.state.sendDatas.idProv,
                    // "idKab": this.state.sendDatas.idKab,
                    // "idKec": this.state.sendDatas.idKec,
                    // "idKel": this.state.sendDatas.idKel,
                    // "idRW": +e.value,

                    "kategori_Wilayah": "RW",
                    "v_id_rw": +e.value,
                }
            }/*,
                () => {this.getBackend(this.state.sendDatas)} */
            );
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        } else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
                sendDatas: {
                    ...this.state.sendDatas,
                    // "tingkatWilayahID": 4,
                    // "idProv": this.state.sendDatas.idProv,
                    // "idKab": this.state.sendDatas.idKab,
                    // "idKec": this.state.sendDatas.idKec,
                    // "idKel": this.state.sendDatas.idKel,
                    // "idRW": 0,

                    "kategori_Wilayah": "RW",
                    // "v_id_provinsi": 0,
                    // "v_id_kabupaten": 0,
                    // "v_id_kecamatan": 0,
                    // "v_id_kelurahan": 0,
                    "v_id_rw": 0,
                    "v_periode_sensus": 0
                }
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah


    sel_periodePendataan = (e) => {
        if (e) {
            this.setState({
                periode_pendataan: e,
                selectedPeriodePendataanId: +e.value,
                sendDatas: {
                    ...this.state.sendDatas,
                    // "periodePendataan": +e.value,
                    "v_periode_sensus": +e.value
                }
            });

        }
        else {
            this.setState({
                sendDatas: {
                    ...this.state.sendDatas,
                    // "periodePendataan": 0,
                    "v_periode_sensus": 0,
                },
                periode_pendataan: null, selectedPeriodePendataanId: 0,
            },
                () => {
                    if (e === null) {
                    }
                }
            )
        }
    }

    // sel_jenisRekap = (e) => {
    //   if (e) {
    //     console.log('e', e)
    //     const rest = JSON.parse(localStorage.getItem("authority"));
    //     this.setState({
    //       jenis_rekap: e,
    //       selectedJenisRekapId:e.value,
    //     });

    //   }
    //   else {
    //       this.setState({
    //         jenis_rekap: null, selectedJenisRekapId: 0,
    //       },
    //           () => {
    //               if (e === null) {
    //               }
    //           }
    //       )
    //   }
    // }

    handlePageChange = (page, rowsPerPage) => {
        this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    }

    setLabel = () => {
        let title, text1, text2
        const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
        if (id_rt !== 0) {
            title = ' di RT ' + this.state.rt.label
        } else {
            title = ''
            if (id_rw !== 0) {
                title = ' di RW ' + this.state.rw.label
                text1 = 'KODE RT'
                text2 = 'NAMA RT'
            } else {
                title = ''
                if (id_des !== 0) {
                    title = ' di Kelurahan ' + this.state.desa.label
                    text1 = 'KODE RW'
                    text2 = 'NAMA RW'
                } else {
                    title = ''
                    if (id_kecamatan !== 0) {
                        title = ' di Kecamatan ' + this.state.kecamatan.label
                        text1 = 'KODE KELURAHAN'
                        text2 = 'NAMA KELURAHAN'
                    } else {
                        title = ''
                        if (id_kabupaten !== 0) {
                            title = ' di Kabupaten ' + this.state.kabupaten.label
                            text1 = 'KODE KECAMATAN'
                            text2 = 'NAMA KECAMATAN'
                        } else {
                            title = ''
                            if (id_provinsi !== 0) {
                                title = ' di Provinsi ' + this.state.provinsi.label
                                text1 = 'KODE KABUPATEN'
                                text2 = 'NAMA KABUPATEN'
                            }
                            else {
                                title = ' Tingkat Nasional'
                                text1 = 'KODE PROVINSI'
                                text2 = 'NAMA PROVINSI'
                            }
                        }
                    }
                }
            }
        }
        this.setState({ title: title, text1: text1, text2: text2 },
            () => { this.setHeaderTable() }
        )
    }

    handleSearch = () => {
        // if (this.state.sendDatas.periodePendataan !== 0) {
        if (this.state.sendDatas.v_periode_sensus !== 0) {
            this.setLabel()
            if (this.state.rw !== null) {
                this.setState({ btnLock: true, btnUnlock: true, cariRW: true })
            } else if (this.state.rw === null) {
                this.setState({ btnLock: false, cariRW: false })
            } else {
                this.setState({ cariRW: false })
            }

            this.setState({ blocking: true })
            // APIDashboard.post("/pkportal/monitoring/getRekapitulasi", this.state.sendDatas)
            APIRekapitulasi.post("/pkportal/monitoring/getRekapMonitoring", this.state.sendDatas)
                .then((res) => {
                    // console.log(res.data['list-rekap-monitoring'], "RES >>>")
                    this.setState({ blocking: false });
                    if (res.status === 200) {
                        this.setState(
                            {
                                datas: res.data['list-rekap-monitoring']
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.setState({ blocking: false });
                    console.log(error, "dataaa ERROR");
                });
        } else {
            Swal.fire({
                title: 'Info',
                icon: 'info',
                text: 'Silakan pilih periode pendataan.',
            });
        }
    }

    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    handleCetak = (e) => {
        // if (this.handleValidation()) {
        this.setState({ blocking: true });
        const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;
        //this.setJudulLaporan()

        let judul_lap, kode_lap
        //const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
        if (id_rt !== 0) {
            kode_lap = this.state.rt.kode
            judul_lap = this.state.rt.namaWil
        } else {
            kode_lap = ''
            judul_lap = ''
            if (id_rw !== 0) {
                kode_lap = this.state.rw.kode
                judul_lap = this.state.rw.namaWil
            } else {
                kode_lap = ''
                judul_lap = ''
                if (id_des !== 0) {
                    kode_lap = this.state.desa.kode
                    judul_lap = this.state.desa.namaWil
                } else {
                    kode_lap = ''
                    judul_lap = ''
                    if (id_kecamatan !== 0) {
                        kode_lap = this.state.kecamatan.kode
                        judul_lap = this.state.kecamatan.namaWil
                    } else {
                        kode_lap = ''
                        judul_lap = ''
                        if (id_kabupaten !== 0) {
                            kode_lap = this.state.kabupaten.kode
                            judul_lap = this.state.kabupaten.namaWil
                        } else {
                            kode_lap = ''
                            judul_lap = ''
                            if (id_provinsi !== 0) {
                                kode_lap = this.state.provinsi.kode
                                judul_lap = this.state.provinsi.namaWil
                            } else {
                                kode_lap = ''
                                judul_lap = ' Tingkat Nasional'
                            }
                        }
                    }
                }
            }
        }

        this.setState({ judul_laporan: judul_lap, kode_laporan: kode_lap }, () => console.log('judul', this.state.judul_laporan, 'kode', this.state.kode_laporan))

        Swal.fire({
            title: 'Cetak Formulir',
            //text: "Anda akan mencetak formulir",
            icon: 'info',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Excel',
            confirmButtonColor: '#4ea842',
            showDenyButton: true,
            denyButtonText: 'PDF',
            denyButtonColor: '#FF8000',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        }).then((result) => {

            let url = 'viewReport?filterPencarian=%7B'

            if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
            if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
            if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
            if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
            if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

            //let urlCetakPdf = url +'%22ketWilayah%22%3A%22'+'tes' + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C'+ '%22title%22%3A%22' +this.state.title+ '%22%2C' +'%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'


            //viewReport?filterPencarian=%7B%22provinsiId%22%3A%221%22%2C%22kabupatenId%22%3A%227%22%2C%22kecamatanId%22%3A%224155%22%2C%22ketWilayah%22%3A%22Nama%20wilayah%20inputan%22%2C%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D

            //{"provinsiId":"1","kabupatenId":"7","kecamatanId":"4155","ketWilayah":"Nama wilayah inputan","reportName":"TABEL1","reportType":"pdf","reportCategory":"MasterWilayah"}

            //let coba =''
            //coba = decodeURI(this.state.judul_laporan)
            let urlCetakPdf = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
            console.log('pdf', urlCetakPdf)
            let urlCetakExcel = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

            if (result.isConfirmed) {
                APIReport.get(urlCetakExcel, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.xlsx', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        // console.log("error ===> ", error)
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });

            } else if (result.isDenied) {
                APIReport.get(urlCetakPdf, { responseType: 'blob' })
                    .then(res => {
                        // console.log(res, 'res report')
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.pdf', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        // console.log("error ===> ", error)
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });

            } else {
                this.setState({ blocking: false });
            }
        })
        e.preventDefault()
    }

    render() {
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;

        const options = {
            page: 1,
            sizePerPageList: [{
                text: '1', value: 1
            }, {
                text: '10', value: 10
            }], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
        };
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                <div className='content'>
                    <div className='title-content'><ImPlay3 className='icon-brd' />Rekapitulasi</div>
                    <div className='body-content'>
                        <Box sx={{ width: '100%' }}>
                            <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                                <AccordionSummary
                                    className='panelSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                                >
                                    <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                {/* <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Jenis Rekapitulasi
                                                        <Select options={this.state.optJenisRekap} id="jenisRekap" name="jenisRekap" onChange={this.sel_jenisRekap} value={this.state.jenis_rekap} placeholder="Pilih Jenis Data" isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid> */}
                                            </Grid>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Periode
                                                        <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small" >
                                                        Provinsi
                                                        <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kabupaten
                                                        <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kecamatan
                                                        <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        Kelurahan
                                                        <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        RW
                                                        <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <Grid item xs={12}>
                                                        <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} />Cari</Button>
                                                        <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <h3>Rekapitulasi {this.state.title}</h3>
                        <Paper sx={{ width: '100%', boxShadow: 'none' }}>

                            <div className='rekap-table'>
                                <BootstrapTable data={this.state.datas}
                                    pagination={true}
                                    options={options}
                                    search={true}
                                >

                                    {/* <TableHeaderColumn width='80' isKey={true} dataField='kode' headerAlign='center' dataAlign='center' >KODE</TableHeaderColumn>
                                    <TableHeaderColumn width='220' dataField='nama' headerAlign='center' dataAlign='left'>{this.state.text2}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='rowtargetKK' headerAlign='center' dataAlign='center'>{this.state.text3}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='rowJumlahKKygDidata' headerAlign='center' dataAlign='center'>{this.state.text4}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='rowJumlahygTidakDapatDitemui' headerAlign='center' dataAlign='center'>{this.state.text5}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='rowKJmlKKygDitolak' headerAlign='center' dataAlign='center'>{this.state.text6}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='rowJmlKKygTidakMampuMenjawab' headerAlign='center' dataAlign='center'>{this.state.text7}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='rowKKygBelumDidata' headerAlign='center' dataAlign='center'>{this.state.text8}</TableHeaderColumn>
                                    {this.state.cariRW === true ? <TableHeaderColumn width='100' dataField='' headerAlign='center' dataAlign='center'>PUS PESERTA KB</TableHeaderColumn> : null}
                                    {this.state.cariRW === true ? <TableHeaderColumn width='100' dataField='' headerAlign='center' dataAlign='center'>PUS BUKAN PESERTA KB</TableHeaderColumn> : null}
                                    {this.state.cariRW === true ? <TableHeaderColumn width='100' dataField='' headerAlign='center' dataAlign='center'>PUS HAMIL</TableHeaderColumn> : null} */}

                                    <TableHeaderColumn width='80' isKey={true} dataField='kode' headerAlign='center' dataAlign='center' >KODE</TableHeaderColumn>
                                    <TableHeaderColumn width='220' dataField='nama_Daerah' headerAlign='center' dataAlign='left'>{this.state.text2}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='kk_Ada' headerAlign='center' dataAlign='center'>{this.state.text3}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='kk_diData' headerAlign='center' dataAlign='center'>{this.state.text4}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='kk_diTemui' headerAlign='center' dataAlign='center'>{this.state.text5}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='kk_Baru' headerAlign='center' dataAlign='center'>{this.state.text6}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='kk_Pindah' headerAlign='center' dataAlign='center'>{this.state.text7}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='kk_Meninggal' headerAlign='center' dataAlign='center'>{this.state.text8}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='kk_tdk_Ditemukan' headerAlign='center' dataAlign='center'>{this.state.text9}</TableHeaderColumn>
                                    <TableHeaderColumn width='100' dataField='kk_Bercerai' headerAlign='center' dataAlign='center'>{this.state.text10}</TableHeaderColumn>
                                    {this.state.cariRW === true ? <TableHeaderColumn width='100' dataField='pus_Peserta_KB' headerAlign='center' dataAlign='center'>PUS PESERTA KB</TableHeaderColumn> : null}
                                    {this.state.cariRW === true ? <TableHeaderColumn width='100' dataField='pus_Bukan_Peserta_KB' headerAlign='center' dataAlign='center'>PUS BUKAN PESERTA KB</TableHeaderColumn> : null}
                                    {this.state.cariRW === true ? <TableHeaderColumn width='100' dataField='pus_Hamil' headerAlign='center' dataAlign='center'>PUS HAMIL</TableHeaderColumn> : null}

                                </BootstrapTable>
                            </div>
                            <div className='sizePerPage'>
                                Size Per Page
                                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                                    <option value='1'>1</option>
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value={this.state.datas?.length}>All</option>
                                </select>
                            </div>
                            <div className='totalData'><b>Total Data: {this.state.datas?.length}</b></div>


                            {/* <div className='totalData'><b>Total Data: {this.state.data_test.length}</b></div> */}
                        </Paper>
                    </div>
                </div>
            </BlockUi>
        )
    }
}

export default Rekapitulasi;