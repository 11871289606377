import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { AiFillCaretDown, AiFillCaretRight, AiFillLock } from 'react-icons/ai';
import { ImBin, ImLoop2, ImPencil, ImPlus, ImSearch } from 'react-icons/im';
import Swal from 'sweetalert2';
import APIMutasiKK from '../../services/APIMutasiKK';
import APIWilayah from '../../services/APIWilayah';
import TransferDialog from '../TransferDialog';
import TransferKKDialog from '../TransferKKDialog';

const isNumber = (str) => /^[0-9]*$/.test(str);
// (d === 2 || d === 4) && (hourWib >= 6 && hourWib <= 20) 

//---table kk---
class BSTableKk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelKk: true,
      openAddKk: false,
      openPindahKK: false,
      dataKK: this.props.data,
      tingkatWilayahId: this.props.tingkatWilayahId,
      roleId: this.props.roleId,
      blocking: false,
      disablePindahKK: false,
      rowRt: this.props.row,
      rowRw: this.props.rowRw,
      rowKel: this.props.rowKel,
      rowKec: this.props.rowKec,
      rowKab: this.props.rowKab,
      rowProv: this.props.rowProv,
      value_priode: this.props.value_priode,
      lockUnlock: false
    }
  }

  componentDidUpdate = (prevProps) => {

    if (this.props.tingkatWilayahId !== prevProps.tingkatWilayahId) {
      this.setState({ tingkatWilayahId: this.props.tingkatWilayahId })
    }
    if (this.props.roleId !== prevProps.roleId) {
      this.setState({ roleId: this.props.roleId })
    }
    if (this.props.data?.length !== prevProps.data?.length) {
      this.setState({ dataKK: this.props.data })
    }
    if (this.props.row !== prevProps.row) {
      this.setState({ rowRt: this.props.row })
    }
    if (this.props.rowRw !== prevProps.rowRw) {
      this.setState({ rowRw: this.props.rowRw })
    }
    if (this.props.rowKel !== prevProps.rowKel) {
      this.setState({ rowKel: this.props.rowKel })
    }
    if (this.props.rowKec !== prevProps.rowKec) {
      this.setState({ rowKec: this.props.rowKec })
    }
    if (this.props.rowKab !== prevProps.rowKab) {
      this.setState({ rowKab: this.props.rowKab })
    }
    if (this.props.rowProv !== prevProps.rowProv) {
      this.setState({ rowProv: this.props.rowProv })
    }
    if (this.props.value_priode !== prevProps.value_priode) {
      this.setState({ value_priode: this.props.value_priode })
    }



  }

  handleChangePanelKk = (e) => {
    e.preventDefault();
    this.setState({ panelKk: !this.state.panelKk })
  }

  buttonFormatter = (cell, row) => {
    return <div className='divBtnTable'>
      <Button disabled={this.state.disRTAction} className='btnTable' variant='contained' color='info'><ImSearch /></Button>
    </div>
  }

  handleOpenCloseAddKk = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openAddKk: !this.state.openAddKk })
  }

  handleOpenClosePindahKK = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openPindahKK: !this.state.openPindahKK })
  }

  render() {
    // var d = new Date().getDay(); 
    // var hourWib = new Date().getUTCHours() + 7;
    //var h = new Date().getHours(); 
    //var t = new Date().getTimezoneOffset();
    // 
    // 


    const options = {
      page: 1,
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: '<',
      nextPage: '>',
      firstPage: '<<',
      lastPage: '>>',
      paginationShowsTotal: false,
      paginationPosition: 'bottom',
      hideSizePerPage: true
    };
    return (
      <>
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
          <Accordion expanded={this.props.panelKk} className='accordionRoot panelExpand'>
            <AccordionSummary
              className='panelSummary'
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.props.handleChangePanelKk} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontSize: '12px' }}><b>Data KK</b> di RT {this.props.row.nama_rt}</Typography>
              <div className='divBtnPanel'>
                {/* <Button className='btnPanel' onClick={this.handleOpenClosePindahKK}><ImLoop2 style={{ marginRight: '3px' }} /> Pindah</Button> */}
                {/* <Button className='btnPanel' onClick={this.handleOpenCloseAddKk}><ImStatsBars style={{ marginRight: '3px' }} /> Ringkasan</Button> */}
                {/* {(this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 || this.state.roleId === 6) ? */}
                  <>
                    {/* {(!this.state.rowProv.statusLock && !this.state.rowKab.statusLock && !this.state.rowKec.statusLock && !this.state.rowKel.statusLock && !this.state.rowRw.statusLock && !this.state.rowRt.statusLock && !this.state.statusLock) &&  */}
                    {/* {(this.state.roleId === 1 || this.state.roleId === 99 ) && */}
                    {(this.state.roleId === 1 || this.state.roleId === 99) &&
                      <Button className='btnPanel' onClick={this.handleOpenClosePindahKK}><ImLoop2 style={{ marginRight: '3px' }} /> Pindah</Button>
                    }

                  </>
                  :
                  <></>
              
              </div>
            </AccordionSummary>
            <AccordionDetails className='panelDetail'>
              {(this.props.data) ?
                <>
                  <BootstrapTable data={this.props.data}
                    pagination={true}
                    options={options}
                    search={true}>
                    <TableHeaderColumn width='180px' dataField='kki' isKey={true} headerAlign='center' dataAlign='center'>KKI</TableHeaderColumn>
                    <TableHeaderColumn width='180px' dataField='nik' headerAlign='center' dataAlign='center'>NIK</TableHeaderColumn>
                    <TableHeaderColumn dataField='nama' headerAlign='center'>Nama</TableHeaderColumn>
                    <TableHeaderColumn width='90px' dataField='hubungan' headerAlign='center' dataAlign='center'>Hubungan</TableHeaderColumn>
                    {/* <TableHeaderColumn width='120px' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter}>Aksi</TableHeaderColumn> */}
                  </BootstrapTable>
                  <div className='totalDataExpand'>Total Data: {this.props.data?.length}</div>
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Tidak ada data</div>
              }
            </AccordionDetails>
          </Accordion>
          <Dialog open={this.state.openAddKk} onClose={this.handleOpenCloseAddKk}>
            <DialogTitle>Ringkasan KK</DialogTitle>
            <DialogContent>
              <DialogContentText>
                RT: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_rt}<br />
              </DialogContentText>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={12} md={6}>
                  Total KK
                </Grid>
                <Grid item xs={12} md={6}>
                  Total Individu
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleOpenCloseAddKk} variant="outlined" color="primary">Tutup</Button>
            </DialogActions>
          </Dialog>

          <TransferKKDialog
            tkWilayah='KK'
            wilayah='KK'
            wilayahId='7'
            dataWilayah={this.state.dataKK}
            open={this.state.openPindahKK}
            onClose={this.handleOpenClosePindahKK}
            kodeDepdagri={this.props.row.kodeDepdagri}
            nama={this.props.row.nama_rt}
            id={this.props.row.id_rt}
          >
          </TransferKKDialog>
        </BlockUi>
      </>
    )
  }
}

//---table rt---
class BSTableRt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelKk: true,
      openAddRt: false,
      openPindahRt: false,
      id: null,
      kodeDepdagri: '',
      nama: '',
      dataRt: this.props.data,
      tingkatWilayahId: this.props.tingkatWilayahId,
      roleId: this.props.roleId,
      blocking: false,
      statusDuplikat: false,
      sdTampil: false,
      kodeDepdagriLama: '',
      disabledSimpanRt: false,
      provinsiId: this.props.provinsiId,
      disEditRT: false,
      dishapusRT: false,
      rowRw: this.props.row,
      rowKel: this.props.rowKel,
      rowKec: this.props.rowKec,
      rowKab: this.props.rowKab,
      rowProv: this.props.rowProv,
      value_priode: this.props.value_priode,
      lockUnlock: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.data?.length !== prevProps.data?.length) {
      this.setState({ dataRt: this.props.data })
    }
    if (this.props.tingkatWilayahId !== prevProps.tingkatWilayahId) {
      this.setState({ tingkatWilayahId: this.props.tingkatWilayahId })
    }
    if (this.props.roleId !== prevProps.roleId) {
      this.setState({ roleId: this.props.roleId })
    }
    if (this.props.provinsiId !== prevProps.provinsiId) {
      this.setState({ provinsiId: this.props.provinsiId })
    }
    if (this.props.row !== prevProps.row) {
      this.setState({ rowRw: this.props.row })
    }
    if (this.props.rowKel !== prevProps.rowKel) {
      this.setState({ rowKel: this.props.rowKel })
    }
    if (this.props.rowKec !== prevProps.rowKec) {
      this.setState({ rowKec: this.props.rowKec })
    }
    if (this.props.rowKab !== prevProps.rowKab) {
      this.setState({ rowKab: this.props.rowKab })
    }
    if (this.props.rowProv !== prevProps.rowProv) {
      this.setState({ rowProv: this.props.rowProv })
    }
    if (this.props.value_priode !== prevProps.value_priode) {
      this.setState({ value_priode: this.props.value_priode })
    }
  }

  isExpandableRow(row) {
    return true
  }

  handleChangePanelKk = (e) => {
    e.preventDefault();
    this.setState({ panelKk: !this.state.panelKk })
  }

  expandComponent = (row) => {
    return (
      <BSTableKk row={row} rowRw={this.state.rowRw} rowKel={this.state.rowKel} rowKec={this.state.rowKec} rowKab={this.state.rowKab} rowProv={this.state.rowProv}
        data={row.children} handleChangePanelKk={this.handleChangePanelKk} panelKk={this.state.panelKk}
        tingkatWilayahId={this.state.tingkatWilayahId} roleId={this.state.roleId} provinsiId={this.state.provinsiId} value_priode={this.state.value_priode} />
    );
  }

  expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';

    if (isExpandableRow) {
      content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
    } else {
      content = ' ';
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
    );
  }

  buttonFormatter = (cell, row) => {

    // var d = new Date().getDay(); 
    // var hourWib = new Date().getUTCHours() + 7;
    if (this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 || this.state.roleId === 6 || this.state.roleId === 3) {
      return <div className='divBtnTable'>
        {(!this.props.rowProv.statusLock && !this.props.rowKab.statusLock && !this.props.rowKec.statusLock && !this.props.rowKel.statusLock && !this.state.rowRw.statusLock && !row.statusLock) ?
          <>
            <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
            {this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
            {/* {this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 || this.state.roleId === 6 || this.state.roleId === 3 ?  <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>} */}
          </>
          :
          <>
            {(row.statusLock === false) ?
              <>
                <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
                {this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
              </>
              :
              <>
                <div style={{ marginRight: '5px', marginTop: '2px', fontWeight: '600' }}>Locked</div>
              </>
            }

          </>
        }
        <Button className='btnTable' variant='contained' color='success' onClick={(e) => this.handleLockUnlockById(e, row)}><AiFillLock /></Button>
      </div>
    } else {
      return <>-</>
    }

  }

  handleOpenCloseAddRt = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openAddRt: !this.state.openAddRt },
      () => { if (!this.state.openAddRt) { this.clearForm() } }
    )
  }

  handleOpenClosePindahRt = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openPindahRt: !this.state.openPindahRt })
  }

  onRowClick = (row) => {
    if (!row.hasOwnProperty('children')) {
      APIMutasiKK.get('/pkportal/targetkk/getListKKByIdRt?idRT=' + row.id_rt + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
        .then(res => {
          this.setState({ tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, provinsiId: this.props.provinsiId, dataRt: this.state.dataRt.map(el => (el.id_rt === row.id_rt ? { ...el, children: res.data } : el)) });
        }).catch((error) => {

        });
      // const kk = [{ id: '11010120010100001001009', nik: '3572890928901322', nama: 'Tes Tampil KK 1', hubungan: 'KK' },
      // { id: '11010120010100001001010', nik: '3572829029201021', nama: 'Tes Tampil KK 2', hubungan: 'KK' },
      // { id: '11010120010100001001011', nik: '3572453029201023', nama: 'Tes Tampil KK 3', hubungan: 'KK' },
      // { id: '11010120010100001001012', nik: '3572811089271024', nama: 'Tes Tampil KK 4', hubungan: 'KK' },
      // { id: '11010120010100001001013', nik: '3572828902920105', nama: 'Tes Tampil KK 5', hubungan: 'KK' },
      // { id: '11010120010100001001014', nik: '3578724329201025', nama: 'Tes Tampil KK 6', hubungan: 'KK' },
      // { id: '11010120010100001001015', nik: '3578724329201026', nama: 'Tes Tampil KK 7', hubungan: 'KK' },
      // { id: '11010120010100001001016', nik: '3578724329201027', nama: 'Tes Tampil KK 8', hubungan: 'KK' },
      // { id: '11010120010100001001017', nik: '3578724329201028', nama: 'Tes Tampil KK 9', hubungan: 'KK' },
      // { id: '11010120010100001001018', nik: '3578724329201029', nama: 'Tes Tampil KK 10', hubungan: 'KK' },
      // { id: '11010120010100001001019', nik: '3578724329201030', nama: 'Tes Tampil KK 11', hubungan: 'KK' }]
    }
  }

  clearForm = () => {
    this.setState({ id: null, kodeDepdagri: '', nama: '' })
  }

  handleDelete = (e, row) => {
    e.stopPropagation();
    Swal.fire({
      title: 'Peringatan Hapus RT',
      text: "Apakah anda yakin menghapus [" + row.kodeDepdagri + " - " + row.nama_rt + "] ?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        const payload = {
          "idRT": row.id_rt,
          "jenisAktivitas": 3,
          "jlhRT": 1,
          "jlhUser": 0,
          "userLogin": localStorage.getItem('username')
        }
        APIWilayah.post('/userwilayahrt/DeleteRT?periodeSensus=' + this.state.value_priode, payload)
          .then(res => {
            if (res.status === 200) {
              if (res.data.deleteRT === 'failed (error: 500->)Gagal hapus karena ada data KK dibawah rt ') {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal dihapus karena ada data KK di bawah RT',
                });
              } else {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil dihapus',
                })
                this.setState({ dataRt: this.state.dataRt.filter(el => el.id_rt !== row.id_rt) })
              }
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal dihapus',
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal dihapus',
            });
          });
      }
    })
  }

  handleEdit = (e, row) => {
    e.stopPropagation();
    this.setState({ id: row.id_rt, kodeDepdagri: row.kodeDepdagri, nama: row.nama_rt, kodeDepdagriLama: row.kodeDepdagri, statusDuplikat: row.statusDuplikat }, () => { this.handleOpenCloseAddRt() })
  }

  handleChangeInput = (e) => {
    if (e.target.name === 'kodeDepdagri') {
      if (isNumber(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value })
      }
    } else {
      const pointer = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
      this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    }
  }

  handleAddRt = () => {
    if (this.state.kodeDepdagri && this.state.kodeDepdagri.length > 0 && this.state.nama && this.state.nama.length > 0) {
      if (this.state.kodeDepdagri.length === 3) {
        const cek = this.state.dataRt?.find(el => el.kodeDepdagri.replace(/ /g, "") === this.state.kodeDepdagri)

        if (cek === undefined || (cek !== undefined && cek.id_rt === this.state.id)) {
          Swal.fire({
            title: 'Peringatan',
            text: "[" + this.state.kodeDepdagri + " - " + this.state.nama + "] Apakah anda yakin data sudah benar? ",
            icon: 'warning',
            cancelButtonText: 'Batal!',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              this.setState({ disabledSimpanRt: true })
              if (this.state.id) { //edit
                let sd = this.state.statusDuplikat
                if (sd === true) {
                  if (this.state.kodeDepdagri !== this.state.kodeDepdagriLama) {
                    sd = true
                    this.setState({ sdTampil: false })
                  }
                }
                APIWilayah.post('/userwilayahrt/EditRT?idRt=' + this.state.id + '&kodeRt=' + this.state.kodeDepdagri + '&namaRt=' + this.state.nama + '&statusDuplikat=' + sd + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      Swal.fire({
                        title: 'Sukses',
                        icon: 'success',
                        text: 'Data berhasil disimpan',
                      })

                      const arr = { id_rt: this.state.id, nama_rt: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: this.state.sdTampil, periodeSensus: this.state.value_priode }
                      this.setState({ dataRt: this.state.dataRt.map(el => el.id_rt === this.state.id ? Object.assign({}, el, arr) : el), openAddRt: false, disabledSimpanRt: false }, () => { this.clearForm() })
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanRt: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanRt: false })
                  });
              } else { //tambah
                const payload = {
                  idRw: this.props.row.id_rw,
                  kodeRT: this.state.kodeDepdagri,
                  namaRT: this.state.nama,
                  provinsiId: this.props.provinsiId,
                  userName: localStorage.getItem('username'),
                  kodeRw: this.state.rowRw.kodeDepdagri,
                  namaRw: this.state.rowRw.nama_rw,
                  kodeKel: this.state.rowKel.kodeDepdagri,
                  idKel: this.state.rowKel.id_kelurahan,
                  namaKel: this.state.rowKel.nama_kelurahan,
                  kodeKec: this.state.rowKec.kodeDepdagri,
                  idKec: this.state.rowKec.id_kecamatan,
                  namaKec: this.state.rowKec.nama_kecamatan,
                  kodeKab: this.state.rowKab.kodeDepdagri,
                  idKab: this.state.rowKab.id_kabupaten,
                  namaKab: this.state.rowKab.nama_kabupaten,
                  kodeProv: this.state.rowProv.kodeDepdagri,
                  idProv: this.state.rowProv.id_provinsi,
                  namaProv: this.state.rowProv.nama
                }
                APIWilayah.post('/userwilayahrt/AddRT?periodeSensus=' + this.state.value_priode, payload)
                  .then(res => {
                    if (res.status === 200) {
                      if (res.data.ID === 0) {
                        Swal.fire({
                          title: 'Error',
                          icon: 'warning',
                          text: 'Data gagal disimpan',
                        })
                        this.setState({ disabledSimpanRt: false })
                      } else {
                        Swal.fire({
                          title: 'Sukses',
                          icon: 'success',
                          text: 'Data berhasil disimpan',
                        })
                        const arr = { id_rt: res.data.ID, nama_rt: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: false, statusLock: false, periodeSensus: this.state.value_priode }
                        this.setState({ dataRt: [...this.state.dataRt, arr], openAddRt: false, disabledSimpanRt: false }, () => { this.clearForm() })
                      }
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanRt: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanRt: false })
                  });
              }
            } else {
              this.setState({ disabledSimpanRt: false })
            }

          });
        } else {
          Swal.fire({
            title: 'Peringatan',
            icon: 'warning',
            text: 'Kode Depdagri telah digunakan.',
          });
        }
      } else {
        Swal.fire({
          title: 'Peringatan',
          icon: 'warning',
          text: 'Kode Depdagri RT harus 3 digit.',
        });
      }
    } else {
      Swal.fire({
        title: 'Peringatan',
        icon: 'warning',
        text: 'Kode Depdagri dan Nama RT tidak boleh kosong.',
      });
    }
  }

  columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
    if (row.statusDuplikat === true) {
      return 'edafaf'
    } else {
      return ''
    }
  }

  handleLockUnlock = (e, param) => {
    const label = param ? 'Lock' : 'Unlock'
    const id = ''
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin akan melakukan " + label + " data seluruh rw?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=6' + '&idParent=' + this.state.rowRw.id_rw + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
          .then(res => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Sukses',
                icon: 'success',
                text: 'Data berhasil di ' + label,
              })
              this.setState({ lockUnlock: param, dataRt: this.state.dataRt.map(el => Object.assign({}, el, { statusLock: param })) })
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal di ' + label,
            });
          });
      }
    })
  }

  handleLockUnlockById = (e, row) => {
    e.stopPropagation();
    const label = row.statusLock ? 'Unlock' : 'Lock'
    const param = !row.statusLock
    const id = row.id_rt
    let role = JSON.parse(localStorage.getItem("authority"))[0].attributes.role

    if ((row.lastModifiedBy === 'superadmin' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true) ||
      (row.lastModifiedBy === 'admindev' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true)) {
      Swal.fire({
        title: 'Info!',
        icon: 'warning',
        text: 'Lock Hanya Bisa Di Unlock Superadmin',
      });
    } else {
      Swal.fire({
        title: 'Peringatan',
        text: "Apakah anda yakin akan melakukan " + label + " data " + row.nama_rt + " ?",
        icon: 'warning',
        cancelButtonText: 'Batal!',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=6' + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
            .then(res => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil di ' + label,
                })
                this.setState({ dataRt: this.state.dataRt.map(el => el.id_rt === id ? Object.assign({}, el, { statusLock: param }) : el) })
              } else {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal di ' + label,
                });
              }
            }).catch((error) => {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            });
        }
      })
    }
  }

  render() {
    var d = new Date().getDay();
    var hourWib = new Date().getUTCHours() + 7;

    const options = {
      onRowClick: this.onRowClick,
      expandRowBgColor: '#F5F5DC',
      expandBy: 'column',

      page: 1,
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: '<',
      nextPage: '>',
      firstPage: '<<',
      lastPage: '>>',
      paginationShowsTotal: false,
      paginationPosition: 'bottom',
      hideSizePerPage: true
    };
    return (
      <>
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
          <Accordion expanded={this.props.panelRt} className='accordionRoot panelExpand'>
            <AccordionSummary
              className='panelSummary'
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.props.handleChangePanelRt} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontSize: '12px' }}><b>Data RT</b> di RW {this.props.row.nama_rw}</Typography>

              {(this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 || this.state.roleId === 6 || this.state.roleId === 3) ?
                <div className='divBtnPanel'>
                  {(!this.state.rowProv?.statusLock && !this.state.rowKab?.statusLock && !this.state.rowKec?.statusLock && !this.state.rowKel?.statusLock && !this.state.rowRw?.statusLock && !this.state.lockUnlock) &&
                    <>
                      <Button className='btnPanel' onClick={this.handleOpenCloseAddRt}><ImPlus style={{ marginRight: '3px' }} /> Tambah</Button>
                      {/* || this.state.roleId === 6 || this.state.roleId === 2 */}
                      {/* {(this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 6 || this.state.roleId === 2) && */}
                      {(this.state.roleId === 1 || this.state.roleId === 99) &&
                        <Button className='btnPanel' onClick={this.handleOpenClosePindahRt}><ImLoop2 style={{ marginRight: '3px' }} /> Pindah</Button>
                      }
                    </>
                  }
                  {/* <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, true)} ><AiFillLock style={{ marginRight: '3px' }} /> Lock</Button>
                  <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, false)} ><AiFillLock style={{ marginRight: '3px' }} /> Unlock</Button> */}
                </div>
                :
                <></>
              }
            </AccordionSummary>
            <AccordionDetails className='panelDetail'>
              {(this.state.dataRt) ?
                <>
                  <BootstrapTable data={this.state.dataRt}
                    pagination={true}
                    search={true}
                    options={options}
                    expandableRow={this.isExpandableRow}
                    expandComponent={this.expandComponent}
                    expandColumnOptions={{
                      expandColumnVisible: true,
                      expandColumnComponent: this.expandColumnComponent,
                      columnWidth: 40
                    }}>
                    <TableHeaderColumn width='120px' dataField='kodeDepdagri' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode RT</TableHeaderColumn>
                    <TableHeaderColumn dataField='nama_rt' headerAlign='center' expandable={false}>Nama RT</TableHeaderColumn>
                    <TableHeaderColumn width='120px' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Aksi</TableHeaderColumn>
                  </BootstrapTable>
                  <div className='totalDataExpand'>Total Data: {this.state.dataRt?.length}</div>
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Tidak ada data</div>
              }
            </AccordionDetails>
          </Accordion>
          <Dialog open={this.state.openAddRt} onClose={this.handleOpenCloseAddRt}>
            <DialogTitle>{this.state.id ? 'Ubah' : 'Tambah'} RT</DialogTitle>
            <DialogContent>
              <DialogContentText>
                RW: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_rw}<br />
              </DialogContentText>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    inputProps={{ maxLength: 3 }}
                    id="filled-required"
                    label="Kode RT"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.kodeDepdagri}
                    name="kodeDepdagri"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="filled-required"
                    label="Nama RT"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.nama}
                    name="nama"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={this.state.disabledSimpanRt} onClick={this.handleAddRt} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
              <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenCloseAddRt} variant="outlined" color="error">Batal</Button>
            </DialogActions>
          </Dialog>
          <TransferDialog
            tkWilayah='RT'
            wilayah='RT'
            wilayahId='6'
            dataWilayah={this.state.dataRt}
            open={this.state.openPindahRt}
            onClose={this.handleOpenClosePindahRt}
            kodeDepdagri={this.props.row.kodeDepdagri}
            nama={this.props.row.nama_rw}
            id={this.props.row.id_rw}
            value_priode={this.state.value_priode}

          >
          </TransferDialog>
        </BlockUi>
      </>
    )
  }
}

//---table rw---
class BSTableRw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelRt: true,
      openAddRw: false,
      openPindahRw: false,
      id: null,
      kodeDepdagri: '',
      nama: '',
      dataRw: this.props.data,
      tingkatWilayahId: this.props.tingkatWilayahId,
      roleId: this.props.roleId,
      blocking: false,
      statusDuplikat: false,
      sdTampil: false,
      kodeDepdagriLama: '',
      disabledSimpanRw: false,
      provinsiId: this.props.provinsiId,
      disEditRW: false,
      dishapusRW: false,
      disEditRT: this.props.disEditRT,
      dishapusRT: this.props.dishapusRT,
      rowKel: this.props.row,
      rowKec: this.props.rowKec,
      rowKab: this.props.rowKab,
      rowProv: this.props.rowProv,
      value_priode: this.props.value_priode,
      lockUnlock: false
    }
  }

  componentDidUpdate = (prevProps) => {

    if (this.props.data?.length !== prevProps.data?.length) {
      this.setState({ dataRw: this.props.data })
    }
    if (this.props.tingkatWilayahId !== prevProps.tingkatWilayahId) {
      this.setState({ tingkatWilayahId: this.props.tingkatWilayahId })
    }
    if (this.props.roleId !== prevProps.roleId) {
      this.setState({ roleId: this.props.roleId })
    }
    if (this.props.provinsiId !== prevProps.provinsiId) {
      this.setState({ provinsiId: this.props.provinsiId })
    }
    if (this.props.row !== prevProps.row) {
      this.setState({ rowKel: this.props.row })
    }
    if (this.props.rowKec !== prevProps.rowKec) {
      this.setState({ rowKec: this.props.rowKec })
    }
    if (this.props.rowKab !== prevProps.rowKab) {
      this.setState({ rowKab: this.props.rowKab })
    }
    if (this.props.rowProv !== prevProps.rowProv) {
      this.setState({ rowProv: this.props.rowProv })
    }
    if (this.props.value_priode !== prevProps.value_priode) {
      this.setState({ value_priode: this.props.value_priode })
    }
  }

  isExpandableRow(row) {
    return true
  }

  handleChangePanelRt = (e) => {
    e.preventDefault();
    this.setState({ panelRt: !this.state.panelRt })
  }

  expandComponent = (row) => {
    return (
      <BSTableRt row={row} rowKel={this.state.rowKel} rowKec={this.state.rowKec} rowKab={this.state.rowKab} rowProv={this.state.rowProv}
        data={row.children} handleChangePanelRt={this.handleChangePanelRt} panelRt={this.state.panelRt}
        tingkatWilayahId={this.state.tingkatWilayahId} roleId={this.state.roleId} provinsiId={this.state.provinsiId} value_priode={this.state.value_priode} />
    );
  }

  expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';

    if (isExpandableRow) {
      content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
    } else {
      content = ' ';
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
    );
  }

  buttonFormatter = (cell, row) => {
    // var d = new Date().getDay(); 
    // var hourWib = new Date().getUTCHours() + 7;
    if (this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 || this.state.roleId === 6 || this.state.roleId === 3) {
      return <div className='divBtnTable'>
        {(!this.props.rowProv.statusLock && !this.props.rowKab.statusLock && !this.props.rowKec.statusLock && !this.state.rowKel.statusLock && !row.statusLock) ?
          <>
            <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
            {/* {this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 || this.state.roleId === 6 || this.state.roleId === 3 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>} */}
            {this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
          </>
          :
          <>
            {(row.statusLock === false) ?
              <>
                <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
                {this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
              </>
              :
              <>
                <div style={{ marginRight: '5px', marginTop: '2px', fontWeight: '600' }}>Locked</div>
              </>
            }

          </>
        }
        <Button className='btnTable' variant='contained' color='success' onClick={(e) => this.handleLockUnlockById(e, row)}><AiFillLock /></Button>
      </div>
    } else {
      return <>-</>
    }

  }

  handleOpenCloseAddRw = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openAddRw: !this.state.openAddRw },
      () => { if (!this.state.openAddRw) { this.clearForm() } }
    )
  }

  handleOpenClosePindahRw = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openPindahRw: !this.state.openPindahRw },
      () => { if (!this.state.openAddRw) { this.clearForm() } }
    )
  }

  onRowClick = (row) => {
    if (!row.hasOwnProperty('children')) {
      this.setState({ blocking: true })
      APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + row.id_rw + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
        .then(res => {
          this.setState({ blocking: false, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, rowProv: this.props.rowProv, rowKab: this.props.rowKab, rowKec: this.props.rowKec, rowKel: this.props.row, provinsiId: this.props.provinsiId, dataRw: this.state.dataRw.map(el => (el.id_rw === row.id_rw ? { ...el, children: res.data } : el)) });
        }).catch((error) => {
          this.setState({ blocking: false })

        });
    }
  }

  clearForm = () => {
    this.setState({ id: null, kodeDepdagri: '', nama: '' })
  }

  handleDelete = (e, row) => {
    e.stopPropagation();
    Swal.fire({
      title: 'Peringatan Hapus RW',
      text: "Apakah anda yakin menghapus [" + row.kodeDepdagri + " - " + row.nama_rw + "] ?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        const payload = {
          "idRW": row.id_rw,
          "jenisAktivitas": 3,
          "jlhRW": 1,
          "jlhUser": 0,
          "userLogin": localStorage.getItem('username')
        }
        APIWilayah.post('/userwilayah/DeleteRW?periodeSensus=' + this.state.value_priode, payload)
          .then(res => {
            if (res.status === 200) {
              if (res.data.deleteRW === 'failed (error: 500->)Gagal hapus karena ada data wilayah dibawah rw ') {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal dihapus karena ada data wilayah di bawah RW',
                });
              } else {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil dihapus',
                })
                this.setState({ dataRw: this.state.dataRw.filter(el => el.id_rw !== row.id_rw) })
              }
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal dihapus',
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal dihapus',
            });
          });
      }
    })
  }

  handleEdit = (e, row) => {
    e.stopPropagation();
    this.setState({ id: row.id_rw, kodeDepdagri: row.kodeDepdagri, nama: row.nama_rw, kodeDepdagriLama: row.kodeDepdagri, statusDuplikat: row.statusDuplikat }, () => { this.handleOpenCloseAddRw() })
  }

  handleChangeInput = (e) => {
    if (e.target.name === 'kodeDepdagri') {
      if (isNumber(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value })
      }
    } else {
      const pointer = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
      this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    }
  }

  handleAddRw = () => {
    if (this.state.kodeDepdagri && this.state.kodeDepdagri.length > 0 && this.state.nama && this.state.nama.length > 0) {
      if (this.state.kodeDepdagri.length === 4) {
        const cek = this.state.dataRw.find(el => el.kodeDepdagri.replace(/ /g, "") === this.state.kodeDepdagri)
        if (cek === undefined || (cek !== undefined && cek.id_rw === this.state.id)) {
          Swal.fire({
            title: 'Peringatan',
            text: "[" + this.state.kodeDepdagri + " - " + this.state.nama + "] Apakah anda yakin data sudah benar? ",
            icon: 'warning',
            cancelButtonText: 'Batal!',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              this.setState({ disabledSimpanRw: true })
              if (this.state.id) {
                let sd = this.state.statusDuplikat
                if (sd === true) {
                  if (this.state.kodeDepdagri !== this.state.kodeDepdagriLama) {
                    sd = true
                    this.setState({ sdTampil: false })
                  }
                }
                APIWilayah.post('/userwilayah/EditRW?idRw=' + this.state.id + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaRw=' + this.state.nama + '&statusduplikat=' + sd + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      Swal.fire({
                        title: 'Sukses',
                        icon: 'success',
                        text: 'Data berhasil disimpan',
                      })

                      const arr = { id_rw: this.state.id, nama_rw: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: this.state.sdTampil, periodeSensus: this.state.value_priode }
                      this.setState({ dataRw: this.state.dataRw.map(el => el.id_rw === this.state.id ? Object.assign({}, el, arr) : el), openAddRw: false, disabledSimpanRw: false }, () => { this.clearForm() })
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanRw: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanRw: false })
                  });
              } else { //tambah
                APIWilayah.post('/userwilayah/AddRW?idKelurahan=' + this.props.row.id_kelurahan + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaRw=' + this.state.nama + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      if (res.data.ID === 0) {
                        Swal.fire({
                          title: 'Error',
                          icon: 'warning',
                          text: 'Data gagal disimpan',
                        })
                        this.setState({ disabledSimpanRw: false })
                      } else {
                        Swal.fire({
                          title: 'Sukses',
                          icon: 'success',
                          text: 'Data berhasil disimpan',
                        })
                        const arr = { id_rw: res.data.ID, nama_rw: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: false, statusLock: false, periodeSensus: this.state.value_priode }
                        this.setState({ dataRw: [...this.state.dataRw, arr], openAddRw: false, disabledSimpanRw: false }, () => { this.clearForm() })
                      }
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanRw: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanRw: false })
                  });
              }
            } else {
              this.setState({ disabledSimpanRw: false })
            }
          });
        } else {
          Swal.fire({
            title: 'Peringatan',
            icon: 'warning',
            text: 'Kode Depdagri telah digunakan.',
          });
        }
      } else {
        Swal.fire({
          title: 'Peringatan',
          icon: 'warning',
          text: 'Kode Depdagri RW harus 4 digit.',
        });
      }
    } else {
      Swal.fire({
        title: 'Peringatan',
        icon: 'warning',
        text: 'Kode Depdagri dan Nama RW tidak boleh kosong.',
      });
    }
  }

  columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
    if (row.statusDuplikat === true) {
      return 'edafaf'
    } else {
      return ''
    }
  }

  handleLockUnlock = (e, param) => {
    const label = param ? 'Lock' : 'Unlock'
    const id = ''
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin akan melakukan " + label + " data seluruh rw?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=5' + '&idParent=' + this.state.rowKel.id_kelurahan + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
          .then(res => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Sukses',
                icon: 'success',
                text: 'Data berhasil di ' + label,
              })
              this.setState({ lockUnlock: param, dataRw: this.state.dataRw.map(el => Object.assign({}, el, { statusLock: param })) })
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal di ' + label,
            });
          });
      }
    })
  }

  handleLockUnlockById = (e, row) => {
    e.stopPropagation();
    const label = row.statusLock ? 'Unlock' : 'Lock'
    const param = !row.statusLock
    const id = row.id_rw
    let role = JSON.parse(localStorage.getItem("authority"))[0].attributes.role

    if ((row.lastModifiedBy === 'superadmin' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true) ||
      (row.lastModifiedBy === 'admindev' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true)) {
      Swal.fire({
        title: 'Info!',
        icon: 'warning',
        text: 'Lock Hanya Bisa Di Unlock Superadmin',
      });
    } else {

      Swal.fire({
        title: 'Peringatan',
        text: "Apakah anda yakin akan melakukan " + label + " data " + row.nama_rw + " ?",
        icon: 'warning',
        cancelButtonText: 'Batal!',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=5' + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
            .then(res => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil di ' + label,
                })
                this.setState({ dataRw: this.state.dataRw.map(el => el.id_rw === id ? Object.assign({}, el, { statusLock: param }) : el) })
              } else {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal di ' + label,
                });
              }
            }).catch((error) => {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            });
        }
      })
    }
  }

  render() {
    var d = new Date().getDay();
    var hourWib = new Date().getUTCHours() + 7;
    const options = {
      onRowClick: this.onRowClick,
      expandRowBgColor: '#F5F5DC',
      expandBy: 'column',

      page: 1,
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: '<',
      nextPage: '>',
      firstPage: '<<',
      lastPage: '>>',
      paginationShowsTotal: false,
      paginationPosition: 'bottom',
      hideSizePerPage: true
    };
    return (
      <>
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
          <Accordion expanded={this.props.panelRw} className='accordionRoot panelExpand'>
            <AccordionSummary
              className='panelSummary'
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.props.handleChangePanelRw} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontSize: '12px' }}><b>Data RW</b> di Kelurahan {this.props.row.nama_kelurahan}</Typography>

              {(this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 || this.state.roleId === 6 || this.state.roleId === 3) ?
                <div className='divBtnPanel'>
                  {(!this.props.rowProv?.statusLock && !this.props.rowKab?.statusLock && !this.props.rowKec?.statusLock && !this.state.rowKel?.statusLock && !this.state.lockUnlock) &&
                    <>
                      {console.log("PASSSSS")}

                      <Button className='btnPanel' onClick={this.handleOpenCloseAddRw}><ImPlus style={{ marginRight: '3px' }} /> Tambah</Button>
                      {/* || this.state.roleId === 6 || this.state.roleId === 2? */}
                      {/* {(this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 6 || this.state.roleId === 2) && */}
                      {(this.state.roleId === 1 || this.state.roleId === 99) &&
                        <Button className='btnPanel' onClick={this.handleOpenClosePindahRw}><ImLoop2 style={{ marginRight: '3px' }} /> Pindah</Button>
                      }
                    </>
                  }
                  {/* <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, true)} ><AiFillLock style={{ marginRight: '3px' }} /> Lock</Button>
                  <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, false)} ><AiFillLock style={{ marginRight: '3px' }} /> Unlock</Button> */}
                </div>
                :
                <></>
              }

            </AccordionSummary>
            <AccordionDetails className='panelDetail'>
              {(this.state.dataRw) ?
                <>
                  <BootstrapTable data={this.state.dataRw}
                    pagination={true}
                    search={true}
                    options={options}
                    expandableRow={this.isExpandableRow}
                    expandComponent={this.expandComponent}
                    expandColumnOptions={{
                      expandColumnVisible: true,
                      expandColumnComponent: this.expandColumnComponent,
                      columnWidth: 40
                    }}>
                    <TableHeaderColumn width='120px' dataField='kodeDepdagri' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode RW</TableHeaderColumn>
                    <TableHeaderColumn dataField='nama_rw' headerAlign='center' expandable={false}>Nama RW</TableHeaderColumn>
                    <TableHeaderColumn width='120px' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Aksi</TableHeaderColumn>
                  </BootstrapTable>
                  <div className='totalDataExpand'>Total Data: {this.state.dataRw?.length}</div>
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Tidak ada data</div>
              }
            </AccordionDetails>
          </Accordion>
          <Dialog open={this.state.openAddRw} onClose={this.handleOpenCloseAddRw}>
            <DialogTitle>{this.state.id ? 'Ubah' : 'Tambah'} RW</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Kelurahan: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_kelurahan}<br />
              </DialogContentText>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    inputProps={{ maxLength: 4 }}
                    id="filled-required"
                    label="Kode RW"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.kodeDepdagri}
                    name="kodeDepdagri"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="filled-required"
                    label="Nama RW"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.nama}
                    name="nama"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={this.state.disabledSimpanRw} onClick={this.handleAddRw} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
              <Button disabled={this.state.disabledSimpanRw} onClick={this.handleOpenCloseAddRw} variant="outlined" color="error">Batal</Button>
            </DialogActions>
          </Dialog>
          <TransferDialog
            tkWilayah='RW'
            wilayah='RW'
            wilayahId='5'
            dataWilayah={this.state.dataRw}
            open={this.state.openPindahRw}
            onClose={this.handleOpenClosePindahRw}
            kodeDepdagri={this.props.row.kodeDepdagri}
            nama={this.props.row.nama_kelurahan}
            id={this.props.row.id_kelurahan}
            value_priode={this.state.value_priode}

          >
          </TransferDialog>
        </BlockUi>
      </>
    )
  }
}

//---table kel---
class BSTableKel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelRw: true,
      openAddKel: false,
      openPindahKel: false,
      id: null,
      kodeDepdagri: '',
      nama: '',
      dataKel: this.props.data,
      tingkatWilayahId: this.props.tingkatWilayahId,
      roleId: this.props.roleId,
      blocking: false,
      statusDuplikat: false,
      sdTampil: false,
      kodeDepdagriLama: '',
      disabledSimpanKel: false,
      provinsiId: this.props.provinsiId,
      disEditKel: false,
      dishapusKel: false,
      rowKec: this.props.row,
      value_priode: this.props.value_priode,
      rowKab: this.props.rowKab,
      rowProv: this.props.rowProv,
      lockUnlock: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.data?.length !== prevProps.data?.length) {
      this.setState({ dataKel: this.props.data })
    }
    if (this.props.tingkatWilayahId !== prevProps.tingkatWilayahId) {
      this.setState({ tingkatWilayahId: this.props.tingkatWilayahId })
    }
    if (this.props.roleId !== prevProps.roleId) {
      this.setState({ roleId: this.props.roleId })
    }
    if (this.props.provinsiId !== prevProps.provinsiId) {
      this.setState({ provinsiId: this.props.provinsiId })
    }
    if (this.props.row !== prevProps.row) {
      this.setState({ rowKec: this.props.row })
    }
    if (this.props.rowKab !== prevProps.rowKab) {
      this.setState({ rowKab: this.props.rowKab })
    }
    if (this.props.rowProv !== prevProps.rowProv) {
      this.setState({ rowProv: this.props.rowProv })
    }
    if (this.props.value_priode !== prevProps.value_priode) {
      this.setState({ value_priode: this.props.value_priode })
    }
  }

  isExpandableRow(row) {
    return true
  }

  handleChangePanelRw = (e) => {
    e.preventDefault();
    this.setState({ panelRw: !this.state.panelRw })
  }

  expandComponent = (row) => {
    return (
      <BSTableRw row={row} rowKec={this.state.rowKec} rowKab={this.state.rowKab} rowProv={this.state.rowProv} data={row.children} handleChangePanelRw={this.handleChangePanelRw} panelRw={this.state.panelRw}
        tingkatWilayahId={this.state.tingkatWilayahId} roleId={this.state.roleId} provinsiId={this.state.provinsiId} value_priode={this.state.value_priode} />
    );
  }

  expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';

    if (isExpandableRow) {
      content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
    } else {
      content = ' ';
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
    );
  }

  buttonFormatter = (cell, row) => {
    // var d = new Date().getDay(); 
    // var hourWib = new Date().getUTCHours() + 7;
    if (this.state.roleId === 1 || this.state.roleId === 99) {
      return <div className='divBtnTable'>
        {(!this.props.rowProv.statusLock && !this.props.rowKab.statusLock && !this.state.rowKec.statusLock && !row.statusLock) ?
          <>
            <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
            {this.state.roleId === 1 || this.state.roleId === 99 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
          </>
          :
          <>
            {(row.statusLock === false) ?
              <>
                <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
                {this.state.roleId === 1 || this.state.roleId === 99 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
              </>
              :
              <>
                <div style={{ marginRight: '5px', marginTop: '2px', fontWeight: '600' }}>Locked</div>
              </>
            }

          </>
        }
        <Button className='btnTable' variant='contained' color='success' onClick={(e) => this.handleLockUnlockById(e, row)}><AiFillLock /></Button>
      </div>
    } else {
      return <>-</>
    }
  }

  handleOpenCloseAddKel = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openAddKel: !this.state.openAddKel },
      () => { if (!this.state.openAddKel) { this.clearForm() } }
    )
  }

  handleOpenClosePindahKel = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openPindahKel: !this.state.openPindahKel },
      () => { if (!this.state.openAddKel) { this.clearForm() } }
    )
  }

  onRowClick = (row) => {
    if (!row.hasOwnProperty('children')) {
      this.setState({ blocking: true })
      APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + row.id_kelurahan + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
        .then(res => {
          this.setState({ blocking: false, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, rowProv: this.props.rowProv, rowKab: this.props.rowKab, rowKec: this.props.row, provinsiId: this.props.provinsiId, dataKel: this.state.dataKel.map(el => (el.id_kelurahan === row.id_kelurahan ? { ...el, children: res.data } : el)) });
        }).catch((error) => {
          this.setState({ blocking: false })

        });
    }
  }

  clearForm = () => {
    this.setState({ id: null, kodeDepdagri: '', nama: '' })
  }

  handleDelete = (e, row) => {
    e.stopPropagation();
    Swal.fire({
      title: 'Peringatan Hapus Kelurahan',
      text: "Apakah anda yakin menghapus [" + row.kodeDepdagri + " - " + row.nama_kelurahan + "] ?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        const payload = {
          "idKel": row.id_kelurahan,
          "jenisAktivitas": 3,
          "jlhKel": 1,
          "jlhUser": 1,
          "userLogin": localStorage.getItem('username')
        }
        APIWilayah.post('/userwilayah/DeleteKelurahan?periodeSensus=' + this.state.value_priode, payload)
          .then(res => {

            if (res.status === 200) {
              if (res.data.deleteKelurahan === 'failed (error: 500->)Gagal hapus karena ada data wilayah dibawah kelurahan ') {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal dihapus karena ada data wilayah di bawah kelurahan',
                })
                //this.setState({ dataKel: this.state.dataKel.filter(el => el.id_kelurahan !== row.id_kelurahan) })
              } else {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil dihapus',
                })
                this.setState({ dataKel: this.state.dataKel.filter(el => el.id_kelurahan !== row.id_kelurahan) })
              }
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal dihapus',
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal dihapus',
            });
          });
      }
    })
  }

  handleEdit = (e, row) => {
    e.stopPropagation();
    this.setState({ id: row.id_kelurahan, kodeDepdagri: row.kodeDepdagri, nama: row.nama_kelurahan, kodeDepdagriLama: row.kodeDepdagri, statusDuplikat: row.statusDuplikat }, () => { this.handleOpenCloseAddKel() })
  }

  handleChangeInput = (e) => {
    if (e.target.name === 'kodeDepdagri') {
      if (isNumber(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value })
      }
    } else {
      const pointer = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
      this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    }
  }

  handleAddKel = () => {
    if (this.state.kodeDepdagri && this.state.kodeDepdagri.length > 0 && this.state.nama && this.state.nama.length > 0) {
      if (this.state.kodeDepdagri.length === 4) {
        const cek = this.state.dataKel.find(el => el.kodeDepdagri.replace(/ /g, "") === this.state.kodeDepdagri)
        if (cek === undefined || (cek !== undefined && cek.id_kelurahan === this.state.id)) {
          Swal.fire({
            title: 'Peringatan',
            text: "[" + this.state.kodeDepdagri + " - " + this.state.nama + "] Apakah anda yakin data sudah benar? ",
            icon: 'warning',
            cancelButtonText: 'Batal!',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              this.setState({ disabledSimpanKel: true })
              if (this.state.id) { //edit
                let sd = this.state.statusDuplikat
                if (sd === true) {
                  if (this.state.kodeDepdagri !== this.state.kodeDepdagriLama) {
                    sd = true
                    this.setState({ sdTampil: false })
                  }
                }
                APIWilayah.post('/userwilayah/EditKelurahan?idKelurahan=' + this.state.id + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaKelurahan=' + this.state.nama + '&statusDuplikat=' + sd + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      Swal.fire({
                        title: 'Sukses',
                        icon: 'success',
                        text: 'Data berhasil disimpan',
                      })
                      let sd = this.state.statusDuplikat
                      if (this.state.kodeDepdagri !== this.state.kodeDepdagriLama) {
                        sd = false
                      }

                      const arr = { id_kelurahan: this.state.id, nama_kelurahan: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: this.state.sdTampil, periodeSensus: this.state.value_priode }
                      this.setState({ dataKel: this.state.dataKel.map(el => el.id_kelurahan === this.state.id ? Object.assign({}, el, arr) : el), openAddKel: false, disabledSimpanKel: false }, () => { this.clearForm() })
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanKel: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanKel: false })
                  });
              } else { //tambah
                APIWilayah.post('/userwilayah/AddKelurahan?idKecamatan=' + this.props.row.id_kecamatan + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaKelurahan=' + this.state.nama + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      if (res.data.ID === 0) {
                        Swal.fire({
                          title: 'Error',
                          icon: 'warning',
                          text: 'Data gagal disimpan',
                        })
                        this.setState({ disabledSimpanKel: false })
                      } else {
                        Swal.fire({
                          title: 'Sukses',
                          icon: 'success',
                          text: 'Data berhasil disimpan',
                        })
                        const arr = { id_kelurahan: res.data.ID, nama_kelurahan: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: false, statusLock: false, periodeSensus: this.state.value_priode }
                        this.setState({ dataKel: [...this.state.dataKel, arr], openAddKel: false, disabledSimpanKel: false }, () => { this.clearForm() })
                      }
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanKel: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanKel: false })
                  });
              }
            } else {
              this.setState({ disabledSimpanKel: false })
            }
          });
        } else {
          Swal.fire({
            title: 'Peringatan',
            icon: 'warning',
            text: 'Kode Depdagri telah digunakan.',
          });
        }
      } else {
        Swal.fire({
          title: 'Peringatan',
          icon: 'warning',
          text: 'Kode Depdagri Kelurahan harus 4 digit.',
        });
      }
    } else {
      Swal.fire({
        title: 'Peringatan',
        icon: 'warning',
        text: 'Kode Depdagri dan Nama Kelurahan tidak boleh kosong.',
      });
    }
  }

  columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
    if (row.statusDuplikat === true) {
      return 'edafaf'
    } else {
      return ''
    }
  }

  handleLockUnlock = (e, param) => {
    const label = param ? 'Lock' : 'Unlock'
    const id = ''
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin akan melakukan " + label + " data seluruh kelurahan?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=4' + '&idParent=' + this.state.rowKec.id_kecamatan + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
          .then(res => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Sukses',
                icon: 'success',
                text: 'Data berhasil di ' + label,
              })
              this.setState({ lockUnlock: param, dataKel: this.state.dataKel.map(el => Object.assign({}, el, { statusLock: param })) })
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal di ' + label,
            });
          });
      }
    })
  }

  handleLockUnlockById = (e, row) => {
    e.stopPropagation();
    const label = row.statusLock ? 'Unlock' : 'Lock'
    const param = !row.statusLock
    const id = row.id_kelurahan

    let role = JSON.parse(localStorage.getItem("authority"))[0].attributes.role

    if ((row.lastModifiedBy === 'superadmin' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true) ||
      (row.lastModifiedBy === 'admindev' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true)) {
      Swal.fire({
        title: 'Info!',
        icon: 'warning',
        text: 'Lock Hanya Bisa Di Unlock Superadmin',
      });
    } else {
      Swal.fire({
        title: 'Peringatan',
        text: "Apakah anda yakin akan melakukan " + label + " data " + row.nama_kelurahan + " ?",
        icon: 'warning',
        cancelButtonText: 'Batal!',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          console.log(row, "CEKKK")
          APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=4' + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
            .then(res => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil di ' + label,
                })
                this.setState({ dataKel: this.state.dataKel.map(el => el.id_kelurahan === id ? Object.assign({}, el, { statusLock: param }) : el) })
              } else {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal di ' + label,
                });
              }
            }).catch((error) => {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            });
        }
      })
    }
  }

  render() {
    var d = new Date().getDay();
    var hourWib = new Date().getUTCHours() + 7;
    const options = {
      onRowClick: this.onRowClick,
      expandRowBgColor: '#F5F5DC',
      expandBy: 'column',

      page: 1,
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: '<',
      nextPage: '>',
      firstPage: '<<',
      lastPage: '>>',
      paginationShowsTotal: false,
      paginationPosition: 'bottom',
      hideSizePerPage: true
    };
    return (
      <>
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
          <Accordion expanded={this.props.panelKel} className='accordionRoot panelExpand'>
            <AccordionSummary
              className='panelSummary'
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.props.handleChangePanelKel} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontSize: '12px' }}><b>Data Kelurahan</b> di Kecamatan {this.props.row.nama_kecamatan}</Typography>
              {(this.state.roleId === 1 || this.state.roleId === 99) ?
                <div className='divBtnPanel'>
                  {(!this.state.rowProv?.statusLock && !this.state.rowKab?.statusLock && !this.state.rowKec?.statusLock && !this.state.lockUnlock) &&
                    <>
                      <Button className='btnPanel' onClick={this.handleOpenCloseAddKel}><ImPlus style={{ marginRight: '3px' }} /> Tambah</Button>
                      {(this.state.roleId === 1 || this.state.roleId === 99) &&
                        <Button className='btnPanel' onClick={this.handleOpenClosePindahKel}><ImLoop2 style={{ marginRight: '3px' }} /> Pindah</Button>
                      }
                    </>
                  }
                  {/* <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, true)} ><AiFillLock style={{ marginRight: '3px' }} /> Lock</Button>
                  <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, false)} ><AiFillLock style={{ marginRight: '3px' }} /> Unlock</Button> */}
                </div>
                :
                <>
                  -
                </>
              }
            </AccordionSummary>
            <AccordionDetails className='panelDetail'>
              {(this.state.dataKel) ?
                <>
                  <BootstrapTable data={this.state.dataKel}
                    pagination={true}
                    search={true}
                    options={options}
                    expandableRow={this.isExpandableRow}
                    expandComponent={this.expandComponent}
                    expandColumnOptions={{
                      expandColumnVisible: true,
                      expandColumnComponent: this.expandColumnComponent,
                      columnWidth: 40
                    }}>
                    <TableHeaderColumn width='120px' dataField='kodeDepdagri' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode Kelurahan</TableHeaderColumn>
                    <TableHeaderColumn dataField='nama_kelurahan' headerAlign='center' expandable={false}>Nama Kelurahan</TableHeaderColumn>
                    <TableHeaderColumn width='120px' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Aksi</TableHeaderColumn>
                  </BootstrapTable>
                  <div className='totalDataExpand'>Total Data: {this.state.dataKel?.length}</div>
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Tidak ada data</div>
              }
            </AccordionDetails>
          </Accordion>
          <Dialog open={this.state.openAddKel} onClose={this.handleOpenCloseAddKel}>
            <DialogTitle>{this.state.id ? 'Ubah' : 'Tambah'}  Kelurahan</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Kecamatan: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_kecamatan}<br />
              </DialogContentText>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    inputProps={{ maxLength: 4 }}
                    id="filled-required"
                    label="Kode Kelurahan"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.kodeDepdagri}
                    name="kodeDepdagri"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="filled-required"
                    label="Nama Kelurahan"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.nama}
                    name="nama"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={this.state.disabledSimpanKel} onClick={this.handleAddKel} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
              <Button disabled={this.state.disabledSimpanKel} onClick={this.handleOpenCloseAddKel} variant="outlined" color="error">Batal</Button>
            </DialogActions>
          </Dialog>
          <TransferDialog
            tkWilayah='Kelurahan'
            wilayah='Kelurahan'
            wilayahId='4'
            dataWilayah={this.state.dataKel}
            open={this.state.openPindahKel}
            onClose={this.handleOpenClosePindahKel}
            kodeDepdagri={this.props.row.kodeDepdagri}
            nama={this.props.row.nama_kecamatan}
            id={this.props.row.id_kecamatan}
            value_priode={this.state.value_priode}
          >
          </TransferDialog>
        </BlockUi>
      </>
    )
  }
}

//---table kec---
class BSTableKec extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelKel: true,
      openAddKec: false,
      openPindahKec: false,
      id: null,
      kodeDepdagri: '',
      nama: '',
      dataKec: this.props.data,
      tingkatWilayahId: this.props.tingkatWilayahId,
      roleId: this.props.roleId,
      wilKelurahan: {},
      blocking: false,
      statusDuplikat: false,
      sdTampil: false,
      kodeDepdagriLama: '',
      disabledSimpanKec: false,
      provinsiId: this.props.provinsiId,
      disEditKec: false,
      dishapusKec: false,
      rowKab: this.props.row,
      value_priode: this.props.value_priode,
      rowProv: this.props.rowProv,
      lockUnlock: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.data?.length !== prevProps.data?.length) {
      this.setState({ dataKec: this.props.data })
    }
    if (Object.keys(this.props.wilKelurahan).length !== Object.keys(prevProps.wilKelurahan).length) {
      this.setState({ wilKelurahan: this.props.wilKelurahan })
    }
    if (this.props.tingkatWilayahId !== prevProps.tingkatWilayahId) {
      this.setState({ tingkatWilayahId: this.props.tingkatWilayahId })
    }
    if (this.props.roleId !== prevProps.roleId) {
      this.setState({ roleId: this.props.roleId })
    }
    if (this.props.provinsiId !== prevProps.provinsiId) {
      this.setState({ provinsiId: this.props.provinsiId })
    }
    if (this.props.row !== prevProps.row) {
      this.setState({ rowKab: this.props.row })
    }
    if (this.props.rowProv !== prevProps.rowProv) {
      this.setState({ rowProv: this.props.rowProv })
    }
    if (this.props.value_priode !== prevProps.value_priode) {
      this.setState({ value_priode: this.props.value_priode })
    }
  }

  isExpandableRow(row) {
    return true
  }

  handleChangePanelKel = (e) => {
    e.preventDefault();
    this.setState({ panelKel: !this.state.panelKel })
  }

  expandComponent = (row) => {
    return (
      <BSTableKel row={row} rowKab={this.state.rowKab} rowProv={this.state.rowProv}
        data={row.children} handleChangePanelKel={this.handleChangePanelKel} panelKel={this.state.panelKel} value_priode={this.state.value_priode}
        tingkatWilayahId={this.state.tingkatWilayahId} roleId={this.state.roleId} provinsiId={this.state.provinsiId} />
    );
  }

  expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';

    if (isExpandableRow) {
      content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
    } else {
      content = ' ';
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
    );
  }

  buttonFormatter = (cell, row) => {
    // var d = new Date().getDay(); 
    // var hourWib = new Date().getUTCHours() + 7;
    if (this.state.roleId === 1 || this.state.roleId === 99) {
      return <div className='divBtnTable'>
        {(!this.props.rowProv.statusLock && !this.state.rowKab.statusLock && !row.statusLock) ?
          <>
            <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
            {this.state.roleId === 1 || this.state.roleId === 99 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
          </>
          :
          <>
            {(row.statusLock === false) ?
              <>
                <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
                {this.state.roleId === 1 || this.state.roleId === 99 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
              </>
              :
              <>
                <div style={{ marginRight: '5px', marginTop: '2px', fontWeight: '600' }}>Locked</div>
              </>
            }

          </>
        }
        <Button className='btnTable' variant='contained' color='success' onClick={(e) => this.handleLockUnlockById(e, row)}><AiFillLock /></Button>
      </div>
    } else {
      return <>-</>
    }
  }

  handleOpenCloseAddKec = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openAddKec: !this.state.openAddKec },
      () => { if (!this.state.openAddKec) { this.clearForm() } }
    )
  }

  handleOpenClosePindahKec = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openPindahKec: !this.state.openPindahKec })
  }

  onRowClick = (row) => {
    // 
    // 
    if (!row.hasOwnProperty('children')) {
      this.setState({ blocking: true })
      if (Object.keys(this.state.wilKelurahan).length > 0) {
        let arr = [{
          id_kelurahan: this.state.wilKelurahan.id_kelurahan,
          kodeDepdagri: this.state.wilKelurahan.id_kelurahan_depdagri,
          nama_kelurahan: this.state.wilKelurahan.nama_kelurahan,
          statusLock: this.state.wilKelurahan.status_lock
        }]
        this.setState({ blocking: false, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, rowProv: this.props.rowProv, rowKab: this.props.row, provinsiId: this.props.provinsiId, dataKec: this.state.dataKec.map(el => (el.id_kecamatan === row.id_kecamatan ? { ...el, children: arr } : el)) });
      } else {
        console.log(this.state.value_priode)
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + row.id_kecamatan + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
          .then(res => {
            let arr = []
            if (Object.keys(this.state.wilKelurahan).length > 0) {
              arr = res.data.filter(el => el.id_kelurahan === this.state.wilKelurahan.id_kelurahan)
            } else {
              arr = res.data
            }
            this.setState({ blocking: false, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, rowProv: this.props.rowProv, rowKab: this.props.row, provinsiId: this.props.provinsiId, dataKec: this.state.dataKec.map(el => (el.id_kecamatan === row.id_kecamatan ? { ...el, children: arr } : el)) });
          }).catch((error) => {
            this.setState({ blocking: false })

          });
      }
    }
  }

  clearForm = () => {
    this.setState({ id: null, kodeDepdagri: '', nama: '' })
  }

  handleDelete = (e, row) => {
    e.stopPropagation();
    Swal.fire({
      title: 'Peringatan Hapus Kecamatan',
      text: "Apakah anda yakin menghapus [" + row.kodeDepdagri + " - " + row.nama_kecamatan + "] ?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        const payload = {
          "idKec": row.id_kecamatan,
          "jenisAktivitas": 3,
          "jlhKec": 1,
          "jlhUser": 2,
          "userLogin": localStorage.getItem('username')
        }
        APIWilayah.post('/userwilayah/DeleteKecamatan?periodeSensus=' + this.state.value_priode, payload)
          .then(res => {

            if (res.status === 200) {
              if (res.data.deleteKecamatan === 'failed (error: 500->)Gagal hapus karena ada data wilayah dibawah kecamatan ') {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal dihapus karena ada data wilayah di bawah kecamatan',
                });
              } else {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil dihapus',
                })
                this.setState({ dataKec: this.state.dataKec.filter(el => el.id_kecamatan !== row.id_kecamatan) })
              }
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal dihapus',
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal dihapus',
            });
          });
      }
    })
  }

  handleEdit = (e, row) => {
    e.stopPropagation();
    this.setState({ id: row.id_kecamatan, kodeDepdagri: row.kodeDepdagri, nama: row.nama_kecamatan, kodeDepdagriLama: row.kodeDepdagri, statusDuplikat: row.statusDuplikat }, () => { this.handleOpenCloseAddKec() })
  }

  handleChangeInput = (e) => {
    if (e.target.name === 'kodeDepdagri') {
      if (isNumber(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value })
      }
    } else {
      const pointer = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
      this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    }
  }

  handleAddKec = () => {
    if (this.state.kodeDepdagri && this.state.kodeDepdagri.length > 0 && this.state.nama && this.state.nama.length > 0) {
      if (this.state.kodeDepdagri.length === 2) {
        const cek = this.state.dataKec.find(el => el.kodeDepdagri.replace(/ /g, "") === this.state.kodeDepdagri)
        if (cek === undefined || (cek !== undefined && cek.id_kecamatan === this.state.id)) {
          Swal.fire({
            title: 'Peringatan',
            text: "[" + this.state.kodeDepdagri + " - " + this.state.nama + "] Apakah anda yakin data sudah benar? ",
            icon: 'warning',
            cancelButtonText: 'Batal!',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              this.setState({ disabledSimpanKec: true })
              if (this.state.id) {
                let sd = this.state.statusDuplikat
                if (sd === true) {
                  if (this.state.kodeDepdagri !== this.state.kodeDepdagriLama) {
                    sd = true
                    this.setState({ sdTampil: false })
                  }
                }
                APIWilayah.post('/userwilayah/EditKecamatan?idKecamatan=' + this.state.id + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaKecamatan=' + this.state.nama + '&statusduplikat=' + sd + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.data.ID === 0) {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      })
                    } else {
                      if (res.status === 200) {
                        Swal.fire({
                          title: 'Sukses',
                          icon: 'success',
                          text: 'Data berhasil disimpan',
                        })

                        const arr = { id_kecamatan: this.state.id, nama_kecamatan: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: this.state.sdTampil, periodeSensus: this.state.value_priode }
                        this.setState({ dataKec: this.state.dataKec.map(el => el.id_kecamatan === this.state.id ? Object.assign({}, el, arr) : el), openAddKec: false, disabledSimpanKec: false }, () => { this.clearForm() })
                      } else {
                        Swal.fire({
                          title: 'Error',
                          icon: 'warning',
                          text: 'Data gagal disimpan',
                        });
                        this.setState({ disabledSimpanKec: false })
                      }
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanKec: false })
                  });
              } else { //tambah
                APIWilayah.post('/userwilayah/AddKecamatan?idKabupaten=' + this.props.row.id_kabupaten + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaKecamatan=' + this.state.nama + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      if (res.data.ID === 0) {
                        Swal.fire({
                          title: 'Error',
                          icon: 'warning',
                          text: 'Data gagal disimpan',
                        });
                        this.setState({ disabledSimpanKec: false })
                      }
                      else {
                        Swal.fire({
                          title: 'Sukses',
                          icon: 'success',
                          text: 'Data berhasil disimpan',
                        })
                        const arr = { id_kecamatan: res.data.ID, nama_kecamatan: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: false, statusLock: false, periodeSensus: this.state.value_priode }
                        this.setState({ dataKec: [...this.state.dataKec, arr], openAddKec: false, disabledSimpanKec: false }, () => { this.clearForm() })

                      }

                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanKec: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanKec: false })
                  });
              }
            } else {
              this.setState({ disabledSimpanKec: false })
            }
          });
        } else {
          Swal.fire({
            title: 'Peringatan',
            icon: 'warning',
            text: 'Kode Depdagri telah digunakan.',
          });
        }
      } else {
        Swal.fire({
          title: 'Peringatan',
          icon: 'warning',
          text: 'Kode Depdagri Kecamatan harus 2 digit.',
        });
      }
    } else {
      Swal.fire({
        title: 'Peringatan',
        icon: 'warning',
        text: 'Kode Depdagri dan Nama Kecamatan tidak boleh kosong.',
      });
    }
  }

  columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
    if (row.statusDuplikat === true) {
      return 'edafaf'
    } else {
      return ''
    }
  }

  handleLockUnlock = (e, param) => {
    const label = param ? 'Lock' : 'Unlock'
    const id = ''
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin akan melakukan " + label + " data seluruh kecamatan?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=3' + '&idParent=' + this.state.rowKab.id_kabupaten + '&username=' + localStorage.getItem('username'))
          .then(res => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Sukses',
                icon: 'success',
                text: 'Data berhasil di ' + label,
              })
              this.setState({ lockUnlock: param, dataKec: this.state.dataKec.map(el => Object.assign({}, el, { statusLock: param })) })
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal di ' + label,
            });
          });
      }
    })
  }

  handleLockUnlockById = (e, row) => {
    e.stopPropagation();
    const label = row.statusLock ? 'Unlock' : 'Lock'
    const param = !row.statusLock
    const id = row.id_kecamatan
    let role = JSON.parse(localStorage.getItem("authority"))[0].attributes.role

    if ((row.lastModifiedBy === 'superadmin' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true) ||
      (row.lastModifiedBy === 'admindev' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true)) {
      Swal.fire({
        title: 'Info!',
        icon: 'warning',
        text: 'Lock Hanya Bisa Di Unlock Superadmin',
      });
    } else {
      Swal.fire({
        title: 'Peringatan',
        text: "Apakah anda yakin akan melakukan " + label + " data " + row.nama_kecamatan + " ?",
        icon: 'warning',
        cancelButtonText: 'Batal!',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=3' + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
            .then(res => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil di ' + label,
                })
                this.setState({ dataKec: this.state.dataKec.map(el => el.id_kecamatan === id ? Object.assign({}, el, { statusLock: param }) : el) })
              } else {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal di ' + label,
                });
              }
            }).catch((error) => {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            });
        }
      })
    }
  }

  render() {
    var d = new Date().getDay();
    var hourWib = new Date().getUTCHours() + 7;
    const options = {
      onRowClick: this.onRowClick,
      expandRowBgColor: '#F5F5DC',
      expandBy: 'column',

      page: 1,
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: '<',
      nextPage: '>',
      firstPage: '<<',
      lastPage: '>>',
      paginationShowsTotal: false,
      paginationPosition: 'bottom',
      hideSizePerPage: true
    };
    return (
      <>
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
          <Accordion expanded={this.props.panelKec} className='accordionRoot panelExpand'>
            <AccordionSummary
              className='panelSummary'
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.props.handleChangePanelKec} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontSize: '12px' }}><b>Data Kecamatan</b> di Kabupaten {this.props.row.nama_kabupaten}</Typography>
              {(this.state.roleId === 1 || this.state.roleId === 99) ?
                <div className='divBtnPanel'>
                  {(!this.props.rowProv.statusLock && !this.state.rowKab.statusLock && !this.state.lockUnlock) &&
                    <>
                      <Button className='btnPanel' onClick={this.handleOpenCloseAddKec}><ImPlus style={{ marginRight: '3px' }} /> Tambah</Button>
                      {(this.state.roleId === 1 || this.state.roleId === 99) &&
                        <Button className='btnPanel' onClick={this.handleOpenClosePindahKec}><ImLoop2 style={{ marginRight: '3px' }} /> Pindah</Button>
                      }
                    </>
                  }
                  {/* <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, true)} ><AiFillLock style={{ marginRight: '3px' }} /> Lock</Button>
                  <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, false)} ><AiFillLock style={{ marginRight: '3px' }} /> Unlock</Button> */}
                </div>
                :
                <>
                  -
                </>
              }
            </AccordionSummary>
            <AccordionDetails className='panelDetail'>
              {(this.state.dataKec) ?
                <>
                  <BootstrapTable data={this.state.dataKec}
                    pagination={true}
                    search={true}
                    options={options}
                    expandableRow={this.isExpandableRow}
                    expandComponent={this.expandComponent}
                    expandColumnOptions={{
                      expandColumnVisible: true,
                      expandColumnComponent: this.expandColumnComponent,
                      columnWidth: 40
                    }}>
                    <TableHeaderColumn width='120px' dataField='kodeDepdagri' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode Kecamatan</TableHeaderColumn>
                    <TableHeaderColumn dataField='nama_kecamatan' headerAlign='center' expandable={false}>Nama Kecamatan</TableHeaderColumn>
                    <TableHeaderColumn width='120px' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Aksi</TableHeaderColumn>
                  </BootstrapTable>
                  <div className='totalDataExpand'>Total Data: {this.state.dataKec?.length}</div>
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Tidak ada data</div>
              }
            </AccordionDetails>
          </Accordion>
          <Dialog open={this.state.openAddKec} onClose={this.handleOpenCloseAddKec}>
            <DialogTitle>{this.state.id ? 'Ubah' : 'Tambah'}  Kecamatan</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Kabupaten: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_kabupaten}<br />
              </DialogContentText>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    inputProps={{ maxLength: 2 }}
                    id="filled-required"
                    label="Kode Kecamatan"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.kodeDepdagri}
                    name="kodeDepdagri"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="filled-required"
                    label="Nama Kecamatan"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.nama}
                    name="nama"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={this.state.disabledSimpanKec} onClick={this.handleAddKec} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
              <Button disabled={this.state.disabledSimpanKec} onClick={this.handleOpenCloseAddKec} variant="outlined" color="error">Batal</Button>
            </DialogActions>
          </Dialog>
          <TransferDialog
            tkWilayah='Kecamatan'
            wilayah='Kecamatan'
            wilayahId='3'
            dataWilayah={this.state.dataKec}
            open={this.state.openPindahKec}
            onClose={this.handleOpenClosePindahKec}
            kodeDepdagri={this.props.row.kodeDepdagri}
            nama={this.props.row.nama_kabupaten}
            id={this.props.row.id_kabupaten}
            value_priode={this.state.value_priode}

          >
          </TransferDialog>
        </BlockUi>
      </>
    )
  }
}

//---table kab---
class BSTableKab extends Component {
  constructor(props) {
    super(props);


    this.state = {
      panelKec: true,
      openAddKab: false,
      openPindahKab: false,
      dataKab: this.props.data,
      id: null,
      kodeDepdagri: '',
      nama: '',
      tingkatWilayahId: this.props.tingkatWilayahId,
      roleId: this.props.roleId,
      wilKecamatan: {},
      wilKelurahan: {},
      blocking: false,
      statusDuplikat: false,
      sdTampil: false,
      kodeDepdagriLama: '',
      disabledSimpanKab: false,
      provinsiId: this.props.provinsiId,
      rowProv: this.props.row,
      value_priode: this.props.value_priode,

      lockUnlock: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.data?.length !== prevProps.data?.length) {
      this.setState({ dataKab: this.props.data })
    }
    if (Object.keys(this.props.wilKecamatan).length !== Object.keys(prevProps.wilKecamatan).length) {
      this.setState({ wilKecamatan: this.props.wilKecamatan })
    }
    if (this.props.tingkatWilayahId !== prevProps.tingkatWilayahId) {
      this.setState({ tingkatWilayahId: this.props.tingkatWilayahId })
    }
    if (this.props.roleId !== prevProps.roleId) {
      this.setState({ roleId: this.props.roleId })
    }
    if (this.props.provinsiId !== prevProps.provinsiId) {
      this.setState({ provinsiId: this.props.provinsiId })
    }
    if (this.props.row !== prevProps.row) {
      this.setState({ rowProv: this.props.row })
    }
    if (this.props.value_priode !== prevProps.value_priode) {
      this.setState({ rowProv: this.props.value_priode })
    }



  }

  isExpandableRow(row) {
    return true
  }

  handleChangePanelKec = (e) => {
    e.preventDefault();
    this.setState({ panelKec: !this.state.panelKec })
  }

  expandComponent = (row) => {
    return (
      <BSTableKec row={row} rowProv={this.state.rowProv} data={row.children} handleChangePanelKec={this.handleChangePanelKec} panelKec={this.state.panelKec}
        wilKelurahan={this.state.wilKelurahan} tingkatWilayahId={this.state.tingkatWilayahId} value_priode={this.state.value_priode}
        roleId={this.state.roleId} provinsiId={this.state.provinsiId} />
    );
  }

  expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';

    if (isExpandableRow) {
      content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
    } else {
      content = ' ';
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
    );
  }

  buttonFormatter = (cell, row) => {
    // var d = new Date().getDay(); 
    // var hourWib = new Date().getUTCHours() + 7;
    if (this.state.roleId === 1 || this.state.roleId === 99) {
      return <div className='divBtnTable'>

        {(!this.state.rowProv.statusLock && !row.statusLock) ?
          <>
            <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
            {this.state.roleId === 1 || this.state.roleId === 99 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
            {/* {this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>} */}
          </>
          :
          <>
            {(row.statusLock === false) ?
              <>
                <Button className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button>
                {this.state.roleId === 1 || this.state.roleId === 99 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>}
                {/* {this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2 ? <Button className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> : <></>} */}
              </>
              :
              <>
                <div style={{ marginRight: '5px', marginTop: '2px', fontWeight: '600' }}>Locked</div>
              </>
            }

          </>
        }
        <Button className='btnTable' variant='contained' color='success' onClick={(e) => this.handleLockUnlockById(e, row)}><AiFillLock /></Button>
      </div>
    } else {
      return <>-</>
    }
  }

  handleOpenCloseAddKab = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openAddKab: !this.state.openAddKab },
      () => { if (!this.state.openAddKab) { this.clearForm() } }
    )
  }

  handleOpenClosePindahKab = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openPindahKab: !this.state.openPindahKab })
  }

  onRowClick = (row) => {
    // 

    if (!row.hasOwnProperty('children')) {
      this.setState({ blocking: true })
      if (Object.keys(this.state.wilKecamatan).length > 0) {
        let arr = [{
          id_kecamatan: this.state.wilKecamatan.id_kecamatan,
          kodeDepdagri: this.state.wilKecamatan.id_kecamatan_depdagri,
          nama_kecamatan: this.state.wilKecamatan.nama_kecamatan,
          statusLock: this.state.wilKecamatan.status_lock
        }]
        this.setState({ blocking: false, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, rowProv: this.props.row, provinsiId: this.props.provinsiId, wilKelurahan: this.props.wilKelurahan, dataKab: this.state.dataKab.map(el => (el.id_kabupaten === row.id_kabupaten ? { ...el, children: arr } : el)) });
      } else {
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + row.id_kabupaten + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
          .then(res => {
            let arr = []
            if (Object.keys(this.state.wilKecamatan).length > 0) {
              arr = res.data.filter(el => el.id_kecamatan === this.state.wilKecamatan.id_kecamatan)
            } else {
              arr = res.data
            }
            this.setState({ blocking: false, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, rowProv: this.props.row, provinsiId: this.props.provinsiId, wilKelurahan: this.props.wilKelurahan, dataKab: this.state.dataKab.map(el => (el.id_kabupaten === row.id_kabupaten ? { ...el, children: arr } : el)) });
          }).catch((error) => {
            this.setState({ blocking: false })

          });
      }
    }
  }

  clearForm = () => {
    this.setState({ id: null, kodeDepdagri: '', nama: '' })
  }

  handleDelete = (e, row) => {
    e.stopPropagation();
    Swal.fire({
      title: 'Peringatan Hapus Kabupaten',
      text: "Apakah anda yakin menghapus [" + row.kodeDepdagri + " - " + row.nama_kabupaten + "] ?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        const payload = {
          "idKab": row.id_kabupaten,
          "jenisAktivitas": 3,
          "jlhKab": 1,
          "jlhUser": 1,
          "userLogin": localStorage.getItem('username')
        }
        APIWilayah.post('/userwilayah/DeleteKabupaten?periodeSensus=' + this.state.value_priode, payload)
          .then(res => {

            if (res.status === 200) {
              if (res.data.deleteKabupaten === 'failed (error: 500->)Gagal hapus karena ada data wilayah dibawah kabupaten ') {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal dihapus karena ada data wilayah di bawah kabupaten',
                });
              } else {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil dihapus',
                })
                this.setState({ dataKab: this.state.dataKab.filter(el => el.id_kabupaten !== row.id_kabupaten) })
              }
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal dihapus',
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal dihapus',
            });
          });
      }
    })
  }

  handleEdit = (e, row) => {
    e.stopPropagation();
    this.setState({ id: row.id_kabupaten, kodeDepdagri: row.kodeDepdagri, nama: row.nama_kabupaten, kodeDepdagriLama: row.kodeDepdagri, statusDuplikat: row.statusDuplikat }, () => { this.handleOpenCloseAddKab() })
  }

  handleChangeInput = (e) => {
    if (e.target.name === 'kodeDepdagri') {
      if (isNumber(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value })
      }
    } else {
      const pointer = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
      this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    }
  }

  handleAddKab = () => {
    if (this.state.kodeDepdagri && this.state.kodeDepdagri.length > 0 && this.state.nama && this.state.nama.length > 0) {
      if (this.state.kodeDepdagri.length === 2) {
        const cek = this.state.dataKab.find(el => el.kodeDepdagri.replace(/ /g, "") === this.state.kodeDepdagri)
        if (cek === undefined || (cek !== undefined && cek.id_kabupaten === this.state.id)) {
          Swal.fire({
            title: 'Peringatan',
            text: "[" + this.state.kodeDepdagri + " - " + this.state.nama + "] Apakah anda yakin data sudah benar? ",
            icon: 'warning',
            cancelButtonText: 'Batal!',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              this.setState({ disabledSimpanKab: true })
              if (this.state.id) { //edit
                let sd = this.state.statusDuplikat
                if (sd === true) {
                  if (this.state.kodeDepdagri !== this.state.kodeDepdagriLama) {
                    sd = true
                    this.setState({ sdTampil: false })
                  }
                }
                APIWilayah.post('/userwilayah/EditKabupaten?idKabupaten=' + this.state.id + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaKabupaten=' + this.state.nama + '&statusduplikat=' + sd + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      Swal.fire({
                        title: 'Sukses',
                        icon: 'success',
                        text: 'Data berhasil disimpan',
                      })

                      const arr = { id_kabupaten: this.state.id, nama_kabupaten: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: this.state.sdTampil, periodeSensus: this.state.value_priode }
                      this.setState({ dataKab: this.state.dataKab.map(el => el.id_kabupaten === this.state.id ? Object.assign({}, el, arr) : el), openAddKab: false, disabledSimpanKab: false }, () => { this.clearForm() })
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanKab: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanKab: false })
                  });
              } else { //tambah
                APIWilayah.post('/userwilayah/AddKabupaten?idProvinsi=' + this.props.row.id_provinsi + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaKabupaten=' + this.state.nama + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      if (res.data.ID === 0) {
                        Swal.fire({
                          title: 'Error',
                          icon: 'warning',
                          text: 'Data gagal disimpan',
                        })
                        this.setState({ disabledSimpanKab: false })
                      } else {
                        Swal.fire({
                          title: 'Sukses',
                          icon: 'success',
                          text: 'Data berhasil disimpan',
                        })
                        const arr = { id_kabupaten: res.data.ID, nama_kabupaten: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: false, statusLock: false, periodeSensus: this.state.value_priode }
                        this.setState({ dataKab: [...this.state.dataKab, arr], openAddKab: false, disabledSimpanKab: false }, () => {
                          this.clearForm()
                        })
                      }
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanKab: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanKab: false })
                  });
              }
            } else {
              this.setState({ disabledSimpanKab: false })
            }
          });
        } else {
          Swal.fire({
            title: 'Peringatan',
            icon: 'warning',
            text: 'Kode Depdagri telah digunakan.',
          });
        }
      } else {
        Swal.fire({
          title: 'Peringatan',
          icon: 'warning',
          text: 'Kode Depdagri Kabupaten harus 2 digit.',
        });
      }
    } else {
      Swal.fire({
        title: 'Peringatan',
        icon: 'warning',
        text: 'Kode Depdagri dan Nama Kabupaten tidak boleh kosong.',
      });
    }
  }

  columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
    if (row.statusDuplikat === true) {
      return 'edafaf'
    } else {
      return ''
    }
  }

  handleLockUnlock = (e, param) => {
    const label = param ? 'Lock' : 'Unlock'
    const id = ''
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin akan melakukan " + label + " data seluruh kabupaten?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=2' + '&idParent=' + this.state.rowProv.id_provinsi + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
          .then(res => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Sukses',
                icon: 'success',
                text: 'Data berhasil di ' + label,
              })
              this.setState({ lockUnlock: param, dataKab: this.state.dataKab.map(el => Object.assign({}, el, { statusLock: param })) })
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal di ' + label,
            });
          });
      }
    })
  }

  handleLockUnlockById = (e, row) => {
    e.stopPropagation();
    const label = row.statusLock ? 'Unlock' : 'Lock'
    const param = !row.statusLock
    const id = row.id_kabupaten
    let role = JSON.parse(localStorage.getItem("authority"))[0].attributes.role

    if ((row.lastModifiedBy === 'superadmin' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true) ||
      (row.lastModifiedBy === 'admindev' && (role !== 'Administrator' && role !== 'Admin Developer') && row.statusLock === true)) {
      Swal.fire({
        title: 'Info!',
        icon: 'warning',
        text: 'Lock Hanya Bisa Di Unlock Superadmin',
      });
    } else {
      Swal.fire({
        title: 'Peringatan',
        text: "Apakah anda yakin akan melakukan " + label + " data " + row.nama_kabupaten + " ?",
        icon: 'warning',
        cancelButtonText: 'Batal!',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=2' + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + (row.periodeSensus ? row.periodeSensus : this.state.value_priode))
            .then(res => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil di ' + label,
                })
                this.setState({ dataKab: this.state.dataKab.map(el => el.id_kabupaten === id ? Object.assign({}, el, { statusLock: param }) : el) })
              } else {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal di ' + label,
                });
              }
            }).catch((error) => {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            });
        }
      })
    }

  }

  render() {
    var d = new Date().getDay();
    var hourWib = new Date().getUTCHours() + 7;
    const options = {
      onRowClick: this.onRowClick,
      expandRowBgColor: '#F5F5DC',
      expandBy: 'column',

      page: 1,
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: '<',
      nextPage: '>',
      firstPage: '<<',
      lastPage: '>>',
      paginationShowsTotal: false,
      paginationPosition: 'bottom',
      hideSizePerPage: true
    };
    return (
      <>
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
          <Accordion expanded={this.props.panelKab} className='accordionRoot panelExpand'>
            <AccordionSummary
              className='panelSummary'
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.props.handleChangePanelKab} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontSize: '12px' }}><b>Data Kabupaten</b> di Provinsi {this.props.row.nama}</Typography>

              {(this.state.roleId === 1 || this.state.roleId === 99) ?
                <div className='divBtnPanel'>
                  {console.log(this.state.rowProv?.statusLock, this.state.lockUnlock)}
                  {(this.state.rowProv?.statusLock && this.state.lockUnlock) &&
                    <>
                      <Button className='btnPanel' onClick={this.handleOpenCloseAddKab}><ImPlus style={{ marginRight: '3px' }} /> Tambah</Button>
                      {(this.state.roleId === 1 || this.state.roleId === 99) &&
                        <Button className='btnPanel' onClick={this.handleOpenClosePindahKab}><ImLoop2 style={{ marginRight: '3px' }} /> Pindah</Button>
                      }
                    </>
                  }
                  {/* <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, true)} ><AiFillLock style={{ marginRight: '3px' }} /> Lock</Button>
                  <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, false)} ><AiFillLock style={{ marginRight: '3px' }} /> Unlock</Button> */}
                </div>
                :
                <>
                  -
                </>
              }
            </AccordionSummary>
            <AccordionDetails className='panelDetail'>
              {this.state.dataKab ?
                <>
                  <BootstrapTable data={this.state.dataKab}
                    pagination={true}
                    search={true}
                    options={options}
                    expandableRow={this.isExpandableRow}
                    expandComponent={this.expandComponent}
                    expandColumnOptions={{
                      expandColumnVisible: true,
                      expandColumnComponent: this.expandColumnComponent,
                      columnWidth: 40
                    }}>
                    <TableHeaderColumn width='120px' dataField='kodeDepdagri' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode Kabupaten</TableHeaderColumn>
                    <TableHeaderColumn dataField='nama_kabupaten' headerAlign='center' expandable={false}>Nama Kabupaten</TableHeaderColumn>
                    <TableHeaderColumn width='120px' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Aksi</TableHeaderColumn>
                  </BootstrapTable>
                  <div className='totalDataExpand'>Total Data: {this.state.dataKab?.length}</div>
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Tidak ada data</div>
              }
            </AccordionDetails>
          </Accordion>
          <Dialog open={this.state.openAddKab} onClose={this.handleOpenCloseAddKab}>
            <DialogTitle>{this.state.id ? 'Ubah' : 'Tambah'} Kabupaten</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Provinsi: {this.props.row.kodeDepdagri + '-' + this.props.row.nama}
              </DialogContentText>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    inputProps={{ maxLength: 2 }}
                    id="filled-required"
                    label="Kode Kabupaten"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.kodeDepdagri}
                    name="kodeDepdagri"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="filled-required"
                    label="Nama Kabupaten"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.nama}
                    name="nama"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={this.state.disabledSimpanKab} onClick={this.handleAddKab} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
              <Button disabled={this.state.disabledSimpanKab} onClick={this.handleOpenCloseAddKab} variant="outlined" color="error">Batal</Button>
            </DialogActions>
          </Dialog>
          <TransferDialog
            tkWilayah='Kabupaten'
            wilayah='Kabupaten'
            wilayahId='2'
            dataWilayah={this.state.dataKab}
            open={this.state.openPindahKab}
            onClose={this.handleOpenClosePindahKab}
            kodeDepdagri={this.props.row.kodeDepdagri}
            nama={this.props.row.nama}
            id={this.props.row.id_provinsi}
            value_priode={this.state.value_priode}

          >
          </TransferDialog>
        </BlockUi>
      </>
    )
  }
}

//---table prov---
export default class TableExpand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panel: true,
      openAddProv: false,
      panelKab: true,
      id: null,
      kodeDepdagri: '',
      nama: '',
      defaultData: this.props.defaultData,
      tingkatWilayahId: this.props.tingkatWilayahId,
      roleId: this.props.roleId,
      wilKabupaten: this.props.wilKabupaten,
      wilKecamatan: this.props.wilKecamatan,
      wilKelurahan: this.props.wilKelurahan,
      value_priode: this.props.value_priode,
      blocking: false,
      statusDuplikat: false,
      disabledSimpanProv: false,
      provinsiId: 0
    }
  }

  componentDidUpdate = (prevProps, prevState) => {

    if (this.props.defaultData.length !== prevProps.defaultData.length) {
      this.setState({ defaultData: this.props.defaultData })
    }
    if (Object.keys(this.props.wilKabupaten).length !== Object.keys(prevProps.wilKabupaten).length) {
      this.setState({ wilKabupaten: this.props.wilKabupaten })
    }
    if (this.props.tingkatWilayahId !== prevProps.tingkatWilayahId) {
      this.setState({ tingkatWilayahId: this.props.tingkatWilayahId })
    }
    if (this.props.roleId !== prevProps.roleId) {
      this.setState({ roleId: this.props.roleId })
    }
    if (this.props.value_priode !== prevProps.value_priode) {
      this.setState({ value_priode: this.props.value_priode })
    }
  }

  handleChangePanel = (e) => {
    e.preventDefault();
    this.setState({ panel: !this.state.panel, kode_depdagri_provinsi: '', nama_provinsi: '' })
  }

  isExpandableRow(row) {
    return true
  }

  handleChangePanelKab = (e) => {
    e.preventDefault();
    this.setState({ panelKab: !this.state.panelKab })
  }

  expandComponent = (row) => {

    return (
      <BSTableKab row={row} data={row.children} handleChangePanelKab={this.handleChangePanelKab} panelKab={this.state.panelKab}
        wilKecamatan={this.state.wilKecamatan} wilKelurahan={this.state.wilKelurahan} value_priode={this.state.value_priode}
        tingkatWilayahId={this.state.tingkatWilayahId} roleId={this.state.roleId} provinsiId={this.state.provinsiId}
      />
    );
  }

  expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';

    if (isExpandableRow) {
      content = (isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />);
    } else {
      content = ' ';
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}> {content} </div>
    );
  }

  buttonFormatter = (cell, row) => {
    // var d = new Date().getDay(); 
    // var hourWib = new Date().getUTCHours() + 7;
    if (this.state.roleId === 1 || this.state.roleId === 99 || this.state.roleId === 2) {
      return <div className='divBtnTable'>
        {(row.statusLock) ?
          <>
            {/* <Button  className='btnTable' variant='contained' color='info' onClick={(e) => this.handleEdit(e, row)}><ImPencil /></Button> */}
            {/* <Button  className='btnTable' variant='contained' color='warning' onClick={(e) => this.handleDelete(e, row)}><ImBin /></Button> */}
          </>
          : <div style={{ marginRight: '5px', marginTop: '2px', fontWeight: '600' }}>Locked</div>
        }
        {(this.state.roleId === 1 || this.state.roleId === 99) &&
          <Button className='btnTable' variant='contained' color='success' onClick={(e) => this.handleLockUnlockById(e, row)}><AiFillLock /></Button>
        }
      </div>
    } else {
      return <>-</>
    }

  }

  onRowClick = (row) => {
    const rest = JSON.parse(localStorage.getItem("authority"));

    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const wilKabupaten = rest[0].attributes.user.wilKabupaten;
      const wilKecamatan = rest[0].attributes.user.wilKecamatan;
      const wilKelurahan = rest[0].attributes.user.wilKelurahan;
      const tw = +rest[0].attributes.user.TingkatWilayahID;
      const ri = +rest[0].attributes.user.roleID;
      this.setState({ wilKabupaten, wilKecamatan, wilKelurahan, tingkatWilayahId: tw, roleId: ri, })

      if (!row.hasOwnProperty('children')) {
        this.setState({ blocking: true })

        if (Object.keys(this.state.wilKabupaten).length > 0) {
          // 
          let arr = [{
            id_kabupaten: this.state.wilKabupaten.id_kabupaten,
            kodeDepdagri: this.state.wilKabupaten.id_kabupaten_depdagri,
            nama_kabupaten: this.state.wilKabupaten.nama_kabupaten,
            statusLock: this.state.wilKabupaten.status_lock,
            value_priode: this.state.value_priode
          }]
          this.setState({ blocking: false, provinsiId: row.id_provinsi, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, wilKecamatan: this.props.wilKecamatan, wilKelurahan: this.props.wilKelurahan, defaultData: this.state.defaultData.map(el => (el.id_provinsi === row.id_provinsi ? { ...el, children: arr } : el)) });
        } else {

          APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + row.id_provinsi + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
              // 
              // if(row.nama === "PAPUA BARAT DAYA"){
              //   this.setState({ blocking: false })
              // }

              let arr = []
              if (Object.keys(this.state.wilKabupaten).length > 0) {
                arr = res.data.filter(el => el.id_kabupaten === this.state.wilKabupaten.id_kabupaten)
              } else {
                arr = res.data
              }
              this.setState({ blocking: false, provinsiId: row.id_provinsi, tingkatWilayahId: this.props.tingkatWilayahId, roleId: this.props.roleId, wilKecamatan: this.props.wilKecamatan, wilKelurahan: this.props.wilKelurahan, defaultData: this.state.defaultData.map(el => (el.id_provinsi === row.id_provinsi ? { ...el, children: arr } : el)) });
            }).catch((error) => {
              this.setState({ blocking: false })

            });
        }
      }
    }
  }

  clearForm = () => {
    this.setState({ id: null, kodeDepdagri: '', nama: '' })
  }

  handleDelete = (e, row) => {
    e.stopPropagation();
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin menghapus [" + row.kodeDepdagri + " - " + row.nama + "] ?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        APIWilayah.post('/userwilayah/DeleteProv?idProvinsi=' + row.id_provinsi + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
          .then(res => {
            if (res.status === 200) {
              if (res.data.deleteProvinsi === 'failed (error: 500->)Gagal hapus karena ada data wilayah dibawah provinsi ') {
                Swal.fire({
                  title: 'Error',
                  icon: 'warning',
                  text: 'Data gagal dihapus karena ada data wilayah di bawah provinsi',
                });
              } else {
                Swal.fire({
                  title: 'Sukses',
                  icon: 'success',
                  text: 'Data berhasil dihapus',
                })
                this.setState({ defaultData: this.state.defaultData.filter(el => el.id_provinsi !== row.id_provinsi) })
              }
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal dihapus',
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal dihapus',
            });
          });
      }
    })
  }

  handleEdit = (e, row) => {
    e.stopPropagation();
    this.setState({ id: row.id_provinsi, kodeDepdagri: row.kodeDepdagri, nama: row.nama }, () => { this.handleOpenCloseAddProv() })
  }

  handleOpenCloseAddProv = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openAddProv: !this.state.openAddProv },
      () => { if (!this.state.openAddProv) { this.clearForm() } }
    )
  }

  handleChangeInput = (e) => {
    if (e.target.name === 'kodeDepdagri') {
      if (isNumber(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value })
      }
    } else {
      const pointer = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
      this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    }
  }

  handleAddProv = () => {
    if (this.state.kodeDepdagri && this.state.kodeDepdagri.length > 0 && this.state.nama && this.state.nama.length > 0) {
      if (this.state.kodeDepdagri.length === 2) {
        const cek = this.state.defaultData.find(el => el.kodeDepdagri.replace(/ /g, "") === this.state.kodeDepdagri)
        if (cek === undefined || (cek !== undefined && cek.id_provinsi === this.state.id)) {
          Swal.fire({
            title: 'Peringatan',
            text: "[" + this.state.kodeDepdagri + " - " + this.state.nama + "] Apakah anda yakin data sudah benar? ",
            icon: 'warning',
            cancelButtonText: 'Batal!',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
          }).then((result) => {

            if (result.value) {
              this.setState({ disabledSimpanProv: true })
              if (this.state.id) { //edit
                APIWilayah.post('/userwilayah/EditProv?idProvinsi=' + this.state.id + '&kodeDepdagri=' + this.state.kodeDepdagri + '&namaProvinsi=' + this.state.nama + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      Swal.fire({
                        title: 'Sukses',
                        icon: 'success',
                        text: 'Data berhasil disimpan',
                      })
                      const arr = { id_provinsi: this.state.id, nama: this.state.nama, kodeDepdagri: this.state.kodeDepdagri }
                      this.setState({ defaultData: this.state.defaultData.map(el => el.id_provinsi === this.state.id ? Object.assign({}, el, arr) : el), openAddProv: false, disabledSimpanProv: false }, () => { this.clearForm() })
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanProv: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanProv: false })
                  });
              } else { //tambah
                APIWilayah.post('/userwilayah/AddProv?kodeDepdagri=' + this.state.kodeDepdagri + '&namaProvinsi=' + this.state.nama + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
                  .then(res => {
                    if (res.status === 200) {
                      if (res.data.ID === 0) {
                        Swal.fire({
                          title: 'Error',
                          icon: 'warning',
                          text: 'Data gagal disimpan',
                        })
                        this.setState({ disabledSimpanProv: false })
                      } else {
                        Swal.fire({
                          title: 'Sukses',
                          icon: 'success',
                          text: 'Data berhasil disimpan',
                        })
                        const arr = { id_provinsi: res.data.ID, nama: this.state.nama, kodeDepdagri: this.state.kodeDepdagri, statusDuplikat: false, statusLock: false, periodeSensus: this.state.value_priode }
                        this.setState({ defaultData: [...this.state.defaultData, arr], openAddProv: false, disabledSimpanProv: false }, () => { this.clearForm() })
                      }
                    } else {
                      Swal.fire({
                        title: 'Error',
                        icon: 'warning',
                        text: 'Data gagal disimpan',
                      });
                      this.setState({ disabledSimpanProv: false })
                    }
                  }).catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      icon: 'warning',
                      text: 'Data gagal disimpan',
                    });
                    this.setState({ disabledSimpanProv: false })
                  });
              }
            } else {
              this.setState({ disabledSimpanProv: false })
            }
          });
        } else {
          Swal.fire({
            title: 'Peringatan',
            icon: 'warning',
            text: 'Kode Depdagri telah digunakan.',
          });
        }
      } else {
        Swal.fire({
          title: 'Peringatan',
          icon: 'warning',
          text: 'Kode Depdagri Provinsi harus 2 digit.',
        });
      }
    } else {
      Swal.fire({
        title: 'Peringatan',
        icon: 'warning',
        text: 'Kode Depdagri dan Nama Provinsi tidak boleh kosong.',
      });
    }
  }

  columnClassFormat = (fieldValue, row, rowIdx, colIdx) => {
    if (row.statusDuplikat === true) {
      return 'edafaf'
    } else {
      return ''
    }
  }

  handleLockUnlock = (e, param) => {
    const label = param ? 'Lock' : 'Unlock'
    const id = ''
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin akan melakukan " + label + " data seluruh provinsi?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=1' + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
          .then(res => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Sukses',
                icon: 'success',
                text: 'Data berhasil di ' + label,
              })
              this.setState({ defaultData: this.state.defaultData.map(el => Object.assign({}, el, { statusLock: param })) })
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal di ' + label,
            });
          });
      }
    })
  }

  handleLockUnlockById = (e, row) => {
    e.stopPropagation();
    const label = row.statusLock ? 'Unlock' : 'Lock'
    const param = !row.statusLock
    const id = row.id_provinsi
    Swal.fire({
      title: 'Peringatan',
      text: "Apakah anda yakin akan melakukan " + label + " data " + row.nama + " ?",
      icon: 'warning',
      cancelButtonText: 'Batal!',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        APIWilayah.post('/masterwil/statusLockWilayah?idWilayah=' + id + '&statusLock=' + param + '&tingkatWilayah=1' + '&username=' + localStorage.getItem('username') + '&periodeSensus=' + this.state.value_priode)
          .then(res => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Sukses',
                icon: 'success',
                text: 'Data berhasil di ' + label,
              })
              this.setState({ defaultData: this.state.defaultData.map(el => el.id_provinsi === id ? Object.assign({}, el, { statusLock: param }) : el) })
            } else {
              Swal.fire({
                title: 'Error',
                icon: 'warning',
                text: 'Data gagal di ' + label,
              });
            }
          }).catch((error) => {
            Swal.fire({
              title: 'Error',
              icon: 'warning',
              text: 'Data gagal di ' + label,
            });
          });
      }
    })
  }

  render() {
    var d = new Date().getDay();
    var hourWib = new Date().getUTCHours() + 7;
    // 
    // 


    // if(h < 12){
    //   //this.setState({disablePindahKK : true}, console.)
    // }
    const options = {
      onRowClick: this.onRowClick,
      expandRowBgColor: '#F5F5DC',
      expandBy: 'column',

      page: 1,
      sizePerPage: 10,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: '<',
      nextPage: '>',
      firstPage: '<<',
      lastPage: '>>',
      paginationShowsTotal: false,
      paginationPosition: 'bottom',
      hideSizePerPage: true
    };
    return (
      <>
        <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
          {console.log(this.state.roleId)}

          <Accordion expanded={this.state.panel} className='accordionRoot'>
            <AccordionSummary
              className='panelSummary'
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} onClick={this.handleChangePanel} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontSize: '12px' }}><b>Data Provinsi</b></Typography>

              {/* {this.state.roleId === 1 || this.state.roleId === 99 ? */}
              {this.state.roleId === 1 || this.state.roleId === 99 ?
                <div className='divBtnPanel'>
                  {/* <Button className='btnPanel' onClick={this.handleOpenCloseAddProv}><ImPlus style={{ marginRight: '3px' }} /> Tambah</Button> */}
                  {/* <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, true)} ><AiFillLock style={{ marginRight: '3px' }} /> Lock</Button>
                  <Button className='btnPanel' onClick={(e) => this.handleLockUnlock(e, false)} ><AiFillLock style={{ marginRight: '3px' }} /> Unlock</Button> */}
                </div>
                :
                <></>
              }
            </AccordionSummary>
            <AccordionDetails className='panelDetail'>
              <BootstrapTable data={this.state.defaultData}
                pagination={true}
                search={true}
                options={options}
                expandableRow={this.isExpandableRow}
                expandComponent={this.expandComponent}

                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 40
                }}>
                <TableHeaderColumn width='120px' dataField='kodeDepdagri' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode Provinsi</TableHeaderColumn>
                <TableHeaderColumn dataField='nama' headerAlign='center' expandable={false}>Nama Provinsi</TableHeaderColumn>
                <TableHeaderColumn width='120px' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter} expandable={false}>Aksi</TableHeaderColumn>
              </BootstrapTable>
              <div className='totalDataExpand'>Total Data: {this.state.defaultData?.length}</div>
            </AccordionDetails>
          </Accordion>
          <Dialog open={this.state.openAddProv} onClose={this.handleOpenCloseAddProv}>
            <DialogTitle>{this.state.id ? 'Ubah' : 'Tambah'} Provinsi</DialogTitle>
            <DialogContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    inputProps={{ maxLength: 2 }}
                    id="filled-required"
                    label="Kode Provinsi"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.kodeDepdagri}
                    name="kodeDepdagri"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="filled-required"
                    label="Nama Provinsi"
                    variant="standard"
                    onChange={this.handleChangeInput}
                    value={this.state.nama}
                    name="nama"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={this.state.disabledSimpanProv} onClick={this.handleAddProv} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
              <Button disabled={this.state.disabledSimpanProv} onClick={this.handleOpenCloseAddProv} variant="outlined" color="error">Batal</Button>
            </DialogActions>
          </Dialog>
        </BlockUi>
      </>
    );
  }
}