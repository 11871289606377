import axios from 'axios';
import Swal from 'sweetalert2';
// import environment from '../environment.js'
let url = ''
let urlRef = 'http://pkportalauthorizeservice-bkkbn-pk.apps.container.layanan.go.id/auth/refreshToken'

if (process.env.REACT_APP_ENV === 'development') {
    url = "http://dev-portal-api-gateway-portal-pk-staging.apps.ocp-dev.bkkbn.go.id/masterwilayah"
}

if (process.env.REACT_APP_ENV === 'production') {
    url = "https://pk23-portal-gw.bkkbn.go.id/masterwilayah"
}

const APIWilayah = axios.create({
    // baseURL: 'http://dev2.multisoft.co.id:10013/',
    // baseURL: environment.baseUrl+'location-service-bkkbn.apps.tkp.platform.lintasarta.net/',
    baseURL: url,
    // baseURL:'http://dev-location-service-bkkbn.apps.tkp.platform.lintasarta.net/',
    headers: {
        Authorization: localStorage.getItem('token') !== 'null' ? 'Bearer ' + localStorage.getItem('token') : '',
        'Authorization' : 'Basic YWRtaW46cGFzc3dvcmQ=',
        // Authorization: 'Basic ' +btoa(username + ':' + password),
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        // 'user_key': '6ab0c0c4f7c40f3c7747329890cc5e78',
        'Content-Type': 'application/json',
    },
    responseType: 'json'
});

axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
APIWilayah.interceptors.response.use(response => {
    return response;
}, err => {
    // return new Promise((resolve, reject) => {
    const originalReq = err.config;
    const refreshToken = localStorage.getItem('refreshToken')
    // const refreshUrl = environment.baseUrl+'sigaauthorizationservice-bkkbn.apps.tkp.platform.lintasarta.net/auth/refreshToken'
    const refreshUrl = urlRef

    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest && refreshToken) {
        originalReq._retry = true;
        let res = fetch(refreshUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token")
            },
            body: JSON.stringify({
                idAplikasi: 2,
                password: localStorage.getItem('pass'),
                refreshToken: refreshToken,
                username: localStorage.getItem('username')
            }),
        }).then(res => res.json()).then(res => {
            originalReq.headers['Authorization'] = 'Bearer ' + res.accessToken;
            localStorage.setItem('token', res.accessToken)

            if (res.status == 401) {
                Swal.fire({
                    title: 'Masa Aktif Berakhir',
                    icon: 'info',
                    text: 'Masa aktif login telah berakhir, silakan login kembali'
                })
                    .then(() => {
                        localStorage.clear()
                        window.location.reload();
                    })
            }

            return axios(originalReq);
        });
        // resolve(res);
    }
    return Promise.reject(err);
    // });
});

export default APIWilayah
