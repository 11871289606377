import React, { Component } from 'react'
import { ImPlay3, ImUserPlus, ImSearch, ImPhone, ImLocation, ImFloppyDisk, ImCross, ImPrinter } from 'react-icons/im';
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIDashboard from "../../../services/APIDashboard";
import CommonTable from '../../../commons/table/TableMonitoring';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import APIReport from '../../../services/APIReport';
import Swal from 'sweetalert2';
import APIMonitoringBaru from '../../../services/APIMonitoringBaru';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdPassword, MdVerified } from 'react-icons/md';



class RekapStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panel1: true,
      sizePerPage: 10,
      title: '',
      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      text1: 'Provinsi',

      datas: [],

      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,

      //datas: [],
      //selectDatas: [],
      sendDatas: {
        "prov": 0,
        "kab": 0,
        "kec": 0,
        "kel": 0,
        "rw": 0,
        "tahun": 2023
      },
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
    }
  }

  // getBackend(formData)
  // {
  //   console.log(formData, "Data yang dikirim");
  //   APIDashboard.post("/pkportal/monitoring/getPendataanStatus", formData)
  //     .then((res) => {
  //       this.setState({ blocking: false });
  //       if (res.status === 200) {
  //         this.setState(
  //           {
  //             datas:res.data.data
  //           }
  //         )
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({ blocking: false });
  //       console.log(error, "dataaa ERROR");
  //     });



  //   console.log("formData",formData)

  // }

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      }, () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if (role === 1 || role === 99) {
          this.setState({
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
          })
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }

            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  setHeaderTable = () => {
    let { text1 } = this.state
    var headerColumns = [
      { width: '150', row: '0', col: '0', rowSpan: '2', title: text1, dataField: "namaDaerah", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: '150', row: '0', col: '1', rowSpan: '2', title: 'KK Target', dataField: "kk_target", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: '200', row: '0', col: '2', colSpan: '2', title: 'KK Valid', dataField: "#", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '200', row: '0', col: '3', colSpan: '2', title: 'KK Tidak Valid', dataField: "#", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '400', row: '0', col: '4', colSpan: '3', title: 'KK Anomali', dataField: "#", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', row: '1', col: '2', title: 'Jumlah', dataField: "jmlValid", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', row: '1', col: '3', title: '% Terhadap KK Target', dataField: "persenValid", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', row: '1', col: '4', title: 'Jumlah', dataField: "jmlTidakValid", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', row: '1', col: '5', title: '% Terhadap KK Target', dataField: "persenTidakValid", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', row: '1', col: '6', title: 'Jumlah', dataField: "jmlAnomali", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', row: '1', col: '7', title: '% Terhadap KK Target', dataField: "persenAnomali", headerAlign: 'center', dataAlign: 'right', editable: false },
      // { width: '100', row:'1', col:'8', title: 'Jumlah Anomali Pendataan Stunting', dataField: "jmlStunting", headerAlign: 'center', dataAlign: 'right', editable: false },
      { width: '100', row: '1', col: '9', title: 'Jumlah Anomali Terkonfirmasi', dataField: "jmlAnomaliKonfirmasi", headerAlign: 'center', dataAlign: 'right', editable: false },



    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidMount() {
    this.setHeaderTable()
    const rest = JSON.parse(localStorage.getItem('authority'))
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    let title2, text11, text21

    if (idTingkatWilayah == 0) {
      title2 = ''

      // this.setState({
      //   sendDatas:{
      //     "prov": 0,
      //     "kab": 0,
      //     "kec": 0,
      //     "kel": 0,
      //     "rw": 0,
      //     "tahun": 0
      //  }
      // }
      // );

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      // this.setState(
      //   {
      //   sendDatas:{
      //     "tingkatWilayahID":1,
      //     "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
      //     "kab": 0,
      //     "kec": 0,
      //     "kel": 0,
      //     "rw": 0,
      //     "tahun": 0
      //    }
      //   }


      // )


    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

      // this.setState(
      //   {
      //     sendDatas:{
      //       "tingkatWilayahID":2,
      //       "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
      //       "kab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
      //       "kec": 0,
      //       "kel": 0,
      //       "rw": 0,
      //       "tahun": 0
      //       }
      //     },
      //     () => {this.getBackend(this.state.sendDatas)}  

      // )


    } else {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan


      // this.setState(
      //   {
      //     sendDatas:{
      //       "tingkatWilayahID":3,
      //       "prov": rest[0].attributes.user.wilProvinsi.id_provinsi,
      //       "kab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
      //       "kec": 0,
      //       "kel": 0,
      //       "rw": 0,
      //       "tahun": 0
      //     }
      //     }
      // )
    }

    this.setState({ title: title2 },
      () => { this.setHeaderTable() }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {
                console.log("objKab", objKab)
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {
                console.log("objKec", objKec)
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
              }
            }
          );


          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }
  //API (Wilayah)
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      //handleSearch
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "prov": +e.value

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
      );

      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )


    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "prov": 0,
          "kab": 0,
          "kec": 0,
          "kel": 0,
          "rw": 0

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "kab": +e.value,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
        , id_kecamatan: 0, id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "kab": 0,
          "kec": 0,
          "kel": 0,
          "rw": 0,

        }

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "kec": +e.value,

        }
      });


      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
        , id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "kec": 0,
          "kel": 0,
          "rw": 0,

        }

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "kel": +e.value,
          "rw": 0,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
        , id_rw: 0

      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "kel": 0,
          "rw": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          ...this.state.sendDatas,
          "rw": +e.value,

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          ...this.state.sendDatas,
          "rw": 0,

        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah
  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        //text1 = 'KODE RT'
        text1 = 'NAMA RT'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          //text1 = 'KODE RW'
          text1 = 'NAMA RW'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            //text1 = 'KODE KELURAHAN'
            text1 = 'NAMA KELURAHAN'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              //text1 = 'KODE KECAMATAN'
              text1 = 'NAMA KECAMATAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                //text1 = 'KODE KABUPATEN'
                text1 = 'NAMA KABUPATEN'
              }
              else {
                title = ''
                //text1 = 'KODE PROVINSI'
                text1 = 'NAMA PROVINSI'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1 },
      () => { this.setHeaderTable() }
    )
  }

  handleSearch = () => {
    this.setLabel()
    let dataParam = {
      "kab": this.state.id_kabupaten,
      "kec": this.state.id_kecamatan,
      "kel": this.state.id_des,
      "prov": this.state.id_provinsi,
      "rw": this.state.id_rw,
      "tahun": 2024
    }
    this.setState({ blocking: true })
    APIMonitoringBaru.post("/pkportal/monitoring/getPendataanStatus?periodeSensus=" + this.state.value_priode, dataParam)
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState(
            {
              datas: res.data.data
            }
          )
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });


  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  handleCetak = (e) => {


    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;
    //this.setJudulLaporan()

    let judul_lap, kode_lap
    //const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      kode_lap = this.state.rt.kode
      judul_lap = this.state.rt.namaWil
    } else {
      kode_lap = ''
      judul_lap = ''
      if (id_rw !== 0) {
        kode_lap = this.state.rw.kode
        judul_lap = this.state.rw.namaWil
      } else {
        kode_lap = ''
        judul_lap = ''
        if (id_des !== 0) {
          kode_lap = this.state.desa.kode
          judul_lap = this.state.desa.namaWil
        } else {
          kode_lap = ''
          judul_lap = ''
          if (id_kecamatan !== 0) {
            kode_lap = this.state.kecamatan.kode
            judul_lap = this.state.kecamatan.namaWil
          } else {
            kode_lap = ''
            judul_lap = ''
            if (id_kabupaten !== 0) {
              kode_lap = this.state.kabupaten.kode
              judul_lap = this.state.kabupaten.namaWil
            } else {
              kode_lap = ''
              judul_lap = ''
              if (id_provinsi !== 0) {
                kode_lap = this.state.provinsi.kode
                judul_lap = this.state.provinsi.namaWil
              } else {
                kode_lap = ''
                judul_lap = ' Tingkat Nasional'
              }
            }
          }
        }
      }
    }

    this.setState({ judul_laporan: judul_lap, kode_laporan: kode_lap }, () => console.log('judul', this.state.judul_laporan, 'kode', this.state.kode_laporan))

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#4ea842',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#FF8000',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      //let urlCetakPdf = url +'%22ketWilayah%22%3A%22'+'tes' + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C'+ '%22title%22%3A%22' +this.state.title+ '%22%2C' +'%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'


      //viewReport?filterPencarian=%7B%22provinsiId%22%3A%221%22%2C%22kabupatenId%22%3A%227%22%2C%22kecamatanId%22%3A%224155%22%2C%22ketWilayah%22%3A%22Nama%20wilayah%20inputan%22%2C%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D

      //{"provinsiId":"1","kabupatenId":"7","kecamatanId":"4155","ketWilayah":"Nama wilayah inputan","reportName":"TABEL1","reportType":"pdf","reportCategory":"MasterWilayah"}

      //let coba =''
      //coba = decodeURI(this.state.judul_laporan)
      let urlCetakPdf = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
      console.log('pdf', urlCetakPdf)
      let urlCetakExcel = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // console.log("error ===> ", error)
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            // console.log(res, 'res report')
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // console.log("error ===> ", error)
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })

    e.preventDefault()

  }

  statusFormatter = (cell, row) => {
    if (this.state.id_kecamatan !== 0) {

      if (row.statusEcdi === true) {

        return <div className='userActive'> < MdOutlineCheckBox style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> ECDI</div>
      } else {
        return <div className='userNonActive'><MdOutlineCheckBoxOutlineBlank style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Non ECDI</div >
      }

    } else {
      return <>-</>
    }
  }
  render() {
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "<", // Previous page button text
      nextPage: ">", // Next page button text
      firstPage: "<<", // First page button text
      lastPage: ">>", // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
    };
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Status Pendataan (Rekap)</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                          {/* <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button> */}

                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                        <Grid item xs={12}>
                          <Button onClick={this.handleSearch} sx={{ width: '30%', minHeight: '36px', marginTop: '22px', marginRight:'5px' }} variant="contained">Cari</Button>
                          <Button  sx={{ width: '34%', minHeight: '36px', marginTop: '22px', backgroundColor:'green', marginRight:'5px', ':hover': {bgcolor: 'green', color: 'white'} }} variant="contained">Cetak .xlsx</Button>
                          <Button  sx={{ width: '32%', minHeight: '36px', marginTop: '22px', backgroundColor:'#DC143C', ':hover': {bgcolor: '#DC143C', color: 'white'} }} variant="contained">Cetak .pdf</Button>

                        </Grid>
                          {/* <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl> */}
                        {/* </Grid> */}
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={12}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '5px' }} variant="contained">Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* var headerColumns = [
            {width: '150', row: '0', col: '0', rowSpan: '2', title: text1, dataField: "namaDaerah", headerAlign: 'center', dataAlign: 'center', editable: false },
            {width: '150', row: '0', col: '1', rowSpan: '2', title: 'KK Target', dataField: "kk_target", headerAlign: 'center', dataAlign: 'center', editable: false },
            {width: '200', row: '0', col: '2', colSpan: '2', title: 'KK Valid', dataField: "#", headerAlign: 'center', dataAlign: 'right', editable: false },
            {width: '200', row: '0', col: '3', colSpan: '2', title: 'KK Tidak Valid', dataField: "#", headerAlign: 'center', dataAlign: 'right', editable: false },
            {width: '400', row: '0', col: '4', colSpan: '3', title: 'KK Anomali', dataField: "#", headerAlign: 'center', dataAlign: 'right', editable: false },

            {width: '100', row: '1', col: '2', title: 'Jumlah', dataField: "jmlValid", headerAlign: 'center', dataAlign: 'right', editable: false },
            {width: '100', row: '1', col: '3', title: '% Terhadap KK Target', dataField: "persenValid", headerAlign: 'center', dataAlign: 'right', editable: false },
            {width: '100', row: '1', col: '4', title: 'Jumlah', dataField: "jmlTidakValid", headerAlign: 'center', dataAlign: 'right', editable: false },
            {width: '100', row: '1', col: '5', title: '% Terhadap KK Target', dataField: "persenTidakValid", headerAlign: 'center', dataAlign: 'right', editable: false },
            {width: '100', row: '1', col: '6', title: 'Jumlah', dataField: "jmlAnomali", headerAlign: 'center', dataAlign: 'right', editable: false },
            {width: '100', row: '1', col: '7', title: '% Terhadap KK Target', dataField: "persenAnomali", headerAlign: 'center', dataAlign: 'right', editable: false },
            {width: '100', row: '1', col: '9', title: 'Jumlah Anomali Terkonfirmasi', dataField: "jmlAnomaliKonfirmasi", headerAlign: 'center', dataAlign: 'right', editable: false },



            ]; */}
            <h3>Status Pendataan (Rekap) {this.state.title}</h3>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <div className='rekap-table'>
                <BootstrapTable data={this.state.datas}
                  pagination={true}
                  options={options}
                  search={true}
                >
                  <TableHeaderColumn row="0" rowSpan='2' width="130" dataField="statusEcdi" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataFormat={this.statusFormatter} >Status</TableHeaderColumn>
                  <TableHeaderColumn row="0" rowSpan='2' width="130" dataField="namaDaerah" isKey={true} headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{this.state.text1} </TableHeaderColumn>
                  <TableHeaderColumn row="0" rowSpan='2' width="120" dataField="kk_target" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>KK Target </TableHeaderColumn>
                  <TableHeaderColumn row='0' colSpan='2' width="120" dataField="jmlValid" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>KK Valid </TableHeaderColumn>
                  <TableHeaderColumn row="1" width="120" dataField="jmlValid" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Jumlah </TableHeaderColumn>
                  <TableHeaderColumn row="1" width="120" dataField="persenValid" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>% Terhadap KK Target </TableHeaderColumn>
                  <TableHeaderColumn row='0' colSpan='2' width="80" dataField="jmlValid" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>KK Tidak Valid</TableHeaderColumn>
                  <TableHeaderColumn row="1" width="120" dataField="jmlTidakValid" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Jumlah </TableHeaderColumn>
                  <TableHeaderColumn row="1" width="120" dataField="persenTidakValid" headerAlign="center" dataFormat={this.dateFormatter} dataAlign="center" >% Terhadap KK Target</TableHeaderColumn>
                  <TableHeaderColumn row='0' colSpan='3' width="120" dataField="persenTidakValid" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >KK Anomali</TableHeaderColumn>
                  <TableHeaderColumn row="1" width="120" dataField="jmlAnomali" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Jumlah</TableHeaderColumn>
                  <TableHeaderColumn row="1" width="120" dataField="persenAnomali" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >% Terhadap KK Target</TableHeaderColumn>
                  <TableHeaderColumn row="1" width="180" dataField="jmlAnomaliKonfirmasi" headerAlign="center" dataAlign="center" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Jumlah Anomali Terkonfirmasi</TableHeaderColumn>


                </BootstrapTable>
              </div>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value={this.state.datas?.length}>All</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas?.length}</b></div>
            </Paper>
          </div>
        </div>
      </BlockUi>
    )
  }





}

export default RekapStatus;