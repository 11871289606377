import React, { Component } from 'react';
import { ImFileExcel, ImFilePdf, ImSearch } from 'react-icons/im';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import BlockUi from 'react-block-ui';
import { ImCross, ImFloppyDisk } from 'react-icons/im';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import APIWilayah from '../../services/APIWilayah';
import Tabulasi from '../../services/Tabulasi';
import APITabulasiHistory from '../../services/APITabulasiHistory';
const pathname = window.location.href;
var splitUrl = pathname.split('/');


class TabulasiDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panel1: true,
      roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,
      blocking: false,
      urlLaporan: '',
      urlNew: '',
      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,
      periode: [
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
        { value: '2023', label: '2023' }
      ],
      sasaran: [
        { value: '%2C%22baduta%22%3A%22V%22', label: 'Baduta' },
        { value: '%2C%22balita%22%3A%22V%22', label: 'Balita' },
        { value: '%2C%22hamil%22%3A%22V%22', label: 'Status Hamil' },
        { value: '%2C%22pus%22%3A%22V%22', label: 'Status PUS' }
      ],
      sasaran_select: null,
      name_sasaran: null,
      periode_select: { value: '2023', label: '2023' },

      semester: [
        { value: 1, label: 'Semester 1' },
        { value: 2, label: 'Semester 2' },
        // { value: 3, label: 'Semester 3 (VERVAL)' },
      ],
      semester_select: '',
      name_semester: '',
      sendDatas: {
        "idProv": 0,
        "idKab": 0,
        "idKec": 0,
        "idKel": 0,
        "idRW": 0,
        "idRt": 0,
        "limit": 300
      },
      phone_auth: JSON.parse(sessionStorage.getItem('phone_auth')),
      openModal: false,
      disButton: true,
      submitDate: '',
      nama: '',
      instansi: '',
      catatan: '',
      paramCetak: '',
      isLogin: false,
      disbuttonNow: false
    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  setHeaderTable = () => {
    var headerColumns = [
      // { width: "50", row: '0', rowSpan: '2', title: 'NO', dataField: "no", headerAlign: 'center', dataAlign: 'center', editable: false },

      // headerColumnsProv
      { width: "300", row: '0', colSpan: '2', title: 'PROVINSI', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriProv', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_prov', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKab
      { width: "300", row: '0', colSpan: '2', title: 'KABUPATEN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKab', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kab', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKec
      { width: "300", row: '0', colSpan: '2', title: 'KECAMATAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKec', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kec', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerColumnsKel
      { width: "300", row: '0', colSpan: '2', title: 'KELURAHAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKel', headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kel', headerAlign: 'center', dataAlign: 'left', editable: false },

      // headerMetodePendataan
      { width: "120", row: '0', rowSpan: '2', title: 'METODE PENDATAAN', dataField: "metodePendataan", headerAlign: 'center', dataAlign: 'center', editable: false },
    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidUpdate() {

  }

  componentDidMount() {
    const pathname = window.location.href;
    var splitUrl = pathname.split('/');
    const check_token = localStorage.getItem('username')
    let cek = ''

    const priodeLama = [
      { value: '2021', label: '2021' },
      { value: '2022', label: '2022' },
      { value: '2023', label: '2023' }

    ]

    const priodeBaru = [
      { value: '2021', label: '2021' },
      { value: '2022', label: '2022' },
      { value: '2023', label: '2023' },
      { value: '2024', label: '2024' },


    ]

    const priodeBaruBanget = [
      { value: '2021', label: '2021' },
      { value: '2022', label: '2022' },
      { value: '2023', label: '2023' },
      { value: '2024', label: '2024' },

    ]

    if (check_token == null || this.state.roleUserLogin === 2 || this.state.roleUserLogin === 9 || this.state.roleUserLogin === 3 || this.state.roleUserLogin === 6) {
      if (splitUrl[4] === "IK" || splitUrl[4] === "IPK") {
        if ((splitUrl[5] === "Tabel12" && splitUrl[4] === "IK") || splitUrl[5] === "Tabel13" && splitUrl[4] === "IK") {
          this.setState({
            periode: priodeLama,
          })
        } else {
          this.setState({
            periode: priodeBaru,
          })
        }

      } else if (splitUrl[4] === "IKB") {
        const tabelValues = ["Tabel99"];
        let found = false;
        for (let i = 0; i < tabelValues.length; i++) {
          if (splitUrl[5] === tabelValues[i]) {
            found = true;
            break;
          }
        }
        console.log(found)
        if (found) {
          this.setState({
            periode: priodeLama,
          });
        } else {
          this.setState({
            periode: priodeBaru,
          });
        }


      } else if (splitUrl[4] === "CAKUPAN") {
        this.setState({
          periode: priodeBaru,
        })
      } else if (splitUrl[4] === "STUNTING") {
        this.setState({
          periode: priodeBaruBanget,
        })
      } else {
        this.setState({
          periode: priodeLama,
        })
      }

    } else {
      this.setState({
        periode: priodeBaru,
      })
    }




    console.log(this.state.periode)

    const rest = JSON.parse(localStorage.getItem('authority'))
    let idTingkatWilayah = 0;

    const cekLogin = JSON.parse(sessionStorage.getItem('isLogin'))
    this.setState({ isLogin: cekLogin })

    if (rest === null) {
      idTingkatWilayah = 0;
    } else {
      idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    }

    let title2, text11, text21

    if (idTingkatWilayah == 0) {
      title2 = ''

      this.setState({
        sendDatas: {
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten
      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      }
        // () => { this.getBackend(this.state.sendDatas)
        //  }
      )


    } else {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan.nama_kecamatan

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });
    }

    this.setState({ title: title2, text1: text11, text2: text21 },
      () => {
        this.setHeaderTable()
      }
    )

    if (rest === null) {
      this.setProv(null);
    } else {
      if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

        const nm = rest[0].attributes.user.TingkatWilayahID
        if (+nm === 0) {
          this.setState({ flagSearch: false },
            () => {
              this.setProv(null)
            }
          )
        } else {
          if (+nm === 1) { this.setState({ flagNumber: 1 }) }
          else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
          else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
          else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
          //--prepare by level
          if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
          } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
          } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
          } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
            this.setState({ dis_prov: true, auto_prov: true })
          }
          const wilProvinsi = rest[0].attributes.user.wilProvinsi
          const wilKabupaten = rest[0].attributes.user.wilKabupaten
          const wilKecamatan = rest[0].attributes.user.wilKecamatan
          const wilKelurahan = rest[0].attributes.user.wilKelurahan

          if (Object.keys(wilProvinsi).length > 0) {

            const objProp = {
              'value': wilProvinsi.id_provinsi,
              'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
            };

            this.setState({ dt_prov: objProp },
              () => {
                if (this.state.auto_prov === true) {
                  //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                  this.sel_prov(objProp)
                }
              }
            );
            //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
          }
          //        alert(wilKabupaten.length)
          if (Object.keys(wilKabupaten).length > 0) {
            //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            const objKab = {
              'value': wilKabupaten.id_kabupaten,
              'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
            };

            this.setState({ dt_kab: objKab },
              () => {
                if (this.state.auto_kab === true) {
                  //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                  this.sel_kab(objKab)
                }
              }
            );

          }
          if (Object.keys(wilKecamatan).length > 0) {
            const objKec = {
              'value': wilKecamatan.id_kecamatan,
              'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
            };

            this.setState({ dt_kec: objKec },
              () => {
                if (this.state.auto_kec === true) {
                  //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                  this.sel_kec(objKec)
                }
              }
            );
            //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
          }
          if (Object.keys(wilKelurahan).length > 0) {
            const objKel = {
              'value': wilKelurahan.id_kelurahan,
              'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
            };

            this.setState({ dt_kel: objKel },
              () => {
                if (this.state.auto_des === true) {
                  //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                  this.sel_des(objKel)
                }
              }
            );


            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
          }
        }
      } else {
        this.setProv(null);
      }
    }
    // if (rest === false) { this.props.history.push('/401') }

  }

  //API (Wilayah)
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    let priode = this.state.periode_select?.value
    console.log(this.state.periode_select)
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + priode)
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    let priode = this.state.periode_select?.value
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    let priode = this.state.periode_select?.value

    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=' + priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    let priode = this.state.periode_select?.value

    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=' + priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    let priode = this.state.periode_select?.value

    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=' + priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    let priode = this.state.periode_select?.value

    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=' + priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      //handleSearch
      this.setState({
        sendDatas: {
          "idProv": e.value,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)}*/
      );

      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    } else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": e.value,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode
        , id_kecamatan: 0, id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    } else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": e.value,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
    () => {this.getBackend(this.state.sendDatas)} */
      );

      /*
            this.setState(prevState => ({
              sendDatas:
                  {
                      ...prevState.sendDatas, ['idKec']: e.value
                  }
              },
              () => {this.getBackend(this.state.sendDatas)}
              ))
      */

      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode
        , id_des: 0, id_rw: 0

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    } else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": this.state.sendDatas.idKec,
          "idKel": e.value,
          "idRW": 0,
          "idRt": 0,
          "limit": 300

        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );

      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true
        , id_rw: 0

      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    } else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {

      this.setState({
        sendDatas: {
          "idProv": this.state.sendDatas.idProv,
          "idKab": this.state.sendDatas.idKab,
          "idKec": this.state.sendDatas.idKec,
          "idKel": this.state.sendDatas.idKel,
          "idRW": e.value,
          "idRt": 0,
        }
      }/*,
      () => {this.getBackend(this.state.sendDatas)} */
      );


      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    } else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    } else {
      this.setState({
        rt: null, id_rt: 0
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  sel_periode = (e) => {
    if (e) {
      // If the selected year is 2023, set Semester 2 as the default
      const defaultSemester = e.value === '2023' ? { label: 'Semester 2', value: 2 } : null;

      this.setState({
        periode_select: { label: e.value, value: e.value },
        semester_select: defaultSemester,
        name_semester: defaultSemester,
      }, () => {
        this.setHeaderTable()

        const rest = JSON.parse(localStorage.getItem("authority"));
        console.log(rest)
        this.setState({
          dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
          dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
          dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
          dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
          dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
          dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
        })
        if (rest === null) {
          this.setProv(null)
        } else {
          if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
            const nm = rest[0].attributes.user.TingkatWilayahID
            if (+nm === 0) {
              this.setState({
                flagSearch: false,
                roleUser: rest[0].attributes.role
              },
                () => {
                  this.setProv(null)
                }
              )
            } else {
              if (+nm === 1) { this.setState({ flagNumber: 1 }) }
              else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
              else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
              else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
              //--prepare by level
              if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
              } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
              } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
              } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                this.setState({ dis_prov: true, auto_prov: true })
              }

              if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
              }
              if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
              }
              if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
              }
              if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
              }
            }

          } else {
            this.setProv(null);
          }
        }
      })



    } else {
      this.setState({
        periode_select: null,
        semester_select: null,
      });
    }
  }

  sel_semester = (e) => {
    if (e) {
      this.setState({
        semester_select: { label: e.value, value: e.value },
        name_semester: { label: e.label, value: e.label },
      })
    } else {
      this.setState({
        semester_select: null
      })
    }
  }

  sel_sasaran = (e) => {
    if (e) {
      this.setState({
        sasaran_select: { label: e.value, value: e.value },
        name_sasaran: { label: e.label, value: e.label },
      })
    } else {
      this.setState({
        sasaran_select: null,
        name_sasaran: null,

      })
    }
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  handleChangeForm = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({ [name]: value },
      () => {
        if (this.state.nama !== '' && this.state.instansi !== '' && this.state.catatan !== '') {
          this.setState({ disButton: false })
        } else {
          this.setState({ disButton: true })
        }
      }
    )
  }

  handleOpenModal = () => {
    this.setState({ openModal: true })
  }
  handleCloseModal = () => {
    this.setState({ openModal: false, disButton: true, nama: '', instansi: '', catatan: '' })
  }

  handleClickCetak = (param) => {
    this.setState({ paramCetak: param })

    const rest = JSON.parse(localStorage.getItem('authority'))
    const getSubmitDate = localStorage.getItem('submit_date')
    const today = new Date()
    const todayDate = this.formatDate(today)
    if (
      this.state.semester_select === null && 
      (this.state.periode_select.value === '2023' || this.state.periode_select.value === '2024') &&
      (splitUrl[4] === "STUNTING" || splitUrl[4] === "CakupanVerval")
    ) {
      Swal.fire(
        'Peringatan !',
        'Mohon Pilih Semester',
        'warning'
      );
      return false;
    }

    if (this.state.periode_select === null) {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Diperlukan Pilihan Periode',
        showConfirmButton: false,
        timer: 1500
      })
      this.setState({ blocking: false });
    } else {
      if (splitUrl[4] === "STUNTING" && splitUrl[5] === "Tabel5" && this.state.id_kecamatan === 0) {
        Swal.fire({
          title: 'Info!',
          icon: 'info',
          text: 'Hanya Untuk Table 5, Diperlukan Pencarian Minimal Kecamatan',
          showConfirmButton: false,
          timer: 1500
        })
        this.setState({ blocking: false });
      } else {
        if (param === 'pdf') {
          if (rest == null) {
            if (getSubmitDate == null || getSubmitDate !== todayDate) {
              localStorage.removeItem("submit_date");
              localStorage.removeItem("nama");
              localStorage.removeItem("instansi");
              localStorage.removeItem("catatan");
              this.setState({ openModal: true })
            } else {
              this.handleCetak()
            }

          } else {
            this.handleCetak()
          }

        } else if (param === 'excel') {
          if (rest == null) {
            if (getSubmitDate == null || getSubmitDate !== todayDate) {
              localStorage.removeItem("submit_date");
              localStorage.removeItem("nama");
              localStorage.removeItem("instansi");
              localStorage.removeItem("catatan");
              this.setState({ openModal: true })
            } else {
              this.handleCetakExcel()
            }

          } else {
            this.handleCetakExcel()
          }
        }
      }
    }
  }

  formatDate = (date) => {
    const today = new Date(date)
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    return yyyy + '-' + mm + '-' + dd;
  }

  handleSubmitProfil = () => {
    // let today = new Date().toISOString()
    const today = new Date()
    const todayDate = this.formatDate(today)


    // const dataSubmit = {
    //   'nama': this.state.nama,
    //   'instansi': this.state.instansi,
    //   'submitDate': todayDate
    // }

    const dataSubmit = {
      "nama": this.state.nama,
      "instansi": this.state.instansi,
      "catatan": this.state.catatan
    }


    // localStorage.setItem('submit_date', todayDate)
    // localStorage.setItem('nama', this.state.nama)
    // localStorage.setItem('instansi', this.state.instansi)
    console.log('data Simpan >>', dataSubmit)

    APITabulasiHistory.post(`portalpk/monitoring/insertHistoryLoginTabulasi`,
      dataSubmit)
      .then((res) => {
        if (res.status === 200) {
          if (this.paramCetak == "pdf") {
            this.handleCetak()
          } else {
            this.handleCetakExcel()
          }
          localStorage.setItem('submit_date', todayDate)
          localStorage.setItem('nama', this.state.nama)
          localStorage.setItem('instansi', this.state.instansi)
          localStorage.setItem('catatan', this.state.catatan)
          this.setState({ openModal: false, nama: '', instansi: '', catatan: '' })
        }
        this.setState({ openModal: false, nama: '', instansi: '', catatan: '' })
      })
      .catch((error) => {
        this.setState({ blocking: false, disButton: false });
        console.log(error, "dataaa ERROR");
      });
    // sessionStorage.setItem('phone_auth', JSON.stringify(dataSubmit))
    // sessionStorage.setItem('nama', this.state.nama)
    // sessionStorage.setItem('email', this.state.email)
    // sessionStorage.setItem('instansi', this.state.instansi)
    // sessionStorage.setItem('catatan', this.state.catatan)
    // sessionStorage.setItem('no_hp', this.state.nohp)

    this.setState({ phone_auth: dataSubmit })
  }

  handleCetak = () => {
    const pathname = window.location.href;
    var splitUrl = pathname.split('/');
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, periode_select, semester_select, sasaran_select } = this.state;

    const link = document.createElement("a");
    let urlnew = ''
    let namaReportnya = splitUrl[4]
    if (this.state.periode_select?.value === '2021') {
      namaReportnya = splitUrl[4]
    } else if (this.state.periode_select?.value === '2022') {
      namaReportnya = splitUrl[4] + '2022'
    } else if (this.state.periode_select?.value === '2023') {
      namaReportnya = splitUrl[4] + '2023'
    } else {
      namaReportnya = splitUrl[4] + '2024'
    }

    if (this.state.periode_select?.value === '2021' && splitUrl[4] === 'IPK') {
      namaReportnya = 'IPKPADANAN'
    }
    if (splitUrl[4] === "STUNTING" && splitUrl[5] === "Tabel5") {
      namaReportnya = 'STUNTING2023'

    }

    if (splitUrl[4] === "CakupanVerval") {
      namaReportnya = 'CakupanVerval'
    }
    //kalo 2021 panggil yang lama kalo 2022 yang baru
    if (id_rt) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22rtId%22%3A%22${id_rt}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_rw) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_des) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_kecamatan) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_kabupaten) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_provinsi) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    }

    if (semester_select !== null && semester_select !== '') { urlnew += '%2C%22semester%22%3A%22' + semester_select.value + '%22' }
    if (sasaran_select !== null && sasaran_select !== '') { urlnew += sasaran_select.value }


    urlnew += '%7D'
    if ((splitUrl[4] === "STUNTING" && splitUrl[5] === "Tabel5")) {
      link.target = "_blank";
      link.download = "Tabulasi.zip"
      Tabulasi
        .get(urlnew, {
          responseType: "blob",
        })
        .then((res) => {
          link.href = URL.createObjectURL(
            new Blob([res.data], { type: "blob" })
          );
          link.click();
        });
    } else {
      link.target = "_blank";
      link.download = "Tabulasi.pdf"
      Tabulasi
        .get(urlnew, {
          responseType: "blob",
        })
        .then((res) => {
          link.href = URL.createObjectURL(
            new Blob([res.data], { type: "blob" })
          );
          link.click();
        });
    }

  }

  handleCetakExcel = () => {
    const pathname = window.location.href;
    var splitUrl = pathname.split('/');
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, periode_select, semester_select, sasaran_select } = this.state;

    const link = document.createElement("a");
    let urlnew = ''
    let namaReportnya = splitUrl[4]
    if (this.state.periode_select?.value === '2021') {
      namaReportnya = splitUrl[4]
    } else if (this.state.periode_select?.value === '2022') {
      namaReportnya = splitUrl[4] + '2022'
    } else if (this.state.periode_select?.value === '2023') {
      namaReportnya = splitUrl[4] + '2023'
    } else {
      namaReportnya = splitUrl[4] + '2024'
    }

    if (this.state.periode_select?.value === '2021' && splitUrl[4] === 'IPK') {
      namaReportnya = 'IPKPADANAN'
    }

    if (splitUrl[4] === "STUNTING" && splitUrl[5] === "Tabel5") {
      namaReportnya = 'STUNTING2023'

    }

    if (splitUrl[4] === "CakupanVerval") {
      namaReportnya = 'CakupanVerval'
    }

    //kalo 2021 panggil yang lama kalo 2022 yang baru
    if (id_rt) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22rtId%22%3A%22${id_rt}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_rw) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_des) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_kecamatan) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_kabupaten) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_provinsi) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    }

    if (semester_select !== null && semester_select !== '') { urlnew += '%2C%22semester%22%3A%22' + semester_select.value + '%22' }
    if (sasaran_select !== null && sasaran_select !== '') { urlnew += sasaran_select.value }


    urlnew += '%7D'
    if ((splitUrl[4] === "STUNTING" && splitUrl[5] === "Tabel5")) {
      link.target = "_blank";
      link.download = "Tabulasi.zip"
      Tabulasi
        .get(urlnew, {
          responseType: "blob",
        })
        .then((res) => {
          link.href = URL.createObjectURL(
            new Blob([res.data], { type: "blob" })
          );
          link.click();
        });
    } else {
      link.target = "_blank";
      link.download = "Tabulasi.xlsx"
      Tabulasi
        .get(urlnew, {
          responseType: "blob",
        })
        .then((res) => {
          link.href = URL.createObjectURL(
            new Blob([res.data], { type: "blob" })
          );
          link.click();
        });
    }
  }

  handleSearch = () => {
    const pathname = window.location.href;
    var splitUrl = pathname.split('/');

    if (this.state.periode_select === '') {
      Swal.fire(
        'Peringatan !',
        'Mohon Pilih Periode',
        'warning'
      )
      return false;
    }

    if (
      this.state.semester_select === null && 
      (this.state.periode_select.value === '2023' || this.state.periode_select.value === '2024') &&
      (splitUrl[4] === "STUNTING" || splitUrl[4] === "CakupanVerval")
    ) {
      Swal.fire(
        'Peringatan !',
        'Mohon Pilih Semester',
        'warning'
      );
      return false;
    }
    

    const rest = JSON.parse(localStorage.getItem('authority'))
    if (rest === null) {
      // console.log('Pake OTP')
      let valuenya = {
        'userName': sessionStorage.getItem('nama'),
        'flag': 2,
        'instansi': sessionStorage.getItem('instansi'),
        'noHp': sessionStorage.getItem('email'),
        'email': sessionStorage.getItem('email'),
        'namaReport': splitUrl[4],
        'reportCategory': splitUrl[5],
      }
      APITabulasiHistory.post(`portalpk/monitoring/insertHistoryActivity`,
        { 'histParam': valuenya })
        .then((res) => {
          // console.log(res)
        })
        .catch((error) => {
          this.setState({ blocking: false, disButtonSave: false });
          console.log(error, "dataaa ERROR");
        });
    } else {
      let valuenya = {
        'userName': localStorage.getItem('username'),
        'flag': 1,
        'instansi': '',
        'noHp': localStorage.getItem('noTelepon'),
        'email': localStorage.getItem('username'),
        'namaReport': splitUrl[4],
        'reportCategory': splitUrl[5],
      }
      APITabulasiHistory.post(`portalpk/monitoring/insertHistoryActivity`,
        { 'histParam': valuenya })
        .then((res) => {
          // console.log(res)
        })
        .catch((error) => {
          this.setState({ blocking: false, disButtonSave: false });
          console.log(error, "dataaa ERROR");
        });
    }

    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt, periode_select, semester_select, sasaran_select } = this.state;
    this.setLabel()
    let urlnew = ''
    let namaReportnya = splitUrl[4]
    if (this.state.periode_select?.value === '2021') {
      namaReportnya = splitUrl[4]
    } else if (this.state.periode_select?.value === '2022') {
      namaReportnya = splitUrl[4] + '2022'
    } else if (this.state.periode_select?.value === '2023') {
      namaReportnya = splitUrl[4] + '2023'
    } else {
      namaReportnya = splitUrl[4] + '2024'
    }

    console.log(splitUrl)

    if (this.state.periode_select?.value === '2021' && splitUrl[4] === 'IPK') {
      namaReportnya = 'IPKPADANAN'
    }

    if (splitUrl[4] === "STUNTING" && splitUrl[5] === "Tabel5") {
      namaReportnya = 'STUNTING2023'
    }

    if (splitUrl[4] === "CakupanVerval") {
      namaReportnya = 'CakupanVerval'
    }

    //kalo 2021 panggil yang lama kalo 2022 yang baru
    if (id_rt) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22rtId%22%3A%22${id_rt}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22%`;
    } else if (id_rw) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22rwId%22%3A%22${id_rw}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_des) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22kelurahanId%22%3A%22${id_des}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_kecamatan) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22kecamatanId%22%3A%22${id_kecamatan}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_kabupaten) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22kabupatenId%22%3A%22${id_kabupaten}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else if (id_provinsi) {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22provinsiId%22%3A%22${id_provinsi}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    } else {
      urlnew = `https://pk23-portal-gw.bkkbn.go.id/report/viewReport?filterPencarian=%7B%22tahun%22%3A%22${periode_select?.value}%22%2C%22reportName%22%3A%22${splitUrl[5]}%22%2C%22reportType%22%3A%22html%22%2C%22reportCategory%22%3A%22${namaReportnya}%22`;
    }

    if (semester_select !== null && semester_select !== '') { urlnew += '%2C%22semester%22%3A%22' + semester_select.value + '%22' }
    if (sasaran_select !== null && sasaran_select !== '') { urlnew += sasaran_select.value }

    urlnew += '%7D'
    console.log(periode_select)
    if (periode_select === null) {
      Swal.fire({
        title: 'Info!',
        icon: 'info',
        text: 'Diperlukan Pilihan Periode',
        showConfirmButton: false,
        timer: 1500
      })
      this.setState({ blocking: false });
    } else {
      if (splitUrl[4] === "STUNTING" && splitUrl[5] === "Tabel5" && id_kecamatan === 0 && periode_select?.value !== '2022') {
        Swal.fire({
          title: 'Info!',
          icon: 'info',
          text: 'Hanya Untuk Table 5, Diperlukan Pencarian Minimal Kecamatan',
          showConfirmButton: false,
          timer: 1500
        })
        this.setState({ blocking: false });
      } else {
        if (this.state.phone_auth !== null || this.state.isLogin == false) {
          this.setState({
            urlLaporan: urlnew,
            htmlLaporan: ''
          })


          Tabulasi.get(urlnew)
            .then((res) => {
              this.setState({ blocking: false });
              if (res.status === 200) {
                this.setState({
                  htmlLaporan: res.data
                })
              }
            })
            .catch((error) => {
              this.setState({ blocking: false });
              Swal.fire(
                'Peringatan !',
                error.message,
                'error'
              )
              // console.log(error, "DATA ERROR");
            });
        } else {
          let reportCat = splitUrl[5]

          console.log('nama report', namaReportnya);
          console.log('report category', reportCat);
          // console.log('id kec', id_kecamatan);
          if (namaReportnya === 'STUNTING2022' || namaReportnya === 'STUNTING') {
            if (reportCat === 'Tabel4') {
              // if (rest[0].attributes.user.TingkatWilayahID === 0 ) {

              //   Swal.fire({
              //     title: 'Info!',
              //     icon: 'info',
              //     text: 'Filter wilayah harus dipilih minimal Provinsi',
              //     showConfirmButton: true
              //   })
              //   return false;
              // } else if(rest[0].attributes.user.TingkatWilayahID !== 0 ){
              //   Swal.fire({
              //     title: 'Info!',
              //     icon: 'info',
              //     text: 'Filter wilayah harus dipilih minimal Provinsi',
              //     showConfirmButton: true
              //   })
              //   return false;

              // } else {
              this.setState({
                urlLaporan: urlnew,
                htmlLaporan: ''
              })
              Tabulasi.get(urlnew)
                .then((res) => {
                  this.setState({ blocking: false });
                  if (res.status === 200) {
                    this.setState({
                      htmlLaporan: res.data
                    })
                  }
                })
                .catch((error) => {
                  this.setState({ blocking: false });
                  Swal.fire(
                    'Peringatan !',
                    error.message,
                    'error'
                  )
                  // console.log(error, "DATA ERROR");
                });
              // }

            } else {

              // console.log(rest[0].attributes.user.TingkatWilayahID);
              // console.log(rest[0].attributes.user.TingkatWilayahID !== 0 && id_kecamatan === 0);
              if (rest[0].attributes.user.TingkatWilayahID === 0 && id_kecamatan === 0) {

                Swal.fire({
                  title: 'Info!',
                  icon: 'info',
                  text: 'Filter wilayah harus dipilih minimal Kecamatan',
                  showConfirmButton: true
                })
                return false;
              } else if (rest[0].attributes.user.TingkatWilayahID !== 0 && id_kecamatan === 0) {
                Swal.fire({
                  title: 'Info!',
                  icon: 'info',
                  text: 'Filter wilayah harus dipilih minimal Kecamatan',
                  showConfirmButton: true
                })
                return false;

              } else {
                this.setState({
                  urlLaporan: urlnew,
                  htmlLaporan: ''
                })
                Tabulasi.get(urlnew)
                  .then((res) => {
                    this.setState({ blocking: false });
                    if (res.status === 200) {
                      this.setState({
                        htmlLaporan: res.data
                      })
                    }
                  })
                  .catch((error) => {
                    this.setState({ blocking: false });
                    Swal.fire(
                      'Peringatan !',
                      error.message,
                      'error'
                    )
                    // console.log(error, "DATA ERROR");
                  });
              }
            }

          } else {

            this.setState({
              urlLaporan: urlnew,
              htmlLaporan: ''
            })
            Tabulasi.get(urlnew)
              .then((res) => {
                this.setState({ blocking: false });
                if (res.status === 200) {
                  this.setState({
                    htmlLaporan: res.data
                  })
                }
              })
              .catch((error) => {
                this.setState({ blocking: false });
                Swal.fire(
                  'Peringatan !',
                  error.message,
                  'error'
                )
                // console.log(error, "DATA ERROR");
              });
          }
        }
      }
    }
  }

  resizeFrame(object) {
    object.style.height = object.contentWindow.document.documentElement.scrollHeight + 'px';
  }
  render() {
    const mystyle = {
      // position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",
      border: "none"
    };

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    const pathname = window.location.href
    let splitUrl = pathname.split('/')
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>

          <div className='title-content'>

            {splitUrl[3] === "tabulasi" ?
              <Link to='/tabulasi' className='btnBackTab' >Kembali</Link> :
              <Link to='/laporan-tabulasi' className='btnBackTab' >Kembali</Link>
            }

          </div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select options={this.state.periode} isClearable onChange={this.sel_periode} value={this.state.periode_select} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        {(this.state.phone_auth !== null || this.state.isLogin == false) ? <div></div> : <>
                          <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: '100%' }} size="small">
                              Kecamatan
                              <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: '100%' }} size="small">
                              Kelurahan
                              <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: '100%' }} size="small">
                              RW
                              <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                          </Grid>
                          {/* <Grid item xs={12} md={4}>
                        <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                            </FormControl>
                        </Grid> */}
                        </>}
                        {(this.state.periode_select?.value === '2023' && splitUrl[4] === "STUNTING") || (this.state.periode_select?.value === '2024' && splitUrl[4] === "STUNTING") || splitUrl[4] === 'CakupanVerval' ?
                          <>
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ width: '100%' }} size="small">
                                Semester
                                <Select options={this.state.semester} isClearable onChange={this.sel_semester} value={this.state.name_semester} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                              </FormControl>
                            </Grid>
                          </>
                          :

                          <></>}
                        {(splitUrl[5] === "Tabel3" && splitUrl[4] === "STUNTING") || (splitUrl[5] === "Tabel5" && splitUrl[4] === "STUNTING") ?
                          <>
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ width: '100%' }} size="small">
                                Kelompok Sasaran
                                <Select options={this.state.sasaran} isClearable onChange={this.sel_sasaran} value={this.state.name_sasaran} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                              </FormControl>
                            </Grid>
                          </> : <></>}
                        <Grid item xs={12} md={4}>
                          <Button disabled={this.state.disbuttonNow} onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Button disabled={this.state.disbuttonNow} onClick={(e) => this.handleClickCetak('pdf')} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', backgroundColor: 'green', marginRight: '5px', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImFilePdf className='icon-brd' style={{ marginRight: '5px' }} /> Cetak PDF</Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Button disabled={this.state.disbuttonNow} onClick={(e) => this.handleClickCetak('excel')} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', backgroundColor: 'green', marginRight: '5px', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImFileExcel className='icon-brd' style={{ marginRight: '5px' }} /> Cetak Excel</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '5px' }} variant="contained">Cari</Button>
                        </Grid>
                        </Grid>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Tabulasi {this.state.title}</h3>
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: this.state.htmlLaporan }}
              />
            </div>


            <div>
              <Dialog open={this.state.openModal} onClose={this.handleCloseModal} maxWidth>
                <DialogTitle>Mohon isi formulir berikut</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  </DialogContentText>
                  <div >
                    <div style={{ border: '1px solid #ececec', padding: '2rem', borderRadius: '4px', textAlign: 'center', boxShadow: `2px 2px 2px 2px gray` }}>
                      <Grid
                        container rowSpacing={3}
                        columnSpacing={{
                          xs: 1, sm: 2, md: 3
                        }}>
                        <Grid
                          item
                          xs={5}
                          style={{
                            display: 'inline-block',
                            textAlign: 'left'
                          }}>
                          <div>Nama</div>
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            required
                            name="nama"
                            variant="outlined"
                            value={this.state.nama}
                            style={{
                              width: '100%'
                            }}
                            size="small"
                            onChange={this.handleChangeForm} />
                        </Grid>
                      </Grid>
                      <p></p>

                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{
                          xs: 1,
                          sm: 2,
                          md: 3
                        }}>
                        <Grid
                          item
                          xs={5}
                          style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            // paddingTop:'inherit',
                            wordWrap: 'break-word'
                          }}>
                          <div>
                            Instansi/Lembaga/Umum/Universitas
                          </div>
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            required
                            name="instansi"
                            variant="outlined"
                            value={this.state.instansi}
                            style={{
                              width: '100%'
                            }}
                            size="small"
                            onChange={this.handleChangeForm} />
                        </Grid>




                      </Grid>
                      <p></p>
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{
                          xs: 1,
                          sm: 2,
                          md: 3
                        }}>
                        <Grid
                          item
                          xs={5}
                          style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            // paddingTop:'inherit',
                            wordWrap: 'break-word'
                          }}>
                          <div>
                            Catatan
                          </div>
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            required
                            name="catatan"
                            variant="outlined"
                            value={this.state.catatan}
                            style={{
                              width: '100%'
                            }}
                            size="small"
                            onChange={this.handleChangeForm} />
                        </Grid>




                      </Grid>

                      <Grid item xs={12} style={{ 'justifyContent': 'flex-end', 'display': 'flex' }}>
                        <Button disabled={this.state.disButton} onClick={this.handleSubmitProfil} sx={{ marginRight: '5px' }} variant="contained"><ImFloppyDisk className='icon-brd' style={{ marginRight: '5px' }} /> Simpan</Button>
                        &nbsp;
                        <Button onClick={this.handleCloseModal} sx={{ marginRight: '5px' }} color='error' variant="contained"><ImCross className='icon-brd' style={{ marginRight: '5px' }} /> Batal</Button>
                      </Grid>
                    </div>
                  </div>

                </DialogContent>
              </Dialog>
            </div>
          </div>
          <div>
          </div>
        </div>
      </BlockUi>
    )
  }
}
export default TabulasiDetail;