import React, { Component } from 'react'
import { ImPlay3 } from 'react-icons/im';

class Implikasi extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <div className='content'>
        <div className='title-content'><ImPlay3 className='icon-brd' />Laporan Implikasi Pemutakhiran Wilayah Terhadap Data PK</div>
        <div className='body-content'>
        </div>
      </div>
    )
  }
}

export default Implikasi;