import React, { Component } from 'react'
import { ImPlay3, ImUserPlus, ImSearch, ImPhone, ImLocation, ImFloppyDisk, ImCross, ImPrinter } from 'react-icons/im';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdAlternateEmail, MdPassword } from 'react-icons/md';
import { FcProcess } from "react-icons/fc";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { BsPersonCheckFill, BsPersonCheck } from "react-icons/bs";
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Grid, Accordion, MenuItem, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import TablePendataan from '../../../commons/table/tablePendataan';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import APIReport from '../../../services/APIReport';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import download from 'downloadjs'
import 'react-block-ui/style.css';

const isNumber = (str) => /^[0-9]*$/.test(str);

class Target extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultData: [{ 'id': '1', 'nama_kelurahan': 'BANJARAN', 'dibayar': '14353', 'pajak': '13232', 'diterima': '13223', 'targetKK': '133' }],
            roleId: 0,
            detailTarget: {
                'belumTerdata': 0,
                'idKecamatan': 0,
                'jumlahData': 0,
                'targetKK': 0,
                'terdata': 0,
                'maksTargetKK': 0
            },
            persen: 0,
            // datas: [{'id' : '1','nama_kecamatan' : 'BANJARAN','dibayar' : '14353','pajak' : '13232','diterima' : '13223' }],
            disKelAction: true,
            statusLockKel: false,
            disBtn: false,
            disMax: false,

            //Wilayah
            dt_prov: [], provinsi: null, id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: null, id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: null, id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: null, id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: null, id_rw: 0, dis_rw: true,
            dt_rt: [], rt: null, rtID: '', id_rt: 0, dis_rt: true,
            sdm: [
                { value: '1', label: 'Supervisor' },
                // { value: '2', label: 'Manager Data' },
                { value: '3', label: 'Operator' },
                { value: '4', label: 'Pendata' },
                { value: '5', label: 'Kader Formulir' },
            ],
            sdmValue: '',
            dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
            priode: [{ value: '2024', label: '2024' }],
            value_priode: 2024,
        }
    }

    sel_priode = (e) => {
        // this.setState({priode: [], value_priode: null})
        if (e) {
            this.setState({
                priode: e, value_priode: e.value
            }, () => {
                const rest = JSON.parse(localStorage.getItem("authority"));
                this.setState({
                    roleUser: rest[0].attributes.role
                })
                let role = rest[0].attributes.user.roleID
                if (role === 1 || role === 99) {
                    this.setState({
                        dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                        dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                        dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                        dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                        dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                        dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
                    })
                }
                if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
                    const nm = rest[0].attributes.user.TingkatWilayahID
                    if (+nm === 0) {
                        this.setState({
                            flagSearch: false,
                            roleUser: rest[0].attributes.role
                        },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                } else {
                    this.setProv(null);
                }
            })


        }
        else {
            this.setState({
                priode: null, value_priode: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    componentDidMount = () => {
        const rest = JSON.parse(localStorage.getItem("authority"));
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
            const nm = rest[0].attributes.user.TingkatWilayahID
            const ri = +rest[0].attributes.user.roleID;
            let x = false
            if (+ri === 3 || +ri === 4 || +ri === 9) {
                x = true
            }
            this.setState({ roleId: ri, disMax: x },
                () => {
                    if (+nm === 0) {
                        this.setState({ flagSearch: false },
                            () => {
                                this.setProv(null)
                            }
                        )
                    } else {
                        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                        //--prepare by level
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setState({ dis_prov: true, auto_prov: true })
                        }

                        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                            this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                            this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                            this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                        }
                        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                            this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                        }
                    }
                }
            )

        } else {
            this.setProv(null);
        }
    }

    //API Wilayah
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData },
                        () => {
                            if (id !== null && this.state.auto_prov === true) {
                                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                                this.sel_prov(vl[0])
                            }
                        }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
                            // console.log(vl, 'vale kab')
                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'nama': data.nama_kecamatan };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
                            // console.log(vl, 'vale kec')
                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    // setDes(idKec, idDes) {
    //     this.setState({ blocking: true });
    //     this.setState({ dt_des: [] });
    //     var newData = [];
    //     APIWilayah.get('pkportal/targetkk/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec)
    //         .then(res => {
    //             res.data.forEach(data => {
    //                 const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'target': data.targetKK };
    //                 newData.push(obj);
    //             });
    //             this.setState({ dt_des: newData, blocking: false, defaultData: res.data },
    //                 () => {
    //                     APIWilayah.get('pkportal/targetkk/detilTargetKecamatan?idKecamatan=' + idKec)
    //                         .then(res => {
    //                             if (res.status === 200 && Object.keys(res.data).length > 0) {
    //                                 this.setState({ blocking: false, detailTarget: res.data },
    //                                     () => {
    //                                         let x = 0
    //                                         if (this.state.detailTarget.terdata !== 0) {
    //                                             (Number.isInteger(100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData))) ?
    //                                                 x = 100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData) :
    //                                                 x = (100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData)).toFixed(2)
    //                                         }
    //                                         this.setState({ persen: x })
    //                                     }
    //                                 );
    //                             } else {
    //                                 this.setState({ blocking: false });
    //                             }
    //                         }).catch((error) => {
    //                             this.setState({ blocking: false });
    //                         });
    //                 }
    //             );
    //         }).catch((error) => {
    //             this.setState({ blocking: false });
    //         });
    // }
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
                            // console.log(vl, 'vale des')
                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
            },
                () => {
                    if (this.state.id_provinsi && this.state.auto_kab === false) {
                        // console.log('masuk false')
                        this.setState({
                            dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
                            rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKab(this.state.id_provinsi, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di prov')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                defaultData: []
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di kab')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                defaultData: []
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    getStatusLock = () => {
        APIWilayah.get('/pkportal/targetkk/detilTargetKecamatan?idKecamatan=' + this.state.id_kecamatan)
            .then(res => {
                console.log('res', res)
                this.setState({ statusLockKel: res.data.statusLock },
                    () => this.setState({ disBtn: this.state.statusLockKel === true ? true : false }, () => console.log('statuslock', this.state.statusLockKel)))

                //console.log('statuslock', this.state.statusLockKel)

            }).catch((error) => {
                this.setState({ blocking: false })
                console.log(error, 'error')
            })

    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        }, () => this.getStatusLock())

                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di kec')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }

                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
                defaultData: []

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }


    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // console.log(this.state.flagNumber, 'flag -1 di des')
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_sdm = (e) => {
        this.setState({
            sdmValue: e.value
        })
    }

    handleFocus = (event) => event.target.select();

    runUpdateData = () => {
        this.setState({ blocking: true })
        APIWilayah.post('pkportal/targetkk/upsertTargetKkKecamatan?idKecamatan=' + this.state.id_kecamatan + '&periodeSensus=2022' + '&targetKK=' + this.state.detailTarget.maksTargetKK + '&username=' + localStorage.getItem('username'))
            .then(res => {
                if (res.status === 200 && res.data.AddMaksTargetKK === 'Gagal, input harus lebih besar dari jumlah TargetKK') {
                    this.setState({ blocking: false });
                    Swal.fire({
                        title: 'Gagal',
                        icon: 'error',
                        text: 'Gagal, input harus lebih besar dari jumlah TargetKK',
                    });
                } else if (res.status === 200 && res.data.AddMaksTargetKK !== "Gagal, input harus lebih besar dari jumlah TargetKK") {
                    this.setState({ blocking: false },
                        () => {
                            Swal.fire({
                                title: 'Success',
                                icon: 'success',
                                text: 'Data berhasil disimpan',
                            });
                        }
                    );
                } else {
                    this.setState({ blocking: false });
                }
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    handleChangeInput = (e) => {
        const val = Number(e.target.value)
        if (isNumber(val)) {
            this.setState({ detailTarget: { ...this.state.detailTarget, maksTargetKK: val } })
        }
    }

    keyPress = (e) => {
        if (e.keyCode == 13) {
            if (isNumber(e.target.value)) {
                const val = Number(e.target.value)
                this.setState({ detailTarget: { ...this.state.detailTarget, maksTargetKK: val } },
                    () => {
                        e.target.blur();
                    }
                )
            }
        }
    }

    updateHeader = () => {
        this.setState({ blocking: true })
        APIWilayah.get('pkportal/targetkk/detilTargetKecamatan?idKecamatan=' + this.state.kecamatan.value)
            .then(res => {
                if (res.status === 200 && Object.keys(res.data).length > 0) {
                    this.setState({ blocking: false, detailTarget: res.data },
                        () => {
                            let x = 0
                            if (this.state.detailTarget.terdata !== 0) {
                                (Number.isInteger(100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData))) ?
                                    x = 100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData) :
                                    x = (100 * (this.state.detailTarget.terdata / this.state.detailTarget.jumlahData)).toFixed(2)
                            }
                            this.setState({ persen: x })
                        }
                    );
                } else {
                    this.setState({ blocking: false });
                }
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    handleCetak = (e) => {
        e.preventDefault()
        // if (this.handleValidation()) {
        this.setState({ blocking: true });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw } = this.state;

        // const addFilter = this.state.additionalFilter
        // let newFilter = []
        // addFilter.forEach(row => {
        //     if (row.filtValue && row.valkey) {
        //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
        //     }
        // })

        Swal.fire({
            title: 'Cetak Formulir',
            //text: "Anda akan mencetak formulir",
            icon: 'info',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Excel',
            confirmButtonColor: '#3085d6',
            showDenyButton: true,
            denyButtonText: 'PDF',
            denyButtonColor: '#4ea842',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        }).then((result) => {

            let url = 'viewReport?filterPencarian=%7B'

            if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
            if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
            if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
            if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
            if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

            let urlCetakPdf = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
            let urlCetakExcel = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

            if (result.isConfirmed) {
                APIReport.get(urlCetakExcel, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'xlsx', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        // console.log("error ===> ", error)
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });

            } else if (result.isDenied) {
                APIReport.get(urlCetakPdf, { responseType: 'blob' })
                    .then(res => {
                        // console.log(res, 'res report')
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'pdf', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        // console.log("error ===> ", error)
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });


            } else {
                this.setState({ blocking: false });
            }
        })
    }

    render() {

        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "1",
                    value: 1,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
        };

        return (
            <div className='content'>
                <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                    <div className='title-content'><ImPlay3 className='icon-brd' />Monitoring Pembayaran</div>
                    <div className='body-content'>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                            <Grid item xs={12} md={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small" >
                                            SDM
                                            <Select options={this.state.sdm} id="prov" name="provinsi" onChange={this.sel_sdm} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Periode
                                            <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small" >
                                            Provinsi
                                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kabupaten
                                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kecamatan
                                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            Kelurahan
                                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            RW
                                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                                        <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ width: '100%' }}>
                                    {/* {this.state.sdmValue === '1' ?
                                        <>
                                            <h4>Operator</h4>
                                            <BootstrapTable
                                                data={this.state.defaultData}
                                                pagination={true}
                                                options={options}
                                                search={true}
                                            >
                                                <TableHeaderColumn width='120px' dataField='id' isKey={true} columnClassName={this.columnClassFormat} headerAlign='center' dataAlign='center' expandable={false}>Kode kelurahan</TableHeaderColumn>
                                                <TableHeaderColumn dataField='nama_kelurahan' headerAlign='center' expandable={false}>Nama kelurahan</TableHeaderColumn>
                                                <TableHeaderColumn dataField='dibayar' headerAlign='center' expandable={false}>Jumlah Yang Dibayarkan</TableHeaderColumn>
                                                <TableHeaderColumn dataField='pajak' headerAlign='center' expandable={false}>Pajak</TableHeaderColumn>
                                                <TableHeaderColumn dataField='diterima' headerAlign='center' expandable={false}>Jumlah Yang Diterima</TableHeaderColumn>
                                            </BootstrapTable>
                                            <div className="sizePerPage">
                                                Size Per Page
                                                <select
                                                    name="category"
                                                    value={this.state.sizePerPage}
                                                    onChange={this.handleChangeSizePerPage}
                                                >
                                                    <option value="1">1</option>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value={this.state.datas?.length}>All</option>
                                                </select>
                                            </div>
                                            <div className="totalData">
                                                <b>Total Data: {this.state.datas?.length}</b>
                                            </div>
                                        </>
                                        :
                                        <> */}
                                    <TablePendataan
                                        defaultData={this.state.defaultData}
                                        roleId={this.state.roleId}
                                        kecamatan={this.state.kecamatan}
                                        updateHeader={this.updateHeader}
                                        disKelAction={this.state.disKelAction}
                                        statusLockKel={this.state.statusLockKel}
                                        disBtn={this.state.disBtn}
                                    />
                                    {/* </>
                                    } */}

                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </BlockUi>
            </div>
        )
    }
}

export default Target;