import React, { Component } from 'react'
import { Select as MuiSelect, Box, Grid, InputLabel, Accordion, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography } from '@mui/material';
// import TextareaAutosize from '@mui/base/TextareaAutosize';

import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIPemutakhiranMonitoring from "../../../services/APIPemutakhiranMonitoring";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock, AiOutlineVerticalAlignBottom } from "react-icons/ai";
import { ImSearch, ImBin, ImCross, ImFloppyDisk, ImLoop2, ImMap, ImMap2, ImPlay3, ImPlus } from 'react-icons/im';
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import APIDashboard from "../../../services/APIDashboard";
import APIReport from '../../../services/APIReport';
import CommonTable from '../../../commons/table/Table';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { FileUploader } from "react-drag-drop-files";
import APIPemutakhiran from '../../../services/APIPemutakhiran';
import APIMonitoringBackend from '../../../services/APIMonitoringBackend';
import APIUpload from '../../../services/APIUpload';

const fileTypes = ["PDF", "DOCX", "JPG", "PNG"];
const isNumber = (str) => /^[0-9]*$/.test(str);
class laporanPenangganan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panel1: true,
            sizePerPage: 10,
            title: '',
            title2: '',
            blocking: false,
            tableHead: [],
            text11: '',
            text21: '',
            openDialog: false,
            datas: [],
            no: "",
            wilayah: [],
            namaKelurahan: [],
            metodePendataan: [],
            smartphone: 0,
            role: JSON.parse(localStorage.getItem('authority'))[0].attributes.role,
            sdm: [
                { value: 'Supervisor', label: 'Supervisor' },
                { value: 'Operator', label: 'Operator' },
            ],
            dt_status: [{ value: 1, label: 'Berhasil', name: 'Berhasil' }, { value: 2, label: 'Berhasil Sebagian', name: 'Berhasil Sebagian' }, { value: 3, label: 'Tidak Berhasil', name: 'Tidak Berhasil' }],
            dt_sdm: [{ value: 1, label: 'Pendata', name: 'Pendata' }, { value: 2, label: 'Kader Formulir', name: 'Kader Formulir' }, { value: 3, label: 'Operator', name: 'Operator' }, { value: 4, label: 'Supervisor', name: 'Supervisor' }, { value: 5, label: 'Manager Data', name: 'Manager Data' }, { value: 6, label: 'Manager Pengelola', name: 'Manager Pengelola' }],
            dt_jenis: [
                { value: 1, label: 'Pencarian Data PK Hilang, keterangan https://bkkbn-pk21.blogspot.com/2021/05/penanganan-data-hilang.html', name: 'Pencarian Data PK Hilang, keterangan https://bkkbn-pk21.blogspot.com/2021/05/penanganan-data-hilang.html' },
                { value: 2, label: 'Peng-active-an / Penon-active-an Pendata', name: 'Peng-active-an / Penon-active-an Pendata' },
                { value: 3, label: 'Penambahan User Operator', name: 'Penambahan User Operator' },
                { value: 4, label: 'Penggantian Pendata (Pengalihan/Mutasi Data PK)', name: 'Penggantian Pendata (Pengalihan/Mutasi Data PK)' },
                { value: 5, label: 'Perubahan Penugasan RT (Pengalihan/Mutasi Data PK)', name: 'Perubahan Penugasan RT (Pengalihan/Mutasi Data PK)' },
                { value: 6, label: 'Pemutakhiran Wilayah RT (Pengalihan/Mutasi Data PK)', name: 'Pemutakhiran Wilayah RT (Pengalihan/Mutasi Data PK)' },
                { value: 7, label: 'Pengembalian Data PK Delete', name: 'Pengembalian Data PK Delete' },
                { value: 8, label: 'Other', name: 'Other' }
            ],
            dt_hp: [
                { value: 1, label: 'HP SAMSUNG & BROWSER SAMSUNG', name: 'HP SAMSUNG & BROWSER SAMSUNG' },
                { value: 2, label: 'HP SAMSUNG & BROWSER CHROME', name: 'HP SAMSUNG & BROWSER CHROME' },
                { value: 3, label: 'HP SAMSUNG & BROWSER LAIN', name: 'HP SAMSUNG & BROWSER LAIN' },
                { value: 1, label: 'HP VIVO & BROWSER SAMSUNG', name: 'HP VIVO & BROWSER SAMSUNG' },
                { value: 2, label: 'HP VIVO & BROWSER CHROME', name: 'HP VIVO & BROWSER CHROME' },
                { value: 3, label: 'HP VIVO & BROWSER LAIN', name: 'HP VIVO & BROWSER LAIN' },
                { value: 1, label: 'HP OPPO & BROWSER SAMSUNG', name: 'HP OPPO & BROWSER SAMSUNG' },
                { value: 2, label: 'HP OPPO & BROWSER CHROME', name: 'HP OPPO & BROWSER CHROME' },
                { value: 3, label: 'HP OPPO & BROWSER LAIN', name: 'HP OPPO & BROWSER LAIN' },
                { value: 1, label: 'HP XIAOMI & BROWSER SAMSUNG', name: 'HP XIAOMI & BROWSER SAMSUNG' },
                { value: 2, label: 'HP XIAOMI & BROWSER CHROME', name: 'HP XIAOMI & BROWSER CHROME' },
                { value: 3, label: 'HP XIAOMI & BROWSER LAIN', name: 'HP XIAOMI & BROWSER LAIN' },
                { value: 1, label: 'HP IPHONE & BROWSER SAMSUNG', name: 'HP IPHONE & BROWSER SAMSUNG' },
                { value: 2, label: 'HP IPHONE & BROWSER CHROME', name: 'HP IPHONE & BROWSER CHROME' },
                { value: 3, label: 'HP IPHONE & BROWSER LAIN', name: 'HP IPHONE & BROWSER LAIN' },
            ],
            filterSDM: [],
            sdmValue: '',
            jenisValue: '',
            filterjenis: [],
            hpValue: '',
            filterhp: [],
            statusValue: '',
            filterstatus: [],
            Other: '',
            ketMasalah: '',
            username: '',
            ketPenanganan: '',
            hasilPenanganan: '',
            kesimpulan: '',
            Nama_PIC: '',
            tanggal: '',

            disjenis: true,

            //Wilayah
            dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
            dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,
            selectedRows: [], rowDipilih: [],

            onSubmit: false,
        }
    }

    handleChangePanel1 = (e) => {
        e.preventDefault();
        this.setState({ panel1: !this.state.panel1 })
    }

    handleOpenClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openDialog: !this.state.openDialog },
            () => { if (!this.state.openDialog) { this.clearForm() } }
        )
    }

    clearForm = () => {
        this.setState({})
    }

    setHeaderTable = () => {
        //     var headerColumns = [
        //       // { width: "50", row: '0', rowSpan: '2', title: 'NO', dataField: "no", headerAlign: 'center', dataAlign: 'center', editable: false },

        //     //   // headerColumnsProv
        //     //   { width: "300", row: '0', colSpan: '2', title: 'PROVINSI', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
        //     //   { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriProv', headerAlign: 'center', dataAlign: 'center', editable: false },
        //     //   { width: "120", row: '1', title: 'NAMA', dataField: 'nama_prov', headerAlign: 'center', dataAlign: 'left', editable: false },

        //     //   // headerColumnsKab
        //     //   { width: "300", row: '0', colSpan: '2', title: 'KABUPATEN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
        //     //   { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKab', headerAlign: 'center', dataAlign: 'center', editable: false },
        //     //   { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kab', headerAlign: 'center', dataAlign: 'left', editable: false },

        //     //   // headerColumnsKec
        //     //   { width: "300", row: '0', colSpan: '2', title: 'KECAMATAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
        //     //   { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKec', headerAlign: 'center', dataAlign: 'center', editable: false },
        //     //   { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kec', headerAlign: 'center', dataAlign: 'left', editable: false },

        //     //   // headerColumnsKel
        //     //   { width: "300", row: '0', colSpan: '2', title: 'KELURAHAN', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
        //     //   { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriKel', headerAlign: 'center', dataAlign: 'center', editable: false },
        //     //   { width: "120", row: '1', title: 'NAMA', dataField: 'nama_kel', headerAlign: 'center', dataAlign: 'left', editable: false },

        //       // headerColumnsRW
        //       { width: "300", row: '0', colSpan: '2', title: 'RW', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
        //       { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriRW', headerAlign: 'center', dataAlign: 'center', editable: false },
        //       { width: "120", row: '1', title: 'NAMA', dataField: 'nama_RW', headerAlign: 'center', dataAlign: 'left', editable: false },

        //       // headerColumnsRT
        //       { width: "300", row: '0', colSpan: '2', title: 'RT', dataField: "#", headerAlign: 'center', dataAlign: 'center', editable: false },
        //       { width: "40", row: '1', title: 'KODE', dataField: 'kodeDepdagriRT', headerAlign: 'center', dataAlign: 'center', editable: false },
        //       { width: "120", row: '1', title: 'NAMA', dataField: 'nama_RT', headerAlign: 'center', dataAlign: 'left', editable: false },

        //       // headerMetodePendataan
        //       { width: "120", row: '0', rowSpan: '2', title: 'METODE PENDATAAN', dataField: "metodePendataan", headerAlign: 'center', dataAlign: 'center', editable: false },

        //       { width: "80", row: '0', rowSpan: '2', title: 'Action', headerAlign: 'center', dataAlign: 'center', editable: false,  },
        //     ];
        //     this.setState({ tableHead: headerColumns });
    }

    componentDidMount() {
        // this.setHeaderTable()
        const rest = JSON.parse(localStorage.getItem('authority'))
        const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
        let title2, text11, text21
        this.setProv()

    }

    //API Wilayah
    setProv = (id) => {
        this.setState({ blocking: true });
        this.setState({ dt_prov: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
            .then(res => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                        const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
                        newData.push(obj);
                    });
                    this.setState({ dt_prov: newData }
                    );
                }
                this.setState({ blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kabupaten
    setKab(idProv, idKab) {
        this.setState({ blocking: true });
        this.setState({ dt_kab: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
                    newData.push(obj);
                });
                this.setState({ dt_kab: newData, blocking: false },
                    () => {
                        if (idKab !== null && this.state.auto_kab === true) {
                            const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))

                            this.sel_kab(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get kecamatan
    setKec(idKab, idKec) {
        this.setState({ blocking: true });
        this.setState({ dt_kec: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
                    newData.push(obj);
                });
                this.setState({ dt_kec: newData, blocking: false },
                    () => {
                        if (idKec !== null && this.state.auto_kec === true) {
                            const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))

                            this.sel_kec(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get desa
    setDes(idKec, idDes) {
        this.setState({ blocking: true });
        this.setState({ dt_des: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
                    newData.push(obj);
                });
                this.setState({ dt_des: newData, blocking: false },
                    () => {
                        if (idDes !== null && this.state.auto_des === true) {
                            const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))

                            this.sel_des(vl[0])
                        }
                    }
                );
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rw
    setRW(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rw: [] });
        var newData = [];
        APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
                    newData.push(obj);
                });
                this.setState({ dt_rw: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    // get rt
    setRT(id) {
        this.setState({ blocking: true });
        this.setState({ dt_rt: [] });
        var newData = [];
        APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
            .then(res => {
                res.data.forEach(data => {
                    const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
                    newData.push(obj);
                });
                this.setState({ dt_rt: newData, blocking: false });
            }).catch((error) => {
                this.setState({ blocking: false });
            });
    }

    sel_prov = (e) => {
        if (e) {
            this.setState({
                provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
            },
            )
        }
        else {
            this.setState({
                provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kab = (e) => {
        if (e) {
            this.setState({
                kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
            },
                () => {
                    if (this.state.id_kabupaten && this.state.auto_kec === false) {
                        this.setState({
                            dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
                            dis_des: true, dis_rw: true, dis_rt: true
                        })
                        this.setKec(this.state.id_kabupaten, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
                dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
                id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_kec = (e) => {
        if (e) {
            this.setState({
                kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

            },
                () => {
                    if (this.state.id_kecamatan && this.state.auto_des === false) {
                        this.setState({
                            desa: null, rw: null,
                            rt: null, dis_des: false, dis_rw: true, dis_rt: true
                        })
                        this.setDes(this.state.id_kecamatan, null);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                })
        }
        else {
            this.setState({
                kecamatan: null, desa: null, rw: null, rt: null,
                dis_des: true, dis_rw: true, dis_rt: true,
                id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_des = (e) => {
        if (e) {
            this.setState({
                desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
            },
                () => {
                    if (this.state.id_des) {
                        this.setRW(this.state.id_des);
                    }
                    if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
                        // 
                        this.setState({ flagNumber: this.state.flagNumber - 1 })
                    }
                }
            )
        }
        else {
            this.setState({
                desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
                id_des: 0, id_rw: 0, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rw = (e) => {
        if (e) {
            this.setState({
                rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
            },
                () => {
                    if (this.state.id_rw) {
                        this.setRT(this.state.id_rw);
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                })
        }
        else {
            this.setState({
                rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_rt = (e) => {
        if (e) {
            this.setState({
                rt: e, id_rt: e.value, depdagriRt: e.kode,
            },
                () => {
                    if (e) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
        else {
            this.setState({
                rt: null, id_rt: 0,

            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    //-----end wilayah

    sel_sdm = (e) => {


        if (e) {
            let sdm = []
            e.forEach(el => {
                sdm.push(el.label)
            })
            this.setState({
                filterSDM: e, sdmValue: sdm,
            },
                () => {
                    if (e) {

                    }
                }
            )
        }
        else {
            this.setState({
                filterSDM: null, sdmValue: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_jenis = (e) => {
        this.setState({ disjenis: true, Other: '' })
        if (e) {
            this.setState({
                filterjenis: e, jenisValue: e.label,
            },
                () => {
                    if (e.label === 'Other') {
                        this.setState({
                            disjenis: false
                        })

                    }
                }
            )
        }
        else {
            this.setState({
                filterjenis: null, jenisValue: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_hp = (e) => {
        if (e) {
            this.setState({
                filterhp: e, hpValue: e.label,
            },
                () => {
                    if (e) {


                    }
                }
            )
        }
        else {
            this.setState({
                filterhp: null, hpValue: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }

    sel_status = (e) => {
        if (e) {
            this.setState({
                filterstatus: e, statusValue: e.label,
            },
                () => {
                    if (e) {


                    }
                }
            )
        }
        else {
            this.setState({
                filterstatus: null, statusValue: null
            },
                () => {
                    if (e === null) {
                        // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
                    }
                }
            )
        }
    }
    handlePageChange = (page, rowsPerPage) => {
        this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
    }

    setLabel = () => {
        let title, text1, text2
        const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
        if (id_rt !== 0) {
            title = ' di RT ' + this.state.rt.label
        } else {
            title = ''
            if (id_rw !== 0) {
                title = ' di RW ' + this.state.rw.label
                text1 = 'RT'
                text2 = 'JUMLAH KK'
            } else {
                title = ''
                if (id_des !== 0) {
                    title = ' di Kelurahan ' + this.state.desa.label
                    text1 = 'RW'
                    text2 = 'JUMLAH RT'
                } else {
                    title = ''
                    if (id_kecamatan !== 0) {
                        title = ' di Kecamatan ' + this.state.kecamatan.label
                        text1 = 'KELURAHAN'
                        text2 = 'JUMLAH RW'
                    } else {
                        title = ''
                        if (id_kabupaten !== 0) {
                            title = ' di Kabupaten ' + this.state.kabupaten.label
                            text1 = 'KECAMATAN'
                            text2 = 'JUMLAH KELURAHAN'
                        } else {
                            title = ''
                            if (id_provinsi !== 0) {
                                title = ' di Provinsi ' + this.state.provinsi.label
                                text1 = 'KABUPATEN'
                                text2 = 'JUMLAH KECAMATAN'
                            } else {
                                title = ' Tingkat Nasional'
                                text1 = 'PROVINSI'
                                text2 = 'JUMLAH KABUPATEN'
                            }
                        }
                    }
                }
            }
        }

        this.setState({ title: title, text1: text1, text2: text2 },
            () => { this.setHeaderTable() }
        )
    }


    buttonFormatter = (cell, row) => {
        return <div className='divBtnTable'>
            {row.status === 0 || row.status === 7 || row.status === 8 || row.status === 9 || row.status === 10 ?
                <Button onClick={(e) => this.handleEdit(e, row)} id={row.id} className='btnTable' variant="outlined"><BsFillArrowDownCircleFill style={{ color: 'green' }} /></Button>
                :
                <>
                    -
                </>
            }
        </div>

    }



    handleChange = (pdf) => {
        
        this.setState({
            setFile: pdf
        })
    };

    handleClear = () => {
        this.setState({
            provinsi: [],
            id_provinsi: 0,
            filterSDM: null,
            sdmValue: null,
            jenisValue: '',
            filterjenis: [],
            hpValue: '',
            filterhp: [],
            statusValue: '',
            filterstatus: [],
            Other: '',
            ketMasalah: '',
            ketPenanganan: '',
            hasilPenanganan: '',
            kesimpulan: '',
            Nama_PIC: '',
            username: '',
            tanggal: '',
        })
    }

    handleSubmit = () => {
        // 
        const dataTable = {
            // "role": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
            "pic": this.state.Nama_PIC,
            "case_date": this.state.tanggal,
            "id_propinsi": this.state.id_provinsi,
            "jenis_case": this.state.jenisValue,
            "other": this.state.Other,
            "description_case": this.state.ketMasalah,
            "status": this.state.statusValue,
            "description_handling": this.state.ketPenanganan,
            "jns_hp_browser": this.state.hpValue,
            "hasil_penanganan": this.state.hasilPenanganan,
            "kesimpulan": this.state.kesimpulan,
            "userName": this.state.username

            // "pdf" : this.state.setFile
        }


        let Data = new FormData();
        Data.append("doc", this.state.setFile);
        




        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Ingin Simpan Laporan & Penanganan?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ blocking: true, onSubmit: true })

                let Data = new FormData();
                // Data.append("idFileTypeUpload", this.state.setFile.type === 'image/png' || this.state.setFile.type === 'image/jpeg' ? '1' : '2');
                // Data.append('createdby', localStorage.getItem('username'))
                // Data.append("idWilayah", this.state.id_rt);
                // Data.append("tingkatwilayah", '6');
                // Data.append("doc", this.state.setFile);

                APIMonitoringBackend.post('pkportal/monitoring/insertProblemPK', dataTable)
                    .then((response) => {
                        if (response.status === 200) {
                            this.setState({ blocking: false });
                            this.handleClear()
                            Swal.fire({
                                title: 'Info!',
                                icon: 'success',
                                text: 'Data Berhasil Ditambah!',
                            })

                            // APIUpload.post('userpembiayaan/uploadFileUser', Data)
                            //     .then((response) => {
                            //         if (response.status === 200) {
                            //             this.setState({ blocking: false, onSubmit: true })

                            //             Swal.fire({
                            //                 title: 'Info!',
                            //                 icon: 'success',
                            //                 text: 'Data Berhasil Ditambah!',
                            //             })
                            //             this.handleOpenClose()
                            //             this.handleSearch()
                            //         }
                            //     })
                            //     .catch((error) => {
                            //         let message = error;
                            //         this.setState({ blocking: false, onSubmit: false })

                            //         Swal.fire({
                            //             title: 'Error!',
                            //             icon: 'warning',
                            //             text: 'Penyimpanan Data Gagal, Harap Diinput Kembali ',
                            //             // text: message,
                            //         })
                            //     });
                        }
                    })
                    .catch((error) => {
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });
            } else {
                this.setState({ blocking: false });
            }

        })
        // } else {
        //   Swal.fire({
        //     title: 'Info!',
        //     icon: 'warning',
        //     text: 'Data Harap di lengkapi',
        //     showConfirmButton: true
        //   })
        // }


    }


    handleCetak = (e) => {
        e.preventDefault()
        // if (this.handleValidation()) {
        this.setState({ blocking: true });
        const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw } = this.state;

        // const addFilter = this.state.additionalFilter
        // let newFilter = []
        // addFilter.forEach(row => {
        //     if (row.filtValue && row.valkey) {
        //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
        //     }
        // })

        Swal.fire({
            title: 'Cetak Formulir',
            //text: "Anda akan mencetak formulir",
            icon: 'info',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Excel',
            confirmButtonColor: '#3085d6',
            showDenyButton: true,
            denyButtonText: 'PDF',
            denyButtonColor: '#4ea842',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        }).then((result) => {

            let url = 'viewReport?filterPencarian=%7B'

            if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
            if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
            if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
            if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
            if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

            let urlCetakPdf = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
            let urlCetakExcel = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

            if (result.isConfirmed) {
                APIReport.get(urlCetakExcel, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'xlsx', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });

            } else if (result.isDenied) {
                APIReport.get(urlCetakPdf, { responseType: 'blob' })
                    .then(res => {
                        if (res.status === 200) {
                            const content = res.headers['content-type'];
                            download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'pdf', content)
                            this.setState({ blocking: false })
                        } else {
                            Swal.fire({
                                title: 'Info!',
                                icon: 'info',
                                text: 'Data tidak ditemukan.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.setState({ blocking: false });
                        }
                    }).catch((error) => {
                        let message = error;
                        Swal.fire({
                            title: 'Error!',
                            icon: 'warning',
                            // text: 'Jaringan bermasalah.',
                            text: message,
                        })
                        this.setState({ blocking: false });
                    });


            } else {
                this.setState({ blocking: false });
            }
        })
    }

    handleChangeSizePerPage = (e) => {
        let x = 1
        if (+e.target.value !== 0) {
            x = +e.target.value
        }
        this.setState({ sizePerPage: x })
    }

    handleSelectRow = (cat, row, isSelect) => {
        if (isSelect === true && cat === 'single') {
            this.setState({ selectedRows: this.state.selectedRows.concat(row) });
        } else if (isSelect === true && cat === 'all') {
            this.setState({ selectedRows: row });
        } else if (cat === 'single') {
            var array = this.state.selectedRows;
            var index = array.findIndex(el => el.id === row.id);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ selectedRows: array });
            }
        } else {
            this.setState({ selectedRows: [] });
        }


    }


    handleChangeInputOther = (e, tab) => {
        if (e) {
            this.setState({ Other: e.target.value })
        } else {
            this.setState({ Other: e.target.value.toUpperCase() })
        }
    }

    handleChangeInputketMasalah = (e, tab) => {

        if (e) {
            this.setState({ ketMasalah: e.target.value })
        } else {
            this.setState({ ketMasalah: e.target.value.toUpperCase() })
        }
    }

    handleChangeInputketPenanganan = (e, tab) => {

        if (e) {
            this.setState({ ketPenanganan: e.target.value })
        } else {
            this.setState({ ketPenanganan: e.target.value.toUpperCase() })
        }
    }

    handleChangeInputUsername = (e, tab) => {

        if (e) {
            this.setState({ username: e.target.value })
        } else {
            this.setState({ username: e.target.value.toUpperCase() })
        }
    }

    handleChangeInputhasilPenanganan = (e, tab) => {

        if (e) {
            this.setState({ hasilPenanganan: e.target.value })
        } else {
            this.setState({ hasilPenanganan: e.target.value.toUpperCase() })
        }
    }

    handleChangeInputkesimpulan = (e, tab) => {

        if (e) {
            this.setState({ kesimpulan: e.target.value })
        } else {
            this.setState({ kesimpulan: e.target.value.toUpperCase() })
        }
    }

    handleChangeInputPIC = (e, tab) => {

        if (e) {
            this.setState({ Nama_PIC: e.target.value })
        } else {
            this.setState({ Nama_PIC: e.target.value.toUpperCase() })
        }
    }

    sel_tanggal = (e) => {

        // .format('YYYY-MM-DDTHH:mm:ss.sssZ'))
        if (e) {
            this.setState({
                //tgl_sp2d: moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss.sssZ').toString()
                tanggal: e.target.value
            })
        }
    }





    render() {

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            bgColor: '#e3e8e4',
            size: '10px',

            headerColumnStyle: { backgroundColor: 'blue' },
            // marginLeft: 'auto',
            // marginRight:'auto'
            onSelect: (row, isSelect, rowIndex, e) => {
                this.handleSelectRow('single', row, isSelect)
            },
            onSelectAll: (isSelect, rows, e) => {
                // if (isSelect) {
                //     this.handleSelectRow('all', rows, isSelect)
                //     return this.state.datas.map(row => row.id)
                // } else {
                //     this.handleSelectRow('all', rows, isSelect)
                //     return [];
                // }
            }
        };
        const options = {
            page: 1,
            sizePerPageList: [
                {
                    text: "1",
                    value: 1,
                },
                {
                    text: "10",
                    value: 10,
                },
            ], // you can change the dropdown list for size per page
            sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            prePage: "<", // Previous page button text
            nextPage: ">", // Next page button text
            firstPage: "<<", // First page button text
            lastPage: ">>", // Last page button text
            hideSizePerPage: true,
            paginationShowsTotal: false, // Accept bool or function
            paginationPosition: "bottom", // default is bottom, top and both is all available
        };
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
                <div className='content'>
                    <div className='title-content'><ImPlay3 className='icon-brd' />LAPORAN & PENANGANAN PERMASALAHAN LAPANGAN PK</div>
                    <div className='body-content'>
                        <fieldset>
                            <legend>LAPORAN & PENANGANAN</legend>
                            <Box sx={{ width: '100%' }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                                            <Grid item xs={12} md={1}>
                                                <FormControl sx={{ width: '100%' }} size="small" >

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Nama PIC
                                                    <TextField
                                                        required
                                                        name="Nama_PIC"
                                                        variant="outlined"
                                                        value={this.state.Nama_PIC}
                                                        type="text"
                                                        style
                                                        ={{
                                                            width: '100%'
                                                        }}
                                                        size="small"
                                                        onChange={this.handleChangeInputPIC} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Tanggal Kejadian
                                                    <TextField name='tanggal' onChange={this.sel_tanggal} value={this.state.tanggal} inputProps={{ style: { height: "6px" } }} type="date" id="outlined-basic" isClearable />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                                            <Grid item xs={12} md={1}>
                                                <FormControl sx={{ width: '100%' }} size="small" >

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    User Name Pendata/Operator/Supervisor/Manager Data ( lebih dari 1 pisahkan dengan koma ( , ) )
                                                    <TextField
                                                        required
                                                        name="username"
                                                        variant="outlined"
                                                        value={this.state.username}
                                                        type="text"
                                                        style
                                                        ={{
                                                            width: '100%'
                                                        }}
                                                        size="small"
                                                        onChange={this.handleChangeInputUsername} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Provinsi
                                                    <Select options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                                            <Grid item xs={12} md={1}>
                                                <FormControl sx={{ width: '100%' }} size="small" >

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Jenis Permasalahan
                                                    <Select options={this.state.dt_jenis} onChange={this.sel_jenis} value={this.state.filterjenis} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Other
                                                    <TextareaAutosize
                                                        maxRows={1}
                                                        name="other"
                                                        variant="outlined"
                                                        value={this.state.Other}
                                                        disabled={this.state.disjenis}
                                                        placeholder="Other"
                                                        defaultValue=""
                                                        style={{ width: '100%', paddingBottom: '50px' }}
                                                        onChange={this.handleChangeInputOther}
                                                    />
                                                </FormControl>
                                            </Grid> */}
                                        </Grid>
                                        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                                            <Grid item xs={12} md={1}>
                                                <FormControl sx={{ width: '100%' }} size="small" >

                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Keterangan Permasalahan
                                                    <TextareaAutosize
                                                        maxRows={1}
                                                        required
                                                        name="ketMasalah"
                                                        value={this.state.ketMasalah}
                                                        onChange={this.handleChangeInputketMasalah}
                                                        placeholder="Keterangan Permasalahan"
                                                        defaultValue=""
                                                        style={{ width: '100%', paddingBottom: '50px' }}
                                                    />
                                                </FormControl>
                                            </Grid> */}
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Status Penanganan
                                                    <Select options={this.state.dt_status} onChange={this.sel_status} value={this.state.filterstatus} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                                            <Grid item xs={12} md={1}>
                                                <FormControl sx={{ width: '100%' }} size="small" >

                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Keterangan Penanganan
                                                    <TextareaAutosize
                                                        maxRows={1}
                                                        required
                                                        name="ketPenanganan"
                                                        variant="outlined"
                                                        value={this.state.ketPenanganan}
                                                        onChange={this.handleChangeInputketPenanganan}
                                                        placeholder="Keterangan Penanganan"
                                                        defaultValue=""
                                                        style={{ width: '100%', paddingBottom: '50px' }}
                                                    />
                                                </FormControl>
                                            </Grid> */}
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Jenis Handphone dan Jenis Browser
                                                    <Select options={this.state.dt_hp} onChange={this.sel_hp} value={this.state.filterhp} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                                            <Grid item xs={12} md={1}>
                                                <FormControl sx={{ width: '100%' }} size="small" >

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Hasil Penanganan Masalah
                                                    <TextareaAutosize
                                                        maxRows={1}
                                                        required
                                                        name="hasilPenanganan"
                                                        variant="outlined"
                                                        value={this.state.hasilPenanganan}
                                                        placeholder="Hasil Penanganan Masalah"
                                                        defaultValue=""
                                                        style={{ width: '100%', paddingBottom: '50px' }}
                                                        onChange={this.handleChangeInputhasilPenanganan}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    Kesimpulan Penyebab Masalah
                                                    <TextareaAutosize
                                                        maxRows={1}
                                                        required
                                                        name="kesimpulan"
                                                        variant="outlined"
                                                        value={this.state.kesimpulan}
                                                        aria-label="maximum height"
                                                        placeholder="Kesimpulan Penyebab Masalah"
                                                        defaultValue=""
                                                        style={{ width: '100%', paddingBottom: '50px' }}
                                                        onChange={this.handleChangeInputkesimpulan}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid> */}
                                        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }} style={{ marginTop: '1px' }}>
                                            <Grid item xs={12} md={1}>
                                                <FormControl sx={{ width: '100%' }} size="small" >

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl size="small">
                                                    Upload Dokumen/Gambar
                                                    <FileUploader
                                                        handleChange={this.handleChange}
                                                        name="pdf"
                                                        label="Dokumen/Gambar"
                                                        types={fileTypes}
                                                        hoverTitle="Upload File"
                                                        style={{ width: '100px' }}

                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl sx={{ width: '100%' }} size="small">
                                                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleSubmit} variant="outlined" color="primary" sx={{ marginTop: '15px' }}>Simpan</Button>
                                                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleClear} variant="outlined" color="warning" sx={{ marginTop: '15px' }}>Clear</Button>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </fieldset>


                    </div>
                </div>
            </BlockUi>
        )
    }
}

export default laporanPenangganan;