import React, { Component } from 'react'
import { Select as MuiSelect, Grid, InputLabel, Accordion, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, Button, Paper, AccordionSummary, AccordionDetails, FormControl, Typography, Box } from '@mui/material';
import Select from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import APIWilayah from '../../../services/APIWilayah';
import APIPemutakhiranMonitoring from "../../../services/APIPemutakhiranMonitoring";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock, AiOutlineVerticalAlignBottom, AiFillFilePdf } from "react-icons/ai";
import { ImSearch, ImBin, ImCross, ImFloppyDisk, ImLoop2, ImMap, ImMap2, ImPlay3, ImPlus } from 'react-icons/im';
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { BsPrinterFill, BsCheckCircleFill, BsXCircleFill, BsInfoCircle } from "react-icons/bs";
import APIDashboard from "../../../services/APIDashboard";
import APIReport from '../../../services/APIReport';
import CommonTable from '../../../commons/table/Table';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import download from 'downloadjs'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { FileUploader } from "react-drag-drop-files";
import APIPemutakhiran from '../../../services/APIPemutakhiran';
import APIUpload from '../../../services/APIUpload';
import APIVerval from '../../../services/APIVerval';


import APIMonitoring from '../../../services/APIMonitoringBackend';

const fileTypes = ["PDF", "JPG", "PNG"];
const isNumber = (str) => /^[0-9]*$/.test(str);
class DaftarPajak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }, { value: '2022', label: '2022' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
      panel1: true,
      sizePerPage: 10,
      title: '',
      deskripsi: '',
      kodeedit:'',
      bruto:0,
      pajak: 0,
      title2: '',
      blocking: false,
      tableHead: [],
      text11: '',
      text21: '',
      openDialog: false,
      didataTambah: 0,
      id_provinsirow: 0,
      id_kabupatenrow: 0,
      id_kecamatanrow: 0,
      id_rwrow: 0,
      id_kelurahanrow: 0,
      edit: null,
      datas: [],
      no: "",
      loginby: null,
      openInfo: false,
      wilayah: [],
      namaKelurahan: [],
      metodePendataan: [],
      smartphone: 0,
      role: JSON.parse(localStorage.getItem('authority'))[0].attributes.user.roleID,
      sdm: [
        { value: '4', label: 'Supervisor' },
        { value: '10', label: 'Operator' },
      ],
      filterSDM: [], selectedSDM: null,
      sdmValue: '',
      disAprrovaladmin: false,
      //Wilayah
      dt_prov: [], provinsi: [], id_provinsi: 0, dis_prov: false, depdagriProv: '', auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true,
      dt_rt: [], rt: [], rtID: '', id_rt: 0, dis_rt: true,
      selectedRows: [], rowDipilih: [],

      onSubmit: false,
      disAprrovalProv: false,
      duplicate: 0,
      susulan: 0,
      roleUserLogin: localStorage.getItem("authority") ? +JSON.parse(localStorage.getItem("authority"))[0].attributes.user.roleID : null,

    }
  }

  handleChangePanel1 = (e) => {
    e.preventDefault();
    this.setState({ panel1: !this.state.panel1 })
  }

  handleOpenClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openDialog: !this.state.openDialog },
      () => { if (!this.state.openDialog) { this.clearForm() } }
    )
  }

  clearForm = () => {
    this.setState({})
  }

  setHeaderTable = () => {

  }

  handleOpenCloseInfo = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openInfo: !this.state.openInfo })
  }

  componentDidMount() {
    // this.setHeaderTable()
    const rest = JSON.parse(localStorage.getItem('authority'))
    this.setState({ loginby: localStorage.getItem("username") })
    const idTingkatWilayah = rest[0].attributes.user.TingkatWilayahID;
    const idTingkatWilayah2 = rest[0].attributes.user.alamat_provinsi === 0 ? rest[0].attributes.user.wilProvinsi.id_provinsi : rest[0].attributes.user.alamat_provinsi;
    let created = new Date().toISOString().substring(0, 10)
    let temp = new Date("2023-06-25").toISOString().substring(0, 10)
    let title2, text11, text21

    if (idTingkatWilayah == 0) {
      title2 = ''

      this.setState({
        sendDatas: {
          "idProv": 0,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 1) {
      title2 = ' di Provinsi ' + rest[0].attributes.user.wilProvinsi.nama_provinsi

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": 0,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });

    } else if (idTingkatWilayah == 2) {
      title2 = ' di Kabupaten ' + rest[0].attributes.user.wilKabupaten.nama_kabupaten

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKabupaten.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      },
        () => { this.getBackend(this.state.sendDatas) }
      )


    } else {
      title2 = ' di Kecamatan ' + rest[0].attributes.user.wilKecamatan?.nama_kecamatan

      this.setState({
        sendDatas: {
          "idProv": rest[0].attributes.user.wilProvinsi.id_provinsi,
          "idKab": rest[0].attributes.user.wilKecamatan.id_kabupaten,
          "idKec": 0,
          "idKel": 0,
          "idRW": 0,
          "idRt": 0,
          "limit": 300
        }
      });
    }

    this.setState({ title: title2, text1: text11, text2: text21 },
      () => { this.setHeaderTable() }
    )

    if (rest === false) { this.props.history.push('/401') }
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {

      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }
        const wilProvinsi = rest[0].attributes.user.wilProvinsi
        const wilKabupaten = rest[0].attributes.user.wilKabupaten
        const wilKecamatan = rest[0].attributes.user.wilKecamatan
        const wilKelurahan = rest[0].attributes.user.wilKelurahan

        if (Object.keys(wilProvinsi).length > 0) {

          const objProp = {
            'value': wilProvinsi.id_provinsi,
            'label': wilProvinsi.id_provinsi_depdagri + ' - ' + wilProvinsi.nama_provinsi, 'kode': wilProvinsi.id_provinsi_depdagri
          };

          this.setState({ dt_prov: objProp },
            () => {
              if (this.state.auto_prov === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(objProp)
              }
            }
          );
          //this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        //        alert(wilKabupaten.length)
        if (Object.keys(wilKabupaten).length > 0) {
          //          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
          const objKab = {
            'value': wilKabupaten.id_kabupaten,
            'label': wilKabupaten.id_kabupaten_depdagri + ' - ' + wilKabupaten.nama_kabupaten, 'kode': wilKabupaten.id_kabupaten_depdagri
          };

          this.setState({ dt_kab: objKab },
            () => {
              if (this.state.auto_kab === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kab(objKab)
              }
            }
          );

        }
        if (Object.keys(wilKecamatan).length > 0) {
          const objKec = {
            'value': wilKecamatan.id_kecamatan,
            'label': wilKecamatan.id_kecamatan_depdagri + ' - ' + wilKecamatan.nama_kecamatan, 'kode': wilKecamatan.id_kecamatan_depdagri
          };

          this.setState({ dt_kec: objKec },
            () => {
              if (this.state.auto_kec === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_kec(objKec)
              }
            }
          );
          //this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(wilKelurahan).length > 0) {
          const objKel = {
            'value': wilKelurahan.id_kelurahan,
            'label': wilKelurahan.id_kelurahan_depdagri + ' - ' + wilKelurahan.nama_kelurahan, 'kode': wilKelurahan.id_kelurahan_depdagri
          };

          this.setState({ dt_kel: objKel },
            () => {
              if (this.state.auto_des === true) {
                //const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_des(objKel)
              }
            }
          );


          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri, 'namaWil': data.nama };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kabupaten };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // console.log(vl, 'vale kab')
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kecamatan };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // console.log(vl, 'vale kec')
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri, 'namaWil': data.nama_kelurahan };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              // console.log(vl, 'vale des')
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rw };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('/pkportal/location/getListRtByIdRw?id_rw=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri, 'namaWil': data.nama_rt };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // console.log('masuk false')
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true, disAprrovalProv: false, disAprrovaladmin: false
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di prov')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }

        }
      )
    }
    else {
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kab')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }

          if (this.state.id_provinsi) {
            this.setState({ blocking: true })
            APIVerval.get("/aktif/getTombolAktifByProvIdKabId?periodeSensus=" + this.state.value_priode + "&kabupatenId=" + this.state.id_kabupaten + "&provinsiId=" + this.state.id_provinsi + "&modulPembayaran=PK" + '&periodeSensus=2024')
              .then((res) => {
                console.log(res.data[0])
                this.setState({ blocking: false });
                if (res.status === 200) {
                  this.setState(
                    {
                      disAprrovalProv: res.data[0].ButtonStatus, disAprrovaladmin: res.data[0].ButtonStatus
                    }

                  )
                }

              })
              .catch((error) => {
                this.setState({ blocking: false });
                console.log(error, "dataaa ERROR");
              });
          }
        })
    }
    else {
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,

      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kec')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di des')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        rt: null, id_rt: 0,

      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  sel_sdm = (e) => {
    if (e) {
      this.setState({
        filterSDM: e, selectedSDM: e.value,
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.setState({
        filterSDM: null, selectedSDM: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handlePageChange = (page, rowsPerPage) => {
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  statusFormatter = (cell, row) => {
    let label = ''
    if (row.status === 1) {
      label = 'Pending MD'
    } else if (row.status === 2) {
      label = 'Pending MP'
    } else if (row.status === 3) {
      label = 'Complete'
    } else if (row.status === 7) {
      label = 'Reject MD'
    } else if (row.status === 8) {
      label = 'Reject MP'
    } else if (row.status === 9) {
      label = 'Reject PK'
    } else if (row.status === 10) {
      label = 'Reject PPK'
    } else if (row.status === 11) {
      label = 'Reject BPP'
    }

    return <div>{label}</div>
  }

  buttonFormatter = (cell, row) => {

    return <div className='divBtnTable'>
      <Button onClick={(e) => this.handleEdit(e, row)} id={row.id} className='btnTable' variant="outlined"><AiTwotoneEdit style={{ color: 'green' }} /></Button>
      <Button onClick={(e) => this.handleHapus(e, row)} id={row.id} className='btnTable' variant="outlined"><BsXCircleFill style={{ color: 'red' }} /></Button>
    </div>

  }

  handleHapus = (e, row) => {
    Swal.fire({
      title: 'Apakah Anda Yakin?',
      text: "Ingin Menghapus Daftar Pajak ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ blocking: true });
        console.log(row)
        APIVerval.post("/transaksi/deletePajak", row)
          .then((res) => {
            this.setState({ blocking: false, openDialog: false });
            if (res.status === 200) {
              this.handleSearch()
            }

          })
          .catch((error) => {
            this.setState({ blocking: false });
            console.log(error, "dataaa ERROR");
          });
      } else {
        this.setState({ openDialog: false , blocking: false})
      }
    })
  }

  handleCreate = (row) => {
    this.setState({
      openDialog: true,
      deskripsi: '',
      pajak: 0,
      bruto: 0,
      edit: false,

    })
  }
  handleEdit = (e, row) => {
    this.setState({
      openDialog: true,
      deskripsi: row.deskripsi,
      pajak: row.pajak,
      kodeedit: row.kode,
      bruto: row.persenBruto,
      onSubmit: false,
      edit: true,
    })
  }

  sel_priode = (e) => {
    // this.setState({priode: [], value_priode: null})
    if (e) {
      this.setState({
        priode: e, value_priode: e.value
      }, () => {

        const rest = JSON.parse(localStorage.getItem("authority"));
        this.setState({
          roleUser: rest[0].attributes.role
        })
        let role = rest[0].attributes.user.roleID
        if (role === 1 || role === 99) {
          this.setState({
            dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
            dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
            dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
            dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
            dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
            dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
          })
        }
        if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
          const nm = rest[0].attributes.user.TingkatWilayahID
          if (+nm === 0) {
            this.setState({
              flagSearch: false,
              roleUser: rest[0].attributes.role
            },
              () => {
                this.setProv(null)
              }
            )
          } else {
            if (+nm === 1) { this.setState({ flagNumber: 1 }) }
            else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
            else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
            else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
            //--prepare by level
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
            } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
            } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
            } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setState({ dis_prov: true, auto_prov: true })
            }

            if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
              this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
            }
            if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
              this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
            }
            if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
              this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
            }
            if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
              this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
            }
          }
        } else {
          this.setProv(null);
        }
      })


    }
    else {
      this.setState({
        priode: null, value_priode: null
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  handleSearch = () => {
    this.setState({ blocking: true });

    APIVerval.get("/transaksi/getPeriodePajak?periode=" + this.state.value_priode)
      .then((res) => {
        console.log(res.data)
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState(
            {
              datas: res.data
            }

          )
        }

      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });
  }

  handleChangeInput = (e, tab) => {
    if (e.target.name === 'pajak' || e.target.name === 'bruto') {
      const value = e.target.value.replace(',', '.'); // Mengganti koma dengan titik
      if (!isNaN(value)) { 
        if (parseFloat(value) > 100) {
          alert("Nilai pajak tidak boleh lebih dari 100");
        } else {
          // Mengatur nilai state jika valid
          this.setState({ [e.target.name]: value });
        }
      }
    } else {
      // Mengubah nilai menjadi huruf besar (uppercase)
      this.setState({ [e.target.name]: e.target.value.toUpperCase() });
    }
  }
  
  
  

  handleChange = (pdf) => {

    if (this.state.file_name !== '' && this.state.file_name !== null) {
      Swal.fire({
        title: 'File Sudah Pernah di Upload',
        text: "Apakah Ingin Upload Ulang?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            setFile: pdf,
            onsubmit: false
          })
        } else {
          this.setState({blocking: false})
        }

      })
    } else {
      this.setState({
        setFile: pdf,
        onsubmit: false
      })
    }

  };


  handleSubmit = () => {
    this.setState({ blocking: false, onSubmit: false })

    const dataTableedit  = [{
      "createdBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "createdDate": new Date(),
      "isActive": 1,
      "kode": this.state.kodeedit,
      "pajak": this.state.pajak,
      "lastModified": new Date(),
      "lastModifiedBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "deskripsi": this.state.deskripsi,
      "periodeSensus": this.state.value_priode,
      "persenBruto": this.state.bruto
    }]

    const dataTableCreate = [{
      "createdBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "createdDate": new Date(),
      "isActive": 1,
      
      "pajak": this.state.pajak,
      "lastModified": new Date(),
      "lastModifiedBy": JSON.parse(localStorage.getItem('authority'))[0].attributes.user.userName,
      "deskripsi": this.state.deskripsi,
      "periodeSensus": this.state.value_priode,
      "persenBruto": this.state.bruto

    }]

    Swal.fire({
      title: 'Apakah Anda Yakin?',
      text: "Dengan Data Entri Pajak ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        let urledit =  this.state.edit === true ? '/transaksi/updatePajak' : '/transaksi/inputPajak'
        let paramedit =  this.state.edit === true ? dataTableedit : dataTableCreate
        console.log(paramedit)

        APIVerval.post(urledit, paramedit)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: 'Berhasil!',
                icon: 'info',
                text: this.state.edit === true ? 'Pajak Berhasil Di Update.' : 'Pajak Berhasil Di Tambah.',
              }).then(() => {
                this.handleSearch()
                this.setState({ blocking: false, onSubmit: false, openDialog: false });

              })
            } else {
              Swal.fire({
                title: 'Gagal!!',
                icon: 'info',
                text: response,
              })
              this.setState({ blocking: false, onSubmit: false, openDialog: false });
            }
          })
          .catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false, onSubmit: false, openDialog: false });
          });
      } else {
        this.setState({ blocking: false, onSubmit: false });
      }
    })

  }


  handleCetak = (e) => {
    e.preventDefault()
    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw } = this.state;

    // const addFilter = this.state.additionalFilter
    // let newFilter = []
    // addFilter.forEach(row => {
    //     if (row.filtValue && row.valkey) {
    //         newFilter.push({ param: row.filtValue.value, value: row.valkey.value })
    //     }
    // })

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#3085d6',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#4ea842',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      let urlCetakPdf = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
      let urlCetakExcel = url + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah.' + 'pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  handleSelectRow = (cat, row, isSelect) => {
    if (isSelect === true && cat === 'single') {
      this.setState({ selectedRows: this.state.selectedRows.concat(row) });
    } else if (isSelect === true && cat === 'all') {
      this.setState({ selectedRows: row });
    } else if (cat === 'single') {
      var array = this.state.selectedRows;
      var index = array.findIndex(el => el.id === row.id);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ selectedRows: array });
      }
    } else {
      this.setState({ selectedRows: [] });
    }


  }

  handleAprrovSelect = (e, param) => {

  }

  createdFormatDuplicate = (cell, row) => {

  }

  pajakFormatter = (cell, row) => {
    return <div className='divBtnTable'>
      {cell}%
    </div>
  }

  createFormatDuplicateRow = (cell, row) => {

  }




  render() {

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: '#e3e8e4',
      size: '10px',

      headerColumnStyle: { backgroundColor: 'blue' },
      // marginLeft: 'auto',
      // marginRight:'auto'
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handleSelectRow('single', row, isSelect)
      },
      onSelectAll: (isSelect, rows, e) => {
        // if (isSelect) {
        //     this.handleSelectRow('all', rows, isSelect)
        //     return this.state.datas.map(row => row.id)
        // } else {
        //     this.handleSelectRow('all', rows, isSelect)
        //     return [];
        // }
      }
    };
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "1",
          value: 1,
        },
        {
          text: "10",
          value: 10,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "<", // Previous page button text
      nextPage: ">", // Next page button text
      firstPage: "<<", // First page button text
      lastPage: ">>", // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
    };
    return (
      <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
        <div className='content'>
          <div className='title-content'><ImPlay3 className='icon-brd' />Daftar Pajak</div>
          <div className='body-content'>
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel1} onChange={this.handleChangePanel1} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select options={this.state.dt_priode} onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: '100%', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>

            <h3>Daftar Pajak</h3>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
              <Grid item xs={12} md={12}>
                <Button onClick={(e) => this.handleCreate()} sx={{ float: 'right', minHeight: '36px', marginRight: '15px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><BsCheckCircleFill className='icon-brd' style={{ marginRight: '5px', float: 'left' }} sx={{ float: 'left' }} /> Tambah</Button>
              </Grid>
            </Grid>
            <Paper sx={{ width: "100%", boxShadow: "none" }}>

              <div className="rekap-table">
                <BootstrapTable
                  data={this.state.datas}
                  pagination={true}
                  keyField='kode'
                  options={options}
                  search={true}
                // selectRow= { selectRow }
                >
                  <TableHeaderColumn width='100' dataField='kode' headerAlign='center' dataAlign='center' >KODE</TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="deskripsi" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate}  >DESKRIPSI </TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="persenBruto" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} dataFormat={this.pajakFormatter}  >% PERSEN PENGHASILAN BRUTO </TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="pajak" headerAlign="center" dataAlign="center" columnClassName={this.createdFormatDuplicate} dataFormat={this.pajakFormatter}  >% PAJAK </TableHeaderColumn>
                  <TableHeaderColumn width="80" dataField="action" headerAlign="center" dataAlign="center" columnClassName={this.createdFormat} dataFormat={this.buttonFormatter} >ACTION </TableHeaderColumn>



                </BootstrapTable>
                <div className="sizePerPage">
                  Size Per Page
                  <select
                    name="category"
                    value={this.state.sizePerPage}
                    onChange={this.handleChangeSizePerPage}
                  >
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value={this.state.datas?.length}>All</option>
                  </select>
                </div>

              </div>

            </Paper>
            {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}> */}

            {/* </Grid> */}

            <Dialog open={this.state.openDialog} onClose={this.handleOpenClose}>
              <DialogTitle>Tambah Pajak</DialogTitle>
              {/* {this.state.id ? 'Ubah' : 'Tambah'} */}
              <DialogContent>
                <DialogContentText>
                  {/* RW: {this.props.row.kodeDepdagri + '-' + this.props.row.nama_rw}<br /> */}
                </DialogContentText>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      id="filled-required"
                      label="Deskripsi"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      name="deskripsi"
                      style={{ width: '70%' }}
                      value={this.state.deskripsi}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      required
                      id="filled-required"
                      label="Persen Penghasilan Bruto"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      name="bruto"
                      style={{ width: '70%' }}
                      value={this.state.bruto}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      required
                      id="filled-required"
                      label="% Pajak"
                      variant="standard"
                      onChange={this.handleChangeInput}
                      name="pajak"
                      style={{ width: '50%' }}
                      value={this.state.pajak}
                      inputProps={{
                        min: 1,
                        max: 100,
                        type: 'number',
                      }}

                    />
                  </Grid>
                </Grid>

              </DialogContent>
              <DialogActions>
                {/* <Button disabled={this.state.disabledSimpanRt} onClick={this.handleAddRt} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Upload PDF</Button> */}
                {this.state.onSubmit === false ?
                  <>
                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleSubmit} variant="outlined" color="primary" sx={{ marginRight: '5px' }}>Simpan</Button>
                    <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenClose} variant="outlined" color="error">Batal</Button>
                  </> :
                  <div style={{ paddingRight: '1rem', fontWeight: '600' }}>
                    Loading, please wait...
                  </div>
                }
                {/* <Button disabled={this.state.disabledSimpanRt} onClick={this.handleOpenClose} variant="outlined" color="error">Batal</Button> */}

              </DialogActions>
            </Dialog>
          </div>
        </div>
      </BlockUi>
    )
  }
}

export default DaftarPajak;