import React, { Component } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImPlay3, ImUserPlus, ImSearch, ImPhone, ImLocation, ImFloppyDisk, ImCross, ImPrinter } from 'react-icons/im';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdAlternateEmail, MdPassword } from 'react-icons/md';
import { FcProcess } from "react-icons/fc";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { BsPersonCheckFill, BsPersonCheck } from "react-icons/bs";
import CommonTable from '../../../commons/table/Table';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import APIWilayah from '../../../services/APIWilayah';
import APIUser from "../../../services/APIUser";
import authServices from "../../../services/authServices";
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import download from 'downloadjs'
import APIReport from '../../../services/APIReport';


class monitoringPUS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendDatas: {},
      panel: true,

      //---table
      sizePerPage: 10,
      tableHead: [],
      datas: [],
      //---end table

      //---wilayah
      flagNumber: -1,
      flagOpen: true,
      blocking: false,
      dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
      dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
      //---end wilayah

      dt_roles: [],
      role: [],
      openEditUser: false,

      changePassword: false,
      showPasswordLama: false,
      showPasswordBaru: false,
      showPasswordBaruKonfirm: false,
      password: '',
      passwordBaru: '',
      updateBy: localStorage.getItem("username"),
      userId: 0,
      optKelompokUmur: [
        { value: '1', label: '10-14 TAHUN' },
        { value: '2', label: '15-49 TAHUN' },
        { value: '0', label: 'ALL' },
      ],
      optStatusHamil: [
        { value: '1', label: 'YA' },
        { value: '2', label: 'TIDAK' },
        { value: '0', label: 'ALL' },
      ],
      optStatusKB: [
        { value: '1', label: 'SEDANG' },
        { value: '2', label: 'PERNAH' },
        { value: '3', label: 'TIDAK PERNAH' },
        { value: '0', label: 'ALL' },
      ],
      optStatusJenisKB: [
        { value: '1', label: 'MOW/Steril Wanita' },
        { value: '2', label: 'MOW/Steril Pria' },
        { value: '3', label: 'IUD' },
        { value: '3', label: 'Implant' },
        { value: '3', label: 'Suntik' },
        { value: '3', label: 'PIL' },
        { value: '3', label: 'Kondom' },
        { value: '3', label: 'Mal' },
        { value: '3', label: 'Tradisional' },
        { value: '0', label: 'ALL' },
      ],
      dt_priode: [{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }],
      priode: [{ value: '2024', label: '2024' }],
      value_priode: 2024,
  }
}

sel_priode = (e) => {
  // this.setState({priode: [], value_priode: null})
  if (e) {
      this.setState({
          priode: e, value_priode: e.value
      }, () => {
          const rest = JSON.parse(localStorage.getItem("authority"));
          this.setState({
              roleUser: rest[0].attributes.role
          })
          let role = rest[0].attributes.user.roleID
          if (role === 1 || role === 99) {
              this.setState({
                  dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
                  dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
                  dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
                  dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
                  dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
                  dt_rt: [], rt: [], id_rt: '', id_rt: 0, dis_rt: true, depdagriRt: '',
              })
          }
          if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
              const nm = rest[0].attributes.user.TingkatWilayahID
              if (+nm === 0) {
                  this.setState({
                      flagSearch: false,
                      roleUser: rest[0].attributes.role
                  },
                      () => {
                          this.setProv(null)
                      }
                  )
              } else {
                  if (+nm === 1) { this.setState({ flagNumber: 1 }) }
                  else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
                  else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
                  else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
                  //--prepare by level
                  if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                      this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
                  } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                      this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
                  } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                      this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
                  } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                      this.setState({ dis_prov: true, auto_prov: true })
                  }

                  if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
                      this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
                  }
                  if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
                      this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
                  }
                  if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
                      this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
                  }
                  if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
                      this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
                  }
              }
          } else {
              this.setProv(null);
          }
      })


  }
  else {
      this.setState({
          priode: null, value_priode: null
      },
          () => {
              if (e === null) {
                  // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
              }
          }
      )
  }
}

  getRole = (e) => {
    authServices
      .get("/roles/list")
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState({
            dt_roles: res.data
          }, () => {
          })
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });
  }

  activeBtn = (e) => {
    const idUser = parseInt(e.currentTarget.id);
    let status = e.currentTarget.value;
    if (status == "true") {
      status = false;
    } else {
      status = true;
    }
    this.setState({
      updateStatus: {
        id: idUser,
        isActive: status
      }
    })

    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: "Ingin mengubah status User ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ blocking: true });
        authServices.post("daftar/updateUserStatus?id=" + this.state.updateStatus.id + "&isActive=" + this.state.updateStatus.isActive)
          .then((res) => {
            this.setState({ blocking: false });
            if (res.status === 200) {
              //Update state berdasarkan id yg diubah
              const arr = { id: this.state.updateStatus.id, isActive: this.state.updateStatus.isActive }
              this.setState({
                datas: this.state.datas.map(el => el.id === this.state.updateStatus.id ? Object.assign({}, el, arr) : el)
              })
              Swal.fire(
                'Berhasil!',
                'Kamu Berhasil Mengubah Status User ini.',
                'success'
              )
              this.setLabel()
            }
          })
          .catch((error) => {
            this.setState({ blocking: false });
            console.log(error, "dataaa ERROR");
          });
      } else {
        this.setState({blocking:false})
      }
    })
  }


  clearForm = () => {
    this.setState({ id: null, passwordBaru: '', password: '' })
  }

  buttonFormatter = (cell, row) => {
    return <div className='divBtnTable'>
      <Tooltip title="Ubah Status User"><Button onClick={this.activeBtn} id={row.id} value={row.isActive} className='btnTable' variant="outlined"><FcProcess /></Button></Tooltip>
      <Tooltip title="Ubah Password"><Button onClick={(e) => this.editPassword(e, row)} id={row.id} className='btnTable' variant="outlined"><MdPassword /></Button></Tooltip>
      {/* <Button onClick={(e) => this.handleEditUser(e, row)} id={row.id}  className='btnTable' variant="outlined"><AiTwotoneEdit style={{color:'green'}} /></Button> */}
      <Tooltip title="Edit User">
        <Button className='btnTable' variant="outlined">
          <Link to={`/edit-user/${row.username}`}><AiTwotoneEdit style={{ color: 'green' }} /></Link>
        </Button>
      </Tooltip>
    </div>
  }

  statusFormatter = (cell, row) => {
    if (row.isActive === true) {
      return <div className='userActive'>< MdOutlineCheckBox style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Aktif</div>
    } else {
      return <div className='userNonActive'><MdOutlineCheckBoxOutlineBlank style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Non-Aktif</div>
    }
  }

  createdFormat = (cell, row) => {
    if (row?.roleName === "Supervisor" && row?.validasiDate === null) {
      return 'adadad'
    } else {
      return ''
    }
  }

  setHeaderTable = () => {
    var headerColumns = [
      { width: "80", title: 'ACTION', dataField: "action", headerAlign: 'center', dataAlign: 'center', editable: false, dataFormat: "action" },
      { width: "100", title: 'STATUS', dataField: "isActive", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", title: 'USERNAME', dataField: "username", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'NAMA', dataField: 'namaLengkap', headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'ROLE', dataField: "roleName", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "150", title: 'WILAYAH', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'ALAMAT', dataField: "alamat", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'TELEPON', dataField: "noTelepon", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", title: 'EMAIL', dataField: "email", headerAlign: 'center', dataAlign: 'left', editable: false },

    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidMount = () => {
    this.setHeaderTable()

    const rest = JSON.parse(localStorage.getItem("authority"));
    if (rest[0] && rest[0].attributes && rest[0].attributes.user) {
      const nm = rest[0].attributes.user.TingkatWilayahID
      if (+nm === 0) {
        this.setState({ flagSearch: false },
          () => {
            this.setProv(null)
          }
        )
      } else {
        if (+nm === 1) { this.setState({ flagNumber: 1 }) }
        else if (+nm === 2) { this.setState({ flagNumber: 2 }) }
        else if (+nm === 3) { this.setState({ flagNumber: 3 }) }
        else if (+nm === 4) { this.setState({ flagNumber: 4 }) }
        //--prepare by level
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true, dis_des: true, auto_des: true })
        } else if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true, dis_kec: true, auto_kec: true })
        } else if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true, dis_kab: true, auto_kab: true })
        } else if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setState({ dis_prov: true, auto_prov: true })
        }

        if (Object.keys(rest[0].attributes.user.wilProvinsi).length > 0) {
          this.setProv(rest[0].attributes.user.wilProvinsi.id_provinsi)
        }
        if (Object.keys(rest[0].attributes.user.wilKabupaten).length > 0) {
          this.setKab(rest[0].attributes.user.wilProvinsi.id_provinsi, rest[0].attributes.user.wilKabupaten.id_kabupaten)
        }
        if (Object.keys(rest[0].attributes.user.wilKecamatan).length > 0) {
          this.setKec(rest[0].attributes.user.wilKabupaten.id_kabupaten, rest[0].attributes.user.wilKecamatan.id_kecamatan)
        }
        if (Object.keys(rest[0].attributes.user.wilKelurahan).length > 0) {
          this.setDes(rest[0].attributes.user.wilKecamatan.id_kecamatan, rest[0].attributes.user.wilKelurahan.id_kelurahan)
        }
      }
    } else {
      this.setProv(null);
    }
  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=' + this.state.value_priode)
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=' + this.state.value_priode)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // console.log(vl, 'vale kab')
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // console.log(vl, 'vale kec')
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              // console.log(vl, 'vale des')
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
        sendDatas: { ...this.state.sendDatas, idProvinsi: e.value }
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // console.log('masuk false')
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di prov')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {},
      }
        ,
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
        sendDatas: { ...this.state.sendDatas, idKabupaten: e.value }
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kab')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
        sendDatas: { ...this.state.sendDatas, idKecamatan: e.value }
      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kec')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
        sendDatas: { ...this.state.sendDatas, idKelurahan: e.value }
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di des')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
        sendDatas: { ...this.state.sendDatas, idRW: e.value }
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
        sendDatas: { ...this.state.sendDatas, idRT: e.value }
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        rt: null, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
          "idRW": this.state.sendDatas.idRW,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah

  handleSelectRole = (e) => {
    if (e) {
      this.setState({ role: e })
      this.setState({ sendDatas: { ...this.state.sendDatas, roleId: e.value } });
    } else {
      this.setState({ role: [], sendDatas: { ...this.state.sendDatas, roleId: '' } })
    }

  }

  handleChangePanel = (e) => {
    e.preventDefault();
    this.setState({ panel: !this.state.panel })
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  handleSearch = () => {
    this.setLabel()
    this.getBackend(this.state.sendDatas)
  }
  getBackend = (e) => {
    Swal.fire(
      'Info!',
      'Menu ini belum dapat digunakan.',
      'success'
    )
  }
  handleCetak = (e) => {


    // if (this.handleValidation()) {
    this.setState({ blocking: true });
    const { provinsi, kabupaten, kecamatan, desa, rw, id_provinsi, id_kabupaten, id_kecamatan, id_des, id_rw, id_rt } = this.state;
    //this.setJudulLaporan()

    let judul_lap, kode_lap
    //const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      kode_lap = this.state.rt.kode
      judul_lap = this.state.rt.namaWil
    } else {
      kode_lap = ''
      judul_lap = ''
      if (id_rw !== 0) {
        kode_lap = this.state.rw.kode
        judul_lap = this.state.rw.namaWil
      } else {
        kode_lap = ''
        judul_lap = ''
        if (id_des !== 0) {
          kode_lap = this.state.desa.kode
          judul_lap = this.state.desa.namaWil
        } else {
          kode_lap = ''
          judul_lap = ''
          if (id_kecamatan !== 0) {
            kode_lap = this.state.kecamatan.kode
            judul_lap = this.state.kecamatan.namaWil
          } else {
            kode_lap = ''
            judul_lap = ''
            if (id_kabupaten !== 0) {
              kode_lap = this.state.kabupaten.kode
              judul_lap = this.state.kabupaten.namaWil
            } else {
              kode_lap = ''
              judul_lap = ''
              if (id_provinsi !== 0) {
                kode_lap = this.state.provinsi.kode
                judul_lap = this.state.provinsi.namaWil
              } else {
                kode_lap = ''
                judul_lap = ' Tingkat Nasional'
              }
            }
          }
        }
      }
    }

    this.setState({ judul_laporan: judul_lap, kode_laporan: kode_lap }, () => console.log('judul', this.state.judul_laporan, 'kode', this.state.kode_laporan))

    Swal.fire({
      title: 'Cetak Formulir',
      //text: "Anda akan mencetak formulir",
      icon: 'info',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excel',
      confirmButtonColor: '#3085d6',
      showDenyButton: true,
      denyButtonText: 'PDF',
      denyButtonColor: '#4ea842',
      showCancelButton: true,
      cancelButtonText: 'Batal',
    }).then((result) => {

      let url = 'viewReport?filterPencarian=%7B'

      if (id_provinsi !== 0 && id_provinsi !== undefined) { url += '%22provinsiId%22%3A%22' + id_provinsi + '%22%2C' }
      if (id_kabupaten !== 0 && id_kabupaten !== undefined) { url += '%22kabupatenId%22%3A%22' + id_kabupaten + '%22%2C' }
      if (id_kecamatan !== 0 && id_kecamatan !== undefined) { url += '%22kecamatanId%22%3A%22' + id_kecamatan + '%22%2C' }
      if (id_des !== 0 && id_des !== undefined) { url += '%22kelurahanId%22%3A%22' + id_des + '%22%2C' }
      if (id_rw !== 0 && id_rw !== undefined) { url += '%22rwId%22%3A%22' + id_rw + '%22%2C' }

      //let urlCetakPdf = url +'%22ketWilayah%22%3A%22'+'tes' + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C'+ '%22title%22%3A%22' +this.state.title+ '%22%2C' +'%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'


      //viewReport?filterPencarian=%7B%22provinsiId%22%3A%221%22%2C%22kabupatenId%22%3A%227%22%2C%22kecamatanId%22%3A%224155%22%2C%22ketWilayah%22%3A%22Nama%20wilayah%20inputan%22%2C%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D

      //{"provinsiId":"1","kabupatenId":"7","kecamatanId":"4155","ketWilayah":"Nama wilayah inputan","reportName":"TABEL1","reportType":"pdf","reportCategory":"MasterWilayah"}

      //let coba =''
      //coba = decodeURI(this.state.judul_laporan)
      let urlCetakPdf = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22pdf%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'
      console.log('pdf', urlCetakPdf)
      let urlCetakExcel = url + '%22ketWilayah%22%3A%22' + this.state.kode_laporan + '-' + this.state.judul_laporan + '%22%2C' + '%22reportName%22%3A%22TABEL1%22%2C%22reportType%22%3A%22xlsx%22%2C%22reportCategory%22%3A%22MasterWilayah%22%7D'

      if (result.isConfirmed) {
        APIReport.get(urlCetakExcel, { responseType: 'blob' })
          .then(res => {
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.xlsx', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // console.log("error ===> ", error)
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });

      } else if (result.isDenied) {
        APIReport.get(urlCetakPdf, { responseType: 'blob' })
          .then(res => {
            // console.log(res, 'res report')
            if (res.status === 200) {
              const content = res.headers['content-type'];
              download(res.data, 'Laporan Pemutakhiran Wilayah ' + this.state.kode_laporan + '-' + this.state.judul_laporan + '.pdf', content)
              this.setState({ blocking: false })
            } else {
              Swal.fire({
                title: 'Info!',
                icon: 'info',
                text: 'Data tidak ditemukan.',
                showConfirmButton: false,
                timer: 1500
              })
              this.setState({ blocking: false });
            }
          }).catch((error) => {
            // console.log("error ===> ", error)
            let message = error;
            Swal.fire({
              title: 'Error!',
              icon: 'warning',
              // text: 'Jaringan bermasalah.',
              text: message,
            })
            this.setState({ blocking: false });
          });


      } else {
        this.setState({ blocking: false });
      }
    })

    e.preventDefault()



    // try{
    //     reportService.get(url, { responseType: 'blob' })
    //     .then(res => {
    //         // console.log(res, 'res report')
    //         if (res.status === 200) {
    //             const content = res.headers['content-type'];
    //             download(res.data, 'Rekapitulasi Data Keluarga.' + extFile, content)
    //             this.setState({ blocking: false })
    //         } else {
    //             Swal.fire({
    //                 title: 'Info!',
    //                 icon: 'info',
    //                 text: 'Data tidak ditemukan.',
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             })
    //             this.setState({ blocking: false });
    //         }
    //     }).catch((error) => {
    //         // console.log("error ===> ", error)
    //         let message = error;
    //         Swal.fire({
    //             title: 'Error!',
    //             icon: 'warning',
    //             // text: 'Jaringan bermasalah.',
    //             text: message,
    //         })
    //         this.setState({ blocking: false });
    //     });
    // }catch(error){
    //     let message = error;
    //     Swal.fire({
    //         title: 'Error!',
    //         icon: 'warning',
    //         // text: 'Jaringan bermasalah.',
    //         text: message,
    //     })
    //     this.setState({ blocking: false });
    // }

    // } else {
    //     Swal.fire({
    //         title: 'Info!',
    //         icon: 'info',
    //         text: 'Wilayah harus dimasukkan minimal Desa/Kelurahan',
    //         showConfirmButton: false,
    //         timer: 2000
    //     })
    //     this.setState({ blocking: false });
    // }
  }

  render() {

    const options = {
      page: 1,
      sizePerPageList: [{
        text: '1', value: 1
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
    };
    return (
      <div className='content'>
        <div className='title-content'><ImPlay3 className='icon-brd' />Monitoring PUS</div>
        <div className='body-content'>
          <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel} onChange={this.handleChangePanel} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Periode
                            <Select isDisabled options={this.state.dt_priode} isClearable onChange={this.sel_priode} value={this.state.priode} placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Provinsi
                            <Select isDisabled={this.state.dis_prov} options={this.state.dt_prov} id="prov" name="provinsi" onChange={this.sel_prov} value={this.state.provinsi} placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kabupaten
                            <Select options={this.state.dt_kab} isClearable onChange={this.sel_kab} value={this.state.kabupaten} placeholder="Cari..." isDisabled={this.state.dis_kab} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kecamatan
                            <Select options={this.state.dt_kec} isClearable onChange={this.sel_kec} value={this.state.kecamatan} placeholder="Cari..." isDisabled={this.state.dis_kec} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Kelurahan
                            <Select options={this.state.dt_des} isClearable onChange={this.sel_des} value={this.state.desa} placeholder="Cari..." isDisabled={this.state.dis_des} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RW
                            <Select options={this.state.dt_rw} isClearable onChange={this.sel_rw} value={this.state.rw} placeholder="Cari..." isDisabled={this.state.dis_rw} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            RT
                            <Select options={this.state.dt_rt} isClearable onChange={this.sel_rt} value={this.state.rt} placeholder="Cari..." isDisabled={this.state.dis_rt} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 2, md: 3 }} style={{ marginTop: '30px' }}>
                    <Grid item xs={12} md={12}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small" >
                            Kelompok Umur
                            <Select options={this.state.optKelompokUmur} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Status Hamil
                            <Select options={this.state.optStatusHamil} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Status KB
                            <Select options={this.state.optStatusKB} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }} size="small">
                            Jenis KB
                            <Select options={this.state.optStatusJenisKB} isClearable placeholder="Cari..." styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button onClick={this.handleSearch} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginRight: '5px' }} variant="contained"><ImSearch className='icon-brd' style={{ marginRight: '5px' }} /> Cari</Button>
                          <Button onClick={this.handleCetak} sx={{ width: 'calc(50% - 5px)', minHeight: '36px', marginTop: '22px', marginLeft: '5px', backgroundColor: 'green', ':hover': { bgcolor: 'green', color: 'white' } }} variant="contained"><ImPrinter className='icon-brd' style={{ marginRight: '5px' }} /> Cetak</Button>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Pasangan Usia Subur</h3>
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              {/* <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
              > */}
              <div className='user-table'>
                <BootstrapTable data={this.state.datas}
                  pagination={true}
                  options={options}
                  search={true}
                >

                  <TableHeaderColumn width='175' isKey={true} dataField='noKK' headerAlign='center' columnClassName={this.createdFormat} tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign='center'>NO KK</TableHeaderColumn>
                  <TableHeaderColumn width='160' dataField='namaKK' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>NAMA KK</TableHeaderColumn>
                  <TableHeaderColumn width='120' dataField='namaIstri' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>NAMA ISTRI</TableHeaderColumn>
                  <TableHeaderColumn width='150' dataField='umurIstri' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>UMUR ISTRI</TableHeaderColumn>
                  <TableHeaderColumn width='180' dataField='statusHamil' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat} >STATUS HAMIL</TableHeaderColumn>
                  <TableHeaderColumn width='110' dataField='statusKB' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>STATUS KB</TableHeaderColumn>
                  <TableHeaderColumn width='90' dataField='jenisKB' headerAlign='center' dataAlign='center' columnClassName={this.createdFormat}>JENIS KB</TableHeaderColumn>
                  {/* <TableHeaderColumn width='150' dataField='smartphone' headerAlign='center' dataAlign='center'>METODE PENDATAAN</TableHeaderColumn> */}
                  {/* <TableHeaderColumn width='150' dataField='' headerAlign='center' dataAlign='center'>CREATED BY</TableHeaderColumn> */}
                </BootstrapTable>
              </div>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='1'>1</option>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
              <div className='totalData'><b>Total Data: {this.state.datas.length}</b></div>
            </Paper>


          </BlockUi>
        </div>

        {/* DIalog ubah password  */}


      </div>
    )
  }
}

export default monitoringPUS;