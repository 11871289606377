import React, { Component } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Box, Grid, Accordion, Button, AccordionSummary, AccordionDetails, FormControl, Typography, Alert, AlertTitle, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImPlay3, ImUserPlus, ImSearch, ImPhone, ImLocation } from 'react-icons/im';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdAlternateEmail } from 'react-icons/md';
import { FcProcess } from "react-icons/fc";
import { AiTwotoneEdit, AiOutlineIdcard, AiFillLock } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { BsPersonCheckFill, BsPersonCheck } from "react-icons/bs";
import CommonTable from '../../../commons/table/Table';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import APIWilayah from '../../../services/APIWilayah';
import APIUser from "../../../services/APIUser";
import authServices from "../../../services/authServices";
import apiPemuakhiran from "../../../services/APIPemutakhiran";
import { Link } from 'react-router-dom';

const isNumber = (str) => /^[0-9]*$/.test(str);

class Indikator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendDatas: {},
      panel: true,
      currentPage: 1,
      sizePerPage: 5,
      dt_data: this.props.dt_data,

      //---table
      tableHead: [],
      datas: [],
      distribusiIsChecked: false,
      penerimaanIsChecked: false,
      statusIsChecked: false,
      pengadaanIsChecked: false,
      jumlahPeserta: "",
      periode_sensus:'',

      //---end table

      //---wilayah
      flagNumber: -1,
      flagOpen: true,
      blocking: false,
      dt_prov: [], provinsi: [], id_provinsi: 0, depdagriProv: '', dis_prov: false, auto_prov: false,
      dt_kab: [], kabupaten: [], id_kabupaten: 0, dis_kab: true, depdagriKab: '', auto_kab: false,
      dt_kec: [], kecamatan: [], id_kecamatan: 0, dis_kec: true, depdagriKec: '', auto_kec: false,
      dt_des: [], desa: [], id_des: 0, dis_des: true, depdagriKel: '', auto_des: false,
      dt_rw: [], rw: [], id_rw: 0, dis_rw: true, depdagriRw: '',
      dt_rt: [], rt: [], id_rt: 0, dis_rt: true, depdagriRt: '',
      //---end wilayah

      dt_roles: [],
      role: [],
      openEditUser: false,
      optPriodePendataan: [
        { value: '2022', label: '2022' },
        { value: '2021', label: '2021' },
        { value: '2020', label: '2020' },
      ],
    }
  }

  getRole = (e) => {
    authServices
      .get("/roles/list")
      .then((res) => {
    
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState({
            dt_roles: res.data,
            distribusiIsChecked: true
          }, () => {
          })
          console.log(this.state.dt_roles,"COBAS")
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error, "dataaa ERROR");
      });
  }

  activeBtn = (e) => {
    const idUser = parseInt(e.currentTarget.id);
    let status = e.currentTarget.value;
    if (status == "true") {
      status = false;
    } else {
      status = true;
    }
    this.setState({
      updateStatus: {
        id: idUser,
        isActive: status
      }
    })

    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: "Ingin mengubah status User ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ blocking: true });
        authServices.post("daftar/updateUserStatus?id=" + this.state.updateStatus.id + "&isActive=" + this.state.updateStatus.isActive)
          .then((res) => {
            this.setState({ blocking: false });
            if (res.status === 200) {
              //Update state berdasarkan id yg diubah
              const arr = { id: this.state.updateStatus.id, isActive: this.state.updateStatus.isActive }
              this.setState({
                datas: this.state.datas.map(el => el.id === this.state.updateStatus.id ? Object.assign({}, el, arr) : el)
              })
              Swal.fire(
                'Berhasil!',
                'Kamu Berhasil Mengubah Status User ini.',
                'success'
              )
              this.setLabel()
            }
          })
          .catch((error) => {
            this.setState({ blocking: false });
            console.log(error, "dataaa ERROR");
          });
      } else {
        this.setState({blocking: false })
      }
    })
  }

  handleEditUser = (e, row) => {
    this.setState({}, () => { this.handleOpenCloseEditUser() })


  }

  handleOpenCloseEditUser = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ openEditUser: !this.state.openEditUser },
      // () => { if (!this.state.openEditUser) { this.clearForm() } }
    )
  }

  buttonFormatter = (cell, row) => {
    return <div className='divBtnTable'>
      <Button onClick={this.activeBtn} id={row.id} value={row.isActive} className='btnTable' variant="outlined"><FcProcess /></Button>
      <Button onClick={(e) => this.handleEditUser(e, row)} id={row.id} className='btnTable' variant="outlined"><AiTwotoneEdit style={{ color: 'green' }} /></Button>

    </div>
  }
  nomorUrut = (cell, row, rowIndex) => {
    // let rowNumber = (this.state.currentPage - 1) * this.state.sizePerPage + (rowIndex + 1);
    return <span>-</span>;
  }
  handleChangeCheckBoxdistribusi = (e, row) => {
    console.log(e.target.checked, "distri")
    if (e) {
      this.setState({
        distribusiIsChecked: e.target.checked,
      });
    }
  };
  handleChangeCheckBoxPenerimaan = (e) => {
    console.log(e.target.checked, "penerimaan")
    if (e) {
      this.setState({
        penerimaanIsChecked: e.target.checked,
      });
    }
  };

  handleChangeCheckBoxstatusawal = (e) => {
    console.log(e.target.checked, "awal")
    if (e) {
      this.setState({
        statusIsChecked: e.target.checked,
      });
    }
  };

  handleChangeCheckBoxpengadaan = (e) => {
    console.log(e.target.checked, "akhir")
    if (e) {
      this.setState({
        pengadaanIsChecked: e.target.checked,
      });
    }
  };

  checkboxDistribusiFormatter = (cell, row) => {
    return <div className='divBtnTable'>
      <Checkbox onChange={(e) => this.handleChangeCheckBoxdistribusi(e)} id={row.distribusi} value={row.distribusi} defaultChecked={
        row.distribusi
      } />

    </div>
  }

  checkboxawalFormatter = (cell, row) => {
    return <div className='divBtnTable'>
      <Checkbox onChange={(e) => this.handleChangeCheckBoxstatusawal(e)} id={row.status_proses} value={row.status_proses} defaultChecked={
        row.status_proses
      } />

    </div>
  }

  checkboxPenerimaanFormatter = (cell, row) => {
    // if
    return <div className='divBtnTable'>
      <Checkbox onChange={(e) => this.handleChangeCheckBoxPenerimaan(e)} id={row.penerimaan} value={row.penerimaan} defaultChecked={
        row.penerimaan
      } />

    </div>
  }
  checkboxpengadaanFormatter = (cell, row) => {
    // if
    return <div className='divBtnTable'>
      <Checkbox onChange={(e) => this.handleChangeCheckBoxpengadaan(e)} id={row.pengadaan} value={row.pengadaan} defaultChecked={
        row.pengadaan
      } />

    </div>
  }

  textfieldFormatter = (cell, row) => {
    return <div className='divBtnTable'>
      <TextField
        inputProps={{ min: 0, style: { textAlign: 'center' } }}
        id="filled-required"
        label=""
        variant="standard"
        onChange={this.handleChangeInput}
        onBlur={this.runUpdateData}
        onFocus={this.handleFocus}
        // onKeyDown={this.keyPress}
        value={cell}
        name={row.ind_code + "-target"}
        fullWidth
      />
    </div>
  }

  handleChangeInput = (e, tab) => {
    const val = Number(e.target.value)
    if (isNumber(val)) {
      this.setState({ [e.target.name]: val, jumlahPeserta: val },
        () => {
          const id = e.target.name.split('-')[0]
          this.setState({ dt_data: this.state.dt_data.map(el => el.ind_code === id)})
        }
      )
    }
  }
  handleFocus = (event) => event.target.select();



  fansarana = () => {
    return <span>	Juknis PK lengkap (Pedoman)</span>;
  }

  fanPelatihan = () => {
    return <span>	Pelatihan tingkat provinsi</span>;
  }

  statusFormatter = (cell, row) => {
    if (row.isActive === true) {
      return <div className='userActive'>< MdOutlineCheckBox style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Aktif</div>
    } else {
      return <div className='userNonActive'><MdOutlineCheckBoxOutlineBlank style={{ fontSize: '12px', position: 'relative', top: '2px' }} /> Non-Aktif</div>
    }

  }
  handleSimpan = (e, row) => {
    console.log(row)
    this.get()
    const data1 = {
      distribusi: this.state.distribusiIsChecked,
      penerimaan: this.state.penerimaanIsChecked,
      statusawal: this.state.statusIsChecked,
      pengadaan: this.state.pengadaanIsChecked,
      jumlahPeserta: this.state.jumlahPeserta
    }
    console.log(data1)
  }

  setHeaderTable = () => {
    var headerColumns = [
      { width: "80", title: 'ACTION', dataField: "action", headerAlign: 'center', dataAlign: 'center', editable: false, dataFormat: "action" },
      { width: "100", title: 'STATUS', dataField: "isActive", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "100", title: 'USERNAME', dataField: "username", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'NAMA', dataField: 'namaLengkap', headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'ROLE', dataField: "roleName", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "150", title: 'WILAYAH', dataField: "namaWilayah", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "180", title: 'ALAMAT', dataField: "alamat", headerAlign: 'center', dataAlign: 'left', editable: false },
      { width: "120", title: 'TELEPON', dataField: "noTelepon", headerAlign: 'center', dataAlign: 'center', editable: false },
      { width: "120", title: 'EMAIL', dataField: "email", headerAlign: 'center', dataAlign: 'left', editable: false },

    ];
    this.setState({ tableHead: headerColumns });
  }

  componentDidMount = () => {

  }

  //API Wilayah
  setProv = (id) => {
    this.setState({ blocking: true });
    this.setState({ dt_prov: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListProvinsi?periodeSensus=2024')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(data => {
            const obj = { 'value': data.id_provinsi, 'label': data.kodeDepdagri + ' - ' + data.nama, 'kode': data.kodeDepdagri };
            newData.push(obj);
          });
          this.setState({ dt_prov: newData },
            () => {
              if (id !== null && this.state.auto_prov === true) {
                const vl = this.state.dt_prov.filter(item => parseInt(item.value) === parseInt(id))
                this.sel_prov(vl[0])
              }
            }
          );
        }
        this.setState({ blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kabupaten
  setKab(idProv, idKab) {
    this.setState({ blocking: true });
    this.setState({ dt_kab: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKabupatenByIdProvinsi?id_provinsi=' + idProv + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kabupaten, 'label': data.kodeDepdagri + ' - ' + data.nama_kabupaten, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kab: newData, blocking: false },
          () => {
            if (idKab !== null && this.state.auto_kab === true) {
              const vl = this.state.dt_kab.filter(item => parseInt(item.value) === parseInt(idKab))
              // console.log(vl, 'vale kab')
              this.sel_kab(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get kecamatan
  setKec(idKab, idKec) {
    this.setState({ blocking: true });
    this.setState({ dt_kec: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKecamatanByIdKabupaten?id_kabupaten=' + idKab + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kecamatan, 'label': data.kodeDepdagri + ' - ' + data.nama_kecamatan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_kec: newData, blocking: false },
          () => {
            if (idKec !== null && this.state.auto_kec === true) {
              const vl = this.state.dt_kec.filter(item => parseInt(item.value) === parseInt(idKec))
              // console.log(vl, 'vale kec')
              this.sel_kec(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get desa
  setDes(idKec, idDes) {
    this.setState({ blocking: true });
    this.setState({ dt_des: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListKelurahanByIdKecamatan?id_kecamatan=' + idKec + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_kelurahan, 'label': data.kodeDepdagri + ' - ' + data.nama_kelurahan, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_des: newData, blocking: false },
          () => {
            if (idDes !== null && this.state.auto_des === true) {
              const vl = this.state.dt_des.filter(item => parseInt(item.value) === parseInt(idDes))
              // console.log(vl, 'vale des')
              this.sel_des(vl[0])
            }
          }
        );
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rw
  setRW(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rw: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRwByIdKelurahan?id_kelurahan=' + id + '&periodeSensus=2024')
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rw, 'label': data.kodeDepdagri + ' - ' + data.nama_rw, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rw: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  // get rt
  setRT(id) {
    this.setState({ blocking: true });
    this.setState({ dt_rt: [] });
    var newData = [];
    APIWilayah.get('pkportal/location/getListRtByIdRw?id_rw=' + id)
      .then(res => {
        res.data.forEach(data => {
          const obj = { 'value': data.id_rt, 'label': data.kodeDepdagri + ' - ' + data.nama_rt, 'kode': data.kodeDepdagri };
          newData.push(obj);
        });
        this.setState({ dt_rt: newData, blocking: false });
      }).catch((error) => {
        this.setState({ blocking: false });
      });
  }

  sel_prov = (e) => {
    if (e) {
      this.setState({
        provinsi: e, id_provinsi: e.value, depdagriProv: e.kode,
        sendDatas: { ...this.state.sendDatas, idProvinsi: e.value }
      },
        () => {
          if (this.state.id_provinsi && this.state.auto_kab === false) {
            // console.log('masuk false')
            this.setState({
              dis_kab: false, kabupaten: null, kecamatan: null, desa: null, rw: null,
              rt: null, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKab(this.state.id_provinsi, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di prov')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        provinsi: null, kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kab: true, dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_provinsi: 0, id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {},
      }
        ,
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kab = (e) => {
    if (e) {
      this.setState({
        kabupaten: e, id_kabupaten: e.value, depdagriKab: e.kode,
        sendDatas: { ...this.state.sendDatas, idKabupaten: e.value }
      },
        () => {
          if (this.state.id_kabupaten && this.state.auto_kec === false) {
            this.setState({
              dis_kec: false, kecamatan: null, desa: null, rw: null, rt: null,
              dis_des: true, dis_rw: true, dis_rt: true
            })
            this.setKec(this.state.id_kabupaten, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kab')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kabupaten: null, kecamatan: null, desa: null, rw: null, rt: null,
        dis_kec: true, dis_des: true, dis_rw: true, dis_rt: true,
        id_kabupaten: 0, id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_kec = (e) => {
    if (e) {
      this.setState({
        kecamatan: e, id_kecamatan: e.value, depdagriKec: e.kode,
        sendDatas: { ...this.state.sendDatas, idKecamatan: e.value }
      },
        () => {
          if (this.state.id_kecamatan && this.state.auto_des === false) {
            this.setState({
              desa: null, rw: null,
              rt: null, dis_des: false, dis_rw: true, dis_rt: true
            })
            this.setDes(this.state.id_kecamatan, null);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di kec')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        kecamatan: null, desa: null, rw: null, rt: null,
        dis_des: true, dis_rw: true, dis_rt: true,
        id_kecamatan: 0, id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_des = (e) => {
    if (e) {
      this.setState({
        desa: e, id_des: e.value, depdagriKel: e.kode, rw: null, rt: null, dis_rw: false, dis_rt: true,
        sendDatas: { ...this.state.sendDatas, idKelurahan: e.value }
      },
        () => {
          if (this.state.id_des) {
            this.setRW(this.state.id_des);
          }
          if (this.state.flagNumber !== 0 && this.state.flagOpen === true) {
            // console.log(this.state.flagNumber, 'flag -1 di des')
            this.setState({ flagNumber: this.state.flagNumber - 1 })
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        desa: null, rw: null, rt: null, dis_rw: true, dis_rt: true,
        id_des: 0, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rw = (e) => {
    if (e) {
      this.setState({
        rw: e, id_rw: e.value, depdagriRw: e.kode, rt: null, dis_rt: false,
        sendDatas: { ...this.state.sendDatas, idRW: e.value }
      },
        () => {
          if (this.state.id_rw) {
            this.setRT(this.state.id_rw);
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        })
    }
    else {
      this.handleSelectRole()
      this.setState({
        rw: null, rt: null, dis_rt: true, id_rw: 0, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }

  sel_rt = (e) => {
    if (e) {
      this.setState({
        rt: e, id_rt: e.value, depdagriRt: e.kode,
        sendDatas: { ...this.state.sendDatas, idRT: e.value }
      },
        () => {
          if (e) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
    else {
      this.handleSelectRole()
      this.setState({
        rt: null, id_rt: 0,
        sendDatas: {
          "idProvinsi": this.state.sendDatas.idProvinsi,
          "idKabupaten": this.state.sendDatas.idKabupaten,
          "idKecamatan": this.state.sendDatas.idKecamatan,
          "idKelurahan": this.state.sendDatas.idKelurahan,
          "idRW": this.state.sendDatas.idRW,
        }
      },
        () => {
          if (e === null) {
            // this.setBody(idProv, idKab, idKec, idDes, idRw, idRt)
          }
        }
      )
    }
  }
  //-----end wilayah
  handlePriode = (row) => {
    let indiProv = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilProvinsi.id_provinsi
    let indiKab = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten ? JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKabupaten.id_kabupaten : ''
    let indiKec = JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKecamatan.id_kecamatan ? JSON.parse(localStorage.getItem('authority'))[0].attributes.user.wilKecamatan.id_kecamatan : ''
    
    this.setState({periode_sensus: row}, () => {
      this.setState({
        periode_sensus: row
      })
      
    })


    apiPemuakhiran
    .get("portalpk/pengaturan/getIndikatorProses?v_periode_sensus=" + row.value + " &v_id_provinsi=" + indiProv + "&v_id_kabupaten="+ indiKab + "&v_id_kecamatan=" + indiKec )
    .then((res) => {
      this.setState({ blocking: false });
      if (res.status === 200) {
        console.log(res)

        this.setState({
          dt_data: res.data.data,
          distribusiIsChecked: res.data.data[0].distribusi,
          penerimaanIsChecked: res.data.data[0].penerimaan,
          statusIsChecked: res.data.data[0].status_proses,
          pengadaanIsChecked: res.data.data[0].pengadaan,
          jumlahPeserta: res.data.data[0].jml_peserta

        }, () => {
        })
      }
    })
    .catch((error) => {
      this.setState({ blocking: false });
      console.log(error, "dataaa ERROR GET");
    });
    
  }


  handleChangePanel = (e) => {
    e.preventDefault();
    this.setState({ panel: !this.state.panel })
  }

  handleChangeSizePerPage = (e) => {
    let x = 1
    if (+e.target.value !== 0) {
      x = +e.target.value
    }
    this.setState({ sizePerPage: x })
  }

  setLabel = () => {
    let title, text1, text2
    const { id_rt, id_rw, id_des, id_kecamatan, id_kabupaten, id_provinsi } = this.state
    if (id_rt !== 0) {
      title = ' di RT ' + this.state.rt.label
    } else {
      title = ''
      if (id_rw !== 0) {
        title = ' di RW ' + this.state.rw.label
        text1 = 'RT'
        text2 = 'JUMLAH KK'
      } else {
        title = ''
        if (id_des !== 0) {
          title = ' di Kelurahan ' + this.state.desa.label
          text1 = 'RW'
          text2 = 'JUMLAH RT'
        } else {
          title = ''
          if (id_kecamatan !== 0) {
            title = ' di Kecamatan ' + this.state.kecamatan.label
            text1 = 'KELURAHAN'
            text2 = 'JUMLAH RW'
          } else {
            title = ''
            if (id_kabupaten !== 0) {
              title = ' di Kabupaten ' + this.state.kabupaten.label
              text1 = 'KECAMATAN'
              text2 = 'JUMLAH KELURAHAN'
            } else {
              title = ''
              if (id_provinsi !== 0) {
                title = ' di Provinsi ' + this.state.provinsi.label
                text1 = 'KABUPATEN'
                text2 = 'JUMLAH KECAMATAN'
              } else {
                title = ' Tingkat Nasional'
                text1 = 'PROVINSI'
                text2 = 'JUMLAH KABUPATEN'
              }
            }
          }
        }
      }
    }

    this.setState({ title: title, text1: text1, text2: text2 },
      () => { this.setHeaderTable() }
    )
  }

  handleSearch = () => {
    this.setLabel()
    this.getBackend(this.state.sendDatas)
  }

  getBackend(formData) {
    this.setState({ blocking: true });
    authServices.get("/daftar/listDaftarUser", { params: formData })
      .then((res) => {
        this.setState({ blocking: false });
        if (res.status === 200) {
          this.setState(
            {
              datas: res.data
            }, () => {
              // console.log(this.state.datas,"datas")
            }
          )
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        // console.log(error, "dataaa ERROR");
      });
  }

  render() {

    const options = {
      page: 1,
      sizePerPageList: [{
        text: '1', value: 1
      }, {
        text: '10', value: 10
      }], // you can change the dropdown list for size per page
      sizePerPage: +this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      hideSizePerPage: true,
      paginationShowsTotal: false,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
    };

    return (
      <div className='content'>
        <div className='title-content'><ImPlay3 className='icon-brd' />Pengaturan Indikator Proses</div>
        <div className='body-content'>
          <BlockUi tag="div" blocking={this.state.blocking} message="Please wait">
            <Box sx={{ width: '100%' }}>
              <Accordion expanded={this.state.panel} onChange={this.handleChangePanel} className='accordionRoot'>
                <AccordionSummary
                  className='panelSummary'
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: '#fafafa', borderBottom: '1px solid #ececec' }}
                >
                  <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                    <Grid style={{ display: this.state.hideProvinsi }} item xs={12} md={4}>
                      <FormControl sx={{ width: '100%' }} size="small" >
                        Priode pendataan
                        <Select options={this.state.optPriodePendataan} value={this.state.periode_sensus} onChange={this.handlePriode} id="periode_sensus" name="periode_sensus" placeholder="Cari..." isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body}>
                        </Select>

                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <h3>Daftar Indikator {this.state.title}</h3>

            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              {/* <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
              > */}
              <BootstrapTable data={this.state.dt_data}
                pagination={true}
                options={options}>
                {/* <TableHeaderColumn width='60' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter}>ACTION</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='100' dataField='isActive' headerAlign='center' dataAlign='center'  dataFormat={this.statusFormatter}>STATUS</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='60' dataField='#' headerAlign='center' dataAlign='center' dataFormat={this.nomorUrut}>#</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='180'  dataField='namaLengkap' headerAlign='center' dataAlign='center'>NAMA</TableHeaderColumn> */}
                <TableHeaderColumn width='180' isKey={true} dataField='sarana' headerAlign='center' dataAlign='center' dataFormat={this.fansarana}>Sarana & Prasarana</TableHeaderColumn>
                <TableHeaderColumn width='80' dataField='penerimaan' headerAlign='center' dataFormat={this.checkboxPenerimaanFormatter} dataAlign='center'>Penerimaan</TableHeaderColumn>
                <TableHeaderColumn width='80' dataField='distribusi' headerAlign='center' dataFormat={this.checkboxDistribusiFormatter} dataAlign='center'>Distribusi</TableHeaderColumn>
                {/* <TableHeaderColumn width='100' dataField='noTelepon' headerAlign='center' dataAlign='center'>ENTRI</TableHeaderColumn> */}


              </BootstrapTable>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='1'>1</option>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
            </Paper>

            <Paper sx={{ width: '100%', boxShadow: 'none', marginTop: '50px' }}>
              {/* <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
              > */}
              <BootstrapTable data={this.state.dt_data}
                pagination={true}
                options={options}>
                {/* <TableHeaderColumn width='60' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter}>ACTION</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='100' dataField='isActive' headerAlign='center' dataAlign='center'  dataFormat={this.statusFormatter}>STATUS</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='60' dataField='#' headerAlign='center' dataAlign='center' dataFormat={this.nomorUrut}>#</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='180'  dataField='namaLengkap' headerAlign='center' dataAlign='center'>NAMA</TableHeaderColumn> */}
                <TableHeaderColumn width='180' isKey={true} dataField='pelatihan' headerAlign='center' dataAlign='center' dataFormat={this.fanPelatihan}>Pelatihan</TableHeaderColumn>
                <TableHeaderColumn width='80' dataField='status_proses' headerAlign='center' dataAlign='center' dataFormat={this.checkboxawalFormatter}>Status (Sudah/Belum)</TableHeaderColumn>
                <TableHeaderColumn width='120' dataField='jml_peserta' headerAlign='center' dataAlign='center' dataFormat={this.textfieldFormatter}>Jumlah Perserta</TableHeaderColumn>
                {/* <TableHeaderColumn width='100' dataField='noTelepon' headerAlign='center' dataAlign='center'>ENTRI</TableHeaderColumn> */}


              </BootstrapTable>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='1'>1</option>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>
            </Paper>

            <Paper sx={{ width: '100%', boxShadow: 'none', marginTop: '50px' }}>
              {/* <CommonTable
                tableHead={this.state.tableHead}
                datas={this.state.datas}
                spp={+this.state.sizePerPage}
              > */}
              <BootstrapTable data={this.state.dt_data}
                pagination={true}
                options={options}>
                {/* <TableHeaderColumn width='60' dataField='action' headerAlign='center' dataAlign='center' dataFormat={this.buttonFormatter}>ACTION</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='100' dataField='isActive' headerAlign='center' dataAlign='center'  dataFormat={this.statusFormatter}>STATUS</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='60' dataField='#' headerAlign='center' dataAlign='center' dataFormat={this.nomorUrut}>#</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='180'  dataField='namaLengkap' headerAlign='center' dataAlign='center'>NAMA</TableHeaderColumn> */}
                <TableHeaderColumn width='120' isKey={true} dataField='kelengkapan' headerAlign='center' dataAlign='center'>Kelengkapan</TableHeaderColumn>
                <TableHeaderColumn width='120' dataField='pengadaan' headerAlign='center' dataAlign='center' dataFormat={this.checkboxpengadaanFormatter}>Status (Sudah/Belum)</TableHeaderColumn>
                {/* <TableHeaderColumn width='120' dataField='alamat' headerAlign='center' dataAlign='center'>Jumlah Perserta</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='100' dataField='noTelepon' headerAlign='center' dataAlign='center'>ENTRI</TableHeaderColumn> */}


              </BootstrapTable>
              <div className='sizePerPage'>
                Size Per Page
                <select name="category" value={this.state.sizePerPage} onChange={this.handleChangeSizePerPage}>
                  <option value='1'>1</option>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value={this.state.datas.length}>All</option>
                </select>
              </div>

            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={6} >
                <Button onClick={this.handleSimpan} sx={{ marginTop: '10px' }} variant="contained">Update Indikator Proses</Button>
              </Grid>
            </Grid>

          </BlockUi>
        </div>
      </div >
    )
  }
}

export default Indikator;